import Button from '@src/@core/components/ui/button'
import InputField from '@src/@core/components/ui/input-field'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import Toggle from '@src/@core/components/ui/toggle-button'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import Action from './action'
import Condition from './condition'
import './createEditRule.scss'

/**
 * A component that handles the creation and editing of rules.
 *
 * @param {Array} triggerConditions - An array of trigger conditions.
 * @param {Array} actionValues - An array of action values.
 * @param {Function} handleCreateEditRule - A function to handle the creation or editing of a rule.
 * @param {Boolean} isFormSubmitting - A boolean indicating whether the form is submitting.
 * @param {Boolean} isEdit - A boolean indicating whether the rule is being edited.
 * @param {Boolean} isView - A boolean indicating whether the rule is being viewed.
 * @param {Object} propsMap - An object mapping API endpoints.
 * @param {Object} defaultFormValues - The default values for the form.
 * @param {Function} onConditionOrActionChange - A callback function to get value when condition or action change.
 * @param {Function} toggle - A function to toggle the side sheet.
 * @param {JSX.Element} CustomActionComponent - A custom action component.
 * @return {JSX.Element} The JSX element representing the rule creation or editing form.
 */
const CreateEditRule = ({triggerConditions = [], actionValues = [], handleCreateEditRule, isFormSubmitting, isEdit, isView, propsMap, defaultFormValues = {}, onConditionOrActionChange = () => {}, toggle, CustomActionComponent, customDescriptionLabel, customNameLabel, primaryCtaText, showHelperText = true, showCancelCta = true  }) => {

  const { t } = useTranslation()
  const showStatusField = isEdit && defaultFormValues.hasOwnProperty('is_active')


  const formObject = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange'
  })

  const { register, reset, handleSubmit, watch, formState: {errors} } = formObject
  const conditionRows = watch('triggerConditions')

  const onSubmit = (data) => {
    const triggerConditions = data.triggerConditions.slice(0, -1) 
    const actionValues = data.actionValues.slice(0, -1)
    handleCreateEditRule({...data, triggerConditions, actionValues})
  }
  
  useEffect(() => {
    reset(defaultFormValues)
  }, [defaultFormValues])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column gap-24px">
      <div className="flex-start-start gap-20px">
        <div><InputField label={customNameLabel ? `${customNameLabel}` : 'Rule Name'} isRequired {...register('name', { required: 'Required Field' })} errors={errors} disabled={isView} width="320px" /></div>
        <div><InputField label={customDescriptionLabel ? `${customDescriptionLabel}` : 'Enter Description'} {...register('description')} disabled={isView} width="320px" /></div>
        {showStatusField && <div className="flex-center-between gap-20px border border-dark-2 bg-light-2 rounded-12px px-16px status-field height-44px">
          <div className="text-dark txt-sub-md text-nowrap">
            {t('Status')}
          </div>
          <Toggle
            name="is_active"
            {...register('is_active')}
            checked={watch('is_active')} 
            activeText='Active'
            inactiveText='Inactive'
          />
        </div>}
      </div>
      <Condition 
        formObject={formObject} 
        isView={isView} 
        triggerConditions={triggerConditions} 
        propsMap={propsMap} 
        onConditionOrActionChange={onConditionOrActionChange}
        showHelperText={showHelperText}
      />
      {CustomActionComponent 
        ? <CustomActionComponent 
          formObject={formObject}
          isView={isView}
          isEdit={isEdit}
          actionValues={actionValues}
          propsMap={propsMap}
          onConditionOrActionChange={onConditionOrActionChange}
          conditionRows={conditionRows}
          showHelperText={showHelperText}
        />
        : <Action 
          formObject={formObject}
          isView={isView}
          actionValues={actionValues}
          propsMap={propsMap}
          onConditionOrActionChange={onConditionOrActionChange}
          conditionRows={conditionRows}
          showHelperText={showHelperText}
        />}
      <SidesheetFooter className='w-100 d-flex justify-content-end gap-8px'>
        {showCancelCta && <Button type='button' ofStyle='outlined' onClick={toggle}>{t('Cancel')}</Button>}
        {!isView && <Button type = 'submit' disabled={isFormSubmitting} icon={isFormSubmitting ? Spinner : null} iconSize={'sm'}>{primaryCtaText ? t(`${primaryCtaText}`) : t('Save Rule')}</Button>}
      </SidesheetFooter>
    </form>
  )
}

export default CreateEditRule