/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import DateFilter from '@src/@core/components/ui/date-filter';
import { loadAllPickupLocationsAsyncConfig, loadAllSellersConfig, loadSellerSalesChannelConfig, loadShippingPartnerConfig } from '@src/app.apis';
import { DROPDOWN_TYPES, LOAD_SHIPPING_PARTNER_CONFIG, TENANT_TYPE } from '@src/App.constants';
import { iFilterHandlerParams } from '@src/app.types';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import { AbilityContext } from '@src/utility/context/Can';
import { getFiltersBasedOnOmit, getOptionsFetcher } from '@src/utility/Utils';
import { getAllCitiesAsyncData } from '@src/views/hubs/store';
import { INTEGRATION_TYPES } from '@src/views/sales-channel/salesChannel.constants';
import FiltersMapper from '@src/views/shipping-aggregator/components/filter-wrapper/filter-wrapper';
import { CREATE_SHIPMENTS_ORDER_FILTER_METADATA, ORDER_STATUS, ORDERS_TABS, PACKAGE_STATUS, PACKAGE_TABS, SHIPMENT_ORDERS_VIEW_OPTIONS } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

type iTripFilters = {
  selectedTabParam?: string;
  filterHandler: (params: iFilterHandlerParams) => void;
  filterHandlerMultipleValues?: (params: iFilterHandlerParams[]) => void;
  filter?: any;
  tripType: string;
  omitShipmentFilters?: string[];
  setFilter?: any
}

const ShipmentsOrderFilters = ({ selectedTabParam, filter, filterHandler }: iTripFilters) => {
  const ability = useContext(AbilityContext);
  const isSellerViewAllowed = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const selfTenantSellerId = useSelector((state: any) => state.auth.userData.sellers[0]?.seller_id);
  const tenantType = useSelector((state: {
    auth: {
      userData: {
        tenant: {
          type: string
        }
      }
    }
  }) => state.auth.userData.tenant.type);
  // @ts-expect-error FIXME
  const loadAllSellersOptions = getOptionsFetcher(loadAllSellersConfig());
  const loadAllPickupLocationsAsyncOptions = getOptionsFetcher(loadAllPickupLocationsAsyncConfig() as any);
  // @ts-expect-error FIXME
  const loadSellerSalesChannelOptions = getOptionsFetcher(loadSellerSalesChannelConfig);
  const loadShippingPartnerOptions = getOptionsFetcher(loadShippingPartnerConfig(LOAD_SHIPPING_PARTNER_CONFIG.ID) as any);

  const ALL_SHIPMENTS_ORDER_FILTER_DATA = {
    sellers:{
      filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey,
      displayName: t(CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.displayName),
      type: DROPDOWN_TYPES.SINGLE_DROPDOWN_WITH_TITLE,
      omit: (tenantType === TENANT_TYPE.SELF_USAGE || !isSellerViewAllowed),
      props: {
        isAsync: true,
        key:  searchParams.get('tab') || 'not-defined',
        id: filter.tab || ORDERS_TABS.NEW_ORDERS,
        isClearable: !!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey]?.value,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        value: (!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey]?.value) ? {label: 'All', value: ''} : filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey],
        selectOptionHandler: (value: any) => filterHandler({
          filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey, filterValue: value
        }),
        // cacheUniqs: searchParams.get('tab'),
        loadOptionsHandler: loadAllSellersOptions,
      }
    },
    sales_channel: {
      filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SALES_CHANNEL.filterKey,
      displayName: t(CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SALES_CHANNEL.displayName),
      type: DROPDOWN_TYPES.SINGLE_DROPDOWN_WITH_TITLE,
      omit: searchParams.get('current_view') === SHIPMENT_ORDERS_VIEW_OPTIONS.PACKAGE.tab_name,
      props: {
        isAsync: true,
        key: searchParams.get('tab') || 'not-defined',
        id: filter.tab || ORDERS_TABS.NEW_ORDERS,
        isClearable: !!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SALES_CHANNEL.filterKey]?.length,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        value: (!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SALES_CHANNEL.filterKey]?.value) ? [{ label: 'All', value: '' }] : filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SALES_CHANNEL.filterKey],
        selectOptionHandler: (value: any) => filterHandler({
          filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SALES_CHANNEL.filterKey, filterValue: value
        }),
        isDisabled: (tenantType === TENANT_TYPE.SELF_USAGE || !isSellerViewAllowed) ? false :!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey]?.value,
        loadOptionsHandler: loadSellerSalesChannelOptions,
        additional: {
          type:INTEGRATION_TYPES.SHIPPING_ONLY_INTEGRATION.value,
          seller: filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey]?.value || selfTenantSellerId
        },
        tooltipId: (((!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey]?.value) && (tenantType === TENANT_TYPE.FULFILLMENT_CENTRE)) && 'seller_sales_channel_id'),
        tooltipText: (((!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey]?.value) && (tenantType === TENANT_TYPE.FULFILLMENT_CENTRE)) && 'Select a seller first')
      }
    },
    orderStatus: {
      filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_STATUS.filterKey,
      displayName: t(CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_STATUS.displayName),
      type: DROPDOWN_TYPES.SINGLE_DROPDOWN_WITH_TITLE,
      omit: ((searchParams.get('current_view') === SHIPMENT_ORDERS_VIEW_OPTIONS.PACKAGE.tab_name) || (searchParams.get('current_tab') !== ORDERS_TABS.ALL.id)),
      props: {
        isAsync: true,
        key:  searchParams.get('tab') || 'not-defined',
        id: filter.tab || ORDERS_TABS.NEW_ORDERS,
        isClearable: !!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_STATUS.filterKey]?.value,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        value: (!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_STATUS.filterKey]?.value) ? {label: 'All', value: ''} : filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_STATUS.filterKey],
        selectOptionHandler: (value: any) => filterHandler({
          filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_STATUS.filterKey, filterValue: value
        }),
        // cacheUniqs: searchParams.get('tab'),
        options: Object.values(ORDER_STATUS)
      }
    },
    orderSource: {
      filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_SOURCE.filterKey,
      displayName: t(CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_SOURCE.displayName),
      type: DROPDOWN_TYPES.SINGLE_DROPDOWN_WITH_TITLE,
      omit: searchParams.get('current_view') === SHIPMENT_ORDERS_VIEW_OPTIONS.PACKAGE.tab_name,
      props: {
        isAsync: true,
        key:  searchParams.get('tab') || 'not-defined',
        id: filter.tab || ORDERS_TABS.NEW_ORDERS,
        isClearable: !!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_SOURCE.filterKey]?.value,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        value: (!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_SOURCE.filterKey]?.value) ? {label: 'All', value: ''} : filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_SOURCE.filterKey],
        selectOptionHandler: (value: any) => filterHandler({
          filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.ORDER_SOURCE.filterKey, filterValue: value
        }),
        // cacheUniqs: searchParams.get('tab'),
        options: [
          { value: 'dashboard', label: 'Manual' },
          { value: 'sales_channel', label: 'Sales Channel' }
        ]
      }
    },
    shippingCity: {
      filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_CITY.filterKey,
      displayName: t(CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_CITY.displayName),
      type: DROPDOWN_TYPES.SINGLE_DROPDOWN_WITH_TITLE,
      props: {
        isAsync: true,
        key:  searchParams.get('tab') || 'not-defined',
        id: filter.tab || ORDERS_TABS.NEW_ORDERS,
        isClearable: !!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_CITY.filterKey]?.value,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        value: (!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_CITY.filterKey]?.value) ? {label: 'All', value: ''} : filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_CITY.filterKey],
        selectOptionHandler: (value: any) => filterHandler({
          filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_CITY.filterKey, filterValue: value
        }),
        loadOptionsHandler: getAllCitiesAsyncData,
      }
    },
    shippingAccount: {
      filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_ACCOUNT.filterKey,
      displayName: t(CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_ACCOUNT.displayName),
      type: DROPDOWN_TYPES.SINGLE_DROPDOWN_WITH_TITLE,
      omit: ((searchParams.get('current_view') === SHIPMENT_ORDERS_VIEW_OPTIONS.ORDER.tab_name) || (searchParams.get('current_tab') === PACKAGE_TABS.NEW_PACKAGES.id || searchParams.get('current_tab') === PACKAGE_TABS.AT_HUB.id || !searchParams.get('current_tab'))),
      props: {
        isAsync: true,
        key:  searchParams.get('tab') || 'not-defined',
        id: filter.tab || ORDERS_TABS.NEW_ORDERS,
        isClearable: !!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_ACCOUNT.filterKey]?.value,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        value: (!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_ACCOUNT.filterKey]?.value) ? {label: 'All', value: ''} : filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_ACCOUNT.filterKey],
        selectOptionHandler: (value: any) => filterHandler({
          filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SHIPPING_ACCOUNT.filterKey, filterValue: value
        }),
        loadOptionsHandler: loadShippingPartnerOptions,
      }
    },
    location: {
      filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.LOCATION.filterKey,
      displayName: t(CREATE_SHIPMENTS_ORDER_FILTER_METADATA.LOCATION.displayName),
      type: DROPDOWN_TYPES.SINGLE_DROPDOWN_WITH_TITLE,
      props: {
        isAsync: true,
        key:  searchParams.get('tab') || 'not-defined',
        id: filter.tab || ORDERS_TABS.NEW_ORDERS,
        isClearable: !!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.LOCATION.filterKey]?.value,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        value: (!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.LOCATION.filterKey]?.value) ? {label: 'All', value: ''} : filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.LOCATION.filterKey],
        selectOptionHandler: (value: any) => filterHandler({
          filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.LOCATION.filterKey, filterValue: value
        }),
        loadOptionsHandler: loadAllPickupLocationsAsyncOptions,
        additional: {
          seller_id: filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey]?.value || selfTenantSellerId
        },
        isDisabled: (tenantType === TENANT_TYPE.SELF_USAGE || !isSellerViewAllowed) ? false :!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey]?.value,
        tooltipId: (((!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey]?.value) && (tenantType === TENANT_TYPE.FULFILLMENT_CENTRE)) && 'pickup_address'),
        tooltipText: (((!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey]?.value) && (tenantType === TENANT_TYPE.FULFILLMENT_CENTRE)) && 'Select a seller first')
      }
    },
    packageStatus: {
      filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.PACKAGE_STATUS.filterKey,
      displayName: t(CREATE_SHIPMENTS_ORDER_FILTER_METADATA.PACKAGE_STATUS.displayName),
      type: DROPDOWN_TYPES.SINGLE_DROPDOWN_WITH_TITLE,
      omit: ((searchParams.get('current_view') === SHIPMENT_ORDERS_VIEW_OPTIONS.ORDER.tab_name) || (searchParams.get('current_tab') !== PACKAGE_TABS.ALL.id)),
      props: {
        isAsync: true,
        key:  searchParams.get('tab') || 'not-defined',
        id: filter.tab || ORDERS_TABS.NEW_ORDERS,
        isClearable: !!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.PACKAGE_STATUS.filterKey]?.value,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        value: (!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.PACKAGE_STATUS.filterKey]?.value) ? {label: 'All', value: ''} : filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.PACKAGE_STATUS.filterKey],
        selectOptionHandler: (value: any) => filterHandler({
          filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.PACKAGE_STATUS.filterKey, filterValue: value
        }),
        // cacheUniqs: searchParams.get('tab'),
        options: Object.values(PACKAGE_STATUS)
      }
    },
    createdAtDateRange: {
      isDateDilter: true,
      filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey,
      displayName: t(CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.displayName),
      props: {
        key:  searchParams.get('tab') || 'not-defined',
        id: filter.tab || ORDERS_TABS.NEW_ORDERS,
        value: (!filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey]?.value) ? null: [filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey].value.start, filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey].value.end],
        onChange: (value: any) => filterHandler({
          filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey, filterValue: value?.start ? {label: `${value.start} to ${value.end}`, value: value} : null
        })
      }
    }
  };

  const filters = useMemo(() => getFiltersBasedOnOmit(ALL_SHIPMENTS_ORDER_FILTER_DATA), [selectedTabParam, filter, searchParams]); 
  
  return (
    <>
      {
        filters.map((singleFilter) => (
          <div key={singleFilter.filterKey}>
            {singleFilter.isDateDilter ? <DateFilter
              {...singleFilter.props}
              placeholder={t(singleFilter.displayName)}
            /> :
              <FiltersMapper
                type={singleFilter.type} 
                filterKey={singleFilter.filterKey} 
                displayName={singleFilter.displayName}
                props={singleFilter.props}
              />}
          </div>
        ))
      }
    </>
  );
};

export default ShipmentsOrderFilters;