import { DEFAULT_COUNTRY_CODE } from '@src/App.constants';
import OrderIcon from '@src/assets/images/omni-ship/order-icon';
import PackageIcon from '@src/assets/images/omni-ship/package-icon';
import FirstMile from './pages/all-shipments/pages/first-mile';
import NewOrders from './pages/orders/components/orders-view/new-orders';

export const PATH_NAME = {
  ORDERS: '/omniship/shipment-orders',
  CREATE_SHIPMENT_ORDERS:'/omniship/create-shipment-order',
  ALL_SHIPMENTS: '/omniship/all-shipments',
  PICKUP_ADDRESS:'/omniship/pickup-address'
};

export const SHIPMENT_ORDER_ENTITY = {
  SHIPMENT_ORDER_DETAILS: 'shipment_order_details',
  SHIPMENT_DETAILS: 'shipment_details'
};

export const ORDER_CREATION_TYPE = {
  BULK: { value: 'bulk', label: 'Bulk' },
  SINGLE: { value: 'single', label: 'Single' },
};

export const PRODUCT_TYPE = {
  GOODS: { value: 'goods', label: 'Goods' },
  SERVICES: { value: 'services', label: 'Services' }
};

export const ORDER_TYPE = {
  FORWARD: { value: 'forward', label: 'Forward' },
  REVERSE: { value: 'reverse', label: 'Reverse' }
};

export const SERVICE_TYPE = {
  NORMAL: { value: 'normal', label: 'Normal' },
  EXPRESS: { value: 'express', label: 'Express' },
  SCHEDULE: { value: 'schedule', label: 'Scheduled' }
};

export const PAYMENT_METHOD = {
  PREPAID: { value: 'prepaid', label: 'Prepaid' },
  POSTPAID: { value: 'postpaid', label: 'Postpaid' }
};

export const ORDER_WEIGHT_UNIT = {
  KG: { value: 'kg', label: 'kg' },
  G: { value: 'gm', label: 'gm' },
};

export const ORDER_DIMENSIONS_UNIT = {
  CM: { value: 'cm', label: 'cm' },
  M: { value: 'm', label: 'm' },
};

export const ORDERS_TABS = {
  NEW_ORDERS: { id: 'new', name: 'New Orders', component: NewOrders },
  READY_TO_SHIP: { id: 'ready_to_ship', name: 'Ready To Ship', component: NewOrders },
  IN_TRANSIT: { id: 'in_transit', name: 'In-Transit', component: NewOrders },
  AT_HUB: { id: 'at_hub', name: 'At Hub', component: NewOrders },
  OUT_FOR_DELIVERY: { id: 'out_for_delivery', name: 'Out For Delivery', component: NewOrders },
  DELIVERED: { id: 'delivered', name: 'Delivered', component: NewOrders },
  ALL: { id: 'all', name: 'All', component: NewOrders }
};

export const PACKAGE_TABS = {
  NEW_PACKAGES: { id: 'new', name: 'New Packages', component: NewOrders },
  READY_TO_SHIP: { id: 'ready_to_ship', name: 'Ready To Ship', component: NewOrders },
  IN_TRANSIT: { id: 'in_transit', name: 'In-Transit', component: NewOrders },
  AT_HUB: { id: 'at_hub', name: 'At Hub', component: NewOrders },
  OUT_FOR_DELIVERY: { id: 'out_for_delivery', name: 'Out For Delivery', component: NewOrders },
  DELIVERED: { id: 'delivered', name: 'Delivered', component: NewOrders },
  ALL: { id: 'all', name: 'All', component: NewOrders }
};

export const SHIPMENT_ORDERS_VIEW_OPTIONS = {
  ORDER: { id: 1, text: 'Order', icon: OrderIcon, tab_name: 'order' },
  PACKAGE: { id: 2, text: 'Package', icon: PackageIcon, tab_name: 'package' }
} as const;

export const PACKAGES_DEFAULT_VALUE = [
  {
    dueAmount: '',
    length: '',
    width: '',
    height: '',
    dimensionUnit: ORDER_DIMENSIONS_UNIT.CM,
    packageValue: '',
    addOrderItem: false,
    order_items: [],
    ordered_weight: {
      value: '',
      unit: ''
    },
    barcode_search: true,
    name_search: true,
    seller_sku_code_search: true,
  }
];

export const CREATE_ORDER_FIELDS_NAME = {
  ORDER_CREATION_TYPE: { label: 'Order Creation Type', value: 'order_creation_type' },
  SELLER: { label: 'Seller', value: 'seller' },
  PRODUCT_TYPE: { label: 'Product Type', value: 'product_type' },
  ORDER_TYPE: { label: 'Order Type', value: 'order_type' },
  SERVICE_TYPE: { label: 'Service Type', value: 'service_type' },
  PAYMENT_TYPE: { label: 'Payment Method', value: 'payment_type' },
  ORDER_AMOUNT: { label: 'Order Amount', value: 'order_amount' },
  ORDER_WEIGHT: { label: 'Order Weight', value: 'order_weight' },
  ORDER_WEIGHT_UNIT: { label: 'Unit', value: 'order_weight_unit' },
  CURRENCY: { label: 'Currency', value: 'currency' },
  REFERENCE_ORDER_ID: { label: 'Reference Order Id', value: 'reference_order_id' },
  SEARCH_PICKUP_LOCATION: { label: 'Pickup Address', value: 'search_pickup_location' },
  SEARCH_CUSTOMER: { label: 'Search Customer', value: 'search_customer' },
  NUMBER_OF_PACKAGES: { label: 'Number of Packages', value: 'number_of_packages' },
  ALL_PACKAGES_ARE_SAME: { label: 'All packages have same dimension', value: 'all_packages_are_same' },
  PACKAGES: { label: 'Packages', value: 'packages' },
  TOTAL_AMOUNT: { label: 'Grand Total', value: 'total_amount' },
  TOTAL_TAXES: { label: 'Total Taxes', value: 'total_taxes' },
  PICKUP_LOCATION: { label: 'Pickup Location', value: 'pickup_location' },
  CUSTOMER_DETAILS: { label: 'Customer Details', value: 'customer_details' },
  CUSTOMER_ADDRESS: { label: 'Customer Address', value: 'customer_address' },
};

export const DEFAULT_VALUE_FOR_CREATE_ORDER_FIELDS = {
  [CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value]: ORDER_CREATION_TYPE.BULK,
  [CREATE_ORDER_FIELDS_NAME.SELLER.value]: null,
  [CREATE_ORDER_FIELDS_NAME.PRODUCT_TYPE.value]: null,
  [CREATE_ORDER_FIELDS_NAME.ORDER_TYPE.value]: null,
  [CREATE_ORDER_FIELDS_NAME.SERVICE_TYPE.value]: null,
  [CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.value]: null,
  [CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value]: '',
  [CREATE_ORDER_FIELDS_NAME.CURRENCY.value]: '',
  [CREATE_ORDER_FIELDS_NAME.REFERENCE_ORDER_ID.value]: '',
  [CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value]: null,
  [CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value]: null,
  [CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value]: 1,
  [CREATE_ORDER_FIELDS_NAME.ALL_PACKAGES_ARE_SAME.value]: false,
  [CREATE_ORDER_FIELDS_NAME.PACKAGES.value]: PACKAGES_DEFAULT_VALUE,
  [CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT.value]: '',
  [CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT_UNIT.value]: ORDER_WEIGHT_UNIT.KG,
  [CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value]: 0,
  [CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value]: {},
  [CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value]: {},
  [CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]: null
};

export const PICKUP_LOCATION_FIELDS_NAME = {
  PICKUP_LOCATION_NAME: 'pickup_location_name',
  ADDRESS_LINE_1: 'address_line_1',
  ADDRESS_LINE_2: 'address_line_2',
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
  ZIP_CODE: 'zip_code',
  CONTACT_DETAILS: 'contact_details',
  contact_details: {
    contactNumber: 'contact_details.contactNumber',
    countryCode: 'contact_details.countryCode'
  },
  PICKUP_LOCATION_PRE_SELECTED: 'pickup_location_pre_selected'
};

export const DEFAULT_VALUE_FOR_PICKUP_LOCATION_FIELDS = {
  [PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_NAME]: '',
  [PICKUP_LOCATION_FIELDS_NAME.ADDRESS_LINE_1]: '',
  [PICKUP_LOCATION_FIELDS_NAME.ADDRESS_LINE_2]: '',
  [PICKUP_LOCATION_FIELDS_NAME.COUNTRY]: null,
  [PICKUP_LOCATION_FIELDS_NAME.STATE]: null,
  [PICKUP_LOCATION_FIELDS_NAME.CITY]: null,
  [PICKUP_LOCATION_FIELDS_NAME.ZIP_CODE]: '',
  [PICKUP_LOCATION_FIELDS_NAME.contact_details.contactNumber]: '',
  [PICKUP_LOCATION_FIELDS_NAME.contact_details.countryCode]: DEFAULT_COUNTRY_CODE,
  [PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_PRE_SELECTED]: true
};

export const CREATE_CUSTOMER_FIELDS_NAME = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  ADDRESS_LINE_1: 'address_line_1',
  ADDRESS_LINE_2: 'address_line_2',
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
  ZIP_CODE: 'zip_code',
  CONTACT_DETAILS: 'contact_details',
  contact_details: {
    contactNumber: 'contact_details.contactNumber',
    countryCode: 'contact_details.countryCode'
  },
  CUSTOMER_PRE_SELECTED: 'customer_pre_selected',
  ADDRESS: 'aaddress'
};

export const CREATE_CUSTOMER_FIELDS_NAME_2 = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  COUNTRY_CODE: 'country_code',
  CONTACT_NUMBER: 'contact_number',
  ADDRESS: 'address',
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
  PINCODE: 'pincode'
} as const;

export const DEFAULT_VALUE_FOR_CREATE_CUSTOMER_FIELDS = {
  [CREATE_CUSTOMER_FIELDS_NAME.FIRST_NAME]: '',
  [CREATE_CUSTOMER_FIELDS_NAME.LAST_NAME]: '',
  [CREATE_CUSTOMER_FIELDS_NAME.EMAIL]: '',
  [CREATE_CUSTOMER_FIELDS_NAME.ADDRESS_LINE_1]: '',
  [CREATE_CUSTOMER_FIELDS_NAME.ADDRESS_LINE_2]: '',
  [CREATE_CUSTOMER_FIELDS_NAME.COUNTRY]: null,
  [CREATE_CUSTOMER_FIELDS_NAME.STATE]: null,
  [CREATE_CUSTOMER_FIELDS_NAME.CITY]: null,
  [CREATE_CUSTOMER_FIELDS_NAME.ZIP_CODE]: '',
  [CREATE_CUSTOMER_FIELDS_NAME.contact_details.contactNumber]: '',
  [CREATE_CUSTOMER_FIELDS_NAME.contact_details.countryCode]: DEFAULT_COUNTRY_CODE,
  [CREATE_CUSTOMER_FIELDS_NAME.CUSTOMER_PRE_SELECTED]: true
};

export const CREATE_PACKAGE_FIELDS_NAME = {
  LENGTH: 'length',
  WIDTH: 'width',
  HEIGHT: 'height',
  WEIGHT: 'weight',
  UNIT_OF_DIMENSION: 'unit_of_dimension',
  UNIT_OF_WEIGHT: 'unit_of_weight',
  AMOUNT: 'amount',
  DELIVERY_NOTE: 'delivery_note',
};

export const DEFAULT_VALUE_FOR_CREATE_PACKAGE_FIELDS = {
  [CREATE_PACKAGE_FIELDS_NAME.LENGTH]: '',
  [CREATE_PACKAGE_FIELDS_NAME.WIDTH]: '',
  [CREATE_PACKAGE_FIELDS_NAME.HEIGHT]: '',
  [CREATE_PACKAGE_FIELDS_NAME.WEIGHT]: '',
  [CREATE_PACKAGE_FIELDS_NAME.UNIT_OF_DIMENSION]: '',
  [CREATE_PACKAGE_FIELDS_NAME.UNIT_OF_WEIGHT]: '',
  [CREATE_PACKAGE_FIELDS_NAME.AMOUNT]: '',
  [CREATE_PACKAGE_FIELDS_NAME.DELIVERY_NOTE]: ''
}; 

export const SHIPMENTS_STAGES_TABS = {
  FIRST_MILE: { id: 'first_mile', name: 'First Mile', component: FirstMile },
  MID_MILE: { id: 'mid_mile', name: 'Mid Mile', component: NewOrders },
  LAST_MILE: { id: 'last_mile', name: 'Last Mile', component: NewOrders }
};

export const PAYMENT_TYPE_OPTIONS = {
  COD: { label: 'COD', value: 'cash', backgroundColor: 'bg-dark-2', textColor: 'text-dark' },
  CARD_PAYMENT: {label: 'Card', value: 'card_payment', backgroundColor: 'bg-dark-2', textColor: 'text-dark'},
  NET_BANKING_PAYMENT: {label: 'Net Banking', value: 'net_banking_payment', backgroundColor: 'bg-dark-2', textColor: 'text-dark'},
  OTHER_PAYMENT_METHOD: {label: 'Other', value: 'other_payment_method', backgroundColor: 'bg-dark-2', textColor: 'text-dark'}
};

export const CREATE_SHIPMENTS_ORDER_FILTER_METADATA = {
  SELLERS: { filterKey: 'seller_id', displayName: 'Sellers' },
  ORDER_SOURCE: {filterKey: 'source', displayName: 'Order Source'},
  HUB: {filterKey: 'hub', displayName: 'Hub'},
  PICKUP_CITY: { filterKey: 'pickup-city', displayName: 'Pickup City' },
  SHIPPING_CITY: {filterKey: 'receiver_address_city', displayName: 'Delivery City'},
  PACKAGE_STATUS: { filterKey: 'status', displayName: 'Status' },
  SALES_CHANNEL: { filterKey: 'seller_sales_channel_id', displayName: 'Sales Channel' },
  LOCATION: { filterKey: 'location', displayName: 'Location' },
  PAYMENT_METHOD: { filterKey: 'payment_type', displayName: 'Payment Method' },
  ORDER_STATUS: { filterKey: 'status', displayName: 'Status' },
  CREATED_AT: { filterKey: 'created_at', displayName: 'Select Created Date Range' },
  SHIPPING_ACCOUNT: { filterKey: 'shipping_partner_id', displayName: 'Shipping Partner' },
};

export const REDIRECTION_SHIPMENT_ORDERS_BANNER = {
  NEW_ORDERS: {name: 'New Orders', id: 'new-orders'},
  READY_TO_SHIP: {name: 'Ready To Ship', id: 'ready-to-ship'},
  AT_HUB: {name: 'At Hub', id: 'at-hub'},
  IN_TRANSIT_ORDERS: {name: 'In-Transit Orders', id: 'in-transit-order'}
};

export const EDIT_SHIPPING_DETAILS_MODAL_TYPE = {
  CUSTOMER_DETAILS: {id:1, title:'Customer Details', value:'customer', key:'CUSTOMER_DETAILS'},
  SHIPPING_ADDRESS: {id:2, title:'Shipping Address', value:'receiver_address', key:'SHIPPING_ADDRESS'},
  // RECEIVER_DETAILS: {id:2, title:'Receiver\'s Details', value:'shipping_details', key:'RECEIVER_DETAILS'},
  SHIPPING_DETAILS: {id:3, title:'Shipping Details', value:'shipping_details', key:'SHIPPING_DETAILS'},
  RECEPIENT_DETAILS: {id:4, title:'Recepient Details', value:'customer_details', key:'RECEPIENT_DETAILS'},
} as const;

export const ORDER_STATUS = {
  NEW: { label: 'New', value: 'new'},
  PROCESSING: { label: 'Processing', value: 'processing'},
  DELIVERED: { label: 'Delivered', value: 'delivered'},
  CANCELLED:{ label: 'Cancelled', value: 'cancelled'},
};

export const PACKAGE_STATUS = {
  NEW: { label: 'New', value: 'new' },
  READY_TO_SHIP: { label: 'Ready to Ship', value: 'ready_to_ship' },
  SHIPPED: { label: 'Shipped', value: 'shipped' },
  IN_TRANSIT: { label: 'In Transit', value: 'in_transit' },
  OUT_FOR_DELIVERY: { label: 'Out for Delivery', value: 'out_for_delivery' },
  UNABLE_TO_DELIVER: { label: 'Unable to Deliver', value: 'unable_to_deliver' },
  REACHED_AT_HUB: { label: 'Reached at Hub', value: 'reached_at_hub' },
  RETURN_IN_PROGRESS: { label: 'Return in Progress', value: 'return_in_progress' },
  RETURNED: { label: 'Returned', value: 'returned' },
  DELIVERED: { label: 'Delivered', value: 'delivered' },
  CANCELLED: { label: 'Cancelled', value: 'cancelled' },
  LOST: { label: 'Lost', value: 'lost' },
};