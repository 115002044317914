import Button from '@src/@core/components/ui/button';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import InputField from '@src/@core/components/ui/input-field';
import RadioButton from '@src/@core/components/ui/radio-button';
import Select from '@src/@core/components/ui/select';
import SideSheet from '@src/@core/components/ui/sideSheet';
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer';
import { useApi } from '@src/configs/react-query/useApi';
import { axiosInstance } from '@src/network/AxiosInstance';
import { getApiUrl } from '@src/utility/Utils';
import StripForReasonNotAvailable from '@src/views/sales/components/reasonsConfigrationRequired/StripForReasonNotAvailable';
import { useFetchReasons } from '@src/views/sales/hooks/useFetchReasons';
import AdditionalCharges from '@src/views/sales/pending-actions/pages/ordersForApproval/additional-charges';
import TotalAmount from '@src/views/sales/pending-actions/pages/ordersForApproval/total-amount';
import { OMS_REASON_CONFIG } from '@src/views/settings/settings.constants';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { getRefundAdjustmentApi } from '../../returns.apis';
import { ADJUSTMENT_FEES_TYPE, MARK_AS_REFUND_FORM_FIELD_NAMES, REFUND_METHODS_CONFIG } from '../../returns.constants';
import { iMarkAsRefundFormValues, iReturnOrder } from '../../returns.type';
import { getReturnOrderDetail } from '../../store';
import RefundAdjustment from './refund-adjustment';

interface iMarkAsRefundProps {
  isOpen: boolean
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (value: boolean) => void
  triggerParentAction: () => void
  preloadData: iReturnOrder
}

const MarkAsRefund: React.FC<iMarkAsRefundProps> = (props) => {
  const { isOpen, setIsOpen, preloadData } = props;
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currency = preloadData.invoice.currency;

  const formDefaultValues = {
    [MARK_AS_REFUND_FORM_FIELD_NAMES.IS_REFUND_ADJUSTMENT_EDIT_MODE]: false,
    [MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT]: '',
    [MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_REASON]: null,
    [MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_METHOD]: REFUND_METHODS_CONFIG.ONLINE.value,
    [MARK_AS_REFUND_FORM_FIELD_NAMES.PAYMENT_REFERENCE_NUMBER]: ''
  };

  const markAsRefundForm = useForm<iMarkAsRefundFormValues>({
    defaultValues: formDefaultValues,
    mode: 'onChange',
  });
  
  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = markAsRefundForm;
  
  const totalAdditionalCharges = useMemo(() => {
    return preloadData.invoice.additional_charges
      .filter(charge => charge.is_applied)
      .reduce((total, charge) => total + charge.cost, 0);
  }, [preloadData.invoice.additional_charges]);

  const totalRefundAmount = useMemo(() => {
    const refundAdjustmentAmount = watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT) || 0;
    return preloadData.invoice.items_total - totalAdditionalCharges + (+refundAdjustmentAmount);
  }, [preloadData.invoice.items_total, totalAdditionalCharges, watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT)]);

  const { reasons } = useFetchReasons({
    isModalOpen: isOpen,
    entity_type: OMS_REASON_CONFIG.refund_adjustment.key,
  });

  const handleClose = () => {
    setIsOpen(false);
    reset(formDefaultValues);
  };

  // queries
  //@ts-expect-error fix
  const { mutate: handleMarkAsRefund, isPending: markAsRefundLoading } = useApi({
    isMutation:true,
    apiKey: [...getRefundAdjustmentApi.apiKey],
    apiFn: (data:iMarkAsRefundFormValues) => {
      const body = {
        adjustment_fees: [
          {
            type: ADJUSTMENT_FEES_TYPE.REFUND.value,
            amount: +data.refund_adjustment_amount || 0,
            reason: data.refund_adjustment_reason?.value
          }
        ],
        refund_details: {
          refund_method: data.refund_method,
          payment_reference_number: data.payment_reference_number
        }
      };
      return axiosInstance.put(getApiUrl(getRefundAdjustmentApi.url, { returnOrderId: preloadData.id }), body);
    },
    onSuccess: ({ data }: { data: { message: string } }) => {
      CustomToast(data.message, { my_type: 'success', audioRequired: false });
      //@ts-expect-error fix
      dispatch(getReturnOrderDetail(preloadData.id));
      handleClose();
    }
  });

  return (
    /* @ts-expect-error fix */
    <SideSheet
      isOpen={isOpen}
      toggle={handleClose}
      size='sm'
      contentClassName='p-0 create-sorting-rules-modal'
      modalClassName='modal-slide-in create-edit-rules-modal'
      title='Mark as Refunded'
      onClosed={handleClose}
    >
      <PerfectScrollbar className='mb-40px pb-40px'>
        <form className='d-flex flex-column gap-24px p-24px'>
          <div className='d-flex flex-column gap-22px p-24px border rounded-12px'>
            <TotalAmount 
              itemsTotal={preloadData.invoice.items_total.toFixed(2)} 
              subtotal={preloadData.invoice.subtotal} 
              tax={preloadData.invoice.tax} 
              currency={currency} 
            />

            <AdditionalCharges 
              additionalCharges={preloadData.invoice.additional_charges} 
              currency={currency} 
            />

            <RefundAdjustment 
              markAsRefundForm={markAsRefundForm} 
              itemsTotal={preloadData.invoice.items_total} 
              totalAdditionalCharges={totalAdditionalCharges}
              currency={currency}
            />

            <div className='flex-center-between txt-h3-rg text-dark border-top pt-22px'>
              <span>{t('Final Refund Amount')}</span>
              <span>{totalRefundAmount.toFixed(2)} {currency}</span>
            </div>
          </div>

          {
            watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT) ? (<div className='d-flex flex-column gap-8px'>
              <Controller
                name={MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_REASON}
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    /* @ts-expect-error fix */
                    errors={errors}
                    options={reasons}
                    isAsync={false}
                    isRequired
                    label='Reason For Refund Adjustment'
                    isDisabled={reasons?.length === 0}
                  />
                )}
              />
              {reasons?.length === 0 && <StripForReasonNotAvailable entity_type={OMS_REASON_CONFIG.refund_adjustment.key} />}
            </div>) : null
          }

          <div className='d-flex flex-column gap-18px'>
            <span className='txt-body-md text-dark'>{t('Select Refund Method')}</span>
            <div className='d-flex flex-column gap-10px'>
              {Object.values(REFUND_METHODS_CONFIG).map((method) => (
                <RadioButton
                  key={method.value}
                  /* @ts-expect-error fix */
                  id={method.value}
                  name={method.name}
                  label={
                    <>
                      <span className='txt-sub-md'>{method.label}</span> - {method.description}
                    </>
                  }
                  value={method.value}
                  checked={watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_METHOD) === method.value}
                  onChange={() => setValue(method.name, method.value)}
                />
              ))}
            </div>
          </div>
          
          <InputField
            errors={errors}
            {...register(MARK_AS_REFUND_FORM_FIELD_NAMES.PAYMENT_REFERENCE_NUMBER)}
            /* @ts-expect-error fix */
            isClearable
            label='Payment Reference Number'
          />
        </form>
      </PerfectScrollbar>
      <SidesheetFooter className='gap-10px'>
        <Button
          type='button'
          ofStyle='outlined'
          onClick={handleClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          type='button'
          onClick={handleSubmit(handleMarkAsRefund)}
          loading={markAsRefundLoading}
          disabled={watch(MARK_AS_REFUND_FORM_FIELD_NAMES.IS_REFUND_ADJUSTMENT_EDIT_MODE)}
        >
          {t('Confirm')}
        </Button>
      </SidesheetFooter>
    </SideSheet>
  );
};

export default MarkAsRefund;
