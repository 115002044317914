import Button from '@src/@core/components/ui/button'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { getApiUrl } from '@src/api.urls'
import { getOmsReasonsListApi } from '@src/app.apis'
import { SKU_TYPES } from '@src/App.constants'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import ImageUploaderReturnOrderProcessing from '@src/views/returns/components/imageUploader/ImageUploaderReturnOrderProcessing'
import StripForReasonNotAvailable from '@src/views/sales/components/reasonsConfigrationRequired/StripForReasonNotAvailable'
import { OMS_REASON_CONFIG } from '@src/views/settings/settings.constants'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ModalBody } from 'reactstrap'
import './styles.scss'

/**
 * SelectReturnRequestReasonsSideSheet
 *
 * @param modalData contains isOpen and selectedItem
 * @param toggle function to toggle the modal
 * @param handleReasonsPerItem function to handle reasons per item
 * @param reasonsPerItem object containing reasons per item
 * 
 * @description This component renders a side sheet with a quantity button for each reason
 * and a save button at the bottom. It fetches the reasons for the customer return entity type.
 * The total quantity selected is calculated using useMemo and is used to determine whether
 * to enable or disable the save button.
 * 
 * @returns JSX.Element
 */
const SelectReturnReasonsSideSheet = ({ modalData, toggle, skuTableData, entity_type, handleSelectReasons }) => {

  const { isOpen, selectedItem } = modalData

  const { t } = useTranslation()

  const [reasonsList, setReasonslist] = useState([])

  const totalQtySelected = useMemo(() => {
    return reasonsList.reduce((total, reason) => total + reason.quantity, 0)
  }, [reasonsList])

  const { isFetching } = useApi({
    apiKey: getOmsReasonsListApi.apiKey,
    apiFn: () => {
      const params = { entity_type: OMS_REASON_CONFIG[entity_type].key }
      const url = getApiUrl(getOmsReasonsListApi.url)
      return axiosInstance.get(url, { params })
    },
    enabled: !!isOpen,
    onSuccess: ({ data }) => {
      const reasonsObjWithQty = data?.[0].reasons.map(reason => {
        if (selectedItem?.seller_sku_type === SKU_TYPES.SIMPLE.key) {
          const reasonQty = skuTableData.find((item) => item.id === selectedItem.id).qc_fail_reasons?.find(reasons => reasons.reason === reason)
          return {
            reason,
            quantity: reasonQty?.quantity || 0,
            images: reasonQty?.images || []
          }
        } else {
          const reasonQty = skuTableData.find((item) => item.id === selectedItem.parentId)?.seller_sku.child_skus?.find((item) => item.id === selectedItem.id)?.qc_fail_reasons?.find(reasons => reasons.reason === reason)
          return {
            reason,
            quantity: reasonQty?.quantity || 0,
            images: reasonQty?.images || []
          }
        }
      });
      setReasonslist(reasonsObjWithQty);
    }
  })

  const handleModalOnClose = () => {
    setReasonslist([])
  }

  const handleQuantity = ({ reason, newQty, images }) => {
    setReasonslist(prevState =>
      prevState.map(item =>
        item.reason === reason ? { ...item, quantity: newQty, images } : item
      )
    );
  }

  const handleSave = () => {
    const reasonsToSave = reasonsList.filter(item => item.quantity > 0);
    handleSelectReasons({ selectedItem, reasons: reasonsToSave })
    toggle()
  }

  const renderList = () => {
    return reasonsList.length > 0 
      ? reasonsList.map((item, index) => (
        <div key={`${item.reason}-${index}`} className='flex-center-between d-flex'>
          <div className='txt-sub-rg text-dark'>
            {item.reason}
          </div>
          <div className='flex-center-start ms-1 gap-8px'>
            {item.quantity > 0 && 
                <ImageUploaderReturnOrderProcessing
                  images={item.images}
                  setImages={(images) => handleQuantity({ reason: item.reason, newQty: item.quantity, images })}
                />
            }
            <QuantityButton
              quantity={item.quantity}
              minQuantityEnabled={!totalQtySelected || item.quantity === 0}
              maxQuantityEnabled={totalQtySelected >= selectedItem.fail_quantity}
              increaseQuantity={() => handleQuantity({ reason: item.reason, newQty: item.quantity + 1, images: item.images })}
              decreaseQuantity={() => handleQuantity({ reason: item.reason, newQty: item.quantity - 1, images: item.images })}
            />
          </div>
        </div>
      )) 
      : <StripForReasonNotAvailable entity_type={entity_type} />;
  };  

  return (
    <SideSheet
      isOpen={isOpen}
      toggle={toggle}
      modalClassName='modal-slide-in select-return-request-reasons-side-sheet-wrapper'
      title='Reasons for Return Order Processing'
      subTextData={['Select Reason(s) and the item quantity for each reason.']}
      onClosed={handleModalOnClose}
    >

      <ModalBody className='modal-body p-0 m-0 py-24px'>
        <section className='d-flex gap-40px mb-24px px-24px'>
          <div className='d-flex flex-column gap-12px'>
            <p className='txt-body-md text-dark-6 m-0'>{t('Fail Qty')}</p>
            <p className='txt-h2-sb text-secondary m-0'>{selectedItem.fail_quantity}</p>
          </div>
          <div className='d-flex flex-column gap-12px'>
            <p className='txt-body-md text-dark-6 m-0'>{t('Items with Reason(s) Selected')}</p>
            <p className='txt-h2-sb text-secondary m-0'>{totalQtySelected}</p>
          </div>
        </section>
        <PerfectScrollbar className='px-24px'>
          {
            isFetching ? (
              <ComponentSpinner />
            ) : (
              <div className="d-flex flex-column gap-24px py-8px">
                {renderList()}
              </div>
            )
          }
        </PerfectScrollbar>
      </ModalBody>
      <SidesheetFooter>
        <div>
          <Button className='justify-content-center w-100' onClick={handleSave} disabled={totalQtySelected > selectedItem.return_quantity} >
            {t('Save')}
          </Button>
        </div>
      </SidesheetFooter>
    </SideSheet>
  )
}

export default SelectReturnReasonsSideSheet
