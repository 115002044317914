/* eslint-disable @typescript-eslint/no-explicit-any */
import { nanoid } from '@reduxjs/toolkit';
import InputField from '@src/@core/components/ui/input-field';
import MultiPurposeSelect from '@src/@core/components/ui/multi-purpose-select';
import SkuDetailsOption from '@src/@core/components/ui/multi-purpose-select/components/sku-details-option';
import QuantityButton from '@src/@core/components/ui/quantity-button';
import DropdownOptionsWithCTA from '@src/@core/components/ui/select/sub-components/DropdownOptionsWithCTA';
import SKUDetailsCol from '@src/@core/components/ui/sku-details';
import { CHOOSE_SKU_KEY } from '@src/views/sales/pending-actions/components/add-items-po/add-items-columns';
import { showSkuTypeTag, validateNumber } from '@src/views/sales/sales.utils';
import { fetchOrderSkus } from '@src/views/shipping-aggregator/shipping-aggregator.apis';
import { CREATE_ORDER_FIELDS_NAME } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import classNames from 'classnames';
import { Trash2 } from 'react-feather';
import { UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MenuProps } from 'react-select';

const getUniqueId = (id?: string) => {
  const uniqueIdentifier = nanoid();
  return id ? `${id}-${uniqueIdentifier}` : uniqueIdentifier;
};

const isChooseSkuRow = (row: {__key: string} | any): boolean => '__key' in row && row.__key === CHOOSE_SKU_KEY;

export const getCreateOrderTotalPriceAndWeight = ({ formState, watch, packageIndex, packageValue }: any) => {
  const packages = watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value) || [];

  let totalAmount = 0;
  let totalTaxes = 0;

  packages.map((pkg: any) => {
    return pkg.order_items.map((item: any) => {
      totalAmount += +item.total || 0;
      totalTaxes += +item.tax || 0;
    });
  });

  const orderItemsTotalAmount = packages.map((pkg: any) => {
    const total = pkg.order_items.reduce((sum: number, item: { total: number }) => sum + item.total, 0);
    return Math.round(total * 100) / 100;
  });

  console.log(orderItemsTotalAmount);
  formState.setValue(packageValue, orderItemsTotalAmount[packageIndex] || 0);

  return { totalAmount: Math.round(+totalAmount * 100) / 100, totalTaxes: +totalTaxes.toFixed(2), orderItemsTotalAmount: +orderItemsTotalAmount };
};

const useCreateShipmentsSkuTableCol = (props: any) => { 
  const {formState, skuItemsFieldsObject, id, packageIndex, packageName, packageValue, ctaConfig, nameFieldSearchName, barcodeFieldSearchName, sellerSkuCodeFieldSearchName} = props;
  const { t } = useTranslation();

  const uniqueId = getUniqueId(id);

  const {fields: skuItemsFieldArray, prepend, remove } = skuItemsFieldsObject;

  const deleteSku = (index: number) => {
    remove(index);
    const { totalAmount, totalTaxes } = getCreateOrderTotalPriceAndWeight({ formState, watch: formState.getValues, packageIndex, packageValue });
    formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value, totalAmount || 0);
    formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_TAXES.value, totalTaxes || 0);
  };

  const handleAddToSkuTable = (data: any) => {
    const sellingPrice = +(data.sku.selling_price_excluding_tax || 0).toFixed(2);
    const taxAmount = +(((data.sku.tax_percentage?.selling_price || 0) * +(data.sku.selling_price_excluding_tax)) / 100).toFixed(2);
    if (!skuItemsFieldArray.some((item: any) => item.sku.id === data.sku.id)) {
      const newItem: any = {
        sku: {
          id: data.sku.id,
          code: data.sku.seller_sku_code,
          name: data.sku.name,
          type: data.sku.product.type,
          images: data.sku.images,
          is_weighted: data.sku.is_weighted,
        },
        quantity: 1,
        unit_price: +sellingPrice.toFixed(2),
        selling_price: +sellingPrice,
        tax_percent: +data.sku.tax_percentage?.selling_price || 0,
        tax: Number((taxAmount).toFixed(2)) || 0,
        discount_percent: '',
        total: +((sellingPrice + taxAmount)).toFixed(2),
        ordered_weight: data.sku.is_weighted ? {
          value: +(data.sku.weight.value || 0)?.toFixed(2),
          unit: data.sku.uom
        } : null
      };
      prepend(newItem);

      const { totalAmount, totalTaxes } = getCreateOrderTotalPriceAndWeight({ formState, watch: formState.getValues, packageIndex, packageValue });
      formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value, totalAmount || 0);
      formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_TAXES.value, totalTaxes || 0);
    }
  };

  const handleDecreaseQty = (index: number) => {
    const quantity = formState.getValues(`${packageName}.${index}.quantity`) - 1;
    formState.setValue(`${packageName}.${index}.quantity`, quantity);
    const { total, taxAmount } = getTotalTaxAmountFromIndex(index, formState.watch, quantity);
    formState.setValue(`${packageName}.${index}.total`, +total || 0);
    formState.setValue(`${packageName}.${index}.tax`, +taxAmount || 0);

    const { totalAmount, totalTaxes } = getCreateOrderTotalPriceAndWeight({ formState, watch: formState.getValues, packageIndex, packageValue });
    formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value, totalAmount || 0);
    formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_TAXES.value, totalTaxes || 0);
  };

  const handleIncreaseQty = (index: number) => {
    const quantity = formState.getValues(`${packageName}.${index}.quantity`) + 1;
    formState.setValue(`${packageName}.${index}.quantity`, quantity);
    const { total, taxAmount } = getTotalTaxAmountFromIndex(index, formState.watch, quantity);
    formState.setValue(`${packageName}.${index}.total`, +total || 0);
    formState.setValue(`${packageName}.${index}.tax`, +taxAmount || 0);

    const { totalAmount, totalTaxes } = getCreateOrderTotalPriceAndWeight({ formState, watch: formState.getValues, packageIndex, packageValue });
    formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value, totalAmount || 0);
    formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_TAXES.value, totalTaxes || 0);
  };

  const handleQtyChange = (quantity: number, index: number) => {
    formState.setValue(`${packageName}.${index}.quantity`, quantity);
    const { total, taxAmount } = getTotalTaxAmountFromIndex(index, formState.watch, quantity);
    formState.setValue(`${packageName}.${index}.total`, +total || 0);
    formState.setValue(`${packageName}.${index}.tax`, +taxAmount || 0);

    const { totalAmount, totalTaxes } = getCreateOrderTotalPriceAndWeight({ formState, watch: formState.getValues, packageIndex, packageValue });
    formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value, totalAmount || 0);
    formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_TAXES.value, totalTaxes || 0);
  };

  const getTotalTaxAmountFromIndex = (index: number, watch: UseFormWatch<any>, qty?: number) => {
    const quantity = typeof qty === 'number' ? qty : +watch(`${packageName}.${index}.quantity`) || 0;
    const weight = +watch(`${packageName}.${index}.ordered_weight.value`) || 0;
    const taxPercent = +watch(`${packageName}.${index}.tax_percent`) || 0;
    const unitPrice = +watch(`${packageName}.${index}.selling_price`) || 0;
    const isWeighted = +watch(`${packageName}.${index}.sku.is_weighted`) || false;

    const qtyToConsider = isWeighted ? weight : quantity;

    const taxAmount = +(((taxPercent || 0) * (unitPrice || 0) * (qtyToConsider || 0)) / 100).toFixed(2);
    const total = +(((unitPrice || 0) * (qtyToConsider || 0)) + taxAmount).toFixed(2);
    
    return { taxAmount: taxAmount || '', total: total || '' };
  };

  return [
    {
      id: 1,
      key:'delete_sku',
      width:'44px',
      // @ts-expect-error fix
      cell: (row, index) => {
        return <div className='flex-center-center w-100'>
          <Trash2
            size={20}
            color={row.__key === CHOOSE_SKU_KEY ? 'var(--bs-dark-2)' : 'var(--bs-danger)'}
            className={classNames({
              'cursor-pointer' : row.__key !== CHOOSE_SKU_KEY
            })}
            onClick={() => row.__key === CHOOSE_SKU_KEY ? {} : deleteSku(index - 1)}
          />
        </div>;
      }
    },
    {
      id: 2,
      name: t('SKU Details'),
      Key:'sku_details',
      width:'300px',
      cell: (row: any) => {
        const isChooseSku = row.__key === CHOOSE_SKU_KEY;
        const sku = row.sku || {};
        
        const skuToShow = {
          ...sku,
          id: sku.id,
          name: sku.name,
          sku_code: sku.code,
          image: sku.images?.[0]?.default,
          selling_price: row.selling_price,
          retail_price: sku.selling_price,
        };

        const skuDetailColCompData = {
          isValidSku: true,
          id: skuToShow.id,
          image: {
            src: skuToShow.images?.[0]?.default,
            alt: skuToShow.name
          },
          details: {
            name: skuToShow.name,
            handleSkuDetails: () => (skuToShow.name ? () => {} : null),
            skuCode: skuToShow.sku_code,
            columnKey: 'sku_details'
          },
          customTag: {
            isCustomTagPresent: showSkuTypeTag(skuToShow.type),
            className:'text-info bg-info-light',
            title: showSkuTypeTag(skuToShow.type)
          }
        };

        return (
          <div id={uniqueId} className='w-100 flex-center-start text-truncate'>
            {isChooseSku ? (
              <MultiPurposeSelect
                filterOption={(option: any) => !skuItemsFieldArray.some((item: any) => item.sku?.id === option.data?.sku?.id)}
                loadOptions={fetchOrderSkus}
                menuPortalTarget={document.body}
                customComponents={{
                  //@ts-expect-error fix
                  Option: (props) => <SkuDetailsOption
                    {...props}
                    data={{
                      ...props.data.sku,
                      sku_code: props.data.sku.seller_sku_code,
                      image: props.data.sku.images?.[0]?.default,
                    }}
                  />,
                  Menu: (props: MenuProps<any, true>) => <DropdownOptionsWithCTA
                    isHeaderCTA
                    ctaConfig={ctaConfig(props)}
                    {...props}
                  />
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 1100 }),
                  menu: (base) => ({ ...base, minWidth: '300px' }),
                  menuList: (base) => ({ ...base, minWidth: '300px' }),
                  //@ts-expect-error fix
                  option: (base, state) => ({
                    ...base,
                    background: state.isFocused && !state.isSelected && 'var(--bs-primary-lighter)',
                    cursor: 'pointer'
                  })
                }}
                additional={{
                  page: 1,
                  sellerId: formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)?.value,
                  params: {
                    search_columns: [
                      formState.watch(nameFieldSearchName) ? 'name' : undefined,
                      formState.watch(barcodeFieldSearchName) ? 'barcode' : undefined,
                      formState.watch(sellerSkuCodeFieldSearchName) ? 'seller_sku_code' : undefined
                    ]
                  }
                }}
                cacheUniqs={[formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)?.value]}
                onChange={(data: any) => handleAddToSkuTable(data)}
                modifiedClassNames={{
                  container: () => 'cursor-pointer w-100',
                }}
              />
            ) : (
              <SKUDetailsCol skuDetailData={skuDetailColCompData} />
            )}
          </div>
        );
      }
    },
    {
      id: 3,
      name: <div className='flex-center-center w-100'>{t('Ordered Quantity')}</div>,
      Key:'ordered_qty',
      width:'180px',
      cell: (row: any | {__key: string}, index: number) => {
        const currentItemName = `${packageName}.${index - 1}.quantity` as const;
        const isWeighted = !isChooseSkuRow(row) && 'sku' in row && row.sku.is_weighted;

        return (
          <div className='w-100 flex-center-center'>
            <QuantityButton
              disabled={isChooseSkuRow(row) || isWeighted}
              decreaseQuantity={() => handleDecreaseQty(index - 1)}
              increaseQuantity={() => handleIncreaseQty(index - 1)}
              quantity={isChooseSkuRow(row) ? '' : formState.watch(currentItemName)}
              maxQuantityEnabled={false}
              minQuantityEnabled={formState.watch(currentItemName) === 1}
              {...(isChooseSkuRow(row) ? {} : formState.register(currentItemName))}
              onChange={(e: {target: {value: string}}) => handleQtyChange(+e.target.value, index - 1)}
            />
          </div>
        );
      }
    },
    {
      id: 4,
      name: <div className='flex-center-end w-100'>{t('Total Weight')}</div>,
      Key:'total_weight',
      minWidth:'150px',
      cell: (row: { __key: string } | any, index: number) => {
        const currentItemName = `${packageName}.${index - 1}.ordered_weight.value` as const;
        const currentItemUomName = `${packageName}.${index - 1}.ordered_weight.unit` as const;

        const uom = formState.watch(currentItemUomName);

        const isWeighted = !isChooseSkuRow(row) && 'sku' in row && row.sku?.is_weighted;

        return (
          <div className='w-100 flex-center-end input-wrapper-height-32px input-wrapper-text-end'>
            {isWeighted ? (
              <div className='d-flex align-items-center gap-4px'>
                <div className='d-flex align-content-center width-80-per'>
                  <InputField
                    placeholder={isChooseSkuRow(row) ? ' ' : 'Weight'}
                    //@ts-expect-error fix
                    onInput={(e) => validateNumber(e, () => formState.watch(currentItemName))}
                    disabled={isChooseSkuRow(row)}
                    {...(isChooseSkuRow(row) ? {} : formState.register(currentItemName, {
                      onChange: () => {
                        const { total, taxAmount } = getTotalTaxAmountFromIndex(index - 1, formState.watch);
                        formState.setValue(`${packageName}.${index - 1}.total`, +total || 0);
                        formState.setValue(`${packageName}.${index - 1}.tax`, +taxAmount || 0);

                        const { totalAmount, totalTaxes } = getCreateOrderTotalPriceAndWeight({ formState, watch: formState.getValues, packageIndex, packageValue });
                        formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value, totalAmount || 0);
                        formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_TAXES.value, totalTaxes || 0);
                      }
                    }))}
                  /> 
                </div>
                <div className='text-nowrap width-20-per text-truncate text-end'>
                  {uom || '-'}
                </div>
              </div>
            )
              : isChooseSkuRow(row) ? null : 'NA'}
          </div>
        );
      }
    },
    {
      id: 5,
      name: <div className='flex-center-end w-100'>{t('Unit Price (Tax excl.)')}</div>,
      Key:'unit_price',
      minWidth:'180px',
      //@ts-expect-error fix
      cell: (row, index) => {
        const currentItemName = `${packageName}.${index - 1}.selling_price` as const;
        return (
          <div className='w-100 flex-center-end input-wrapper-height-32px input-wrapper-text-end'>
            <InputField
              placeholder={isChooseSkuRow(row) ? ' ' : 'Unit Price'}
              //@ts-expect-error fix
              onInput={(e) => validateNumber(e, () => formState.watch(currentItemName))}
              disabled={row.__key === CHOOSE_SKU_KEY}
              {...(row.__key === CHOOSE_SKU_KEY ? {} : formState.register(currentItemName, {
                onChange: () => {
                  const { total, taxAmount } = getTotalTaxAmountFromIndex(index - 1, formState.watch);
                  formState.setValue(`${packageName}.${index - 1}.total`, +total || 0);
                  formState.setValue(`${packageName}.${index - 1}.tax`, +taxAmount || 0);

                  const { totalAmount, totalTaxes } = getCreateOrderTotalPriceAndWeight({ formState, watch: formState.getValues, packageIndex, packageValue });
                  formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value, totalAmount || 0);
                  formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_TAXES.value, totalTaxes || 0);
                }
              }))}
            />
          </div>
        );
      }
    },
    {
      id: 6,
      name: <div className='flex-center-end w-100'>{t('Tax (%)')}</div>,
      Key:'tax_percentage',
      minWidth:'150px',
      //@ts-expect-error fix
      cell: (row, index) => {
        const currentItemName = `${packageName}.${index - 1}.tax_percent` as const;
        return (
          <div className='w-100 flex-center-end input-wrapper-height-32px input-wrapper-text-end'>
            <InputField
              placeholder={isChooseSkuRow(row) ? ' ' : 'Tax %'}
              //@ts-expect-error fix
              onInput={(e) => {
                if (validateNumber(e, () => formState.watch(currentItemName))) {
                  if (+e.target.value > 100) e.target.value = '100';
                }
              }}
              disabled={row.__key === CHOOSE_SKU_KEY}
              {...(row.__key === CHOOSE_SKU_KEY ? {} : formState.register(currentItemName, {
                onChange: () => {
                  const { total, taxAmount } = getTotalTaxAmountFromIndex(index - 1, formState.watch);
                  formState.setValue(`${packageName}.${index - 1}.total`, +total || 0);
                  formState.setValue(`${packageName}.${index - 1}.tax`, +taxAmount || 0);

                  const { totalAmount, totalTaxes } = getCreateOrderTotalPriceAndWeight({ formState, watch: formState.getValues, packageIndex, packageValue });
                  formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value, totalAmount || 0);
                  formState.setValue(CREATE_ORDER_FIELDS_NAME.TOTAL_TAXES.value, totalTaxes || 0);
                }
              }))}
            />
          </div>
        );
      }
    },
    {
      id: 7,
      name: <div className='flex-center-end w-100'>{t('Tax Amount')}</div>,
      Key:'tax_amount',
      minWidth:'150px',
      cell: (row: { __key: string }, index: number) => {
        const { taxAmount } = getTotalTaxAmountFromIndex(index - 1, formState.watch);
        return (
          <div className='w-100 flex-center-end'>
            {row.__key === CHOOSE_SKU_KEY ? '' : taxAmount || '-'}
          </div>
        );
      }
    },
    {
      id: 8,
      name: <div className='flex-center-end w-100'>{t('Item Total')}</div>,
      Key:'item_total',
      minWidth:'150px',
      cell: (row: { __key: string }, index: number) => {
        const { total } = getTotalTaxAmountFromIndex(index - 1, formState.watch);
        return (
          <div className='w-100 flex-center-end'>
            {row.__key === CHOOSE_SKU_KEY ? '' : Number(total).toFixed(2) || '-'}
          </div>
        );
      }
    },
  ];
};

export default useCreateShipmentsSkuTableCol;

