import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import { KEYBOARD_KEYS } from '@src/App.constants'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import { PACKAGE_TYPE } from '@src/views/Purchases/purchase.constants'
import SkuTypeBadge from '@src/views/catalog/components/sku-master/skus-table/SkuTypeBadge'
import { GRN_TYPES, SKU_TYPE } from '@src/views/inventory/inventory.constants'
import {
  changeSkuWeight,
  clearGrnChildSkuData,
  clearScannedSkuDetails,
  clearSkuDetailUpdateStatus,
  handleBreakOpen,
  setBinDisabledGrnTableData,
  setGrnSkuDetails,
  setScannedSkuDetails,
  updateEnforcedFailUploadIds,
  updateGtinSkuDetails,
  updateWeightForSku
  // setGrnSkuDetails
} from '@src/views/inventory/store'
import { useEffect, useRef, useState } from 'react'
import { AlertTriangle, ChevronDown } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap'
import { BATCH_STATUS, SERIALISATION_OPTION } from '../../constant'
import NonSerializeMarkAsFail from '../non-serialize-mark-as-fail'
import SerializedMarkAsFail from '../serialized-mark-as-fail/serializedMarkAsFail'
import OpenedQtyModal from './OpenedQtyModal'
import AddDimensionModal from './addDimensionModal'
import AddSkuBarcodeModal from './addSkuBarcodeModal'
import EditSkuWeight from './editSkuWeight'
import OverReceiveItemModal from './overReceiveItemModal'
import SkuNotPartOfOrderModal from './skuNotPartOfOrderModal'

const SkuCard = ({ skuDetails, qty, setQty, remainingQty = false, setValue, grnType, watch, setError, scannedBarcode }) => {
  const {
    grnResponse, 
    latestSkuDetails, 
    grnChildSkuData, 
    selectedGlobalHubId, 
    displayGrnDetails, 
    enforcedFailUploadIds,
    enforcedFailReason,
    isShelfLifeBreached,
    isSkuDetailUpdated,
    configurations,
    isDimensionUpdateSuccess,
    tableData,
    returnGrnResponse,
    gtinSkuDetails
  } = useSelector(store => ({
    grnResponse: store.inventory.grn.grnResponse,
    latestSkuDetails: store.inventory.grn.sku_details?.data,
    grnChildSkuData: store.inventory.grn.grnChildSkuData,
    selectedGlobalHubId: store.auth.selectedGlobalHubId,
    displayGrnDetails: store.inventory.grn.displayGrnDetails,
    enforcedFailUploadIds: store.inventory.enforcedFailUploadIds,
    enforcedFailReason: store.inventory.enforcedFailReason,
    isShelfLifeBreached: store.inventory.isShelfLifeBreached,
    isSkuDetailUpdated: store.inventory.isSkuDetailUpdated,
    configurations: store.auth.selectedHubConfiguration,
    isDimensionUpdateSuccess: store.inventory.successState,
    tableData: store.inventory.binDisabledGrn.tableData,
    returnGrnResponse: store.returns.createReturnGrnResponse?.data,
    gtinSkuDetails: store.inventory.gtinSkuDetails
  }))

  const qtyInputRef = useRef(null)
  const dispatch = useDispatch()
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const [isEditInputOpen, setIsEditInputOpen] = useState(false)
  const [weight, setWeight] = useState(skuDetails.weight?.value || 0)
  const [unit, setUnit] = useState({ value: 'kg', label: 'kg' })
  const [isAddBarcodeModalOpen, setIsBarcodeModalOpen] = useState(false)
  const [isOpenedQtyModalOpen, setIsOpenedQtyModalOpen] = useState(false)
  const [isOverReceiveModalOpen, setIsOverReceiveModalOpen] = useState(false)
  const [qtyToOpen, setQtyToOpen] = useState(1)
  const [isSkuNotPartModalOpen, setIsSkuNotPartModalOpen] = useState(false)
  const [isAddDimensionModalOpen, setIsAddDimensionModalOpen] = useState(false)
  const [dimension, setDimension] = useState({
    dimensions: {
      length: { length: '', unit: 'cm' },
      breadth: { breadth: '', unit: 'cm' },
      height: { height: '', unit: 'cm' }
    }
  })
  const [isSerializedMarkAsFail, setIsSerializedMarkAsFail] = useState(false)
  const [isNonSerializedMarkAsFail, setIsNonSerializedMarkAsFail] = useState(false)
  const isMaxShelfLifeEnforced = configurations?.find(item => item.configuration_type === 'acceptable_shelf_life_validation')?.configuration_values?.enabled
  const { t } = useTranslation()
  const skuWeightRef = useRef(null)
  const [changedQuantity, setChangedQuantity] = useState(0)
  const [qtyForToast, setQtyForToast] = useState()
  const gtinNonSerialised = skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED

  const {mutate: enforcedMarkAsFail, isPending:enforcedLoading} = useApi({
    isMutation:true,
    apiKey:['enforced-mark-as-fail'],
    apiFn: (scannedQty) => {
      
      if(skuDetails?.batch_status === BATCH_STATUS.BATCHED && !watch('selected_batch')) {
        setError('selected_batch', { type: 'required', message: 'Batch is required' })
        return
      }

      if (scannedQty < skuDetails?.fail_qty) {
        CustomToast('Scanned quantity cannot be less than failed quantity.', { my_type: 'error', audioRequired: false })
        dispatch(setScannedSkuDetails({
          data: {
            ...skuDetails,
            scanned_qty: skuDetails?.pass_qty + skuDetails?.fail_qty
          }
        }))
        return
      }

      setChangedQuantity(scannedQty - skuDetails?.pass_qty)
      const qtyToShow = qtyForToast === 'plus' ? 1 : qtyForToast === 'minus' ? false : scannedQty - skuDetails?.pass_qty
      setQtyForToast(qtyToShow)

      const reasons = [
        {
          id:enforcedFailReason.id,
          quantity: scannedQty,
          images:[],
          upload_ids: enforcedFailUploadIds || []
        }
      ]
      const body = {
        batch_id:skuDetails?.batch?.id,
        seller_sku_code:skuDetails?.seller_sku_code,
        over_receive: isOverReceiveModalOpen,
        reasons,
        pass_quantity:0,
        non_serialised_gtin_barcode: gtinNonSerialised ? scannedBarcode : undefined
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: (data) => {
      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setIsOverReceiveModalOpen(true)
        } else {
          setIsSkuNotPartModalOpen(true)
        }
      } else {
        dispatch(updateEnforcedFailUploadIds([]))
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                batch: skuDetails?.batch,
                fail_qty: changedQuantity,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: changedQuantity,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isPresentInTable) {
              if (isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity,
                  reasons: []
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: 0,
                fail_qty: changedQuantity,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                reasons: [],
                scanned_qty: changedQuantity,
                batch: skuDetails?.batch
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }

        if (qtyForToast) {
          CustomToast(`${qtyForToast} ${qtyForToast > 1 ? 'Items' : 'Item'} failed successfully`, { my_type: 'success', duration:2000 })
        }
      
      }
    }
  })

  const { mutate: enforcedMarkAsFailModalOpen, isPending: enforcedModalLoading } = useApi({
    isMutation: true,
    apiKey: ['enforcedMarkAsFailModalOpen'],
    apiFn: () => {
      const reasons = [
        {
          id:enforcedFailReason.id,
          quantity: parseInt(changedQuantity),
          images:[],
          upload_ids: enforcedFailUploadIds || []
        }
      ]
      const body = {
        batch_id:skuDetails?.batch?.id,
        seller_sku_code:skuDetails?.seller_sku_code,
        over_receive: isOverReceiveModalOpen,
        reasons,
        pass_quantity:0,
        non_serialised_gtin_barcode: gtinNonSerialised ? scannedBarcode : undefined
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: () => {
      dispatch(updateEnforcedFailUploadIds([]))
      if (isSkuNotPartModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (isPresentInTable) {
            if (isPresentInTable?.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.pass_qty
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                  ...item,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.pass_qty
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            } else if (!isPresentInTable?.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.pass_qty
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails.seller_sku_id ? {
                  ...item,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.pass_qty
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                fail_qty:changedQuantity,
                batch: skuDetails?.batch,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: changedQuantity,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
      }

      if (isOverReceiveModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                fail_qty:changedQuantity,
                batch: skuDetails?.batch,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: changedQuantity,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch,
              allow_over_receive: true
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isPresentInTable) {
              if (isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity,
                  reasons: []
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: 0,
                fail_qty: changedQuantity,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                reasons: [],
                scanned_qty: changedQuantity,
                batch: skuDetails?.batch,
                allow_over_receive: true
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }
      }
      if (qtyForToast) {
        CustomToast(`${qtyForToast} ${qtyForToast > 1 ? 'Items' : 'Item'} failed successfully`, { my_type: 'success', duration:2000 })
      }
      setIsSkuNotPartModalOpen(false)
      setIsOverReceiveModalOpen(false)
    }
  })

  const { mutate: updateBinDisabledSkuDetails, isPending: cardQtyLoading } = useApi({
    isMutation: true,
    apiKey: ['update-Bin-Disabled-Sku-Details-on-changing-the-qty-manually'],
    apiFn: (scannedQty) => {

      if (+scannedQty === 0) {
        CustomToast('Scanned quantity must be greater than 0.', { my_type: 'error', audioRequired: false })
        dispatch(setScannedSkuDetails({
          data: {
            ...skuDetails,
            scanned_qty: skuDetails?.pass_qty + skuDetails?.fail_qty
          }
        }))
        return
      }

      if(skuDetails?.batch_status === BATCH_STATUS.BATCHED && !watch('selected_batch')) {
        setError('selected_batch', { type: 'required', message: 'Batch is required' })
        return
      }

      if (scannedQty < skuDetails?.fail_qty) {
        CustomToast('Scanned quantity cannot be less than failed quantity.', { my_type: 'error', audioRequired: false })
        dispatch(setScannedSkuDetails({
          data: {
            ...skuDetails,
            scanned_qty: skuDetails?.pass_qty + skuDetails?.fail_qty
          }
        }))
        return
      }
      setChangedQuantity(scannedQty - skuDetails?.fail_qty)
      const qtyToShow = qtyForToast === 'plus' ? 1 : qtyForToast === 'minus' ? false : scannedQty - skuDetails?.fail_qty
      setQtyForToast(qtyToShow)

      const reasonsToSend = Array.isArray(skuDetails?.reasons) && skuDetails?.reasons?.length ? 
        skuDetails.reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const skuInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)

      const body = {
        pass_quantity: parseInt(scannedQty - skuDetails?.fail_qty),
        seller_sku_code: skuDetails?.seller_sku_code,
        over_receive: isOverReceiveModalOpen || !!skuInTable?.allow_over_receive,
        batch_id: skuDetails?.batch?.id,
        reasons: reasonsToSend,
        return_order_id: returnGrnResponse?.return_order_detail?.id,
        non_serialised_gtin_barcode: gtinNonSerialised ? scannedBarcode : undefined
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: (data) => {
      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setIsOverReceiveModalOpen(true)
        } else {
          setIsSkuNotPartModalOpen(true)
        }
      } else {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                batch: skuDetails?.batch,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: changedQuantity,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isPresentInTable) {
              if (isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id ? {
                    ...item,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity,
                  reasons: []
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: changedQuantity,
                fail_qty: 0,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                reasons: [],
                scanned_qty: changedQuantity,
                batch: skuDetails?.batch
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        } else {
          const isPresentInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: changedQuantity,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity + skuDetails?.fail_qty
              }
            }))
  
            const updateTableData = tableData.map((item) => {
              return item.seller_sku_id === skuDetails.seller_sku_id ? {
                ...item,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity + skuDetails?.fail_qty
              } : item
            })
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
        if (qtyForToast) {
          CustomToast(`${qtyForToast} ${qtyForToast > 1 ? 'Items' : 'Item'} passed successfully`, { my_type: 'success', duration:2000 })
        }
      }
    }
  })

  const { mutate: updateBinDisabledSkuDetailsFromModal, isPending: overAndNotExistLoading } = useApi({
    isMutation: true,
    apiKey: ['update-Bin-Disabled-Sku-Details-on-changing-the-qty-manually-from-modal'],
    apiFn: () => {
      const reasonsToSend = Array.isArray(skuDetails?.reasons) && skuDetails?.reasons?.length ? 
        skuDetails.reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const body = {
        pass_quantity: parseInt(changedQuantity),
        seller_sku_code: skuDetails?.seller_sku_code,
        over_receive: isOverReceiveModalOpen || isSkuNotPartModalOpen,
        batch_id: skuDetails?.batch?.id,
        reasons: reasonsToSend,
        return_order_id: returnGrnResponse?.return_order_detail?.id,
        non_serialised_gtin_barcode: gtinNonSerialised ? scannedBarcode : undefined
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: () => {

      if (isSkuNotPartModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (isPresentInTable) {
            if (isPresentInTable?.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.fail_qty
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                  ...item,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.fail_qty
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            } else if (!isPresentInTable?.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.fail_qty
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails.seller_sku_id ? {
                  ...item,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.fail_qty
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                batch: skuDetails?.batch,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: changedQuantity,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        } else {
          const isPresentInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)
          if (isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity + skuDetails?.fail_qty
              }
            }))

            const updateTableData = tableData.map((item) => {
              return item.seller_sku_id === skuDetails.seller_sku_id ? {
                ...item,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity + skuDetails?.fail_qty
              } : item
            })
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: changedQuantity,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
      }

      if (isOverReceiveModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                batch: skuDetails?.batch,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: changedQuantity,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch,
              allow_over_receive: true
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isPresentInTable) {
              if (isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id ? {
                    ...item,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity,
                  reasons: []
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: changedQuantity,
                fail_qty: 0,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                reasons: [],
                scanned_qty: changedQuantity,
                batch: skuDetails?.batch,
                allow_over_receive: true
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        } else {
          dispatch(setScannedSkuDetails({
            data: {
              ...skuDetails,
              pass_qty: changedQuantity,
              scanned_qty: changedQuantity + skuDetails?.fail_qty
            }
          }))

          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === skuDetails.seller_sku_id ? {
              ...item,
              pass_qty: changedQuantity,
              scanned_qty: changedQuantity + skuDetails?.fail_qty,
              allow_over_receive: true
            } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        }
      }
      if (qtyForToast) {
        CustomToast(`${qtyForToast} ${qtyForToast > 1 ? 'Items' : 'Item'} passed successfully`, { my_type: 'success', duration:2000 })
      }
      setIsSkuNotPartModalOpen(false)
      setIsOverReceiveModalOpen(false)
    }
  })

  const handleUpdateWeight = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (weight && unit) {
        const body = {
          weight: {
            value: +weight,
            uom: unit.value
          },
          sku_upsert_source_details: {
            source: 'manual'
          }
        }
        dispatch(
          changeSkuWeight({
            seller_id: skuDetails.seller_id,
            seller_sku_id: skuDetails.seller_sku_id,
            body,
            mode: 'weight'
          })
        )
      }
    }
  }

  const handleSkuWeightBlur = () => {
    if (weight && unit) {
      const body = {
        weight: {
          value: +weight,
          uom: unit.value
        },
        sku_upsert_source_details: {
          source: 'manual'
        }
      }
      dispatch(
        changeSkuWeight({
          seller_id: skuDetails.seller_id,
          seller_sku_id: skuDetails.seller_sku_id,
          body,
          mode: 'weight'
        })
      )
    }
  }

  const handleUpdateDimension = (data) => {
    const body = {
      ...data,
      sku_upsert_source_details: {
        source: 'manual'
      }
    }

    dispatch(
      changeSkuWeight({
        seller_id: skuDetails.seller_id,
        seller_sku_id: skuDetails.seller_sku_id,
        body,
        mode: 'dimension'
      })
    )
  }

  useOnClickOutside(skuWeightRef, handleSkuWeightBlur)
  const toggleAddSkuBarcode = () => {
    setIsBarcodeModalOpen((pre) => !pre)
  }
  const handleAddBarcode = () => {
    setIsBarcodeModalOpen(true)
  }

  useEffect(() => {
    if (isSkuDetailUpdated && isSkuDetailUpdated.mode === 'weight' && isSkuDetailUpdated.skuId === skuDetails.seller_sku_id) {
      dispatch(updateWeightForSku({ prevState: latestSkuDetails, skuId: isSkuDetailUpdated.skuId, body: isSkuDetailUpdated.body, mode: isSkuDetailUpdated.mode }))
      setIsEditInputOpen(false)
      dispatch(clearSkuDetailUpdateStatus())
    }
  }, [isSkuDetailUpdated])

  const handleWeight = (e) => {
    setWeight(e.target.value)
  }

  const handleEditWeightOfSKU = () => {
    setIsEditInputOpen((pre) => !pre)
  }
  const handleUnit = (e) => {
    setUnit(e)
  }
  const decreaseQuantity = () => {
    if (qty > 0) {
      if (isBinDisabled) {
        if (isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached) {
          enforcedMarkAsFail(skuDetails?.scanned_qty - 1)
        } else {
          updateBinDisabledSkuDetails(skuDetails?.scanned_qty - 1)
        }
        setQtyForToast('minus')
      } else {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.is_gtin_enabled) {
          dispatch(updateGtinSkuDetails({
            ...gtinSkuDetails,
            scannedQty: gtinSkuDetails?.scannedQty - 1
          }))
        } else {
          setQty(qty - 1)
        }
      }
    }
  }
  const increaseQuantity = () => {
    if (isBinDisabled) {
      if (isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached) {
        enforcedMarkAsFail(skuDetails?.scanned_qty + 1)
      } else {
        updateBinDisabledSkuDetails(skuDetails?.scanned_qty + 1)
      }
      setQtyForToast('plus')
    } else {
      if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.is_gtin_enabled) {
        dispatch(updateGtinSkuDetails({
          ...gtinSkuDetails,
          scannedQty: gtinSkuDetails?.scannedQty + 1
        }))
      } else {
        setQty(qty + 1)
      }
    }
  }

  const onChange = (e) => {
    const number = e.target.value.trim()
    const value = +number
    if (e.nativeEvent.data === '+') {
      if (isBinDisabled) {
        dispatch(setScannedSkuDetails({
          data: {
            ...skuDetails,
            scanned_qty: skuDetails.scanned_qty + 1
          }
        }))
      } else {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.is_gtin_enabled) {
          dispatch(updateGtinSkuDetails({
            ...gtinSkuDetails,
            scannedQty: gtinSkuDetails?.scannedQty + 1
          }))
        } else {
          setQty(qty + 1)
        }
      }
      return
    } else if (e.nativeEvent.data === '-') {
      if (qty > 0) {
        if (isBinDisabled) {
          dispatch(setScannedSkuDetails({
            data: {
              ...skuDetails,
              scanned_qty: skuDetails.scanned_qty - 1
            }
          }))
        } else {
          if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.is_gtin_enabled) {
            dispatch(updateGtinSkuDetails({
              ...gtinSkuDetails,
              scannedQty: gtinSkuDetails?.scannedQty - 1
            }))
          } else {
            setQty(qty - 1)
          }
        }
      }
      return
    }
    
    if (/^[1-9]\d*$/.test(value) || e.target.value === '') {
      if (!skuDetails.isRoot && value > skuDetails.maxQty - skuDetails.openedQty - skuDetails.closedAssignedQty) {
        if (isBinDisabled) {
          dispatch(setScannedSkuDetails({
            data: {
              ...skuDetails,
              scanned_qty: skuDetails.maxQty - skuDetails.openedQty - skuDetails.closedAssignedQty || 0
            }
          }))
        } else {
          if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.is_gtin_enabled) {
            dispatch(updateGtinSkuDetails({
              ...gtinSkuDetails,
              scannedQty: skuDetails.maxQty - skuDetails.openedQty - skuDetails.closedAssignedQty || 0
            }))
          } else {
            setQty(skuDetails.maxQty - skuDetails.openedQty - skuDetails.closedAssignedQty || 0)
          }
        }
      } else {
        if (isBinDisabled) {
          dispatch(setScannedSkuDetails({
            data: {
              ...skuDetails,
              scanned_qty: value || 0
            }
          }))
        } else {
          if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.is_gtin_enabled) {
            dispatch(updateGtinSkuDetails({
              ...gtinSkuDetails,
              scannedQty: value || 0
            }))
          } else {
            setQty(value || 0)
          }
        }
      }
    }
  }

  const onKeyDown = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      e.preventDefault()
      qtyInputRef.current.blur()
    }
  }

  const onBlur = (e) => {
    e.preventDefault()
    if (isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached) {
      enforcedMarkAsFail(skuDetails?.scanned_qty)
    } else {
      updateBinDisabledSkuDetails(skuDetails?.scanned_qty)
    }
  }

  const handleOpen = () => {
    if (qty > 1) {
      setIsOpenedQtyModalOpen(true)
    } else {
      const body = {
        is_root_parent: !!skuDetails.isRoot,
        parent_id: skuDetails.isRoot ? 0 : skuDetails?.parent_id,
        over_receive: false,
        quantity: 1,
        seller_sku_id: skuDetails.seller_sku_id,
        grn_id: grnResponse.id
      }
      dispatch(handleBreakOpen(body))
    }
  }

  const handleAddOverReceiveItem = () => {
    const body = {
      is_root_parent: !!skuDetails.isRoot,
      parent_id: skuDetails.isRoot ? 0 : skuDetails?.parent_id,
      over_receive: true,
      quantity: qtyToOpen,
      seller_sku_id: skuDetails.seller_sku_id,
      grn_id: grnResponse.id
    }
    dispatch(handleBreakOpen(body))
  }

  const handleSkipThisSku = () => {
    setIsSkuNotPartModalOpen(false)
    dispatch(clearGrnChildSkuData())
  }

  const handleChangeQuantity = () => {
    setIsOverReceiveModalOpen(false)
    dispatch(clearGrnChildSkuData())
  }

  useEffect(() => {
    if (grnChildSkuData && grnChildSkuData.currentSkuId === skuDetails.seller_sku_id) {
      if (grnChildSkuData.is_over_receive) {
        setIsOverReceiveModalOpen(true)
      } else if (grnChildSkuData.is_sku_not_exist) {
        setIsSkuNotPartModalOpen(true)
      } else {
        dispatch(setGrnSkuDetails({ prevState: latestSkuDetails, response: grnChildSkuData, openedQty: qtyToOpen, closedQty: qty - qtyToOpen, maxQty: skuDetails.child_sku.quantity * (skuDetails.openedQty + qtyToOpen) }))
      }
      dispatch(clearGrnChildSkuData())
    }
  }, [grnChildSkuData])

  useEffect(() => {
    if (isDimensionUpdateSuccess && isAddDimensionModalOpen) {
      setIsAddDimensionModalOpen(false)
    }
  }, [isDimensionUpdateSuccess])

  const handleChangeQty = () => {
    setIsOverReceiveModalOpen(p => !p)
  }

  const handleSaveAnyway = () => {
    if (isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached) {
      enforcedMarkAsFailModalOpen()
    } else {
      updateBinDisabledSkuDetailsFromModal()
    }
  }

  const handleSkipSku = () => {
    setValue('search_sku', '')
    setValue('search_barcode', '')
    setValue('selected_batch', null)
    setIsSkuNotPartModalOpen(false)
    dispatch(clearScannedSkuDetails())
  }

  const handleBinDisabledMarkAsFail = () => {
    if (skuDetails.serialisation_status === SERIALISATION_OPTION.serialised.id) {
      setIsSerializedMarkAsFail(p => !p)
    } else if (skuDetails.serialisation_status === SERIALISATION_OPTION.non_serialised.id) {
      setIsNonSerializedMarkAsFail(p => !p)
    }
  }

  return (
    <>
      <div className="d-flex flex-column gap-26px rounded-8px bg-white p-16px border border-success-light sku-card">
        <div className="flex-center-between">
          <div className="d-flex flex-column gap-8px w-50">

            {(skuDetails.package_type === PACKAGE_TYPE.CASE_PACK || skuDetails.package_type === PACKAGE_TYPE.PALLET) &&
              <SkuTypeBadge packageType={skuDetails.package_type} />
            }

            <div className="text-dark txt-body-md truncated-text">{skuDetails.name}</div>

            {(skuDetails.type === 'pallet' || skuDetails.type === 'case_pack') &&
              <div className='flex-start-start'>
                <span className='text-dark-6 txt-asst-rg'>
                  {t('Units')}:&nbsp;
                </span>
                <span className="text-dark txt-asst-md">{skuDetails.child?.quantity}</span>
              </div>
            }

            { skuDetails.is_gtin_enabled && skuDetails.batch_status === BATCH_STATUS.BATCHED ?
              <div className='flex-start-start'>
                <span className="text-dark-6 txt-asst-rg">
                  {t('GTIN')}:&nbsp;
                </span>
                <span className="text-dark txt-asst-md">
                  {skuDetails.gtin_number}
                </span>
              </div> : null
            }

            <div className='flex-start-start'>
              <span className="text-dark-6 txt-asst-rg">
                {t('SKU Code')}:&nbsp;
              </span>
              <span className="text-dark txt-asst-md">
                {skuDetails.seller_sku_code}
              </span>
            </div>

            <div className="flex-center-start">
              <span className="text-dark-6 txt-asst-rg">{t('Weight')}:</span>
              {isEditInputOpen ? '    ' : null}
              {isEditInputOpen ? (
                <EditSkuWeight
                  ref={skuWeightRef}
                  defaultValue={skuDetails.weight?.value}
                  handleWeight={handleWeight}
                  handleUnit={handleUnit}
                  handleUpdateWeight={handleUpdateWeight}
                />
              ) : !!skuDetails.weight?.value && (
                <span className='text-dark txt-asst-md'>&nbsp;{skuDetails.weight.value}&nbsp;{skuDetails.weight.uom}</span>
              )}

              {!isEditInputOpen && (
                <div className="flex-center-start">
                  &nbsp;
                  <Button
                    onClick={handleEditWeightOfSKU}
                    className=" p-2px bg-transparent txt-asst-md"
                    ofStyle="noBackground"
                  >
                    {skuDetails.weight?.value ? t('Edit') : t('Add Weight')}
                  </Button>
                </div>
              )}
            </div>

            <div className="flex-center-start">
              <span className="text-dark-6 txt-asst-rg">{t('Dimensions (In cm)')}:&nbsp;</span>
              {(dimension.dimensions.length.length || skuDetails.dimensions?.length?.length) ? <div className="text-dark txt-asst-md text-dark txt-asst-md">
                {dimension.dimensions.length.length || skuDetails.dimensions.length.length} x {dimension.dimensions.breadth.breadth || skuDetails.dimensions.breadth.breadth} x {dimension.dimensions.height.height || skuDetails.dimensions.height.height} &nbsp;
              </div> : null}
              <Button
                onClick={() => setIsAddDimensionModalOpen(prev => !prev)}
                className="p-2px bg-transparent txt-asst-md"
                ofStyle="noBackground"
              >
                {(dimension.dimensions.length.length || skuDetails.dimensions?.length?.length) ? t('Edit') : t('Add Dimensions')}
              </Button>
            </div>

            <div className="flex-center-start gap-8px width-300">
              {skuDetails.barcodes?.length > 0 ?
                <div className="text-dark-6 d-flex gap-4px align-items-center txt-asst-rg">
                  <span>{t('SKU Barcodes')}:</span>
                  {skuDetails.barcodes.length > 1 ?
                    <div className="text-dark-6 txt-asst-rg text-dark txt-asst-md">
                      <div className="flex-center-start gap-4px">
                        <span className="max-width-100 text-truncate flex-center-start text-dark txt-asst-md" title={skuDetails.barcodes?.[0]}>{skuDetails.barcodes?.[0]}</span>
                        {/* <div id="barcodesTooltip" className="text-primary cursor-pointer ps-4px">+{skuDetails.barcodes?.length - 1}</div> */}
                        <UncontrolledDropdown>
                          <DropdownToggle tag='span'
                            className='text-primary cursor-pointer txt-asst-md'
                          >
                          +{skuDetails.barcodes?.length - 1}
                          </DropdownToggle>
                          <DropdownMenu end container='body' className='max-height-150 overflow-auto'>
                            <div className="d-flex flex-column gap-2px txt-asst-md text-dark">
                              {
                                skuDetails.barcodes?.map(ele => <div key={ele} className='px-12px py-4px max-width-150 text-truncate' title={ele}>{ele}</div>)
                              }
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div> :
                    <span className="text-dark txt-asst-md max-width-100 text-truncate" title={skuDetails.barcodes?.[0]}>{skuDetails.barcodes?.[0]}</span>}
                </div> 
                : 
                skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED ? null : <>
                  <AlertTriangle id="barcode-warning" className="cursor-pointer mb-2px" style={{ strokeWidth: 1.5, color: '#FF991F' }} size={16} fill="currentColor" strokeColor="white" stroke="white" />
                  <UncontrolledTooltip innerClassName="text-start" popperClassName="mt-6px" placement="bottom-start" target="barcode-warning">
                    {t('SKU barcode missing. Order will not be processed until SKU barcode is added.')}
                  </UncontrolledTooltip>
                </>}
              {skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED ? null : <Button
                className="p-2px text-nowrap txt-asst-md"
                onClick={handleAddBarcode}
                ofStyle="noBackground"
              >
                {t('Add Barcode')}
              </Button>}
            </div>

            <AddSkuBarcodeModal
              isOpen={isAddBarcodeModalOpen}
              toggle={toggleAddSkuBarcode}
              skuDetails={skuDetails}
            />

            {(skuDetails.package_type === SKU_TYPE.PALLET.value || skuDetails.package_type === SKU_TYPE.CASE_PACK.value) && 
            grnType === GRN_TYPES.STANDARD && !isBinDisabled &&
              <div className="text-primary cursor-pointer" onClick={handleOpen}>
                <span>Break {skuDetails.package_type === SKU_TYPE.PALLET.value ? SKU_TYPE.PALLET.label : SKU_TYPE.CASE_PACK.label}</span>
                <ChevronDown size={20} />
              </div>
            }
          </div>

          <div className="flex-center-center flex-column gap-12px position-relative">
            <img
              src={skuDetails.images?.[0]?.default || skuDetails.images?.[0]?.url || no_sku_image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = no_sku_image
              }}
              alt="no img"
              className="height-50px width-50 align-self-center rounded-8px"
            />

            {skuDetails.serialisation_status ===
              SERIALISATION_OPTION.non_serialised.id ? (
                <QuantityButton
                  minQuantityEnabled={isBinDisabled ? isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached ? skuDetails?.scanned_qty : ((skuDetails?.scanned_qty) <= 1) : 
                    skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED ? gtinSkuDetails?.scannedQty <= 1 : qty <= 1}
                  maxQuantityEnabled={remainingQty ? qty >= remainingQty : false || !skuDetails?.isRoot ? qty >= skuDetails?.maxQty - skuDetails?.openedQty - skuDetails?.closedAssignedQty : false}
                  decreaseQuantity={decreaseQuantity}
                  increaseQuantity={increaseQuantity}
                  quantity={isBinDisabled ? ((skuDetails?.scanned_qty) || 0) : 
                    skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED ? gtinSkuDetails?.scannedQty :
                      qty}
                  onChange={onChange}
                  onKeyDown={isBinDisabled ? onKeyDown : () => { }}
                  onBlur={isBinDisabled ? onBlur : () => { }}
                  ref={qtyInputRef}
                  disabled={cardQtyLoading || enforcedLoading}
                />
              ) : null}

            {cardQtyLoading || enforcedLoading ? <div className="position-absolute" style={{top:'63.5px'}}>
              <Spinner style={{color:'var(--bs-primary)'}}/>
            </div> : null}

            {isBinDisabled && skuDetails.serialisation_status !==
                SERIALISATION_OPTION.non_serialised.id && (skuDetails?.batch_status !== 'undefined' && skuDetails?.serialisation_status !== 'undefined') ? 
              <div className="d-flex flex-column gap-4px">
                <div className="bg-light-1 text-dark txt-body-md flex-center-center width-100 height-32px rounded-8px border border-dark-2">
                  {(parseInt(skuDetails?.pass_qty) + parseInt(skuDetails?.fail_qty)) || 0}
                </div>
                <div className="txt-asst-rg text-dark-6 flex-center-center">
                  {t('Scanned Qty')}
                </div>
              </div>
              : null
            }
          </div>
        </div>

        {isBinDisabled && skuDetails?.fail_qty > 0 ? <div className="flex-center-center w-100 bg-light-2 rounded-8px px-24px py-8px">
          <div className="w-50 d-flex flex-column align-items-center text-success">
            <div className="txt-sub-md">{t('Passed Qty')}</div>
            <div className="txt-h2-sb">{skuDetails?.pass_qty}</div>
          </div>
          <div className="w-50 d-flex flex-column align-items-center text-danger">
            <div className="txt-sub-md">{t('Failed Qty')}</div>
            <div className="txt-h2-sb">{skuDetails?.fail_qty}</div>
          </div>
        </div> : null}

        {isBinDisabled ?
          <Button ofStyle='noBackground' className='p-0 txt-sub-md'
            onClick={handleBinDisabledMarkAsFail}
            disabled={parseInt(skuDetails?.pass_qty) === 0 || !skuDetails?.pass_qty}
          >
            {t('Mark As Fail')}
          </Button>
          :
          null
        }
      </div>

      <OpenedQtyModal
        isOpen={isOpenedQtyModalOpen}
        toggle={() => setIsOpenedQtyModalOpen(!isOpenedQtyModalOpen)}
        qty={qty}
        skuDetails={skuDetails}
        grnResponse={grnResponse}
        qtyToOpen={qtyToOpen}
        setQtyToOpen={setQtyToOpen}
      />

      <OverReceiveItemModal
        isOpen={isOverReceiveModalOpen}
        toggle={() => setIsOverReceiveModalOpen(pre => !pre)}
        handleSaveAnyway={isBinDisabled ? handleSaveAnyway : handleAddOverReceiveItem}
        handleChangeQuantity={isBinDisabled ? handleChangeQty : handleChangeQuantity}
        loading={overAndNotExistLoading || enforcedModalLoading}
      />

      <SkuNotPartOfOrderModal
        isOpen={isSkuNotPartModalOpen}
        toggle={() => setIsSkuNotPartModalOpen(pre => !pre)}
        handleSaveAnyway={isBinDisabled ? handleSaveAnyway : handleAddOverReceiveItem}
        handleSkipThisSku={isBinDisabled ? handleSkipSku : handleSkipThisSku}
        loading={overAndNotExistLoading || enforcedModalLoading}
      />
      
      <AddDimensionModal
        isOpen={isAddDimensionModalOpen}
        toggle={() => setIsAddDimensionModalOpen(pre => !pre)}
        setDimension={setDimension}
        handleUpdateDimension={handleUpdateDimension}
        dimensions={dimension.dimensions.length.length ? dimension.dimensions : skuDetails.dimensions}
      />

      <SerializedMarkAsFail
        isSerializedMarkAsFail={isSerializedMarkAsFail}
        toggle={() => setIsSerializedMarkAsFail(pre => !pre)}
        skuDetails={skuDetails}
        grnResponse={grnResponse}
        selectedGlobalHubId={selectedGlobalHubId}
      />

      <NonSerializeMarkAsFail
        isOpen={isNonSerializedMarkAsFail}
        toggle={() => setIsNonSerializedMarkAsFail(pre => !pre)}
        skuDetails={skuDetails}
        grnResponse={grnResponse}
        selectedGlobalHubId={selectedGlobalHubId}
        scannedBarcode={scannedBarcode}
        gtinNonSerialised={gtinNonSerialised}
      />
    </>
  )
}

export default SkuCard
