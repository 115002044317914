/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@src/@core/components/ui/button';
import trackingImage from '@src/assets/images/orders/trackingOrder.svg';
import CopyOnClick from '@src/@core/components/ui/click-and-copy';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import CustomFilter from '@src/@core/components/ui/customTableFilterComponent';
import { DROPDOWN_TYPES } from '@src/@core/components/ui/customTableFilterComponent/customTableFilterComponent.constant';
import { ExpandableCustomTable, externalTableStyles } from '@src/@core/components/ui/globalTable/ExpandableCustomTable';
import NewCheckbox from '@src/@core/components/ui/new-checkbox';
import { getLoadOrderTagsConfig } from '@src/app.apis';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import { axiosInstance } from '@src/network/AxiosInstance';
import { AbilityContext } from '@src/utility/context/Can';
import { useFilters, usePagination, useSearch } from '@src/utility/hooks/useFilters';
import { useRTL } from '@src/utility/hooks/useRTL';
import { getOptionsFetcher } from '@src/utility/Utils';
import OrderTagCell from '@src/views/sales/components/OrderTagCell';
import CreateShipment from '@src/views/shipping-aggregator/components/create-shipment';
import OrderDetailsSidesheet from '@src/views/shipping-aggregator/components/order-details-sidesheet';
import PackageDetailsSideSheet from '@src/views/shipping-aggregator/components/order-details-sidesheet/order-details/package-details-sidesheet';
import { iPackage, Order } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { fetchShipmentOrders, SHIPPING_AGGREGATOR_API_KEYS } from '@src/views/shipping-aggregator/shipping-aggregator.apis';
import { CREATE_SHIPMENTS_ORDER_FILTER_METADATA, ORDERS_TABS, PAYMENT_METHOD, SHIPMENT_ORDER_ENTITY } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, MoreVertical } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import ShipmentsOrderFilters from '../shipments-order-filters';

type iOrderTableView = {
  // eslint-disable-next-line no-unused-vars
  setTableMetaData: (data: any) => void
}
const OrdersTableView = ({ setTableMetaData }: iOrderTableView) => {
  const ability = useContext(AbilityContext);
  const TableColumnsHeader = [
    { id: 'search_display_order_id', name: 'Order ID' },
    { id: 'search_display_package_id', name: 'Package ID' },
    { id: 'search_client_reference_id', name: 'Reference Order ID' },
    // { id: 'tracking_number', name: 'Tracking Number' }
  ];
  const { t } = useTranslation();
  const [isRTL] = useRTL();
  const [searchParams] = useSearchParams();

  const isLMDSellerEnabled = useSelector((store: any) => store.auth.userData?.restricted_third_party_logistics);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [selectedOrderPackages, setSelectedOrderPackages] = useState<{ [key: string]: iPackage[] }>({});
  const [isCreateShipmentSideSheetOpen, setIsCreateShipmentSideSheetOpen] = useState<boolean>(false);
  const [selectedPackages, setSelectedPackages] = useState<undefined | { [key: string]: iPackage[] }>();
  const [shipmentOrderDetails, setShipmentOrderDetails] = useState<{ isOpen: boolean, id: string, defaultTabOpen?: number }>({ isOpen: false, id: '' });
  const [shipmentPackageDetails, setShipmentPackageDetails] = useState({ isOpen: false, data: null });
  const { searchQuery, setSearchQuery } = useSearch({ column: TableColumnsHeader[0] });
  const { filter, setFilter } = useFilters({});
  const { pagination, setPagination } = usePagination();
  const currentSelectedTab = searchParams.get('current_tab');

  // @ts-expect-error fix
  const loadOrderTagOptions = getOptionsFetcher(getLoadOrderTagsConfig());

  const ALL_ORDER_PAGE_FILTER_DATA = {
    tags: {
      filterKey: 'filter_tags',
      displayName: t('Order Tags'),
      type: DROPDOWN_TYPES.MULTI_ASYNC_DROPDOWN,
      options: loadOrderTagOptions,
    },
    paymentMethod: {
      filterKey: CREATE_SHIPMENTS_ORDER_FILTER_METADATA.PAYMENT_METHOD.filterKey,
      displayName: t(CREATE_SHIPMENTS_ORDER_FILTER_METADATA.PAYMENT_METHOD.displayName),
      type: DROPDOWN_TYPES.DROPDOWN,
      options: Object.values(PAYMENT_METHOD),
    }
  };

  const { data: orderDetails, isFetching: isOrderDetailsLoading, isSuccess, isError, refetch } = useQuery({
    queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_ORDERS],
    queryFn: () => {
      const formattedParams: [string, unknown][] = [];
      if (pagination?.page) {
        formattedParams.push(['page', pagination.page]);
        formattedParams.push(['per_page', pagination.per_page || 20]);
      }

      if (searchQuery?.query) { 
        formattedParams.push([searchQuery.column.id, searchQuery.query]);
      }
      for (const key in filter) {
        if (key === CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey) {
          continue;
        }
        if (typeof filter[key] === 'object' && Array.isArray(filter[key])) {
          {
            formattedParams.push([key, (filter[key] as { value: string }[]).map(({ value }) => value)]);
          }
        } else if (typeof filter[key] === 'object') {
          formattedParams.push([key, (filter[key] as { value: string }).value]);
        } else {
          formattedParams.push([key, filter[key]]);
        }
      }

      const params = {
        ...Object.fromEntries(formattedParams), 
        package_status: currentSelectedTab === ORDERS_TABS.ALL.id ? undefined : (currentSelectedTab || ORDERS_TABS.NEW_ORDERS.id),
        created_at_start: filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey]?.value.start,
        created_at_end: filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey]?.value.end,
      };

      return fetchShipmentOrders({ params });
    },
    select: ({ data }) => {
      const mutatedData = data.data.map((singleOrder: Order, index: number) => {
        return {
          ...singleOrder,
          serial_no: (((data.meta?.current_page - 1) * data.meta?.per_page) + (index + 1))
        };
      });
      return { ...data, data: mutatedData };
    },
    enabled: true
  });

  const { mutate: fetchSingleShipmentAWB } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.FETCH_SHIPMENT_AWB],
    mutationFn: ({ shipmentId}: {shipmentId: string | number}) => {
      return axiosInstance.get(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}/awb`);
    },
    onSuccess: ({ data }) => {
      window.open(data.data.awb_label, '_blank');
    }
  });

  const { mutate: markShipmentOperation } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.MARK_AS_DELIVERED],
    mutationFn: async ({ shipmentId, body}: {shipmentId: string | number, body: {status: string}}) => {
      const response = await axiosInstance.put(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}`, body);
      return {...response, type: body.status};
    },
    onSuccess: ({type}) => {
      refetch();
      CustomToast(`Mark shipment as ${type} successfully`, { my_type: 'success' });
      setSelectedOrderPackages({});
    }
  });

  const { mutate: markShipmentAsCancel } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.MARK_SHIPMENT_CANCEL],
    mutationFn: async ({ shipmentId, body}: {shipmentId: string | number, body: {status: string}}) => {
      const response = await axiosInstance.put(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}`, body);
      return {...response, type: body.status};
    },
    onSuccess: () => {
      refetch();
      CustomToast('Shipment cancelled successfully', { my_type: 'success' });
      setSelectedOrderPackages({});
    }
  });

  const { mutate: cancelledOrder } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.DELETE_ORDER],
    mutationFn: ({ orderId}: {orderId: string}) => {
      return axiosInstance.put(`/api/v1/shipping_aggregator/orders/${orderId}/cancel`, {});
    },
    onSuccess: () => {
      refetch();
      CustomToast('Order cancelled successfully', { my_type: 'success' });
      setSelectedOrderPackages({});
    }
  });

  const handlePagination = (page: { selected: number, per_page: number }) => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 10 };
    setPagination(pagination);

  };

  const handleSearchItem = ({ searchVal, selectedColumn = null }: { searchVal: string, selectedColumn: { id: string, name: string } | null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : null;
    setSearchQuery(searchQuery);
  };

  const filterHandler = ({ filterKey, filterValue }: any) => {
    if (!filterValue) {
      delete filter[filterKey];
      if (filterKey === CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SELLERS.filterKey) {
        delete filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.SALES_CHANNEL.filterKey];
      }
      setFilter(filter);
      return;
    }
    setFilter({ ...filter, [filterKey]: filterValue });
  };

  console.log({ filterHandler });

  const handleSelectedShipmentsChange = (changedShipment: (Omit<Order, 'packages'> & { packages: iPackage[] }), checked: boolean) => {
    if (checked) {
      setSelectedOrderPackages(p => {
        const copiedStruct = { ...p };
        copiedStruct[changedShipment.id] = changedShipment.packages.filter((singlePackage) => singlePackage.is_package_selectable);
        return copiedStruct;
      });
    } else {
      setSelectedOrderPackages(p => {
        const copiedStruct = { ...p };
        delete copiedStruct[changedShipment.id];
        return copiedStruct;
      });
    }
  };

  const handleSelectedPackageChange = (changedPackage: (iPackage), checked: boolean) => {
    if (checked) {
      setSelectedOrderPackages(p => {
        const copiedStruct = { ...p };
        const targetedShipmentPackages = copiedStruct[changedPackage.order_id];
        if (!targetedShipmentPackages?.length) {
          copiedStruct[changedPackage.order_id] = [changedPackage];
          return copiedStruct;
        }
        copiedStruct[changedPackage.order_id] = [...copiedStruct[changedPackage.order_id], changedPackage];
        return copiedStruct;
      });
    } else {
      setSelectedOrderPackages(p => {
        const copiedStruct = { ...p };
        const targetedShipmentPackages = copiedStruct[changedPackage.order_id];
        if (targetedShipmentPackages?.length === 1) {
          // This should never be the case
          delete copiedStruct[changedPackage.order_id];
          return copiedStruct;
        }
        copiedStruct[changedPackage.order_id] = copiedStruct[changedPackage.order_id].filter((pkg) => `${pkg.id}` !== `${changedPackage.id}`);
        return copiedStruct;
      });
    }
  };

  // const createShipmentFromSelectedRow = ({ orderRow, packageRow, isComeFromPackageRow }: { orderRow?: Order, packageRow?: iPackage, isComeFromPackageRow: boolean }) => {
  //   if (isComeFromPackageRow) {
  //     setSelectedPackages({
  //       [packageRow?.order_id as string]: [packageRow] as iPackage[]
  //     });
  //   } else {
  //     setSelectedPackages({
  //       [orderRow?.id as string]: orderRow?.packages.filter((singlePackage: iPackage) => singlePackage.is_package_selectable)
  //     });
  //   }
  //   toggleCreateShipment();
  // };

  const createShipmentFromSelectedData = () => {
    if (Object.values(selectedOrderPackages || {}).length > 0) {
      setSelectedPackages(selectedOrderPackages as unknown as { [key: string]: iPackage[] });
      toggleCreateShipment();
    } else {
      CustomToast('Select a package or order to create a shipment', { my_type: 'error' });
    }
  };

  const handleOpenPackageSideSheet = (data: any) => () => {
    setShipmentPackageDetails({ isOpen: true, data });
  };

  const toggleCreateShipment = () => {
    
    if (isCreateShipmentSideSheetOpen) {
      setSelectedPackages(undefined);
      setIsCreateShipmentSideSheetOpen((p) => !p);
    } else {
      setIsCreateShipmentSideSheetOpen((p) => !p);
    }

  };

  const selectedPackageCleanup = () => {
    refetch();
    setSelectedOrderPackages({});
  };

  const getAllAppliedFilters = (appliedFilter: any) => {
    setFilter(appliedFilter);
  };

  const handleToCheckIsActionDisabled = () => {
    // if (currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id || !currentSelectedTab) {
    //   return !(ability.can(
    //     abilityMap.add_shipment_v2.create.action,
    //     abilityMap.add_shipment_v2.create.resource
    //   ));
    // } else
    if (currentSelectedTab === ORDERS_TABS.AT_HUB.id) {
      return (
        !(ability.can(
          abilityMap.add_shipment_v2.create.action,
          abilityMap.add_shipment_v2.create.resource
        )) && !(ability.can(
          abilityMap.edit_shipment_v2.edit.action,
          abilityMap.edit_shipment_v2.edit.resource
        ))
      );
    } else {
      return (
        !(ability.can(
          abilityMap.edit_shipment_v2.edit.action,
          abilityMap.edit_shipment_v2.edit.resource
        )) && !(ability.can(
          abilityMap.view_awb_shipment_v2.view_awb.action,
          abilityMap.view_awb_shipment_v2.view_awb.resource
        ))
      );
    }
  };

  useEffect(() => {
    setTableMetaData(orderDetails?.meta || {});
  }, [orderDetails]);

  const orderColumns = useMemo(() => {
    return [
      {
        id: 1,
        name: '',
        key: 'selectable',
        width: '60px',
        reorder: false,
        omit:  !(currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id || (currentSelectedTab === ORDERS_TABS.AT_HUB.id && !isLMDSellerEnabled) || !currentSelectedTab),
        cell: (row: Order) => {
          const isSelected = selectedOrderPackages[row.id]?.length === row.packages?.length;
          const isPartiallyChecked = (selectedOrderPackages[row.id]?.length > 0 && selectedOrderPackages[row.id]?.length < row.packages?.length) ? true : false;
          const isAtLeastOneShipmentSelected = Object.values(selectedOrderPackages)?.length > 0;
          return (
            <div className='w-100 d-flex justify-content-center'>
              {/* @ts-expect-error fix */}
              <NewCheckbox name='shipment-checkbox' id={`${row.id}`} disabled={isAtLeastOneShipmentSelected && !selectedOrderPackages[row.id]} partialChecked={isPartiallyChecked} checked={isPartiallyChecked || isSelected} onChange={(e: { target: { checked: boolean } }) => handleSelectedShipmentsChange(row, ((e.target as unknown as { checked: boolean }).checked))} />
            </div>
          );
        },
      },
      {
        id: 2,
        name: <div className='d-flex w-100 align-items-center justify-content-center'>{t('S. No.')}</div>,
        key: 'sr.no',
        width: '70px',
        reorder: false,
        cell: (row: Order & { serial_no: number }) => <div className='d-flex w-100 align-items-center justify-content-center'>{row.serial_no}</div>
      },
      {
        id: 3,
        name: t('Shipment Order ID'),
        key: 'order_id',
        minWidth: '250px',
        reorder: true,
        cell: (row: Order, index: number) => {
          return (
          /* @ts-expect-error fix */
            <CopyOnClick
              id={`${index}`}
              handleDetails={() => setShipmentOrderDetails({ isOpen: true, id: row.id })}
              columnKey={'order_id'} 
            >{row.display_order_id}</CopyOnClick>
          );
        },
      },
      {
        id: 4,
        name: t('Reference Order ID'),
        key: 'reference_order_id',
        minWidth: '200px',
        reorder: true,
        cell: (row: Order) => {
          return (
            <div className='flex flex-col gap-2 align-middle'>{row.client_reference_id || '-'}</div>
          );
        },
      },
      {
        id: 26,
        name: t('Tracking Number'),
        key: 'tracking_number',
        minWidth: '250px',
        omit:currentSelectedTab == ORDERS_TABS.NEW_ORDERS.id  || !currentSelectedTab,
        reorder: true,
        cell: (row: Order) => {
          return row.tracking_number ? (
            <div className='d-flex align-items-center gap-50'>
              <a href={`/tracking/${row.tracking_number}`} target='_blank' rel='noreferrer'>
                <img src={trackingImage} alt='tracking-image' />
              </a>
              <span>{row.tracking_number}</span>
            </div>
          ) : '-';
        },
      },
      {
        id: 5,
        name: t('No. of Packages'),
        key: 'no_of_packages',
        minWidth: '200px',
        reorder: true,
        cell: (row: Order) => {
          return (
            <div className='cursor-pointer text-primary' onClick={() => setShipmentOrderDetails({ isOpen: true, id: row.id, defaultTabOpen: 1 })}>{row.packages?.length} {row.packages.length > 1 ? 'Packages' : 'Package'}</div>
          );
        },
      },
      {
        id: 6,
        name: t('Order Tags'),
        key: 'order_tags',
        minWidth: '320px',
        reorder: true,
        cell: (row: Order) => {
          return (
            <OrderTagCell tags={row.tags} />
          );
        }
      },
      {
        id: 7,
        name: t('Seller Name'),
        key: 'seller_name',
        omit: isLMDSellerEnabled,
        minWidth: '200px',
        reorder: true,
        cell: (row: Order) => {
          return <span>{row.seller_name || '-'}</span>;
        },
      },
      {
        id: 30,
        name: t('Sales Channel'),
        key: 'sales_channel_name',
        minWidth: '250px',
        reorder: true,
        cell: (row: Order) => {
          return (
            row.sales_channel_info?.sales_channel?.logo_url ? <div className="d-flex gap-8px align-items-center">
              <img
                // onError={({ currentTarget }) => {
                //   currentTarget.onerror = null;
                //   currentTarget.src = noImage;
                // }}
                src={row.sales_channel_info?.sales_channel?.logo_url}
                alt={'sales-channel'}
                className="object-fit-contain"
                width={60}
                height={30}
              />
            </div> : '-'
          );
        }
      },
      {
        id: 8,
        name: t('Pickup Address'),
        key: 'pickup_address',
        minWidth: '200px',
        reorder: true,
        cell: (row: Order) => {
          return <div>{row.origin_details.address.display_address || '-'}</div>;
        },
      },
      {
        id: 9,
        name: t('Pickup City'),
        key: 'pickup_city',
        minWidth: '200px',
        reorder: true,
        cell: (row: Order) => {
          return (
            <div>{row.origin_details.address.city.name || '-'}</div>
          );
        },
      },
      {
        id: 10,
        name: t('Delivery Address'),
        key: 'shipping_address',
        minWidth: '200px',
        reorder: true,
        cell: (row: Order) => {
          return <div>{row.receiver_address.display_address || '-'}</div>;
        },
      },
      {
        id: 11,
        name: t('Delivery City'),
        key: 'shipping_city',
        minWidth: '200px',
        reorder: true,
        cell: (row: Order) => {
          return <div>{row.receiver_address.omniful_city || '-'}</div>;
        },
      },
      {
        id: 12,
        name: t('Order Source'),
        key: 'order_source',
        minWidth: '200px',
        reorder: true,
        cell: (row: Order) => {
          return (
            <div>
              {row.display_order_source || '-'}
            </div>
          );
        },
      },
      {
        id: 14,
        name: t('Payment Type'),
        key: 'payment_type',
        minWidth: '200px',
        reorder: true,
        cell: (row: Order) => {
          return (
            <div>
              {row.payment_info.display_payment_type || '-'}
            </div>
          );
        },
      },
      {
        id: 16,
        name: t('Created On'),
        key: 'created_at_date',
        minWidth: '200px',
        reorder: true,
        cell: (row: Order) => {
          return (
            <span>{row.created_at}</span>
          );
        }
      },
      {
        id: 17,
        name: t('Action'),
        key: 'actions',
        width: '70px',
        allowOverflow: true,
        omit: (currentSelectedTab === ORDERS_TABS.DELIVERED.id || currentSelectedTab === ORDERS_TABS.ALL.id),
        // omit: !ability.can(
        //   abilityMap.hub_location.edit.action,
        //   abilityMap.hub_location.edit.resource
        // ),
        cell: (row: Order) => {
          const isAllActionsDisabled = (
            !(ability.can(
              abilityMap.add_shipment_order_v2.create.action,
              abilityMap.add_shipment_order_v2.create.resource
            ))
          );
          return (
            <div className={classNames('d-flex w-100 justify-content-center', {'cursor-pointer': !isAllActionsDisabled})}>
              <UncontrolledDropdown>
                <DropdownToggle className="pe-1 more_vert" tag="span" disabled={isAllActionsDisabled}>
                  <MoreVertical size={20} color={isAllActionsDisabled ? 'var(--bs-dark-4)' : 'var(--bs-dark)'} />
                </DropdownToggle>
                <DropdownMenu end container="body" className='omniship-dropdown-cta'>
                  {(ability.can(
                    abilityMap.add_shipment_order_v2.create.action,
                    abilityMap.add_shipment_order_v2.create.resource
                  )) && <DropdownItem
                    className="w-100 text-dark"
                    onClick={() => cancelledOrder({orderId: row.id})}
                  >
                    <span className="align-middle ms-50 txt-sub-rg">
                      {t('Cancel Order')}
                    </span>
                  </DropdownItem>}
                  
                  {(row.can_mark_delivered && (ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  ))) && <div className='omniship-dropdown-cta-disabled-state'>
                    <DropdownItem
                      id='delivered'
                      className={classNames('w-100 text-dark', { 'opacity-25': !row.metadata?.is_same_shipment })}
                      onClick={() => {
                        if (row.metadata?.is_same_shipment) {
                          markShipmentOperation({
                            shipmentId: row.metadata?.shipment_id || '',
                            body: {
                              status: 'delivered'
                            }
                          });
                        }
                      }}
                    >
                      <span className="align-middle ms-50 txt-sub-rg">
                        {t('Mark as Delivered')}
                      </span>
                    </DropdownItem>
                    {!row.metadata?.is_same_shipment && <UncontrolledTooltip placement='left' target={'delivered'}>
                      {t('Enabled when all packages in an order are delivered in a single shipment. For multiple shipments, use package-level actions.')}
                    </UncontrolledTooltip>}
                  </div>
                  }
                  {(row.can_mark_shipped && (ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  ))) && <div className='omniship-dropdown-cta-disabled-state'>
                    <DropdownItem
                      id='shipped'
                      className={classNames('w-100 text-dark', { 'opacity-25': !row.metadata?.is_same_shipment })}
                      onClick={() => {
                        if (row.metadata?.is_same_shipment) {
                          markShipmentOperation({
                            shipmentId: row.metadata?.shipment_id || '',
                            body: {
                              status: 'shipped'
                            }
                          });
                        }
                      }}
                    >
                      <span className="align-middle ms-50 txt-sub-rg">
                        {t('Mark as Shipped')}
                      </span>
                    </DropdownItem>
                    {!row.metadata?.is_same_shipment && <UncontrolledTooltip placement='left' target={'shipped'}>
                      {t('Enabled when all packages in an order are delivered in a single shipment. For multiple shipments, use package-level actions.')}
                    </UncontrolledTooltip>}
                  </div>
                  }
                  {(row.can_cancel_shipment && (ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  ))) && <div className='omniship-dropdown-cta-disabled-state'>
                    <DropdownItem
                      id='cancelled'
                      className={classNames('w-100 text-dark', { 'opacity-25': !row.metadata?.is_same_shipment })}
                      onClick={() => {
                        if (row.metadata?.is_same_shipment) {
                          markShipmentOperation({
                            shipmentId: row.metadata?.shipment_id || '',
                            body: {
                              status: 'cancelled'
                            }
                          });
                        }
                      }}
                    >
                      <span className="align-middle ms-50 txt-sub-rg">
                        {t('Cancel Shipment')}
                      </span>
                    </DropdownItem>
                    {!row.metadata?.is_same_shipment && <UncontrolledTooltip placement='left' target={'cancelled'}>
                      {t('Enabled when all packages in an order are delivered in a single shipment. For multiple shipments, use package-level actions.')}
                    </UncontrolledTooltip>}
                  </div>
                  }
                  {row.can_print_awb && (ability.can(
                    abilityMap.view_awb_shipment_v2.view_awb.action,
                    abilityMap.view_awb_shipment_v2.view_awb.resource
                  )) &&
                    <div className='omniship-dropdown-cta-disabled-state'>
                      <DropdownItem
                        id='print-awb'
                        className={classNames('w-100 text-dark', { 'opacity-25': !row.metadata?.is_same_shipment })}
                        onClick={() => {
                          if (row.metadata?.is_same_shipment) {
                            fetchSingleShipmentAWB({ shipmentId: row.metadata?.shipment_id || '' });
                          }
                        } }
                      >
                        <span className="align-middle ms-50 txt-sub-rg">
                          {t('Print AWB')}
                        </span>
                      </DropdownItem>
                      {!row.metadata?.is_same_shipment && <UncontrolledTooltip placement='left' target={'print-awb'}>
                        {t('Enabled when all packages in an order are delivered in a single shipment. For multiple shipments, use package-level actions.')}
                      </UncontrolledTooltip>}
                    </div>
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          );
        }
      }
    ];
  }, [selectedOrderPackages, orderDetails, t]);

  const packagesColumns = useMemo(() => {
    return [
      {
        id: 21,
        name: '',
        key: 'selectable-packages',
        width: '60px',
        reorder: false,
        omit: !(currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id || (currentSelectedTab === ORDERS_TABS.AT_HUB.id && !isLMDSellerEnabled) || !currentSelectedTab),
        cell: (row: iPackage) => {
          const isSelected = selectedOrderPackages[row.order_id]?.findIndex(pkg => pkg.id === row.id) > -1;
          const isAtLeastOneShipmentSelected = Object.values(selectedOrderPackages)?.length > 0;
          return (
            <div className='w-100 d-flex justify-content-center'>
              { /* @ts-expect-error fix */}
              <NewCheckbox name='shipment-checkbox' id={`${row.id}`} disabled={(isAtLeastOneShipmentSelected && !selectedOrderPackages[row.order_id]) || !row.is_package_selectable} checked={isSelected} onChange={(e: { target: { checked: boolean } }) => handleSelectedPackageChange(row, ((e.target as unknown as { checked: boolean }).checked))} />
            </div>
          );
        },
      },
      {
        id: 22,
        name: <div className='d-flex w-100 align-items-center justify-content-center'>{t('S. No.')}</div>,
        key: 'sr.no',
        width: '70px',
        reorder: false,
        cell: (_: unknown, ind: number) => {
          return <div className='d-flex w-100 align-items-center justify-content-center'>{ind + 1}</div>;
        }
      },
      {
        id: 23,
        name: t('Package ID'),
        key: 'package_id',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage, index: number) => {
          return (
          /* @ts-expect-error fix */
            <CopyOnClick
              id={`${index}`}
              handleDetails={handleOpenPackageSideSheet(row)}
              columnKey={'package_id'} 
            >{row.display_package_id}</CopyOnClick>
          );
        },
      },
      {
        id: 25,
        name: t('Package’s AWB Number'),
        key: 'package_identifier',
        minWidth: '250px',
        omit: (currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id) || !currentSelectedTab,
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className={classNames('flex h-full w-full items-center text-clickable', { 'cursor-pointer': row.awb })}>
              {row.display_awb_number || '-'}
            </div>
          );
        },
      },
      {
        id: 27,
        name: t('Package Type'),
        key: 'package_type',
        minWidth: '200px',
        reorder: true,
        cell: () => {
          return (
            <div>
              Forward
            </div>
          );
        },
      },
      {
        id: 28,
        name: t('Package Status'),
        key: 'package_status',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.display_status ||'-'}
            </div>
          );
        }
      },
      {
        id: 31,
        name: t('Payment Type'),
        key: 'payment_type',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.display_payment_type ||'-'}
            </div>
          );
        }
      },
      {
        id: 32,
        name: t('Current Location'),
        key: 'current_location',
        minWidth: '250px',
        omit:isLMDSellerEnabled,
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.current_location_name || '-'}
            </div>
          );
        }
      },
      {
        id: 33,
        name: t('Next Location'),
        key: 'next_location',
        minWidth: '250px',
        reorder: true,
        omit: (currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id) || !currentSelectedTab || isLMDSellerEnabled,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.next_location_name || '-'}
            </div>
          );
        }
      },
      {
        id: 36,
        name: t('Courier Partner'),
        key: 'courier_partner',
        minWidth: '250px',
        reorder: true,
        omit: (currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id || currentSelectedTab === ORDERS_TABS.AT_HUB.id) || !currentSelectedTab || isLMDSellerEnabled,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.display_shipping_partner.name || '-'}
            </div>
          );
        }
      },
      {
        id: 37,
        name: t('Weight'),
        key: 'weight',
        minWidth: '180px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='flex items-center gap-2'>
              {row.package_info.weight?.value ? `${row.package_info.weight?.value} ${row.package_info.weight?.uom || ''}` : '-'}
            </div>
          );
        },
      },
      {
        id: 38,
        name: t('Dimensions (l X b X h)'),
        key: 'dimensions',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='flex items-center gap-2'>
              {(row.package_info.length && row.package_info.width && row.package_info.height) ? `${row.package_info.length} X ${row.package_info.width} X ${row.package_info.height}` : '-'}
            </div>
          );
        },
      },
      {
        id: 40,
        name: t('Created On'),
        key: 'date_time_of_creation_packages',
        minWidth: '240px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <span>{row.created_at || '-'}</span>
          );
        }
      },
      {
        id: 41,
        name: t('Action'),
        key: 'nested-actions',
        width: '70px',
        omit: (currentSelectedTab === ORDERS_TABS.DELIVERED.id || currentSelectedTab === ORDERS_TABS.ALL.id || currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id || !currentSelectedTab),
        cell: (row: iPackage) => {
          const isAllActionsDisabled = handleToCheckIsActionDisabled(); 

          return (
            <div className={classNames('d-flex w-100 justify-content-center', {'cursor-pointer' : (!isAllActionsDisabled && row.is_package_selectable)})}>
              <UncontrolledDropdown>
                <DropdownToggle className="pe-1 more_vert" tag="span" disabled={isAllActionsDisabled || !row.is_package_selectable}>
                  <MoreVertical size={20} color={(isAllActionsDisabled || !row.is_package_selectable) ? 'var(--bs-dark-4)' : 'var(--bs-dark)'} />
                </DropdownToggle>
                <DropdownMenu end container="body">
                  {/* {((currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id || currentSelectedTab === ORDERS_TABS.AT_HUB.id || !currentSelectedTab) && (ability.can(
                    abilityMap.add_shipment_v2.create.action,
                    abilityMap.add_shipment_v2.create.resource
                  ))) && <DropdownItem
                    className={classNames('w-100 text-dark', { 'opacity-25 cursor-not-allowed': !row.is_package_selectable })}
                    disabled={!row.is_package_selectable}
                    onClick={() => createShipmentFromSelectedRow({ packageRow: row, isComeFromPackageRow: true })}
                  >
                    <span className="align-middle ms-50 text-dark txt-sub-rg">
                      {t('Create Shipment')}
                    </span>
                  </DropdownItem>} */}
                  {(row.can_mark_delivered && (ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  ))) && <DropdownItem
                    className={classNames('w-100 text-dark', { 'opacity-25 cursor-not-allowed': !row.is_package_selectable })}
                    disabled={!row.is_package_selectable}
                    onClick={() => markShipmentOperation({
                      shipmentId: row.shipments_info[row.shipments_info.length - 1].shipment_id,
                      body: {
                        status: 'delivered'
                      }
                    })}
                  >
                    <span className="align-middle ms-50 txt-sub-rg">
                      {t('Mark as Delivered')}
                    </span>
                  </DropdownItem>
                  }
                  {(row.can_mark_shipped && (ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  ))) && <DropdownItem
                    className={classNames('w-100 text-dark', { 'opacity-25 cursor-not-allowed': !row.is_package_selectable })}
                    disabled={!row.is_package_selectable}
                    onClick={() => markShipmentOperation({
                      shipmentId: row.shipments_info[row.shipments_info.length - 1].shipment_id,
                      body: {
                        status: 'shipped'
                      }
                    })}
                  >
                    <span className="align-middle ms-50 txt-sub-rg">
                      {t('Mark as Shipped')}
                    </span>
                  </DropdownItem>
                  }
                  {(row.can_cancel_shipment && (ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  ))) && <DropdownItem
                    className={classNames('w-100 text-dark', { 'opacity-25 cursor-not-allowed': !row.is_package_selectable })}
                    disabled={!row.is_package_selectable}
                    onClick={() => markShipmentAsCancel({
                      shipmentId: row.shipments_info[row.shipments_info.length - 1].shipment_id,
                      body: {
                        status: 'cancelled'
                      }
                    })}
                  >
                    <span className="align-middle ms-50 txt-sub-rg">
                      {t('Cancel Shipment')}
                    </span>
                  </DropdownItem>
                  }
                  {row.can_print_awb && (ability.can(
                    abilityMap.view_awb_shipment_v2.view_awb.action,
                    abilityMap.view_awb_shipment_v2.view_awb.resource
                  )) &&
                    <DropdownItem
                      className={classNames('w-100 text-dark', { 'opacity-25 cursor-not-allowed': !row.is_package_selectable })}
                      disabled={!row.is_package_selectable}
                      onClick={() => fetchSingleShipmentAWB({ shipmentId: row.shipments_info[row.shipments_info.length - 1]?.shipment_id })}
                    >
                      <span className="align-middle ms-50 txt-sub-rg">
                        {t('Print AWB')}
                      </span>
                    </DropdownItem>
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          );
        }
      }
    ];
  }, [selectedOrderPackages, t]);

  // const bulkActionButtons = {
  //   CREATE_SHIPMENT: {
  //     id: 'create-shipment',
  //     text: 'Create Shipment',
  //     onClick: createShipmentFromSelectedData,
  //     omit: !(currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id || currentSelectedTab === ORDERS_TABS.AT_HUB.id || !currentSelectedTab)
  //   },
  //   MARK_AS_DELIVERED: {
  //     id: 'mark-as-delivered',
  //     text: 'Mark as Delivered',
  //     onClick: markAsDeliveredFromSelectedData,
  //     omit: true
  //   },
  //   PRINT_AWB: {
  //     id: 'print-awb',
  //     text: 'Print AWB',
  //     onClick: markAsDeliveredFromSelectedData,
  //     omit: true
  //   }
  // };

  return (
    <div className='d-flex flex-column gap-16px'>
      <div className='flex-wrap d-flex gap-16px'>
        <CustomFilter
          filterData={ALL_ORDER_PAGE_FILTER_DATA}
          defaultSelectedOption={filter}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          getAllAppliedFilters={getAllAppliedFilters}
        />

        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <ShipmentsOrderFilters selectedTabParam={currentSelectedTab} filterHandlerMultipleValues={() => {}} filterHandler={filterHandler} filter={filter} omitShipmentFilters={false} setFilter={setFilter} />
      </div>
      <ExpandableCustomTable
        // key={tableRerenderer}
        loading={isOrderDetailsLoading}
        success={isSuccess}
        error={isError}
        columns={orderColumns}
        data={orderDetails?.data || []}
        meta={orderDetails?.meta || {}}
        isFilterOrSearchApplied={!!(Object.values(filter)?.length)}
        searchcolumnsList={TableColumnsHeader}
        search_column_query={searchQuery}
        handleQueryParams={handleSearchItem}
        handleRefreshTable={refetch}
        /* @ts-expect-error fix */
        handlePagination={handlePagination}
        TableHeaderComponent={
          ((currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id ||  (currentSelectedTab === ORDERS_TABS.AT_HUB.id && !isLMDSellerEnabled)  || !currentSelectedTab) && (ability.can(
            abilityMap.add_shipment_v2.create.action,
            abilityMap.add_shipment_v2.create.resource
          ))) && <div className='d-flex gap-1 align-items-center'>
            <div className='border border-primary-lighter height-40px'></div>
            {/* <Button ofStyle='outlined' onClick={createShipmentFromSelectedData}>{t('Cancel Order')}</Button> */}
            {/* <BulkActions color="primary" actions={Object.values(bulkActionButtons).filter((opt) => (!opt.omit)).filter((_, ind) => ind !== 0)} selectedButton={Object.values(bulkActionButtons).filter(opt => !opt.omit)[0]} /> */}
            {((currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id || (currentSelectedTab === ORDERS_TABS.AT_HUB.id && !isLMDSellerEnabled) || !currentSelectedTab) && (ability.can(
              abilityMap.add_shipment_v2.create.action,
              abilityMap.add_shipment_v2.create.resource
            ))) && <Button onClick={createShipmentFromSelectedData}>{t('Create Shipment')}</Button>}
            {/* <Button onClick={() => {}}>{t('Create Order')}</Button> */}
          </div>
        }
        expandableRowExpanded={(row: Order) => expandedRows.includes(`${row.id}`)}
        expandableRows
        onRowExpandToggled={(expanded: boolean, row: Order) => {
          setExpandedRows(p => {
            if (expanded) return [...p, `${row.id}`];
            else return p.filter(id => `${id}` !== `${row.id}`);
          });
        }}
        expandableIcon={{
          collapsed: (() => (<div className='d-flex width-100 justify-content-center'>
            <div className='rounded-4px border border-dark-5'>
              <ChevronDown className='text-primary' size={18} />
            </div>
          </div>))(),
          expanded: (() => (<div className='d-flex width-100 justify-content-center'>
            <div className='rounded-4px border border-dark-5'>
              <ChevronUp className='text-primary' size={18} />
            </div>
          </div>))()
        }}
        expandableRowsComponent={(row: { data: any }) => {
          return <div className='inner-card-shadow position-relative nested-orders-table'>
            {
              isRTL ? row.data.packages?.map((pkg: iPackage, index: number) => (
                <span key={pkg.id} className='side-lines' style={{
                  height: 70 + (index * 48)
                }} >
                  <div className='rounded-circle'></div>
                </span>
              )) : row.data.packages?.map((pkg: iPackage, index: number) => (
                <span key={pkg.id} className='side-lines' style={{
                  height: 70 + (index * 48)
                }} >
                  <div className='rounded-circle'></div>
                </span>
              ))
            }
            <div className='overflow-hidden package-container bg-dark-2'>
              { /* @ts-expect-error fix */}
              <ExpandableCustomTable
                tableWrapperClassNames='package-table-wrapper'
                showColumnsTableHeader={false}
                showPagination={false}
                showCustomHeader={false}
                columns={packagesColumns}
                loading={false}
                error={false}
                success={true}
                data={row.data.packages || []}
                selectableRows={false}
                meta={{}}
                showFixedTableActionColumn={false}
                customStyles={{ ...externalTableStyles, rows: { style: { backgroundColor: '#FFFFFF', borderColor: '#EEEFF2' } }, cells: { style: { ...externalTableStyles.cells?.style, padding: '8px 16px' } }, headRow: { style: { background: '#F4F4F4', borderBottom: 'none', borderTop: 'none' } } }}
                tableClassNames='package-table' />
            </div>
          </div>;
        }}
        customStyles={{
          expanderCell: {
            style: {
              borderRight: '1px solid var(--bs-dark-2)'
            }
          }
        }}
      />

      <CreateShipment
        isOpen={isCreateShipmentSideSheetOpen}
        toggle={toggleCreateShipment}
        selectedPackages={Object.values(selectedPackages || {})[0]}
        cleanupOnSuccesHandler={selectedPackageCleanup}
      />
      <OrderDetailsSidesheet
        isOpen={shipmentOrderDetails.isOpen}
        toggleSideSheet={() => setShipmentOrderDetails({isOpen:false, id:''})}
        entity={{ id: shipmentOrderDetails.id, key: SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS }}
        defaultTabOpen={shipmentOrderDetails.defaultTabOpen}
        setIsCreateShipmentSideSheetOpen={setIsCreateShipmentSideSheetOpen}
        setSelectedPackages={setSelectedPackages}
      />
      <PackageDetailsSideSheet
        isOpen={shipmentPackageDetails.isOpen}
        toggleSideSheet={() => setShipmentPackageDetails({ isOpen: false, data: null })}
        shipmentPackageDetails={shipmentPackageDetails.data}
      />
     
    </div>
  );
};

export default OrdersTableView;