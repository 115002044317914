import defaultImg from '@src/assets/images/catalog/bundlesku/default-img.svg'
import error_img from '@src/assets/images/catalog/bundlesku/error_img.svg'
import { format, isValid, parseISO } from 'date-fns'

export const ANALYTICS_TABS_LIST = {
  hub: { id: 'hub', name: 'Hub', path: '/analytics/hub' },
  seller: { id: 'seller', name: 'Seller', path: '/analytics/seller' },
  shipping: { id: 'shipping', name: 'Shipping', path: '/analytics/shipping' },
  fulfilment: { id: 'fulfillment', name: 'Fulfilment', path: '/analytics/fulfillment' },
  lastmile: { id: 'last_mile', name: 'Last Mile', path: '/analytics/last_mile' }
}

export const ANALYTICS_DASHBOARD = {
  HUB: {label: 'Hub', value: 'hub'},
  SELLER: {label: 'Seller', value: 'seller'},
  SHIPPING: {label: 'Shipping', value: 'shipping'},
  LIVE: {label: 'Live', value: 'live'}
}

export const INPUT_SOURCE = {
  CHECKBOX: 'checkbox',
  RADIO_BUTTON: 'radio_button',
  API: 'api',
  DATE_PICKER: 'datepicker',
  DROPDOWN: 'dropdown'
}

export const DATE_FILTER_KEY = {
  CREATED: 'created'
}

export const TIME_INTERVAL = {
  LAST_24_HOURS: 'last_24_hours',
  LAST_7_DAYS: 'last_7_days',
  LAST_30_DAYS: 'last_30_days',
  CUSTOM: 'custom'
}

export const AVERAGE_INTERVAL = {
  [TIME_INTERVAL.LAST_24_HOURS]: 'Per Hour',
  [TIME_INTERVAL.LAST_7_DAYS]: 'Per Day',
  [TIME_INTERVAL.LAST_30_DAYS]: 'Per Day'
}

export const STATS_VALUE_DESCRIPTION = {
  hub: {
    average_item_pick_rate: {
      desc: 'Item',
      interval: {...AVERAGE_INTERVAL}
    },
    average_order_pick_rate: {
      desc: 'Order',
      interval: {...AVERAGE_INTERVAL}
    },
    average_order_pack_rate: {
      desc: 'Order',
      interval: {...AVERAGE_INTERVAL}
    },
    average_order_processing_time: {
      desc: 'Min'
    }
  }
}

export const CHART_TYPES = {
  BAR: 'bar_chart',
  LINE: 'line_chart',
  AREA: 'area_chart',
  PIE: 'pie_chart',
  TABLE: 'data_table',
  MAP: 'map',
  NUMBER: 'number_chart',
  DONUT: 'donut_chart',
  STACKED_BAR: 'stacked_bar_chart',
  STACKED_BAR_VERTICAL: 'stacked_bar_chart_vertical',
  DYNAMIC_STACKED_BAR_HORIZONTAL: 'dynamic_stacked_bar_chart_horizontal',
}

export const ADDITIONAL_FILTER_DROPDOWN_STYLE = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '180px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}

export const CHARTS = {
  [CHART_TYPES.DONUT]: 'donut',
  [CHART_TYPES.PIE]: 'pie',
  [CHART_TYPES.BAR]: 'bar',
  [CHART_TYPES.STACKED_BAR]: 'bar',
  [CHART_TYPES.STACKED_BAR_VERTICAL]: 'bar',
  [CHART_TYPES.DYNAMIC_STACKED_BAR_HORIZONTAL]: 'bar',
  [CHART_TYPES.LINE]: 'line',
  [CHART_TYPES.TABLE]: 'table'
}

export const CHART_DEFAULT_DATA_MODIFIER = (options) => {
  const { defaultData, matrix, ...res } = options || {}
  const { tag } = matrix || {}
  const defaultDataCopy = { ...defaultData }
  switch (tag) {
  case 'domestic_international_order_split': {
    defaultDataCopy.option.chart.stackType = '100%'
    defaultDataCopy.option.tooltip = {
      y: {
        formatter(val, opts) {
          const {w, dataPointIndex, seriesIndex} = opts
          const seriesPercentageArray = w.globals.seriesPercent[seriesIndex]
          const percentage = seriesPercentageArray[dataPointIndex].toFixed(2)
          const formattedValue = `${percentage}% (${val.toLocaleString()})`
          return formattedValue
        }
      }
    }
    break
  }
  case 'shipped_orders_in_hub': {
    defaultDataCopy.option.yaxis = {
      labels: {
        formatter: val => Math.round(val),
      }
    }
    break
  }
  case 'average_pickup_time':
  case 'average_delivery_time': {
    defaultDataCopy.option.yaxis = {
      labels: {
        formatter: val => Math.round(val),
      }
    }
    defaultDataCopy.option.tooltip = {
      y: {
        formatter(val) {
          return `${val}`
        }
      }
    }
    break
  }
  case 'delivery_sla_breach_percentage':
  case 'pickup_sla_breach_percentage':
  case 'courier_partner_return_rate': {
    defaultDataCopy.option.tooltip = {
      y: {
        formatter(val) {
          const formattedValue = `${val}%`
          return formattedValue
        }
      }
    }
    defaultDataCopy.option.yaxis = {
      min: 0,
      max: 100,
      labels: {
        formatter: val => Math.round(val),
      }
    }
    break
  }
  default:
    break
  }
  return defaultDataCopy
}

export const CHART_DEFAULT_DATA = {
  [CHART_TYPES.DONUT]: () => ({
    series: [],
    option: {
      chart: {
        fontFamily: 'Poppins',
        sparkline: { enabled: false }
      },
      dataLabels: {
        enabled: true,
        formatter(val, opts) {
          return `${opts.w.globals.series[opts.seriesIndex]}`
        },
        textAnchor: 'middle'
      },
      labels: [],
      legend: {
        show: true,
        position: 'right',
        horizontalAlign: 'center',
        width: 200,
        formatter(val) {
          return `${val}`
        },
        onItemClick: {
          toggleDataSeries: false
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: false,
          donut: {
            size: '55%'
          }
        }
      }
    }
  }),
  [CHART_TYPES.BAR]: () => ({
    series: [],
    option: {
      chart: {
        fontFamily: 'Poppins'
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: false,
          columnWidth: 20
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      legend: {
        position: 'right',
        horizontalAlign: 'center',
        floating: false,
        width: 200,
        onItemClick: {
          toggleDataSeries: false
        }
      },
      xaxis: {
        labels: {
          maxHeight: 100,
          trim: true
        }
      },
      grid: {
        strokeDashArray: 4,
        borderColor: 'var(--bs-dark-1)',
        borderWidth: 1,
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      tooltip: {
        y: {
          formatter (value) {
            return value
          },
          title: {
            formatter: () => undefined
          }
        }
      }
    }
  }),
  [CHART_TYPES.STACKED_BAR]: (options) => {
    const defaultData = {
      series: [],
      option: {
        chart: {
          stacked: true,
          fontFamily: 'Poppins',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'all',
            barHeight: 20
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          colors: ['#fff']
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'right',
          horizontalAlign: 'center',
          floating: false,
          width: 200,
          onItemClick: {
            toggleDataSeries: false
          }
        },
        yaxis: {
          labels: {
            maxWidth: 100,
            trim: true,
            offsetX: 10
          }
        },
        grid: {
          // strokeDashArray: 4,
          // borderColor: "var(--bs-dark-1)",
          // borderWidth: 1,
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        }
      }
    }
    return CHART_DEFAULT_DATA_MODIFIER({defaultData, ...options})
  },
  [CHART_TYPES.STACKED_BAR_VERTICAL]: () => ({
    series: [],
    option: {
      chart: {
        stacked: true,
        fontFamily: 'Poppins',
        stackType: '100%'
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: false,
          columnWidth: 40,
          dataLabels: {
            enabled: true,
            total: {
              enabled: true,
              offsetY: -6,
              style: {
                color: '#222222',
                fontSize: '12px',
                fontWeight: 500
              },
              formatter(val) {
                const formattedValue = val.toLocaleString()
                return formattedValue.toString().replace('%', '')
              }
            }
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        floating: false,
        width: 'fit-content',
        onItemHover: {
          highlightDataSeries: false
        },
        onItemClick: {
          toggleDataSeries: false
        }
      },
      dataLabels: {
        formatter(val) {
          const roundedValue = val.toFixed()
          if (roundedValue < 5) {
            return ''
          }
          return `${roundedValue}%`
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 2,
        labels: {
          formatter: val => `${val}%`,
          maxWidth: 100,
          trim: true
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        X: {
          formatter(val) {
            const formattedValue = val.toLocaleString()
            return formattedValue
          }
        },
        y: {
          formatter(val) {
            const formattedValue = val.toLocaleString()
            return formattedValue
          }
        }
      }
    }
  }),
  [CHART_TYPES.DYNAMIC_STACKED_BAR_HORIZONTAL]: () => ({
    series: [],
    option: {
      chart: {
        stacked: true,
        fontFamily: 'Poppins'
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
          borderRadiusApplication: 'around',
          borderRadiusWhenStacked: 'all',
          barHeight: 20
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2,
        colors: ['#fff']
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'right',
        horizontalAlign: 'center',
        floating: false,
        width: 200,
        maxWidth: 200,
        showForZeroSeries: false,
        onItemClick: {
          toggleDataSeries: false
        }
      },
      yaxis: {
        labels: {
          maxWidth: 100,
          trim: true,
          offsetX: 10
        }
      },
      grid: {
        // strokeDashArray: 4,
        // borderColor: "var(--bs-dark-1)",
        // borderWidth: 1,
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      tooltip: {
        y: {
          formatter(val, opts) {
            const {w, dataPointIndex, seriesIndex} = opts
            const seriesPercentageArray = w.globals.seriesPercent[seriesIndex]
            const percentage = seriesPercentageArray[dataPointIndex].toFixed(2)
            const formattedValue = `${val.toLocaleString()} (${percentage}%)`
            return formattedValue
          }
        }
      }
    },
  }),
  [[CHART_TYPES.LINE]]: (options) => {
    const defaultData = {
      series: [],
      option: {
        chart: {
          fontFamily: 'Poppins',
          zoom: {
            enabled: false,
            type: 'xy'
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'center',
          floating: false,
          onItemClick: {
            toggleDataSeries: false
          }
        },
        stroke: {
          width: 1
        },
        markers: {
          size: 4
        },
        xaxis: {
          labels: {
            rotate: -45, // Rotate labels by -45 degrees
            rotateAlways: true,
            style:{
              fontSize:'10px',
            },
            offsetX:4,
            minHeight: 80,
            maxHeight: 80,
            trim: true
          }
        },
        yaxis: {
          labels: {
            style:{
              fontSize:'10px'
            }
          }
        },
        grid: {
          strokeDashArray: 4,
          borderColor: 'var(--bs-dark-1)',
          borderWidth: 1,
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          style: {
            fontSize: '11px'
          },
          x: {
            show: false
          }
        }
      }
    }
    return CHART_DEFAULT_DATA_MODIFIER({defaultData, ...options})
  },
  [[CHART_TYPES.TABLE]]: () => ({
    option: {}
  })
}

export const CHART_DATA_MODIFIER = {
  [CHART_TYPES.DONUT]({ coordinates, data }) {
    const series = []
    const categories = []
    if (!(coordinates && data.length)) {
      return {
        series,
        categories
      }
    }
    data.forEach(item => {
      if (item[coordinates.group_by]) {
        series.push(item[coordinates.value] || 0)
        categories.push(item[coordinates.group_by])
      }
    })
    return {
      series,
      categories
    }
  },
  [CHART_TYPES.BAR]({coordinates, data}) {
    const series = []
    const categories = []
  
    if (!(coordinates?.['x-axis']?.length && coordinates?.['y-axis']?.length && data.length)) {
      return {
        series: [{data: series}],
        categories
      }
    }
  
    for (const entry of data) {
      categories.push(entry[coordinates['x-axis'][0].key_name])
    }
    for (const entry of data) {
      series.push(entry[coordinates['y-axis'][0].key_name])
    }
    return {
      series: [{data: series}],
      categories
    }
  },
  [CHART_TYPES.STACKED_BAR]({coordinates, data}) {
    const series = []
    const categories = []

    if (!(coordinates?.['x-axis']?.length && coordinates?.['y-axis']?.length)) {
      return {
        series,
        categories
      }
    }

    for (const entry of data) {
      categories.push(entry[coordinates['y-axis'][0].key_name])
    }
    for (const axis of coordinates['x-axis']) {
      const keyName = axis.key_name
      const displayName = axis.display_name
      const seriesData = []

      for (const entry of data) {
        const value = entry[keyName] || 0
        seriesData.push(value)
      }

      series.push({
        name: displayName,
        data: seriesData
      })
    }

    return {
      series,
      categories
    }
  },
  [CHART_TYPES.STACKED_BAR_VERTICAL]({coordinates, data}) {
    const series = []
    const categories = []

    if (!(coordinates?.['x-axis']?.length && coordinates?.['y-axis']?.length)) {
      return {
        series,
        categories
      }
    }

    for (const entry of data) {
      categories.push(entry[coordinates['x-axis'][0].key_name])
    }
    for (const axis of coordinates['y-axis']) {
      const keyName = axis.key_name
      const displayName = axis.display_name
      const seriesData = []

      for (const entry of data) {
        const value = entry[keyName] || 0
        seriesData.push(value)
      }

      series.push({
        name: displayName,
        data: seriesData
      })
    }

    return {
      series,
      categories
    }
  },
  [CHART_TYPES.DYNAMIC_STACKED_BAR_HORIZONTAL]({coordinates, data}) {
    const series = []
    const categories = []

    if (!(coordinates?.['x-axis']?.length && coordinates?.['y-axis']?.length)) {
      return {
        series,
        categories
      }
    }

    for (const entry of data) {
      categories.push(entry[coordinates['y-axis'][0].key_name])
    }

    const allPossibleStacks = [
      ...new Set(
        data.flatMap(entry => entry[coordinates['x-axis'][0].key_name].map(entry => entry.display_value))
      )
    ]
    
    for (const axis of coordinates['x-axis']) {
      const keyName = axis.key_name
      // const displayName = axis.display_name
      for (const stack of allPossibleStacks) {
        const seriesData = []
        for (const entry of data) {
          const stacks = entry[keyName] || []
          const value = stacks.find(item => item.display_value === stack)?.key_value || 0
          seriesData.push(value)
        }
        series.push({
          name: stack,
          data: seriesData
        })
      }
    }

    return {
      series,
      categories
    }
  },
  [CHART_TYPES.LINE]({ coordinates, data, interval, isTimeSelected }) {
    const generateCategories = (data) => {
      const categories = data.map(item => {
        const date = parseISO(item)
        if (!isValid(date)) {
          return item
        }
        switch (interval) {
        case TIME_INTERVAL.LAST_30_DAYS:
          return format(date, 'dd-MM')
        case TIME_INTERVAL.LAST_7_DAYS:
          return format(date, 'dd-MM')
        case TIME_INTERVAL.LAST_24_HOURS:
          const formattedTime = format(date, 'hh:mm a')
          return formattedTime
        case TIME_INTERVAL.CUSTOM:
          const formattedDate = format(date, 'dd-MM')
          const formattedTimeCustom = format(date, 'hh:mm a')
          if (isTimeSelected) {
            return `${formattedDate} (${formattedTimeCustom})` 
          } else {
            return `${formattedDate}` 
          }
        default:
          return item.label
        }
      })
      return categories
    }
    const sorter = (prev, curr) => {

      const prevDate = new Date(prev)
      const currDate = new Date(curr)
      if (!isValid(prevDate) || !isValid(currDate)) {
        return 0
      }
      if (prevDate < currDate) {
        return -1
      } else if (prevDate === currDate) {
        return 0
      } else {
        return 1
      }
    }
    const series = []
    if (!(coordinates?.['x-axis']?.length && coordinates?.['y-axis']?.length && data.length)) {
      return {
        series,
        categories: []
      }
    }

    const categories = [
      ...new Set(
        data.map(item => item[coordinates['x-axis'][0].key_name])
      )
    ].sort(sorter)

    const formattedCategories = generateCategories(categories)

    if (!coordinates?.group_by) {
      // No grouping: Directly create the series for each item
      const values = categories.map(category => 
        data.find(item => item[coordinates['x-axis'][0].key_name] === category)?.[coordinates['y-axis'][0].key_name] || 0
      );
      series.push({
        name: coordinates['y-axis'][0].display_name,
        data: values  
      });
      return {
        series,
        categories: formattedCategories
      };
    }

    const groupedData = data.reduce((acc, item) => {
      const groupName = item[coordinates.group_by]
      if (!acc[groupName]) {
        acc[groupName] = []
      }
      acc[groupName].push(item)
      // acc[groupName].sort(sorter)
      return acc
    }, {})

    for (const [key, value] of Object.entries(groupedData)) {
      // const values = value.map(entry => {
      //   return entry.value
      // })
      const values = []
      for (const category of categories) {
        const item = value.find(item => item[coordinates['x-axis'][0].key_name] === category)?.[coordinates['y-axis'][0].key_name] || 0
        values.push(item)
      }
      series.push({
        name: key,
        data: values
      })
    }
    return {
      series,
      categories: formattedCategories
    }
  },
  [CHART_TYPES.PIE]({ coordinates, data }) {
    const series = []
    const categories = []
    if (!(coordinates && data.length)) {
      return {
        series,
        categories
      }
    }
    data.forEach(item => {
      series.push(item[coordinates.value] || 0)
      categories.push(item[coordinates.group_by])
    })
    return {
      series,
      categories
    }
  },
  [CHART_TYPES.TABLE]({ coordinates, data, handleSkuDetailsModal, pagination, showSerialNumber }) {
    const series = [...data]
    const categories = []
    if (!coordinates.length && !series.length) {
      return {
        series,
        categories
      }
    }
    const getSKu = (row) => {
      return (
        <div className='d-flex align-item-center gap-1 cursor-pointer flex-fill' onClick={() => handleSkuDetailsModal(row)}>
          <div className='width-32px height-32px rounded-6px'>
            <img
              src={ row.sku?.sku_image || defaultImg}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = error_img
              }}
              className='width-32px height-32px rounded-6px overflow-hidden border border-light_2'
              alt='item-image'
            />
          </div>
          <div className='width-100 flex-fill'>
            <div className="text-primary text-truncate">{row.sku?.sku_name || '-'}</div>
            <div className="txt-asst-rg text-truncate">SKU Code: {row.sku?.sku_code || '-'}</div>
          </div>
        </div>
      )
    }
    const coordinate = coordinates.map((column, index) => {
      return {
        id: index + 1,
        name: column?.display_name,
        key: column?.key_name,
        minWidth: column?.key_name === 'sku' ? '350px' : column.width,
        cell: row => <div className="d-flex flex-wrap w-100 gap-4px">
          {Array.isArray(row[column?.key_name])
            ? row[column?.key_name].map((data, index) => <span key={data}>{ }{index !== row[column?.key_name].length - 1 && ','}</span>)
            : column.key_name === 'sku'
              ? getSKu(row)
              : <span>{row[column?.key_name]}</span>
          }
        </div>
      }
    })
    if (showSerialNumber) {
      categories.push([
        {
          id: coordinates.length + 1,
          name: 'S. No.',
          minWidth: '80px',
          maxWidth: '80px',
          cell: (_, index) => <div className="flex-center-center w-100">
            {((pagination.current_page - 1) * pagination.per_page) + index + 1}
          </div>
        }
      ].concat(coordinate))
    } else {
      categories.push(coordinate)
    }

    return {
      series,
      categories
    }
  }
}

export const HANDLE_WIDGET_EXTRACT_DATA = {
  [CHART_TYPES.DONUT]: ({ categories, series, count }) => {
    return {
      categories: categories.slice(0, count),
      series: series.slice(0, count)
    }
  },
  [CHART_TYPES.PIE]: ({ categories, series, count }) => {
    return {
      categories: categories.slice(0, count),
      series: series.slice(0, count)
    }
  },
  [CHART_TYPES.BAR]: ({ categories, series, count}) => {
    if (!categories.length && !series.length) {
      return {
        categories,
        series
      }
    }
    return {
      categories: categories.slice(0, count),
      series: [{data: series[0].data.slice(0, count)}]

    }
  },
  [CHART_TYPES.STACKED_BAR]: ({ categories, series, count }) => {
    return {
      categories: categories.slice(0, count),
      series: series.map(serie => {
        return {
          name: serie.name,
          data: serie.data.slice(0, count)
        }
      })
    }
  },
  [CHART_TYPES.STACKED_BAR_VERTICAL]: ({ categories, series, count }) => {
    return {
      categories: categories.slice(0, count),
      series: series.map(serie => {
        return {
          name: serie.name,
          data: serie.data.slice(0, count)
        }
      })
    }
  },
  [CHART_TYPES.DYNAMIC_STACKED_BAR_HORIZONTAL]: ({ categories, series, count }) => {
    return {
      categories: categories.slice(0, count),
      series: series.map(serie => {
        return {
          name: serie.name,
          data: serie.data.slice(0, count)
        }
      })
    }
  },
  [CHART_TYPES.LINE]: ({ categories, series, count }) => {
    return {
      categories,
      series: series.slice(0, count)
    }
  },
  [CHART_TYPES.TABLE]: ({ categories, series, count }) => {
    return {
      categories: categories.slice(0, count),
      series: series.slice(0, count)
    }
  }
}

export const ANALYTICS_FILTER_TYPE = {
  INTERVAL:{label: 'Interval', value: 'interval', inputType:'dropdown'},
  DATE_TIME:{label: 'Date Time', value: 'datetime', inputType:'datetime_picker'}
}

export const TABLE_MATRIX_CONFIG = {
  DEFAULT: { maxmizerPerPage: 50, paginationMaximizerDisabled: false, minmizerPerPage: 5, paginationMinimizerDisabled: false },
  current_last_mile_status_by_order_date: { maxmizerPerPage: 90, paginationMaximizerDisabled: true, minmizerPerPage: 10, paginationMinimizerDisabled: false },
  current_last_mile_status_by_shipping_account: { maxmizerPerPage: 90, paginationMaximizerDisabled: true, minmizerPerPage: 10, paginationMinimizerDisabled: false }
}

export const ANALYTICS_FILTER_VALUES = {
  HUB: 'hub_id',
  HUBS: 'hub_ids',
  SELLER: 'seller_id',
  SELLERS: 'seller_ids',
  ORDER_TAGS: 'tag_ids',
  SHIPPING_ACCOUNT: 'shipping_accounts',
  SHIPPING_ACCOUNTS: 'shipping_account_ids',
  PAYMENT_METHOD: 'payment_method',
  COUNTRIES: 'country_ids',
  CITIES: 'city_ids',
  TIME_RANGE: 'time_range'
}

const DATETIME_TEMPLATE = (options = {}) => {
  const omitKeys = options.omitKeys || [];

  const values = [
    {
      key_name: 'today',
      display_name: 'Today',
      default: options.defaultToday || false
    },
    {
      key_name: 'last_24_hours',
      display_name: 'Last 24 Hours',
      default: options.default24Hours || false
    },
    {
      key_name: 'last_7_days',
      display_name: 'Last 7 Days',
      default: options.default7Days || false
    },
    {
      key_name: 'last_30_days',
      display_name: 'Last 30 Days',
      default: options.default30Days || false
    },
    {
      key_name: 'custom',
      display_name: 'Custom'
    }
  ];

  const filteredValues = values.filter(value => !omitKeys.includes(value.key_name));

  return {
    key_name: 'datetime',
    display_name: 'Datetime',
    input_source: 'datetime_picker',
    datatype: 'string_array',
    is_required: true,
    values: filteredValues,
    doesNotRequireTime: options.doesNotRequireTime || false
  }
}

export const DEFAULT_ANALYTICS_GLOBAL_DATETIME = DATETIME_TEMPLATE({
  default24Hours: true,
  omitKeys: ['today']
})

export const FULFILMENT_ANALYTICS_DATETIME = DATETIME_TEMPLATE({
  defaultToday: true,
  doesNotRequireTime: true,
  omitKeys: ['last_24_hours','custom']

})

export const LASTMILE_ANALYTICS_DATETIME = DATETIME_TEMPLATE({
  default7Days: true,
  doesNotRequireTime: true,
  omitKeys: ['last_24_hours','custom']
})
