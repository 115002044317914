import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { Col, Row } from 'reactstrap'
import { EDIT_MODAL_TYPE } from '../../sales.constant'
import Edit from '@src/assets/images/icons/Edit'

const ShippingDetails = ({ shippingDetailsData, shipmentCreated, handleEditDetailsModal, ability, t }) => {
  return (<div className="shipping-details border mt-2 rounded-4">
    <h5 className="py-1 px-1 fw-bolder bg-primary-lighter text-secondary mb-0 d-flex justify-content-between rounded-top-4">
      <span className="txt-h3-md">{t('Shipping Details')}</span>
      {!shipmentCreated && ability.can(
        abilityMap.order.edit_details.action,
        abilityMap.order.edit_details.resource
      ) && <span className='txt-h3-md cursor-pointer' onClick={() => { handleEditDetailsModal(EDIT_MODAL_TYPE.SHIPPING_DETAILS.key) }}><Edit height={18} width={18}/></span>}
    </h5>
    <div className="py-1 px-1">
      <Row className="mb-1">
        <Col className="col-3">{t('Recipient\'s Name')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.first_name ? (
            <span>{shippingDetailsData.entity_data.shipping_address.first_name} {shippingDetailsData.entity_data.shipping_address.last_name}</span>
          ) : '-'}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3">{t('Email ID')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.email || '-'}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3">{t('Address')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.display_address}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3">{t('City')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.display_city}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3">{t('State/Country')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.display_state_country}
        </Col>
      </Row>
      {shippingDetailsData.entity_data.shipping_address.display_sales_channel_city && <Row className="mb-1">
        <Col className="col-3">{t('Sales Channel City')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.display_sales_channel_city}
        </Col>
      </Row>}
      <Row>
        <Col className="col-3">{t('Phone number')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address?.phone || '-'}
        </Col>
      </Row>
    </div>
  </div>)
}

export default ShippingDetails