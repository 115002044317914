import BulkActions from '@src/@core/components/ui/bulk-actions/BulkActions'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
// import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import PageHeader from '@src/@core/components/ui/page-header'
import { SORTING_ORDER, TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { SettingsIcon } from '@src/assets/images/icons/primary-navbar/secondary-navbar/tms/tms-logos'
import { AbilityContext } from '@src/utility/context/Can'
import { useFilters, usePagination, useSearch, useSorting } from '@src/utility/hooks/useFilters'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE, CUSTOMER_ADDRESS, CUSTOMER_MODAL_TYPE } from '@src/views/sales/constant/orders.constants'
import { getSellersAsyncData } from '@src/views/settings/store'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { MoreVertical } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import CreateAddress from '../pending-actions/pages/createOrder/CreateAddress'
import CreateCustomer from '../pending-actions/pages/createOrder/CreateCustomer'
import UploadBulkCustomer from '../pending-actions/pages/createOrder/UploadBulkCustomer'
import { clearCustomerAddresses, clearCustomerList, getCustomerDetail, resetSuccess } from '../store/store'
import CustomerDetails from './components/customerDetails'
import PayBalanceModal from './components/payBalanceModal'
import './styles.scss'

const dropdownOptions = [
  { id: 'query', name: 'Customer' },
  { id: 'name', name: 'Customer Name' },
  { id: 'phone_number', name: 'Contact Number' },
  { id: 'email', name: 'Email' }
]


const Customers = () => {
  const pageHeaderProps = {
    breadcrumbIcon: SettingsIcon,
    title: 'Customers',
    breadcrumbsData: [{ title: 'Settings' }]
  }
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const customerDetail = useSelector(store => store.sales.customerDetail)
  const customerMetaData = useSelector(store => store.sales.customerMetaData)
  const success = useSelector(store => store.sales.success)
  const error = useSelector(store => store.sales.error)
  const tenantSellers = useSelector(state => state.auth.userSellers)
  const loading = useSelector(store => store.sales.loading)
  const tenantType = useSelector(state => state.auth.userData.tenant.type)
  const isSellerViewAllowed = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource)

  const [openCreateCustomerSidebar, setOpenCreateCustomerSidebar] = useState({open : false, type : CUSTOMER_MODAL_TYPE.CREATE_CUSTOMER.key})
  const [openCreateAddressSidebar, setOpenCreateAddressSidebar] = useState({ open: false, type: CUSTOMER_ADDRESS.CREATE_ADDRESS.key })
  const [isEditCustomerEnabled, setIsEditCustomerEnabled] = useState(false)
  const [showPayBalanceModal, setShowPayBalanceModal] = useState(false)
  const isPosEnabled = useSelector(store => store.auth.userData?.user_plan?.is_pos_enabled)
  
  const [selectedSeller, setSelectedSeller] = useState(() => {
    if (
      tenantSellers?.length
    ) {
      const firstSeller = tenantSellers[0]
      const sellerObj = {
        value: firstSeller.seller_id,
        label: firstSeller.name,
        code: firstSeller.code
      }
      return sellerObj
    }
    return null
  })

  const [customerTableData, setCustomerTableData] = useState([])
  const [openCustomerDetailsSidebar, setOpenCustomerDetailsSidebar] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [isBulkModalOpen, setBulkModalOpen] = useState(false)

  // custom hooks
  const { filter, setFilter } = useFilters({})
  const { sort, setSort } = useSorting()
  const { pagination, setPagination } = usePagination()
  const { searchQuery, setSearchQuery } = useSearch({ column: dropdownOptions[0] })
  
  const handleCreateUserSidebar = () => {
    setOpenCreateCustomerSidebar({open : true, type : CUSTOMER_MODAL_TYPE.CREATE_CUSTOMER.key})
  }

  const openUploadCustomerBulkModal = () => {
    setBulkModalOpen(true)
  }

  const actions = {
    CREATE_CUSTOMER: {
      id: 'createCustomes',
      text: 'Create Customer',
      onClick: handleCreateUserSidebar
    },
    BULK_UPLOAD_CUSTOMER: {
      id: 'bulkCustomers',
      text: 'Bulk Upload Customers',
      onClick: openUploadCustomerBulkModal
    }
  }

  const handleViewCustomerDetails = (row) => {
    setOpenCustomerDetailsSidebar(true)
    setSelectedCustomer(row)
  }
  const handleEditCustomerDetailsModal = (row) => {
    setOpenCustomerDetailsSidebar(true)
    setSelectedCustomer(row)
    setIsEditCustomerEnabled(true)
  }

  const handlePagination = page => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 20 }
    setPagination(pagination)
  }

  const handleGetAllCustomers = () => {
    const filterParams = {
      seller_id: filter.seller_id ? filter.seller_id.value : selectedSeller?.value
    }
    if(!filterParams.seller_id) return null
    const searchParams = searchQuery.query ? { [searchQuery.column.id]: searchQuery.query } : {}
    dispatch(getCustomerDetail({ ...filterParams, ...searchParams, ...pagination }))
  }

  const handleSort = (sortField) => {
    setSort(sortField)
  }

  // handler functions
  const filterHandler = ({ filterkey, filterValue }) => {
    if (!filterValue) {
      delete filter[filterkey]
      setFilter(filter)
      return
    }
    if (filterkey === 'seller_id') {
      setSelectedSeller(filterValue)
    }
    setFilter({ ...filter, [filterkey]: filterValue })
  }

  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : null
    setSearchQuery(searchQuery)
  }

  const handleShowPayBalanceModal = (row) => {
    setSelectedCustomer(row)
    setShowPayBalanceModal(true)
  }

  const TableColumns = [
    {
      id: 1,
      name: t('S. No.'),
      key: 'srNo',
      minWidth: '50px',
      maxWidth: '60px',
      cell: (row) => {
        return (
          <div className="w-100 text-center">
            {row.serialNumber}
          </div>
        )
      }
    },
    {
      id: 2,
      name: t('Customer Name'),
      minWidth: '200px',
      key: 'customerName',
      cell: row => <span className='text-primary cursor-pointer' onClick={() => { handleViewCustomerDetails(row) }}>{`${row.first_name} ${row.last_name}`}</span> 
    },
    {
      id: 3,
      name: t('Email ID'),
      key: 'email',
      reorder: true,
      minWidth: '200px',
      selector: row => row.email || '-'
    },
    {
      id: 4,
      name: t('Contact Number'),
      key: 'contactNo',
      reorder: true,
      minWidth: '200px',
      selector: row => row.phone_number ? `${row.country_calling_code}-${row.phone_number}` : '-'
   
    },
    {
      id: 5,
      name: t('Balance'),
      key: 'balance',
      reorder: true,
      minWidth: '200px',
      omit: !isPosEnabled,
      cell: (row) => <div className={classNames(
        {
          'text-danger': row.balance?.value < 0,
          'text-success': row.balance?.value > 0
        })}>
        {row.balance?.value ? `${row.balance.value}` : '0'}
      </div>
    },
    {
      id: 6,
      name: t('Action'),
      width: '70px',
      key: 'actions',
      allowOverflow: true,
      omit:!ability.can(
        abilityMap.customer.edit.action,
        abilityMap.customer.edit.resource
      ),
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center align-items-center w-100 cursor-pointer">
            <UncontrolledDropdown>
              <DropdownToggle className="pe-1 more_vert" tag="span">
                <MoreVertical size={20} />
              </DropdownToggle>
              {(ability.can(abilityMap.customer.edit.action, abilityMap.customer.edit.resource) || ability.can(abilityMap.pos.view.action, abilityMap.pos.view.resource)) &&
                <DropdownMenu end container="body">
                  {ability.can(abilityMap.customer.edit.action, abilityMap.customer.edit.resource) &&
                    <DropdownItem className="w-100 text-dark" onClick={() => { handleEditCustomerDetailsModal(row) }}>
                      <span className="align-middle ms-50">{t('Edit')}</span>
                    </DropdownItem>
                  }
                  {isPosEnabled &&
                    <DropdownItem className="w-100 text-dark" onClick={() => handleShowPayBalanceModal(row)}>
                      <span className="align-middle ms-50">{t('Pay Account Balance')}</span>
                    </DropdownItem>
                  }
                </DropdownMenu>}
            </UncontrolledDropdown>
          </div>
        )
      }
    }
  ]
  const defaultSortColumn = TableColumns.find((column) => column.key === sort.field) || {}

  useEffect(() => {
    const newTableData = []
    if (customerDetail) {
      customerDetail.map((customer, index) => {
        newTableData.push({
          ...customer,
          serialNumber: ((customerMetaData.current_page - 1) * customerMetaData.per_page) + index + 1
        })
      })
    }
    setCustomerTableData(newTableData)
  }, [customerDetail])

  useEffect(() => {
    if (success.createCustomer) {
      handleGetAllCustomers()
      dispatch(resetSuccess())
      setOpenCreateCustomerSidebar(prev => ({...prev, open: false}))
    }
    if (success.editCustomer) {
      handleGetAllCustomers()
      dispatch(resetSuccess())
      setOpenCustomerDetailsSidebar(false)
    }
  }, [success])

  useEffect(() => {
    handleGetAllCustomers()
    dispatch(clearCustomerAddresses())
    return () => { dispatch(clearCustomerList()) }
  }, [])


  return (
    <div className="all-customers-page-wrapper">
      <div className="customers-container">
        <div className='page-header-container'>
          <PageHeader
            {...pageHeaderProps}
          />
        </div>
        {(tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed) && <div className="px-1 pt-1 flex-center-between gap-1">
          <DropdownWithTitle
            isAsync
            title='Sellers'
            isClearable={!!filter.seller_id}
            defaultValue={selectedSeller}
            value={(() => {
              if (!filter.seller_id) return selectedSeller
              return filter.seller_id
            })()}
            loadOptionsHandler={getSellersAsyncData}
            selectOptionHandler={(value) => { filterHandler({ filterkey: 'seller_id', filterValue: value }) }}
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
          />
        </div>}
        <div className="p-1">
          <ExpandableCustomTable
            loading={loading.getCustomerDetail}
            error={error.getCustomerDetail}
            success={success.getCustomerDetail}
            isFilterOrSearchApplied={!!(Object.values(filter).length || Object.values(searchQuery).length > 1)}
            data={customerTableData}
            columns={TableColumns}
            columnName={ALL_COLUMN_NAME_MAPPING.CUSTOMERS}
            searchcolumnsList={dropdownOptions}
            search_column_query={searchQuery}
            handleQueryParams={handleSearchItem}
            meta={customerMetaData || {}}
            useReactPaginate={false}
            handlePagination={handlePagination}
            handleRefreshTable={handleGetAllCustomers}
            showColumnsTableHeader={true}
            handleSort={handleSort}
            defaultSortFieldId={defaultSortColumn.id}
            defaultSortAsc={sort.order === SORTING_ORDER.ASCENDING}
            TableHeaderComponent={ability.can(
              abilityMap.customer.create.action,
              abilityMap.customer.create.resource
            ) && selectedSeller && 
              <div className="d-flex gap-1 ps-14px border-start border-primary-lighter customer-bulk-action-wrapper">
                <BulkActions color="primary" actions={Object.values(actions).filter(opt => opt.id !== actions.CREATE_CUSTOMER.id)} selectedButton={actions.CREATE_CUSTOMER}
                />
              </div>  
            }
          />
        </div>
      </div>
      <CreateCustomer
        openCreateCustomerSidebar={openCreateCustomerSidebar}
        setOpenCreateCustomerSidebar={setOpenCreateCustomerSidebar}
        selectedSeller={filter.seller_id || selectedSeller}
      />
      <CustomerDetails 
        openCustomerDetailsSidebar={openCustomerDetailsSidebar}
        setOpenCustomerDetailsSidebar={setOpenCustomerDetailsSidebar}
        customerDetails={selectedCustomer}
        setOpenCreateAddressSidebar={setOpenCreateAddressSidebar}
        selectedSeller={filter.seller_id || selectedSeller}
        handleGetAllCustomers={handleGetAllCustomers}
        isEditCustomerEnabled={isEditCustomerEnabled}
        setIsEditCustomerEnabled={setIsEditCustomerEnabled}
      />
      <CreateAddress
        openCreateAddressSidebar={openCreateAddressSidebar}
        setOpenCreateAddressSidebar={setOpenCreateAddressSidebar}
        customerDetails={selectedCustomer}
      />
      <UploadBulkCustomer
        openUploadBulkModal={isBulkModalOpen}
        setOpenUploadBulkModal={setBulkModalOpen}
      />
      <PayBalanceModal
        isOpen={showPayBalanceModal}
        toggle={() => setShowPayBalanceModal(false)}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        handleGetAllCustomers={handleGetAllCustomers}
      />
    </div>
  )
}

export default Customers