import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import InputField from '@src/@core/components/ui/input-field'
import Select from '@src/@core/components/ui/select'
import { TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import deleteImage from '@src/assets/images/icons/delete-icon.svg'
import ability from '@src/configs/acl/ability'
import { axiosInstance } from '@src/network/AxiosInstance'
import {
  BUNDLE_SKU_FIELDS_NAME,
  BUNDLE_SKU_FORM_VALIDATION_SCHEMA,
  CATALOG_FLOATING_YES_AND_NO_OPTIONS,
  LABELS_ADDITIONAL_OPTIONS
} from '@src/views/catalog/catalog.constants'
import { getSellersAsync, getSkusLabels } from '@src/views/catalog/store'
import { getTaxCategoriesData } from '@src/views/pos/pos.apis'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { components } from 'react-select'
import AddSkuLabelModal from '../single-sku-create/AddSkuLabelModal'
import DeleteSkuTag from '../single-sku-create/DeleteSkuTag'
import Edit from '@src/assets/images/icons/Edit'

export default function BundleDetailsSection({
  errors, register, Vector, t, tenantType, selectedSeller, watch, isEdit, singleSkuLoading, bundleCreationLoading, updateBundleSkusRes, imageGettingUpload, setSellerChangedWarningModal, setValue, bundleSkus, setStoreChangedSeller, setIsSellerChangeWarningVisibled, control, isPosEnabled
}) {

  const [isOpenAddSkuLabelModal, setIsOpenAddSkuLabelModal] = useState(false)
  const [label, setLabel] = useState('')
  const [isOpenDeleteSkuTagModal, setIsOpenDeleteSkuTagModal] = useState({isOpen: false, data: null})
  const [isLabelEdit, setIsLabelEdit] = useState(false)
  const [labelId, setLabelId] = useState(null)
  const initialLabelValue = useRef(label)
  const [isLabelSuccess, setIsLabelSuccess] = useState(false)
  const [skuTagDeleted, setSkuTagDeleted] = useState(false)
  const isSellerViewAllowed = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource)
  const globalSeller = useSelector(state => state.auth.globalSeller)
  const isLoading = bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload


  const sellerValueChangeHandler = (selectedSellerData) => {
    if (bundleSkus.length && (selectedSellerData.value !== selectedSeller.value)) {
      setSellerChangedWarningModal(true)
      setStoreChangedSeller(selectedSellerData)
      setIsSellerChangeWarningVisibled(true)
      return
    }
    setValue(BUNDLE_SKU_FIELDS_NAME.SELLER, selectedSellerData)
  }

  const taxValidator = () => {
    if (Number(watch(BUNDLE_SKU_FIELDS_NAME.TAX_PERCENTAGE)) > 100 || watch(BUNDLE_SKU_FIELDS_NAME.TAX_PERCENTAGE).length > 4) {
      return 'Please provide valid tax percentage.'
    }
    return undefined
  }

  const toggleDeleteSkuTagModal = ({data}) => {
    if (isOpenDeleteSkuTagModal.isOpen) {
      setIsOpenDeleteSkuTagModal({isOpen: !isOpenDeleteSkuTagModal.isOpen, data: null})
    } else setIsOpenDeleteSkuTagModal({isOpen: !isOpenDeleteSkuTagModal.isOpen, data})
  }

  const toggleAddLabelModal = () => {
    if (isLabelEdit) {
      setLabelId(null)
      setIsLabelEdit(false)
    }
    setIsOpenAddSkuLabelModal(p => !p)
  }

  const { mutate: addSkuLabel, isPending: isAddLocationAttributePending, isSuccess: isAddLocationAttributeSuccess } = useMutation({
    mutationKey: ['add-label'],
    mutationFn: ({ body }) => axiosInstance.post('/api/v1/products/labels', body),
    onSuccess: ({ data }) => {
      CustomToast('Sku tag added successfully', { my_type: 'success' })
      setValue(BUNDLE_SKU_FIELDS_NAME.LABELS, [...watch(BUNDLE_SKU_FIELDS_NAME.LABELS), { label: data.data?.name, value: data.data?.id }])
    }
  })

  const handleSkuLabelChange = (e) => {
    const filteredOptions = e.filter(option => option.tag !== LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value)
    const matchedOption = e.find(option => option.tag === LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value)

    if (matchedOption?.tag === LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value) {
      addSkuLabel({ body: { name: matchedOption.value.trim() } })
      setValue(BUNDLE_SKU_FIELDS_NAME.LABELS, filteredOptions)
    } else {
      setValue(BUNDLE_SKU_FIELDS_NAME.LABELS, e)
    }
  }

  const CustomDropdownOption = (props) => {
    const { data: { label, color, value, tag } } = props
    const { Option } = components

    const handleEdit = (event) => {
      event.stopPropagation()
      setLabel(label)
      initialLabelValue.current = label
      setIsLabelEdit(true)
      setLabelId(value)
      toggleAddLabelModal()
    }

    const handleDelete = (event) => {
      event.stopPropagation()
      toggleDeleteSkuTagModal({isOpen: true, data: {label, value, sellerId: selectedSeller?.value}})
    }

    return (
      <Option key={value} {...props}>
        <div className="custom-option flex-center-between" title={label}>
          <div className='d-flex gap-20px align-items-center w-50'>
            <div className={classNames('cursor-pointer text-truncate', { 'rounded px-4px py-2px': color })}>
              {label}
            </div>
          </div>
          {tag !== LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value ?
            <div className="edit-icon d-flex gap-12px align-items-center">
              <span onClick={handleEdit}>
                <Edit title="Edit" height={16} width={16} />
              </span>
              <img src={deleteImage} alt="delete" title='Delete' height={16} width={16}  onClick={handleDelete}/>
            </div> : null
          }
        </div>
      </Option>
    )
  }

  return (
    <div className='d-flex flex-column gap-30px w-100 bundle-detail-input-fields'>
      {(tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed) &&
        <div className='two-col-section d-flex gap-1 w-100'>
          <Controller
            name={BUNDLE_SKU_FIELDS_NAME.SELLER}
            control={control}
            
            render={({ field }) => (
              <Select
                isAsync
                {...field}
                isRequired
                width="100%"
                label="Select Seller"
                loadOptions={getSellersAsync} 
                isDisabled={
                  isEdit || isLoading
                }
                onChange={(selectedOption) => {
                  field.onChange(selectedOption); 
                  sellerValueChangeHandler(selectedOption);
                }}
                errors={errors} 
              />
            )}
          />

          
          <div className='w-100'>
            {/* <InputField
            width='100%'
            name='confirm_seller'
            label='Confirm Seller'
            isRequired
            isClearable
            id='confirm-seller-name'
            value={confirmSellerInpValue}
            disabled={isBundleEdit && singleSkuLoading}
            errors={confirmSellerInpValue && !isSellerMatched ? { confirm_seller: {message: 'Seller name does not match!'} } : null}
            onChange={(e) => confirmHandler(e)}
            /> */}
          </div>
        </div>
      }
      {/* <div>
      </div> */}
      <div className='two-col-section d-flex gap-1 w-100'>
        <InputField
          width='100%'
          label='Kit Name'
          name={BUNDLE_SKU_FIELDS_NAME.SKU_NAME}
          isRequired
          isClearable
          errors={errors}
          disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
          {...register(BUNDLE_SKU_FIELDS_NAME.SKU_NAME, BUNDLE_SKU_FORM_VALIDATION_SCHEMA[BUNDLE_SKU_FIELDS_NAME.SKU_NAME])} />
        <InputField
          width='100%'
          name={BUNDLE_SKU_FIELDS_NAME.SKU_CODE}
          label='SKU Code'
          isRequired
          isClearable
          register={register}
          errors={errors}
          disabled={isEdit || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
          {...register(BUNDLE_SKU_FIELDS_NAME.SKU_CODE, BUNDLE_SKU_FORM_VALIDATION_SCHEMA[BUNDLE_SKU_FIELDS_NAME.SKU_CODE])} />
        
        {/* <FloatingDropDown
          width='401px'
          title='Unit of Measurement'
          name={BUNDLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT}
          options={[{label: 'KG', value: 'kg'}]}
          isRequired
          isClearable
          value={watch('measurement_unit')}
          control={control}
          errors={errors}
          validationSchema={{
            measurement_unit: BUNDLE_SKU_FORM_VALIDATION_SCHEMA[BUNDLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT]
          }} /> */}
          
        
      </div>
      <div className='w-100'>
        <Controller
          name={BUNDLE_SKU_FIELDS_NAME.LABELS}
          control={control}
          render={({ field, ref }) => {
            return (
              <Select
                {...field}
                ref={ref}
                label="SKU Tags"
                isMulti
                isAsync
                loadOptions={getSkusLabels}
                isClearable
                cacheUniqs={[selectedSeller?.value, isLabelSuccess, isAddLocationAttributeSuccess, skuTagDeleted]}
                additional={{ sellerId: selectedSeller?.value, page: 1 }}
                isDisabled={isAddLocationAttributePending}
                customComponents={{ Option: CustomDropdownOption }}
                closeMenuOnSelect={false}
                onChange={(e) => {
                  field.onChange(e)
                  handleSkuLabelChange(e)
                }}
                modifiedClassNames={{
                  option: (state) => {
                    return classNames('cursor-pointer txt-sub-rg font-normal select-option', {
                      'bg-primary text-white': state.isSelected,
                      'bg-primary-lighter': state.isFocused && !state.isSelected,
                      'text-primary': state.data.tag === LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value,
                      'text-dark': state.data.tag !== LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value
                    })
                  }
                }}
              />
            )
          }}
        />
        <AddSkuLabelModal isOpen={isOpenAddSkuLabelModal} toggle={toggleAddLabelModal} label={label} setLabel={setLabel} labelId={labelId} isEdit={isLabelEdit} isButtonDisabled={initialLabelValue.current === label} setIsLabelSuccess={setIsLabelSuccess} />
        <DeleteSkuTag isOpen={isOpenDeleteSkuTagModal.isOpen} toggle={() => toggleDeleteSkuTagModal({isOpen: false, data: null})} data={isOpenDeleteSkuTagModal.data} setSkuTagDeleted={setSkuTagDeleted} />
      </div>
      <div className='d-flex flex-column gap-6px'>
        <InputField
          width='100%'
          label='Write description here'
          className='text-area'
          type='textarea'
          name={BUNDLE_SKU_FIELDS_NAME.DESCRIPTION}
          value={watch(BUNDLE_SKU_FIELDS_NAME.DESCRIPTION)}
          disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
          maxLength='3000'
          {...register(BUNDLE_SKU_FIELDS_NAME.DESCRIPTION)} />
        <div className='d-flex gap-4px align-items-center'>
          <img src={Vector} />
          <div className='text-dark-5 txt-asst-rg'>
            {t('Maximum 3000 Characters')}
          </div>
        </div>
      </div>
      <div>
        <div className='txt-h3-sb text-dark pb-2'>{t('Price Details')}</div>
        <div className='d-flex gap-16px'>
          <div className='w-100'>
            {          
              <Controller
                name={BUNDLE_SKU_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    width="100%"
                    label="Price Includes Tax" 
                    options={CATALOG_FLOATING_YES_AND_NO_OPTIONS}
                    isDisabled={
                      (isEdit && singleSkuLoading) ||isLoading 
                    }
                  />
                )}
              />


            }
          </div>

          <div className='w-100'>
            {isPosEnabled ?
              <Controller
                name={BUNDLE_SKU_FIELDS_NAME.TAX_CATEGORY}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isAsync
                    width="100%"
                    label="Tax Category" 
                    loadOptions={getTaxCategoriesData}
                    isDisabled={
                      (isEdit && singleSkuLoading) ||isLoading
                    }
                    additional={{
                      page: 1,
                      seller_id: globalSeller.seller_id
                    }}
                   
                  />
                )}
              />

              
              :
              <InputField
                width='100%'
                label='Tax Percentage'
                name={BUNDLE_SKU_FIELDS_NAME.TAX_PERCENTAGE}
                isClearable
                type='number'
                errors={errors}
                {...register(BUNDLE_SKU_FIELDS_NAME.TAX_PERCENTAGE, { validate: taxValidator })}
                disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              />
            
            }
          </div>
        </div>
      </div>
    </div>
  )
}
