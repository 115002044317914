const InfoFilledIcon = ({fillColor='var(--bs-warning)', width=20, height=20}) => {
  return (
    <svg width={width} height={height}  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Info filled">
        <path id="Vector" d="M9.43746 14.1665H10.6875V9.1665H9.43746V14.1665ZM9.99958 7.62484C10.1943 7.62484 10.3576 7.56095 10.4895 7.43317C10.6215 7.30539 10.6875 7.14706 10.6875 6.95817C10.6875 6.75748 10.6216 6.58925 10.4899 6.45348C10.3582 6.31772 10.195 6.24984 10.0003 6.24984C9.80564 6.24984 9.64232 6.31772 9.51038 6.45348C9.37843 6.58925 9.31246 6.75748 9.31246 6.95817C9.31246 7.14706 9.37831 7.30539 9.51 7.43317C9.64171 7.56095 9.8049 7.62484 9.99958 7.62484ZM10.0055 18.3332C8.85642 18.3332 7.77656 18.1144 6.76592 17.6769C5.75528 17.2394 4.87149 16.6422 4.11454 15.8853C3.3576 15.1283 2.76038 14.244 2.32288 13.2323C1.88538 12.2206 1.66663 11.1397 1.66663 9.98942C1.66663 8.83916 1.88538 7.75819 2.32288 6.74652C2.76038 5.73484 3.3576 4.854 4.11454 4.104C4.87149 3.354 5.7558 2.76025 6.76748 2.32275C7.77915 1.88525 8.86011 1.6665 10.0104 1.6665C11.1606 1.6665 12.2416 1.88525 13.2533 2.32275C14.265 2.76025 15.1458 3.354 15.8958 4.104C16.6458 4.854 17.2395 5.73595 17.677 6.74984C18.1145 7.76373 18.3333 8.84521 18.3333 9.9943C18.3333 11.1434 18.1145 12.2232 17.677 13.2339C17.2395 14.2445 16.6458 15.127 15.8958 15.8814C15.1458 16.6358 14.2638 17.233 13.25 17.6731C12.2361 18.1131 11.1546 18.3332 10.0055 18.3332Z" fill={fillColor}/>
      </g>
    </svg>
  )
}

export default InfoFilledIcon

