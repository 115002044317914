/* eslint-disable @typescript-eslint/no-explicit-any */
import PageHeader from '@src/@core/components/ui/page-header';
import Omniship from '@src/assets/images/icons/primary-navbar/Omniship';
import CreateShipmentOrderForm from './create-shipment-order-form';
import './style.scss';

const CreateShipmentOrder = () => {
  const pageHeaderProps: any = {
    breadcrumbIcon: Omniship,
    title: 'Create Shipment Order',
    breadcrumbsData: [{ title: 'OmniShip' }],
  };
  //todo: fix bottom cta botton
  return (
    <div className='create-shipment-order'>
      <PageHeader {...pageHeaderProps} />
      <CreateShipmentOrderForm />
    </div>
  );
};

export default CreateShipmentOrder;
