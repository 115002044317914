import ChevronDownCircle from '@src/assets/images/icons/chevron-down-circle';
import ChevronUpCircle from '@src/assets/images/icons/chevron-up-circle';
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

interface iCollapsibleField {
  label: string;
  value: string | number;
}

interface iCollapsibleKeyValueListProps {
  label: string;
  value: string | number;
  collapsibleFields: iCollapsibleField[];
}

const CollapsibleKeyValueList: React.FC<iCollapsibleKeyValueListProps> = ({ label, value, collapsibleFields }) => {

  const [isCollapsed, setIsCollapsed] = useState(false);
  const canToggleCollapse = collapsibleFields.length > 0;
  const toggleCollapse = () => {
    if (!canToggleCollapse) return;
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className='d-flex flex-column gap-12px'>
      <div className='text-dark flex-center-between cursor-pointer' onClick={toggleCollapse}>
        <div className='txt-h3-rg flex-center-start gap-8px'>
          <span className='text-dark-5'>{label}</span>
          {canToggleCollapse ? <span>
            {isCollapsed ? (
              <ChevronUpCircle primaryColor='var(--bs-primary)' secondaryColor='var(--bs-primary-lighter)' />
            ) : (
              <ChevronDownCircle primaryColor='var(--bs-primary)' secondaryColor='var(--bs-primary-lighter)' />
            )}
          </span> : null}
        </div>
        <div className='txt-h3-rg'>{value}</div>
      </div>
      <Collapse isOpen={isCollapsed}>
        <div className='bg-light-1 rounded-8px d-flex flex-column gap-16px p-12px'>
          {collapsibleFields.map(field => (
            <div key={field.label} className='flex-center-between text-dark-6 txt-body-rg'>
              <div className='text-dark-5'>{field.label}</div>
              <div className='text-dark'>{field.value}</div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsibleKeyValueList;