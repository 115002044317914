/* eslint-disable @typescript-eslint/no-explicit-any */
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner';
import PublicNavbarHeader from '@src/@core/layouts/components/navbar/public-navbar-header';
import { checkDomainExistance } from '@src/redux/authentication';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PublicTrackingPageInfo from './components/public-tracking-page-info';
import './styles.scss';

const PublicTracking = () => {
  // @ts-expect-error fix
  const domainData = useSelector(store => store.auth.domainData);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  useEffect(() => {
    // @ts-expect-error fix
    if (!domainData) dispatch(checkDomainExistance());
  }, []);

  if (!domainData) {
    return <div className='d-flex vh-100 vw-100 align-items-center justify-content-center'>
      <ComponentSpinner className='' />
    </div>;
  }

  return (
    <div id='public-tracking-page'> 
      <div className='public-page-navbar'>
        <PublicNavbarHeader />
      </div>
      <div className="d-flex flex-column w-100 px-12px gap-40px public-tracking-page-header">
        <div className="d-flex flex-column align-items-center gap-2">
          <div className="txt-hero text-dark">{t('Track Your Packages')}</div> 
          <span className='txt-h3-rg text-dark-6 text-center'>{t('Enter your Order ID/Tracking ID to view the status and progress of each package within your order.')}</span> 
        </div>
        <div className='w-100 d-flex align-items-start justify-content-center'>
          <PublicTrackingPageInfo/>
        </div>
      </div>
    </div>
  );
};

export default PublicTracking;