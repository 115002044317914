import Banner from '@src/@core/layouts/components/banner/Banner';
import { BANNER_KEYS, BANNER_TYPES, BANNER_VARIANTS, ORDER_SOURCE } from '@src/App.constants';
import { ORDER_STATUS } from '@src/views/sales/constant/orders.constants';
import { AUTOMATION_RULE_ORDER_ITEM_STATUS } from '@src/views/sales/sales.constant';
import { useTranslation } from 'react-i18next';

const OrderInfobanner = ({ orderDetails }) => {
  const { t } = useTranslation();

  const assignedHub =
    orderDetails.status === ORDER_STATUS.ON_HOLD.name
      ? orderDetails.assigned_hub?.name
      : orderDetails.hub.name;

  const hubReassignmentDetails = orderDetails.hub_reassignment_details.previous_hub_name;

  const banners = [
    {
      show: orderDetails.is_quantity_edited,
      content:
        orderDetails.source === ORDER_SOURCE.MANUAL.label
          ? t('The order items have been edited.')
          : t('The order has been synchronised and item quantities have been updated accordingly.'),
    },
    {
      show: orderDetails.is_hub_reassigned,
      content: `The order has been reassigned from ${hubReassignmentDetails} to ${assignedHub}.`,
    },
    {
      show:
        orderDetails.automation_rule_items_details.items_status ===
        AUTOMATION_RULE_ORDER_ITEM_STATUS.REMOVED,
      content: t(
        'Gift item(s) have been removed as the updated order no longer satisfies the gifting automation rule.'
      ),
    },
  ].filter((banner) => banner.show);

  if (!banners.length) {
    return null;
  }


  return (
    <div className="mb-24px mx-0">
      { 
        banners.length > 1 ? (
          <Banner 
            type={BANNER_TYPES.NOTIFICATION}
            variant={BANNER_VARIANTS.INFO}
            bannerKey={BANNER_KEYS.ORDER_INFO_BANNER}
          >
            <div className='d-flex flex-column gap-8px'>
              <div className='txt-body-sb'>{t('Please Note')}</div>
              <ul className="m-0 ps-20px d-flex flex-column txt-sub-rg gap-4px">
                {banners.map((banner, index) => <li key={index}>
                  {banner.content}
                </li>
                )}
              </ul>
            </div>
          </Banner>
        )
          : (
            <Banner 
              text={banners[0].content} 
              type={BANNER_TYPES.NOTIFICATION}
              variant={BANNER_VARIANTS.INFO}
              bannerKey={BANNER_KEYS.ORDER_INFO_BANNER}
            />
          )
      }
    </div>
  );
};

export default OrderInfobanner;
