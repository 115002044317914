import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import BreadCrumbs from './BreadCrumbs'
import SideSheetCloseButton from './sideSheetCloseButton'
import './styles.scss'

const PageHeader = ({
  hasBackButton,
  backButtonHandler,
  breadcrumbIcon,
  tag,
  breadcrumbsData,
  title,
  subTextData,
  hasClearButton,
  clearButtonHandler
}) => {
  const {t} = useTranslation()
  // Render the subtext elements based on the subTextData prop
  const renderSubText = subTextData ? subTextData.map((text, idx) => (text ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '9px'
      }}
      key={idx}
    >
      <span className="txt-sub-rg text-dark-6">{t(text)}</span>
      {idx < subTextData.length - 1 && (
        <svg
          width="4"
          height="4"
          viewBox="0 0 4 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.98852 3.564C1.70052 3.564 1.43652 3.496 1.19652 3.36C0.964516 3.216 0.776516 3.028 0.632516 2.796C0.496516 2.556 0.428516 2.292 0.428516 2.004C0.428516 1.564 0.580516 1.192 0.884516 0.888C1.18852 0.584 1.55652 0.432 1.98852 0.432C2.43652 0.432 2.81252 0.584 3.11652 0.888C3.42052 1.192 3.57252 1.564 3.57252 2.004C3.57252 2.444 3.42052 2.816 3.11652 3.12C2.81252 3.416 2.43652 3.564 1.98852 3.564Z"
            fill="#666666"
          />
        </svg>
      )}
    </div>
  ) : null)) : null
  
  return (
    <div className="omnifulPageHeader bg-white flex-start-between" >
      {/* Render the BreadCrumbs component */}
      <div>
        <BreadCrumbs
          hasBackButton={hasBackButton}
          backButtonHandler={backButtonHandler}
          icon={breadcrumbIcon}
          tag={tag}
          data={breadcrumbsData}
          title={title ? typeof title === 'string' ?  t(title) : title : null}
        />
        {/* Render the subtext elements */}
        {renderSubText?.length>0 && <div className="omnifulPageHeader__subText mt-6px">{renderSubText}</div>}
      </div>
      {hasClearButton &&
        <SideSheetCloseButton clearButtonHandler={clearButtonHandler}/>
      }
    </div>
  )
}

// Define prop types for the PageHeader component
PageHeader.propTypes = {
  hasBackButton: PropTypes.bool, // Whether to display a back button
  breadcrumbIcon: PropTypes.elementType, // Icon component to display in breadcrumbs
  tag: PropTypes.shape({
    icon: PropTypes.elementType, // Icon component to display in tag
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.elementType
    ]) // Text to display in tag
  }),
  breadcrumbsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object
      ]), // Title for each breadcrumb item
      link: PropTypes.string // Link for each breadcrumb item
    })
  ),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]), // Main title text
  subTextData: PropTypes.arrayOf(PropTypes.string) // Array of subtext strings
}

// Set default values for the PageHeader component props
PageHeader.defaultProps = {
  hasBackButton: false,
  breadcrumbIcon: null,
  tag: null,
  breadcrumbsData: null,
  title: '',
  subTextData: null
}

export default PageHeader