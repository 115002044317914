/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@src/@core/components/ui/button';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import InputField from '@src/@core/components/ui/input-field';
import PhoneNumberDropdown from '@src/@core/components/ui/phone-number';
import Select from '@src/@core/components/ui/select';
import SideSheet from '@src/@core/components/ui/sideSheet';
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer';
import { loadCitiesConfig, loadCountryConfig, loadStatesCitiesConfig, loadStatesConfig } from '@src/app.apis';
import { REGEX_PATTERN } from '@src/App.constants';
import { axiosInstance } from '@src/network/AxiosInstance';
import { getOptionsFetcher } from '@src/utility/Utils';
import { Countries } from '@src/views/hubs/utils/countries';
import { SHIPPING_ENTITY } from '@src/views/sales/constant/orders.constants';
import { SHIPPING_AGGREGATOR_API_KEYS } from '@src/views/shipping-aggregator/shipping-aggregator.apis';
import { EDIT_SHIPPING_DETAILS_MODAL_TYPE } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, ModalBody, Spinner } from 'reactstrap';
// type iEditDetailStruct = {
//   first_name: string,
//   last_name: string,
//   email: string,
//   address1: string,
//   address2: string,
//   city: null,
//   country: null,
//   state: null,
//   zip: string,
//   phone: string,
//   mobile: string,
//   country_code: string
//   omniful_country: string,
//   omniful_country_id: string,
//   omniful_city: string,
//   omniful_city_id: string,
//   omniful_state: string,
//   omniful_state_id: string,
//   country_currency_code: string,
//   state_code
// }

type iEditCustomerAndShippingDetails = {
  isModalOpen: {
    key: string;
    open: boolean
  };
  // eslint-disable-next-line no-unused-vars
  setIsModalOpen: (data:any) => void;
  entity_name?: string;
  order_id: string;
  editDetails: any
  funcHandlerOnSuccess: () => void
}

const EditCustomerAndShippingDetails = ({ isModalOpen, setIsModalOpen, editDetails, order_id, entity_name = '', funcHandlerOnSuccess }: iEditCustomerAndShippingDetails) => {
  const { t } = useTranslation();
  const { register, control, watch, reset, handleSubmit, setValue, setError, clearErrors, formState: { errors, isDirty }} = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: {
        countryCode: '',
        contactNumber: ''
      },
      address1: '',
      address2: '',
      city: null,
      country: null,
      state: null,
      zip:''
    },
    mode: 'onChange'
  }) as any;

  console.log({isModalOpen});

  const { mutate: editShippingAndCustomerAddress, isPending: isEditShippingAndCustomerAddress } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.MARK_AS_DELIVERED],
    mutationFn: ({ orderId, body}: {orderId: string | number, body: {status: string}}) => {
      return axiosInstance.put(`/api/v1/shipping_aggregator/orders/${orderId}/edit_details`, body);
    },
    onSuccess: () => {
      funcHandlerOnSuccess();
      CustomToast('Shipping address edited successfully', { my_type: 'success' });
    }
  });
  
  const handleOnOpened = () => {
    if (EDIT_SHIPPING_DETAILS_MODAL_TYPE.CUSTOMER_DETAILS.key === isModalOpen.key) {
      const mobileDetails = editDetails.mobile.split('-');
      const countryData = mobileDetails.length === 2 ? Countries.find(country => country.calling_code === mobileDetails[0]) : '';
      reset({
        first_name: editDetails.first_name || '',
        last_name: editDetails.last_name || '',
        email: editDetails.email || '',
        mobile: {
          contactNumber: mobileDetails[1] || mobileDetails[0] || '',
          countryCode:countryData || ''
        }
      });
    } else {
      const mobileDetails = editDetails.phone.split('-');
      const countryData = mobileDetails.length === 2 ? Countries.find(country => country.calling_code === mobileDetails[0]) : '';
      reset({
        mobile: {
          contactNumber: mobileDetails[1] || mobileDetails[0] || '',
          countryCode:countryData || ''
        },
        first_name: editDetails.first_name || '',
        last_name: editDetails.last_name || '',
        email: editDetails.email || '',
        address1:editDetails.address1 || '',
        address2:editDetails.address2 || '',
        country: editDetails.omniful_country ? {label: editDetails.omniful_country, value: editDetails.omniful_country_id, code:editDetails.country_code, currencyCode:editDetails.country_currency_code} : null,
        state:editDetails.omniful_state ? {label: editDetails.omniful_state, value: editDetails.omniful_state_id, code:editDetails.state_code} : null,
        city:editDetails.omniful_city ? {label: editDetails.omniful_city, value: editDetails.omniful_city_id} : null,
        zip: editDetails.zip || ''
      });
    }
  };
  const handleOnClosed = () => {
    reset();
  };
  // @ts-expect-error fix
  const loadCountryOptions = getOptionsFetcher(loadCountryConfig);
  const handleEditSubmit = (data: any) => {
    let body: any = {};
    if (EDIT_SHIPPING_DETAILS_MODAL_TYPE.CUSTOMER_DETAILS.key === isModalOpen.key) {
      body = {
        customer: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email || undefined,
          mobile_number: {
            number: data.mobile.contactNumber,
            country_calling_code: data.mobile.countryCode.calling_code,
            country_code: data.mobile.countryCode.country_code
          }
        },
        entity_type:EDIT_SHIPPING_DETAILS_MODAL_TYPE.CUSTOMER_DETAILS.value
      };
      // if (data.mobile.contactNumber) {
      //   body = {
      //     ...body,
      //     [EDIT_SHIPPING_DETAILS_MODAL_TYPE.CUSTOMER_DETAILS.value]: {
      //       ...body.customer,
      //       mobile_number: {
      //         number: data.mobile.contactNumber,
      //         country_calling_code: data.mobile.countryCode.calling_code,
      //         country_code: data.mobile.countryCode.country_code
      //       }
      //     }
      //   };
      // }      
    } else {
      body = {
        [EDIT_SHIPPING_DETAILS_MODAL_TYPE.SHIPPING_ADDRESS.value]: {
          first_name: data.first_name,
          last_name: data.last_name,
          address1: data.address1,
          address2: data.address2,
          zip: data.zip || undefined,
          email: data.email || undefined,
          country_currency: {
            code: data.country?.currencyCode,
            name: data.country?.currenyName
          },
          omniful_country_id: data.country?.value,
          omniful_state_id: data.state?.value,
          omniful_city_id: data.city?.value,
          omniful_country: data.country?.label,
          omniful_state: data.state?.label,
          omniful_city: data.city?.label,
          mobile_number: {
            number: data.mobile.contactNumber,
            country_calling_code: data.mobile.countryCode.calling_code,
            country_code: data.mobile.countryCode.country_code
          }
        },
        entity_type:EDIT_SHIPPING_DETAILS_MODAL_TYPE.SHIPPING_ADDRESS.value
      };
      // if (data.mobile.contactNumber) {
      //   body = {
      //     ...body,
      //     shipping_address: {
      //       ...body.shipping_address,
      //       mobile: data.mobile.contactNumber,
      //       country_calling_code: data.mobile.countryCode.calling_code,
      //       country_code: data.mobile.countryCode.country_code
      //     }
      //   };
      // }   
    }
    if (entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value) {
      editShippingAndCustomerAddress({orderId: order_id, body});
      // dispatch(editShipmentOrderDetails({shipment_order_id:order_id, body}));
    } else {
      editShippingAndCustomerAddress({orderId: order_id, body});
    }
  };
  // @ts-expect-error FIE ME
  const loadCitiesOptions = getOptionsFetcher(loadCitiesConfig);
  // @ts-expect-error FIE ME
  const loadStatesCitiesOptions = getOptionsFetcher(loadStatesCitiesConfig);
  /* @ts-expect-error fix */
  const loadStatesOptions = getOptionsFetcher(loadStatesConfig);
  return (
    /* @ts-expect-error fix */
    <SideSheet
      isOpen={isModalOpen.open}
      toggle={() => { setIsModalOpen((prev: any) => ({ ...prev, open: false })); }}
      modalClassName='modal-slide-in edit-customer-and-shipping-details-side-sheet'
      size='sm'
      onOpened={handleOnOpened}
      onClosed={handleOnClosed}
      /* @ts-expect-error fix */
      title={EDIT_SHIPPING_DETAILS_MODAL_TYPE[isModalOpen.key].title as string}
    >
      <ModalBody className='mt-32px p-0'>
        <Form onSubmit={handleSubmit(handleEditSubmit)}>
          <div className="customer-and-shipping-details"> 
            <div className='px-24px d-flex flex-column gap-24px'>
              <InputField
                name='first_name'
                errors={errors}
                {...register('first_name', { required: 'This field is required' })}
                isRequired
                isClearable
                label='First Name'
              />
              <InputField
                name='last_name'
                {...register('last_name')}
                errors={errors}
                isClearable
                label='Last Name'
              />
              {/* @ts-expect-error fix */}
              {(EDIT_SHIPPING_DETAILS_MODAL_TYPE.CUSTOMER_DETAILS.key === isModalOpen.key) && <PhoneNumberDropdown
                name='mobile'
                control={control}
                errors={errors}
                register={register}
                isClearable
                isRequired
                label='Contact Number'
                contactNumber={watch('mobile.contactNumber')}
                countryCode={watch('mobile.countryCode')}
                setError={setError}
                clearErrors={clearErrors}
              />}
              <InputField
                errors={errors}
                {...register('email', {
                  required: 'This field is required',
                  pattern:{
                    value: REGEX_PATTERN.EMAIL_VALIDATION,
                    message: t('Enter a valid email address')
                  }
                })}
                isRequired
                isClearable
                label='Email ID'
              />
            </div>
            {(EDIT_SHIPPING_DETAILS_MODAL_TYPE.SHIPPING_ADDRESS.key === isModalOpen.key) && <div className='px-24px d-flex flex-column gap-24px mt-24px'>
              <InputField
                errors={errors}
                {...register('address1', {required: 'This field is required'})}
                isClearable
                isRequired
                label='Address line 1'
              />
              <InputField
                {...register('address2')}
                isClearable
                label='Address line 2'
              />
              <div className='row'>
                <div className='col-6'>
                  <Controller
                    name='country'
                    control={control}
                    rules={{required: 'This field is required'}}
                    render={({field}) => {
                      return <Select
                        label='Country'
                        isAsync
                        isRequired
                        /* @ts-expect-error fix */
                        loadOptions={loadCountryOptions}
                        {...field}
                        errors={errors}
                        isClearable
                        onChange={(e) => {
                          field.onChange(e);
                          if (watch('state') || watch('city')) {
                            setValue('state', null);
                            setValue('city', null);
                          }
                        }}
                      />;
                    }}
                  />
                </div>
                <div className='col-6'>
                  <Controller
                    name='state'
                    control={control}
                    render={({field}) => {
                      return <Select
                        label='State/Region'
                        isAsync
                        /* @ts-expect-error fix */
                        loadOptions={loadStatesOptions}
                        {...field}
                        errors={errors}
                        isClearable
                        isDisabled={!watch('country')}
                        onChange={(e) => {
                          field.onChange(e);
                          if (watch('city')) {
                            setValue('city', null);
                          }
                        }}
                        additional={{
                          country: watch('country')
                        }}
                        cacheUniqs={[watch('country')]}
                      />;
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <Controller
                    name='city'
                    control={control}
                    rules={{required: 'This field is required'}}
                    render={({ field }) => {
                      return <Select
                        label='City'
                        isAsync
                        /* @ts-expect-error fix */
                        loadOptions={watch('state') ?loadStatesCitiesOptions:loadCitiesOptions}
                        {...field}
                        errors={errors}
                        isClearable
                        isDisabled={!watch('country')}
                        isRequired
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        additional={{
                          state: watch('state'),
                          params: {
                            country_id: watch('state') ? null : watch('country')?.value,
                          }
                        }}
                        cacheUniqs={watch('state') ? [watch('state')] : [watch('country')]}
                      />;
                    }}
                  />
                </div>
                <div className='col-6'>
                  <InputField
                    name='zip'
                    errors={errors}
                    {...register('zip', {
                      pattern: {
                        value: /^[a-zA-Z0-9]+$/,
                        message: 'only alphanumeric characters allowed'
                      }
                    })}
                    isClearable
                    label='Zip Code'
                  />
                </div>
              </div>
              {/* @ts-expect-error fix */}
              <PhoneNumberDropdown
                name='mobile'
                control={control}
                errors={errors}
                register={register}
                isRequired
                isClearable
                label='Contact Number'
                contactNumber={watch('mobile.contactNumber')}
                countryCode={watch('mobile.countryCode')}
                setError={setError}
                clearErrors={clearErrors}
              />
            </div>}
          </div>  
          <SidesheetFooter>
            <Button className='save-details-button' type='submit' disabled={isEditShippingAndCustomerAddress || !isDirty}>{(isEditShippingAndCustomerAddress) && <Spinner size="sm" className="me-25" />}{t('Save Details')}</Button>
          </SidesheetFooter>
        </Form>
      </ModalBody>
    </SideSheet>
  );
};

export default EditCustomerAndShippingDetails;