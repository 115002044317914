import InputField from '@src/@core/components/ui/input-field'
import Select from '@src/@core/components/ui/select'
import {
  BUNDLE_SKU_FIELDS_NAME,
  BUNDLE_SKU_FORM_VALIDATION_SCHEMA
} from '@src/views/catalog/catalog.constants'
import { getSellersAsync } from '@src/views/catalog/store'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

export default function SkuBulkUploadDetail({
  control, errors, isSellerMatched, setIsSellerMatched, watch, isBundleEdit, singleSkuLoading, selectedSeller
}) {
  const [confirmSellerInpValue, setConfirmSellerInpValue] = useState('')

  const SellerValue = watch(BUNDLE_SKU_FIELDS_NAME.SELLER)

  const confirmHandler = (e) => {
    const { value } = e.target
    if (value.toLowerCase() === SellerValue.label.toLowerCase()) {
      setIsSellerMatched(true)
    } else {
      setIsSellerMatched(false)
    }
    setConfirmSellerInpValue(value)
  }

  useEffect(() => {
    if (confirmSellerInpValue?.toLowerCase() === SellerValue?.label?.toLowerCase()) {
      setIsSellerMatched(true)
    } else {
      setIsSellerMatched(false)
    }
  }, [SellerValue?.value])
  return (
    <div className='d-flex gap-1 w-100'>
      <div className='w-100'>
        <Controller
          name={BUNDLE_SKU_FIELDS_NAME.SELLER}
          control={control}
          rules={BUNDLE_SKU_FORM_VALIDATION_SCHEMA[BUNDLE_SKU_FIELDS_NAME.SELLER]}
          render={({ field }) => (
            <Select
              isAsync
              isRequired
              label='Select Seller'
              value={selectedSeller}
              loadOptions={getSellersAsync}
              errors={errors}
              isDisabled={isBundleEdit}
              onChange={(selectedSeller) => {
                field.onChange(selectedSeller)
                setConfirmSellerInpValue('')
              }}
            />
          )}
        />
      </div>
      <div className='w-100'>
        <InputField
          width='100%'
          name='confirm_seller'
          label='Confirm Seller'
          isRequired
          isClearable
          id='confirm-seller-name'
          value={confirmSellerInpValue}
          disabled={isBundleEdit && singleSkuLoading}
          errors={confirmSellerInpValue && !isSellerMatched ? { confirm_seller: {message: 'Seller name does not match!'} } : null}
          onChange={(e) => confirmHandler(e)}
        />
      </div>
      <div className='w-100'></div>
    </div>
  )
}

