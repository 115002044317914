const MultiTripPlanned = ({color, packagesCount = 2, tripStopNumber = 0}: {color: string, tripStopNumber?: number, packagesCount?: number}) => {

  return <svg width="72" height="83" viewBox="0 0 72 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_3736_18057)">
      <ellipse cx="21" cy="66.5" rx="8" ry="3.5" fill={color}/>
    </g>
    <g filter="url(#filter1_f_3736_18057)">
      <ellipse cx="20.841" cy="66.8987" rx="5.84289" ry="2.07101" transform="rotate(0.0533949 20.841 66.8987)" fill={color}/>
    </g>
    <rect x="19.2344" y="50.4316" width="4" height="16" rx="2" fill="#222222"/>
    <ellipse cx="21" cy="33.9994" rx="18" ry="17.9994" fill={color}/>
    <ellipse cx="5.17271" cy="3.68804" rx="5.17271" ry="3.68804" transform="matrix(0.923141 -0.384461 0.384483 0.923132 9.0022 22.9766)" fill="white" fillOpacity="0.3"/>
    <g id="trip-stop-number">
      <rect width="560" height="140"/>
      <text x={`${tripStopNumber}`.length > 1 ? '14' : '16'} y="40" stroke="none" fill='white'>{tripStopNumber ? tripStopNumber : ''}</text>
    </g>
    {packagesCount > 0 && <>
      <rect x="26.5" y="0.5" width="45" height="25.0004" rx="8.5" fill="white"/>
      <rect x="26.5" y="0.5" width="45" height="25.0004" rx="8.5" stroke={color}/>
      <g id="packages-number">
        <rect width="560" height="140"/>
        <text x="30" y="18" stroke="none" fill={color}>{packagesCount}</text>
      </g>
      <path d="M54.5971 21.0385L48.1406 17.1498V8.85156L54.5971 12.7622V21.0385Z" fill="url(#paint0_linear_3736_18057)"/>
      <path d="M54.5957 21.0309L61.8592 17.161V8.88477L54.5957 12.7661V21.0309Z" fill="url(#paint1_linear_3736_18057)"/>
      <path d="M50.0971 17.6743L48.7031 16.7718V15.1797L50.0971 16.1555V17.6743Z" fill="#CCCCCC"/>
      <path d="M48.1445 8.85889L54.9479 4.95898L61.8593 8.88352L54.5995 12.7681L48.1445 8.85889Z" fill="url(#paint2_linear_3736_18057)"/>
      <path d="M60.0039 16.6268L61.3979 15.8711V16.1793L60.0039 16.9496V16.6268Z" fill="#CCCCCC"/>
      <path d="M60.0039 17.1854L61.3979 16.4297V16.7378L60.0039 17.5082V17.1854Z" fill="#CCCCCC"/>
      <path d="M52.182 11.3114L50.5176 10.2949V14.3624L51.3649 14.0674L52.182 15.1794V11.3114Z" fill="url(#paint3_linear_3736_18057)"/>
      <path d="M54.8452 8.03414L50.5121 10.2865L52.1632 11.3009L56.7242 8.85785L54.8452 8.03414Z" fill="#DEDFE8"/>
      <defs>
        <filter id="filter0_f_3736_18057" x="0.573921" y="50.5739" width="40.8522" height="31.8522" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="6.21304" result="effect1_foregroundBlur_3736_18057"/>
        </filter>
        <filter id="filter1_f_3736_18057" x="13.9625" y="63.7926" width="13.7568" height="6.21359" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.517753" result="effect1_foregroundBlur_3736_18057"/>
        </filter>
        <linearGradient id="paint0_linear_3736_18057" x1="54.8551" y1="20.9956" x2="47.9185" y2="8.98146" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C3787"/>
          <stop offset="1" stopColor={color}/>
        </linearGradient>
        <linearGradient id="paint1_linear_3736_18057" x1="54.6547" y1="21.1534" x2="61.7694" y2="8.83072" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C3787"/>
          <stop offset="1" stopColor={color}/>
        </linearGradient>
        <linearGradient id="paint2_linear_3736_18057" x1="57.4164" y1="13.0638" x2="55.8313" y2="1.13079" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C3787"/>
          <stop offset="1" stopColor={color}/>
        </linearGradient>
        <linearGradient id="paint3_linear_3736_18057" x1="50.1257" y1="10.6828" x2="52.5867" y2="14.9458" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7686FB"/>
          <stop offset="1" stopColor="#E6E6E6"/>
        </linearGradient>
      </defs>
    </> 
    }
  </svg>;
  
};

const MultiPackagesMarker = () => {
  return MultiTripPlanned;
};

export default MultiPackagesMarker;