import CollapsibleKeyValueList from '@src/components/collapsible-key-value-list/collapsible-key-value-list'
import { EDITABLE_RETURN_FEES } from '@src/views/returns/returns.constants'
import { useTranslation } from 'react-i18next'

const NA = 'NA'

const AdditionalCharges = ({ additionalCharges, currency }) => {
  const { t } = useTranslation()

  const returnFee = additionalCharges?.find((el) => el.type === EDITABLE_RETURN_FEES.RETURN_FEE.KEY) || {}
  const returnShipmentFee = additionalCharges?.find((el) => el.type === EDITABLE_RETURN_FEES.RETURN_SHIPMENT_FEE.KEY) || {}
  const additionalChargesSum = +(returnFee.cost?.toFixed(2) || 0) + +(returnShipmentFee.cost?.toFixed(2) || 0)

  const totalAdditionalFee =
  returnFee.is_applied || returnShipmentFee.is_applied
    ? // At least one Fee is applied
    additionalChargesSum > 0
      ? `-${(additionalChargesSum || 0).toFixed(2)} ${currency}`
      : `${(additionalChargesSum || 0).toFixed(2)} ${currency}`
    : // No Fee is applied
    NA;

  const isAdditionalFeeNotApplied = totalAdditionalFee === NA

  const additionalChargesFields = [
    {
      label: 'Return Fee',
      value: `${returnFee.cost_without_tax?.toFixed(2) || 0} ${currency}`,
      isApplied: returnFee.is_applied,
    },
    {
      label: 'Return Shipment Fee',
      value: `${returnShipmentFee.cost_without_tax?.toFixed(2) || 0} ${currency}`,
      isApplied: returnShipmentFee.is_applied,
    },
    {
      label: 'Tax',
      value: `${additionalCharges.reduce((acc, el) => +(acc + +el.tax_amount), 0).toFixed(2)} ${currency}`,
      isApplied: isAdditionalFeeNotApplied ? false : true,
    },
  ].filter((field) => field.isApplied)
  
  return (
    <CollapsibleKeyValueList
      key='additional_charges'
      label={`${t('Charges Applied')}${isAdditionalFeeNotApplied ? ':' : ''}`}
      value={totalAdditionalFee}
      collapsibleFields={additionalChargesFields}
    />
  )
}

export default AdditionalCharges
