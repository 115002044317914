/* eslint-disable @typescript-eslint/no-explicit-any */
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable';
import InputField from '@src/@core/components/ui/input-field';
import InputWithAdornment from '@src/@core/components/ui/input-with-adornment/input-adornment';
import Select from '@src/@core/components/ui/select';
import DropdownOptionsWithCTA from '@src/@core/components/ui/select/sub-components/DropdownOptionsWithCTA';
import { getApiUrl } from '@src/api.urls';
import { TENANT_TYPE } from '@src/App.constants';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import Edit from '@src/assets/images/icons/Edit';
import { GET_CURRENCIES } from '@src/redux/authentication';
import { getSellersAsyncData } from '@src/views/settings/store';
import AddCustomerSidesheet from '@src/views/shipping-aggregator/components/add-customer-sidesheet';
import AddPickupLocationSidesheet from '@src/views/shipping-aggregator/components/add-pickup-location-sidesheet';
import { iOrderCreationProps } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { CREATE_CUSTOMER_FIELDS_NAME, CREATE_ORDER_FIELDS_NAME, ORDER_CREATION_TYPE, ORDER_WEIGHT_UNIT, PAYMENT_METHOD, PICKUP_LOCATION_FIELDS_NAME } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { allowOnlyPositiveInteger, convertSKUMapToArray, distributeOrderAmountToPackages, distributeOrderWeightToPackages, GENERATE_REF_ID, groupSKUsByPackages, handleFormReset } from '@src/views/shipping-aggregator/shipping-aggregator.utility';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddShipmentPackageDetails from './add-package-details/add-shipment-package-details';
import OrderSummary from './order-summary/order-summary';
//@ts-expect-error fix
import noSkuImage from '@src/assets/images/omniful/no_sku_image.svg';
import ability from '@src/configs/acl/ability';
import { axiosInstance } from '@src/network/AxiosInstance';
import { CHANGE_ADDRESS, CUSTOMER_ADDRESS, CUSTOMER_MODAL_TYPE } from '@src/views/sales/constant/orders.constants';
import CreateAddress from '@src/views/sales/pending-actions/pages/createOrder/CreateAddress';
import CreateCustomer from '@src/views/sales/pending-actions/pages/createOrder/CreateCustomer';
import ChangeDeliveryAddress from '@src/views/sales/shipmentOrders/createShipmentOrder/components/ChangeDeliveryAddress';
import { getCustomerAddresses, resetSuccess } from '@src/views/sales/store/store';
import { addPickupLocation, fetchCustomers, fetchPickupAddress } from '@src/views/shipping-aggregator/shipping-aggregator.apis';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { MenuProps } from 'react-select';

const SingleOrderCreation: React.FC<iOrderCreationProps> = (props) => {
  const { formState, defaultCurrency } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLMDSellerEnabled = useSelector((store: any) => store.auth.userData?.restricted_third_party_logistics);
  
  const [addPickupLocationSidesheet, setAddPickupLocationSidesheet] = useState(false);
  const [editPickupLocationSidesheet, setEditPickupLocationSidesheet] = useState<{ isEdit: boolean, pickupLocationId: string | number | null }>({ isEdit: false, pickupLocationId: null });
  const [addCustomerSidesheet, setAddCustomerSidesheet] = useState(false);
  const [editCustomerSidesheet, setEditCustomerSidesheet] = useState<{ isEdit: boolean, customerId: string | number | null }>({ isEdit: false, customerId: null });
  const [openCreateCustomerSidebar, setOpenCreateCustomerSidebar] = useState({
    open: false,
    type: CUSTOMER_MODAL_TYPE.CREATE_CUSTOMER.key
  });
  const [openChangeAddressSidebar, setOpenChangeAddressSidebar] = useState({
    open: false,
    type: CHANGE_ADDRESS.DELIVERY_ADDRESS.key
  });
  const [openCreateAddressSidebar, setOpenCreateAddressSidebar] = useState({
    open: false,
    type: CUSTOMER_ADDRESS.CREATE_ADDRESS.key
  });
  const customerAddresses = useSelector((store: any) => store.sales.customerAddresses || [{}]);
  const customerData = useSelector((store: any) => store.sales.customerData);
  const success = useSelector((store: any) => store.sales.success);
  const tenantType = useSelector((state: any) => state.auth.userData.tenant.type);
  const DEFAULT_SELLER = formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value);

  const { mutate: addNewPickupLocation, isPending: addPickupLocationPending, isSuccess: addPickupLocationSuccess } = useMutation({
    mutationKey: [addPickupLocation.apiKey],
    mutationFn: (body: any) => {
      return axiosInstance.post(getApiUrl(addPickupLocation.url, {sellerId: DEFAULT_SELLER?.value}), body);
    },
    onSuccess: ({ data }) => {
      const locationData = data.data;
      CustomToast('Pickup location added successfully', { my_type: 'success' });
      const pickupLocationPreSelected = formState.watch(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value)?.pickup_location_pre_selected;
      handlePickupAddressChanges({
        ...locationData,
        label: locationData.name,
        value: locationData.id,
        [PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_PRE_SELECTED]: pickupLocationPreSelected,
      });
      if(!pickupLocationPreSelected) {
        formState.setValue(CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value, null);
      }
      toggleAddPickupLocationSidesheet();
    },
    onError: () => {
      formState.setValue(CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value, null);
      formState.setValue(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value, {});
    }
  });

  const { mutate: addNewCustomer, isPending: addCustomerPending, isSuccess: addCustomerSuccess } = useMutation({
    mutationKey: [addPickupLocation.apiKey],
    mutationFn: ({body, sellerId}: any) => {
      return axiosInstance.post(`/api/v1/cs/sellers/${sellerId}/customers`, body);
    },
    onSuccess: ({ data }) => {
      const customerData = data.data;
      CustomToast('Customer added successfully', { my_type: 'success' });
      const customerPreSelected = formState.watch(CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value)?.customer_pre_selected;
      handleShippingAddressChanges({...customerData, label: customerData.first_name + ' ' + customerData.last_name, value: customerData.id});
      if(!customerPreSelected) {
        formState.setValue(CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value, null);
      }
      toggleCreateCustomerSidesheet();
    },
    onError: () => {
      formState.setValue(CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value, null);
      formState.setValue(CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value, {});
    }
  });

  const toggleAddPickupLocationSidesheet = () => {
    setAddPickupLocationSidesheet(p => !p);
    // formState.reset({
    // ...DEFAULT_VALUE_FOR_PICKUP_LOCATION_FIELDS,
    //   [CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE]: ORDER_CREATION_TYPE.SINGLE
    // });
    if (addPickupLocationSidesheet && editPickupLocationSidesheet.isEdit) {
      setEditPickupLocationSidesheet({
        isEdit: false,
        pickupLocationId: null
      });
    }
  };

  const toggleCreateCustomerSidesheet = () => {
    setAddCustomerSidesheet(p => !p);
    // formState.reset({
    // ...DEFAULT_VALUE_FOR_CREATE_CUSTOMER_FIELDS,
    //   [CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE]: ORDER_CREATION_TYPE.SINGLE,
    // });
    if (addCustomerSidesheet && editCustomerSidesheet.isEdit) {
      setEditCustomerSidesheet({
        isEdit: false,
        customerId: null
      });
    }
  };

  // const toggleEditCustomerSidesheet = (id: string | number | null) => {
  //   setEditCustomerSidesheet((prev) => ({
  //     ...prev,
  //     isEdit: true,
  //     customerId: id
  //   }));
  //   toggleCreateCustomerSidesheet();
  // };

  const handleChangeAddress = () => {
    setOpenChangeAddressSidebar((prev) => ({ ...prev, open: true }));
  };

  const handleEditCustomerDetails = () => {
    setOpenCreateCustomerSidebar({
      open: true,
      type: CUSTOMER_MODAL_TYPE.EDIT_CUSTOMER.key
    });
  };

  const packageTableColumn = [
    {
      id: 1,
      name: <div>{t('SKU')}</div>,
      Key:'sku',
      minWidth:'300px',
      cell: (row: any) => {
        return (
          <div className='d-flex w-100 gap-10px align-items-center p-10px'>
            <div className="border border-dark-2 rounded-2 flex-center-center max-height-40px max-width-40px overflow-hidden bg-white">
              <img
                onError={(currentTarget: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src = noSkuImage;
                }}
                src={row.sku_details.sku?.images?.[0]?.default || noSkuImage}
                alt={`${row.sku_details.sku?.code}-image`}
                className="h-full w-full object-fit-fill"
                width="40"
                height="40"
                loading="lazy"
              />
            </div>
            <div className='w-100 text-truncate'>
              <div className='txt-sub-md text-dark text-truncate' title={row.sku_details.sku?.name}>{row.sku_details.sku?.name}</div>
              <div className='txt-asst-rg text-dark-6 text-truncate' title={row.sku_details.sku?.code}>{row.sku_details.sku?.code}</div>
            </div>
          </div>
        );
      }
    },
    {
      id: 2,
      name: <div className='flex-center-end w-100'>{t('Total Quantity')}</div>,
      Key:'total_quantity',
      minWidth:'200px',
      cell: (row: any) => {
        return (
          <div className='flex-center-end w-100 p-10px'>
            {row.packages.reduce((total: number, pkg: { quantity: number }) => total + pkg.quantity, 0)}
          </div>
        );
      }
    },
    {
      id: 3,
      name: <div>{t('Package ID')}</div>,
      Key:'package_id',
      minWidth:'200px',
      cell: (row: any) => {
        return (
          <div className="d-flex flex-column w-100 py-10px">
            {row.packages.map((pkg: { name: string }, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div className='px-10px'>{pkg.name || '-'}</div>
                  {index < row.packages.length - 1 && (
                    <hr className="mx-0 my-6px w-100 border-dark-2" />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      }
    },
    {
      id: 4,
      name: <div className='flex-center-end w-100'>{t('Qty in Package')}</div>,
      Key:'quantity_in _package',
      minWidth:'200px',
      cell: (row: any) => {
        return (
          <div className="d-flex flex-column w-100 py-10px">
            {row.packages.map((pkg: { quantity: number }, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div className='w-100 flex-center-end px-10px'>{pkg.quantity || '-'}</div>
                  {index < row.packages.length - 1 && (
                    <hr className="mx-0 my-6px w-100 border-dark-2" />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      }
    },
    {
      id: 5,
      name: <div className='flex-center-end w-100'>{t('Total Weight')}</div>,
      Key:'total_weight',
      minWidth:'200px',
      cell: (row: any) => {
        return (
          <div className="d-flex flex-column w-100 py-10px">
            {row.packages.map((pkg: { ordered_weight: {value: number, unit: string} }, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div className='w-100 flex-center-end px-10px'>
                    {pkg.ordered_weight !== null ? <div className='d-flex gap-4px'>
                      <span>{pkg.ordered_weight.value}</span>
                      <span>{pkg.ordered_weight.unit}</span>
                    </div> : '-'}
                  </div>
                  {index < row.packages.length - 1 && (
                    <hr className="mx-0 my-6px w-100 border-dark-2" />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      }
    },
    {
      id: 6,
      name: <div className='flex-center-end w-100'>{t('Unit Price (Tax Excl.)')}</div>,
      Key:'unit_price',
      minWidth:'200px',
      cell: (row: any) => {
        return (
          <div className="d-flex flex-column w-100 py-10px">
            {row.packages.map((pkg: { unit_price: number }, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div className='w-100 flex-center-end px-10px'>{pkg.unit_price || '-'}</div>
                  {index < row.packages.length - 1 && (
                    <hr className="mx-0 my-6px w-100 border-dark-2" />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      }
    },
    {
      id: 7,
      name: <div className='flex-center-end w-100'>{t('Tax % (Per Unit)')}</div>,
      Key:'tax_percentage',
      minWidth:'200px',
      cell: (row: any) => {
        return (
          <div className="d-flex flex-column w-100 py-10px">
            {row.packages.map((pkg: { tax_percent: number }, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div className='w-100 flex-center-end px-10px'>{pkg.tax_percent ||'-'}</div>
                  {index < row.packages.length - 1 && (
                    <hr className="mx-0 my-6px w-100 border-dark-2" />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      }
    },
    {
      id: 8,
      name: <div className='flex-center-end w-100'>{t('Tax Amount')}</div>,
      Key:'tax_amount',
      minWidth:'200px',
      cell: (row: any) => {
        return (
          <div className="d-flex flex-column w-100 py-10px">
            {row.packages.map((pkg: { tax: number }, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div className='w-100 flex-center-end px-10px'>{pkg.tax || '-'}</div>
                  {index < row.packages.length - 1 && (
                    <hr className="mx-0 my-6px w-100 border-dark-2" />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      }
    },
    {
      id: 9,
      name: <div className='flex-center-end w-100'>{t('Item Total')}</div>,
      Key:'item_total',
      minWidth:'200px',
      cell: (row: any) => {
        return (
          <div className="d-flex flex-column w-100">
            {row.packages.map((pkg: { total: number }, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div className='w-100 flex-center-end px-10px'>{pkg.total ||'-'}</div>
                  {index < row.packages.length - 1 && (
                    <hr className="mx-0 my-6px w-100 border-dark-2" />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      }
    },
  ];

  const packageData = formState.watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value) || [];
  const skuPackageMap = groupSKUsByPackages(packageData);
  const formattedData = convertSKUMapToArray(skuPackageMap);

  const handlePickupAddressChanges = (value: any) => {
    formState.setValue(CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value, value);
    formState.setValue(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value, value);
    formState.clearErrors(CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value);
  };

  const handleShippingAddressChanges = (value: any) => {
    formState.setValue(CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value, value);
    formState.clearErrors(CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value);
    formState.setValue(CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value, null);
    formState.setValue(CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value, {
      ...formState.watch(CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value),
      ...value,
    });
    const params = { entity_id: value.id, entity_name: 'customer' };
    if (ability.can( abilityMap.customer.view_address.action, abilityMap.customer.view_address.resource )) {
      //@ts-expect-error fix
      dispatch(getCustomerAddresses({ params }));
    }
  };

  const handleCustomerAddressChanges = (value: any) => {
    formState.setValue('customer_address.first_name', value.first_name);
    formState.setValue('customer_address.last_name', value.last_name);
    formState.setValue('customer_address.email', value.email);
    formState.setValue('customer_address.phone_number', value.phone_number);
    formState.setValue('customer_address.country_calling_code', value.country_calling_code);
    formState.setValue('customer_address.country_code', value.country_code);
    formState.setValue('customer_address.address_line_1', value.address1);
    formState.setValue('customer_address.address_line_2', value.address2);
    formState.setValue('customer_address.city.label', value.city?.name);
    formState.setValue('customer_address.state.label', value.state?.name);
    formState.setValue('customer_address.country.label', value.country?.name);
    formState.setValue('customer_address.zip_code', value.zip);
    formState.setValue('customer_address.id', value.id);
  };

  const handlePickupLocationForm = (data: any) => {
    formState.setValue(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value, data);
    const body = {
      name: data[PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_NAME],
      phone_number: data.contact_details.contactNumber,
      country_code: data.contact_details.countryCode?.country_code,
      country_calling_code: data.contact_details.countryCode?.calling_code,
      address: {
        address_line1: data[PICKUP_LOCATION_FIELDS_NAME.ADDRESS_LINE_1],
        address_line2: data[PICKUP_LOCATION_FIELDS_NAME.ADDRESS_LINE_2],
        postal_code: data[PICKUP_LOCATION_FIELDS_NAME.ZIP_CODE],
        country: {
          name: data[PICKUP_LOCATION_FIELDS_NAME.COUNTRY].label,
          id: data[PICKUP_LOCATION_FIELDS_NAME.COUNTRY].value.toString(),
          code: data[PICKUP_LOCATION_FIELDS_NAME.COUNTRY].code,
          currency: data[PICKUP_LOCATION_FIELDS_NAME.COUNTRY].currency
        },
        state: {
          name: data[PICKUP_LOCATION_FIELDS_NAME.STATE]?.label,
          id: data[PICKUP_LOCATION_FIELDS_NAME.STATE]?.value?.toString(),
          code: data[PICKUP_LOCATION_FIELDS_NAME.STATE]?.code
        },
        city: {
          name: data[PICKUP_LOCATION_FIELDS_NAME.CITY].label,
          id: data[PICKUP_LOCATION_FIELDS_NAME.CITY].value.toString(),
          code: data[PICKUP_LOCATION_FIELDS_NAME.CITY].code
        }
      },
      type: 'virtual'
    };
    addNewPickupLocation(body);
  };

  const handleCreateCustomerForm = (data: any) => {
    const phone = data.contact_details.countryCode.calling_code + '-' + data.contact_details.contactNumber;
    formState.setValue(CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value, {
      ...data,
      phone,
      phone_number: data.contact_details.contactNumber,
      country_calling_code: data.contact_details.countryCode.calling_code
    });

    const contactDetails = {
      mobile: data.contact_details.contactNumber,
      country_calling_code: data.contact_details.countryCode.calling_code,
      country_code: data.contact_details.countryCode.country_code,
    };

    const body = {
      first_name: data[CREATE_CUSTOMER_FIELDS_NAME.FIRST_NAME],
      last_name: data[CREATE_CUSTOMER_FIELDS_NAME.LAST_NAME],
      email: data[CREATE_CUSTOMER_FIELDS_NAME.EMAIL],
      seller_code: formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)?.code,
      ...(data.contact_details.contactNumber && contactDetails),
      address: {
        address1: data[CREATE_CUSTOMER_FIELDS_NAME.ADDRESS_LINE_1],
        address2: data[CREATE_CUSTOMER_FIELDS_NAME.ADDRESS_LINE_2],
        city_id: data[CREATE_CUSTOMER_FIELDS_NAME.CITY].value,
        city: data[CREATE_CUSTOMER_FIELDS_NAME.CITY].label,
        country_id: data[CREATE_CUSTOMER_FIELDS_NAME.COUNTRY].value,
        country: data[CREATE_CUSTOMER_FIELDS_NAME.COUNTRY].label,
        state_id: data[CREATE_CUSTOMER_FIELDS_NAME.STATE]?.value,
        state: data[CREATE_CUSTOMER_FIELDS_NAME.STATE]?.label,
        zip: data[CREATE_CUSTOMER_FIELDS_NAME.ZIP_CODE],
        first_name: data[CREATE_CUSTOMER_FIELDS_NAME.FIRST_NAME],
        last_name: data[CREATE_CUSTOMER_FIELDS_NAME.LAST_NAME],
        email: data[CREATE_CUSTOMER_FIELDS_NAME.EMAIL],
        ...(data.contact_details.contactNumber && contactDetails),
      },
    };

    addNewCustomer({body, sellerId: formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)?.value});
  };

  useEffect(() => {
    if (success.getCustomerAddresses) {
      if (formState.watch(CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value)) return;
      formState.setValue(CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value, customerAddresses[0]);
      handleCustomerAddressChanges(customerAddresses[0]);
      dispatch(resetSuccess());
    }

    if (success.createAddress || success.editAddress) {
      setOpenCreateAddressSidebar((prev) => ({ ...prev, open: false }));
    }

    if (success.createCustomer || success.editCustomer) {
      handleShippingAddressChanges({...customerData, label: customerData.first_name + ' ' + customerData.last_name, value: customerData.id});
      setOpenCreateCustomerSidebar({
        open: false,
        type: CUSTOMER_MODAL_TYPE.CREATE_CUSTOMER.key
      });
      dispatch(resetSuccess());
    }

  }, [success.getCustomerAddresses, success.createAddress, success.editAddress, success.createCustomer, success.editCustomer]);

  const ctaConfig = (props: MenuProps<unknown, true>) => ({
    append: () => {
      toggleAddPickupLocationSidesheet();
      props.selectProps?.onMenuClose();
    },
    disabled: false,
  });
  const customCtaConfig = (props: MenuProps<unknown, true>) => ({
    append: () => {
      toggleCreateCustomerSidesheet();
      props.selectProps?.onMenuClose();
    },
    disabled: false,
  });

  return (
    <div className='single-order-creation'>
      <div className='basic-details-container'>
        <Controller
          name={CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value}
          control={formState.control}
          render={({ field }) => {
            return (
              /* @ts-expect-error fix*/
              <Select
                {...field}
                label={CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.label}
                variant='floatingDropdown'
                isRequired
                options={ Object.values(ORDER_CREATION_TYPE)}
                onChange={(e: any) => {
                  field.onChange(e);
                  const resetValues = handleFormReset({ key: CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value, watch: formState.watch });
                  formState.reset(resetValues);
                }}
              />
            );
          }}
        />
        {tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && !isLMDSellerEnabled &&
          <Controller
            name={CREATE_ORDER_FIELDS_NAME.SELLER.value}
            control={formState.control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  label={CREATE_ORDER_FIELDS_NAME.SELLER.label}
                  isRequired
                  isAsync
                  /* @ts-expect-error fix*/
                  loadOptions={getSellersAsyncData}
                  onChange={(e: any) => {
                    field.onChange(e);
                    const resetValues = handleFormReset({ key: CREATE_ORDER_FIELDS_NAME.SELLER.value, watch: formState.watch });
                    formState.reset(resetValues);
                    formState.setValue(CREATE_ORDER_FIELDS_NAME.CURRENCY.value, defaultCurrency);
                  }}
                  errors={formState.formState.errors}
                />
              );
            }}
          />
        }
        <Controller
          name={CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.value}
          control={formState.control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => {
            return (
              /* @ts-expect-error fix*/
              <Select
                {...field}
                label={CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.label}
                variant='floatingDropdown'
                isRequired
                isClearable
                options={Object.values(PAYMENT_METHOD)}
                onChange={(e: any) => {
                  field.onChange(e);
                  const resetValues = handleFormReset({ key: CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.value, watch: formState.watch });
                  formState.reset(resetValues);
                }}
                errors={formState.formState.errors}
              />
            );
          }}
        />
        {/* <Controller
          name={CREATE_ORDER_FIELDS_NAME.PRODUCT_TYPE.value}
          control={formState.control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => {
            return (
              <Select
                {...field}
                label={CREATE_ORDER_FIELDS_NAME.PRODUCT_TYPE.label}
                variant='floatingDropdown'
                isRequired
                isClearable
                options={Object.values(PRODUCT_TYPE)}
                errors={formState.formState.errors}
              />
            );
          }}
        /> */}
        {/* <Controller
          name={CREATE_ORDER_FIELDS_NAME.ORDER_TYPE.value}
          control={formState.control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => {
            return (
              <Select
                {...field}
                label={CREATE_ORDER_FIELDS_NAME.ORDER_TYPE.label}
                variant='floatingDropdown'
                isRequired
                isClearable
                options={Object.values(ORDER_TYPE)}
                errors={formState.formState.errors}
              />
            );
          }}
        /> */}
        {/* <Controller
          name={CREATE_ORDER_FIELDS_NAME.SERVICE_TYPE.value}
          control={formState.control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => {
            return (
              <Select
                {...field}
                label={CREATE_ORDER_FIELDS_NAME.SERVICE_TYPE.label}
                variant='floatingDropdown'
                isRequired
                isClearable
                options={Object.values(SERVICE_TYPE)}
                errors={formState.formState.errors}
              />
            );
          }}
        /> */}
        <div className='d-flex gap-12px w-100'>
          <div className='w-100'>
            <InputField
              name={CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value}
              {...formState.register(CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value, {
                required: 'This field is required',
                onChange: () => distributeOrderAmountToPackages({ formState })
              })}
              isRequired
              errors={formState.formState.errors}
              label={CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.label}
              width='100%'
              onInput={(e: any) => {
                e.target.value = allowOnlyPositiveInteger(e.target.value);
              }}
            />
          </div>
          <Controller
            name={CREATE_ORDER_FIELDS_NAME.CURRENCY.value}
            control={formState.control}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  label={CREATE_ORDER_FIELDS_NAME.CURRENCY.label}
                  isAsync
                  /* @ts-expect-error fix */
                  loadOptions={GET_CURRENCIES}
                  width='110px'
                  customComponents={{
                    LoadingIndicator: () => null
                  }}
                />
              );
            }}
          />
        </div>
        <div className='d-flex gap-12px w-100'>
          <div className="w-100">
            <InputField
              label={CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT.label}
              isRequired
              {...formState.register(CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT.value, {
                required: 'This field is required',
                validate: (value: number) => value > 0 ? undefined : 'Order weight must be greater than 0',
                onChange: () => distributeOrderWeightToPackages({formState})
              })}
              errors={formState.formState.errors}
              onInput={(e: any) => {
                e.target.value = allowOnlyPositiveInteger(e.target.value);
              }}
            />
          </div>
          <Controller
            name={CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT_UNIT.value}
            control={formState.control}
            render={({ field }) => {
              return (
              /* @ts-expect-error fix */
                <Select
                  {...field}
                  label={CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT_UNIT.label}
                  options={Object.values(ORDER_WEIGHT_UNIT)}
                  width='110px'
                />
              );
            }}
          />
        </div>
        <div>
          <InputWithAdornment
            {...formState.register(CREATE_ORDER_FIELDS_NAME.REFERENCE_ORDER_ID.value)}
            errors={formState.formState.errors}
            label={CREATE_ORDER_FIELDS_NAME.REFERENCE_ORDER_ID.label}
            adornment='Auto Fill'
            handleAdornment={async () => {
              const REFERENCE_ID = GENERATE_REF_ID();
              await formState.setValue(CREATE_ORDER_FIELDS_NAME.REFERENCE_ORDER_ID.value, REFERENCE_ID);
              formState.clearErrors(CREATE_ORDER_FIELDS_NAME.REFERENCE_ORDER_ID.value);
            }}
          />
        </div>
      </div>

      <div className='pickup-drop-location-container'>

        <div className='pickup-location'>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <div className='text-dark txt-h3-sb'>{t('Pickup Address')}</div>
            {/* <Button ofStyle='noBackground' ofType='compressed' type='button' onClick={toggleAddPickupLocationSidesheet} className='d-flex gap-8px txt-sub-md'>
              <Plus size={16} /> {t('Add New Address')}
            </Button> */}
          </div>
          <Controller
            name={CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value}
            control={formState.control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => { 
              return (
                <Select

                  {...field}
                  label={CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.label}
                  isAsync
                  isRequired
                  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                  /* @ts-expect-error */
                  loadOptions={fetchPickupAddress}
                  cacheUniqs={[addPickupLocationSuccess, formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)]}
                  onChange={(e) => {
                    field.onChange(e);
                    handlePickupAddressChanges(e);
                    formState.setValue(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value, {
                      ...formState.getValues(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value),
                      [PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_PRE_SELECTED]: true
                    });
                  }}
                  customComponents={{
                    Menu: (props: MenuProps<any, true>) => <DropdownOptionsWithCTA
                      isHeaderCTA
                      ctaConfig={ctaConfig(props)}
                      {...props}
                    />
                  }}
                  errors={formState.formState.errors}
                  additional={{
                    seller_id: formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)?.value
                  }}
                />
              );
            }}
          />

          {formState.watch('pickup_location.name') && formState.watch('pickup_location.pickup_location_pre_selected') &&
            <div className='bg-primary-lighter p-16px rounded-8px d-flex flex-column gap-12px h-100'>
              <div className='text-dark txt-body-sb'>{formState.watch('pickup_location.name')}</div>
              <div className='d-flex flex-column gap-6px'>
                <div className='d-flex gap-8px txt-sub-rg text-dark-6'>
                  <span>{formState.watch('pickup_location.contact_details.country_calling_code')}{'-'}{formState.watch('pickup_location.contact_details.phone_number')}</span>
                  {formState.watch('pickup_location.email') &&  <>
                    {formState.watch('pickup_location.contact_details.phone_number') && <span>•</span>}
                    <span>{formState.watch('pickup_location.email')}</span>
                  </>}
                </div>
                <span className='txt-asst-rg text-dark-6'>
                  {formState.watch('pickup_location.address.address_line1') && <> {formState.watch('pickup_location.address.address_line1')}</>}
                  {formState.watch('pickup_location.address.address_line2') && <>, {formState.watch('pickup_location.address.address_line2')}</>}
                  {formState.watch('pickup_location.address.city.name') && <>, {formState.watch('pickup_location.address.city.name')}</>}
                  {formState.watch('pickup_location.address.state.name') && <>, {formState.watch('pickup_location.address.state.name')}</>}
                  {formState.watch('pickup_location.address.country.name') && <>, {formState.watch('pickup_location.address.country.name')}</>}
                  {formState.watch('pickup_location.address.zip_code') && <>, {formState.watch('pickup_location.address.zip_code')}</>}
                </span>
              </div>
            </div>
          }
        </div>

        <div className='drop-location'>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <div className='text-dark txt-h3-sb'>{t('Delivery Address')}</div>
            {/* <Button ofStyle='noBackground' type='button' ofType='compressed' onClick={toggleCreateCustomerSidesheet} className='d-flex gap-8px txt-sub-md'>
              <Plus size={16} /> {t('Add New Customer')}
            </Button> */}
          </div>
          <Controller
            name={CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value}
            control={formState.control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  label={CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.label}
                  isAsync
                  isRequired
                  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                  /* @ts-expect-error */
                  loadOptions={fetchCustomers}
                  cacheUniqs={[formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value), success.createCustomer, success.editCustomer, addCustomerSuccess]}
                  additional={{
                    sellerId: formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)?.value
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                    handleShippingAddressChanges(e);
                    formState.setValue(CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value, {
                      ...e,
                      [CREATE_CUSTOMER_FIELDS_NAME.CUSTOMER_PRE_SELECTED]: true
                    });
                  }}
                  customComponents={{
                    Menu: (props: MenuProps<any, true>) => <DropdownOptionsWithCTA
                      isHeaderCTA
                      ctaConfig={customCtaConfig(props)}
                      {...props}
                    />
                  }}
                  errors={formState.formState.errors}
                />
              );
            }}
          />

          {formState.watch('customer_details.first_name') && formState.watch('customer_details.customer_pre_selected') &&
            <div className='bg-primary-lighter p-16px rounded-8px d-flex flex-column gap-12px h-100'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <span className='text-dark txt-body-sb'>{formState.watch('customer_details.first_name')} {formState.watch('customer_details.last_name')}</span>
                <div className='height-16px width-16px cursor-pointer flex-center-center' onClick={handleEditCustomerDetails}>
                  <Edit width={18} height={18}/>
                </div>
              </div>
              <div className='d-flex flex-column gap-6px'>
                <div className='d-flex gap-8px txt-sub-rg text-dark-6'>
                  {formState.watch('customer_details.phone') && <span>{formState.watch('customer_details.phone')}</span>}
                  {formState.watch('customer_details.email') && <>
                    {formState.watch('customer_details.phone') && <span>•</span>}
                    <span>{formState.watch('customer_details.email')}</span>
                  </>}
                </div>
                {formState.watch('customer_address.address_line_1') ? <>
                  <div className='d-flex justify-content-between align-items-start w-100'>
                    <span className='txt-asst-rg text-dark-6'>
                      {formState.watch('customer_address.address_line_1') && <> {formState.watch('customer_address.address_line_1')}</>}
                      {formState.watch('customer_address.address_line_2') && <>, {formState.watch('customer_address.address_line_2')}</>}
                      {formState.watch('customer_address.city.label') && <>, {formState.watch('customer_address.city.label')}</>}
                      {formState.watch('customer_address.state.label') && <>, {formState.watch('customer_address.state.label')}</>}
                      {formState.watch('customer_address.country.label') && <>, {formState.watch('customer_address.country.label')}</>}
                      {formState.watch('customer_address.zip_code') && <>, {formState.watch('customer_address.zip_code')}</>}
                    </span>
                    <div className='height-16px width-16px cursor-pointer flex-center-center' onClick={handleChangeAddress}>
                      <Edit width={18} height={18}/>
                    </div>
                  </div>
                </> : null}
              </div>
            </div>
          }
        </div>
      </div>

      <div className='p-24px rounded-8px bg-white'>
        <AddShipmentPackageDetails formState={formState}/>
      </div>

      {formattedData?.length > 0 &&
        <div className='p-24px rounded-8px bg-white sku-details-container'>
          <div className='w-100 d-flex flex-column gap-24px'>
            <span className='text-dark txt-h3-sb'>{t('SKU Details')}</span>
            <ExpandableCustomTable
              isActionColumnSticky={false}
              columns={packageTableColumn || []}
              //@ts-expect-error fix
              data={formattedData || []}
              showColumnsDropdown={false}
              hideTableSearch
              showPagination={false}
              showColumnsTableHeader={false}
            />

            <div className='w-100 d-flex justify-content-end'>
              <div style={{ width: '360px' }}>
                <OrderSummary
                  isCreateShipmentView
                  subTotal={formState.watch(CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value) - formState.watch(CREATE_ORDER_FIELDS_NAME.TOTAL_TAXES.value) || 0}
                  totalTaxes={formState.watch(CREATE_ORDER_FIELDS_NAME.TOTAL_TAXES.value) || 0}
                  totalAmount={formState.watch(CREATE_ORDER_FIELDS_NAME.TOTAL_AMOUNT.value) || 0}
                  currency={formState.watch(CREATE_ORDER_FIELDS_NAME.CURRENCY.value)?.label}
                />
              </div>
            </div>
          </div>
        </div>
      }

      <AddPickupLocationSidesheet
        isOpen={addPickupLocationSidesheet}
        toggleSideSheet={toggleAddPickupLocationSidesheet}
        editPickupLocationSidesheet={editPickupLocationSidesheet}
        handleFormSubmit={handlePickupLocationForm}
        loading={addPickupLocationPending}
      />

      <AddCustomerSidesheet
        isOpen={addCustomerSidesheet}
        toggleSideSheet={toggleCreateCustomerSidesheet}
        editCustomerSidesheet={editCustomerSidesheet}
        handleFormSubmit={handleCreateCustomerForm}
        loading={addCustomerPending}
      />

      {openCreateCustomerSidebar.open && (
        <CreateCustomer
          openCreateCustomerSidebar={openCreateCustomerSidebar}
          setOpenCreateCustomerSidebar={setOpenCreateCustomerSidebar}
          selectedSeller={formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)}
          customerDetails={formState.watch(CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value)}
        />
      )}

      <CreateAddress
        openCreateAddressSidebar={openCreateAddressSidebar}
        setOpenCreateAddressSidebar={setOpenCreateAddressSidebar}
        customerDetails={formState.watch(CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value)}
      />

      <ChangeDeliveryAddress
        openChangeAddressSidebar={openChangeAddressSidebar}
        setOpenChangeAddressSidebar={setOpenChangeAddressSidebar}
        setOpenCreateAddressSidebar={setOpenCreateAddressSidebar}
        selectedDeliveryAddress={formState.watch(CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value)}
        setSelectedDeliveryAddress={(address: any) => handleCustomerAddressChanges(address)}
        selectedCustomer={formState.watch(CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value)}
      />
    </div>
  );
};

export default SingleOrderCreation;
