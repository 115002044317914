import { API_ENDPOINTS } from '@src/api.urls';
import { getApiUrl, paramsSerializer } from '@src/utility/Utils';
import { INVENTORY_SEARCH_COLUMN_KEYS } from './inventory.constants';

export const onHandBreakdownApi = {
  apiKey: ['get-on-hand-inventory-breakdown'],
  url: API_ENDPOINTS.INVENTORY.ON_HAND_INVENTORY_BREAKDOWN
};

export const loadQcFailReasons = {
  apiUrl: API_ENDPOINTS.INVENTORY.GET_QC_FAIL_REASONS,
  select: (data) => data?.data?.map(ele => ({
    value: ele.id,
    label: ele.title
  })) || [],
  hasMore: () => false
};

export const loadStockTransferOrdersConfig = {
  apiUrl: (additional) => getApiUrl(API_ENDPOINTS.INVENTORY.GET_STO_LIST, {
    hubId: additional.hubId
  }),
  additionalHandler: (search, prevAdditional) => {
    return {
      ...prevAdditional,
      params: {
        search_query: search || undefined,
        search_column: search ? INVENTORY_SEARCH_COLUMN_KEYS.STOCK_TRANSFER_ORDER_ID : undefined,
        page: (prevAdditional.params?.page || 0) + 1,
        status_not_in: prevAdditional.statusNotIn || undefined
      }
    }
  },
  select: (data) => data?.data?.map(ele => ({
    value: ele.id,
    label: ele.sto_number,
    type: ele.type,
    seller: ele.seller
  })) || []
};
export const loadBatchesConfig = {
  apiUrl: () => API_ENDPOINTS.INVENTORY.GET_BATCHES,
  additionalHandler: (search, prevAdditional) => ({
    ...prevAdditional,
    params: {
      search_query: search || undefined,
      search_column: search ? INVENTORY_SEARCH_COLUMN_KEYS.EXTERNAL_BATCH_ID : undefined,
      page: (prevAdditional.params?.page || 0) + 1,
      seller_sku_id: prevAdditional.sellerSkuId || undefined
    }
  }),
  select: (data) => data?.data?.map(ele => ({
    value: {
      batch_num: ele.external_batch_id,
      expiry_date: ele.expiry_date
    },
    label: ele.external_batch_id,
    id: ele.id,
    external_batch_id: ele.external_batch_id,
    expiry_date: ele.expiry_date
  })) || []
}

export const loadPurchaseOrders = {
  apiUrl: (additional) => {
    const url = getApiUrl(API_ENDPOINTS.PURCHASE_ORDER.GET_PURCHASE_ORDERS, { hubId: additional.hubId });
    return `${url}?${paramsSerializer({order_status: additional.orderStatus})}`;
  },
  additionalHandler: (search, prevAdditional) => ({
    ...prevAdditional,
    params: {
      search_query: search ? search : undefined,
      search_column: search ? INVENTORY_SEARCH_COLUMN_KEYS.PURCHASE_ORDER_ID : undefined,
      page: (prevAdditional.params?.page || 0) + 1,
    }
  }),
  select: (data) => data?.data?.map(ele => ({
    value: ele.id,
    label: ele.purchase_order_id,
    type: ele.type,
    status: ele.status,
    seller: ele.seller,
    supplier: ele.supplier
  })) || []
};

export const loadGateEntries = {
  apiUrl: (additional) => getApiUrl(API_ENDPOINTS.INVENTORY.GET_OR_CREATE_GATE_ENTRY, { hubId: additional.hubId }),
  additionalHandler: (search, prevAdditional) => ({
    ...prevAdditional,
    params: {
      search_query: search ? search : undefined,
      search_column: search ? INVENTORY_SEARCH_COLUMN_KEYS.GATE_ENTRY_ID : undefined,
      page: (prevAdditional.params?.page || 0) + 1
    }
  }),
  select: (data) => data?.data?.map(ele => ({
    value: ele.id,
    label: ele.gate_entry_id,
    seller: ele.seller,
    supplier: ele.supplier,
    entity_id: ele.entity_id,
    entity_type: ele.entity_type
  })) || []
}


export const loadReturnGateEntriesConfig = {
  apiUrl: (additional) => getApiUrl(API_ENDPOINTS.RETURNS.RETURN_GATE_ENTRIES, { hubId: additional.hubId }),
  select: (data) => data?.data?.map(ele => ({
    value: ele.id,
    label: ele.return_gate_entry_id,
  })) || [],
  hasMore: () => false
}

export const loadSupplierListConfig = {
  apiUrl: (additional) => getApiUrl(API_ENDPOINTS.COMMON.GET_ALL_SUPPLIERS_OF_SELLER, { sellerId: additional.sellerId }),
  select: (data) => data.data?.map((ele) => ({
    id:ele.id,
    value: ele.id,
    label: ele.name,
  })) || [],
}
