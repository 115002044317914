import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import Checkbox from '@src/@core/components/ui/new-checkbox'
import { API_ENDPOINTS } from '@src/api.urls'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import ErrorFilled from '@src/assets/images/icons/errorFilled'
import RefreshTableIcon from '@src/assets/images/svg/table/refreshTableIcon'
import ability from '@src/configs/acl/ability'
import { bindToChannelEvent, initRealTime } from '@src/network/RealTime'
import { handlePusherResponse } from '@src/redux/authentication'
import { getApiUrl, printMultipleAwb } from '@src/utility/Utils'
import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import { ORDER_STATUS } from '@src/views/Purchases/purchase.constants'
import { resetSuccess } from '@src/views/Purchases/store'
import Invoice from '@src/views/sales/components/invoice/invoice'
import CancelShipmentPopUp from '@src/views/sales/create/CancelShipmentPopUp'
import OrderTagsMapper from '@src/views/sales/live-orders/pages/packingOrderWise/StationPages/packingOrderTabsPages/OrderTagsMapper'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Check, Info, MoreVertical } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, Spinner, Tooltip, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap'
import CopyOnClick from '../../../@core/components/ui/click-and-copy'
import FloatingDropDown from '../../../@core/components/ui/floating-dropdown'
import ToggleTabs from '../../../@core/components/ui/toggleTabs'
import { useApi } from '../../../configs/react-query/useApi'
import { axiosInstance } from '../../../network/AxiosInstance'
import { useFilters, usePagination, useSearch } from '../../../utility/hooks/useFilters'
import OrderDetailsSideSlider from '../../sales/OrderDetailsSideSlider'
import { getAWBDetail, getAsyncShippingEligibleAccountsData } from '../../sales/store/store'
import { API_KEYS, BULK_SHIPMENT_STATUSES, SHIPMENT_TYPE } from '../bulk-ship.constants'
import { setChangedOrdersForShipments, setCreationStatuses, setSelectedOrdersForShipments } from '../store'
import RetryShipmentModal from './retry-shipments-modal'

const ShipmentProcessing = ({ pickingWave, setIsAtleastOneShipmentCreated }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const printInvoiceRef = useRef(null)

  const tableSearchColList = [
    { id: 'query', name: 'Order' },
    { id: 'seller_sales_channel_order_id', name: 'Sales Channel Order ID' },
    { id: 'seller_sku_code', name: 'SKU Code' },
    { id: 'barcode', name: 'SKU Barcode' }
  ]

  // states
  const selectedOrders = useSelector((state) => state.bulkShip.selectedOrdersForShipments)
  const changedShipmentOrders = useSelector((state) => state.bulkShip.changedOrdersForShipments)
  const pusherResponse = useSelector((store) => store.auth.pusherResponse)
  const pusherLoading = useSelector(store => store.auth.pusherLoading)
  const success = useSelector((store) => store.sales.success)
  
  const [sellerId, setSellerId] = useState(null)
  const [orderForPrintInvoice, setOrderForPrintInvoicer] = useState({}) // state exclusive for print invoice
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedOrder, setSelectedOrder] = useState({})
  const [cancelShipmentModal, setCancelShipmentModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const shippingPartnerId = searchParams.get('shipment_type') || SHIPMENT_TYPE.AUTOMATION_RULE
  const [isChooseShippingPartnerError, setIsChooseShippingPartnerError] = useState(false)
  const [selectedShippingAccount, setSelectedShippingAccount] = useState(null)
  const hasCreateShipmentPermission = ability.can(abilityMap.order.create_shipment.action, abilityMap.order.create_shipment.resource)


  const { filter, setFilter } = useFilters({})
  const { searchQuery, setSearchQuery } = useSearch({ column: tableSearchColList[0] })
  const { pagination, setPagination } = usePagination({page: 1, per_page: 20 })
  const selectedGlobalHubId = useSelector((store) => store.auth.selectedGlobalHubId)
  const [tableData, setTableData] = useState([])
  const {
    data: fetchOrdersResponse,
    refetch: handleGetOrdersData,
    isFetching: isGetOrdersFetching,
    isError: isGetOrdersError,
    isSuccess: isGetOrdersSuccess
  } = useApi({
    apiKey: [API_KEYS.FETCH_WAVE_SHIPMENT_DATA, { pickingWave, pagination }],
    apiFn: () => {
      const params = {
        picking_wave_id: pickingWave?.wave?.id,
        hub_id: selectedGlobalHubId,
        creation_status: filter.creation_status?.value,
        [searchQuery.column.id]:
          searchQuery.query,
        shipping_partner_tag: filter.shipping_partner_tag?.value,
        ...pagination
      }
      return axiosInstance.get(getApiUrl(API_ENDPOINTS.BULK_SHIP.FETCH_WAVE_SHIPMENT_DATA), { params })
    },
    onSuccess: (props) => {
      const { data, meta } = props || {}
      const newData = data?.orders?.map((order, idx) => {
        const orderTags = [...order.tags]
        orderTags.sort((a, b) => a.name.length - b.name.length)
        return {
          ...order,
          serialNumber: ((meta?.current_page - 1) * meta?.per_page) + idx + 1 || idx + 1,
          orderID: order.seller_sales_channel_order_id,
          createdAt: order.order_created_at,
          orderTags,
          referenceOrderID: order.order_alias,
          orderSource: order.display_source,
          shippingPartner: { label: order.shipment?.shipping_account?.name, value: order.shipment?.shipping_account?.id, ...order.shipment?.shipping_account },
          id: order.id,
          displaySource: order.display_source,
          awbNumber: order.shipment.awb_number
        }
      })
      setTableData(newData)
      if (setIsAtleastOneShipmentCreated) setIsAtleastOneShipmentCreated(() => newData.some(el => el.shipment.creation_status === BULK_SHIPMENT_STATUSES.CREATED.value || el.shipment.creation_status === BULK_SHIPMENT_STATUSES.AWB_PRINTED.value))
      if (data) {
        dispatch(setCreationStatuses(data.creation_status_counts))
      }
    }
  })

  const [isSelectAllOrderForShipment, setIsSelectAllOrderForShipment] = useState(false)
  const [createShipmentConfirmation, setCreateShipmentConfirmation] = useState(false)
  const shippingAccountRef = useRef(null)
 
  const cancelledCount =
    fetchOrdersResponse?.data?.creation_status_counts?.find(
      statuses => statuses.status === BULK_SHIPMENT_STATUSES.CANCELLED.value
    )?.count || 0;

  const notApplicableCount =
    fetchOrdersResponse?.data?.creation_status_counts?.find(
      statuses => statuses.status === BULK_SHIPMENT_STATUSES.NOT_APPLICABLE.value
    )?.count || 0;

  const totalCancelledCount = cancelledCount + notApplicableCount;

  const notAllOrdersAreCancelled = fetchOrdersResponse?.data?.total !== totalCancelledCount;

  const handleOrderDetails = (row) => {
    setSellerId(row.id)
    setIsModalOpen(true)
  }

  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : { column: tableSearchColList[0] }
    setSearchQuery(searchQuery)
  }

  const { mutate: createBulkShipment, isPending: isCreateBulkShipmentPending } = useMutation({
    mutationKey: ['create-bulk-shipment'],
    mutationFn: (properties) => {
      const { singleRow, status } = properties || {}
      const body = {
        creation_status: status,
        wave_id: `${pickingWave?.wave?.id}`,
        hub_id: selectedGlobalHubId,
        shipment_type: shippingPartnerId.toLowerCase(),
        order_details: {
          number_of_boxes: +pickingWave.packing_details?.box_details?.length || 0,
          weight: +pickingWave.packing_details?.order_weight
        },
        shipping_account: {
          id: selectedShippingAccount?.id,
          name: selectedShippingAccount?.account_name
        },
        is_all_orders: singleRow ? false : isSelectAllOrderForShipment || !Object.keys(selectedOrders).length,
        shipping_partner_tag: selectedShippingAccount?.shipping_partner?.tag,
        order_shipments: singleRow
          ? [
            (() => {
              const isChanged = !!changedShipmentOrders[singleRow.id]
              const shippingAccId = (() => {
                if (shippingPartnerId === SHIPMENT_TYPE.AUTOMATION_RULE) {
                  if (isChanged) return changedShipmentOrders[singleRow.id]?.shippingPartner?.id
                  return singleRow.shippingPartner?.id
                } else return selectedShippingAccount?.id
              })()
              const shippingAccName = (() => {
                if (shippingPartnerId === SHIPMENT_TYPE.AUTOMATION_RULE) {
                  if (isChanged) return changedShipmentOrders[singleRow.id]?.shippingPartner?.account_name
                  return singleRow.shippingPartner?.name
                } else return selectedShippingAccount?.account_name
              })()
              return {
                oms_order_id: singleRow.id,
                is_specific_shipping_partner: isChanged,
                shipping_partner_tag: changedShipmentOrders[singleRow.id] && changedShipmentOrders[singleRow.id].shippingPartner.shipping_partner.tag,
                shipping_account: {
                  id: shippingAccId,
                  name: shippingAccName
                }
              }
            })()
          ]
          : Object.values(selectedOrders).map((order) => {
            return {
              oms_order_id: order.id,
              is_specific_shipping_partner: !!changedShipmentOrders[order.id],
              shipping_partner_tag: changedShipmentOrders[order.id] && changedShipmentOrders[order.id].shippingPartner.shipping_partner.tag,
              shipping_account: {
                id: shippingPartnerId === SHIPMENT_TYPE.AUTOMATION_RULE ? changedShipmentOrders[order.id] ? changedShipmentOrders[order.id].shippingPartner.id : order.shippingPartner?.id : selectedShippingAccount.id,
                name: shippingPartnerId === SHIPMENT_TYPE.AUTOMATION_RULE ? changedShipmentOrders[order.id] ? changedShipmentOrders[order.id].shippingPartner.account_name : order.shippingPartner?.name : selectedShippingAccount.account_name
              }
            }
          })
      }
      return axiosInstance.post('/api/v1/oms/orders/bulk/shipments', body)
    },
    onSuccess: ({ data }) => {
      CustomToast('Shipment creation started', {my_type: 'info'})
      bindToChannelEvent(data.data?.event)
      setCreateShipmentConfirmation(false)
    }
  })

  const handleCreateShipment = (properties) => {
    const { singleRow } = properties
    if (shippingPartnerId === SHIPMENT_TYPE.SHIPPING_PARTNER) {
      if (!selectedShippingAccount?.value) {
        setIsChooseShippingPartnerError(true)
        shippingAccountRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        return
      }
      if (singleRow) {
        initRealTime(() => {
          createBulkShipment({singleRow})
        })
      } else {
        setCreateShipmentConfirmation(true)
      }
    } else {
      if (singleRow) {
        initRealTime(() => {
          createBulkShipment({singleRow})
        })
      } else {
        setCreateShipmentConfirmation(true)
      }
    }
  }
  

  const { mutate: cancelShipment, isPending: isCancelShipmentPending } = useApi({
    isMutation: true,
    apiKey: ['cancel-shipments'],
    apiFn: () => {
      return axiosInstance.post('/api/v1/oms/orders/bulk/shipments/cancel', {
        wave_id: `${pickingWave.wave.id}`,
        hub_id: selectedGlobalHubId,
        order_ids: Object.values(selectedOrders).map((order) => order.id),
        is_all_orders: isSelectAllOrderForShipment || !Object.keys(selectedOrders).length
      })
    },
    onSuccess: ({ data }) => {
      if (data?.event) bindToChannelEvent(data?.event)
    }
  })

  const handlebulkCancelShipment = () => {
    initRealTime(() => {
      cancelShipment()
    })
  }

  const handleCancelShipment = ({singleRow}) => {
    setSelectedOrder(singleRow)
    setCancelShipmentModal(true)
  }

  const handlePrintAwb = async (row) => {
    if (row.shipment.awb_label) {
      printMultipleAwb(row.shipment.awb_label)
    } else {
      const res = await getAWBDetail(row.id)
      if (res?.is_success && res?.data?.awb_label) {
        printMultipleAwb(res.data.awb_label)
      }
    }
  }

  const handlePrintInvoice = (row) => {
    setOrderForPrintInvoicer(row)
  }

  const tableColumns = [
    {
      id: 1,
      name: (
        <>
          <Checkbox
            id={'check-all-order-id'}
            key={'check-all-order-id'}
            disabled={!tableData?.length || isSelectAllOrderForShipment || fetchOrdersResponse?.data?.total === totalCancelledCount}
            checked={tableData?.length ? isSelectAllOrderForShipment || tableData?.every((order) => selectedOrders[order.orderID]) : false}
            onChange={() => {
              const isAlreadyChecked = tableData?.every((order) => selectedOrders[order.orderID])
              const newSelectedOrders = { ...selectedOrders }
              if (isAlreadyChecked) {
                tableData.forEach((elem) => {
                  delete newSelectedOrders[elem.orderID]
                })
              } else {
                tableData.forEach((elem) => {
                  newSelectedOrders[elem.orderID] = elem
                })
              }
              dispatch(setSelectedOrdersForShipments(newSelectedOrders))
            }}
          />
        </>
      ),
      key: 'check',
      width: '48px',
      reorder: false,
      cell: (row) => (
        <Checkbox
          id={row.orderID}
          checked={isSelectAllOrderForShipment || !!selectedOrders[row.orderID] && selectedOrders[row.orderID].status !== ORDER_STATUS.CANCELLED}
          disabled={row.status === ORDER_STATUS.CANCELLED}
          onChange={(e) => {
            const p = { ...selectedOrders }
            const newSelectedOrders = ((p) => {
              const newSelectedOrders = { ...p }
              if (!e.target.checked) {
                setIsSelectAllOrderForShipment(false)
                delete newSelectedOrders[row.orderID]
                return newSelectedOrders
              } else {
                return { ...newSelectedOrders, [row.orderID]: row }
              }
            })(p)
            dispatch(setSelectedOrdersForShipments(newSelectedOrders))
          }}
        />
      )
    },
    {
      id: 2,
      name: t('S. No.'),
      key: 'serail_number',
      width: '64px',
      selector: (row) => row,
      reorder: false,
      cell: (row) => <div className='w-100 text-center'>{row.serialNumber}</div>
    },
    {
      id: 3,
      name: t('Order ID'),
      key: 'seller_sales_channel_order_id',
      minWidth: '340px',
      maxWidth: '400px',
      reorder: false,
      cell: (row) => (
        <div className='w-100 flex-center-between'>
          <CopyOnClick id={row.orderID} handleDetails={() => handleOrderDetails(row)} columnKey="seller_sales_channel">
            {row.orderID}
          </CopyOnClick>
          {row.status === ORDER_STATUS.CANCELLED && <CustomLabel  title={row.display_status} className="text-danger bg-danger-light text-nowrap" />}
        </div>
      )
    },
    {
      id: 4,
      name: t('Reference Order ID'),
      key: 'reference_order_id',
      width: '180px',
      reorder: false,
      cell: (row) => (
        row.referenceOrderID ? <CopyOnClick id={row.orderID}  columnKey={'dfa'}>
          {row.referenceOrderID}
        </CopyOnClick> : '-'
      )
    },
    {
      id: 5,
      name: t('Shipment Status'),
      key: 'shipment_status',
      minWidth: '170px',
      reorder: true,
      cell: (row) => (
        <div className='w-100 flex-center-between'>
          <span
            className={classNames({
              'text-danger': row.shipment?.creation_status === 'failed'
            })}
          >
            {row.shipment?.display_creation_status}
          </span>
          {row.shipment?.creation_status === 'failed' && <span>
            <Info size={16} strokeWidth={1.5} color="var(--bs-danger)" id={`tooltip-${row.id}`} />
            <UncontrolledTooltip target={`tooltip-${row.id}`} placement="top" offset={[0, 5]} >
              <span>{row.shipment?.creation_error}</span>
            </UncontrolledTooltip>
          </span>}
        </div>
      )
    },
    {
      id: 6,
      name: t('Order Tags'),
      key: 'tags',
      minWidth: '300px',
      maxWidth: '350px',
      reorder: true,
      cell: (row) => {
        const CustomIcon = () => {
          return <span className="text-primary">+{row.orderTags?.length - 3}</span>
        }
        return (
          <div className='d-flex flex-wrap gap-6px'>
            {row.orderTags?.length > 0 ? <OrderTagsMapper customIcon={CustomIcon} tagsArray={row.orderTags} containerBody/>
            // (
            //   row.orderTags.map((tag, index) => {
            //     return <CustomTag key={index} style={{ color: tag.color, backgroundColor: `${tag.color}1A` }} title={tag.name} />
            //   })
            // ) 
              : (
                <div className='w-100 text-center'>-</div>
              )}
          </div>
        )
      }
    },
    {
      id: 7,
      name: t('Shipping Account'),
      key: 'shipping_account',
      minWidth: '230px',
      reorder: true,
      cell: (row) => {
        return row.shipment?.creation_status === BULK_SHIPMENT_STATUSES.CREATED.value || row.shipment?.creation_status === BULK_SHIPMENT_STATUSES.AWB_PRINTED.value ? <div>{row.shipment?.shipping_account?.display_name || '-'}</div>  : shippingPartnerId === SHIPMENT_TYPE.SHIPPING_PARTNER ? (
          <div>{selectedShippingAccount?.label || '-'}</div>
        ) : (
          <div className='w-100 table-column-dropdown'>
            <FloatingDropDown
              key={row.id}
              isAsync
              classNames={{
                valueContainer: () => 'p-0'
              }}
              title={(changedShipmentOrders[row.id]?.shippingPartner || row.shipment?.shipping_account?.id) ? '' : 'Choose Shipping Account'}
              loadOptions={getAsyncShippingEligibleAccountsData}
              value={changedShipmentOrders[row.id]?.shippingPartner || {label: row.shipment?.shipping_account?.display_name, value: row.shipment?.shipping_account?.id}}
              onChangeFunc={(val) => {
                const newSelectedOrders = { ...changedShipmentOrders }
                newSelectedOrders[row.id] = { ...row, shippingPartner: val }
                dispatch(setChangedOrdersForShipments(newSelectedOrders))
              }}
              menuPosition="fixed"
              additional={{ 
                params:{seller_ids:[pickingWave.seller.id]}
              }}
            />
          </div>
        )
      }
    },
    {
      id: 8,
      name: t('Order Source'),
      key: 'order_source',
      minWidth: '170px',
      reorder: true,
      cell: (row) => row.source
    },
    {
      id: 9,
      name: t('Action'),
      key: 'action',
      width: '60px',
      reorder: false,
      omit: tableData?.every(data => data.status === ORDER_STATUS.CANCELLED),
      cell: (row) => {
        const creationStatus = row.shipment.creation_status
        const isShipmentNotCreated = (creationStatus !== BULK_SHIPMENT_STATUSES.CREATED.value &&
          creationStatus !== BULK_SHIPMENT_STATUSES.AWB_PRINTED.value)
        const isOptionDisabled = !hasCreateShipmentPermission && isShipmentNotCreated
        return (
          <div className='flex-center-center w-100'>
            {row.status !== ORDER_STATUS.CANCELLED && 
              <UncontrolledDropdown  id={`toolTip-createShipment-${row.serialNumber}`}>
                <DropdownToggle className={classNames('pe-1 more_vert rounded-4px', {
                  'cursor-pointer': !isOptionDisabled,
                  'cursor-not-allowed': isOptionDisabled
                })} tag='span'
                disabled={isOptionDisabled}
                >
                  <div className='border p-4px rounded-4px border-dark-2 overflow-hidden'>
                    <MoreVertical size={16} color={isOptionDisabled ? 'var(--bs-dark-5)' : 'var(--bs-dark)'} />
                  </div>
                  {isOptionDisabled && <UncontrolledTooltip placement="left" offset={[0, 5]} target={`toolTip-createShipment-${row.serialNumber}`} trigger="hover">
                    <div>{t('You do not have the necessary permissions to create the shipment.')}</div>
                  </UncontrolledTooltip>}
                </DropdownToggle>
                <DropdownMenu container='body' className='py-0'>
                  {(() => {
                    switch (creationStatus) {
                    case BULK_SHIPMENT_STATUSES.PENDING.value:
                      return (
                        <>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault()
                              handleCreateShipment({ singleRow: row })
                            }}
                            className='txt-sub-rg rounded-4px w-100 text-dark'
                          >
                            <span className='align-middle'>{t('Create Shipment')}</span>
                          </DropdownItem>
                        </>
                      )
                    case BULK_SHIPMENT_STATUSES.FAILED.value:
                      return (
                        <>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault()
                              handleCreateShipment({ singleRow: row })
                            }}
                            className='txt-sub-rg rounded-4px w-100 text-dark'
                          >
                            <span className='align-middle'>{t('Retry Shipment')}</span>
                          </DropdownItem>
                        </>
                      )
                    case BULK_SHIPMENT_STATUSES.CREATED.value:
                      return (
                        <>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault()
                              handlePrintAwb(row)
                            }}
                            className='txt-sub-rg rounded-4px w-100 text-dark'
                          >
                            <span className='align-middle'>{t('Print AWB')}</span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault()
                              handleCancelShipment({singleRow: row})
                            }}
                            className='txt-sub-rg rounded-4px w-100 text-dark'
                          >
                            <span className='align-middle'>{t('Cancel Shipment')}</span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault()
                              handlePrintInvoice(row)
                            }}
                            className='txt-sub-rg rounded-4px w-100 text-dark'
                          >
                            <span className='align-middle'>{t('Print Invoice')}</span>
                          </DropdownItem>
                        </>
                      )
                    case BULK_SHIPMENT_STATUSES.AWB_PRINTED.value:
                      return (
                        <>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault()
                              handlePrintAwb(row)
                            }}
                            className='txt-sub-rg rounded-4px w-100 text-dark'
                          >
                            <span className='align-middle'>{t('Print AWB')}</span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault()
                              handleCancelShipment({singleRow: row})
                            }}
                            className='txt-sub-rg rounded-4px w-100 text-dark'
                          >
                            <span className='align-middle'>{t('Cancel Shipment')}</span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault()
                              handlePrintInvoice(row)
                            }}
                            className='txt-sub-rg rounded-4px w-100 text-dark'
                          >
                            <span className='align-middle'>{t('Print Invoice')}</span>
                          </DropdownItem>
                        </>
                      )
                    default:
                      return (
                        <>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault()
                              handleCreateShipment({ singleRow: row })
                            }}
                            className='txt-sub-rg rounded-4px text-dark'
                          >
                            <span className='align-middle'>{t('Create Shipment')}</span>
                          </DropdownItem>
                        </>
                      )
                    }
                  })()}
                </DropdownMenu>
              </UncontrolledDropdown> 
            }
       
          </div>
        )
      }
    }
  ]

  const filterHandler = ({ filterkey, filterValue }) => {

    if (filterkey === 'seller_id') {
      delete filter.seller_sales_channel_id
    }
    if (!filterValue) {
      delete filter[filterkey]
      setFilter(filter)
      return
    }
    setFilter({ ...filter, [filterkey]: filterValue })
  }

  const handleShippingTypeChange = (tab) => {
    setSelectedShippingAccount(null)
    setIsChooseShippingPartnerError(false)
    dispatch(setSelectedOrdersForShipments({}))
    const prevSearchParams = Object.fromEntries([...searchParams])
    setSearchParams({
      ...prevSearchParams,
      shipment_type: tab.id
    })
  }

  const handlePagination = (page) => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 10 }
    setPagination(pagination)
  }

  const shipmentFailedCount = +fetchOrdersResponse?.data?.creation_status_counts?.find((el) => el.status === 'failed')?.count
  const shipmentSuccessCount =
    (+fetchOrdersResponse?.data?.creation_status_counts?.find((el) => el.status === 'created')?.count || 0) +
    (+fetchOrdersResponse?.data?.creation_status_counts?.find((el) => el.status === 'awb_printed')?.count || 0)

  const [isRetryShipmentModalOpen, setIsRetryShipmentModalOpen] = useState(false)

  useEffect(() => {
    if (pusherResponse['bulk-shipments']) {
      const cleanedPusherResp = { ...pusherResponse }
      cleanedPusherResp['bulk-shipments'] = undefined
      dispatch(handlePusherResponse(cleanedPusherResp))
      dispatch(setSelectedOrdersForShipments({}))
      setSelectedShippingAccount(null)
      handleGetOrdersData()
    }

    if (success.cancelShipment) {
      setCancelShipmentModal(false)
      dispatch(resetSuccess())
      handleGetOrdersData()
    }

    if (orderForPrintInvoice.id) {
      printInvoiceRef.current.click()
    }

    return () => {
      if (orderForPrintInvoice.id) {
        setOrderForPrintInvoicer({})
      }
    }
  }, [pusherResponse, orderForPrintInvoice, success])

  useEffect(() => {
    // cleanup
    return () => {
      dispatch(setSelectedOrdersForShipments({}))
    }
  }, [])

  useOnClickOutside(shippingAccountRef, () => setIsChooseShippingPartnerError(false))
  

  return (
    <>
      <div>
        <div className='d-flex flex-column gap-20px'>
          <div className='d-flex gap-20px'>
            <DropdownWithTitle
              title='Shipment Status'
              isClearable={!!filter.creation_status}
              value={filter.creation_status || { label: 'All', value: '' }}
              selectOptionHandler={(value) => filterHandler({ filterkey: 'creation_status', filterValue: value })}
              options={Object.values(BULK_SHIPMENT_STATUSES)}
            />
            <div className='h-full bg-dark-2' style={{ width: 1 }} />
            <div className='d-flex gap-20px'>
              <div className='customised-toggle-tabs txt-asst-rg'>
                <ToggleTabs
                  selectedTabId={shippingPartnerId}
                  onTabChange={handleShippingTypeChange}
                  tabDataList={[
                    { id: SHIPMENT_TYPE.AUTOMATION_RULE, name: 'Automation Rule' },
                    { id: SHIPMENT_TYPE.SHIPPING_PARTNER, name: 'Shipping Account' }
                  ]}
                />
              </div>
              {shippingPartnerId === SHIPMENT_TYPE.SHIPPING_PARTNER ? (
                <div className='txt-asst-rg dropdown-height-40px'>
                  <Tooltip target={'shipping-partner-dropdown'} isOpen={isChooseShippingPartnerError} offset={[0, 10]}>
                    {t('Please select shipping partner')}
                  </Tooltip>
                  <div ref={shippingAccountRef}>
                    <FloatingDropDown
                      isAsync
                      id='shipping-partner-dropdown'
                      name='shipping_account'
                      isTooltipError
                      hideErrorIcon
                      errors={isChooseShippingPartnerError ? { shipping_account: { message: ' ' } } : {}}
                      width={'200px'}
                      value={selectedShippingAccount}
                      onChangeFunc={(val) => {
                        setSelectedShippingAccount(val)
                        setIsChooseShippingPartnerError(false)
                      }}
                      title={selectedShippingAccount ? 'Shipping Account' : 'Select Shipping Account'}
                      loadOptions={getAsyncShippingEligibleAccountsData}
                      additional={{
                        params: { seller_ids: [pickingWave.seller.id] }
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {(!!shipmentFailedCount && notAllOrdersAreCancelled) ? (
            <div className='bg-danger-light text-dark p-12px rounded-8px d-flex align-items-start gap-12px'>
              <div>
                <ErrorFilled size={20} />
              </div>
              <div className='d-flex flex-column gap-8px'>
                <div className='txt-body-sb'>Some Error Occurred While Creating Shipments For {shipmentFailedCount} Orders</div>
                {hasCreateShipmentPermission && <div className='txt-sub-rg'>Try changing shipping partner and retry creating shipments</div>}
                {hasCreateShipmentPermission && <Button
                  ofStyle='noBackground'
                  onClick={() => setIsRetryShipmentModalOpen(true)}
                  className='bg-transparent shadow-none p-0 txt-body-md d-flex gap-10px'
                  icon={() => <RefreshTableIcon color={'var(--bs-primary)'} width="20" height="20" />}
                >
                  {t('Retry Shipment Creation')}
                </Button>}
              </div>
            </div>
          ) : null}
          {!!Object.keys(selectedOrders).length && fetchOrdersResponse?.meta?.per_page < fetchOrdersResponse?.data?.total ? (
            <div className='bg-primary-lighter px-16px py-14px rounded-12px d-flex gap-20px'>
              <div className='d-flex align-items-center gap-12px'>
                <Check color='var(--bs-dark)' size={18} />
                <span className='text-dark txt-body-md'>
                  {Object.keys(selectedOrders)?.length} {Object.keys(selectedOrders)?.length === 1 ? 'Order' : 'Orders'} selected
                </span>
              </div>
              <div className='h-full bg-primary-light' style={{ width: 1 }} />
              <div className='d-flex align-items-center gap-8px'>
                <Checkbox
                  size='sm'
                  checked={isSelectAllOrderForShipment}
                  onChange={(e) => {
                    // const isAlreadyChecked = tableData?.every((order) => selectedOrders[order.orderID])
                    const newSelectedOrders = { ...selectedOrders }
                    if (!e.target.checked) {
                      tableData.forEach((elem) => {
                        delete newSelectedOrders[elem.orderID]
                      })
                    } else {
                      tableData.forEach((elem) => {
                        newSelectedOrders[elem.orderID] = elem
                      })
                    }
                    setIsSelectAllOrderForShipment(e.target.checked)
                    dispatch(setSelectedOrdersForShipments(newSelectedOrders))
                  }} 
                  label={
                    <span className='text-primary txt-body-md'>
                      {t('Select All')} ({fetchOrdersResponse?.data?.total})
                    </span>
                  }
                />
              </div>
            </div>
          ) : null}
          <ExpandableCustomTable
            TableHeaderComponent={
              <div className='d-flex gap-16px'>
                {(+shipmentSuccessCount > 0 && notAllOrdersAreCancelled) && (
                  <div>
                    <Button onClick={handlebulkCancelShipment} ofStyle='outlined' disabled={pusherLoading || isCancelShipmentPending}>
                      {isCancelShipmentPending && <Spinner size='sm' />}
                      {t('Cancel Shipment')}
                    </Button>
                  </div>
                )}
                {hasCreateShipmentPermission && (+(shipmentSuccessCount || 0) < +pickingWave.number_of_orders && notAllOrdersAreCancelled) && (
                  <div className=''>
                    <Button onClick={handleCreateShipment}>
                      {t('Create Shipment')}
                    </Button>
                  </div>
                )}
              </div>
            }
            handleQueryParams={handleSearchItem}
            loading={isGetOrdersFetching}
            data={tableData}
            error={isGetOrdersError}
            columns={tableColumns}
            searchcolumnsList={tableSearchColList}
            showColumnsTableHeader={true}
            success={isGetOrdersSuccess}
            search_column_query={searchQuery}
            useReactPaginate={false}
            handlePagination={handlePagination}
            meta={fetchOrdersResponse?.meta || {}}
          />
        </div>
      </div>
      <OrderDetailsSideSlider sellerId={sellerId} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleGetParentData={handleGetOrdersData} />
      <RetryShipmentModal t={t} isOpen={isRetryShipmentModalOpen} toggle={() => setIsRetryShipmentModalOpen((p) => !p)} pickingWave={pickingWave} setCreateShipmentConfirmation={setCreateShipmentConfirmation} handleOrderDetails={handleOrderDetails} />

      <CancelShipmentPopUp deleteModalOpen={cancelShipmentModal} setDeleteModalOpen={setCancelShipmentModal} shipping_partner_tag={selectedOrder.shipment?.shipping_partner_tag} awb_number={selectedOrder.shipment?.awb_number} shipmentOrderID={selectedOrder.shipment?.shipment_order_id}/>

      <div className='d-none'>
        <Invoice printInvoiceRef={printInvoiceRef} order_id={orderForPrintInvoice.id} cleanupFunction={() => setOrderForPrintInvoicer({})} />
      </div>

      <Modal
        container='__bulk-ship-module'
        isOpen={createShipmentConfirmation}
        toggle={() => setCreateShipmentConfirmation((p) => !p)}
        centered
        contentClassName='rounded-24px bg-white'
        modalClassName='modal-max-w-600'
      >
        <ModalBody className='overflow-auto p-24px d-flex flex-column gap-28px'>
          <p className='flex-center-center'>
            <Info size={150} color='var(--bs-primary)' strokeWidth={'1.5px'} />
          </p>
          <div className='d-flex flex-column gap-12px'>
            <h2 className='m-0 p-0 txt-h1-sb'>
              {t(
                shippingPartnerId === SHIPMENT_TYPE.SHIPPING_PARTNER
                  ? `${selectedShippingAccount?.label} will be assigned as shipping partner for the selected orders`
                  : t('Shipping partners will be assigned to the orders based on automation rules')
              )}
            </h2>
            <p className='p-0 m-0 txt-body-rg text-dark'>
              {t(
                shippingPartnerId === SHIPMENT_TYPE.SHIPPING_PARTNER
                  ? 'You can cancel the shipment or edit the shipment partner later.'
                  : t('You can edit the shipping partners now, cancel the shipment or edit the shipment partner later.')
              )}
            </p>
          </div>
        </ModalBody>
        <ModalFooter className='height-80px d-flex gap-16px'>
          <Button onClick={() => setCreateShipmentConfirmation(false)} type='button' ofStyle='noBackground'>
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              initRealTime(() => {
                createBulkShipment()
              })
            }}
            type='button'
            loading={isCreateBulkShipmentPending || pusherLoading}
          >
            {t('Confirm & Continue')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ShipmentProcessing
