import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import PageHeader from '@src/@core/components/ui/page-header'

import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import TableActionButton from '@src/@core/components/ui/tableActionButton'
import { loadAllSellersConfig } from '@src/app.apis'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE, TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import Omniship from '@src/assets/images/icons/primary-navbar/Omniship'
import Orders from '@src/assets/images/icons/primary-navbar/Sales'
import { AbilityContext } from '@src/utility/context/Can'
import { useFilters, usePagination, useSearch } from '@src/utility/hooks/useFilters'
import { getOptionsFetcher } from '@src/utility/Utils'
import { useContext, useEffect, useState } from 'react'
import { Edit2, Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AddNewVirtualHubFormModal from '../StockTransferOrder/components/StoPoCommonCreateModal/components/addNewVirtualHubFormModal/AddNewVirtualHubFormModal'
import { addNewPickupLocation, editPickupLocation, getPickupLocation, resetSuccess } from '../store/store'

const PickupLocation = ({isPoweredFromShippingAggregator = false}) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)


  const pageHeaderProps = {
    breadcrumbIcon: isPoweredFromShippingAggregator ? Omniship : Orders,
    breadcrumbsData: isPoweredFromShippingAggregator ? [{ title: 'OmniShip' }] : [{ title: 'Orders' }, {title: 'Shipment Orders'}],
    title: isPoweredFromShippingAggregator ? 'Pickup Address' : 'Locations'
  }

  const tenantSellers = useSelector(state => state.auth.userSellers)

  //state
  const [isVirtualHubFormModalOpen, setIsVirtualHubFormModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const { pagination, setPagination } = usePagination()
  const [selectedPickUpLocation, setSelectedPickupLocation] = useState()
  const [selectedSeller, setSelectedSeller] = useState(() => {
    if (
      tenantSellers?.length
    ) {
      const firstSeller = tenantSellers[0]
      const sellerObj = {
        value: firstSeller.seller_id,
        label: firstSeller.name,
        code: firstSeller.code
      }
      return sellerObj
    }
    return null
  })
  const { filter, setFilter } = useFilters({})
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const pickupLocationData = useSelector((store) => store.sales.pickupLocationData)
  const pickupLocationMeta = useSelector((store) => store.sales.pickupLocationMeta)
  const pickupLocationLoading = useSelector((store) => store.sales.loading.getPickupLocation)
  const pickupLocationSuccess = useSelector((store) => store.sales.success.getPickupLocation)
  const pickupLocationError = useSelector((store) => store.sales.error.getPickupLocation)
  const loading = useSelector((store) => store.sales.loading.editPickupLocation)
  const success = useSelector((store) => store.sales.success.editPickupLocation)
  const successAddNewHub = useSelector((store) => store.sales.success.addNewPickupLocation)
  const loadAllSellersOptions = getOptionsFetcher(loadAllSellersConfig())

  // handle search
  const searchColumnList = [{ id: 'name', name: t(isPoweredFromShippingAggregator ? 'Address' : 'Locations') }]
  const { searchQuery, setSearchQuery } = useSearch({ column: searchColumnList[0] })
  
  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : null
    setSearchQuery(searchQuery)
  }
  
  //submit
  const handleVirtualHubFormSubmit = (virtualFormData) => {
    const body = {
      name: virtualFormData.virtual_hub_name,
      phone_number: virtualFormData.phone.contactNumber,
      country_code: virtualFormData.phone.countryCode.country_code,
      country_calling_code: virtualFormData.phone.countryCode.calling_code,
      address: {
        address_line1: virtualFormData.address_line1,
        address_line2: virtualFormData.address_line2,
        postal_code: virtualFormData.zip,
        country: {
          name: virtualFormData.country?.label,
          id: virtualFormData.country?.value.toString(),
          code: virtualFormData.country?.code,
          currency: {
            name: virtualFormData.country?.currency?.name,
            code: virtualFormData.country?.currency?.code
          }
        },
        state: {
          name: virtualFormData.state?.label,
          id: virtualFormData.state?.value.toString(),
          code: virtualFormData.state?.code
        },
        city: {
          name: virtualFormData.city?.label,
          id: virtualFormData.city?.value.toString(),
          code: virtualFormData.city?.code
        }
      },
      type: 'virtual'
    }
    if (isEdit) {
      dispatch(editPickupLocation({body, hub_code:selectedPickUpLocation.hub_code, seller_id: filter.seller_id?.value || selectedSeller?.value}))
    } else {
      dispatch(addNewPickupLocation({body, seller_id: filter.seller_id?.value || selectedSeller?.value}))
    }
  }

  const handleEdit = (row) => {
    setSelectedPickupLocation(row)
    setIsVirtualHubFormModalOpen(true)
    setIsEdit(true)
  }

  const handlePagination = page => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 10 }
    setPagination(pagination)
  }

  const tableActions = [
    {
      id:1,
      label: 'Edit',
      icon: <Edit2 size={16} />,
      onClick: (row) => {
        handleEdit(row)
      },
      hasPermission:() => (isPoweredFromShippingAggregator ? ability.can(abilityMap.pickup_location_v2.edit.action, abilityMap.pickup_location_v2.edit.resource) : ability.can(abilityMap.pickup_location.edit.action, abilityMap.pickup_location.edit.resource)),
    }
  ]

  // columns
  const columns = [
    {
      id: 1,
      name: <div className='w-100 text-center'>{t('S. No.')}</div>,
      width: '80px',
      key: 'serialNumber',
      cell: (_, idx) => <div className='w-100 text-center'> {((pickupLocationMeta?.current_page - 1) * pickupLocationMeta?.per_page) + idx + 1 || idx + 1}</div>
    },
    {
      id: 2,
      name: t(isPoweredFromShippingAggregator ? 'Address Code' : 'Location Code'),
      minWidth: '200px',
      reorder: true,
      key: 'hub_code',
      cell: (row) => {  
        return row.hub_code ? <CopyOnClick id={row.id}  columnKey="location_code">{row.hub_code}</CopyOnClick> : '-'
      }
    },
    {
      id: 3,
      name: t(isPoweredFromShippingAggregator ? 'Address Name' : 'Location Name'),
      minWidth: '200px',
      reorder: true,
      key: 'hub_name',
      cell: (row) => {
        return <span>{row.name || '-'}</span>
      }
    },
    {
      id: 4,
      name: t('Source'),
      minWidth: '120px',
      reorder: true,
      key: 'display_source',
      cell: (row) => {
        return <span>{row.display_source || '-'}</span>
      }
    },
    {
      id: 5,
      name: t('Contact Number'),
      minWidth: '150px',
      reorder: true,
      key: 'contact_number',
      cell: (row) => {
        return <span>{row.phone_number || '-'}</span>
      }
    },
    {
      id: 6,
      name: t('City'),
      minWidth: '150px',
      reorder: true,
      key: 'city',
      cell: (row) => {
        return <span>{row.address.city.name || '-'}</span>
      }
    },
    {
      id: 7,
      name: t('Country '),
      minWidth: '150px',
      reorder: true,
      key: 'country',
      cell: (row) => {
        return <span>{row.address.country.name || '-'}</span>
      }
    },
    {
      id: 8,
      name: t('Created By '),
      key: 'created_by',
      reorder: true,
      minWidth:'120px',
      cell: (row) => {
        return <span>{row.created_by?.name || '-'}</span>
      }
    },
    {
      id: 9,
      name: t('Created At '),
      key: 'created_at',
      minWidth: '200px',
      reorder: true,
      cell: (row) => {
        return <span>{row.created_at || '-'}</span>
      }
    },
    {
      id: 10,
      name: t('Updated By'),
      minWidth:'120px',
      key: 'updated_by',
      reorder: true,
      cell: (row) => {
        return <span>{row.updated_by?.name || '-'}</span>
      }
    },
    {
      id: 11,
      name: t('Updated At'),
      minWidth:'200px',
      key: 'updated_at',
      reorder: true,
      cell: (row) => {
        return <span>{row.updated_at || '-'}</span>
      }
    },
    {
      id: 12,
      width: '80px',
      name:'Action',
      key: 'action',
      cell: (row) => <TableActionButton actions={tableActions} row={row} />,
      omit:!(isPoweredFromShippingAggregator ? ability.can(abilityMap.pickup_location_v2.edit.action, abilityMap.pickup_location_v2.edit.resource) : ability.can(abilityMap.pickup_location.edit.action, abilityMap.pickup_location.edit.resource)),
    }
  ]
  const handleRefetch = () => {
    dispatch(
      getPickupLocation({
        ...searchQuery,
        ...pagination,
        seller_id: filter.seller_id?.value || selectedSeller?.value
      }))
  }

  const filterHandler = ({ filterkey, filterValue }) => {
    if (!filterValue) {
      delete filter[filterkey]
      setFilter(filter)
      return
    }
    setFilter({ ...filter, [filterkey]: filterValue })
  }

  useEffect(() => {
    if (!pickupLocationLoading) {
      dispatch(
        getPickupLocation({
          ...searchQuery,
          ...pagination,
          seller_id: filter.seller_id?.value || selectedSeller?.value
        })
      )
    }
  }, [])

  useEffect(() => { 
    if (success || successAddNewHub) {
      dispatch(
        getPickupLocation({
          ...searchQuery,
          ...pagination,
          seller_id: filter.seller_id?.value || selectedSeller?.value
        })
      )
      setIsVirtualHubFormModalOpen(false)
      setIsEdit(false)
      dispatch(resetSuccess())
    }
  }, [success, successAddNewHub])

  console.log({filter, selectedSeller})
  
  return (
    <div>
      <PageHeader {...pageHeaderProps} />
      {((ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource) && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE)) && <div className='pt-2 ps-2'>
        <DropdownWithTitle
          isAsync
          isClearable={!!filter.seller_id}
          defaultValue={selectedSeller}
          value={(() => {
            if (!filter.seller_id) return selectedSeller
            return filter.seller_id
          })()}
          title={t('Sellers')}
          loadOptionsHandler={loadAllSellersOptions}
          selectOptionHandler={(value) => filterHandler({ filterkey: 'seller_id', filterValue: value })}
          externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
        />
      </div>}
      <div className='p-16px'>
        <ExpandableCustomTable
          loading={pickupLocationLoading}
          success={pickupLocationSuccess}
          error={pickupLocationError}
          columns={columns}
          columnName={ALL_COLUMN_NAME_MAPPING.PICKUP_LOCATION}
          searchcolumnsList={searchColumnList}
          data={pickupLocationData || []}
          TableHeaderComponent={(isPoweredFromShippingAggregator ? ability.can(abilityMap.pickup_location_v2.create.action, abilityMap.pickup_location_v2.create.resource) : ability.can(abilityMap.pickup_location.create.action, abilityMap.pickup_location.create.resource)) && 
            <div className="ps-14px border-start border-primary-lighter">
              <Button className='gap-4px' onClick={() => setIsVirtualHubFormModalOpen(true)} icon={() => <Plus size={16} />}>
                <span>{t(isPoweredFromShippingAggregator ? 'Create Address' : 'Create Location')} </span>
              </Button>
            </div>
          }
          search_column_query={searchQuery}
          handleQueryParams={handleSearchItem}
          showColumnsTableHeader={true}
          handleRefreshTable={handleRefetch}
          handlePagination={handlePagination}
          meta={pickupLocationMeta || {}}
        />
        <AddNewVirtualHubFormModal isVirtualHubFormModalOpen={isVirtualHubFormModalOpen} resetVirtualHubFormModal={() => {
          setIsVirtualHubFormModalOpen(false)
          setIsEdit(false)
        }} handleVirtualHubFormSubmit={handleVirtualHubFormSubmit} virtualHubFromOpenFor={isPoweredFromShippingAggregator ? 'Pickup Address' : 'Locations'} selectedPickUpLocation={selectedPickUpLocation} isLoading={loading} isEdit={isEdit} />
      </div>
    </div>
  )
}

export default PickupLocation