import CollapsibleKeyValueList from '@src/components/collapsible-key-value-list/collapsible-key-value-list'
import { useTranslation } from 'react-i18next'

const TotalAmount = ({ itemsTotal, subtotal, tax, currency }) => {
  const { t } = useTranslation()
  const totalBreakdownFields = [
    { label: 'Sub Total', value: `${subtotal} ${currency}` },
    { label: 'Tax', value: `${tax} ${currency}` },
  ]
  
  return (
    <CollapsibleKeyValueList
      key='total_amount'
      label={t('Total Amount')}
      value={`${itemsTotal} ${currency}`}
      collapsibleFields={totalBreakdownFields} 
    />
  )
}

export default TotalAmount