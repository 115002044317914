import { useLocation, useParams } from 'react-router-dom';

const ShipmentsTracking = () => {
  const location = useLocation(); 
  const { trackingId } = useParams();

  if (location.pathname !== `/tms/shipments/tracking/${trackingId}`) {
    window.location.href = `/tms${location.pathname}`;
    return null;
  } else return null;
  
};

export default ShipmentsTracking;