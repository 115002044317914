import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { API_ENDPOINTS } from '@src/api.urls'
import { MAX_PER_PAGE_LIMIT } from '@src/App.constants'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent } from '@src/network/RealTime'
import { uploadFileOnS3 } from '@src/redux/authentication'
import { getApiUrl, paramsSerializer } from '@src/utility/Utils'
import { PUTAWAY_TYPES } from '../inventory.constants'

const urlPrefixProvider = (sellerId) => `/api/v1/products/sellers/${parseInt(sellerId)}`

// thunk actions

export const handleBreakOpen = createAsyncThunk('inventory/open-pallet-or-casepack', async ({ grn_id, ...body }, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.put(`/api/v1/wms/hubs/${hubId}/grns/${grn_id}/case_pack/open`, body)
  return { currentSkuId: body.seller_sku_id, ...res?.data?.data }
})

export const getTotalInventory = createAsyncThunk('inventory/get-total-inventory', async params => {
  if (params.hub_id) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${params.hub_id}/inventory`, { params })
    return res?.data
  }
})

export const assembledKitDetails = createAsyncThunk('assembly-kit/barcodes-details', async ({ pathParams }) => {
  if (pathParams.hub_id) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/assembly/tasks/${pathParams.assembly_task_id}/items/${pathParams.assembly_task_item_id}/details`, { params: pathParams.param })
    return res?.data
  }
})

export const exportAssembledSku = createAsyncThunk(
  'assembly-sku-export/sku',
  async ({ pathParams }) => {
    if (pathParams.hub_id) {
      const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/assembly/tasks/${pathParams.assembly_task_id}/items/${pathParams.assembly_task_item_id}/export`)
      return res.data
    }
  }
)

// upload safty stock in bulk 
export const bulkSafetyStockUpload = createAsyncThunk('safety-stock/upload', async ({ body }, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.put(`/api/v1/wms/hubs/${hubId}/sellers/${body.seller_id}/safety_stock`, body)
  return res.data
}
)

export const getLocationInventory = createAsyncThunk('inventory/get-location-inventory', async (params, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/location_inventory/skus`, { params })
  return {
    data: res
  }
})

export const getSkusToLocationData = createAsyncThunk(
  'inventory/skus-to-location',
  async (params, store) => {
    if (params.seller_name) {
      delete params.seller_name
    }
    const hubId = store.getState().auth.selectedGlobalHubId
    if (hubId) {
      const res = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/locations/skus`, { params })
      return res?.data
    }
  }

)

export const createGateEntry = createAsyncThunk('/gate-entry/create', async ({ pathParams, body }) => {
  if (pathParams.hub_id) {
    const res = axiosInstance.post(`/api/v1/wms/hubs/${pathParams.hub_id}/gate_entries`, body)
    return res
  }
})
export const createGateEntryForPo = createAsyncThunk('/gate-entry/po-enabled/create', async ({ pathParams, body }) => {
  if (pathParams.hub_id) {
    const res = axiosInstance.post(`/api/v1/wms/hubs/${pathParams.hub_id}/gate_entries/purchase_order`, body)
    return res
  }
})

export const createNewGrn = createAsyncThunk('/grn/createNewGrn', async body => {
  const { hub_id, ...params } = body
  if (hub_id) {
    const res = await axiosInstance(`/api/v1/wms/hubs/${body.hub_id}/grns/gate_entries`, params)
    return res
  }
})

export const createNewGrnForGateEntry = createAsyncThunk('/grn/createNewGrnForGateEntry', async body => {
  const { hub_id, errorConfig , ...params } = body
  if (hub_id) {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${body.hub_id}/grns/gate_entry`, {...params}, {_audioRequired: Boolean(errorConfig?.audioRequired)})
    return res.data
  }
})

export const createNewGrnForPo = createAsyncThunk('/grn/createNewGrnForPo', async data => {
  const { hub_id, ...body } = data
  if (hub_id) {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/grns/purchase_order`, body)
    return res.data
  }
})
export const createNewGrnForSto = createAsyncThunk('/grn/createNewGrnForSto', async body => {
  if (body.hub_id) {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${body.hub_id}/grns/stock_transfer_order`, { entity_id: body.entity_id, entity_type: body.entity_type })
    return res.data
  }
})
export const createNewGrnForSellerAndSupplier = createAsyncThunk('/grn/createNewGrnForSellerAndSupplier', async body => {
  if (body.hub_id) {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${body.hub_id}/sellers/${body.seller_id}/grns`, { supplier_id: body.supplier_id })
    return res.data
  }
})

export const getHubConfiguration = createAsyncThunk('/grn/getHubConfiguration', async ({ pathParams }) => {
  if (pathParams.hub_id) {
    const params = {
      per_page: MAX_PER_PAGE_LIMIT
    }
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/configurations`, { params })
    return res
  }
})

export const getSuppliers = createAsyncThunk('/suppliers', async params => {
  if (params.seller_id) {
    const res = await axiosInstance.get(`/api/v1/wms/sellers/${params.seller_id}/suppliers`)
    return res
  }
})

export const getPurchaseOrders = createAsyncThunk('/purchase_orders', async params => {
  if (params.hub_id) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${params.hub_id}/purchase_order`)
    return res
  }
})

export const getSkuDetails = createAsyncThunk('/grn/getSkuDetails', async body => {
  const { hub_id, gtinSkuScanned, errorConfig, ...queryParams } = body
  if (hub_id) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/grns/sku_details`, { params: queryParams, _audioRequired: Boolean(errorConfig?.audioRequired) })
    return {data:res?.data, gtinSkuScanned}
  }
})

export const generateCartonBarcodes = createAsyncThunk('/gate/generateCartonBarcodes', async ({ pathParams, body }) => {
  if (pathParams.hub_id) {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${pathParams.hub_id}/gate_entries/cartons`, body)
    return res?.data
  }
})

export const scanPassBinAtGrn = createAsyncThunk('/gate/scanPassBinAtGrn', async body => {
  const { hub_id, errorConfig, ...bodyParams } = body
  // grn_id:number, bin_qc_type:"pass","fail", barcode
  if (hub_id) {
    // try {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/grn/bin/scan`, bodyParams, {_audioRequired: Boolean(errorConfig?.audioRequired)})
    return res.data
    // } catch (err) {
    //   CustomToast(err?.response?.data?.error?.message, { my_type: 'error', duration: 3000 })
    //   // return err?.response?.data?.message
    // }
  }
})

export const scanFailBinAtGrn = createAsyncThunk('/gate/scanFailBinAtGrn', async body => {
  const { hub_id, errorConfig, ...config } = body
  // grn_id:number, bin_qc_type:"pass","fail", barcode
  if (hub_id) {
    // try {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${body.hub_id}/grn/bin/scan`, {...config}, {_audioRequired: Boolean(errorConfig?.audioRequired)})
    return res.data
    // } catch (err) {
    //   // debugger
    //   CustomToast(err?.response?.data?.error?.message, { my_type: 'error', duration: 3000 })
    //   // return err?.config?.data?.message
    //   // return err
    // }
  }
})

export const generateBarcode = createAsyncThunk('/grn/printItemBarcode', async body => {
  // {sellerId, skuId} in query params
  const { hub_id, seller_id, seller_sku_id, ...rest } = body
  if (hub_id) {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/grns/sku/barcode?seller_id=${seller_id}&seller_sku_id=${seller_sku_id}`, rest)
    return res?.data
  }
})

export const createBatch = createAsyncThunk('/gate/addBatch', async body => {
  const { hub_id, grn_id, ...postBody } = body
  if (hub_id) {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/grn/${grn_id}/batch`, postBody)
    return res?.data
  }
})

export const addSkuInGrnBin = createAsyncThunk('/gate/addSkuInGrnBin', async body => {
  try {
    if (body.bin_status === 'fail') {
      // uploading the images on s3
      const images = body.reason_images
      const uploadIdsArr = []
      for (let i = 0; i < images.length; i++) {
        const upload_id = await uploadFileOnS3({
          service: 'grn',
          usecase: 'reason_images',
          file: images[i]
        })
        uploadIdsArr.push(upload_id)
      }
      // updating the reason_images key
      body.reason_images = uploadIdsArr
    }
  } catch (error) {
    CustomToast('Image upload failed. Please try again', { my_type: 'error', duration: 2000, audioRequired: false })
    throw error
  }

  // making the backend request
  const { hub_id, seller_sku_id, ...postParams } = body
  if (hub_id) {
    const res = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/grn/bin/sku`, postParams, {_audioRequired: true})
    return { seller_sku_id, ...res?.data, grnBinAssignedQty:body.quantity, binStatus:body.bin_status }
  }
})

export const generatePassBinId = createAsyncThunk('/grn/generatePassBinId', async body => {
  const { hub_id, ...bodyParams } = body
  if (hub_id) {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/grn/bin`, bodyParams)
    return res?.data
  }
})

export const generateFailBinId = createAsyncThunk('/grn/generateFailBinId', async body => {
  const { hub_id, ...bodyParams } = body
  if (hub_id) {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/grn/bin`, bodyParams)
    return res?.data
  }
})

// Gate Entry
export const getGateEntries = createAsyncThunk('/gate/getGateEntries', async ({ pathParams, params }) => {
  if (pathParams.hub_id) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/gate_entries`, { params })
    return res?.data
  }
})
export const getSingleGateEntries = createAsyncThunk('/gate/getSingleGateEntries', async ({ pathParams, id }) => {
  if (pathParams.hub_id) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/gate_entries/${id}`)
    return res?.data
  }
})

export const getGrns = createAsyncThunk('/gate/getGrns', async ({ pathParams, params }) => {
  if (pathParams.hub_id) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/grns`, { params })
    return res?.data
  }
})

// PUTAWAY
export const getPutaways = createAsyncThunk('/putaway/getPutaways', async params => {
  const { hub_id, putawayType, ...paginationParams } = params
  if (hub_id) {
    if (putawayType === PUTAWAY_TYPES.BIN_PUTAWAY) {
      const res = await axiosInstance.get(`/api/v1/wms/hubs/${params.hub_id}/bin_put_away`, { params: { ...paginationParams } })
      return res?.data
    } else if (putawayType === PUTAWAY_TYPES.ITEM_PUTAWAY) {
      const res = await axiosInstance.get(`/api/v1/wms/hubs/${params.hub_id}/item_put_away`, { params: { ...paginationParams } })
      return res?.data
    }
  }
})
export const getPutawayItems = createAsyncThunk('/putaway/getPutawayItems', async params => {
  const { hub_id, ...queryParams } = params
  if (hub_id) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/put_away_items`, { params: queryParams })
    return res?.data
  }
})
//Sellers
export const getAllSellers = createAsyncThunk('purchase/getAllSellers', async () => {
  const res = await axiosInstance.get('/api/v1/tenant/sellers?sort=name_asc')
  return res?.data
})
//Suppliers
export const getAllSuppliers = createAsyncThunk('purchase/getAllSuppliers', async ({ pathParams }) => {
  const res = await axiosInstance.get(`/api/v1/wms/sellers/${pathParams.seller_id}/suppliers`)
  return res?.data
})
//Purchase Order
export const getPurchaseOrderList = createAsyncThunk('purchase/purchase-order', async ({ pathParams }) => {
  if (pathParams.hub_id) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/purchase_order`)
    return res?.data
  }
})
export const getBinStatus = createAsyncThunk('grn/bin-status', async ({ grn_id, hub_id, bin_barcode, seller_sku_id, type, source, errorConfig: {audioRequired} }) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/bins`, { 
    params: { 
      entity_id: grn_id, 
      entity_type: 'grn', 
      barcode: bin_barcode, 
      type, 
      source 
    },
    _audioRequired: Boolean(audioRequired)
  })
  return { seller_sku_id, ...res?.data }
})
//GRN Final
export const createHubSkuSerialisationStatus = createAsyncThunk('grn/sku-serialisation', async params => {
  const { hub_id, ...body } = params
  if (hub_id) {
    const res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/grns/serialisation`, body)
    return res?.data
  }
})
export const setSkuConfig = createAsyncThunk('grn/sku/config', async params => {
  const { hub_id, is_edit, ...body } = params
  let res
  if (is_edit) {
    res = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/grns/sku/configuration`, body)
  } else {
    res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/grns/sku/configuration`, body)
  }
  return res?.data
})


// http://localhost:3000/api/v1/oms/orders/64a2971071155f2e91b725bc/stock_transfer_order
export const getSingleStoList = createAsyncThunk('purchase/single-stock-transfer-order-list', async ({ pathParams }) => {
  const res = await axiosInstance.get(`/api/v1/oms/orders/sto?seller_sales_channel_order_id=${pathParams.sto_id}`)
  return res?.data
})

export const getStoItems = createAsyncThunk('get-sto-items', async ({ hub_id, sto_id, ...params }) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/stock_transfer_order/${sto_id}`, { params })
  return res?.data
})

// GRN Items View
export const getGrnItemsViewOverviewDetails = createAsyncThunk('grn/grn-items-view/overview', async ({ hub_id, grn_id }) => {
  // debugger
  const res = axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/grns/${grn_id}/items`)
  return res
})
export const getGrnPutawayDetails = createAsyncThunk('grn/grn-items/putaway-details', async ({ hub_id, grn_id, putawayType, ...params }) => {
  // debugger

  if (putawayType === PUTAWAY_TYPES.ITEM_PUTAWAY) {
    const res = axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/grns/${grn_id}/item_put_away`, { params })
    return res
  } else {
    const res = axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/grns/${grn_id}/bin_put_away`, { params })
    return res
  }
})
// get hub wise inventory sidebar data
export const getHubWiseInventorySidebarData = createAsyncThunk('total-inventory/get-hub-wise-inventory-sidebarData', async inventoryId => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/inventory/${inventoryId}`)
  return res.data.data
})

export const bulkSkuUploadTotalInventary = createAsyncThunk(
  'inventory/sku-upload',
  async (body, store) => {
    const hubId = store.getState().auth.selectedGlobalHubId
    if (hubId) {
      const res = await axiosInstance.put(`/api/v1/wms/hubs/${hubId}/sellers/${body.sellerId}/inventory`, body.data)
      return res.data
    }
  }
)

export const uploadSkusToLocationMapping = createAsyncThunk(
  'inventory/sku-mapping',
  async (body, store) => {
    const hubId = store.getState().auth.selectedGlobalHubId
    if (hubId) {
      const res = await axiosInstance.post(`/api/v1/wms/hubs/${hubId}/sellers/${body.sellerId}/location/skus/upload`, body.data)
      return res.data
    }
  }
)

export const getSku = createAsyncThunk('inventory/sku', async (params) => {
  const res = await axiosInstance.get(
    `/api/v1/products/sellers/${params.seller_id}/skus/${params.sku_id}`
  )
  return res.data
})

export const getSkuSalesChannel = createAsyncThunk(
  'inventory/skuSalesChannel',
  async (params) => {
    // const sellerId = store.getState()?.catalog?.selectedSeller?.value
    // seller_sales_channels/99/sales_channel_skus/6453cd4d6e413aa4c7ba7f05
    const res = await axiosInstance.get(
      `/api/v1/products/sellers/${params.seller_id}/sales_channel_skus/${params.sku_id}`
    )
    return res.data
  }
)
//inventory -> inventory Operations -> pending putaway
//start
export const getGrnPendingPutaway = createAsyncThunk('inventory/grnPendingPutaway', async (params, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/grns/pending/putaways`, { params })
  return res
})

export const getGrnPendingPutawayTotalSkus = createAsyncThunk('inventory/grnPendingPutaway/totalSKUs', async (params, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const { grnId, binId } = params
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/grns/${grnId}/bins/${binId}/items`)
  return res
})

export const getCancelledItemPutaway = createAsyncThunk('inventory/cancelled-item-putaway', async (params, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/${hubId}/orders/put_away/pending`, { params })
  return res
})
//end

export const getProduct = createAsyncThunk(
  'inventory/product',
  async (params, store) => {
    const sellerId = store.getState()?.inventory?.selectedSeller?.value
    const res = await axiosInstance.get(
      `${urlPrefixProvider(sellerId)}/products/${params.product_id}`
    )
    return res.data.data
  }
)

export const getSkus = createAsyncThunk(
  'inventory/skus',
  async (params, store) => {
    const sellerId = store.getState()?.inventory?.selectedSeller?.value
    const res = await axiosInstance.get(
      `${urlPrefixProvider(sellerId)}/skus?${paramsSerializer(params)}`
    )
    return { data: res }
  }
)

export const getUnmappedSkusData = createAsyncThunk(
  'inventory/unmapped-skus',
  async (params, store) => {
    const hubId = store.getState().auth.selectedGlobalHubId
    if (hubId) {
      const res = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/locations/skus/unmapped`, { params })
      return res?.data
    }
  }
)

export const getCancelPutwayOrders = createAsyncThunk('cancel-putway/orders', async ({ sellerSkuId, pageParam }, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  if (hubId) {
    const res = await axiosInstance.get(`/api/v1/wms/${hubId}/orders/put_away/pending/skus/${sellerSkuId}`, { params: pageParam })
    return res?.data
  }

})

export const getBatches = createAsyncThunk('/inventory/batches', async (params, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/batch/inventory`, { params })
  return res?.data
})

export const importBatchesBulk = createAsyncThunk(
  'inventory/importBulkBatches',
  async ({seller_id, ...body}, store) => {
    const allStore = store.getState()
    const sellerId = allStore.auth.userData.tenant.type !== 'Self' ? seller_id : allStore.auth.globalSeller?.seller_id
    const res = await axiosInstance.post(
      `/api/v1/wms/sellers/${sellerId}/batches/upload`,
      body
    )
    return res.data
  }
)

export const importBulkUploadedExpiryThresholdFile = createAsyncThunk(
  'inventory/importBulkUploadedExpiryThresholdFile',
  async ({seller_id, ...body}, store) => {
    const allStore = store.getState()
    const hubId = allStore.auth.selectedGlobalHubId
    const sellerId = allStore.auth.userData.tenant.type !== 'Self' ? seller_id : allStore.auth.globalSeller?.seller_id
    const res = await axiosInstance.put(
      `/api/v1/wms/hubs/${hubId}/sellers/${sellerId}/expiry`,
      body
    )
    return res.data
  }
)

export const getReturnGrnSkuDetails = createAsyncThunk('/return-order/getSkuDetails', async (params, store) => {
  const { gtinSkuScanned, errorConfig, ...rest } = params
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/returns/grns/sku_details`, { params:rest, _audioRequired: Boolean(errorConfig?.audioRequired) })
  return {data: res.data, gtinSkuScanned}
})

// export const setQcTypeData = createAsyncThunk('/return-order/qc-type-data', async (body, store) => {
//   const hub_id = store.getState()?.auth?.selectedGlobalHubId
//   const res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/returns/grns/order/details`, body)
//   return res.data
// })

export const getAwbDetailsData = createAsyncThunk('/gate-entry/awb-details', async ({ hubId, awbNumber, errorConfig }) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/gate_entries/awb/${awbNumber}`,{_audioRequired: Boolean(errorConfig?.audioRequired)})
  return res.data
})
export const changeSkuWeight = createAsyncThunk('/change-sku-wight', async ({ seller_id, seller_sku_id, body, mode }) => {
  const res = await axiosInstance.patch(`/api/v1/products/sellers/${seller_id}/skus/${seller_sku_id}`, body)
  return { ...res.data, skuId: seller_sku_id, body, mode }
})

export const getCycleCountLocations = createAsyncThunk('get-cycle-count-locations', async ({ cc_id, ...params }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/cycle_counts/${cc_id}/items`, { params })
  return res.data
})

export const updateInventoryLocations = createAsyncThunk('update-inventory-locations', async ({ cc_id, ...body }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/cycle_counts/${cc_id}/update_inventory`, body)
  return res.data
})

export const deleteCycleCountLocations = createAsyncThunk('delete-cycle-count-location', async ({ cc_id, ...params }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.delete(`/api/v1/wms/hubs/${hub_id}/cycle_counts/${cc_id}/locations/delete?${paramsSerializer(params)}`)
  return res.data
})

export const getCycleCountList = createAsyncThunk('/cycle_counts', async (params, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/cycle_counts`, { params })
  return response?.data
})

export const createCycleCount = createAsyncThunk('/create-cycle-count', async ({ body }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/cycle_counts`, body)
  return response.data
})

export const bulkCycleCountUpload = createAsyncThunk('/cycle-count-bulk/upload', async ({ body }, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.post(`/api/v1/wms/hubs/${hubId}/cycle_counts/bulk_upload`, body)
  return res.data
}
)

export const editCycleCount = createAsyncThunk('/edit-cycle-count', async ({ id, body }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/cycle_counts/${id}/edit`, body)
  return response.data
})

export const addLocationCycleCount = createAsyncThunk('/add-location-cycle-count', async ({ id, body }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/cycle_counts/${id}/locations/add`, body)
  return response.data
})

export const getLocationItemDetails = createAsyncThunk('location-item-details', async ({ cc_id, location_id }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/cycle_counts/${cc_id}/locations/${location_id}/items`)
  return res.data
})

export const getSingleBinSkus = createAsyncThunk('single-bin-skus', async ({ cc_id, location_id, bin_id }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/cycle_counts/${cc_id}/locations/${location_id}/bins/${bin_id}/items`)
  return res.data
})

export const markLocationsForRecount = createAsyncThunk('mark-locations-for-recount', async ({ cc_id, ...body }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/cycle_counts/${cc_id}/status`, body)
  return res.data
})

export const getLocationsCountForPrefixes = createAsyncThunk('get-locations-count-for-prefix', async ({ location_prefixes }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/locations/prefix?${paramsSerializer({ location_prefixes })}`)
  return res.data
})

export const rejectInventoryLocations = createAsyncThunk('mark-locations-rejected', async ({ cc_id, ...body }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/cycle_counts/${cc_id}/status`, body)
  return res.data
})

export const getSingleGrnDetail = createAsyncThunk('single-grn/detail', async ({ grn_id }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/grns/${grn_id}/details`)
  return res.data
})
export const getSkuDetailInGrnBin = createAsyncThunk('sku-detail/in-bin', async ({ grn_id, params, errorConfig }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/grns/${grn_id}/bin/sku`, {
    params, 
    _audioRequired: Boolean(errorConfig?.audioRequired)
  })
  return res.data
})
export const updateSkuQtyInGrnBin = createAsyncThunk('update-sku-qty/in-bin', async ({ grn_id, body }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/grns/${grn_id}/bin/sku`, body)
  return res.data
})

export const getDisplayGrnDetail = createAsyncThunk('get-grn-details-display/grn', async ({ grn_id }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/grns/${grn_id}/order`)
  return res.data
})
export const completeGRN = createAsyncThunk('create-new-grn/complete-grn', async ({ grn_id }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/grns/${grn_id}/complete`)
  return res.data
})

export const getSkusByNameAndCode = createAsyncThunk(
  'creat-new-grn/get-sku-by-code-and-nam',
  async ({ seller_id, query, params }, store) => {
    const hub_id = store.getState().auth.selectedGlobalHubId
    const res = await axiosInstance.get(
      `/api/v1/wms/hubs/${hub_id}/grns/sku/details`,
      { params: { seller_id, query, ...params } }
    )
    return res?.data
  }
)

//Serialise Upload Barcode Support new grn
export const bulkUploadSerializeBarcode = createAsyncThunk(
  'bulk/upload/serialised/skus',
  async ({ body, hubId, grnId }) => {
    const apiUrl = getApiUrl(API_ENDPOINTS.INVENTORY.BULK_UPLOAD_SERIALISE_BARCODE, { hubId, grnId })
    const response = await axiosInstance.post(apiUrl, body)
    return response?.data
  }
)

export const getAssembleTasks = createAsyncThunk('assemble/task', async (params, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const apiUrl = getApiUrl(API_ENDPOINTS.INVENTORY.GET_ASSEMBLED_TASKS, { hubId })
  const res = await axiosInstance.get(apiUrl, { params })
  return res?.data
})
export const getAssembleTaskDetail = createAsyncThunk('assemble/task/detail', async ({ taskId, hub_id }, store) => {
  const hubId = hub_id || store.getState().auth.selectedGlobalHubId
  const apiUrl = getApiUrl(API_ENDPOINTS.INVENTORY.GET_ASSEMBLED_TASK_DETAIL, { hubId })
  const res = await axiosInstance.get(apiUrl, { params: { assembly_task_id: taskId } })
  return res?.data
})
export const getAdjustInventoryList = createAsyncThunk('adjust/inventory', async (params, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const apiUrl = getApiUrl(API_ENDPOINTS.INVENTORY.GET_ADJUST_INVENTORY_LIST, { hubId })
  const res = await axiosInstance.get(apiUrl, { params })
  return res?.data
})
export const getAdjustInventorySkuList = createAsyncThunk('adjust/inventory/sku', async ({ binId }, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const apiUrl = getApiUrl(API_ENDPOINTS.INVENTORY.GET_ADJUST_INVENTORY_SKU_LIST, { hubId, binId })
  const res = await axiosInstance.get(apiUrl)
  return res?.data
})
export const updateInventoryQty = createAsyncThunk('update/qty', async (body, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const apiUrl = getApiUrl(API_ENDPOINTS.INVENTORY.UPDATE__INVENTORY_QTY, { hubId })
  const res = await axiosInstance.put(apiUrl, body)
  return res?.data
})
export const getSupplierDetail = createAsyncThunk('get/supplier/details', async ({seller_id, page, query}) => {
  const response = await axiosInstance.get(`/api/v1/wms/sellers/${seller_id}/suppliers?sort=name_asc`, {
    params: { search_column: 'name', search_query: query, page }
  })
  return response?.data
})

export const editBatch = createAsyncThunk('/batch-inventory/edit', async ({ batch_id, ...body}) => {
  const res = await axiosInstance.put(`/api/v1/wms/batches/${batch_id}`, body)
  return res?.data
})

export const uploadMaxShelfLife = createAsyncThunk(
  'inventory/uploadMaxShelfLife',
  async ({seller_id, ...body}, store) => {
    const allStore = store.getState()
    const hubId = allStore.auth.selectedGlobalHubId
    const sellerId = allStore.auth.userData.tenant.type !== 'Self' ? seller_id : allStore.auth.globalSeller?.seller_id
    const res = await axiosInstance.put(
      `/api/v1/wms/hubs/${hubId}/sellers/${sellerId}/shelf_life`,
      body
    )
    return res.data
  }
)

const initialState = {

  loading: false,
  errors: {},
  skusToLocationData: {
    success: false,
    loading: false,
    error: false,
    data: {}
  },
  totalInventoryData: {
    data: {},
    loading: false,
    error: false,
    success: false
  },
  assembledKitData: {
    data: {},
    loading: false,
    success: false,
    error: false
  },
  uploadAssembledSku: { loading: false, is_success: false, data: null },
  unmappedSkusData: {},
  error: false,
  SynchronisedTotalInventoryData: [],
  locationInventoryData: [],
  filtersData: [],
  activeFilter: 0,

  hubConfiguration: {},

  allSellersList: [],
  allSuppliersList: [],
  allPurchaseOrdersList: [],
  po_enabled: false,
  gate_entry_enabled: false,
  sellerId: '',
  supplier: null,
  seller: null,
  selectedSeller: null,
  singleGateEntry: null,
  singleGateEntryLoading: false,
  stoDetail: null,
  stoRequestMetaData: null,
  stoLoading: true,
  stoOrderID: null,
  product: {},
  sku: {},
  skuSalesChannel: null,
  skus: [],
  batches: {},
  batchesBulkUploadedFile: null,
  bulkUploadedExpiryThresholdFile: null,
  importBatchesBulkResponse: null,
  importExpiryThresholdBulkResponse: null,
  expiryDateSort: 'expirydate_asc',
  awbDetails: null,

  gateEntry: {
    loading: false,
    error: false,
    success: false,
    gateEntryData: { data: [] },
    hasGateEntryConfigured: false,
    generatedCartonBarcodes: {},
    createGateEntryResponse: {},
    gate_entry_create_loading: false,
    // new
    gateEntries: [],
    gate_entry_created_success: false
  },
  skuConfigResponse: {},
  createBatchResponse: {},
  grn: {
    success: false,
    ClientSkuId: { id: '', valid: false },
    suppliersList: [],
    sellersList: [],
    grns: [],
    loading: false,
    error: false,
    purchase_orders: [],
    generatedBarcode: '',
    grnResponse: {},
    noOfItems: 0,
    typeOfBarcode: {},
    itemsBarcodesResponse: [],
    isPrintButtonDisabled: false,
    hasScanningStarted: false,
    hasScanningCompleted: false,
    addSkuInGrnBinResponse: {},
    carton_barcode: '',
    po_number: '',
    sku_details: null,
    // --------------
    grnChildSkuData: null,
    childAddedSuccess: false,
    assignmentDone: false,
    // --------------
    is_serialised: null,
    pass_bin_id: '',
    fail_bin_id: '',
    scanned_pass_bin_id: '',
    scanned_fail_bin_id: '',
    batch_data: [],
    itemIdStatus: false,
    sku_details_loading: false,
    no_sku_found: false,
    hub_sku_serialisation_status_response: '',
    loading_barcode_generation: false,
    item_barcode_loading: false,
    sku_barcode: '',
    scan_bin_error: false,
    fail_bin_error: false,
    pass_bin_error: false,
    has_barcode_mapped: false,
    has_barcode_printed: false,
    bin_status: '',
    bin_status_success: false,
    addSkuInGrnBinError: false,
    hasItemBarcodesPrinted: false,
    serialisation_status: '',
    batch_status: 'undefined',
    create_batch_succes: true,
    config_type: '',
    next_config_step: 'batching',
    importSkusBulkResponse: null,
    skuBulkUploadedFile: null,
    importSkusMappingResponse: null,
    skuMappingUploadedFile: null,
    skuDetails: null,
    grnImagesUploadedFiles: [],
    // qcTypeData: null,
    singleGrnDetail: null,
    skuDetailInBin: null,
    skuQtyUpdated: { key: false, bin_barcode: null },
    displayGrnDetails: null,
    lastScannedBin: null,
    lastScannedSkuComplete: null,
    completeGRNResponse: null,
    getSkusByNameAndCodeResponse: null,
    grnSkuDetails: null,
    current_sku_id: null
  },
  grnItems: {
    overViewData: [],
    loading: false,
    error: false,
    success: false
  },
  grnPutaway: {
    loading: false,
    error: false,
    success: false,
    putawayData: null
  },
  putaway: {
    putawayTableData: [],
    putawayItemsForBinPutaway: [],
    loading: false,
    error: false,
    success: false
  },
  // hub wise inventory Sidebar
  hubWiseInventorySidebarMeta: null,
  hubWiseInventorySidebarLoading: {},
  hubwiseInventorySidebarData: null,
  //pending putaway
  //start
  grnPendingPutaway: {
    grnPendingPutawayData: [],
    meta: {},
    loading: {},
    error: {},
    success: {}
  },
  skuDataOfGrnPendingPutaway: null,
  cancelledItemPutaway: {
    cancelledItemPutawayData: [],
    meta: {},
    loading: {},
    error: {},
    success: {}
  },
  loadingState: {},
  errorState: {},
  successState: {},
  cancelPutwayOrders: null,
  //end

  // cycle count
  // start
  cycleCountLocations: null,
  updateInventoryLocationsResponse: null,
  deleteCycleCountLocationsResponse: null,
  locationItemDetails: null,
  singleBinSkus: { loading: false, error: false, success: false, data: null },
  markLocationsForRecountResponse: null,
  cycleCountCreation: null,
  editCycleCount: null,
  addLocationCycleCount: null,
  locationsCountForPrefixes: null,
  rejectInventoryLocationsResponse: null,
  isSkuDetailUpdated: null,
  bulkUploadSerialiseBarcodeResponse: { loading: false, is_success: false, data: null },
  cycleCount: {
    loading: false,
    error: false,
    success: false,
    data: null,
    meta: null
  },
  safetystockMappingUploadedFile: null,
  cycleCountLocationUploadedFile: null,
  uploadBulkSafetyStock: { loading: false, is_success: false, data: null },
  uploadBulkCycleCount: { loading: false, is_success: false, data: null },
  assembleTasks: { loading: false, is_success: false, data: null, error: false },
  assembleTaskDetail: { loading: false, is_success: false, data: null, error: false },
  adjustInventoryList: { loading: false, is_success: false, data: null, error: false },
  adjustInventorySkuList: { loading: false, is_success: false, data: null, error: false },
  demandForeCastBulkUploadFile: null,
  supplierDetails:null,
  editBatchResponse: {},
  binDisabledGrn:{
    tableData:[],
    return_order_id:''
  },
  uploadMaxShelfLifeResponse: null,
  bulkUploadedMaxShelfLifeFile: null,
  enforcedFailUploadIds:[],
  enforcedFailReason:{},
  isShelfLifeBreached:false,
  gtinSkuDetails: {},
  isGtinChanged:false
}
const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setSafetyStocksUploadedFile: (state, action) => {
      state.safetystockMappingUploadedFile = { ...action.payload }
    },
    setCycleCountUploadedFile: (state, action) => {
      state.cycleCountLocationUploadedFile = { ...action.payload }
    },
    cleanupBulkResponse: (state) => {
      state.uploadBulkSafetyStock = { loading: false, is_success: false, data: null }
    },
    cleanupCycleLocationsBulkResponse: (state) => {
      state.uploadBulkCycleCount = { loading: false, is_success: false, data: null }
    },
    cleanupSafetyStockUploadedFile: (state) => {
      state.safetystockMappingUploadedFile = null
    },
    cleanupCycleCountUploadedFile: (state) => {
      state.cycleCountLocationUploadedFile = null
    },
    clearGetSkusByNameAndCodeResponse: (state) => {
      state.grn.getSkusByNameAndCodeResponse = null
    },
    totalInventoryData: (state, action) => {
      state.totalInventoryData.data = action.payload
    },
    setFilters: (state, action) => {
      const newarr = [...state.filtersData]

      newarr.push(action.payload)
      state.filtersData = newarr
    },
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload
    },
    setTableFiltersState: (state, action) => {
      state.tableFiltersState = action.payload
    },
    setClientSkuIdValidStatus: (state, action) => {
      state.grn.isClientSkuIdValid = action.payload
    },
    addNoOfItems: (state, action) => {
      state.grn.noOfItems = action.payload
    },
    setTypeOfBarcode: (state, action) => {
      state.grn.typeOfBarcode = action.payload
    },
    setStartScanningBarcodes: (state, action) => {
      state.grn.hasScanningStarted = action.payload
    },
    setEndScanningBarcodes: (state, action) => {
      state.grn.hasScanningCompleted = action.payload
    },
    setPoStoNo: (state, action) => {
      state.grn.po_number = action.payload
    },
    setInwardBoxId: (state, action) => {
      state.grn.carton_barcode = action.payload
    },
    setSkuDetails: (state, action) => {
      state.grn.sku_details = action.payload
    },
    addBatch: (state, action) => {
      state.grn.batch_data.push(action.payload)
    },
    resetBatch: state => {
      state.grn.batch_data = []
    },
    setItemIdStatus: (state, action) => {
      state.grn.itemIdStatus = action.payload
    },
    setSellerId: (state, action) => {
      state.sellerId = action.payload
    },
    // setGeneratedBarcode: (state, action) => {
    //   state.grn.generatedBarcode = action.payload
    // },
    setSkuDetailsLoading: (state, action) => {
      state.grn.sku_details_loading = action.payload
    },
    clearGrnResponse: (state) => {
      state.grn.grnResponse = {}
      state.grn.pass_bin_id = ''
      state.grn.fail_bin_id = ''
    },
    setScannedSkuDetails: (state, action) => {
      state.grn.sku_details = { data: { ...action.payload.data, isRoot: true, openedQty: 0, closedQty: 0, openedAssignedQty: 0, closedAssignedQty: 0 } }
      // state.grn.sku_details = dummySkuDetails.data
      // state.grn.grnSkuDetails = dummySkuDetails.data
    },
    clearGrnPassBinId: state => {
      state.grn.pass_bin_id = ''
    },
    clearGrnFailBinId: state => {
      state.grn.fail_bin_id = ''
    },
    setScannedPassBin: (state, action) => {
      state.grn.scanned_pass_bin_id = action.payload
    },
    setScannedFailBin: (state, action) => {
      state.grn.scanned_fail_bin_id = action.payload
    },
    setLastScannedBin: (state, action) => {
      if (action.payload) {
        state.grn.lastScannedBin = action.payload
      }
    },
    setLastScannedSKUComplete: (state, action) => {
      state.grn.lastScannedSkuComplete = action.payload
    },
    clearLastScannedBin: state => {
      state.grn.lastScannedBin = null
    },
    clearLastScannedSKUComplete: state => {
      state.grn.lastScannedSkuComplete = null
    },
    clearAddItemInGrnBinResponse: (state) => {
      state.grn.addSkuInGrnBinResponse = {}
    },
    clearGrnStore: state => {
      const grns = state.grn.grns
      state.grn = initialState.grn
      state.grn = { ...state.grn, grns }
      state.awbDetails = null
    },
    clearBatchResponse: state => {
      state.createBatchResponse = {}
      state.editBatchResponse = {}
    },
    clearQcPrevData: state => {
      state.grn.sku_details = null
      state.grn.generatedBarcode = ''
    },
    clearSingleGateEntry: state => {
      state.singleGateEntry = null
    },
    setHubSkuSerialistionStatusResponse: (state, action) => {
      state.grn.hub_sku_serialisation_status_response = action.payload
    },
    setSerialisationStatus: (state, action) => {
      state.grn.serialisation_status = action.payload
    },
    setBatchStatus: (state, action) => {
      state.grn.batch_status = action.payload
    },
    setSkuNotFound: (state, action) => {
      state.grn.no_sku_found = action.payload
    },
    setSeller: (state, action) => {
      state.seller = action.payload
    },
    setSkuBarcode: (state, action) => {
      state.sku_barcode = action.payload
    },
    setGeneratedBarcode: (state, action) => {
      state.grn.generatedBarcode = action.payload
    },
    setSupplier: (state, action) => {
      state.supplier = action.payload
    },
    setGateEntryCreationSuccess: (state, action) => {
      state.gateEntry.gate_entry_created_success = action.payload
    },
    setBinStatusSuccess: (state, action) => {
      state.grn.bin_status_success = action.payload
    },
    setBinStatus: (state, action) => {
      state.grn.bin_status = action.payload
    },
    clearBinStatus: state => {
      state.grn.bin_status = ''
      state.grn.current_sku_id = null
    },
    resetGrnItems: state => {
      state.grnItems = { overViewData: [], loading: false, error: false, success: false }
    },
    resetGrnPutawayItems: state => {
      state.grnPutaway = { loading: false, putawayData: null, error: false, success: false }
    },
    // handle loading hubwiseInventory sidebar
    setHubWiseInventoryLoading: (state, action) => {
      state.hubWiseInventorySidebarLoading = { ...state.hubWiseInventorySidebarLoading, hubWiseInventorySidebarTableLoading: action.payload }
    },
    // set hubwise inventory sidebar meta data
    setHubWiseInventoryMeta: (state, action) => {
      state.hubWiseInventorySidebarMeta = { ...state.hubWiseInventorySidebarMeta, ...action.payload }
    },
    setPrintingStatus: (state, action) => {
      state.grn.hasItemBarcodesPrinted = action.payload
    },
    setSkuInGrnBinError: (state, action) => {
      state.grn.addSkuInGrnBinError = action.payload
    },
    setCreateBatchSuccess: (state, action) => {
      state.grn.create_batch_succes = action.payload
    },
    setNextConfigStep: (state, action) => {
      state.grn.next_config_step = action.payload
    },
    clearHubSkuSerialisationResponse: state => {
      state.grn.hub_sku_serialisation_status_response = ''
    },
    setHasBarcodeMapped: (state, action) => {
      state.grn.has_barcode_mapped = action.payload
    },
    clearStoDetails: state => {
      state.stoDetail = null
      state.stoLoading = false
      state.stoOrderID = null
      state.stoRequestMetaData = null
    },
    clearBinPutawaySidebarItems: state => {
      state.putaway.putawayItemsForBinPutaway = []
    },
    cleanupImportSkusBulkResponse: (state) => {
      state.importSkusBulkResponse = null
    },
    setSkuBulkUploadedFile: (state, action) => {
      state.skuBulkUploadedFile = { ...action.payload }
    },
    setBatchesBulkUploadedFile: (state, action) => {
      state.batchesBulkUploadedFile = { ...action.payload }
    },
    setBulkUploadedExpiryThresholdFile: (state, action) => {
      state.bulkUploadedExpiryThresholdFile = { ...action.payload }
    },
    cleanupImportSkusMappingResponse: (state) => {
      state.importSkusMappingResponse = null
    },
    setSkuMappingUploadedFile: (state, action) => {
      state.skuMappingUploadedFile = { ...action.payload }
    },
    setGrnImagesUploadedFiles: (state, action) => {
      state.grnImagesUploadedFiles = action.payload
    },
    cleanupSingleSku: (state) => {
      state.sku = {}
    },
    cleanupUnmappedSkusData: (state) => {
      state.unmappedSkusData = {}
    },
    cleanupCanelPutwayOrders: (state) => {
      state.cancelPutwayOrders = null
    },
    cleanupImportBatchesBulkResponse: (state) => {
      state.importBatchesBulkResponse = null
    },
    cleanupImportExpiryThresholdBulkResponse: (state) => {
      state.importExpiryThresholdBulkResponse = null
    },
    selectedSellerDispatch: (state, action) => {
      state.selectedSeller = action.payload
    },
    setExpiryDateSort: (state, action) => {
      state.expiryDateSort = action.payload
    },
    // clearQcTypeData: state => {
    //   state.grn.qcTypeData = null
    // },
    clearScannedSkuDetails: state => {
      state.grn.sku_details = null
      state.grn.generatedBarcode = ''
    },
    clearDataAfterSKUScanned: state => {
      // state.grn.sku_details = null
      state.grn.bin_status = ''
      state.grn.generatedBarcode = ''
      state.grn.addSkuInGrnBinResponse = {}
    },
    clearGrnSkuAndBinStatus: state => {
      state.grn.sku_details = null
      state.grn.serialisation_status = ''
      state.grn.batch_status = 'undefined'
      state.grn.bin_status_success = false
      state.grn.bin_status = ''
    },
    clearAwbDetailsResponse: state => {
      state.awbDetails = null
    },
    cleanupInventoryData: (state, action) => {
      const { key } = action.payload
      state[key] = null
    },
    clearCycleCreationStatus: state => {
      state.cycleCountCreation = null
      state.editCycleCount = null
      state.addLocationCycleCount = null
    },
    clearSkuDetailUpdateStatus: state => {
      state.isSkuDetailUpdated = null
    },
    clearPutawayTableData: state => {
      state.putaway.putawayTableData = []
    },
    clearSkuQtyUpdated: state => {
      state.grn.skuQtyUpdated = { key: false, bin_barcode: null }
    },
    clearBulkUploadSerialiseBarcodeResponse: (state) => {
      state.bulkUploadSerialiseBarcodeResponse = { loading: false, is_success: false, data: {} }
    },
    clearResponseForGateEntry: state => {
      state.gateEntry = {
        loading: false,
        gateEntryData: { data: [] },
        hasGateEntryConfigured: false,
        generatedCartonBarcodes: {},
        createGateEntryResponse: {},
        gateEntries: state.gateEntry.gateEntries
      }
    },
    setAddItemInGrnBinResponse: (state, action) => {
      state.grn.addSkuInGrnBinResponse = action.payload
    },
    clearSkuConfigResponse: (state) => {
      state.skuConfigResponse = {}
    },
    clearAssembleTaskDetail: state => {
      state.assembleTaskDetail = {
        loading: false, is_success: false, data: null, error: false
      }
    },
    updateSingleGrnDetailForNextOrder: (state, action) => {
      state.grn.singleGrnDetail = {
        ...state.grn.singleGrnDetail,
        return_order_id: action.payload?.return_order_id || '',
        seller_sales_channel_order_id: action.payload?.seller_sales_channel_order_id || '',
        oms_order_id: action.payload?.oms_order_id || ''
      }
    },
    clearAssembleBarcodeDetail: (state) => {
      state.assembledKitData = { loading: false, error: false, success: false, data: {} }
    },
    clearAssembleExportData: (state) => {
      state.uploadAssembledSku = { loading: false, is_success: false, data: null }
    },
    setOpenedQty: (state, { payload }) => {
      const { id, qtyOpen, qtyClose, skuDetails: skuDetailsState } = payload
      const skuDetails = JSON.parse(JSON.stringify(skuDetailsState))
      const changeOpenedQty = (id, qtyOpen, qtyClose, skuDetails) => {
        if (skuDetails.seller_sku_id === id) {
          skuDetails.openedQty = qtyOpen
          skuDetails.closedQty = qtyClose
        } else {
          changeOpenedQty(id, qtyOpen, qtyClose, skuDetails.child_data)
        }
      }
      changeOpenedQty(id, qtyOpen, qtyClose, skuDetails)
      state.grn.grnSkuDetails = skuDetails
    },
    setClosedQty: (state, { payload }) => {
      const { id, qty, skuDetails: skuDetailsState } = payload
      const skuDetails = JSON.parse(JSON.stringify(skuDetailsState))
      const changeClosedQty = (id, qty, skuDetails) => {
        if (skuDetails.seller_sku_id === id) {
          skuDetails.closedQty = qty
        } else {
          changeClosedQty(id, qty, skuDetails.child_data)
        }
      }
      changeClosedQty(id, qty, skuDetails)
      state.grn.grnSkuDetails = skuDetails
    },
    setGrnSkuDetails: (state, { payload }) => {
      const tempSkuDetails = JSON.parse(JSON.stringify(payload.prevState))
      const opened = payload.openedQty
      const closed = payload.closedQty
      const qtyLimit = payload.maxQty

      const setChildData = (currentSkuDetails, responseData) => {
        if (currentSkuDetails.seller_sku_id === responseData.currentSkuId) {
          if (!currentSkuDetails.child_data) {
            currentSkuDetails.child_data = { ...responseData, openedQty: 0, closedQty: 0, maxQty: qtyLimit, openedAssignedQty: 0, closedAssignedQty: 0 }
            currentSkuDetails.openedQty = opened
            currentSkuDetails.closedQty = closed
          } else {
            currentSkuDetails.child_data.maxQty = qtyLimit
            currentSkuDetails.openedQty = currentSkuDetails.openedQty + opened
            currentSkuDetails.closedQty = currentSkuDetails.closedQty ? currentSkuDetails.closedQty - opened : closed
          }
        } else {
          setChildData(currentSkuDetails.child_data, responseData)
        }
      }
      setChildData(tempSkuDetails, JSON.parse(JSON.stringify(payload.response)))
      state.grn.sku_details.data = tempSkuDetails
    },
    clearGrnChildSkuData: (state) => {
      state.grn.grnChildSkuData = null
    },
    clearChildAddedSuccess: (state) => {
      state.grn.childAddedSuccess = null
    },
    updateAssignedQtyForCurrentSku: (state, { payload }) => {
      const tempSkuDetails = JSON.parse(JSON.stringify(payload.prevState))
      const skuId = payload.skuId
      const qtyToAssign = payload.qtyToAssign
      // debugger

      const setAssignedQty = (data, currId, qtyToAdd) => {
        if (data.seller_sku_id === currId || !data.child_data) {
          data.closedAssignedQty = (data.closedAssignedQty || 0) + qtyToAdd
        } else {
          setAssignedQty(data.child_data, currId, qtyToAdd)
        }
      }

      const updateAssignedQty = (data) => {
        if (!data.child_data) {
          // do nothing
        } else {
          data.openedAssignedQty = Math.floor((data.child_data.openedAssignedQty + data.child_data.closedAssignedQty) / data.child_sku.quantity)
          updateAssignedQty(data.child_data)
        }
      }

      setAssignedQty(tempSkuDetails, skuId, qtyToAssign)
      updateAssignedQty(tempSkuDetails)
      // debugger

      if (tempSkuDetails.is_configuration_editable) tempSkuDetails.is_configuration_editable = false

      state.grn.sku_details.data = tempSkuDetails
      state.grn.assignmentDone = true
    },
    clearSkuDetailInBin: state => {
      state.grn.skuDetailInBin = null
    },
    clearAssignmentDone: state => {
      state.grn.assignmentDone = false
    },
    setBatchAndSerialisationConfigForSku: (state, { payload }) => {
      const rootSkuDetails = JSON.parse(JSON.stringify(payload.prevState))
      const currentSkuId = payload.skuId
      const batchStatus = payload.batchStatus
      const serialisationStatus = payload.serialisationStatus

      const setConfig = (skuDetails, skuId) => {
        if (skuDetails.seller_sku_id === skuId) {
          skuDetails.batch_status = batchStatus === 'undefined' ? 'undefined' : (batchStatus ? 'batched' : 'not_batched')
          skuDetails.serialisation_status = serialisationStatus
          skuDetails.is_configuration_editable = true
        } else {
          if (skuDetails.child_data) {
            setConfig(skuDetails.child_data, skuId)
          }
        }
      }

      setConfig(rootSkuDetails, currentSkuId)
      state.grn.sku_details.data = rootSkuDetails
    },
    updateWeightForSku: (state, { payload }) => {
      const rootSkuDetails = JSON.parse(JSON.stringify(payload.prevState))
      const currentSkuId = payload.skuId
      const weightDetails = payload.body.weight
      const barcodes = payload.body.barcodes
      const mode = payload.mode

      const setConfig = (skuDetails, skuId) => {
        if (skuDetails.seller_sku_id === skuId) {
          if (mode === 'weight') {
            skuDetails.weight = weightDetails
          } else if (mode === 'barcode') {
            skuDetails.barcodes = [...barcodes]
          }
        } else {
          if (skuDetails.child_data) {
            setConfig(skuDetails.child_data, skuId)
          }
        }
      }

      setConfig(rootSkuDetails, currentSkuId)
      state.grn.sku_details.data = rootSkuDetails
      if (state.gtinSkuDetails.id) {
        state.gtinSkuDetails = {...state.gtinSkuDetails, weight:rootSkuDetails?.weight}
      }
    },
    clearAdjustmentInventorySkuList: state => {
      state.adjustInventorySkuList = { loading: false, is_success: false, data: null, error: false }
    },
    clearAdjustmentInventory: state => {
      state.adjustInventoryList = { loading: false, is_success: false, data: null, error: false }
    },
    setDemandForecastUploadedFile: (state, action) => {
      state.demandForeCastBulkUploadFile = { ...action.payload }
    },
    cleanupDemandForeCastUploadedFile: (state) => {
      state.demandForeCastBulkUploadFile = null
    },
    resetSuccessState: (state) => {
      state.successState = {}
    },
    resetErrorState: (state) => {
      state.errorState = {}
    },
    clearSupplierDetails: (state) => {
      state.supplierDetails = null
    },
    setBinDisabledGrnTableData : (state, {payload}) => {
      state.binDisabledGrn.tableData = payload
    },
    clearBinDisabledGrnTableData: (state) => {
      state.binDisabledGrn.tableData = []
    },
    setBinDisabledReturnOrderId : (state, {payload}) => {
      state.binDisabledGrn.return_order_id = payload
    },
    clearSingleGrnDetails: (state) => {
      state.grn.singleGrnDetail = null
    },
    clearDisplayGrnDetails: (state) => {
      state.grn.displayGrnDetails = null
    },
    cleanupUploadMaxShelfLifeResponse: (state) => {
      state.uploadMaxShelfLifeResponse = null
    },
    setBulkUploadedMaxShelfLifeFile: (state, action) => {
      state.bulkUploadedMaxShelfLifeFile = { ...action.payload }
    },
    updateEnforcedFailUploadIds: (state, action) => {
      state.enforcedFailUploadIds = action.payload
    },
    setEnforcedFailReason: (state, action) => {
      state.enforcedFailReason = action.payload
    },
    updateIsShelfLifeBreached: (state, action) => {
      state.isShelfLifeBreached = action.payload
    },
    updateGtinSkuDetails : (state, action) => {
      state.gtinSkuDetails = action.payload
    },
    updateIsGtinChanged : (state, action) => {
      state.isGtinChanged = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getTotalInventory.pending, state => {
        state.totalInventoryData.loading = true
        state.totalInventoryData.error = false
        state.totalInventoryData.success = false
      })
      .addCase(getTotalInventory.fulfilled, (state, action) => {
        state.totalInventoryData.loading = false
        state.totalInventoryData.error = false
        state.totalInventoryData.success = true
        state.totalInventoryData.data = action.payload
      })
      .addCase(getTotalInventory.rejected, state => {
        state.totalInventoryData.loading = false
        state.totalInventoryData.error = true
        state.totalInventoryData.success = false
      })
      .addCase(assembledKitDetails.pending, state => {
        state.assembledKitData = { loading: true, error: false, success: false, data: {} }
      })
      .addCase(assembledKitDetails.fulfilled, (state, action) => {
        state.assembledKitData = { loading: false, error: false, success: true, data: action.payload }
      })
      .addCase(assembledKitDetails.rejected, state => {
        state.assembledKitData = { loading: false, error: true, success: false, data: {} }
      })
      .addCase(exportAssembledSku.pending, (state) => {
        state.uploadAssembledSku = {
          loading: true,
          is_success: false,
          data: null
        }
      })
      .addCase(exportAssembledSku.fulfilled, (state, action) => {
        state.uploadAssembledSku = {
          loading: false,
          is_success: true,
          data: action.payload
        }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(exportAssembledSku.rejected, (state) => {
        state.uploadAssembledSku = { loading: false, is_success: false }
      })


      .addCase(bulkCycleCountUpload.pending, (state) => {
        state.uploadBulkCycleCount = {
          loading: true,
          is_success: false,
          data: null
        }
      })
      .addCase(bulkCycleCountUpload.fulfilled, (state, action) => {
        state.uploadBulkCycleCount = {
          loading: false,
          is_success: true,
          data: action.payload
        }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(bulkCycleCountUpload.rejected, (state) => {
        state.uploadBulkCycleCount = { loading: false, is_success: false }
      })
      .addCase(bulkSafetyStockUpload.pending, (state) => {
        state.uploadBulkSafetyStock = {
          loading: true,
          is_success: false,
          data: null
        }
      })
      .addCase(bulkSafetyStockUpload.fulfilled, (state, action) => {
        state.uploadBulkSafetyStock = {
          loading: false,
          is_success: true,
          data: action.payload
        }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(bulkSafetyStockUpload.rejected, (state) => {
        state.uploadBulkSafetyStock = { loading: false, is_success: false }
      })
      .addCase(getLocationInventory.pending, state => {
        state.loadingState = { ...state.loadingState, getLocationInventory: true }
        state.successState = { ...state.successState, getLocationInventory: false }
        state.errorState = { ...state.errorState, getLocationInventory: false }
      })
      .addCase(getLocationInventory.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getLocationInventory: false }
        state.successState = { ...state.successState, getLocationInventory: true }
        state.errorState = { ...state.errorState, getLocationInventory: false }
        const obj = action.payload.data
        state.locationInventoryData = obj
      })
      .addCase(getLocationInventory.rejected, state => {
        state.loadingState = { ...state.loadingState, getLocationInventory: false }
        state.successState = { ...state.successState, getLocationInventory: false }
        state.errorState = { ...state.errorState, getLocationInventory: true }
      })
      .addCase(getSkusToLocationData.pending, state => {
        state.skusToLocationData.loading = true
        state.skusToLocationData.error = false
        state.skusToLocationData.success = false
      })
      .addCase(getSkusToLocationData.fulfilled, (state, action) => {
        state.skusToLocationData.data = action.payload
        state.skusToLocationData.loading = false
        state.skusToLocationData.error = false
        state.skusToLocationData.success = true
      })
      .addCase(getSkusToLocationData.rejected, state => {
        state.skusToLocationData.loading = false
        state.skusToLocationData.error = true
        state.skusToLocationData.success = false
      })
      .addCase(getHubConfiguration.fulfilled, (state, action) => {
        // setting hub config globally in inventory store
        action.payload.data?.data.map(config => {
          if (config.configuration_type === 'purchase_order') {
            state.po_enabled = config.configuration_values.enabled
          }
          if (config.configuration_type === 'gate_entry') {
            state.gate_entry_enabled = config.configuration_values.enabled
          }
        })
        const obj = action.payload.data
        state.hubConfiguration = obj
      })
      .addCase(getGrns.pending, state => {
        state.grn.loading = true
        state.grn.error = false
        state.grn.success = false
      })
      .addCase(getGrns.fulfilled, (state, action) => {
        state.grn.grns = action.payload
        state.grn.loading = false
        state.grn.error = false
        state.grn.success = true
      })
      .addCase(getGrns.rejected, state => {
        state.grn.loading = false
        state.grn.error = true
        state.grn.success = false
      })
      .addCase(createGateEntry.pending, state => {
        state.gateEntry.gate_entry_created_success = false
        state.gateEntry.gate_entry_create_loading = true
      })
      .addCase(createGateEntry.fulfilled, (state, action) => {
        state.gateEntry.createGateEntryResponse = action.payload.data
        state.gateEntry.gate_entry_created_success = true
        state.gateEntry.gate_entry_create_loading = false
      })
      .addCase(createGateEntry.rejected, state => {
        state.gateEntry.gate_entry_created_success = false
        state.gateEntry.gate_entry_create_loading = false
      })
      .addCase(generateBarcode.pending, state => {
        state.grn.item_barcode_loading = true
        state.loadingState = { ...state.loadingState, generateBarcode: true }
      })
      .addCase(generateBarcode.fulfilled, (state, action) => {
        CustomToast('Barcode generated successfully', { my_type: 'success', duration: 2000 })
        state.loadingState = { ...state.loadingState, generateBarcode: false }
        state.grn.generatedBarcode = action.payload.data
        state.grn.item_barcode_loading = false
      })
      .addCase(generateBarcode.rejected, state => {
        state.grn.item_barcode_loading = false
        state.loadingState = { ...state.loadingState, generateBarcode: false }
        state.gateEntry.gate_entry_created_success = false
      })
      .addCase(createGateEntryForPo.pending, state => {
        state.gateEntry.gate_entry_create_loading = true
      })
      .addCase(createGateEntryForPo.fulfilled, (state, action) => {
        state.gateEntry.createGateEntryResponse = action.payload.data
        state.gateEntry.gate_entry_created_success = true
        state.gateEntry.gate_entry_create_loading = false
      })
      .addCase(createGateEntryForPo.rejected, (state, action) => {
        state.gateEntry.gate_entry_created_success = false
        state.gateEntry.createGateEntryResponse = { is_success: false, message: action.error.message }
        state.gateEntry.gate_entry_create_loading = false
      })
      .addCase(getSuppliers.fulfilled, (state, action) => {
        state.grn.suppliersList = action.payload
      })
      .addCase(createHubSkuSerialisationStatus.fulfilled, (state, action) => {
        state.grn.hub_sku_serialisation_status_response = action.payload?.is_success
        state.grn.config_type = action.payload?.data?.config_type
      })
      .addCase(addSkuInGrnBin.pending, state => {
        state.loadingState = { ...state.loadingState, addSkuInGrnBin: true }
        state.grn.addSkuInGrnBinError = false
        state.loadingState = { ...state.loadingState, addSkuInGrnBin: true }
      })
      .addCase(addSkuInGrnBin.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, addSkuInGrnBin: false }
        if (!(action.payload.data.is_sku_not_exist || action.payload.data.is_over_receive)) {
          const binStatus = action.payload.binStatus
          const qty = action.payload.grnBinAssignedQty

          if (binStatus === 'fail') {
            CustomToast(`${qty} ${qty > 1 ? 'Items' : 'Item'} failed successfully`, { my_type: 'success', duration: 2000 })
          } else if (binStatus === 'pass') {
            CustomToast(`${qty} ${qty > 1 ? 'Items' : 'Item'} passed successfully`, { my_type: 'success', duration: 2000 })
          } else {
            CustomToast('GRN created successfully', { my_type: 'success', duration: 2000 })
          }
        }
        state.gtinSkuDetails = {}
        state.grn.addSkuInGrnBinResponse = action.payload
        state.grn.addSkuInGrnBinError = false
      })
      .addCase(addSkuInGrnBin.rejected, state => {
        state.loadingState = { ...state.loadingState, addSkuInGrnBin: false }
        state.grn.addSkuInGrnBinError = true

        // CustomToast('Grn failed, try again', { my_type: 'error', duration: 2000 })
      })
      .addCase(getPurchaseOrders.fulfilled, (state, action) => {
        state.grn.purchase_orders = action.payload
      })

      .addCase(createNewGrn.fulfilled, (state, action) => {
        state.grn.grnResponse = action.payload
      })

      .addCase(createNewGrnForGateEntry.pending, state => {
        state.loadingState = { ...state.loadingState, createNewGrnForGateEntry: true }
      })

      .addCase(createNewGrnForGateEntry.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, createNewGrnForGateEntry: false }
        state.sellerId = action.payload.data.seller_id
        state.grn.grnResponse = action.payload.data
      })
      .addCase(createNewGrnForGateEntry.rejected, state => {
        state.loadingState = { ...state.loadingState, createNewGrnForGateEntry: false }
      })

      .addCase(createNewGrnForPo.pending, state => {
        state.loadingState = { ...state.loadingState, createNewGrnForPo: true }
      })

      .addCase(createNewGrnForPo.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, createNewGrnForPo: false }
        state.grn.grnResponse = action.payload.data
      })

      .addCase(createNewGrnForPo.rejected, state => {
        state.loadingState = { ...state.loadingState, createNewGrnForPo: false }
      })

      .addCase(createNewGrnForSto.pending, (state) => {
        state.loadingState = { ...state.loadingState, createNewGrnForSto: true }
      })
      .addCase(createNewGrnForSto.fulfilled, (state, action) => {
        state.grn.grnResponse = action.payload.data
        state.loadingState = { ...state.loadingState, createNewGrnForSto: false }
      })
      .addCase(createNewGrnForSto.rejected, (state) => {
        state.loadingState = { ...state.loadingState, createNewGrnForSto: false }
      })
      .addCase(createNewGrnForSellerAndSupplier.pending, state => {
        state.loadingState = { ...state.loadingState, createNewGrnForSellerAndSupplier: true }
      })
      .addCase(createNewGrnForSellerAndSupplier.rejected, state => {
        state.loadingState = { ...state.loadingState, createNewGrnForSellerAndSupplier: false }
      })
      .addCase(createNewGrnForSellerAndSupplier.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, createNewGrnForSellerAndSupplier: false }
        state.grn.grnResponse = action.payload.data
      })

      .addCase(getSkuDetails.pending, state => {
        state.successState = { ...state.successState, getSkuDetails: false }
        state.loadingState = { ...state.loadingState, getSkuDetails: true }
        state.errorState = { ...state.errorState, getSkuDetails: false }
        state.grn.sku_details_loading = true
        state.grn.no_sku_found = false
      })
      .addCase(getSkuDetails.fulfilled, (state, action) => {
        const initialBatch = action.payload.data.data.batch || {}
        const batchDetails = {
          id:initialBatch.id,
          label:initialBatch.external_batch_id,
          external_batch_id:initialBatch.external_batch_id,
          expiry_date:initialBatch.expiry_date,
          value: {batch_num:initialBatch.external_batch_id, expiry_date:initialBatch.expiry_date}
        } 
        if (action.payload.data.data.is_gtin_enabled && 
          action.payload.data.data.sku_config.is_batching_enable && 
          action.payload.gtinSkuScanned && !action.payload.data.data.sku_config.is_serialisation_enable) { 

          if (!state.gtinSkuDetails.id) {
            state.gtinSkuDetails = {
              ...action.payload.data.data,
              is_gtin_enabled:action.payload.data.data.is_gtin_enabled, 
              batch:batchDetails,
              sourceType:false,
              min_shelf_life: action.payload.data.data?.sku_config?.min_shelf_life,
              isRoot: true, 
              openedQty: 0, 
              closedQty: 0, 
              openedAssignedQty: 0, 
              closedAssignedQty: 0,
              scannedQty:1
            }
            state.isGtinChanged = false
          } else {
            // if (action.payload.data.data.sku_config.is_serialisation_enable) {
            //   if (state.gtinSkuDetails.seller_sku_id === action.payload.data.data.seller_sku_id) {
            //     const isSerialNumberDiff = state.gtinSkuDetails.gtin_serialisation_number !== action.payload.data.data.gtin_serialisation_number
            //     state.gtinSkuDetails = {
            //       ...state.gtinSkuDetails,
            //       gtin_serialisation_number: action.payload.data.data.gtin_serialisation_number,
            //       scannedQty: isSerialNumberDiff ? state.gtinSkuDetails.scannedQty + 1 : state.gtinSkuDetails.scannedQty,
            //       batch:batchDetails
            //     }
            //     if (!isSerialNumberDiff) {
            //       CustomToast('SKU already scanned', { my_type: 'success', duration: 2000 })
            //     }
            //     state.isGtinChanged = false
            //   } else {
            //     state.isGtinChanged = true
            //   }
            // } 
            // else {
            if (state.gtinSkuDetails.seller_sku_id === action.payload.data.data.seller_sku_id && state.gtinSkuDetails.batch.id === action.payload.data.data.batch.id) {
              state.gtinSkuDetails = {
                ...state.gtinSkuDetails,
                scannedQty: state.gtinSkuDetails.scannedQty + 1
              }
              state.isGtinChanged = false
            } else {
              state.isGtinChanged = true
            }
            // }
          }
        } else {
          if (state.gtinSkuDetails.id && state.gtinSkuDetails.seller_sku_id !== action.payload.data.data.seller_sku_id) {
            state.isGtinChanged = true
          }
          state.grn.sku_details = { data: { ...action.payload.data.data,
            is_gtin_enabled:action.payload.data.data.is_gtin_enabled, 
            batch:batchDetails.id ? batchDetails : null,
            sourceType:false,
            min_shelf_life: action.payload.data.data?.sku_config?.min_shelf_life,
            isRoot: true, 
            openedQty: 0, 
            closedQty: 0, 
            openedAssignedQty: 0, 
            closedAssignedQty: 0 } }
        }
          
        state.successState = { ...state.successState, getSkuDetails: true }
        state.loadingState = { ...state.loadingState, getSkuDetails: false }
        // state.grn.sku_details = dummySkuDetails
        // state.grn.grnSkuDetails = dummySkuDetails.data


        state.grn.serialisation_status = action.payload.data.data.serialisation_status
        state.grn.batch_status = action.payload.data.data?.batch_status //TODO: batch key
        state.grn.sku_details_loading = false
        state.grn.no_sku_found = false
        state.errorState = { ...state.errorState, getSkuDetails: false }
      })
      .addCase(getSkuDetails.rejected, state => {
        state.successState = { ...state.successState, getSkuDetails: false }
        state.loadingState = { ...state.loadingState, getSkuDetails: false }
        state.errorState = { ...state.errorState, getSkuDetails: true }
        state.grn.sku_details_loading = false
        state.grn.no_sku_found = true
      })
      .addCase(generatePassBinId.pending, state => {
        state.loadingState = { ...state.loadingState, generatePassBinId: true }
        state.grn.loading_barcode_generation = true
        state.grn.print_bin_error = false
        state.grn.has_barcode_printed = false
      })
      .addCase(generatePassBinId.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, generatePassBinId: false }
        state.grn.pass_bin_id = action.payload.data
        CustomToast('Pass bin generated successfully', { my_type: 'success', duration: 3000 })
        state.grn.loading_barcode_generation = false
        state.grn.print_bin_error = false
        state.grn.has_barcode_printed = true
      })
      .addCase(generatePassBinId.rejected, state => {
        state.loadingState = { ...state.loadingState, generatePassBinId: false }
        state.grn.loading_barcode_generation = false
        state.grn.print_bin_error = true
        // CustomToast(action?.error?.message, { my_type: 'error', duration: 3000 })
        state.grn.has_barcode_printed = false
      })
      .addCase(generateFailBinId.pending, state => {
        state.loadingState = { ...state.loadingState, generateFailBinId: true }
        state.grn.loading_barcode_generation = true
        state.grn.print_bin_error = true
        state.grn.has_barcode_printed = false
      })
      .addCase(generateFailBinId.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, generateFailBinId: false }
        state.grn.fail_bin_id = action.payload.data
        CustomToast('Fail bin generated successfully', { my_type: 'success', duration: 3000 })
        state.grn.loading_barcode_generation = false
        state.grn.print_bin_error = false
        state.grn.has_barcode_printed = true
      })
      .addCase(generateFailBinId.rejected, state => {
        state.loadingState = { ...state.loadingState, generateFailBinId: false }
        state.grn.loading_barcode_generation = false
        // CustomToast(action?.error?.message, { my_type: 'error', duration: 3000 })
        state.grn.has_barcode_printed = false
      })
      .addCase(scanPassBinAtGrn.pending, state => {
        state.loadingState = { ...state.loadingState, scanPassBinAtGrn: true }
        state.grn.scan_bin_error = false
        state.grn.pass_bin_error = false
        state.grn.has_barcode_mapped = false
      })
      .addCase(scanPassBinAtGrn.fulfilled, (state, action) => {
        // if (action.payload.)
        state.grn.pass_bin_id = action.payload.data
        state.loadingState = { ...state.loadingState, scanPassBinAtGrn: false }
        CustomToast('Pass bin added successfully', { my_type: 'success', duration: 3000 })
        state.grn.scan_bin_error = false
        state.grn.pass_bin_error = false
        state.grn.has_barcode_mapped = true
      })
      .addCase(scanPassBinAtGrn.rejected, state => {
        // CustomToast(action?.error?.message, { my_type: 'error', duration: 3000 })
        state.loadingState = { ...state.loadingState, scanPassBinAtGrn: false }
        state.grn.scan_bin_error = true
        state.grn.pass_bin_error = true
        state.grn.has_barcode_mapped = false
      })
      .addCase(scanFailBinAtGrn.pending, state => {
        state.loadingState = { ...state.loadingState, scanFailBinAtGrn: true }
        state.grn.scan_bin_error = false
        state.grn.fail_bin_error = false
        state.grn.has_barcode_mapped = false
      })
      .addCase(scanFailBinAtGrn.fulfilled, (state, action) => {
        // if (action.payload.)
        state.loadingState = { ...state.loadingState, scanFailBinAtGrn: false }
        CustomToast('Fail bin added successfully', { my_type: 'success', duration: 3000 })
        state.grn.scan_bin_error = false
        state.grn.fail_bin_error = false
        state.grn.fail_bin_id = action.payload.data
        state.grn.has_barcode_mapped = true
      })
      .addCase(scanFailBinAtGrn.rejected, state => {
        // debugger
        state.loadingState = { ...state.loadingState, scanFailBinAtGrn: false }
        state.grn.scan_bin_error = true
        state.grn.fail_bin_error = true
        // CustomToast(action?.error?.message, { my_type: 'error', duration: 3000 })
        state.loadingState = { ...state.loadingState, scanFailBinAtGrn: false }
        state.grn.has_barcode_mapped = false
        // debugger
      })

      .addCase(generateCartonBarcodes.fulfilled, (state, action) => {
        state.gateEntry.generatedCartonBarcodes = action.payload
      })
      .addCase(generateCartonBarcodes.rejected, (state, action) => {
        state.gateEntry.generatedCartonBarcodes = { is_success: false, message: action.error.message }
      })

      // PUTAWAY
      .addCase(getPutaways.pending, state => {
        state.putaway.putawayTableData = []
        state.putaway.loading = { ...state.putaway.loading, getPutaways: true }
      })
      .addCase(getPutaways.fulfilled, (state, action) => {
        if (action.payload.status_code === 200) {
          state.putaway.loading = { ...state.putaway.loading, getPutaways: false }
          state.putaway.error = { ...state.putaway.error, getPutaways: false }
          state.putaway.success = { ...state.putaway.success, getPutaways: true }
          state.putaway.putawayTableData = action.payload
        }
      })
      .addCase(getPutaways.rejected, state => {
        state.putaway.success = { ...state.putaway.success, getPutaways: false }
        state.putaway.error = { ...state.putaway.error, getPutaways: true }
        state.putaway.loading = { ...state.putaway.loading, getPutaways: false }
      })
      .addCase(getPutawayItems.pending, state => {
        state.putaway = { loading: true, error: false, success: false }
      })
      .addCase(getPutawayItems.fulfilled, (state, action) => {
        if (action.payload.status_code === 200) {
          state.putaway = { loading: false, error: false, success: true, putawayItemsForBinPutaway: action.payload.data }
        }
      })
      .addCase(getPutawayItems.rejected, state => {
        state.putaway = { loading: false, error: true, success: false }
      })

      .addCase(getGateEntries.fulfilled, (state, action) => {
        state.gateEntry.loading = false
        state.gateEntry.error = false
        state.gateEntry.success = true
        if (action.payload.status_code === 200) state.gateEntry.gateEntries = action.payload
      })
      .addCase(getSingleGateEntries.pending, state => {
        state.singleGateEntryLoading = true
      })
      .addCase(getSingleGateEntries.fulfilled, (state, action) => {
        if (action.payload.status_code === 200) {
          state.singleGateEntryLoading = false
          state.singleGateEntry = action.payload.data
        }
      })
      .addCase(getSingleGateEntries.rejected, state => {
        state.singleGateEntryLoading = false
      })
      .addCase(getGateEntries.pending, state => {
        state.gateEntry.loading = true
        state.gateEntry.error = false
        state.gateEntry.success = false
      })
      .addCase(getGateEntries.rejected, state => {
        // CustomToast("Something went wrong", { my_type: 'success', duration: 2000 })
        state.gateEntry.loading = false
        state.gateEntry.error = true
        state.gateEntry.success = false
      })
      .addCase(getBinStatus.pending, state => {
        state.loadingState = { ...state.loadingState, getBinStatus: true }
        state.grn.bin_status_success = false
        state.grn.bin_status = ''
      })
      .addCase(getBinStatus.fulfilled, (state, action) => {
        state.grn.bin_status_success = true
        state.loadingState = { ...state.loadingState, getBinStatus: false }
        state.grn.bin_status = action.payload?.data?.[0]?.bin_qc_type
        state.grn.current_sku_id = action.payload?.seller_sku_id
        if (action.payload?.data?.length === 0) {
          CustomToast('This bin is not associated with this GRN. Please scan another bin', { my_type: 'error', duration: 5000, audioRequired: true })
        }
      })
      .addCase(getBinStatus.rejected, state => {
        state.loadingState = { ...state.loadingState, getBinStatus: false }
        state.grn.bin_status_success = false
        state.grn.bin_status = ''
      })
      //Sellers and suppliers
      .addCase(getAllSellers.fulfilled, (state, action) => {
        if (action.payload.status_code === 200) {
          state.allSellersList = action.payload.data
        }
      })
      .addCase(getAllSuppliers.fulfilled, (state, action) => {
        if (action.payload.status_code === 200) state.allSuppliersList = action.payload.data
      })
      .addCase(getPurchaseOrderList.fulfilled, (state, action) => {
        if (action.payload.status_code === 200) state.allPurchaseOrdersList = action.payload.data
      })
      // grn items
      .addCase(getGrnItemsViewOverviewDetails.pending, state => {
        state.grnItems = { loading: true, error: false, success: false, overViewData: [] }
      })
      .addCase(getGrnItemsViewOverviewDetails.fulfilled, (state, action) => {
        state.grnItems = { loading: false, error: false, success: true, overViewData: action.payload?.data?.data }
      })
      .addCase(getGrnItemsViewOverviewDetails.rejected, state => {
        state.grnItems = { loading: false, error: true, success: false, overViewData: [] }
      })
      .addCase(getGrnPutawayDetails.pending, state => {
        state.grnPutaway = { loading: true, error: false, success: false, putawayData: null }
      })
      .addCase(getGrnPutawayDetails.fulfilled, (state, action) => {
        state.grnPutaway = { loading: false, error: false, success: true, putawayData: action.payload?.data }
      })
      .addCase(getGrnPutawayDetails.rejected, state => {
        state.grnPutaway = { loading: false, error: true, success: false, putawayData: null }
      })
      // get hub wise inventory sidebar data response
      .addCase(getHubWiseInventorySidebarData.pending, state => {
        state.hubWiseInventorySidebarLoading = { ...state.hubWiseInventorySidebarLoading, hubWiseInventorySidebarLoading: true }
      })
      .addCase(getHubWiseInventorySidebarData.fulfilled, (state, action) => {
        state.hubWiseInventorySidebarLoading = { ...state.hubWiseInventorySidebarLoading, hubWiseInventorySidebarLoading: false }
        state.hubwiseInventorySidebarData = action.payload
        const per_page = 20
        const total = action.payload?.hub_inventories?.length
        const last_page = Math.abs(Math.ceil(total / per_page))
        const current_page = 1
        const start = (current_page - 1) * per_page
        const end = start + per_page
        state.hubWiseInventorySidebarMeta = {
          current_page,
          last_page,
          per_page,
          start,
          end,
          total
        }
      })
      .addCase(getHubWiseInventorySidebarData.rejected, state => {
        state.hubwiseInventorySidebarData = { ...state.hubWiseInventorySidebarLoading, hubWiseInventorySidebarLoading: false }
      })

      .addCase(createBatch.pending, state => {
        state.loadingState = { ...state.loadingState, createBatch: true }
      })
      .addCase(createBatch.fulfilled, (state, action) => {
        state.grn.create_batch_succes = true
        state.createBatchResponse = action.payload
        state.loadingState = { ...state.loadingState, createBatch: false }
        CustomToast('Batch created successfully', { my_type: 'success', duration: 2000 })
      })
      .addCase(createBatch.rejected, state => {
        state.loadingState = { ...state.loadingState, createBatch: false }
      })
      .addCase(getSingleStoList.pending, state => {
        state.stoLoading = true
      })
      .addCase(getSingleStoList.fulfilled, (state, action) => {
        state.stoLoading = false
        state.stoDetail = action.payload?.data?.orders?.[0]
        state.stoRequestMetaData = action.payload.meta
        state.stoOrderID = action.payload?.data?.orders?.[0]?.id
      })
      .addCase(getSingleStoList.rejected, state => {
        state.stoLoading = false
      })
      .addCase(getStoItems.pending, state => {
        state.stoLoading = true
      })
      .addCase(getStoItems.fulfilled, (state, action) => {
        state.stoLoading = false
        state.stoDetail = action.payload?.data?.stock_transfer_order_items
        state.stoRequestMetaData = action.payload.meta
        state.stoOrderID = action.payload?.data?.id
      })
      .addCase(getStoItems.rejected, state => {
        state.stoLoading = false
      })
      .addCase(bulkSkuUploadTotalInventary.fulfilled, (state, action) => {
        state.importSkusBulkResponse = action.payload
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
      })
      .addCase(uploadSkusToLocationMapping.fulfilled, (state, action) => {
        state.importSkusMappingResponse = action.payload
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
      })
      .addCase(getSku.pending, (state) => {
        state.loadingState = { ...state.loadingState, getSku: true }
        state.error = false
      })
      .addCase(getSku.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getSku: false }
        state.sku = action.payload.data
      })
      .addCase(getSku.rejected, (state) => {
        state.loadingState = { ...state.loadingState, getSku: false, getSalesChannelForSingleSeller: false }
        state.error = true
      })
      .addCase(getSkuSalesChannel.fulfilled, (state, action) => {
        state.skuSalesChannel = action.payload.data
      })
      .addCase(getGrnPendingPutaway.pending, state => {
        state.grnPendingPutaway.loading = { ...state.grnPendingPutaway.loading, getGrnPendingPutaway: true }
        state.grnPendingPutaway.success = { ...state.grnPendingPutaway.success, getGrnPendingPutaway: false }
        state.grnPendingPutaway.error = { ...state.grnPendingPutaway.error, getGrnPendingPutaway: false }
      })
      .addCase(getGrnPendingPutaway.fulfilled, (state, action) => {
        state.grnPendingPutaway.grnPendingPutawayData = action.payload.data.data
        state.grnPendingPutaway.meta = action.payload.data.meta
        state.grnPendingPutaway.loading = { ...state.grnPendingPutaway.loading, getGrnPendingPutaway: false }
        state.grnPendingPutaway.success = { ...state.grnPendingPutaway.success, getGrnPendingPutaway: true }
        state.grnPendingPutaway.error = { ...state.grnPendingPutaway.error, getGrnPendingPutaway: false }
      })
      .addCase(getGrnPendingPutaway.rejected, state => {
        state.grnPendingPutaway.loading = { ...state.grnPendingPutaway.loading, getGrnPendingPutaway: false }
        state.grnPendingPutaway.success = { ...state.grnPendingPutaway.success, getGrnPendingPutaway: false }
        state.grnPendingPutaway.error = { ...state.grnPendingPutaway.error, getGrnPendingPutaway: true }
      })
      .addCase(getGrnPendingPutawayTotalSkus.pending, state => {
        state.loading = { ...state.loading, getGrnPendingPutawayTotalSkus: true }
        state.error = false
        state.skuDataOfGrnPendingPutaway = null
      })
      .addCase(getGrnPendingPutawayTotalSkus.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getGrnPendingPutawayTotalSkus: false }
        state.error = false
        state.skuDataOfGrnPendingPutaway = action.payload
      })
      .addCase(getGrnPendingPutawayTotalSkus.rejected, state => {
        state.loading = { ...state.loading, getGrnPendingPutawayTotalSkus: false }
        state.error = true
      })
      .addCase(getCancelledItemPutaway.pending, state => {
        state.cancelledItemPutaway.loading = { ...state.cancelledItemPutaway.loading, getCancelledItemPutaway: true }
        state.cancelledItemPutaway.success = { ...state.cancelledItemPutaway.success, getCancelledItemPutaway: false }
        state.cancelledItemPutaway.error = { ...state.cancelledItemPutaway.error, getCancelledItemPutaway: false }
      })
      .addCase(getCancelledItemPutaway.fulfilled, (state, action) => {
        state.cancelledItemPutaway.cancelledItemPutawayData = action.payload.data.data
        state.cancelledItemPutaway.meta = action.payload.data.meta
        state.cancelledItemPutaway.loading = { ...state.cancelledItemPutaway.loading, getCancelledItemPutaway: false }
        state.cancelledItemPutaway.success = { ...state.cancelledItemPutaway.success, getCancelledItemPutaway: true }
        state.cancelledItemPutaway.error = { ...state.cancelledItemPutaway.error, getCancelledItemPutaway: false }
      })
      .addCase(getCancelledItemPutaway.rejected, state => {
        state.cancelledItemPutaway.loading = { ...state.cancelledItemPutaway.loading, getCancelledItemPutaway: false }
        state.cancelledItemPutaway.success = { ...state.cancelledItemPutaway.success, getCancelledItemPutaway: false }
        state.cancelledItemPutaway.error = { ...state.cancelledItemPutaway.error, getCancelledItemPutaway: true }
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.product = action.payload
      })
      .addCase(getSkus.pending, (state) => {
        state.skus = { loading: true }
      })
      .addCase(getSkus.rejected, (state) => {
        state.skus = { loading: false, error: true }
      })
      .addCase(getSkus.fulfilled, (state, action) => {
        state.skus = action.payload.data
      })
      .addCase(getUnmappedSkusData.pending, state => {
        state.loadingState = { ...state.loadingState, unmappedSkus: true }
        state.successState =  { ...state.successState, unmappedSkus: false }
        state.errorState =  { ...state.errorState, unmappedSkus: false }
      })
      .addCase(getUnmappedSkusData.fulfilled, (state, action) => {
        state.unmappedSkusData = action.payload
        state.loadingState = { ...state.loadingState, unmappedSkus: false }
        state.successState =  { ...state.successState, unmappedSkus: true }
        state.errorState =  { ...state.errorState, unmappedSkus: false }
      })
      .addCase(getUnmappedSkusData.rejected, state => {
        state.unmappedSkusData = {}
        state.loadingState = { ...state.loadingState, unmappedSkus: false }
        state.successState =  { ...state.successState, unmappedSkus: false }
        state.errorState =  { ...state.errorState, unmappedSkus: true }
      })
      .addCase(getCancelPutwayOrders.pending, state => {
        state.loadingState = { ...state.loadingState, getCancelPutwayOrders: true }
        state.errorState = { ...state.errorState, getCancelPutwayOrders: false }
        state.successState = { ...state.successState, getCancelPutwayOrders: false }
      })
      .addCase(getCancelPutwayOrders.fulfilled, (state, action) => {
        state.cancelPutwayOrders = action.payload
        state.loadingState = { ...state.loadingState, getCancelPutwayOrders: false }
        state.errorState = { ...state.errorState, getCancelPutwayOrders: false }
        state.successState = { ...state.successState, getCancelPutwayOrders: true }
      })
      .addCase(getCancelPutwayOrders.rejected, state => {
        state.loadingState = { ...state.loadingState, getCancelPutwayOrders: false }
        state.errorState = { ...state.errorState, getCancelPutwayOrders: true }
        state.successState = { ...state.successState, getCancelPutwayOrders: false }
      })
      .addCase(getBatches.pending, (state) => {
        state.loadingState = { ...state.loadingState, getBatches: true }
        state.errors = { ...state.errors, getBatches: false }
        state.successState = { ...state.successState, getBatches: false }
      })
      .addCase(getBatches.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getBatches: false }
        state.errors = { ...state.errors, getBatches: false }
        state.successState = { ...state.successState, getBatches: true }
        state.batches = action.payload
      })
      .addCase(getBatches.rejected, (state) => {
        state.loadingState = { ...state.loadingState, getBatches: false }
        state.errors = { ...state.errors, getBatches: true }
        state.successState = { ...state.successState, getBatches: false }
      })
      .addCase(importBatchesBulk.pending, (state) => {
        state.loadingState = {...state.loadingState, importBatchesBulk: true}
        state.successState = {...state.successState, importBatchesBulk: false}
        state.errorState = {...state.errorState, importBatchesBulk: false}
      })
      .addCase(importBatchesBulk.fulfilled, (state, action) => {
        state.loadingState = {...state.loadingState, importBatchesBulk: false}
        state.successState = {...state.successState, importBatchesBulk: true}
        state.importBatchesBulkResponse = action.payload
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
      })
      .addCase(importBatchesBulk.rejected, (state) => {
        state.loadingState = {...state.loadingState, importBatchesBulk: false}
        state.errorState = {...state.errorState, importBatchesBulk: true}
      })
      .addCase(importBulkUploadedExpiryThresholdFile.pending, (state) => {
        state.loadingState = {...state.loadingState, importBulkUploadedExpiryThresholdFile: true}
      })
      .addCase(importBulkUploadedExpiryThresholdFile.fulfilled, (state, action) => {
        state.loadingState = {...state.loadingState, importBulkUploadedExpiryThresholdFile: false}
        state.successState = {...state.successState, importBulkUploadedExpiryThresholdFile: true}
        state.errorState = {...state.errorState, importBulkUploadedExpiryThresholdFile: false}
        state.importExpiryThresholdBulkResponse = action.payload
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
      })
      .addCase(importBulkUploadedExpiryThresholdFile.rejected, (state) => {
        state.loadingState = {...state.loadingState, importBulkUploadedExpiryThresholdFile: false}
        state.errorState = {...state.errorState, importBulkUploadedExpiryThresholdFile: true}
        state.successState = {...state.successState, importBulkUploadedExpiryThresholdFile: false}
      })
      .addCase(getReturnGrnSkuDetails.pending, state => {
        state.grn.sku_details_loading = true
        state.grn.no_sku_found = false
        state.successState = { ...state.successState, getReturnGrnSkuDetails: false }
      })
      .addCase(getReturnGrnSkuDetails.fulfilled, (state, action) => {
        const initialBatch = action.payload.data.data.batch || {}
        const batchDetails = {
          id:initialBatch.id,
          label:initialBatch.external_batch_id,
          external_batch_id:initialBatch.external_batch_id,
          expiry_date:initialBatch.expiry_date,
          value: {batch_num:initialBatch.external_batch_id, expiry_date:initialBatch.expiry_date}
        } 
        if (action.payload.data.data.is_gtin_enabled && 
          action.payload.data.data.sku_config.is_batching_enable && 
          action.payload.gtinSkuScanned && !action.payload.data.data.sku_config.is_serialisation_enable) { 

          if (!state.gtinSkuDetails.id) {
            state.gtinSkuDetails = {
              ...action.payload.data.data,
              is_gtin_enabled:action.payload.data.data.is_gtin_enabled, 
              batch:batchDetails,
              sourceType:false,
              min_shelf_life: action.payload.data.data?.sku_config?.min_shelf_life,
              isRoot: true, 
              openedQty: 0, 
              closedQty: 0, 
              openedAssignedQty: 0, 
              closedAssignedQty: 0,
              scannedQty:1
            }
            state.isGtinChanged = false
          } else {
            if (state.gtinSkuDetails.seller_sku_id === action.payload.data.data.seller_sku_id && state.gtinSkuDetails.batch.id === action.payload.data.data.batch.id) {
              state.gtinSkuDetails = {
                ...state.gtinSkuDetails,
                scannedQty: state.gtinSkuDetails.scannedQty + 1
              }
              state.isGtinChanged = false
            } else {
              state.isGtinChanged = true
            }
          }
        } else {
          if (state.gtinSkuDetails.id && state.gtinSkuDetails.seller_sku_id !== action.payload.data.data.seller_sku_id) {
            state.isGtinChanged = true
          }
          state.grn.sku_details = { data: { ...action.payload.data.data,
            is_gtin_enabled:action.payload.data.data.is_gtin_enabled, 
            batch:batchDetails,
            sourceType:false,
            min_shelf_life: action.payload.data.data?.sku_config?.min_shelf_life,
            isRoot: true, 
            openedQty: 0, 
            closedQty: 0, 
            openedAssignedQty: 0, 
            closedAssignedQty: 0 } }
        }

        state.successState = { ...state.successState, getReturnGrnSkuDetails: true }
        state.grn.serialisation_status = action.payload.data.serialisation_status
        state.grn.batch_status = action.payload.data?.batch_status //TODO: batch key
        state.grn.sku_details_loading = false
        state.grn.no_sku_found = false
        state.sellerId = action.payload.data?.seller_id
      })
      .addCase(getReturnGrnSkuDetails.rejected, state => {
        state.successState = { ...state.successState, getReturnGrnSkuDetails: false }
        state.grn.sku_details_loading = false
        state.grn.no_sku_found = true
      })
      // .addCase(setQcTypeData.pending, state => {
      //   state.loadingState = { ...state.loadingState, setQcTypeData: true }
      // })
      // .addCase(setQcTypeData.fulfilled, (state, action) => {
      //   state.loadingState = { ...state.loadingState, setQcTypeData: false }
      //   state.grn.qcTypeData = action.payload
      // })
      // .addCase(setQcTypeData.rejected, state => {
      //   state.loadingState = { ...state.loadingState, setQcTypeData: false }
      //   state.errorState = { ...state.errorState, setQcTypeData: true }
      // })
      .addCase(getAwbDetailsData.pending, (state) => {
        state.loadingState = { ...state.loadingState, getAwbDetailsData: true }
      })
      .addCase(getAwbDetailsData.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getAwbDetailsData: false }
        state.awbDetails = action.payload
      })
      .addCase(getAwbDetailsData.rejected, (state) => {
        state.loadingState = { ...state.loadingState, getAwbDetailsData: false }
        state.errorState = { ...state.errorState, getAwbDetailsData: true }
      })
      .addCase(getCycleCountLocations.pending, (state) => {
        state.loadingState = { ...state.loadingState, getCycleCountLocations: true }
      })
      .addCase(getCycleCountLocations.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getCycleCountLocations: false }
        state.successState = { ...state.successState, getCycleCountLocations: true }
        state.errorState = { ...state.errorState, getCycleCountLocations: false }
        state.cycleCountLocations = action.payload
      })
      .addCase(getCycleCountLocations.rejected, (state) => {
        state.loadingState = { ...state.loadingState, getCycleCountLocations: false }
        state.successState = { ...state.successState, getCycleCountLocations: false }
        state.errorState = { ...state.errorState, getCycleCountLocations: true }
      })
      .addCase(updateInventoryLocations.pending, (state) => {
        state.loadingState = { ...state.loadingState, updateInventoryLocations: true }
      })
      .addCase(updateInventoryLocations.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, updateInventoryLocations: false }
        state.updateInventoryLocationsResponse = action.payload
        if (action.payload.status_code !== 301) {
          CustomToast('Inventory updated successfully', { my_type: 'success', duration: 2000 })
        }
      })
      .addCase(updateInventoryLocations.rejected, (state) => {
        state.loadingState = { ...state.loadingState, updateInventoryLocations: false }
        state.errorState = { ...state.errorState, updateInventoryLocations: true }
        // CustomToast('Inventory Update Failed', { my_type: 'error', duration: 2000})
      })
      .addCase(deleteCycleCountLocations.pending, (state) => {
        state.loadingState = { ...state.loadingState, deleteCycleCountLocations: true }
      })
      .addCase(deleteCycleCountLocations.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, deleteCycleCountLocations: false }
        state.deleteCycleCountLocationsResponse = action.payload
        if (action.payload.status_code !== 301) {
          CustomToast('Location deleted successfully', { my_type: 'success', duration: 2000 })
        }
      })
      .addCase(deleteCycleCountLocations.rejected, (state) => {
        state.loadingState = { ...state.loadingState, deleteCycleCountLocations: false }
        state.errorState = { ...state.errorState, deleteCycleCountLocations: true }
        // CustomToast('Deletion Failed', { my_type: 'error', duration: 2000})
      })
      .addCase(getLocationItemDetails.pending, (state) => {
        state.loadingState = { ...state.loadingState, getLocationItemDetails: true }
      })
      .addCase(getLocationItemDetails.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getLocationItemDetails: false }
        state.successState = { ...state.successState, getLocationItemDetails: false }
        state.locationItemDetails = action.payload
      })
      .addCase(getLocationItemDetails.rejected, (state) => {
        state.loadingState = { ...state.loadingState, getLocationItemDetails: false }
        state.errorState = { ...state.errorState, getLocationItemDetails: true }
      })
      .addCase(getSingleBinSkus.pending, (state) => {
        state.singleBinSkus = { loading: true, error: false, success: false, data: null }
      })
      .addCase(getSingleBinSkus.fulfilled, (state, action) => {
        state.singleBinSkus = { loading: false, error: false, success: true, data: action.payload }
      })
      .addCase(getSingleBinSkus.rejected, (state) => {
        state.singleBinSkus = { loading: false, error: true, success: false, data: null }
      })
      .addCase(markLocationsForRecount.pending, (state) => {
        state.loadingState = { ...state.loadingState, markLocationsForRecount: true }
      })
      .addCase(markLocationsForRecount.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, markLocationsForRecount: false }
        state.markLocationsForRecountResponse = action.payload
        if (action.payload.status_code !== 301) {
          CustomToast('Locations marked for recount', { my_type: 'success', duration: 2000 })
        }
      })
      .addCase(markLocationsForRecount.rejected, (state) => {
        state.loadingState = { ...state.loadingState, markLocationsForRecount: false }
        state.errorState = { ...state.errorState, markLocationsForRecount: true }
        // CustomToast('Recount Creation Failed', { my_type: 'error', duration: 2000})
      })
      .addCase(changeSkuWeight.pending, (state) => {
        state.loadingState = { ...state.loadingState, changeSkuWeight: true }
        state.successState = { ...state.successState, changeSkuWeight: false }
      })
      .addCase(changeSkuWeight.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, changeSkuWeight: false }
        state.isSkuDetailUpdated = { skuId: action.payload.skuId, body: action.payload.body, mode: action.payload.mode, status: true }
        CustomToast(action.payload.data.message, { my_type: 'success', duration: 2000 })
        state.successState = { ...state.successState, changeSkuWeight: true }
      })
      .addCase(changeSkuWeight.rejected, (state) => {
        state.loadingState = { ...state.loadingState, changeSkuWeight: false }
        state.errorState = { ...state.errorState, changeSkuWeight: true }
        state.successState = { ...state.successState, changeSkuWeight: false }
      })

      .addCase(getCycleCountList.pending, (state) => {
        state.cycleCount = { ...state.cycleCount, loading: true }
      })
      .addCase(getCycleCountList.fulfilled, (state, action) => {
        state.cycleCount = { ...action.payload, loading: false, success: true, error: false }
      })
      .addCase(getCycleCountList.rejected, (state) => {
        state.cycleCount = { ...state.cycleCount, loading: false, success: false, error: true }
      })

      .addCase(createCycleCount.pending, state => {
        state.loadingState = { ...state.loadingState, createCycleCount: true }
      })
      .addCase(createCycleCount.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, createCycleCount: false }
        state.cycleCountCreation = { ...action.payload }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(createCycleCount.rejected, state => {
        state.loadingState = { ...state.loadingState, createCycleCount: false }
        state.error = { ...state.error, createCycleCount: true }
      })

      .addCase(editCycleCount.pending, state => {
        state.loadingState = { ...state.loadingState, editCycleCount: true }
      })
      .addCase(editCycleCount.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, editCycleCount: false }
        state.editCycleCount = { ...action.payload }
      })
      .addCase(editCycleCount.rejected, state => {
        state.loadingState = { ...state.loadingState, editCycleCount: false }
        state.error = { ...state.error, editCycleCount: true }
      })

      .addCase(addLocationCycleCount.pending, state => {
        state.loadingState = { ...state.loadingState, addLocationCycleCount: true }
      })
      .addCase(addLocationCycleCount.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, addLocationCycleCount: false }
        state.addLocationCycleCount = { ...action.payload }
      })
      .addCase(addLocationCycleCount.rejected, state => {
        state.loadingState = { ...state.loadingState, addLocationCycleCount: false }
        state.error = { ...state.error, addLocationCycleCount: true }
      })
      .addCase(getLocationsCountForPrefixes.pending, state => {
        state.loadingState = { ...state.loadingState, getLocationsCountForPrefixes: true }
      })
      .addCase(getLocationsCountForPrefixes.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getLocationsCountForPrefixes: false }
        state.locationsCountForPrefixes = action.payload
      })
      .addCase(getLocationsCountForPrefixes.rejected, state => {
        state.loadingState = { ...state.loadingState, getLocationsCountForPrefixes: false }
        state.error = { ...state.error, getLocationsCountForPrefixes: true }
      })
      .addCase(rejectInventoryLocations.pending, state => {
        state.loadingState = { ...state.loadingState, rejectInventoryLocations: true }
      })
      .addCase(rejectInventoryLocations.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, rejectInventoryLocations: false }
        state.rejectInventoryLocationsResponse = action.payload
        if (action.payload.status_code !== 301) {
          CustomToast('Locations marked as rejected', { my_type: 'success', duration: 2000 })
        }
      })
      .addCase(rejectInventoryLocations.rejected, state => {
        state.loadingState = { ...state.loadingState, rejectInventoryLocations: false }
        state.error = { ...state.error, rejectInventoryLocations: true }
      })
      .addCase(getSingleGrnDetail.pending, state => {
        state.loadingState = { ...state.loadingState, getSingleGrnDetail: true }
      })
      .addCase(getSingleGrnDetail.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getSingleGrnDetail: false }
        state.grn.singleGrnDetail = action.payload.data

      })
      .addCase(getSingleGrnDetail.rejected, state => {
        state.loadingState = { ...state.loadingState, getSingleGrnDetail: false }
        state.error = { ...state.error, getSingleGrnDetail: true }
      })
      .addCase(getSkuDetailInGrnBin.pending, state => {
        state.loadingState = { ...state.loadingState, getSkuDetailInGrnBin: true }
      })
      .addCase(getSkuDetailInGrnBin.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getSkuDetailInGrnBin: false }
        state.grn.skuDetailInBin = action.payload.data
      })
      .addCase(getSkuDetailInGrnBin.rejected, state => {
        state.loadingState = { ...state.loadingState, getSkuDetailInGrnBin: false }
        state.error = { ...state.error, getSkuDetailInGrnBin: true }
      })
      .addCase(updateSkuQtyInGrnBin.pending, state => {
        state.loadingState = { ...state.loadingState, updateSkuQtyInGrnBin: true }
      })
      .addCase(updateSkuQtyInGrnBin.fulfilled, (state, action) => {
        state.grn.skuDetailInBin = null
        state.loadingState = { ...state.loadingState, updateSkuQtyInGrnBin: false }
        CustomToast(action.payload.data.message, { my_type: 'success', duration: 2000 })
        state.grn.skuQtyUpdated = { key: true, bin_barcode: action.meta.arg.body.bin_barcode }
      })
      .addCase(updateSkuQtyInGrnBin.rejected, state => {
        state.loadingState = { ...state.loadingState, updateSkuQtyInGrnBin: false }
        state.error = { ...state.error, updateSkuQtyInGrnBin: true }
      })
      .addCase(setSkuConfig.pending, state => {
        state.loadingState = { ...state.loadingState, setSkuConfig: true }
      })
      .addCase(setSkuConfig.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, setSkuConfig: false }
        state.skuConfigResponse = action.payload
      })
      .addCase(setSkuConfig.rejected, state => {
        state.loadingState = { ...state.loadingState, setSkuConfig: false }
      })
      .addCase(getDisplayGrnDetail.pending, state => {
        state.loadingState = { ...state.loadingState, getDisplayGrnDetail: true }
      })
      .addCase(getDisplayGrnDetail.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getDisplayGrnDetail: false }
        state.grn.displayGrnDetails = action.payload.data
      })
      .addCase(getDisplayGrnDetail.rejected, state => {
        state.loadingState = { ...state.loadingState, getDisplayGrnDetail: false }
      })
      .addCase(completeGRN.pending, state => {
        state.loadingState = { ...state.loadingState, completeGRN: true }
      })
      .addCase(completeGRN.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, completeGRN: false }
        state.grn.completeGRNResponse = action.payload
      })
      .addCase(completeGRN.rejected, state => {
        state.loadingState = { ...state.loadingState, completeGRN: false }
      })
      .addCase(getSkusByNameAndCode.pending, state => {
        state.loadingState = { ...state.loadingState, getSkusByNameAndCode: true }
      })
      .addCase(getSkusByNameAndCode.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getSkusByNameAndCode: false }
        state.grn.getSkusByNameAndCodeResponse = action.payload
      })
      .addCase(getSkusByNameAndCode.rejected, state => {
        state.loadingState = { ...state.loadingState, getSkusByNameAndCode: false }
      })
      .addCase(bulkUploadSerializeBarcode.pending, (state) => {
        state.bulkUploadSerialiseBarcodeResponse = { loading: true, data: null }
      })
      .addCase(bulkUploadSerializeBarcode.fulfilled, (state, action) => {
        state.bulkUploadSerialiseBarcodeResponse = {
          loading: false,
          is_success: true,
          data: action.payload
        }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(bulkUploadSerializeBarcode.rejected, (state) => {
        state.bulkUploadSerialiseBarcodeResponse = { loading: false }
      })
      .addCase(getAssembleTasks.rejected, state => {
        state.assembleTasks = { ...state.assembleTasks, loading: false, error: true, is_success: false }
      })
      .addCase(getAssembleTasks.pending, state => {
        state.assembleTasks = { ...state.assembleTasks, loading: true }
      })
      .addCase(getAssembleTasks.fulfilled, (state, action) => {
        state.assembleTasks = { ...state.assembleTasks, loading: false, error: false, is_success: true, data: action.payload }
      })
      .addCase(getAssembleTaskDetail.rejected, state => {
        state.assembleTaskDetail = { ...state.assembleTaskDetail, loading: false, is_success: false }
      })
      .addCase(getAssembleTaskDetail.pending, state => {
        state.assembleTaskDetail = { ...state.assembleTaskDetail, loading: true }
      })
      .addCase(getAssembleTaskDetail.fulfilled, (state, action) => {
        state.assembleTaskDetail = { ...state.assembleTaskDetail, loading: false, is_success: true, data: action.payload }
      })
      .addCase(handleBreakOpen.pending, (state) => {
        state.loadingState = { ...state.loadingState, handleBreakOpen: true }
      })
      .addCase(handleBreakOpen.fulfilled, (state, { payload }) => {
        state.loadingState = { ...state.loadingState, handleBreakOpen: false }
        const formattedData = {
          ...payload,
          ...payload.sku_detail,
          ...payload.sku_config
        }
        delete formattedData.sku_detail
        delete formattedData.sku_config
        state.grn.grnChildSkuData = formattedData
      })
      .addCase(handleBreakOpen.rejected, (state) => {
        state.loadingState = { ...state.loadingState, handleBreakOpen: false }
        state.errorState = { ...state.errorState, handleBreakOpen: true }
      })
      .addCase(updateInventoryQty.pending, (state) => {
        state.loadingState = { ...state.loadingState, updateInventoryQty: true }
      })
      .addCase(updateInventoryQty.fulfilled, (state) => {
        state.loadingState = { ...state.loadingState, updateInventoryQty: false }
        state.successState = { ...state.successState, updateInventoryQty: true }
        CustomToast('Quantity updated successfully', { my_type: 'success', duration: 2000 })
      })
      .addCase(updateInventoryQty.rejected, (state) => {
        state.loadingState = { ...state.loadingState, updateInventoryQty: false }
        state.errorState = { ...state.errorState, updateInventoryQty: true }
      })
      .addCase(getAdjustInventoryList.rejected, state => {
        state.adjustInventoryList = { ...state.adjustInventoryList, loading: false, is_success: false }
      })
      .addCase(getAdjustInventoryList.pending, state => {
        state.adjustInventoryList = { ...state.adjustInventoryList, loading: true }
      })
      .addCase(getAdjustInventoryList.fulfilled, (state, action) => {
        state.adjustInventoryList = { ...state.adjustInventoryList, loading: false, is_success: true, data: action.payload?.data, meta: action.payload?.meta }
      })
      .addCase(getAdjustInventorySkuList.rejected, state => {
        state.adjustInventorySkuList = { ...state.adjustInventorySkuList, loading: false, is_success: false }
      })
      .addCase(getAdjustInventorySkuList.pending, state => {
        state.adjustInventorySkuList = { ...state.adjustInventorySkuList, loading: true }
      })
      .addCase(getAdjustInventorySkuList.fulfilled, (state, action) => {
        state.adjustInventorySkuList = { ...state.adjustInventorySkuList, loading: false, is_success: true, data: action.payload?.data, meta: action.payload?.meta }
      })
    
      .addCase(getSupplierDetail.pending, (state) => {
        state.loadingState = { ...state.loadingState, getSupplierDetail: true }
        state.errorState = { ...state.errorState, getSupplierDetail: false }
        state.successState = { ...state.successState, getSupplierDetail: false }
      })
      .addCase(getSupplierDetail.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getSupplierDetail: false }
        state.successState = { ...state.successState, getSupplierDetail: true }
        state.supplierDetails = action.payload.data
      })
      .addCase(getSupplierDetail.rejected, (state) => {
        state.loadingState = { ...state.loadingState, getSupplierDetail: false }
        state.errorState = { ...state.errorState, getSupplierDetail: true }
      })
      .addCase(editBatch.pending, (state) => {
        state.loadingState = {...state.loadingState, editBatch: true }
        state.successState = {...state.successState, editBatch: false }
        state.errorState = {...state.errorState, editBatch: false }
      })
      .addCase(editBatch.fulfilled, (state, action) => {
        state.loadingState = {...state.loadingState, editBatch: false }
        state.successState = {...state.successState, editBatch: true }
        state.editBatchResponse = action.payload
      })
      .addCase(editBatch.rejected, (state) => {
        state.loadingState = {...state.loadingState, editBatch: false }
        state.errorState = {...state.errorState, editBatch: true }
      })
      .addCase(uploadMaxShelfLife.pending, (state) => {
        state.loadingState = {...state.loadingState, uploadMaxShelfLife: true}
        state.successState = {...state.successState, uploadMaxShelfLife: false}
        state.errorState = {...state.errorState, uploadMaxShelfLife: false}
      })
      .addCase(uploadMaxShelfLife.fulfilled, (state, action) => {
        state.loadingState = {...state.loadingState, uploadMaxShelfLife: false}
        state.successState = {...state.successState, uploadMaxShelfLife: true}
        state.errorState = {...state.errorState, uploadMaxShelfLife: false}
        state.uploadMaxShelfLifeResponse = action.payload
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
      })
      .addCase(uploadMaxShelfLife.rejected, (state) => {
        state.loadingState = {...state.loadingState, uploadMaxShelfLife: false}
        state.errorState = {...state.errorState, uploadMaxShelfLife: true}
        state.successState = {...state.successState, uploadMaxShelfLife: false}
      })
  }
})

export const inventoryActions = inventorySlice.actions

export const {
  setSafetyStocksUploadedFile,
  setCycleCountUploadedFile,
  clearSingleGateEntry,
  setHubWiseInventoryLoading,
  setHubWiseInventoryMeta,
  cleanupImportSkusBulkResponse,
  setSkuBulkUploadedFile,
  cleanupImportSkusMappingResponse,
  setSkuMappingUploadedFile,
  cleanupSingleSku,
  cleanupUnmappedSkusData,
  cleanupCanelPutwayOrders,
  setBatchesBulkUploadedFile,
  setBulkUploadedExpiryThresholdFile,
  cleanupImportBatchesBulkResponse,
  cleanupImportExpiryThresholdBulkResponse,
  selectedSellerDispatch,
  setExpiryDateSort,
  clearGrnSkuAndBinStatus,
  clearAwbDetailsResponse,
  cleanupInventoryData,
  clearCycleCreationStatus,
  clearSkuDetailUpdateStatus,
  clearPutawayTableData,
  setSellerId,
  clearGrnResponse,
  clearSkuQtyUpdated,
  clearGrnStore,
  setHasBarcodeMapped,
  clearGrnPassBinId,
  clearGrnFailBinId,
  clearBatchResponse,
  clearScannedSkuDetails,
  clearDataAfterSKUScanned,
  clearAddItemInGrnBinResponse,
  clearBinStatus,
  clearBulkUploadSerialiseBarcodeResponse,
  setLastScannedBin,
  clearLastScannedBin,
  setScannedSkuDetails,
  clearQcPrevData,
  clearGetSkusByNameAndCodeResponse,
  clearSkuConfigResponse,
  clearStoDetails,
  setLastScannedSKUComplete,
  clearLastScannedSKUComplete,
  cleanupBulkResponse,
  cleanupCycleLocationsBulkResponse,
  cleanupSafetyStockUploadedFile,
  cleanupCycleCountUploadedFile,
  clearAssembleTaskDetail,
  setGeneratedBarcode,
  updateSingleGrnDetailForNextOrder,
  clearAssembleBarcodeDetail,
  clearAssembleExportData,
  setGrnSkuDetails,
  clearGrnChildSkuData,
  setOpenedQty,
  setClosedQty,
  clearChildAddedSuccess,
  updateAssignedQtyForCurrentSku,
  updateSkuDetailsTree,
  clearSkuDetailInBin,
  clearAssignmentDone,
  setBatchAndSerialisationConfigForSku,
  updateWeightForSku,
  clearAdjustmentInventorySkuList,
  clearAdjustmentInventory,
  cleanupDemandForeCastUploadedFile,
  setDemandForecastUploadedFile,
  resetSuccessState,
  resetErrorState,
  clearSupplierDetails,
  setBinDisabledGrnTableData,
  clearBinDisabledGrnTableData,
  setBinDisabledReturnOrderId,
  clearSingleGrnDetails,
  clearDisplayGrnDetails,
  cleanupUploadMaxShelfLifeResponse,
  setBulkUploadedMaxShelfLifeFile,
  updateEnforcedFailUploadIds,
  setEnforcedFailReason,
  updateIsShelfLifeBreached,
  updateGtinSkuDetails,
  updateIsGtinChanged
} = inventorySlice.actions

export default inventorySlice.reducer