import { SELECT_ACTIONS } from '@src/utility/Utils';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Plus } from 'react-feather';
import { ControlProps, GroupBase, InputActionMeta } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { iOptionType, iSelectProps } from '../select/select.types';
import './styles.scss';

const MultiPurposeSelect: React.FC<iSelectProps> = (props) => {
  const {loadOptions, customComponents, modifiedClassNames, isDisabled, styles, placeholder = 'Search for SKU', ...rest} = props;
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const ref = useRef(null);

  const CustomControl: React.FC<ControlProps<iOptionType, false, GroupBase<iOptionType>>> = ({innerRef, innerProps, children}) => {
    return <div id='disabled-id' className={classNames('flex-center-start control-wrapper', {
      'opacity-50': isDisabled
    })} ref={innerRef} {...innerProps}>
      <div className='p-8px rounded-4px plus-icon-wrapper flex-center-center'>
        <Plus className='text-primary' size={24} strokeWidth={1.5}/>
      </div>
      {children}
    </div>;
  };

  const handleInputChange = async (inputValue: string, action:InputActionMeta) => {
    // API call and options will only trigger when input length is greater than or equal to 3 
    if (inputValue?.length) {
      setIsMenuOpen(true);
    } else {
      await setIsMenuOpen(false);
      //when selecting a value or changing input value focusing the input field 
      if (action.action === SELECT_ACTIONS.SET_VALUE || action.action === SELECT_ACTIONS.INPUT_CHANGE) {
        //@ts-expect-error fix
        ref.current?.inputRef?.focus();
      }
    }
    
    // This function is used to focus the input when the user clicks on it.
    // When the menu-close action is triggered while isMenuOpen is still true, 
    // the dropdown won't reopen on input focus. To handle this, we manually set 
    // isMenuOpen to false and then focus the input to ensure it opens correctly.
    if (action.action === SELECT_ACTIONS.MENU_CLOSE && isMenuOpen) {
      await setIsMenuOpen(false);
      //@ts-expect-error fix
      ref.current?.inputRef?.focus();
    }
  };
    
  return (
    <div className='search-sku-component-wrapper w-100'>
      <AsyncPaginate
        selectRef={ref}
        menuIsOpen={isMenuOpen}
        additional={{ page: 1 }}
        onMenuOpen={() => {
          //@ts-expect-error fix
          ref.current?.inputRef?.focus();}
        }
        onMenuClose={() => setIsMenuOpen(false)}
        debounceTimeout={500}
        loadOptions={loadOptions}
        components={{
          Control:CustomControl,
          ...customComponents
        }}
        isDisabled={isDisabled}
        closeMenuOnSelect={false}
        value={[]}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        classNames={{
          dropdownIndicator: () => 'd-none',
          placeholder: () => 'text-primary txt-sub-md',
          indicatorSeparator: () => 'd-none',
          loadingIndicator: () => 'd-none',
          input: () => 'text-dark',
          valueContainer: () => 'txt-sub-md',
          menu: () => 'cursor-pointer',
          option: () => 'cursor-pointer txt-body-rg',
          container: () => 'cursor-pointer width-200',
          ...modifiedClassNames
        }}
        styles={{
          ...styles
        }}
        {...rest}
      />
    </div>
  );
};

export default MultiPurposeSelect;