/* eslint-disable @typescript-eslint/no-explicit-any */
import BulkUpload from '@src/@core/components/ui/bulk-upload';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import Select from '@src/@core/components/ui/select';
import DropdownOptionsWithCTA from '@src/@core/components/ui/select/sub-components/DropdownOptionsWithCTA';
import { getApiUrl } from '@src/api.urls';
import { BulkUploadSvg } from '@src/assets/data/assets';
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
/* @ts-expect-error */
import fileLogo from '@src/assets/images/orders/fileLogo.svg';
import { axiosInstance } from '@src/network/AxiosInstance';
import { uploadFileOnS3 } from '@src/redux/authentication';
import { COMPLETED_PROGRESS_VALUE } from '@src/views/hubs/constants.hubs';
import { getSellersAsyncData } from '@src/views/settings/store';
import AddPickupLocationSidesheet from '@src/views/shipping-aggregator/components/add-pickup-location-sidesheet';
import { iBulkOrderCreationProps } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { addPickupLocation, fetchPickupAddress } from '@src/views/shipping-aggregator/shipping-aggregator.apis';
import { CREATE_ORDER_FIELDS_NAME, ORDER_CREATION_TYPE, PICKUP_LOCATION_FIELDS_NAME } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { handleFormReset } from '@src/views/shipping-aggregator/shipping-aggregator.utility';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MenuProps } from 'react-select';

const BulkOrderCreation: React.FC<iBulkOrderCreationProps> = (props) => {
  const { formState, setUploadId, isFileExists, setIsFileExists } = props;
  const { t } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [addPickupLocationSidesheet, setAddPickupLocationSidesheet] = useState(false);
  const [editPickupLocationSidesheet, setEditPickupLocationSidesheet] = useState<{ isEdit: boolean, pickupLocationId: string | number | null }>({ isEdit: false, pickupLocationId: null });

  const isLMDSellerEnabled = useSelector((store: any) => store.auth.userData?.restricted_third_party_logistics);
  // const [addCustomerSidesheet, setAddCustomerSidesheet] = useState(false);
  // const [editCustomerSidesheet, setEditCustomerSidesheet] = useState<{ isEdit: boolean, customerId: string | number | null }>({ isEdit: false, customerId: null });
  const sampleFile = 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/omniship/orders/sample-sheets/bulk-create/sample_bulk_create.csv';
  const DEFAULT_SELLER = formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value);

  const { mutate: addNewPickupLocation, isPending: addPickupLocationPending, isSuccess: addPickupLocationSuccess } = useMutation({
    mutationKey: [addPickupLocation.apiKey],
    mutationFn: (body: any) => {
      return axiosInstance.post(getApiUrl(addPickupLocation.url, {sellerId: DEFAULT_SELLER?.value}), body);
    },
    onSuccess: ({ data }) => {
      const locationData = data.data;
      CustomToast('Pickup location added successfully', { my_type: 'success' });
      const pickupLocationPreSelected = formState.watch(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value)?.pickup_location_pre_selected;
      handlePickupAddressChanges({
        ...locationData,
        label: locationData.name,
        value: locationData.id,
        [PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_PRE_SELECTED]: pickupLocationPreSelected,
      });
      if(!pickupLocationPreSelected) {
        formState.setValue(CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value, null);
      }
      toggleAddPickupLocationSidesheet();
    },
    onError: () => {
      formState.setValue(CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value, null);
      formState.setValue(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value, {});
    }
  });

  const fileChangeHandler = async (e: any) => {
    setIsFileUploading(true);
    const upload_id = await uploadFileOnS3({
      service: 'create-bulk-order',
      usecase: 'bulk-upload',
      file: e?.[0]
    });

    if (upload_id) {
      setIsFileUploading(false);
      setUploadId(upload_id);
      setUploadProgress(COMPLETED_PROGRESS_VALUE);
    }
  };

  const toggleAddPickupLocationSidesheet = () => {
    setAddPickupLocationSidesheet(p => !p);
    // formState.reset({
    //   ...DEFAULT_VALUE_FOR_PICKUP_LOCATION_FIELDS,
    //   [CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE]: ORDER_CREATION_TYPE.BULK,
    // });
    if (addPickupLocationSidesheet && editPickupLocationSidesheet.isEdit) {
      setEditPickupLocationSidesheet({
        isEdit: false,
        pickupLocationId: null
      });
    }
  };

  // const toggleCreateCustomerSidesheet = () => {
  //   setAddCustomerSidesheet(p => !p);
  //   if (addCustomerSidesheet && editCustomerSidesheet.isEdit) {
  //     setEditCustomerSidesheet({
  //       isEdit: false,
  //       customerId: null
  //     });
  //   }
  // };

  // const toggleEditPickupLocationSidesheet = (id: string | number | null) => {
  //   setEditPickupLocationSidesheet((prev) => ({
  //     ...prev,
  //     isEdit: true,
  //     roleId: id
  //   }));
  //   toggleAddPickupLocationSidesheet();
  // };

  const handlePickupAddressChanges = (value: any) => {
    formState.setValue(CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value, value);
    formState.setValue(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value, value);
    formState.clearErrors(CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value);
  };

  const handlePickupLocationForm = (data: any) => {
    formState.setValue(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value, data);
    const body = {
      name: data[PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_NAME],
      phone_number: data.contact_details.contactNumber,
      country_code: data.contact_details.countryCode?.country_code,
      country_calling_code: data.contact_details.countryCode?.calling_code,
      address: {
        address_line1: data[PICKUP_LOCATION_FIELDS_NAME.ADDRESS_LINE_1],
        address_line2: data[PICKUP_LOCATION_FIELDS_NAME.ADDRESS_LINE_2],
        postal_code: data[PICKUP_LOCATION_FIELDS_NAME.ZIP_CODE],
        country: {
          name: data[PICKUP_LOCATION_FIELDS_NAME.COUNTRY].label,
          id: data[PICKUP_LOCATION_FIELDS_NAME.COUNTRY].value.toString(),
          code: data[PICKUP_LOCATION_FIELDS_NAME.COUNTRY].code
        },
        state: {
          name: data[PICKUP_LOCATION_FIELDS_NAME.STATE]?.label,
          id: data[PICKUP_LOCATION_FIELDS_NAME.STATE]?.value?.toString(),
          code: data[PICKUP_LOCATION_FIELDS_NAME.STATE]?.code
        },
        city: {
          name: data[PICKUP_LOCATION_FIELDS_NAME.CITY].label,
          id: data[PICKUP_LOCATION_FIELDS_NAME.CITY].value.toString(),
          code: data[PICKUP_LOCATION_FIELDS_NAME.CITY].code
        }
      },
      type: 'virtual'
    };
    console.log({ data });
    addNewPickupLocation(body);
  };

  const ctaConfig = (props: MenuProps<unknown, true>) => ({
    append: () => {
      toggleAddPickupLocationSidesheet();
      props.selectProps?.onMenuClose();
    },
    disabled: false,
  });

  return (
    <div className='bulk-order-creation w-100'>
      <div className='d-flex gap-16px w-100'>
        <div className='w-100'>
          <Controller
            name={CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value}
            control={formState.control}
            render={({ field }) => {
              return (
                /* @ts-expect-error fix */
                <Select
                  {...field}
                  label={CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.label}
                  variant='floatingDropdown'
                  isRequired
                  options={Object.values(ORDER_CREATION_TYPE)}
                  onChange={(e: any) => {
                    field.onChange(e);
                    const resetValues = handleFormReset({ key: CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value, watch: formState.watch });
                    formState.reset(resetValues);
                  }}
                />
              );
            }}
          />
        </div>
        <div className='w-100'>
          {!isLMDSellerEnabled && <Controller
            name={CREATE_ORDER_FIELDS_NAME.SELLER.value}
            control={formState.control}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  label={CREATE_ORDER_FIELDS_NAME.SELLER.label}
                  isRequired
                  isAsync
                  /* @ts-expect-error fix*/
                  loadOptions={getSellersAsyncData}
                  onChange={(e: any) => {
                    field.onChange(e);
                    // formState.setValue(CREATE_ORDER_FIELDS_NAME.PACKAGES, PACKAGES_DEFAULT_VALUE);
                  }}
                />
              );
            }}
          />}
        </div>
      </div>

      <div className='pickup-location-container'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='text-dark txt-h3-sb'>{t('Pickup Address')}</div>
          {/* <Button ofStyle='noBackground' ofType='compressed' type='button' onClick={toggleAddPickupLocationSidesheet} className='d-flex gap-8px txt-sub-md'>
              <Plus size={16} /> {t('Add New Address')}
            </Button> */}
        </div>
        <Controller
          name={CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value}
          control={formState.control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => { 
            return (
              <Select
                {...field}
                label={CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.label}
                isAsync
                isRequired
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-expect-error */
                loadOptions={fetchPickupAddress}
                cacheUniqs={[addPickupLocationSuccess, formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)]}
                onChange={(e) => {
                  field.onChange(e);
                  handlePickupAddressChanges(e);
                  formState.setValue(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value, {
                    ...formState.getValues(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value),
                    [PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_PRE_SELECTED]: true
                  });
                }}
                customComponents={{
                  Menu: (props: MenuProps<any, true>) => <DropdownOptionsWithCTA
                    isHeaderCTA
                    ctaConfig={ctaConfig(props)}
                    {...props}
                  />
                }}
                errors={formState.formState.errors}
                additional={{
                  seller_id: formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)?.value
                }}
              />
            );
          }}
        />

        {formState.watch('pickup_location.name') && formState.watch('pickup_location.pickup_location_pre_selected') &&
            <div className='bg-primary-lighter p-16px rounded-8px d-flex flex-column gap-12px h-100'>
              <div className='text-dark txt-body-sb'>{formState.watch('pickup_location.name')}</div>
              <div className='d-flex flex-column gap-6px'>
                <div className='d-flex gap-8px txt-sub-rg text-dark-6'>
                  <span>{formState.watch('pickup_location.contact_details.country_calling_code')}{'-'}{formState.watch('pickup_location.contact_details.phone_number')}</span>
                  {formState.watch('pickup_location.email') &&  <>
                    {formState.watch('pickup_location.contact_details.phone_number') && <span>•</span>}
                    <span>{formState.watch('pickup_location.email')}</span>
                  </>}
                </div>
                <span className='txt-asst-rg text-dark-6'>
                  {formState.watch('pickup_location.address.address_line1') && <> {formState.watch('pickup_location.address.address_line1')}</>}
                  {formState.watch('pickup_location.address.address_line2') && <>, {formState.watch('pickup_location.address.address_line2')}</>}
                  {formState.watch('pickup_location.address.city.name') && <>, {formState.watch('pickup_location.address.city.name')}</>}
                  {formState.watch('pickup_location.address.state.name') && <>, {formState.watch('pickup_location.address.state.name')}</>}
                  {formState.watch('pickup_location.address.country.name') && <>, {formState.watch('pickup_location.address.country.name')}</>}
                  {formState.watch('pickup_location.address.zip_code') && <>, {formState.watch('pickup_location.address.zip_code')}</>}
                </span>
              </div>
            </div>
        }
      </div>

      <div className='steps-to-create-shipments'>
        <p className='m-0 txt-body-md text-dark'>{t('Steps to Create a Bulk Shipment:')}</p>
        <ol className='m-0 ps-18px text-dark txt-sub-md'>
          <li>{t('Download Sample Template :')} <span className='txt-sub-rg text-dark-6'>{t('Click to download the')} <a href={sampleFile} className='text-primary'>{t('sample sheet')}</a> {t('for bulk shipment.')}</span> </li>
          <li>{t('Fill in Required Details :')} <span className='txt-sub-rg text-dark-6'>{t('Complete the sheet, ensuring all mandatory fields are filled according to the provided data format.')}</span> </li>
          <li>{t('Upload Completed Sheet :')} <span className='txt-sub-rg text-dark-6'>{t('Submit the sheet to upload and process the bulk shipment.')}</span> </li>
        </ol>
      </div>

      <div className='w-100 sheet-upload-section-container'>
        <div className='sheet-upload-section'>
          {!isFileExists &&
            <div className='upload-file-illustration'>
              <BulkUploadSvg primary="var(--bs-primary)" width={140} height={124} />
            </div>
          }
          <BulkUpload
            fileChangeHandler={fileChangeHandler}
            fileLogo={fileLogo}
            setUploadId={setUploadId}
            progress={uploadProgress}
            setUploadProgress={setUploadProgress}
            isFileExists={isFileExists}
            /* @ts-expect-error fix*/
            setIsFileExists={setIsFileExists}
            url={sampleFile}
            hasBorder={false}
            isContentCenter={false}
            isFileUploading={isFileUploading}
          />
        </div>
      </div>

      <AddPickupLocationSidesheet
        isOpen={addPickupLocationSidesheet}
        toggleSideSheet={toggleAddPickupLocationSidesheet}
        editPickupLocationSidesheet={editPickupLocationSidesheet}
        handleFormSubmit={handlePickupLocationForm}
        loading={addPickupLocationPending}
      />
      {/* <AddCustomer isOpen={addCustomerSidesheet} toggleSideSheet={toggleCreateCustomerSidesheet} editCustomer={editCustomerSidesheet} formState={formState} handleFormSubmit={handleCreateCustomerForm} /> */}
    </div>
  );
};

export default BulkOrderCreation;
