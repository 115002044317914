/* eslint-disable @typescript-eslint/no-explicit-any */
import { nanoid } from '@reduxjs/toolkit';
import { ErrorIcon } from '@src/assets/images/icons/ErrorIcon';
import { RequiredIcon } from '@src/assets/images/icons/RequiredIcon';
import classNames from 'classnames';
import { forwardRef, useRef } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type iInputWithAdornmentProps = {
  errors: FieldErrors<any>;
  name: string;
  label: string;
  isRequired?: boolean;
  disabled?: boolean;
  isSuccess?: boolean;
  width?: string;
  adornment?: string;
  handleAdornment?: () => void;
};

const InputWithAdornment = forwardRef<HTMLInputElement, iInputWithAdornmentProps>((props, ref) => {
  const { errors, name, label, isRequired, disabled, isSuccess, width, adornment, handleAdornment } = props;
  const id = useRef(nanoid());
  const { t } = useTranslation();

  let isNested = false;
  let nestedError;
  if (name?.includes('.')) {
    isNested = true;
    nestedError = errors as any;
    for (const key of name.split('.')) {
      nestedError = nestedError?.[key];
    }
  }

  const errorMessage = isNested ? (nestedError as any)?.message : (errors[name]?.message as string);

  return (
    <div className="omniful-floating-input-with-adornment" style={{ width }}>
      <div className={classNames('omniful-floating-input-with-adornment-wrapper w-100', {
        'invalid-input': (!!errorMessage && !disabled),
        'success-state': isSuccess,
        'disabled-state': disabled,
      })}>
        <input
          ref={ref}
          id={id.current}
          placeholder=" "
          className={classNames({ 'is-required': isRequired })}
          {...props}
        />
        <label htmlFor={id.current}>
          {t(label)}
          {isRequired && (
            <span className="required-icon">
              <RequiredIcon />
            </span>
          )}
        </label>
        <div className="start-adornment-wrapper cursor-pointer" onClick={handleAdornment}>
          {adornment}
        </div>
      </div>

      {errorMessage && !disabled && (
        <div className="d-flex align-items-center mt-2px">
          <span className="ms-12px me-6px">
            <ErrorIcon />
          </span>
          <span className="txt-sub-rg text-danger">
            {errorMessage}
          </span>
        </div>
      )}
    </div>
  );
});

InputWithAdornment.displayName = 'InputWithAdornment';

export default InputWithAdornment;
