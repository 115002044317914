// ** Reactstrap Imports
import Button from '@src/@core/components/ui/button'
import InputField from '@src/@core/components/ui/input-field'
import PhoneNumberDropdown from '@src/@core/components/ui/phone-number'
import Select from '@src/@core/components/ui/select'
import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { loadCitiesConfig, loadCountryConfig, loadStatesCitiesConfig, loadStatesConfig } from '@src/app.apis'
import { REGEX_PATTERN } from '@src/App.constants'
import { getOptionsFetcher } from '@src/utility/Utils'
import { Countries } from '@src/views/hubs/utils/countries'
import { CUSTOMER_ADDRESS } from '@src/views/sales/constant/orders.constants'
import { CUSTOMERS_FIELDS } from '@src/views/sales/sales.constant'
import {
  clearCustomerAddress,
  createAddress,
  editAddress,
  getCustomerAddress
} from '@src/views/sales/store/store'
import { useEffect } from 'react'
import { Info } from 'react-feather'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Form,
  ModalBody,
  ModalHeader,
  Spinner
} from 'reactstrap'
import './CreateAddress.scss'

const CreateAddress = ({
  openCreateAddressSidebar,
  setOpenCreateAddressSidebar,
  // selectedSeller
  customerDetails = {}
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loading = useSelector((store) => store.sales.loading)
  const customerAddress = useSelector((store) => store.sales.customerAddress)
  const loadCountryOptions = getOptionsFetcher(loadCountryConfig)
  const handleCreateCustomerSidebar = () => {
    setOpenCreateAddressSidebar((prev) => ({ ...prev, open: false }))
  }
  const loadStatesOptions = getOptionsFetcher(loadStatesConfig);

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name:'',
      email: '',
      customer_phone_number: {
        countryCode: {
          name: 'Saudi Arabia',
          flag: '🇸🇦',
          country_code: 'SA',
          calling_code: '+966',
          value: 'Saudi Arabia (SA)',
          label: 'Saudi Arabia (+966)',
          maxPhoneNumberLength: 9
        },
        contactNumber: ''
      },
      customer_address1: '',
      customer_address2: '',
      customer_country: '',
      customer_state: '',
      customer_city: '',
      customer_zip: ''
    },
    mode: 'onChange'

  })
  const loadCitiesOptions = getOptionsFetcher(loadCitiesConfig);
  // @ts-expect-error FIX ME
  const loadStatesCitiesOptions = getOptionsFetcher(loadStatesCitiesConfig);

  const onSubmit = (data) => {
    let body = {
      entity_id: openCreateAddressSidebar?.entity_id,
      entity_name: 'customer',
      address1: data.customer_address1,
      address2: data.customer_address2,
      city: data.customer_city.label,
      city_id: data.customer_city.value,
      country: data.customer_country.label,
      country_id: data.customer_country.value,
      state: data.customer_state?.label,
      state_id: data.customer_state?.value,
      first_name: data.first_name,
      last_name:data.last_name,
      email: data.email || undefined,
      zip: data.customer_zip || undefined
    }
    if (data.customer_phone_number.contactNumber) {
      body = {
        ...body,
        phone: data.customer_phone_number.contactNumber,
        country_calling_code: data.customer_phone_number.countryCode.calling_code,
        country_code: data.customer_phone_number.countryCode.country_code}
    }

    if (openCreateAddressSidebar.type === CUSTOMER_ADDRESS.CREATE_ADDRESS.key) {
      dispatch(createAddress({ body }))
    } else {
      dispatch(
        editAddress({ address_id: openCreateAddressSidebar.address_id, body })
      )
    }
  }

  const handleSidebarOpened = () => {
    const address_id = openCreateAddressSidebar?.address_id
    if (
      openCreateAddressSidebar.type === CUSTOMER_ADDRESS.EDIT_ADDRESS.key &&
      address_id
    ) {
      dispatch(getCustomerAddress({ address_id }))
    } else {
      const customerCountryCodeObj = Countries.find(country => country.calling_code === customerDetails.country_calling_code)
      setValue(CUSTOMERS_FIELDS.FIRST_NAME, customerDetails.first_name)
      setValue(CUSTOMERS_FIELDS.LAST_NAME, customerDetails.last_name)
      setValue(CUSTOMERS_FIELDS.EMAIL, customerDetails.email)
      if (customerDetails.phone_number) {
        setValue('customer_phone_number.countryCode', customerCountryCodeObj)
        setValue('customer_phone_number.contactNumber', customerDetails.phone_number)
      }
    }
  }


  useEffect(() => {
    if (customerAddress) {
      const country = {
        value: customerAddress.country.omniful_id,
        label: customerAddress.country.omniful_name
      }
      const state = {
        value: customerAddress.state.omniful_id,
        label: customerAddress.state.omniful_name
      }
      const city = {
        value: customerAddress.city.omniful_id,
        label: customerAddress.city.omniful_name
      }
      setValue(CUSTOMERS_FIELDS.FIRST_NAME, customerAddress.first_name, {
        shouldValidate: true
      })
      setValue(CUSTOMERS_FIELDS.EMAIL, customerAddress.email, { shouldValidate: true })
      if (customerAddress.phone_number) {
        const countryData = Countries.find(cntry => cntry.calling_code === customerAddress.country_calling_code)
        const customer_phone_number =  { contactNumber: customerAddress.phone_number, countryCode: countryData}
        setValue(CUSTOMERS_FIELDS.PHONE, customer_phone_number)
      }
      setValue(CUSTOMERS_FIELDS.ADDRESS_1, customerAddress.address1, {
        shouldValidate: true
      })
      setValue(CUSTOMERS_FIELDS.ADDRESS_2, customerAddress.address2, {
        shouldValidate: true
      })
      setValue(CUSTOMERS_FIELDS.COUNTRY, country, { shouldValidate: true })
      setValue(CUSTOMERS_FIELDS.STATE, state, { shouldValidate: true })
      setValue(CUSTOMERS_FIELDS.CITY, city, { shouldValidate: true })
      setValue(CUSTOMERS_FIELDS.ZIP, customerAddress.zip, { shouldValidate: true })
      setValue(CUSTOMERS_FIELDS.LAST_NAME, customerAddress.last_name)
    }
  }, [customerAddress])

  const handleSidebarClosed = () => {
    reset()
    dispatch(clearCustomerAddress())
  }

  useEffect(() => {
    if (watch(CUSTOMERS_FIELDS.EMAIL) && errors.customer_phone_number) clearErrors(CUSTOMERS_FIELDS.PHONE)
    if (watch('customer_phone_number.contactNumber') && errors.email?.type === 'required') clearErrors(CUSTOMERS_FIELDS.EMAIL)
  }, [watch(CUSTOMERS_FIELDS.EMAIL), watch('customer_phone_number.contactNumber')])
  
  const isPhoneRequired = !!(!watch(CUSTOMERS_FIELDS.EMAIL) || watch('customer_phone_number.contactNumber'))
  const isEmailRequired = !!(!watch('customer_phone_number.contactNumber') || watch(CUSTOMERS_FIELDS.EMAIL))

  return (
    <SideSheet
      modalClassName="modal-slide-in sidebar-todo-modal create-address-sidesheet"
      isOpen={openCreateAddressSidebar.open}
      toggle={handleCreateCustomerSidebar}
      onClosed={handleSidebarClosed}
      onOpened={handleSidebarOpened}
    >
      <ModalHeader toggle={handleCreateCustomerSidebar}>
        {t(CUSTOMER_ADDRESS[openCreateAddressSidebar.type].headerTitle)}
      </ModalHeader>
      {loading.getCustomerAddress ? <ComponentSpinner /> : <ModalBody className="modal-body-data p-24px">
        <Form>
          <>
            <div className="d-flex flex-column gap-18px mb-5">
              <div className="d-flex p-12px rounded-8px gap-10px text-dark txt-body-rg info-header">
                <Info fill='var(--bs-primary)' color="white" size={24} />
                <span>{t(`An email or phone number is required to ${CUSTOMER_ADDRESS[openCreateAddressSidebar.type].infoText}`)}.</span>
              </div>
              <InputField
                errors={errors}
                {...register(CUSTOMERS_FIELDS.FIRST_NAME, {
                  required: t('Please enter first name')
                })}
                isClearable
                label="First Name"
                isRequired
              />
              <InputField
                errors={errors}
                {...register(CUSTOMERS_FIELDS.LAST_NAME)}
                isClearable
                label="Last Name"
              />
              <InputField
                errors={errors}
                {...register(CUSTOMERS_FIELDS.EMAIL, {
                  required: watch('customer_phone_number.contactNumber') ? false :  'Please enter either email or contact number',
                  pattern: {
                    value: REGEX_PATTERN.EMAIL_VALIDATION,
                    message: t('Enter a valid email address')
                  }
                })}
                isClearable
                label="Email"
                isRequired={isEmailRequired}
              />
              <PhoneNumberDropdown
                name={CUSTOMERS_FIELDS.PHONE}
                contactNumber={watch('customer_phone_number.contactNumber')}
                countryCode={watch('customer_phone_number.countryCode')}
                control={control}
                errors={errors}
                register={register}
                isClearable
                label="Contact Number"
                isRequired={isPhoneRequired}
                setError={setError}
                clearErrors={clearErrors}
                customValidation={val => {
                  if (watch(CUSTOMERS_FIELDS.EMAIL) || val.length) {
                    return true
                  }
                  return 'Please enter either email or contact number'
                }}
                menuPlacement='auto'
                captureMenuScroll={false}
              />
              <InputField
                errors={errors}
                {...register(CUSTOMERS_FIELDS.ADDRESS_1, {
                  required: t('Please enter address')
                })}
                isClearable
                label="Address line 1"
                isRequired
              />
              <InputField
                errors={errors}
                {...register(CUSTOMERS_FIELDS.ADDRESS_2)}
                isClearable
                label="Address line 2"
              />

              <Controller
                name={CUSTOMERS_FIELDS.COUNTRY}
                control={control}
                rules={{ required: t('Please select country') }}
                render={({ field }) => (
                  <Select
                    {...field}
                    isAsync
                    loadOptions={loadCountryOptions}
                    isTooltipError
                    errors={errors}
                    value={watch(CUSTOMERS_FIELDS.COUNTRY)}
                    isClearable
                    label="Select Country"
                    isRequired
                    onChange={(e) => {
                      field.onChange(e)
                      if (watch(CUSTOMERS_FIELDS.CITY) || watch(CUSTOMERS_FIELDS.STATE)) {
                        setValue(CUSTOMERS_FIELDS.CITY, null)
                        setValue(CUSTOMERS_FIELDS.STATE, null)
                      }
                    }}
                    infoText={!errors.customer_country ? 'Type at least 3 characters to search for a country' : null}
                    menuPlacement='auto'
                    captureMenuScroll={false}
                  />
                )}
              />

              <Controller
                name={CUSTOMERS_FIELDS.STATE}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field} 
                    isAsync
                    control={control}
                    value={getValues(CUSTOMERS_FIELDS.STATE)}
                    loadOptions={loadStatesOptions}
                    errors={errors}
                    isClearable
                    isDisabled={!watch(CUSTOMERS_FIELDS.COUNTRY)}
                    label='Select State/Region'
                    cacheUniqs={[getValues(CUSTOMERS_FIELDS.COUNTRY)]}
                    additional={{
                      country: getValues(CUSTOMERS_FIELDS.COUNTRY)
                    }}
                    onChange={(e) => {
                      field.onChange(e)
                      if (watch(CUSTOMERS_FIELDS.CITY)) {
                        setValue(CUSTOMERS_FIELDS.CITY, null)
                      }
                    }}
                    menuPlacement='auto'
                    captureMenuScroll={false}
                  />
                )}
              />

              <Controller
                name={CUSTOMERS_FIELDS.CITY}
                control={control}
                rules={{ required: t('Please select city') }}
                render={({ field }) => (
                  <Select
                    {...field}
                    isAsync
                    loadOptions={watch(CUSTOMERS_FIELDS.STATE) ? loadStatesCitiesOptions : loadCitiesOptions}
                    isTooltipError
                    errors={errors}
                    value={watch(CUSTOMERS_FIELDS.CITY)}
                    isClearable
                    label="Select City"
                    isRequired
                    isDisabled={!getValues(CUSTOMERS_FIELDS.COUNTRY)}
                    cacheUniqs={watch(CUSTOMERS_FIELDS.STATE) ? [getValues(CUSTOMERS_FIELDS.STATE)] : [getValues(CUSTOMERS_FIELDS.COUNTRY)]}
                    additional={{
                      state: getValues(CUSTOMERS_FIELDS.STATE),
                      params: {
                        country_id: watch(CUSTOMERS_FIELDS.STATE) ? null
                          : watch(CUSTOMERS_FIELDS.COUNTRY)?.value,
                      }
                    }}
                    menuPlacement='auto'
                    captureMenuScroll={false}
                  />
                )}
              />
              <div>
                <InputField
                  errors={errors}
                  {...register(CUSTOMERS_FIELDS.ZIP)}
                  isClearable
                  label="Zip"
                  type="number"
                />
              </div>

            </div>
          </>
        </Form>
      </ModalBody>}
      <SidesheetFooter className='position-sticky bottom-0'>
        <Button
          type="button"
          disabled={loading.createAddress || loading.editAddress}
          onClick={handleSubmit(onSubmit)}
          icon={loading.createAddress || loading.editAddress ? Spinner : null}
          iconSize='sm'
        >
          {t('Save')}
        </Button>
      </SidesheetFooter>
    </SideSheet>
  )
}

export default CreateAddress
