/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@src/@core/components/ui/button';
import InputField from '@src/@core/components/ui/input-field';
import PhoneNumberDropdown from '@src/@core/components/ui/phone-number';
import Select from '@src/@core/components/ui/select';
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer';
import { loadCitiesConfig, loadCountryConfig, loadStatesCitiesConfig, loadStatesConfig } from '@src/app.apis';
import { REGEX_PATTERN } from '@src/App.constants';
import { getOptionsFetcher } from '@src/utility/Utils';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { iAddCustomerProps } from '../../shipping-aggregator-types';
import { CREATE_CUSTOMER_FIELDS_NAME, DEFAULT_VALUE_FOR_CREATE_CUSTOMER_FIELDS } from '../../shipping-aggregator.constant';
const AddCustomerSidesheetForm = (props: iAddCustomerProps) => {
  const { toggleSideSheet, handleFormSubmit, loading } = props;
  const { t } = useTranslation();
  /* @ts-expect-error fix */
  const loadStatesOptions = getOptionsFetcher(loadStatesConfig);
  const { register, control, watch, setValue, formState: { errors }, handleSubmit, setError, clearErrors } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...DEFAULT_VALUE_FOR_CREATE_CUSTOMER_FIELDS
    }
  });
  // @ts-expect-error Fix Me
  const loadCitiesOptions = getOptionsFetcher(loadCitiesConfig); // @ts-expect-error Fix Me
  const loadStatesCitiesOptions = getOptionsFetcher(loadStatesCitiesConfig);

  const handleSubmitFunc = (data: object) => {
    handleFormSubmit(data);
  };

  const isPhoneRequired = !!(!watch(CREATE_CUSTOMER_FIELDS_NAME.EMAIL) || watch(CREATE_CUSTOMER_FIELDS_NAME.contact_details?.contactNumber));
  const isEmailRequired = !!(!watch(CREATE_CUSTOMER_FIELDS_NAME.contact_details?.contactNumber) || watch(CREATE_CUSTOMER_FIELDS_NAME.EMAIL));

  useEffect(() => {
    if (watch(CREATE_CUSTOMER_FIELDS_NAME.EMAIL)) clearErrors(CREATE_CUSTOMER_FIELDS_NAME.CONTACT_DETAILS);
    if (watch(CREATE_CUSTOMER_FIELDS_NAME.contact_details?.contactNumber)) clearErrors(CREATE_CUSTOMER_FIELDS_NAME.EMAIL);
  }, [watch(CREATE_CUSTOMER_FIELDS_NAME.EMAIL), watch(CREATE_CUSTOMER_FIELDS_NAME.contact_details?.contactNumber)]);
  // @ts-expect-error Fix Me
  const loadCountryOptions = getOptionsFetcher(loadCountryConfig);

  return (
    <form className='p-24px d-flex flex-column h-100 align-items-center justify-content-between gap-20px' style={{ marginBottom: '72px' }}>
      <div className='w-100 h-100 d-flex flex-column gap-20px'>
        <div className='d-flex gap-16px w-100'>
          <div className='w-100'>
            <InputField
              label='First Name'
              isRequired
              {...register(CREATE_CUSTOMER_FIELDS_NAME.FIRST_NAME, {
                required: 'This field is required'
              })}
              /* @ts-expect-error fix*/
              errors={errors}
            />
          </div>
          <div className='w-100'>
            <InputField
              /* @ts-expect-error fix*/
              label='Last Name'
              {...register(CREATE_CUSTOMER_FIELDS_NAME.LAST_NAME)}
            />
          </div>
        </div>

        <div className='p-16px bg-light-2 rounded-8px d-flex flex-column gap-16px'>
          <p className='m-0 txt-sub-rg text-dark-6'>{t('Please provide either an email address or a contact number (both can be entered).')}</p>
          <InputField
            label='Email ID'
            isRequired={isEmailRequired}
            {...register(CREATE_CUSTOMER_FIELDS_NAME.EMAIL, {
              required: watch(CREATE_CUSTOMER_FIELDS_NAME.contact_details?.contactNumber) ? false :  'Please enter either email or contact number',
              pattern: {
                value: REGEX_PATTERN.EMAIL_VALIDATION,
                message: t('Enter a valid email address')
              }
            })}
            /* @ts-expect-error fix*/
            errors={errors}
          />
          <div className='d-flex align-items-center justify-content-center gap-8px'>
            <hr className='w-100 bg-dark-1' />
            <span className='bg-dark-1 px-8px py-2px rounded-24px text-dark-6 txt-asst-rg'>OR</span>
            <hr className='w-100 bg-dark-1' />
          </div>
          {/* @ts-expect-error fix*/}
          <PhoneNumberDropdown
            name={CREATE_CUSTOMER_FIELDS_NAME.CONTACT_DETAILS}
            contactNumber={watch(CREATE_CUSTOMER_FIELDS_NAME.contact_details?.contactNumber)}
            countryCode={watch(CREATE_CUSTOMER_FIELDS_NAME.contact_details?.countryCode)}
            control={control}
            errors={errors}
            register={register}
            isClearable
            label="Contact Number"
            isRequired={isPhoneRequired}
            setError={setError}
            clearErrors={clearErrors}
            customValidation={(val: string) => {
              if (watch(CREATE_CUSTOMER_FIELDS_NAME.EMAIL) || val.length) {
                return true;
              }
              return 'Please enter either email or contact number';
            }}
          />
        </div>
        
        <InputField
          label='Address Line 1'
          isRequired
          {...register(CREATE_CUSTOMER_FIELDS_NAME.ADDRESS_LINE_1, {
            required: 'This field is required'
          })}
          /* @ts-expect-error fix*/
          errors={errors}
        />

        <InputField
          /* @ts-expect-error fix*/
          label='Address Line 2'
          {...register(CREATE_CUSTOMER_FIELDS_NAME.ADDRESS_LINE_2)}
        />

        <div className='d-flex w-100 gap-16px'>
          <div className='w-100'>
            <Controller
              name={CREATE_CUSTOMER_FIELDS_NAME.COUNTRY}
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label='Country'
                    isRequired
                    isClearable
                    isAsync
                    /* @ts-expect-error fix*/
                    value={watch(CREATE_CUSTOMER_FIELDS_NAME.COUNTRY)}
                    /* @ts-expect-error fix*/
                    loadOptions={loadCountryOptions}
                    onChange={(e: any) => {
                      field.onChange(e);
                      setValue(CREATE_CUSTOMER_FIELDS_NAME.STATE, null);
                      setValue(CREATE_CUSTOMER_FIELDS_NAME.CITY, null);
                    }}
                    /* @ts-expect-error fix*/
                    errors={errors}
                  />
                );
              }}
            />
          </div>
          <div className='w-100'>
            <Controller
              name={CREATE_CUSTOMER_FIELDS_NAME.STATE}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label='State'
                    isAsync
                    /* @ts-expect-error fix*/
                    value={watch(CREATE_CUSTOMER_FIELDS_NAME.STATE)}
                    /* @ts-expect-error fix*/
                    loadOptions={loadStatesOptions}
                    isClearable
                    isDisabled={!watch(CREATE_CUSTOMER_FIELDS_NAME.COUNTRY)}
                    additional={{
                      country: watch(CREATE_CUSTOMER_FIELDS_NAME.COUNTRY)
                    }}
                    cacheUniqs={[watch(CREATE_CUSTOMER_FIELDS_NAME.COUNTRY)]}
                    onChange={(e: any) => {
                      field.onChange(e);
                      setValue(CREATE_CUSTOMER_FIELDS_NAME.CITY, null);
                    }}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className='d-flex w-100 gap-16px'>
          <div className='w-100'>
            <Controller
              name={CREATE_CUSTOMER_FIELDS_NAME.CITY}
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label='City'
                    isRequired
                    isClearable
                    isAsync
                    /* @ts-expect-error fix*/
                    value={watch(CREATE_CUSTOMER_FIELDS_NAME.CITY)}
                    /* @ts-expect-error fix*/
                    loadOptions={watch(CREATE_CUSTOMER_FIELDS_NAME.STATE) ? loadStatesCitiesOptions : loadCitiesOptions}
                    isDisabled={!watch(CREATE_CUSTOMER_FIELDS_NAME.COUNTRY)}
                    additional={{
                      state: watch(CREATE_CUSTOMER_FIELDS_NAME.STATE),
                      params: {
                        country_id: watch(CREATE_CUSTOMER_FIELDS_NAME.STATE) ? null
                        /* @ts-expect-error fix*/
                          : watch(CREATE_CUSTOMER_FIELDS_NAME.COUNTRY)?.value,
                      }
                    }}
                    cacheUniqs={watch(CREATE_CUSTOMER_FIELDS_NAME.STATE) ? [watch(CREATE_CUSTOMER_FIELDS_NAME.STATE)] : [watch(CREATE_CUSTOMER_FIELDS_NAME.COUNTRY)]}
                    onChange={(e: any) => {
                      field.onChange(e);
                    }}
                    /* @ts-expect-error fix*/
                    errors={errors}
                  />
                );
              }}
            />
          </div>
          <div className='w-100'>
            <InputField
              label='Zip Code'
              {...register(CREATE_CUSTOMER_FIELDS_NAME.ZIP_CODE, {
                required: false
              })}
              /* @ts-expect-error fix*/
              errors={errors}
            />
          </div>
        </div>

        {/* <CheckBox
          type="checkbox"
          label="Customer will be pre-selected by default. Uncheck if not needed."
          {...register(CREATE_CUSTOMER_FIELDS_NAME.CUSTOMER_PRE_SELECTED)}
          checked={watch(CREATE_CUSTOMER_FIELDS_NAME.CUSTOMER_PRE_SELECTED)}
          isRequired={false}
        /> */}
      </div>
      <SidesheetFooter className='d-flex gap-12px'>
        <Button ofStyle='outlined' type='button' onClick={toggleSideSheet}>{t('Cancel')}</Button>
        <Button type='button' loading={loading} onClick={handleSubmit(handleSubmitFunc)}>{t('Add')}</Button>
      </SidesheetFooter>
    </form>
  );
};

export default AddCustomerSidesheetForm;