import AndroidIcon from '../../../../assets/images/svg/android_icon.svg'
import app from '../../../../assets/images/svg/android_ios.svg'

export const omnifulApps = ({enabled = {}}) => ([
  {
    key: 'PICKER_PLUS',
    appName: 'Picker Plus App',
    desc: 'Optimise Your Warehouse Operations',
    appURL: 'picker-plus-app',
    availability: 'Android or iOS',
    icon: app,
    iconWidth: 98,
    iconHeight: 45.23,
    isEnabled: enabled['PICKER_PLUS']
  },
  {
    key: 'POINT_OF_SALE',
    appName: 'Point of Sale App',
    desc: 'Simplify Transactions & Track Sale in Real-Time',
    appURL: 'point-of-sale-app',
    availability: 'Android',
    icon: AndroidIcon,
    iconWidth: 54,
    iconHeight: 54,
    isEnabled: enabled['POINT_OF_SALE']
  },
  // {
  //     appName:"TMS Delivery App",
  //     desc:"Streamline Your Delivery & Fleet Operations",
  //     appURL:"tms-delivery-app",
  //     availability:"Android or iOS",
  //     icon: app,
  //     iconWidth: 98,
  //     iconHeight: 45.23
  // },
  // {
  //     appName:"TMS Sorting App",
  //     desc:"Streamline Your Receiving & Sorting Operations",
  //     appURL:"tms-sorting-app",
  //     availability:"Android",
  //     icon: AndroidIcon,
  //     iconWidth: 54,
  //     iconHeight: 54
  // }
].filter(e => e.isEnabled))

export const ENVIRONMENT_VARIABLES = {
  PRODUCTION: 'live',
  STAGING: 'stage',
  DEVELOPMENT: 'dev'
}