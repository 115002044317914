import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import SKUDetailsCol from '@src/@core/components/ui/sku-details'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import SKUBarcodeModal from '@src/views/sales/components/SKUBarcodeModal'
import { ORDER_STATUS } from '@src/views/sales/constant/orders.constants'
import OrderDetailsSideSlider from '@src/views/sales/OrderDetailsSideSlider'
import OnHoldOrderDetailsSideSheet from '@src/views/sales/pending-actions/components/OnHoldOrderDetailsSideSheet/OnHoldOrderDetailsSideSheet'
import OrderItemsChildTable from '@src/views/sales/pending-actions/pages/ordersForApproval/OrderItemsChildTable/OrderItemsChildTable'
import { PATH_NAME } from '@src/views/sales/sales.constant'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const OrderDetails = ({ stoDetail, isSourceHubVirtual, isDestinationHubVirtual }) => {

  // Hooks
  const { t } = useTranslation()

  // variables 
  const currentPathname = window.location.pathname

  // States
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedStoOrder, setSelectedStoOrder] = useState(null)
  const [barcodeModalOpen, setBarcodeModalOpen] = useState(false)
  const [barcodeList, setBarcodeList] = useState([])
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)
  const [skuForDetails, setSkuForDetails] = useState({})
  const [isOnHoldSideSheetOpen, setIsOnHoldSideSheetOpen] = useState(false)

  // Functions
  const handleOpenOrderDetailSideSheet = (row) => {
    if (row.source_status === ORDER_STATUS.ON_HOLD.key) {
      setIsOnHoldSideSheetOpen(true)
    } else {
      setIsModalOpen(true)
    }
    setSelectedStoOrder(row)
  }

  const toggleBarcodeModal = (barcodes) => {
    setBarcodeList(
      barcodes ? barcodes?.map((barcode) => ({ barcodeValue: barcode })) : []
    )
    setBarcodeModalOpen((p) => !p)
  }

  const handleSkuDetailsSidebar = (data) => {
    setSkuForDetails(data)
    setOpenSkuDetailsSidebar(!openSkuDetailsSidebar)
  }

  const handleCloseOnHoldDetailsSideSheet = () => {
    setIsOnHoldSideSheetOpen(false)
    setSelectedStoOrder(null)
  }
  
  const TableColumns = [
    {
      id: 1,
      name: t('Order ID'),
      minWidth: '320px',
      key: 'order_id',
      cell: (row) => row.display_order_id ?
        <CopyOnClick
          id={row.oms_order_id}
          handleDetails={currentPathname === (PATH_NAME.liveOrders || PATH_NAME.pendingActions) ? null : () =>  handleOpenOrderDetailSideSheet(row)}
          columnKey="order_id"
        >
          {row.display_order_id}
        </CopyOnClick>
        : '-'
    },
    {
      id: 2,
      name: <div className='w-100 text-end text-truncate'>{t('Ordered Qty')}</div>,
      key: 'ordered_qty',
      minWidth: '120px',
      cell: (row) => <div className='w-100 text-end'>{row.total_ordered_quantity}</div>
    },
    {
      id: 3,
      name: t('Source Status'),
      minWidth: '130px',
      key: 'source_status',
      omit: isSourceHubVirtual,
      selector: (row) => row.display_source_status
    },
    {
      id: 4,
      name: t('Destination Status'),
      minWidth: '160px',
      key: 'received_status',
      omit: isDestinationHubVirtual,
      selector: (row) => row.display_received_status
    },
    {
      id: 5,
      name: <div className='w-100 text-end text-truncate'>{t('Shipped Qty')}</div>,
      minWidth: '120px',
      key: 'shipped_qty',
      omit: isSourceHubVirtual,
      cell: (row) =><div className='w-100 text-end'>{row.total_transferred_quantity}</div>
    },
    {
      id: 6,
      name: <div className='w-100 text-end text-truncate'>{t('Received Qty')}</div>,
      minWidth: '120px',
      key: 'received_qty',
      omit:isDestinationHubVirtual,
      cell: (row) =><div className='w-100 text-end'>{row.total_received_quantity}</div>
    }
  ]

  const childTableColumns = [
    {
      id: 1,
      name: t('SKU Details'),
      minWidth: '350px',
      key: 'sku_details',
      cell: (row) => {
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: row.id,
              image: {
                src: row.seller_sku?.images?.[0]?.default || no_sku_image,
                alt: row.seller_sku?.name
              },
              details: {
                name: row.seller_sku?.name,
                skuCode: row.seller_sku?.seller_sku_code,
                columnKey: 'sku_details',
                handleSkuDetails: row.id ? () => (handleSkuDetailsSidebar(row)) : null,
              }
            }}
          />
        )
      } 
    },
    {
      id: 2,
      name: t('SKU Barcode'),
      key: 'sku_barcode',
      minWidth: '200px',
      cell: (row) => {
        return (
          <div className="box-cell">
            <span className="text-truncate w-75">
              {row.seller_sku?.barcodes?.[0] || '-'}
            </span>
            {row.seller_sku?.barcodes?.length > 1 && (
              <span
                onClick={() => toggleBarcodeModal(row.seller_sku?.barcodes)}
                className={'text-primary ms-1 cursor-pointer w-25'}
              >
                {`+${row.seller_sku.barcodes.length - 1}`}
              </span>
            )}
          </div>
        )
      }
    },
    {
      id: 3,
      name: <div className='w-100 text-end text-truncate' title={t('Ordered Qty')}>{t('Ordered Qty')}</div>,
      key: 'ordered_qty',
      minWidth: '100px',
      cell: (row) => <div className='w-100 text-end'>{row.ordered_quantity}</div>
    },
    {
      id: 4,
      name: <div className='w-100 text-end text-truncate'>{t('Shipped Qty')}</div>,
      minWidth: '100px',
      key: 'shipped_qty',
      omit: isSourceHubVirtual,
      cell: (row) =><div className='w-100 text-end'>{row.transferred_quantity}</div>
    },
    {
      id: 5,
      name: <div className='w-100 text-end text-truncate'>{t('Received Qty')}</div>,
      minWidth: '100px',
      key: 'received_qty',
      omit:isDestinationHubVirtual,
      cell: (row) =><div className='w-100 text-end'>{row.received_quantity}</div>
    }
  ]

  return (
    <div>
      <ExpandableCustomTable
        data={stoDetail?.sto_orders}
        columns={TableColumns}
        columnName={ALL_COLUMN_NAME_MAPPING.STO_ORDER_DETAILS}
        useReactPaginate={false}
        showPagination={false}
        showColumnsTableHeader={false}
        expandableRows
        expandableRowsComponent={OrderItemsChildTable}
        expandableRowsComponentProps={{ childTableColumns, childDataKey: 'items' }}
      />
      
      <OrderDetailsSideSlider
        sellerId={selectedStoOrder?.oms_order_id}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleGetParentData={()=>{}}
      />

      <SKUBarcodeModal
        modalOpen={barcodeModalOpen}
        toggleSidebar={toggleBarcodeModal}
        data={barcodeList}
      />

      <SimpleSkuDetailModal
        skuForDetails={skuForDetails}
        toggleSimpleSkuModal={() => handleSkuDetailsSidebar(false)}
        isOpen={openSkuDetailsSidebar} 
      />

      {/* in case of sto order no order_alias is present */}
      <OnHoldOrderDetailsSideSheet
        isSideSheetOpen={isOnHoldSideSheetOpen}
        orderId={selectedStoOrder?.oms_order_id}
        handleSideSheetClose={handleCloseOnHoldDetailsSideSheet}
        handleGetParentData={()=>{}}
      />

    </div>
  )
}

export default OrderDetails