import classNames from 'classnames'
import PropTypes from 'prop-types'
import './choiceSelector.scss'
const ChoiceSelector = ({ options, activeOption, setActiveOption, onOptionChange, disabled }) => {
  const handleActiveOption = (option) => {
    if (disabled) return
    setActiveOption(option)
    if (onOptionChange) {
      onOptionChange(option)
    }
  }

  return (
    <>
      <section className={classNames('d-flex w-100 align-items-center gap-8px rounded-12px border border-dark-2 bg-light-2 p-4px choice-selector text-dark')}>
        {options.map((item) => (
          <div 
            key={item.value} 
            onClick={() => handleActiveOption(item)} 
            className={classNames('p-8px w-100 rounded-8px cursor-pointer text-center txt-sub-md transition-all text-nowrap', {
              'active-option': (item.value === activeOption.value && !disabled), 
              'inactive-option': (item.value !== activeOption.value && !disabled),
              'active-disabled-option': (item.value === activeOption.value && disabled),
              'inactive-disabled-option': item.value !== activeOption.value && disabled
            })}
          >
            {item.label}
          </div>
        ))}
      </section>
      {activeOption.component && <div>{activeOption.component}</div>}
    </>
  )
}

ChoiceSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      component: PropTypes.element
    })
  ).isRequired,
  activeOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    component: PropTypes.element
  }).isRequired,
  setActiveOption: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default ChoiceSelector
