/* eslint-disable @typescript-eslint/no-explicit-any */
import { RowWrapper } from '@src/views/sales/shipmentOrders/shipmentOrders.utils';
import { SHIPMENT_ORDER_ENTITY } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

const OriginDetailsCard = ({orderDetails = {}, entityType}: any) => {
  const { t } = useTranslation();
  const originDetails = orderDetails.origin_details;

  console.log({orderDetails, entityType, originDetails});

  const ORIGIN_DETAILS_DATA = [
    {
      id: 'location_name',
      entityName: 'Location Name',
      entityValue: originDetails.name || '-',
      omit: false
    },
    {
      id: 'contact_number',
      entityName: 'Contact Number',
      entityValue: originDetails.phone_number || '-',
      omit: false
    },
    {
      id: 'email',
      entityName: 'Email ID',
      entityValue: originDetails.email || '-',
      omit: false
    },
    {
      id: 'address',
      entityName: 'Address',
      entityValue: `${originDetails.address.address_line1 || '-'} ${originDetails.address.address_line2 ? ', ' : ''} ${originDetails.address.address_line2 || ''}`,
      omit: false
    },
    {
      id: 'city',
      entityName: 'City',
      entityValue: originDetails.address.city?.name || '-',
      omit: false
    },
    {
      id: 'state_country',
      entityName: 'State & Country',
      entityValue: `${originDetails.address.state?.name || ''} ${originDetails.address.state?.name ? ', ' : ''} ${originDetails.address.country?.name || '-'}`,
      omit: false
    }
  ];

  return (
    <Card className="bg-white w-100 origin-details-card rounded-3">
      <div className="card-header py-12px px-16px rounded-top-12px  text-secondary txt-h3-md">
        <span>{entityType === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? t('Sender Details') : t('Pickup Details')}</span>
      </div>
      <div className="d-flex flex-column p-16px gap-16px">
        {ORIGIN_DETAILS_DATA.filter((item) => !item.omit).map((item) => (
          <RowWrapper key={item.id} entityNameColSpan={4} entityValueColSpan={8} entityName={item.entityName} entityValue={item.entityValue || '-'} />
        ))
        }
      </div>
    </Card>
  );
};

export default OriginDetailsCard;