/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_ENDPOINTS, getApiUrl } from '@src/api.urls';
import { axiosInstance } from '@src/network/AxiosInstance';
import { paramsSerializer } from '@src/utility/Utils';
// import axios from 'axios';

export const SHIPPING_AGGREGATOR_API_KEYS = {
  GET_ALL_SKU: 'get-all-sku',
  GET_SINGLE_ORDER_DETAIL: 'fetch-single-order',
  GET_ALL_ORDERS: 'get-all-orders',
  GET_ALL_PACKAGES: 'get-all-packages',
  GET_ALL_SHIPMENTS: 'get-all-shipments',
  GET_SUGGESTED_ACCOUNTS: 'suggested-accounts',
  CREATE_SHIPMENT: 'create-shipment',
  GET_ALL_AWB: 'get-all-awb',
  GET_SHIPMENT_ORDER_LOG: 'get-shipment-order-log',
  GET_SINGLE_PACKAGE_DETAILS: 'get-single-package-details',
  FETCH_SHIPMENT_AWB: 'fetch-single-shipment-awb',
  MARK_AS_DELIVERED: 'mark-as-delivered',
  DELETE_ORDER: 'delete_order',
  EDIT_SHIPPING_ADDRESS: 'edit_shipping_address',
  MARK_SHIPMENT_CANCEL: 'mark_shipment_cancel'
};

export const fetchAllSku = ({params, seller_id}: { params: any, seller_id: number }) => {
  const url = getApiUrl(API_ENDPOINTS.CATALOG.GET_PRODUCTS, {sellerId: seller_id});
  const response = axiosInstance.get(url, {params});
  return response;
};

export const createShipmentOrder = {
  apiKey: ['create-shipment-order'],
  url: '/api/v1/shipping_aggregator/orders'
};
export const bulkCreateShipmentOrder = {
  apiKey: ['bulk-create-shipment-order'],
  url: '/api/v1/shipping_aggregator/orders/bulk'
};

export const addPickupLocation = {
  apiKey: ['create-shipment-order'],
  url: '/api/v1/oms/sellers/:sellerId/pickup_locations'
};

export const addNewCustomer = {
  apiKey: ['create-customer'],
  url: '/api/v1/cs/sellers/479/customers'
};

export const getAllAwb = {
  apiKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_AWB],
  url: '/api/v1/shipping_aggregator/orders/1/shipments/1/awb'
};

//async get seller SKUs
export const fetchOrderSkus = async (search: any, _loadedOptions: any, {sellerId, params: restParams = {}, ...restAdditional}: any) => {
  try {
    const params = { search_value: search, ...restParams };
    const response = await axiosInstance.get(`/api/v1/products/sellers/${sellerId}/order_skus?${paramsSerializer({ ...params })}`);

    const data = response?.data?.data ?? [];

    return {
      options: data,
      hasMore: restParams.page < response?.data?.meta?.last_page,
      additional: {
        params: {
          ...restParams,
          page: response?.data?.meta?.current_page + 1,
        },
        ...restAdditional
      },
    };
  } catch (error) {
    console.warn(error);
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
      }
    };
  }
};

export const fetchPickupAddress = async (search: string, _loadedOptions: unknown, { page = 1, seller_id = undefined }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/oms/sellers/${seller_id}/pickup_locations`, { params: { search_query: search || undefined, page } });
    const data = response?.data;
    const modiFiedData = data?.data?.map((ele: any) => ({
      ...ele,
      value: ele.id,
      label: ele.name,
      phone_number: ele.phone,
    }));

    return {
      options: modiFiedData,
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: data?.meta?.current_page + 1
      }
    };
  } catch (error) {
    console.warn(error);
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    };
  }
};

export const fetchCustomers = async (search: string, _loadedOptions: unknown, {sellerId, page = 1 }: {sellerId: string | number, page: number}) => {
  try {
    const response = await axiosInstance.get(`/api/v1/cs/sellers/${sellerId}/customers`, { params: { query: search, page } });
    const data = response?.data;
    const modiFiedData = data?.data?.map((ele: any) => ({
      ...ele,
      value: ele.id,
      label: `${ele.first_name} ${ele.last_name}`,
    }));

    return {
      options: modiFiedData,
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: data?.meta?.current_page + 1,
        sellerId
      }
    };
  } catch (error) {
    console.warn(error);
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    };
  }
};

export const fetchShippingPartners = async (search: string, _loadedOptions: unknown, { page = 1 }: {sellerId: string | number, page: number}) => {
  try {
    const response = await axiosInstance.get('/api/v1/shipping/shipping_partners', { params: { name_filter: search, page } });
    const data = response?.data;
    const modiFiedData = data?.data?.map((ele: any) => ({
      ...ele,
      id: ele.id,
      value: ele.id,
      logo:  ele.logo,
      label: ele.name
    }));

    return {
      options: modiFiedData,
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: data?.meta?.current_page + 1,
      }
    };
  } catch (error) {
    console.warn(error);
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    };
  }
};

export const fetchSingleOrder = ({ order_id }: { order_id: string }) => {
  const url = getApiUrl(`/api/v1/shipping_aggregator/orders/${order_id}`);
  const response = axiosInstance.get(url);
  return response;
};

// export const fetchShipmentOrders = ({ params }: { params: {entity_id?: string, entity_name?: string} }) => {
//   // const url = getApiUrl(API_ENDPOINTS.COMMON.GET_CREATE_SHIPMENT_DETAILS);
//   const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzI3OTUzNTYsImp0aSI6IjA0OTA3MTIxLTZmZGYtNDI1Yy1hMjQ3LTk1YzRkNjEyYjdiNCIsInVzZXJfZGV0YWlscyI6eyJVc2VySUQiOiI3MjAiLCJVc2VyVHlwZSI6MiwiVXNlck5hbWUiOiJCaGF2eWUiLCJVc2VyRW1haWwiOiJiaGF2eWUuZ29lbDIwMDJAZ21haWwuY29tIiwiVGVuYW50SUQiOiIxIiwiVGVuYW50VHlwZSI6MiwiVGVuYW50TmFtZSI6ImNvbXBvc2VkLWxvb3NlbHktZ2hvdWwiLCJVc2VyVGltZVpvbmUiOiJBc2lhL0tvbGthdGEiLCJUZW5hbnREb21haW4iOiJjb21wb3NlZC1sb29zZWx5LWdob3VsLm5ncm9rLWZyZWUuYXBwIn0sInVzZXJfcGxhbiI6eyJpc19vbXNfZW5hYmxlZCI6dHJ1ZSwiaXNfZmxlZXRfZW5hYmxlZCI6dHJ1ZSwiaXNfd21zX2VuYWJsZWQiOnRydWUsImlzX2V4dGVybmFsX3dtc19lbmFibGVkIjpmYWxzZSwiaXNfcG9zX2VuYWJsZWQiOmZhbHNlfX0.seCJznUvoRrKoPmeuukCzkaP_1qvu4Lv4MhSaxkXfOTvjChw_ArO7IlZOMXED7p4Ft4ZjMsAL85jEbA--iVsMavg5lAlV-tvl6-ftacObonWQiGac6pXOJH9RtMwbfVO5_uc_XaX64S3aSgO3OoPNHUT01GsrGSLyHcQsejWppbCiGe-3HT0nqM4c4KBD6HdOT4k-iSBdCQtUEuhFuzfEWxq3cGBaRcvAWk3SDhCzfEqaQ3c4qZDo2MhL1MYiMcHSq5lK3NZrKBS7BFroALsf95pcqvPsWUjakhy6pvZ2yK_b7pySWj8crbQltOqhy2EvC0VmpgwoRaaA_As9M9uYnX1zUBCXkcbmkc43empJ3ZgxALWgKWs5XBt6veuLFFl5NFTy4QI9en_L_-kxyNQwygYvAW0JLZrNEiqbAOjUwbPcuIzFkEGRO4aIWXM8z04WngJDglhxy-MaS0jAh7XXIWzMozoAFYiEdYKP31wcUXULS4dBRCZ4xZZtu04jqMDH6-619BBg2ZDR35AsHujClqqKRT13j3esYu7mt91YvW6I8OwDbfNdpc9TkNULyBxIoXw6xSvb3KZKEoGPg6H28VIr-bezGqV8QCPlVPQda-RuTtpPxnTHMOxmPiyccz9LWdVB6bynxULBWz4yxqjPf3ByMajgm3EJCzthBONFdk';
//   const response = axios.get('https://composed-loosely-ghoul.ngrok-free.app/api/v1/shipping_aggregator/orders', {
//     params, headers: {
//       'ngrok-skip-browser-warning': '73878377',
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     }});
//   return response;
// };

export const fetchShipmentOrders = ({params}: any) => {
  console.log({params});
  const url = getApiUrl('/api/v1/shipping_aggregator/orders');
  const response = axiosInstance.get(url, {params});
  return response;
};
export const fetchOrderLog = (params: {actionable_type:string, actionable_id:string}) => {
  const url = getApiUrl('/api/v1/shipping_aggregator/action_logs');
  console.log({url});
  const response = axiosInstance.get(url, {params});
  return response;
};

export const fetchShipmentPackages = ({params}: any) => {
  const url = getApiUrl('/api/v1/shipping_aggregator/order_packages');
  const response = axiosInstance.get(url, {params});
  return response;
};
export const fetchSingleShipmentPackage = ({ params }: any) => {
  const {id} = params;
  const response = axiosInstance.get(`/api/v1/shipping_aggregator/order_packages/${id}`);
  return response;
};

export const fetchSingleShipmentOrder = ({ id, params }: { id: string , params:any} ) => {
  // const url = getApiUrl(API_ENDPOINTS.COMMON.GET_CREATE_SHIPMENT_DETAILS);
  const response = axiosInstance.get(`/api/v1/shipping_aggregator/orders/${id}`, {params});
  return response;
};

export const fetchSuggestedShippingAccounts = ({ body={} }: { body: object }) => {
  const response = axiosInstance.put('/api/v1/shipping_aggregator/order_packages/suggest_shipping_accounts', body);
  return response;
};

export const shipmentCreate = ({ order_id, body={} }: { order_id: string, body?: object }) => {
  const response = axiosInstance.post(`/api/v1/shipping_aggregator/orders/${order_id}/shipments`, body);
  return response;
};

// export const fetchSingleOrder = ({ order_id, params={} }: { order_id: string, params?: object }) => {
//   // const url = getApiUrl('/api/v1/shipping_aggregator/orders');
//   const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzM3NDc5MzEsImp0aSI6IjFjYzZlMzNmLWFlYTItNGI1NS04Y2Q1LTU1ZWU5MDE5MjcxMiIsInVzZXJfZGV0YWlscyI6eyJVc2VySUQiOiI3MjAiLCJVc2VyVHlwZSI6MiwiVXNlck5hbWUiOiJCaGF2eWUiLCJVc2VyRW1haWwiOiJiaGF2eWUuZ29lbDIwMDJAZ21haWwuY29tIiwiVGVuYW50SUQiOiIxIiwiVGVuYW50VHlwZSI6MiwiVGVuYW50TmFtZSI6ImNvbXBvc2VkLWxvb3NlbHktZ2hvdWwiLCJVc2VyVGltZVpvbmUiOiJBc2lhL0tvbGthdGEiLCJUZW5hbnREb21haW4iOiJjb21wb3NlZC1sb29zZWx5LWdob3VsLm5ncm9rLWZyZWUuYXBwIn0sInVzZXJfcGxhbiI6eyJpc19vbXNfZW5hYmxlZCI6dHJ1ZSwiaXNfZmxlZXRfZW5hYmxlZCI6dHJ1ZSwiaXNfd21zX2VuYWJsZWQiOnRydWUsImlzX2V4dGVybmFsX3dtc19lbmFibGVkIjpmYWxzZSwiaXNfcG9zX2VuYWJsZWQiOmZhbHNlfX0.FlPoMMRZk7r76K208724DdGkBsLmIVwQn4CTlWZzg5WO63fH0cPLt7KNwBlAmPwaKgsCJqJTM-IA4bLQk66KAF1I0qQtifgymj7-QsPpZVjWCGDJkHCWzFzdoRxMCcDjwS681anqvCto-2QXB-r8fsACku6BOU5MOrfw600a_EnTawN2b4m3jFrZkyOL3udW5D7DokG12AZhfgAoP2CG4oVesC_e-f83s3rUVFUL_n85KB9IQEjQHRTg5dW-72mbXpAQGJlVEsZ2Xk-HuqD2RKc0hfUE98NUmii6_YcRZqhDGeXkHV9haeUs846SbZZfeCmJ0LRv2jlIvB-6YYpem1_ndR3gJq27X1v5nsftFnXInCUNIwSS2Zubo7t3P4pmUdfypsaPyuK4tI4Lpj0h1AVE0b8DjKdZ28YaAXJzsoU1WZtl3plEOdQ89Frus_PEtPPgLW6H9BMmnYNJMegqrV8zN8uososRIAwyRIRdxi4KqejI0zXvuGSP5OCNwjHUnOD413DRL-vWOWub5cdZdGZ7KXoq_wsvAPxzP2K1CFfPl0rVaPtmWUUCYkSNdottZOOJ1Tm5j4Pso8EZTaZ1fy6n096qKnujqPQAjem4swAZ2JpEPg6ElVG7CMvczC81QooV-ut8mP_SWU5PSh1p5P--xjs2zeKBb27SK1nINsM';
//   const response = axios.get(`https://composed-loosely-ghoul.ngrok-free.app/api/v1/shipping_aggregator/orders/${'6754233afc36cbdc715cf8b9' || order_id}`, {params, headers: {
//     'ngrok-skip-browser-warning': '73878377',
//     Authorization: `Bearer ${token}`,
//     'Content-Type': 'application/json',
//   }});
//   return response;
// };

export const fetchAllShipment = ({params}: any) => {
  const url = getApiUrl('/api/v1/shipping_aggregator/orders/shipments');
  const response = axiosInstance.get(url, {params});
  return response;
};

export const fetchSingleShipment = ({ params }: { params: {entity_id?: string, entity_name?: string, id: string} }) => {
  // const url = getApiUrl(API_ENDPOINTS.COMMON.GET_CREATE_SHIPMENT_DETAILS);
  const response = axiosInstance.get(`/api/v1/shipping_aggregator/orders/shipments/${params.id}`);
  return response;
};

// export const fetchAllShipment = ({ params }: any) => {
//   // const url = getApiUrl(API_ENDPOINTS.COMMON.GET_CREATE_SHIPMENT_DETAILS);
//   const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQwMDAxNDUsImp0aSI6IjY4OWEzZTZjLWRkYjgtNGViYS1iMjAxLTBhZTAwOTI3ODdiMyIsInVzZXJfZGV0YWlscyI6eyJVc2VySUQiOiI3MjAiLCJVc2VyVHlwZSI6MiwiVXNlck5hbWUiOiJCaGF2eWUiLCJVc2VyRW1haWwiOiJiaGF2eWUuZ29lbDIwMDJAZ21haWwuY29tIiwiVGVuYW50SUQiOiIxIiwiVGVuYW50VHlwZSI6MiwiVGVuYW50TmFtZSI6ImNvbXBvc2VkLWxvb3NlbHktZ2hvdWwiLCJVc2VyVGltZVpvbmUiOiJBc2lhL0tvbGthdGEiLCJUZW5hbnREb21haW4iOiJjb21wb3NlZC1sb29zZWx5LWdob3VsLm5ncm9rLWZyZWUuYXBwIn0sInVzZXJfcGxhbiI6eyJpc19vbXNfZW5hYmxlZCI6dHJ1ZSwiaXNfZmxlZXRfZW5hYmxlZCI6dHJ1ZSwiaXNfd21zX2VuYWJsZWQiOnRydWUsImlzX2V4dGVybmFsX3dtc19lbmFibGVkIjpmYWxzZSwiaXNfcG9zX2VuYWJsZWQiOmZhbHNlfX0.hc7WjptkJyI6hBOg4_4k6RFpgi2AEsgRcE1_a9F2YI9YuFoLWl1Cpa108Aw2FWPDbXNI3JgQ-NGxGkkB4uCxCsdxOFq3JQGAOCrTFz7RfbYszgOThopzGgJM673Yjmj9CQL1TvV_Jdn5u-Mf-zf1E5X_2ehijFvio0jhSRvky4WaKas3ivM6AmCp-4q7Ao2s0-V-YAHsVP5Zg4sWh8M-SnK0h8XBXzeRgrfQ-IIBkfdADM-642urzBriE4ahKAQGuzpxaGiWEhSq88BDKrWb3jAxIfaBly8X_hoM4CBPm6_jPqHeN6wQMQgehM9PVMa8WJVPH70Sso5KUEAtST_bKT6gnR9byzmjxbB5X0nYHjxqP2Ry4v6C_kkAaeDKT3rCuB-wilE9x8Wah9kC5FU-AM5G0b3vgrwsC0ISIvr9aUkZB1NKM-TWCodxmbTBVvGQMP5ZVGx7UbiJEujD-OEd2cT8zJ9lWPRZGz1a9_hb6o3oislvN2NbXKSuu_Mo7Dz8tGmYAk7BfnwKn-FAaGlBverzzIsBVbI2s7VqI49f3MCEwPSPQoM2R_kUs9C_htsO3TAiMVqP_8b-siwHRbEFHe4_85z3Lr2KNkf-TjNSkGIs0QgcKH_WLO-yLlbYpMpyEkf_uuYzFPIEO8pHma1vW7FeYsKdJchRidQCGX2zE6o';
//   const response = axios.get('https://composed-loosely-ghoul.ngrok-free.app/api/v1/shipping_aggregator/orders/shipments', {
//     params, headers: {
//       'ngrok-skip-browser-warning': '73878377',
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     }});
//   return response;
// };

// export const fetchSingleShipment = ({ params }: any) => {
//   // const url = getApiUrl(API_ENDPOINTS.COMMON.GET_CREATE_SHIPMENT_DETAILS);
//   const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQwMDAxNDUsImp0aSI6IjY4OWEzZTZjLWRkYjgtNGViYS1iMjAxLTBhZTAwOTI3ODdiMyIsInVzZXJfZGV0YWlscyI6eyJVc2VySUQiOiI3MjAiLCJVc2VyVHlwZSI6MiwiVXNlck5hbWUiOiJCaGF2eWUiLCJVc2VyRW1haWwiOiJiaGF2eWUuZ29lbDIwMDJAZ21haWwuY29tIiwiVGVuYW50SUQiOiIxIiwiVGVuYW50VHlwZSI6MiwiVGVuYW50TmFtZSI6ImNvbXBvc2VkLWxvb3NlbHktZ2hvdWwiLCJVc2VyVGltZVpvbmUiOiJBc2lhL0tvbGthdGEiLCJUZW5hbnREb21haW4iOiJjb21wb3NlZC1sb29zZWx5LWdob3VsLm5ncm9rLWZyZWUuYXBwIn0sInVzZXJfcGxhbiI6eyJpc19vbXNfZW5hYmxlZCI6dHJ1ZSwiaXNfZmxlZXRfZW5hYmxlZCI6dHJ1ZSwiaXNfd21zX2VuYWJsZWQiOnRydWUsImlzX2V4dGVybmFsX3dtc19lbmFibGVkIjpmYWxzZSwiaXNfcG9zX2VuYWJsZWQiOmZhbHNlfX0.hc7WjptkJyI6hBOg4_4k6RFpgi2AEsgRcE1_a9F2YI9YuFoLWl1Cpa108Aw2FWPDbXNI3JgQ-NGxGkkB4uCxCsdxOFq3JQGAOCrTFz7RfbYszgOThopzGgJM673Yjmj9CQL1TvV_Jdn5u-Mf-zf1E5X_2ehijFvio0jhSRvky4WaKas3ivM6AmCp-4q7Ao2s0-V-YAHsVP5Zg4sWh8M-SnK0h8XBXzeRgrfQ-IIBkfdADM-642urzBriE4ahKAQGuzpxaGiWEhSq88BDKrWb3jAxIfaBly8X_hoM4CBPm6_jPqHeN6wQMQgehM9PVMa8WJVPH70Sso5KUEAtST_bKT6gnR9byzmjxbB5X0nYHjxqP2Ry4v6C_kkAaeDKT3rCuB-wilE9x8Wah9kC5FU-AM5G0b3vgrwsC0ISIvr9aUkZB1NKM-TWCodxmbTBVvGQMP5ZVGx7UbiJEujD-OEd2cT8zJ9lWPRZGz1a9_hb6o3oislvN2NbXKSuu_Mo7Dz8tGmYAk7BfnwKn-FAaGlBverzzIsBVbI2s7VqI49f3MCEwPSPQoM2R_kUs9C_htsO3TAiMVqP_8b-siwHRbEFHe4_85z3Lr2KNkf-TjNSkGIs0QgcKH_WLO-yLlbYpMpyEkf_uuYzFPIEO8pHma1vW7FeYsKdJchRidQCGX2zE6o';
//   const response = axios.get(`https://composed-loosely-ghoul.ngrok-free.app/api/v1/shipping_aggregator/orders/shipments/${params.id}`, {
//     headers: {
//       'ngrok-skip-browser-warning': '73878377',
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     }});
//   return response;
// };