import { FILE_TYPES } from '@src/App.constants';

export const ORDER_TAG_CONDITIONS = {
  ORDER_TOTAL: { name: 'Order Total', key: 'order_total'},
  AMOUNT_DUE: { name: 'Amount Due', key: 'amount_due'},
  SHIMMING_PRICE: { name: 'Shipping Price', key: 'shipping_price'},
  ORIGIN_CITY: { name: 'origin city', key: 'origin_city'},
  ORIGIN_COUNTRY: { name: 'origin country', key: 'origin_country'},
  ORDER_WEIGHT: { name: 'order weight', key: 'order_weight'},
  NUMBER_OF_PACKAGES: { name: 'number of packages', key: 'number_of_packages'},
  DESTINATION_COUNTRY: { name: 'Order Total', key: 'destination_country'},
  PAYMENT_METHOD: { name: 'destination country', key: 'payment_method'},
  DESTINATION_CITY: { name: 'destination city', key: 'destination_city'},
  TOTAL_ITEM_COUNT: { name: 'total item count', key: 'total_item_count'},
  TOTAL_SKU_COUNT: { name: 'total sku count', key: 'total_sku_count'},
  ORDER_TYPE: { name: 'order type', key: 'order_type'},
  ORDER_SOURCE: { name: 'order source', key: 'order_source'},
  SALES_CHANNEL: { name: 'sales channel', key: 'sales_channel'},
  SELLER: { name: 'seller', key: 'seller'},
  ORDER_CREATED_AT: { name: 'order created at', key: 'order_created_at'}
}

export const hubFilterCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#d8d6de !important' : '#d8d6de !important',
    minHeight: '30px',
    maxHeight: '30px',
    width: '200px',
    fontSize: '12px',
    paddingTop: '0px',
    '&:hover': {
      borderColor: '#5468FA !important',
      cursor: 'pointer'
    }
  }),
  singleValue: provided => (
    {
      ...provided,
      color: 'black !important'
    }
  ),
  valueContainer: provided => ({
    ...provided,
    overflow: 'visible'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? '-50%' : '14%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
    backgroundColor: 'white'
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 0,
    fontSize: 2
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0,
    marginInlineEnd: '10px',
    width: '20px'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuList: provided => ({
    ...provided,
    color: '#222222'
  })
}

export const NO_ACTION_ROLES = ['Picker', 'Packer', 'Dispatcher', 'Dispatcher Supervisor', 'Picker and Shipper']

export const getAutomationRulesTabs = (omit = false) => ({
  ELIGIBILITY: { id: 'eligibility', name: 'Forward' },
  RETURN_ELIGIBILITY: { id: 'return_eligibility', name: 'Return' },
  PRIORITY: { id: 'priority', name: 'Priority' },
  SHIPPING_AGGREGATOR: { id: 'shipping_aggregator', name: 'OmniShip', omit: omit },
});


export const ELIGIBILITY_DROPDOWN_OPTIONS = [
  { label: 'Eligible', value: 'eligible' },
  { label: 'Not Eligible', value: 'not_eligible' }
    
]

export const SHIPMENT_TYPE_DROPDOWN_OPTIONS = [
  { label: 'Forward Shipment', value: 'forward_shipment' },
  { label: 'Reverse Shipment', value: 'reverse_shipment' }
    
]
export const PRINT_LAYOUT = {
  THERMAL: {id:1, name:'Thermal', value:'thermal'},
  A4:{id:2, name:'A4', value:'a4'}
}

export const RULE_DATATYPE = {
  STRING: 'string',
  STRING_ARRAY: 'array_string',
  STRING_INTEGER: 'array_integer'
}

export const RULES_INPUT_TYPES = {
  INPUTBOX: 'inputbox',
  DROPDOWN: 'dropdown',
  API: 'api',
  DATEPICKER: 'datepicker'
}

export const FIRST_INDEX = 0

export const BULK_UPLOAD_SETTING_OPTIONS = {
  currency: [],
  unit_weight: []
}
export const ORDER_TAG_CONDITION_DATA_TYPES =  {
  ARRAY_INTEGER: {key: 'array_integer'},
  ARRAY_STRING: {key: 'array_string'},
  INTEGER: {key: 'integer'}
}

export const STORE_CONFIG_FIELDS = {
  SELLER: {value:'seller'},
  HUB: {value:'hub'},
  CUSTOM: {value:'custom'},
  DEFAULT: {value:'default'}
}

export const INVOICE_SETTING_CONFIG = {
  SHOW_QR_CODE : {value: 'show_qr_code', label: 'Show QR Code'}
}

export const ELIGIBILITY_RULE_OPTIONS = {
  SELLER: { label: 'SELLER', value: 'seller' }
}

export const CITIES_TYPES = {
  UNMAPPED: { label: 'Unmapped', value: 'unmapped' },
  MAPPED: { label: 'Mapped', value: 'mapped' }
}

export const ADDITIONAL_FILTER_DROPDOWN_STYLE_FOR_SKU_CONFIGURATIONS = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}

export const HUB_TYPES_SKU_CONFIG = {
  ALL: { label: 'All', value: 'all' },
  WAREHOUSE: { label: 'Warehouse', value: 'warehouse' },
  RETAIL: { label: 'Retail', value: 'retail' },
  DARKSTORE: { label: 'Dark Store', value: 'dark_store' }
}  

export const SKU_CONFIG_UPLOAD_TYPE = {
  sheetUrl: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/warehouse_sku/Sku+Configuration+-+Sheet1.csv',
  title: '(Acceptable file formats is .csv and maximum 5000 rows can be uploaded).',
  fileFormat: { [FILE_TYPES.CSV.type]: FILE_TYPES.CSV.extensions}
}
export const PICKING_WAVE_UPLOAD_TYPE = {
  sheetUrl:'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/waves/Picking_wave_sheet.csv',
  title: '(Acceptable file format is .csv and maximum 500 rows can be uploaded)',
  fileFormat: { [FILE_TYPES.CSV.type]: FILE_TYPES.CSV.extensions}
}
export const PICKING_WAVE = {
  uploadIdName:'bulk_config_for_picking_wave',
  apiKey: ['order-picking-wave'],
  fileS3Service: 'picking-wave-bulk-upload',
  fileS3UseCase:'picking-config'
}
export const PICKING_TYPE = {
  SINGLE: 'Apply Filters',
  BULK:'Upload Order Sheet'
}
export const BARCODE_CONFIG_STATUS = {
  non_serialised : { id: 'non_serialised', name: 'Non Serialised'},
  serialised : { id: 'serialised', name: 'Serialised'}
}

export const SKU_CONFIG_STATUS = {
  CONFIGURED: {label:'Configured', value:'configured'},
  NOT_CONFIGURED: {label:'Not Configured', value:'un_configured'}
}

export const OMS_CONFIGURATION_TYPE = {
  MOVE_TO_ON_HOLD: 'move_to_on_hold',
  HUB_ASSIGNMENT: 'hub_assignment',
  RETURN_ORDER_CREATION: 'return_order_creation',
  ORDER_NOTIFICATION: 'order_notification',
  SHIPMENT_ORDER_DEFAULT_HUB: 'shipment_order_default_hub',
  REASONS: 'reasons',
  DEFAULT_CURRENCY: 'default_currency',
  SHIPMENT_ORDER_SHIPPING_AUTOMATION:'shipment_order_shipping_automation',
}

export const OMS_SETTING_ENTITY_TYPE = {
  TENANT: 'tenant',
  SELLER: 'seller'
}

export const USER_SETTINGS_FLOATING_DROPDOWNS = {
  TIME_ZONE: 'time_zone',
  ROLE: 'role',
  HUB: 'hub',
  SELLER: 'seller'
}
 
// To introduce a new reason, please add it here in this config and also include it in the default value of the tenant settings form.
export const OMS_REASON_CONFIG = Object.freeze({
  cancel_order: {
    title: 'Order Cancellation',
    subtitle: 'Reasons for cancelling orders.',
    key:'cancel_order'
  },
  customer_return: {
    title: 'Customer Return Order',
    subtitle: 'Reasons for customer-initiated returns.',
    key:'customer_return'
  },
  qc_fail: {
    title: 'Return Order Processing',
    subtitle: 'Reasons for marking return order items as QC failed in location inventory off hubs.',
    key:'qc_fail'
  },
  hub_reassignment: {
    title: 'Reassigning Order to Another Hub',
    subtitle: 'Reasons for reassigning an order to another hub.',
    key:'hub_reassignment'
  },
  cancel_shipment: {
    title: 'Shipment Cancellation',
    subtitle: 'Reasons for cancelling shipment.',
    key:'cancel_shipment'
  },
  reorder: {
    title: 'Reorder Creation',
    subtitle: 'Reasons for reordering an existing order.',
    key:'reorder'
  },
  refund_adjustment: {
    title: 'Refund Adjustment',
    subtitle: 'Reasons for adjusting the refund amount of a return order',
    key:'refund_adjustment'
  },
  reject_stock_transfer_request: {
    title: 'Stock Transfer Request Cancellation',
    subtitle: 'Reasons for Cancelling a Stock Transfer Request',
    key:'reject_stock_transfer_request'
  }
})

export const OMS_REASON_MAX_LENGTH = 30

export const OMNISHIP_API_KEYS = {
  GET_RULES: 'omniship/get_rules',
  GET_SINGLE_RULE: 'omniship/get_single_rule',
  CREATE_RULE: 'omniship/create_rule',
  UPDATE_RULE: 'omniship/update_rule',
  DELETE_RULE: 'omniship/delete_rule',
  GET_SHIPPING_ACCOUNTS: 'omniship/get_shipping_accounts',
  GET_RULE_CONDITIONS: 'omniship/get_rule_conditions',
  GET_RULE_PRECEDENCE: 'omniship/get_rule_precedence',
  UPDATE_RULE_PRECEDENCE: 'omniship/update_rule_precedence',
  GET_SINGLE_PRIORITY_RULE: 'omniship/get_single_priority_rule'
}

export const CREATE_USER_INPUT_FIELDS = {
  IS_ACTIVE: 'is_active',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'customer_phone_number',
  EMPLOYEE_ID: 'employee_id',
  TIMEZONE: 'timezone',
  ROLE_IDS: 'role_ids',
  ALL_HUBS: 'all_hubs',
  HUB_IDS: 'hub_ids',
  ALL_SELLERS: 'all_sellers',
  SELLER_IDS: 'seller_ids',
  USER_EXPIRY_DATE: 'user_expiry_date',
  IS_LMD_SELLER: 'restricted_third_party_logistics',
}

export function convertToLocalIsoString(input) {
  // Parse the input date-time string into a JavaScript Date object
  const [datePart, timePart] = input.split('T');
  const [day, month, year] = datePart.split('-').map(Number);

  // Directly use the provided time without any operations
  const [hours, minutes] = timePart.split(':').map(Number);

  // Create a Date object for the given date and time
  const date = new Date(year, month - 1, day, hours, minutes);

  // Format the local timezone offset (e.g., "+05:30")
  const offsetMinutes = date.getTimezoneOffset();
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMins = Math.abs(offsetMinutes) % 60;
  const offsetSign = offsetMinutes <= 0 ? '+' : '-';
  const formattedOffset = `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMins).padStart(2, '0')}`;

  // Construct the result manually to keep the original time
  const result = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00${formattedOffset}`;

  return result;
}


export function parseUtcDateTime(input) {
  // Parse the input date-time string
  const date = new Date(input);

  // Extract the day, month, and year
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getUTCFullYear();

  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;

  // Extract hours and minutes
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Format the time as HH:mm
  const formattedTime = `${hours}:${minutes}`;

  // Return the formatted date and time as an object
  return {
    date: formattedDate,
    time: formattedTime,
  };
}
