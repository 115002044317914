import warningImage from '@src/assets/images/catalog/bundlesku/warning-icon.svg'
import classNames from 'classnames'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalFooter, Modal as ReactstrapModal } from 'reactstrap'
import Button from '../button'
import './modal.scss'

const Modal = ({
  closeModalHandler,
  primaryButtonHandler,
  secondaryButtonHandler,
  title,
  content,
  primaryButtonText,
  secondaryButtonText,
  isPrimaryButtonVisible = true,
  isSecondaryButtonVisible = true,
  isButtonVerticallyAlign = false,
  icon,
  hasComponentSideContentTranslation = true,
  customImage,
  modalClassNames,
  modalFooterClass,
  closeButtonHidden,
  isSecondaryDisabled = false,
  customIcon = null,
  secondaryButtonProps = {},
  primaryButtonProps = {},
  modalLoadingComponent = null,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <ReactstrapModal
      className={`warning-modal-with-image max-width-600 ${modalClassNames}`}
      modalClassName='warning-seller-change-modal-classname'
      centered
      toggle={closeModalHandler}
      {...rest}
    >
      {modalLoadingComponent}
      {!modalLoadingComponent && <>
        <div className='warning-modal-header'>
          <div className='modal-title m-auto'>
            {!customIcon && <img src={customImage || warningImage} width={164} height={164} alt='Modal Image' />}
            {customIcon}
          </div>
          {!closeButtonHidden && <span
            className='bg-white cursor-pointer'
            onClick={closeModalHandler}
          >
            <X size={18} className='text-dark-6' />
          </span>}
        </div>
        <ModalBody className='d-flex flex-column gap-16px justify-content-center1 mt-0 mb-0 color-black'>
          <div className='txt-h1-sb text-dark'>
            {t(title)}
          </div>
          <div className='text-dark txt-body-rg'>
            {hasComponentSideContentTranslation ? t(content) : content}
          </div>
        </ModalBody>
        <ModalFooter className={classNames('d-flex gap-16px px-2 modal-footer', modalFooterClass, {
          'justify-content-end align-items-center': !isButtonVerticallyAlign,
          'flex-column w-100': isButtonVerticallyAlign
        })}>
          {isPrimaryButtonVisible && <Button className={classNames({
            'w-100 justify-content-center txt-body-md': isButtonVerticallyAlign
          })}  ofStyle={isButtonVerticallyAlign ? 'outlined' : 'noBackground'} onClick={primaryButtonHandler} {...primaryButtonProps}>
            {t(primaryButtonText)} { icon && icon}
          </Button>}
          {isSecondaryButtonVisible && <Button className={classNames({
            'w-100 d-flex justify-content-center txt-body-md': isButtonVerticallyAlign
          })} ofStyle={isButtonVerticallyAlign ? 'outlined' : 'filled'} onClick={secondaryButtonHandler} disabled={isSecondaryDisabled} {...secondaryButtonProps}>{t(secondaryButtonText)} { icon && icon}</Button>}
        </ModalFooter>
      </>}
    </ReactstrapModal>
  )
}

export default Modal
