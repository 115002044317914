import { API_ENDPOINTS } from '@src/api.urls';

export const PUBLIC_TRACKING_PAGE_API_KEYS = {
  GET_SHIPMENT_PACKAGES: 'get-shipment-packages',
  GET_SHIPMENT_PACKAGE_LOGS: 'get-shipment-package-logs',
};

export const getShipmentPackages = {
  apiKey: [PUBLIC_TRACKING_PAGE_API_KEYS.GET_SHIPMENT_PACKAGES],
  url: API_ENDPOINTS.OMNISHIP.GET_SHIPMENT_PACKAGES_FOR_TRACKING
};

export const getShipmentPackageLogs = {
  apiKey: [PUBLIC_TRACKING_PAGE_API_KEYS.GET_SHIPMENT_PACKAGE_LOGS],
  url: API_ENDPOINTS.OMNISHIP.GET_SHIPMENT_PACKAGE_LOGS_FOR_TRACKING
};