import Edit from '@src/assets/images/icons/Edit';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type iDetailsCard = {
  title: string
  children: ReactNode
  isEditable?: boolean
  handleEdit?: () => void
  childrenClassNames?: string
  containerClassNames?: string
  titleClassNames?: string
}
const DetailsCard = ({ title, childrenClassNames = '', containerClassNames = '', titleClassNames='', children, isEditable = false, handleEdit = () => {} }: iDetailsCard) => {
  const {t} = useTranslation();
  return (
    <div className={`d-flex w-100 flex-column overflow-hidden rounded-12px border h-100 ${containerClassNames}`}>
      <div className='d-flex align-items-center justify-content-between rounded-t-12px bg-primary-lighter px-16px py-12px txt-h3-md text-dark'>
        <div className={titleClassNames}>{t(title)}</div>
        {isEditable && <div onClick={handleEdit} className='cursor-pointer'><Edit width={18} height={18}/></div>}
      </div>
      <div className={`p-16px ${childrenClassNames}`}>
        {children}
      </div>
    </div>
  );
};

export default DetailsCard;