import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

/**
 * Adds serial numbers to elements in an array nested within an object, based on pagination metadata.
 * @param {Object} response - The input object containing response data.
 * @param {Array} param0.path - It denotes the target path where an array of response needs to have serial numbers assigned.
*/

const addSerialNumber = ({ response, path }) => {
  const meta = response.meta
  const targetData = path.reduce((current, key) => {
    if (current && typeof current === 'object') {
      return current[key]
    } else {
      return null
    }
  }, response)

  if (!targetData) return null

  const modifiedRes = targetData.map((el, idx) => ({
    ...el,
    serialNumber: ((meta.current_page - 1) * meta.per_page) + idx + 1
  }))

  for (let i = 0; i < path.length - 1; i++) {
    if (!response.hasOwnProperty(path[i])) {
      response[path[i]] = {}
    }
    response = response[path[i]]
  }
  response[path[path.length - 1]] = modifiedRes
}


/**
 * Custom hook for making API requests using Tanstack React Query
 * 
 * @param {object} config - Configuration object for the API request
 * @param {boolean} config.isMutation - Flag indicating if the request is a mutation
 * @param {string[]} config.apiKey - Unique identifier for the API request (used as cache key)
 * @param {function} config.apiFn - Function that defines the API request logic
 * @param {function} [config.onSuccess] - Callback function to be executed upon successful data fetching (only for queries)
 * 
 * @returns {object} - Object containing the data fetching result from React Query (either mutation or query response)
 */
export const useApi = (config) => {

  /**
   * Destructure configuration object
   */
  const { isMutation, apiKey, apiFn, isSerializedData = false, serializationPath = 'data', select, ...apiConfig } = config

  /**
   * The 'isSerializedData' flag should be set to 'true' when the response requires a serial number key.
   * 'serializationPath' can be either a string or an array of strings.
      It denotes the target path where an array of objects needs to have serial numbers assigned.
  */

  /**
   * Determine appropriate React Query hook based on mutation flag
   */
  const apiFetcher = isMutation ? useMutation : useQuery

  const selectFn = useCallback((data) => {
    const response = data

    if (isSerializedData) {
      if (Array.isArray(serializationPath)) {
        serializationPath.forEach((path) => {
          const pathKeys = path.split('.')
          addSerialNumber({ response, path: pathKeys })
        })
      } else {
        const pathKeys = serializationPath.split('.')
        addSerialNumber({ response, path: pathKeys })
      }
    }

    if (select) {
      const finalRes = select(response)
      return finalRes
    } else {
      return response
    }
  }, [select, isSerializedData, serializationPath])

  /**
   * Define the API fetching function using the provided apiFn
   */
  const data = apiFetcher({
    ...apiConfig,
    /**
     * Set cache key based on mutation or query
     */
    [isMutation ? 'mutationKey' : 'queryKey']: apiKey,
    [isMutation ? 'mutationFn' : 'queryFn']: async (...props) => {
      const response = await apiFn(...props)
      if (!isMutation && apiConfig.onSuccess) apiConfig.onSuccess(response.data)
      return response.data
    },
    select: selectFn
  })
  return data
}