import InputField from '@src/@core/components/ui/input-field'
import Select from '@src/@core/components/ui/select'
import { DATA_TYPE } from '@src/App.constants'
import { Controller } from 'react-hook-form'
import { INPUT_TYPES_MAP } from '../rule.constant'
import { getComponentProps } from '../rule.utils'
import DatePicker from './DatePicker'
import MultiInputField from './MultiInputField'
import RangeInputGroup from './RangeInputGroup'

/**
 * Renders a component based on the input type.
 *
 * @param {object} props - The component props.
 * @param {string} props.name - The name of the component.
 * @param {object} props.formObject - The form object.
 * @param {number} props.index - The index of the component.
 * @param {object} props.selectedCondition - The selected condition.
 * @param {object} props.selectedOperator - The selected operator.
 * @param {object} props.propsMap - The props map.
 * @param {boolean} props.disabled - Whether the component is disabled.
 * @return {JSX.Element|null} The rendered component or null if input type is not supported.
 */
const ComponentRenderer = (props) => {
  const {name, formObject, index, selectedCondition, selectedOperator, propsMap, disabled, fieldsName} = props
  const operatorDatatype = selectedOperator?.datatype
  const inputType = selectedCondition?.input_type
  const inputOptions = selectedCondition?.input_options
  const keyName = selectedCondition?.key_name
  const dropdownOptions = inputOptions?.map((item) => ({label: item.display_name, value: item.key_name}))
  const defaultValue = propsMap[keyName]?.defaultValue
  const componentProps = getComponentProps({
    name,
    formObject,
    index,
    operatorDatatype,
    inputType,
    dropdownOptions,
    selectedCondition,
    propsMap,
    disabled,
    fieldsName
  })
  
  // this check will run irrespective of the input type
  if (operatorDatatype?.includes(DATA_TYPE.NA)) {
    return null
  } 

  if (inputType === INPUT_TYPES_MAP.DROPDOWN || inputType === INPUT_TYPES_MAP.API) {
    return <Controller
      key={selectedCondition.key_name}
      name={componentProps.name}
      control={componentProps.control}
      rules={componentProps.rules}
      render={({ field }) => (
        <Select
          {...field}
          {...componentProps}
          onChange={(value) => {
            if (Array.isArray(value)) {
              const filteredValue = value.filter((item) => item.value !== 'all')
              formObject.setValue(name, filteredValue.length ? filteredValue : null)
            } else {
              formObject.setValue(name, value)
            }
            formObject.clearErrors(name)
          }}
          isDisabled={componentProps.disabled}
        />
      )}
    />
  } else if (inputType === INPUT_TYPES_MAP.INPUT_BOX) {
    if (operatorDatatype?.includes(DATA_TYPE.ARRAY)) {
      return <MultiInputField {...componentProps} />
    } else if (operatorDatatype?.includes(DATA_TYPE.START_END_FLOAT)) {
      return <RangeInputGroup {...componentProps} />
    } else {
      return <InputField {...componentProps} />
    }
  } else if (inputType === INPUT_TYPES_MAP.DATE_PICKER) {
    return <DatePicker {...componentProps} />
  } else {
    return null
  }
}

export default ComponentRenderer