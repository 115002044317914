/* eslint-disable @typescript-eslint/no-explicit-any */
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner';
import Inventory from '@src/assets/images/icons/primary-navbar/Inventory';
import { iPackage } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { SHIPPING_AGGREGATOR_API_KEYS, fetchShipmentPackages, fetchSingleShipmentPackage } from '@src/views/shipping-aggregator/shipping-aggregator.apis';
import trackingImage from '@src/assets/images/orders/trackingOrder.svg';
// import { PACKAGE_TABS } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import Button from '@src/@core/components/ui/button';
import CustomSearchDropDown from '@src/@core/components/ui/custom-search-dropDown';
import NewCheckbox from '@src/@core/components/ui/new-checkbox';
import { NoMatchesFound } from '@src/assets/data/assets';
import { formattedLongDate } from '@src/utility/Utils';
import { ORDERS_TABS, SHIPMENT_ORDER_ENTITY } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PackageDetailMapView from './package-detail-map-view';
import TrackingViewLogs from './tracking-view-logs';

const PackageInfo = ({ orderDetails, entityType, id, isPackageDetailView, selectedPackages, setSelectedPackages }: any) => {
  console.log(entityType);
  const { t } = useTranslation();
  const [selectedPackageId, setSelectedPackageId] = useState<number | null>(null);
  // const [selectedStatus, setSelectedStatus] = useState(null);
  
  const isLMDSellerEnabled = useSelector((store: any) => store.auth.userData?.restricted_third_party_logistics);

  const [searchQuery, setSearchQuery] = useState(null);
  const [searchParams] = useSearchParams();
  const currentSelectedTab = searchParams.get('current_tab') || ORDERS_TABS.NEW_ORDERS.id;

  const handleChangeSearchQuery = (value: any) => {
    setSearchQuery(value.searchVal);
  };

  const { data: packageDetails, isFetching: isPackageListLoading } = useQuery({
    queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_PACKAGES, searchQuery],
    queryFn: () => {
      const params = {
        // status: currentSelectedTab || PACKAGE_TABS.NEW_PACKAGES.id,
        page: 1,
        per_page: 100,
        [entityType === SHIPMENT_ORDER_ENTITY.SHIPMENT_DETAILS ? 'shipment_id' : 'order_id']: orderDetails.id,
        // status: selectedStatus?.value,
        search_display_package_id: searchQuery,
      };
      return fetchShipmentPackages({ params });
    },
    select: ({ data }) => {
      const mutatedData = data.data.map((singlePackage: iPackage, index: number) => {
        return {
          ...singlePackage,
          serial_no: (data.meta?.current_page - 1) * data.meta?.per_page + (index + 1),
        };
      });
      return { ...data, data: mutatedData };
    },
    enabled: !!orderDetails?.id,
  });

  const { data: singlePackageDetails, isFetching } = useQuery({
    queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_SINGLE_PACKAGE_DETAILS, selectedPackageId],
    queryFn: () => {
      return fetchSingleShipmentPackage({ params: { id: isPackageDetailView ? id : selectedPackageId } });
    },
    select: ({ data }) => data.data,
    enabled: !!(id || selectedPackageId),
  });

  const handlePackageIdSelected = (packageId: number) => {
    setSelectedPackageId(packageId);
  };

  const handleGlobalChecked = (value: any) => {
    if (value) {
      const updatedSelectedPackage = {};
      packageDetails.data.forEach((singlePackage: any) => {
        if ( singlePackage.status === ORDERS_TABS.NEW_ORDERS.id) updatedSelectedPackage[singlePackage.id] = { ...singlePackage, isSelected: true };
      });
      setSelectedPackages({ ...updatedSelectedPackage });
    } else {
      setSelectedPackages({});
    }
  };
  const handlePackageSelectForCreate = (singlePackage: any, value: boolean) => {
    console.log('trial', singlePackage, value);
    if (value && singlePackage.status === ORDERS_TABS.NEW_ORDERS.id) {
      setSelectedPackages((pre) => ({ ...pre, [singlePackage.id]: { isSelected: value, ...singlePackage } }));
    } else {
      const updatedSelectedPackage = { ...selectedPackages };
      delete updatedSelectedPackage[singlePackage.id];
      setSelectedPackages(updatedSelectedPackage);
    }
  };

  const selectedPackageDetails = useMemo(
    () => [
      {
        label: 'Weight',
        value: singlePackageDetails?.package_info?.weight ? singlePackageDetails.package_info.weight.value + '' + singlePackageDetails.package_info.weight.uom : '-',
      },
      { label: 'Dimensions',value:(singlePackageDetails?.package_info?.length && singlePackageDetails?.package_info?.width && singlePackageDetails?.package_info?.height) ?  `${ singlePackageDetails?.package_info?.length} X ${ singlePackageDetails?.package_info?.width} X ${ singlePackageDetails?.package_info?.height}` : '-' },
      // { 'label': 'Package Tag', 'value': 'Package Tag' },
      {
        label: 'Tracking Number',
        value: singlePackageDetails?.tracking_number ? (
          <div className='d-flex align-items-center gap-50'>
            <a href={`/tracking/${singlePackageDetails.tracking_number}`} target='_blank' rel='noreferrer'>
              <img src={trackingImage} alt='tracking-image' />
            </a>
            <span>{singlePackageDetails.tracking_number}</span>
          </div>
        ) : '-'
      } ,
      { label: 'Current Location', value: singlePackageDetails?.current_location_name || '-', omit: isLMDSellerEnabled},
      { label: 'Created On', value: (singlePackageDetails?.created_at && formattedLongDate(singlePackageDetails.created_at)) || '-' },
      { label: 'Payment', value: singlePackageDetails?.display_payment_type || '-' },
      { label: 'Shipment Type', value:'Forward' },
      // { 'label': 'Service Type', 'value': 'Normal' },
      { label: 'Next Location', value: singlePackageDetails?.next_location_name || '-', omit: isLMDSellerEnabled },
    ],
    [singlePackageDetails]
  );
  const numberOfSelectablePackage = packageDetails?.data?.filter((singlePackage: any) => singlePackage.status === ORDERS_TABS.NEW_ORDERS.id)?.length;
  useEffect(() => {
    if (packageDetails?.data?.length) setSelectedPackageId(packageDetails.data[0].id as unknown as number);
  }, [packageDetails]);

  useEffect(() => {
    if (id && isPackageDetailView) setSelectedPackageId(id);
  }, [id]);
  return (
    <div className='package-info'>
      {!isPackageDetailView  && (
        <div className='package-list'>
          <div className='header'>
            <CustomSearchDropDown columnsList={[{name: 'Package ID', id:'search_display_package_id'}]}  width={'100%'} handleQueryParams={handleChangeSearchQuery} />
          </div>
          {!isPackageListLoading ? (
            packageDetails.data.length ?  <div className='d-flex flex-column flex-grow-1 py-12px'>
              {packageDetails.data.length ? (
                <div className='d-flex w-100 flex-row-reverse justify-content-between align-items-center p-12px pt-0'>
                  <div className='txt-body-rg text-dark'>{`Showing 1-${packageDetails.data.length} of ${packageDetails.meta.total} Packages`}</div>
                  {entityType === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS && (currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id) && (
                    <div className='ps-16px'>
                      <NewCheckbox
                        checked={!!Object.values(selectedPackages).length}
                        onChange={(e: any) => handleGlobalChecked(e.target.checked)}
                        partialChecked={(Object.values(selectedPackages).length < numberOfSelectablePackage) && ( Object.values(selectedPackages).length >= 1)}
                        id='global-checked' />
                    </div>
                  )}
                </div>
              ) : 
                <div>
                  <NoMatchesFound primary_dark={'var(--bs-primary-dark)'} primary_light={'var(--bs-primary-light)'} width={140} height={120} />
                  <h4 className='mt-2'>{t('No Matches Found in the Data Universe')}</h4>
                </div>
              }
              <PerfectScrollbar className='px-12px flex-grow-1 list'>
                <div className='d-flex flex-column gap-12px'>
                  {packageDetails.data.map((singlePackage: any) => (
                    <div
                      key={singlePackage.id}
                      className={classNames('px-16px position-relative   py-12px border rounded-8px  d-flex justify-content-between cursor-pointer', {
                        'active-package': selectedPackageId === singlePackage.id,
                      })}
                      onClick={() => handlePackageIdSelected(singlePackage.id)}
                    >
                      <div className='d-flex align-items-center gap-8px'>
                        {entityType === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS && (currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id) && (
                          <NewCheckbox
                            disabled={currentSelectedTab !== singlePackage.status }
                            checked={selectedPackages?.[singlePackage.id]?.isSelected}
                            onChange={(e: any) => handlePackageSelectForCreate(singlePackage, e.target.checked)}
                            id={singlePackage.id}
                          />
                        )}
                        <div className='p-12px bg-light-2 rounded-2px'>
                          <Inventory iswhite={false} height={20} width={20} />
                        </div>
                        <div className='d-flex flex-column gap-4px'>
                          <div className='d-flex align-items-center gap-8px'>
                            <div className='txt-sub-rg'>{t('Package ID')}</div>
                            <div className='text-primary end-0 me-8px bg-primary-lighter txt-asst-rg px-8px py-2px rounded-4px'>
                              {singlePackage.display_status}
                            </div>
                          </div>
                          <div className='txt-body-md text-dark'>{singlePackage.display_package_id}</div>
                        </div>
                      </div>
                      
                    </div>
                  ))}
                  {!(packageDetails.meta.current_page >= packageDetails.meta.last_page) && (
                    <div className='w-100 d-flex justify-content-center'>
                      <Button ofStyle='noBackground' className='shadow-none'>
                        {t('Load More')}
                      </Button>
                    </div>
                  )}
                </div>
              </PerfectScrollbar>
            </div> : 
              <div className='p-24px d-flex flex-column align-items-center'>
                <NoMatchesFound primary_dark={'var(--bs-primary-dark)'} primary_light={'var(--bs-primary-light)'} width={140} height={120} />
                <h4 className='mt-2 text-center'>{t('No Matches Found in the Data Universe')}</h4>
              </div>
          ) : (
            <ComponentSpinner className='' />
          )}
        </div>
      )}
      {!isFetching ? (
        singlePackageDetails ? (
          <PerfectScrollbar className='package-details-scrollbar '>
            <div className='package-details'>
              <div className='details d-flex flex-column gap-16px'>
                <div className='header'>
                  <div className='text-dark'>
                    {t('Package Details')}
                  </div>
                  <div className='text-primary bg-primary-light px-8px py-2px rounded-4px'>{singlePackageDetails.display_status}</div>
                </div>
                <div className={classNames('static-labels', {'lmd-seller-enabled': isLMDSellerEnabled})}>
                  {selectedPackageDetails.map((packageDetails) => {
                    if(packageDetails.omit) return null;
                    return(
                      <div key={packageDetails.label} className='d-flex'>
                        <div className='w-50 txt-sub-rg'>{packageDetails.label}</div>
                        <div className='w-50 text-dark txt-asst-rg'>{packageDetails.value}</div>
                      </div>
                    );})}
                </div>
                <div className='height-400 package-map-view-container'>
                  <PackageDetailMapView singlePackageDetails={singlePackageDetails} />
                </div>
              </div>
              <div className='tracking-details'>
                <div className='header text-dark'>Tracking Details</div>
                <div className='logs-view'>
                  <TrackingViewLogs
                    status={singlePackageDetails.status || ''}
                    logsDetails={singlePackageDetails?.tracking_history || []}
                  />
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        ) : null
      ) : (
        <div className='d-flex flex-grow-1 h-100 justify-content-center align-content-center'>
          <ComponentSpinner className='' />
        </div>
      )}
    </div>
  );
};

export default PackageInfo;
