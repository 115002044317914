import { useLocation } from 'react-router-dom';
import PickupLocation from '../sales/pickup-location';
import AllShipments from './pages/all-shipments';
import CreateShipmentOrder from './pages/create-shipment-order';
import Orders from './pages/orders';
import { PATH_NAME } from './shipping-aggregator.constant';
import './styles.scss';

const ShippingAggregator = () => {
  const location = useLocation();

  return (
    <div className='page-container'>
      {location.pathname === PATH_NAME.ORDERS && <Orders />}
      {location.pathname === PATH_NAME.CREATE_SHIPMENT_ORDERS && <CreateShipmentOrder />}
      {location.pathname === PATH_NAME.ALL_SHIPMENTS && <AllShipments />}
      {location.pathname === PATH_NAME.PICKUP_ADDRESS && <PickupLocation isPoweredFromShippingAggregator/>}
    </div>
  );
};

export default ShippingAggregator;