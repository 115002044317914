/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@src/@core/components/ui/button';
import CopyOnClick from '@src/@core/components/ui/click-and-copy';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable';
import NewCheckbox from '@src/@core/components/ui/new-checkbox';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import trackingImage from '@src/assets/images/orders/trackingOrder.svg';
import { axiosInstance } from '@src/network/AxiosInstance';
import { AbilityContext } from '@src/utility/context/Can';
import { useFilters, usePagination, useSearch } from '@src/utility/hooks/useFilters';
import CreateShipment from '@src/views/shipping-aggregator/components/create-shipment';
import OrderDetailsSidesheet from '@src/views/shipping-aggregator/components/order-details-sidesheet';
import PackageDetailsSideSheet from '@src/views/shipping-aggregator/components/order-details-sidesheet/order-details/package-details-sidesheet';
import { iPackage } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { fetchShipmentPackages, SHIPPING_AGGREGATOR_API_KEYS } from '@src/views/shipping-aggregator/shipping-aggregator.apis';
import { CREATE_SHIPMENTS_ORDER_FILTER_METADATA, PACKAGE_TABS, SHIPMENT_ORDER_ENTITY } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MoreVertical } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import ShipmentsOrderFilters from '../shipments-order-filters';

type iPackageTableView = {
 // eslint-disable-next-line no-unused-vars
 setTableMetaData: (data: any) => void
}

const PackageTableView = ({ setTableMetaData }: iPackageTableView) => {
  const ability = useContext(AbilityContext);
  const TableColumnsHeader = [
    { id: 'search_display_package_id', name: 'Package ID' },
    { id: 'search_display_order_id', name: 'Order ID' },
    { id: 'tracking_number', name: 'Tracking Number' }
  ];
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const isLMDSellerEnabled = useSelector((store: any) => store.auth.userData?.restricted_third_party_logistics);

  const [selectedOrderPackages, setSelectedOrderPackages] = useState<{ [key: string]: iPackage[] }>({});
  const [selectedPackages, setSelectedPackages] = useState<undefined | {[key: string]: iPackage[]}>();
  const [isCreateShipmentSideSheetOpen, setIsCreateShipmentSideSheetOpen] = useState<boolean>(false);
  const [shipmentOrderDetails, setShipmentOrderDetails] = useState<{ isOpen: boolean, id: string, defaultTabOpen?: number }>({ isOpen: false, id: '' });
  const [shipmentPackageDetails, setShipmentPackageDetails] = useState({ isOpen: false, data: null });
  const { searchQuery, setSearchQuery } = useSearch({ column: TableColumnsHeader[0] });
  const { filter, setFilter } = useFilters({});
  const { pagination, setPagination } = usePagination();
  const currentSelectedTab = searchParams.get('current_tab');

  // const ALL_PACKAGE_PAGE_FILTER_DATA = {
  //   tags: {
  //     filterKey: 'tags',
  //     displayName: t('Order Tags'),
  //     type: DROPDOWN_TYPES.MULTI_ASYNC_DROPDOWN,
  //     options: getOrderTagsAsync,
  //   },
  // };

  console.log({filter});

  const { data: packageDetails, isFetching: isPackageDetailsLoading, isSuccess, isError, refetch } = useQuery({
    queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_PACKAGES],
    queryFn: () => {
      const formattedParams: [string, unknown][] = [];
      if (pagination?.page) {
        formattedParams.push(['page', pagination.page]);
        formattedParams.push(['per_page', pagination.per_page || 20]);
      }
      if (searchQuery?.query) { 
        formattedParams.push([searchQuery.column.id, searchQuery.query]);
      }
      for (const key in filter) {
        if (key === CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey) {
          continue;
        }
        if (typeof filter[key] === 'object' && Array.isArray(filter[key])) {
          {
            formattedParams.push([key, (filter[key] as { value: string }[]).map(({ value }) => value)]);
          }
        } else if (typeof filter[key] === 'object') {
          formattedParams.push([key, (filter[key] as { value: string }).value]);
        } else {
          formattedParams.push([key, filter[key]]);
        }
      }

      const collectAllParams = Object.fromEntries(formattedParams);

      const params = {
        ...Object.fromEntries(formattedParams), 
        status: collectAllParams?.status ? collectAllParams.status : currentSelectedTab === PACKAGE_TABS.ALL.id ? undefined : (currentSelectedTab || PACKAGE_TABS.NEW_PACKAGES.id),
        created_at_start: filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey]?.value.start,
        created_at_end: filter[CREATE_SHIPMENTS_ORDER_FILTER_METADATA.CREATED_AT.filterKey]?.value.end,
      };
      // const params = {
      //   status: currentSelectedTab === PACKAGE_TABS.ALL.id ? undefined : (currentSelectedTab || PACKAGE_TABS.NEW_PACKAGES.id),
      //   ...pagination
      // };
      return fetchShipmentPackages({params});
    },
    select: ({ data }) => {
      const mutatedData = data.data.map((singlePackage: iPackage, index: number) => {
        return {
          ...singlePackage,
          serial_no: (((data.meta?.current_page - 1) * data.meta?.per_page) + (index + 1))
        };
      });
      return {...data, data: mutatedData};
    }
  });

  const { mutate: fetchSingleShipmentAWB } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.FETCH_SHIPMENT_AWB],
    mutationFn: ({ shipmentId}: {shipmentId: string | number}) => {
      return axiosInstance.get(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}/awb`);
    },
    onSuccess: ({ data }) => {
      window.open(data.data.awb_label, '_blank');
    }
  });

  const { mutate: markShipmentOperation } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.MARK_AS_DELIVERED],
    mutationFn: async ({ shipmentId, body}: {shipmentId: string | number, body: {status: string}}) => {
      const response = await axiosInstance.put(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}`, body);
      return {...response, type: body.status};
    },
    onSuccess: ({type}) => {
      refetch();
      CustomToast(`Mark shipment as ${type} successfully`, { my_type: 'success' });
      setSelectedOrderPackages({});
    }
  });

  const { mutate: markShipmentAsCancel } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.MARK_SHIPMENT_CANCEL],
    mutationFn: async ({ shipmentId, body}: {shipmentId: string | number, body: {status: string}}) => {
      const response = await axiosInstance.put(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}`, body);
      return {...response, type: body.status};
    },
    onSuccess: () => {
      refetch();
      CustomToast('Shipment cancelled successfully', { my_type: 'success' });
      setSelectedOrderPackages({});
    }
  });

  const handleSelectedPackageChange = (changedPackage: (iPackage), checked: boolean) => {
    if (checked) {
      setSelectedOrderPackages(p => {
        const copiedStruct = { ...p };
        const targetedShipmentPackages = copiedStruct[changedPackage.order_id];
        if (!targetedShipmentPackages?.length) {
          copiedStruct[changedPackage.order_id] = [changedPackage];
          return copiedStruct;
        }
        copiedStruct[changedPackage.order_id] = [...copiedStruct[changedPackage.order_id], changedPackage];
        return copiedStruct;
      });
    } else {
      setSelectedOrderPackages(p => {
        const copiedStruct = { ...p };
        const targetedShipmentPackages = copiedStruct[changedPackage.order_id];
        if (targetedShipmentPackages?.length === 1) {
          // This should never be the case
          delete copiedStruct[changedPackage.order_id];
          return copiedStruct;
        }
        copiedStruct[changedPackage.order_id] = copiedStruct[changedPackage.order_id].filter((pkg) => `${pkg.id}` !== `${changedPackage.id}`);
        return copiedStruct;
      });
    }
  };
  const handleOpenPackageSideSheet = (data: any) => () => {
    setShipmentPackageDetails({ isOpen: true, data });
  };

  const toggleCreateShipment = () => {
    
    if (isCreateShipmentSideSheetOpen) {
      setSelectedPackages(undefined);
      setIsCreateShipmentSideSheetOpen((p) => !p);
    } else {
      setIsCreateShipmentSideSheetOpen((p) => !p);
    }

  };

  const handlePagination = (page: {selected: number, per_page: number}) => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 10 };
    setPagination(pagination);

  };

  const handleSearchItem = ({ searchVal, selectedColumn = null }: { searchVal: string, selectedColumn: {id: string, name: string} | null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : null;
    setSearchQuery(searchQuery);
  };

  const filterHandler = ({ filterKey, filterValue }: { filterKey: string, filterValue: unknown }) => {
    if (!filterValue) {
      delete filter[filterKey];
      setFilter(filter);
      return;
    }
    setFilter({ ...filter, [filterKey]: filterValue });
  };

  // const createShipmentFromSelectedRow = ({ packageRow }: {packageRow: iPackage }) => {
  //   setSelectedPackages({
  //     [packageRow?.order_id as string]: [packageRow] as iPackage[]
  //   });
  //   toggleCreateShipment();
  // };

  const createShipmentFromSelectedData = () => {
    if (Object.values(selectedOrderPackages || {}).length > 0) {
      setSelectedPackages(selectedOrderPackages as unknown as { [key: string]: iPackage[] });
      toggleCreateShipment();
    } else {
      CustomToast('Select a package to create a shipment', {my_type: 'error'});
    }
  };

  const selectedPackageCleanup = () => {
    refetch();
    setSelectedOrderPackages({});
  }; 

  const handleToCheckIsActionDisabled = () => {
    if (currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id || !currentSelectedTab) {
      return !(ability.can(
        abilityMap.add_shipment_v2.create.action,
        abilityMap.add_shipment_v2.create.resource
      ));
    } else if (currentSelectedTab === PACKAGE_TABS.AT_HUB.id) {
      return (
        !(ability.can(
          abilityMap.add_shipment_v2.create.action,
          abilityMap.add_shipment_v2.create.resource
        )) && !(ability.can(
          abilityMap.edit_shipment_v2.edit.action,
          abilityMap.edit_shipment_v2.edit.resource
        ))
      );
    } else {
      return (
        !(ability.can(
          abilityMap.edit_shipment_v2.edit.action,
          abilityMap.edit_shipment_v2.edit.resource
        )) && !(ability.can(
          abilityMap.view_awb_shipment_v2.view_awb.action,
          abilityMap.view_awb_shipment_v2.view_awb.resource
        ))
      );
    }
  };

  console.log({ filterHandler });

  useEffect(() => {
    setTableMetaData(packageDetails?.meta || {});
  }, [packageDetails]);
  
  // const getAllAppliedFilters = (appliedFilter: any) => {
  //   setFilter(appliedFilter);
  // };

  const packagesColumns = useMemo(() => {
    return [
      {
        id: 21,
        name: '',
        key: 'selectable-packages',
        width: '60px',
        reorder: false,
        omit: !(currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id || (currentSelectedTab === PACKAGE_TABS.AT_HUB.id && !isLMDSellerEnabled) || !currentSelectedTab),
        cell: (row: iPackage) => {
          const isSelected = selectedOrderPackages[row.order_id]?.findIndex(pkg => pkg.id === row.id) > -1;
          const isAtLeastOneShipmentSelected = Object.values(selectedOrderPackages)?.length > 0;
          return (
            <div className='w-100 d-flex justify-content-center'>
              { /* @ts-expect-error fix */}
              <NewCheckbox name='shipment-checkbox' id={`${row.id}`} disabled={isAtLeastOneShipmentSelected && !selectedOrderPackages[row.order_id]} checked={isSelected} onChange={(e: { target: { checked: boolean } }) => handleSelectedPackageChange(row, ((e.target as unknown as { checked: boolean }).checked))} />
            </div>
          );
        },
      },
      {
        id: 22,
        name: <div className='d-flex w-100 align-items-center justify-content-center'>{t('S. No.')}</div>,
        key: 'sr.no',
        width: '70px',
        reorder: false,
        cell: (row: iPackage & {serial_no: number}) => {
          return <div className='d-flex w-100 align-items-center justify-content-center'>{row.serial_no}</div>;
        }
      },
      {
        id: 23,
        name: t('Package ID'),
        key: 'package_id',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage, index: number) => {
          return (
            /* @ts-expect-error fix */
            <CopyOnClick
              id={`${index}`}
              handleDetails={handleOpenPackageSideSheet(row)}
              columnKey={'package_id'} 
            >{row.display_package_id}</CopyOnClick>
          );
        },
      },
      {
        id: 24,
        name: t('Shipment Order ID'),
        key: 'order_id',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage, index: number) => {
          return (
          /* @ts-expect-error fix */
            <CopyOnClick
              id={`${index}`}
              handleDetails={() => setShipmentOrderDetails({ isOpen: true, id: row.order_id })}
              columnKey={'order_id'} 
            >{row.display_order_id || '-'}</CopyOnClick>
          );
        },
      },
      {
        id: 25,
        name: t('Package’s AWB Number'),
        key: 'package_identifier',
        minWidth: '200px',
        reorder: true,
        omit: (currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id) || !currentSelectedTab,
        cell: (row: iPackage) => {
          return (
            <div className='group/copy flex w-full flex-col'>
              <div className={classNames('flex h-full w-full items-center text-clickable')}>
                <div className={classNames({ 'cursor-pointer': row.awb })} onClick={() => {
                }}>{row.display_awb_number || '-'}</div>
              </div>
            </div>
          );
        },
      },
      {
        id: 26,
        name: t('Tracking Number'),
        key: 'tracking_number',
        minWidth: '150px',
        omit: (currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id) || !currentSelectedTab,
        reorder: true,
        cell: (row: iPackage) => {
          return row.tracking_number ? (
            <div className='d-flex align-items-center gap-50'>
              <a href={`/tracking/${row.tracking_number}`} target='_blank' rel='noreferrer'>
                <img src={trackingImage} alt='tracking-image' />
              </a>
              <span>{row.tracking_number}</span>
            </div>
          ) : '-';
        }
      },
      {
        id: 27,
        name: t('Package Type'),
        key: 'package_type',
        minWidth: '150px',
        reorder: true,
        cell: () => {
          return (
            <div>
              Forward
            </div>
          );
        },
      },
      {
        id: 28,
        name: t('Package Status'),
        key: 'package_status',
        minWidth: '150px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.display_status || '-'}
            </div>
          );
        }
      },
      {
        id: 29,
        name: t('Seller Name'),
        key: 'seller_name',
        minWidth: '150px',
        omit:isLMDSellerEnabled,
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.seller_name ||'-'}
            </div>
          );
        }
      },
      {
        id: 30,
        name: t('Sales Channel'),
        key: 'sales_channel_name',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            row.sales_channel?.logo_url ? <div className="d-flex  gap-8px align-items-center">
              <img
                // onError={({ currentTarget }) => {
                //   currentTarget.onerror = null;
                //   currentTarget.src = noImage;
                // }}
                src={row.sales_channel?.logo_url}
                alt={'sales-channel'}
                className="object-fit-contain"
                width={60}
                height={30}
              />
            </div> : '-'
          );
        }
      },
      {
        id: 31,
        name: t('Payment Type'),
        key: 'payment_type',
        minWidth: '150px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.display_payment_type ||'-'}
            </div>
          );
        }
      },
      {
        id: 32,
        name: t('Current Location'),
        key: 'current_location',
        omit:isLMDSellerEnabled,
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.current_location_name || '-'}
            </div>
          );
        }
      },
      {
        id: 33,
        name: t('Next Location'),
        key: 'next_location',
        minWidth: '250px',
        reorder: true,
        omit: (currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id || currentSelectedTab === PACKAGE_TABS.AT_HUB.id) || !currentSelectedTab || isLMDSellerEnabled,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.next_location_name || '-'}
            </div>
          );
        }
      },
      {
        id: 34,
        name: t('Pickup Address'),
        key: 'origin_location',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.origin_location_name || '-'}
            </div>
          );
        }
      },
      {
        id: 35,
        name: t('Delivery Address'),
        key: 'destination_location',
        minWidth: '250px',
        reorder: true,
        omit: false,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.destination_address || '-'}
            </div>
          );
        }
      },
      {
        id: 36,
        name: t('Courier Partner'),
        key: 'courier_partner',
        minWidth: '250px',
        reorder: true,
        omit: (currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id) || !currentSelectedTab || isLMDSellerEnabled,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.courier_partner || '-'}
            </div>
          );
        }
      },
      {
        id: 37,
        name: t('Weight'),
        key: 'weight',
        minWidth: '180px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='flex items-center gap-2'>
              {row.package_info.weight?.value ? `${row.package_info.weight?.value} ${row.package_info.weight?.uom || ''}` : '-'}
            </div>
          );
        },
      },
      {
        id: 38,
        name: t('Dimensions (l X b X h)'),
        key: 'dimensions',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='flex items-center gap-2'>
              {(row.package_info.length && row.package_info.width && row.package_info.height) ? `${row.package_info.length} X ${row.package_info.width} X ${row.package_info.height}` : '-'}
            </div>
          );
        },
      },
      {
        id: 39,
        name: t('Delivery Attempts'),
        key: 'delivery_attempts',
        minWidth: '200px',
        reorder: true,
        omit: (currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id) || !currentSelectedTab,
        cell: (row: iPackage) => {
          return (
            <div className='flex items-center gap-2'>
              {row.delivery_attempts || '-'}
            </div>
          );
        },
      },
      {
        id: 40,
        name: t('Created on'),
        key: 'created_at_date',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <span>{row.created_at || '-'}</span>
          );
        }
      },
      {
        id: 41,
        name: t('Action'),
        key: 'actions',
        width: '70px',
        omit: (currentSelectedTab === PACKAGE_TABS.DELIVERED.id || currentSelectedTab === PACKAGE_TABS.ALL.id || currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id || !currentSelectedTab),
        // omit: !ability.can(
        //   abilityMap.hub_location.edit.action,
        //   abilityMap.hub_location.edit.resource
        // ),
        cell: (row: iPackage) => {
          const isAllActionsDisabled = handleToCheckIsActionDisabled();
          return (
            <div className={classNames('d-flex w-100 justify-content-center', {'cursor-pointer' : !isAllActionsDisabled})}>
              <UncontrolledDropdown>
                <DropdownToggle className="pe-1 more_vert" tag="span" disabled={isAllActionsDisabled}>
                  <MoreVertical size={20} color={isAllActionsDisabled ? 'var(--bs-dark-4)' : 'var(--bs-dark)'} />
                </DropdownToggle>
                <DropdownMenu end container="body">
                  {(row.can_mark_delivered && (ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  ))) && <DropdownItem
                    className='w-100 text-dark'
                    onClick={() => markShipmentOperation({
                      shipmentId: row.shipments_info[row.shipments_info.length - 1].shipment_id,
                      body: {
                        status: 'delivered'
                      }
                    })}
                  >
                    <span className="align-middle ms-50 txt-sub-rg">
                      {t('Mark as Delivered')}
                    </span>
                  </DropdownItem>
                  }
                  {(row.can_mark_shipped && (ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  ))) && <DropdownItem
                    className='w-100 text-dark'
                    onClick={() => markShipmentOperation({
                      shipmentId: row.shipments_info[row.shipments_info.length - 1].shipment_id,
                      body: {
                        status: 'shipped'
                      }
                    })}
                  >
                    <span className="align-middle ms-50 txt-sub-rg">
                      {t('Mark as Shipped')}
                    </span>
                  </DropdownItem>
                  }
                  {(row.can_cancel_shipment && (ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  ))) && <DropdownItem
                    className='w-100 text-dark'
                    onClick={() => markShipmentAsCancel({
                      shipmentId: row.shipments_info[row.shipments_info.length - 1].shipment_id,
                      body: {
                        status: 'cancelled'
                      }
                    })}
                  >
                    <span className="align-middle ms-50 txt-sub-rg">
                      {t('Cancel Shipment')}
                    </span>
                  </DropdownItem>
                  }
                  {(row.can_print_awb  && (ability.can(
                    abilityMap.view_awb_shipment_v2.view_awb.action,
                    abilityMap.view_awb_shipment_v2.view_awb.resource
                  ))) &&
                  <DropdownItem
                    className='w-100 text-dark'
                    onClick={() => fetchSingleShipmentAWB({ shipmentId: row.shipments_info[row.shipments_info.length - 1]?.shipment_id })}
                  >
                    <span className="align-middle ms-50 txt-sub-rg">
                      {t('Print AWB')}
                    </span>
                  </DropdownItem>
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          );
        }
      }
    ];
  }, [selectedOrderPackages, t]);

  return (
    <div className='d-flex flex-column gap-16px'>
      <div className='flex-wrap d-flex gap-16px'>
        {/* <CustomFilter
          filterData={ALL_PACKAGE_PAGE_FILTER_DATA}
          defaultSelectedOption={filter}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          getAllAppliedFilters={getAllAppliedFilters}
        /> */}

        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <ShipmentsOrderFilters selectedTabParam={currentSelectedTab} filterHandlerMultipleValues={() => {}} filterHandler={filterHandler} filter={filter}  omitShipmentFilters={false} setFilter={setFilter} />
      </div>
      <ExpandableCustomTable
        tableWrapperClassNames='package-table-wrapper'
        columns={packagesColumns}
        loading={isPackageDetailsLoading}
        error={isError}
        success={isSuccess}
        data={packageDetails?.data || []}
        searchcolumnsList={TableColumnsHeader}
        search_column_query={searchQuery}
        handleQueryParams={handleSearchItem}
        handleRefreshTable={refetch}
        /* @ts-expect-error fix */
        handlePagination={handlePagination}
        TableHeaderComponent={
          (ability.can(
            abilityMap.add_shipment_v2.create.action,
            abilityMap.add_shipment_v2.create.resource
          ) && (currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id || (currentSelectedTab === PACKAGE_TABS.AT_HUB.id && !isLMDSellerEnabled) || !currentSelectedTab)) && <div className='d-flex gap-16px align-items-center'>
            <div className='border border-primary-lighter height-40px'></div>
            {((currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id || (currentSelectedTab === PACKAGE_TABS.AT_HUB.id && !isLMDSellerEnabled) || !currentSelectedTab) && (ability.can(
              abilityMap.add_shipment_v2.create.action,
              abilityMap.add_shipment_v2.create.resource
            )))&& 
            <Button onClick={createShipmentFromSelectedData}>{t('Create Shipment')}</Button>}
            
            {/* <Button onClick={() => {}}>{t('Create Order')}</Button> */}
          </div>
        }
        selectableRows={false}
        meta={packageDetails?.meta || {}}
        isActionColumnSticky={!(currentSelectedTab === PACKAGE_TABS.DELIVERED.id || currentSelectedTab === PACKAGE_TABS.ALL.id || currentSelectedTab === PACKAGE_TABS.NEW_PACKAGES.id || !currentSelectedTab)}
      />
      
      <CreateShipment
        isOpen={isCreateShipmentSideSheetOpen}
        toggle={toggleCreateShipment}
        selectedPackages={Object.values(selectedPackages || {})[0]}
        cleanupOnSuccesHandler={selectedPackageCleanup}
      />
      <PackageDetailsSideSheet
        isOpen={shipmentPackageDetails.isOpen}
        toggleSideSheet={() => setShipmentPackageDetails({ isOpen: false, data: null })}
        shipmentPackageDetails={shipmentPackageDetails.data}
      />
      <OrderDetailsSidesheet
        isOpen={shipmentOrderDetails.isOpen}
        toggleSideSheet={() => setShipmentOrderDetails({isOpen:false, id:''})}
        entity={{ id: shipmentOrderDetails.id, key: SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS }}
        defaultTabOpen={shipmentOrderDetails.defaultTabOpen}
        setIsCreateShipmentSideSheetOpen={setIsCreateShipmentSideSheetOpen}
        setSelectedPackages={setSelectedPackages}
      />
    </div>
  );
};

export default PackageTableView;