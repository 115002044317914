
const PackageIcon = (color: string) => {
  return (
    <svg width="16" height="16" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.2308 32.3074C15.4236 32.3074 14.7692 31.6531 14.7692 30.8459V26.4612H8.9231V23.5381H23.5384C25.1528 23.5381 26.4615 24.8468 26.4615 26.4612V41.0768C26.4615 42.6912 25.1528 43.9999 23.5384 43.9999H8.9231C7.30872 43.9999 6 42.6912 6 41.0768V26.4612C6 24.8468 7.30872 23.5381 8.9231 23.5381L8.9231 41.0768H23.5384V26.4612L17.6923 26.4612V30.8459C17.6923 31.6531 17.038 32.3074 16.2308 32.3074Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M33.7692 32.3074C32.962 32.3074 32.3077 31.6531 32.3077 30.8459V26.4612H26.4616V23.5381H41.0769C42.6913 23.5381 44 24.8468 44 26.4612V41.0768C44 42.6912 42.6913 43.9999 41.0769 43.9999H26.4616C24.8472 43.9999 23.5385 42.6912 23.5385 41.0768V26.4612C23.5385 24.8468 24.8472 23.5381 26.4616 23.5381L26.4616 41.0768H41.0769V26.4612L35.2308 26.4612V30.8459C35.2308 31.6531 34.5764 32.3074 33.7692 32.3074Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25 14.7694C24.1928 14.7694 23.5384 14.115 23.5384 13.3078V8.9231H17.6923V6H32.3077C33.9221 6 35.2308 7.30872 35.2308 8.9231V23.5387C35.2308 25.1531 33.9221 26.4618 32.3077 26.4618H17.6923C16.0779 26.4618 14.7692 25.1531 14.7692 23.5387V8.9231C14.7692 7.30872 16.0779 6 17.6923 6L17.6923 23.5387H32.3077V8.9231L26.4615 8.9231V13.3078C26.4615 14.115 25.8072 14.7694 25 14.7694Z" fill={color}/>
    </svg>

  );
};

export default PackageIcon;