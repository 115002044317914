import Button from '@src/@core/components/ui/button';
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner';
import { getApiUrl } from '@src/api.urls';
import { ErrorState, NoTrackingData } from '@src/assets/data/assets';
import FilledInfo from '@src/assets/images/icons/FilledInfo';
import { axiosInstance } from '@src/network/AxiosInstance';
import { useRTL } from '@src/utility/hooks/useRTL';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ArrowLeft, ArrowRight, Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getShipmentPackages } from '../public-tracking-page.api';
import TrackingStatusWithTable from './tracking-status-with-table';

const NoDataComponentWhenError = () => {
  const {t} = useTranslation();
  return (
    <div className='d-flex flex-column align-items-center py-40px'>
      <ErrorState primary='var(--bs-primary)' width={160} height={120}/>
      <h4 className='mt-2 text-center'>{t('Oops, It Seems There Is An Issue In the Request')}.</h4>
      <p>{t('Please verify the provided information and retry')}.</p>
    </div>
  );
};

const PublicTrackingPageInfo = () => {
  const [isRTL] = useRTL();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackingId } = useParams();
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const handleAwbSearch = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (!searchValue) return;
    const trackingNumber = searchValue || trackingId;
    navigate(`/tracking/${trackingNumber}`);
  };

  const { data: shipmentPackagesData, isFetching, isError } = useQuery({
    queryKey: [getShipmentPackages.apiKey, trackingId],
    queryFn: () => {
      return axiosInstance.get(getApiUrl(getShipmentPackages.url, { trackingId: trackingId }));
    },
    enabled: !!trackingId,
    select: ({ data }) => {
      return data.data?.packages;
    }
  });

  return (
    <div className="public-tracking-page-info">
      <form className='w-100 flex-center-center' onSubmit={(e) => handleAwbSearch(e)}>
        <div className='input-field-container'>
          <Search size={20} className='text-dark' />
          <input
            defaultValue={searchValue || trackingId}
            onChange={(e: { target: { value: string; }; }) => setSearchValue(e.target.value)}
            placeholder={'Enter Order ID/Tracking ID'}
            className='input-field w-100 h-100 text-truncate'
          />
          <Button className='gap-10px txt-sub-rg' ofType='compact'>
            <span>{t('Track')}</span>
            {isRTL ? <ArrowLeft size={18} /> : <ArrowRight size={18} />}
          </Button>
        </div>
      </form>

      {isFetching ? <div className='py-40px'><ComponentSpinner className={''} /></div> : <>    
        {isError ? (
          <NoDataComponentWhenError />) : null
        }
        
        {!isError &&!shipmentPackagesData?.length ? (  
          <div className='w-100 flex-center-center'>
            <NoTrackingData primary='var(--bs-primary)' primary_light='var(--bs-primary-light)' width='312' height='312'/>
          </div>) : null
        }

        {shipmentPackagesData?.length > 1 && (
          <div className='notification-bar-container'>
            <div className='notification-bar'>
              <span className='width-20px height-20px flex-center-center'>
                <FilledInfo height={20} width={20} color={'var(--bs-primary)'}/>
              </span>
              <span className='txt-body-rg text-dark'>{t('This order has been packed into')} {shipmentPackagesData?.length} {t('different boxes (packages)')}. {t('Please select an individual package number to view tracking status.')} </span>
            </div>
          </div>
        )}
        
        {shipmentPackagesData?.length > 0 && (
          <TrackingStatusWithTable shipmentPackagesData={shipmentPackagesData}/>
        )}
      </>}
    </div>
  );
};

export default PublicTrackingPageInfo;