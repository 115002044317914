import InputField from '@src/@core/components/ui/input-field';
import MultiPurposeSelect from '@src/@core/components/ui/multi-purpose-select';
import SkuDetailsOption from '@src/@core/components/ui/multi-purpose-select/components/sku-details-option';
import QuantityButton from '@src/@core/components/ui/quantity-button';
import SKUDetailsCol from '@src/@core/components/ui/sku-details';
import { loadAsyncSellerSkusConfig } from '@src/app.apis';
import { commaSeparateInternational, getOptionsFetcher } from '@src/utility/Utils';
import classNames from 'classnames';
import React from 'react';
import { Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';

export const CHOOSE_SKU_KEY = 'default_sku_state';
export const INITIATOR = {
  ADD_AWB:'addAwb',
  ADD_ITEMS:'addItems'
};

interface iAddItemsColumns {
  deleteSku: () => void
  handleSelect: () => void
  handleSkuDetails: () => void
  handleDecreaseQty: () => void
  handleIncreaseQty: () => void
  handleQtyChange: () => void
  handleUnitCostChange: () => void
  selectedSeller: {label: string, value: string}
  originalItemsData: []
  fields: []
  isPoTypeShipment: string
  initiator: string
  watch: () => void
  register: () => void
}

//@ts-expect-error fix
export const AddItemsColumns: React.FC<iAddItemsColumns> = ({
  deleteSku,
  handleSelect,
  handleSkuDetails,
  handleDecreaseQty,
  handleIncreaseQty,
  handleQtyChange,
  handleUnitCostChange,
  selectedSeller,
  originalItemsData,
  fields,
  isPoTypeShipment,
  initiator,
  watch,
  register
}) => {
  const { t } = useTranslation();
  //@ts-expect-error fix
  const loadAsyncSellerSkusOptions = getOptionsFetcher(loadAsyncSellerSkusConfig());
  return [
    {
      id:1,
      key:'delete_sku',
      width:'44px',
      // @ts-expect-error fix
      cell: (row, index) => {
        return <div className='flex-center-center w-100'>
          <Trash2 
            size={20}
            color={row.key === CHOOSE_SKU_KEY ? 'var(--bs-dark-2)' : 'var(--bs-danger)'}
            className={classNames({
              'cursor-pointer' : row.key !== CHOOSE_SKU_KEY
            })}
            //@ts-expect-error fix
            onClick={() => row.key === CHOOSE_SKU_KEY ? {} : deleteSku(index - 1)}
          />
        </div>;
      }
    },
    {
      id: 2,
      name: t('SKU Details'),
      Key:'sku_details',
      width:'420px',
      // @ts-expect-error fix
      cell: (row) => {
        return <div className='w-100 flex-center-start'>
          {
            row.key === CHOOSE_SKU_KEY ? 
              <div id='disabled-id'>
                <MultiPurposeSelect
                  //@ts-expect-error fix
                  filterOption={(option) => !fields.some(item => item.sku?.id === option.data?.id)}
                  //@ts-expect-error fix
                  loadOptions={loadAsyncSellerSkusOptions}
                  customComponents={{
                    //@ts-expect-error fix
                    Option: (props) => <SkuDetailsOption 
                      {...props} 
                      tooltipText={isPoTypeShipment ? 'This SKU has already been included in this shipment.' : 'This SKU has already been included in this PO.'} 
                      tooltipPlacement='top'
                    />
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 1100 })
                  }}
                  additional={{
                    page:1,
                    seller_id:selectedSeller?.value,
                    originalItemsData:initiator === INITIATOR.ADD_ITEMS ? originalItemsData : []
                  }}
                  //@ts-expect-error fix
                  isDisabled={initiator === INITIATOR.ADD_AWB && !watch('awb_number')}
                  onChange={(data) => 
                    //@ts-expect-error fix
                    originalItemsData.some(item => item.seller_sku_id === data.id) && initiator === INITIATOR.ADD_ITEMS ? {} :
                    //@ts-expect-error fix
                      handleSelect(data)}
                  modifiedClassNames={{
                    menu: () => 'width-400',
                    menuList: () => 'max-height-200',
                    option: (state) => classNames('cursor-pointer', {
                      'bg-primary-lighter': state.isFocused && !state.isSelected
                    })
                  }}
                />
                {/**@ts-expect-error fix */}
                {initiator === INITIATOR.ADD_AWB && !watch('awb_number') ? <UncontrolledTooltip placement="top"  offset={[0, 5]} target='disabled-id'>
                  {t('Enter the AWB number you wish to add first.')}
                </UncontrolledTooltip> : null
                }
              </div>
              :
              <SKUDetailsCol
                skuDetailData={{
                  isValidSku: true,
                  id: row.sku?.id,
                  image: {
                    src: row.sku?.images?.[0]?.default,
                    alt: row.sku?.name
                  },
                  details: {
                    name: row.sku?.name,
                    //@ts-expect-error fix
                    handleSkuDetails: () => (row.sku?.name ? handleSkuDetails(row) : null),
                    skuCode: row.sku?.seller_sku_code,
                    columnKey: 'sku_details'
                  }
                }}
              />
          }
        </div>;
      } 
    },
    {
      id: 3,
      name: t('Quantity'),
      Key:'ordered_qty',
      minWidth:'150px',
      // @ts-expect-error fix
      cell: (row, index) => {
        return <div className='w-100 flex-center-center'>
          <QuantityButton
            disabled={row.key === CHOOSE_SKU_KEY}
            //@ts-expect-error fix
            decreaseQuantity={() => handleDecreaseQty(index - 1)}
            //@ts-expect-error fix
            increaseQuantity={() => handleIncreaseQty(index - 1)}
            // @ts-expect-error fix
            quantity={row.key === CHOOSE_SKU_KEY ? '' : watch(`items.${index - 1}.qty`)}
            maxQuantityEnabled={false}
            // @ts-expect-error fix
            minQuantityEnabled={watch(`items.${index - 1}.qty`) === 1}
            // @ts-expect-error fix
            {...(row.key === CHOOSE_SKU_KEY ? {} : register(`items.${index - 1}.qty`))}
            // @ts-expect-error fix
            onChange={(e) => handleQtyChange(e, index - 1)}
          />
        </div>;
      } 
    },
    {
      id: 4,
      name: <div className='flex-center-end w-100'>{t('Unit Cost')}</div>,
      Key:'unit_cost',
      minWidth:'130px',
      //@ts-expect-error fix
      cell: (row, index) => {
        return <div className='w-100 flex-center-center'>
          <InputField
            type='number'
            style={{height:'32px'}}
            disabled={row.key === CHOOSE_SKU_KEY}
            className='text-end'
            //@ts-expect-error fix
            {...(row.key === CHOOSE_SKU_KEY ? {} : register(`items.${index - 1}.unitCost`))}
            //@ts-expect-error fix
            onChange={(e) => handleUnitCostChange(e, index - 1)}
          />
        </div>;
      } 
    },
    {
      id: 5,
      name: <div className='flex-center-end w-100'>{t('Total Cost')}</div>,
      Key:'total_cost',
      minWidth:'100px',
      //@ts-expect-error fix
      cell: (row, index) => {
        return <div className='w-100 flex-center-end'>
          {row.key === CHOOSE_SKU_KEY ? '-' :
            //@ts-expect-error fix
            `${commaSeparateInternational(watch(`items.${index - 1}.totalCost`)??0)} ${row.sku.currency}` 
          }
        </div>;
      } 
    }
  ];
};