import SideSheet from '@src/@core/components/ui/sideSheet';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {  fetchSingleShipmentOrder, SHIPPING_AGGREGATOR_API_KEYS } from '../../shipping-aggregator.apis';
import CreateShipmentSideSheet from './components/create-shipment-sidesheet';
import { iPackage } from '../../shipping-aggregator-types';

 type iCreateShipment = {
  isOpen: boolean
  toggle: () => void
  selectedPackages: iPackage[]
  cleanupOnSuccesHandler?: () => void
 }

const CreateShipment = ({ isOpen, toggle, selectedPackages = [], cleanupOnSuccesHandler }: iCreateShipment) => {
  const orderId = selectedPackages[0]?.order_id;
  const queryClient = useQueryClient();
  
  const { data: singleOrderDetail, isFetching: isSingleOrderDetailLoading, refetch } = useQuery({
    queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_SINGLE_ORDER_DETAIL],
    queryFn: () => {
      return  fetchSingleShipmentOrder({id: orderId, params:{package_status: 'new'}});
    },
    enabled: (isOpen && !!orderId)
  });

  const handleSideSheetClose = () => {
    queryClient.removeQueries({queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_SINGLE_ORDER_DETAIL], exact: true});
  };

  return (
    <div>
      {/* @ts-expect-error fix */}
      <SideSheet
        modalClassName='modal-slide-in create-shipment-container'
        size='lg'
        title='Create Shipment'
        isOpen={isOpen}
        toggle={toggle}
        onClose={handleSideSheetClose}
      >
        <CreateShipmentSideSheet
          singleOrderDetail={singleOrderDetail?.data?.data}
          toggle={toggle}
          isLoading={isSingleOrderDetailLoading}
          selectedPackages={selectedPackages}
          cleanupOnSuccesHandler={cleanupOnSuccesHandler}
          refetch={refetch}
        />
      </SideSheet>
    </div>
  );
};

export default CreateShipment;