import CustomTag from '@src/@core/components/ui/badge/CustomTag';
import Button from '@src/@core/components/ui/button';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable';
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant';
import Modal from '@src/@core/components/ui/modal';
import EditRulePrioritySideSheet from '@src/components/rule/editRulePriority/EditRulePrioritySideSheet';
import { AUTOMATION_OPERATIONS } from '@src/components/rule/rule.constant';
import { useState } from 'react';
import { Check, Edit, MoreVertical, Plus, Slash, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import { fetchAutomationRules, fetchConditions, updateAutomationRuleStatus, updatePriorityRules } from '../giftItemAutomation.api';
import { DEFAULT_VALUES } from '../giftItemAutomation.constants';

const GiftItemTableSection = (props) => {

  const {filter, searchQuery, setSearchQuery, pagination, setPagination, hasEditRulePermission, activeRule, setActiveRule,hasCreateRulePermission, searchColumnList, automationDetail, setRuleSideSheet, setDefaultFormValues, isSingleAutomationRuleFetching,handleSetConditionGroupAndActionGroup }= props;

  // Hooks
  const { t } = useTranslation();

  // States
  const [isDeleteRuleConfirmationModalOpen, setDeleteRuleConfirmationModalOpen] = useState(false)
  const [isPrioritySideSheetOpen, setIsPrioritySideSheetOpen] = useState(false)

  // variables
  const selectedSellerId = filter.seller?.value

  const handleWhenStatusUpdated = () => {
    let message = 'Status Updated Successfully'
    if (activeRule?.operation === AUTOMATION_OPERATIONS.DELETE) {
      message = 'Rule Deleted Successfully'
      setDeleteRuleConfirmationModalOpen(false)
    }
    CustomToast(message, { my_type: 'success' })
    refetchAutomationRules()
  }

  // APIs
  const {data: automationRulesResponse, isFetching: isAutomationRulesFetching, isError: isAutomationRulesError, isSuccess: isAutomationRulesSuccess, refetch: refetchAutomationRules} = fetchAutomationRules({
    params: {
      seller_id: selectedSellerId,
      search_column: searchQuery?.column?.id,
      search_query: searchQuery?.query,
      ...pagination
    }
  })

  const {mutate: updateStatus, isPending: isStatusUpdating} = updateAutomationRuleStatus({
    onSuccess: handleWhenStatusUpdated
  })

  const handleWhenPriorityUpdated = () => {
    CustomToast('Priority Updated Successfully', { my_type: 'success' })
    setIsPrioritySideSheetOpen(false)
    refetchAutomationRules()
  }
  const { mutate: updatePriority, isPending: isPriorityUpdating } = updatePriorityRules({
    onSuccess: handleWhenPriorityUpdated
  })


  const handleWhenConditionFetched = (data) => {
    handleSetConditionGroupAndActionGroup(data)
    setRuleSideSheet({ isOpen: true, isEdit: false, isView: false })
    setDefaultFormValues(DEFAULT_VALUES)
  }

  const { mutate: mutateFetchConditions, isPending: isConditionsFetching} = fetchConditions({
    onSuccess: handleWhenConditionFetched,
  })

  const handleOpenCreateRule = () => {
    const params = {
      seller_id: filter.seller.value
    }
    mutateFetchConditions({params})
  }

  const automationRules = automationRulesResponse?.data?.length ? automationRulesResponse.data : []

  const tableData = automationRules.slice((pagination.page - 1) * pagination.per_page, Math.min(pagination.page * pagination.per_page, automationRules.length))

  const meta = {
    current_page: pagination.page,
    last_page: Math.ceil(automationRules.length / pagination.per_page),
    per_page: pagination.per_page,
    total: automationRules.length
  }

  const handleChangeStatus = (data) => {
    setActiveRule(data)
    if (data.operation === AUTOMATION_OPERATIONS.EDIT) {
      const params = {
        ruleId: data.id,
        seller_id: selectedSellerId
      }
      automationDetail({params})
      return
    }
    const body = {
      operation: data.operation
    }
    if (data.operation === AUTOMATION_OPERATIONS.UPDATE_STATUS) {
      body.is_active = !data.is_active
    }
    updateStatus({ ruleId: data.id, body, seller_id: selectedSellerId })
  }

  const handleSearchItem = ({ searchVal, selectedColumn }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : null;
    setSearchQuery(searchQuery);
  };

  const handlePagination = (page) => {
    const pagination = {page: page.selected, per_page: page.per_page };
    setPagination(pagination);
  };

  const handleToggleDeleteRuleConfirmationModal = () => {
    setDeleteRuleConfirmationModalOpen(prev => !prev)
  }

  const handleOnClickRuleName = (data) => {
    setActiveRule(data)
    const params = {
      ruleId: data.id,
      seller_id: selectedSellerId
    }
    if (hasEditRulePermission) {
      setRuleSideSheet({isOpen: true, isEdit: true, isView: false})
    } else {
      setRuleSideSheet({isOpen: true, isEdit: false, isView: true})
    }
    automationDetail({params})
  }
  
  const handleOpenPriorityRule = () => {
    setIsPrioritySideSheetOpen(true)
  }
  const handleClosePriorityRule = () => {
    setIsPrioritySideSheetOpen(false)
  }

  const handleUpdatePriorityRules = ({body}) => {
    updatePriority({body, seller_id: selectedSellerId})
  }

  // Table Columns
  const tableColumns = [
    {
      id: 1,
      name: <div className='w-100 text-center'>{t('Priority')}</div>,
      width: '80px',
      key: 'precedence',
      cell: (row) => <div className='w-100 text-center'>{row.precedence}</div>
    },
    {
      id: 2,
      name: t('Rule Name'),
      key: 'rule_name',
      cell: (row) => {
        return <div className='cursor-pointer text-truncate text-primary' title={row.name} onClick={() => handleOnClickRuleName({...row, operation: AUTOMATION_OPERATIONS.EDIT})}>{row.name}</div>
      }
    },
    {
      id: 3,
      maxWidth: '125px',
      minWidth: '100px',
      name: t('Status'),
      key: 'status',
      reorder: true,
      cell: (row) => {
        return (
          <CustomTag
            title={row.is_active ? 'Active' : 'Inactive'}
            className={row.is_active ? 'text-success bg-success-light' : 'bg-dark-1 text-secondary'}
          />
        )
      }
    },
    {
      id: 4,
      name: t('Created By'),
      key: 'created_by',
      selector: (row) => row.created_by?.name || '-'
    },
    {
      id: 5,
      name: t('Created At'),
      key: 'created_at',
      selector: (row) => row.created_at || '-'
    },
    {
      id: 6,
      name: t('Updated By'),
      key: 'updated_by',
      selector: (row) => row.updated_by?.name || '-'
    },
    {
      id: 7,
      name: t('Updated At'),
      key: 'updated_at',
      selector: (row) => row.updated_at || '-'
    },
    {
      id: 8,
      name: t('Action'),
      width: '80px',
      allowOverflow: true,
      key: 'actions',
      omit: !hasEditRulePermission,
      cell: (row) => {
        return (
          <div className='d-flex justify-content-center align-items-center w-100 cursor-pointer'>
            <UncontrolledDropdown>
              <DropdownToggle className='border p-4px rounded-4px' tag='span'>
                <MoreVertical size={16} color={'var(--bs-dark)'} />
              </DropdownToggle>
              <DropdownMenu end container='body'>
                <DropdownItem
                  onClick={() => handleChangeStatus({...row, operation: AUTOMATION_OPERATIONS.UPDATE_STATUS})}
                  className='w-100 text-dark'
                  toggle={false}
                  disabled={isStatusUpdating}
                >
                  <div className="flex-center-start gap-8px">
                    {isStatusUpdating && activeRule?.operation === AUTOMATION_OPERATIONS.UPDATE_STATUS ? <Spinner size="sm" /> : row.is_active ? <Slash size={16} /> : <Check size={16} />}
                    <span className='align-middle ms-50'>
                      {t(`Mark as ${row.is_active ? 'Inactive' : 'Active'}`)}
                    </span>
                  </div>
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleChangeStatus({...row, operation: AUTOMATION_OPERATIONS.EDIT})}
                  className='w-100 text-dark'
                  toggle={false}
                  disabled={isSingleAutomationRuleFetching}
                >
                  <div className="flex-center-start gap-8px">
                    {isSingleAutomationRuleFetching && activeRule?.operation === AUTOMATION_OPERATIONS.EDIT ? <Spinner size="sm" /> : <Edit size={16} />}
                    <span className='align-middle ms-50'>{t('Edit')}</span>
                  </div>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setActiveRule({...row, operation: AUTOMATION_OPERATIONS.DELETE})
                    handleToggleDeleteRuleConfirmationModal()
                  }}
                  className='w-100 text-dark'
                  toggle={false}
                  disabled={isStatusUpdating}
                >
                  <div className="flex-center-start gap-8px">
                    {isStatusUpdating && activeRule?.operation === AUTOMATION_OPERATIONS.DELETE ? <Spinner size="sm" /> : <Trash2 size={16} />}
                    <span className='align-middle ms-50'>{t('Delete')}</span>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      }
    }
  ].filter(column => !column.omit);

  const tableActions = [
    {
      button: <Button ofStyle='outlined' onClick={handleOpenPriorityRule}>{t('Edit Priority')}</Button>,
      condition: hasEditRulePermission && tableData.length > 1 
    },
    {
      button: <Button onClick={handleOpenCreateRule} icon={() => (isConditionsFetching ? <Spinner size='sm' /> :  <Plus size={16} />)} disabled={isConditionsFetching}>{t('Create Rule')}</Button>,
      condition: hasCreateRulePermission
    }
  ].filter(action => action.condition).map(action => action.button)

  return (
    <div>
      <ExpandableCustomTable
        loading={isAutomationRulesFetching}
        success={isAutomationRulesSuccess}
        error={isAutomationRulesError}
        columns={tableColumns}
        columnName={ALL_COLUMN_NAME_MAPPING.ADD_ITEMS_AUTOMATION}
        searchcolumnsList={searchColumnList}
        data={tableData}
        TableHeaderComponent={tableActions?.length ? <div className='ps-14px gap-14px border-start border-primary-lighter flex-center-end'>{tableActions}</div> : null}
        showPagination={meta}
        meta={meta}
        handlePagination={handlePagination}
        search_column_query={searchQuery}
        handleQueryParams={handleSearchItem}
        showColumnsTableHeader={true}
        handleRefreshTable={refetchAutomationRules}
      />

      <EditRulePrioritySideSheet 
        isOpen={isPrioritySideSheetOpen} 
        toggle={handleClosePriorityRule}
        automationRules={automationRules}
        updatePriorityRules={handleUpdatePriorityRules}
        isPriorityRulesUpdating={isPriorityUpdating}
      />
      
      <Modal
        closeModalHandler={handleToggleDeleteRuleConfirmationModal}
        secondaryButtonHandler={() => handleChangeStatus({...activeRule, operation: AUTOMATION_OPERATIONS.DELETE})}
        secondaryButtonProps={{disabled: isStatusUpdating, icon: isStatusUpdating ? Spinner : null, iconSize: 'sm'}}
        primaryButtonHandler={handleToggleDeleteRuleConfirmationModal}
        secondaryButtonText={t('Yes, Delete')}
        primaryButtonText={t('Cancel')}
        title="Are you sure you want to delete this rule"
        content="This action will permanently delete this rule. You can't undo this action"
        isOpen={isDeleteRuleConfirmationModalOpen}
        isButtonVerticallyAlign={false}
      />
      
    </div>
  );
};

export default GiftItemTableSection;