import Button from '@src/@core/components/ui/button'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getOptionsFetcher } from '@src/utility/Utils'
import { loadBatchesConfig } from '@src/views/inventory/inventory.apis'
import { clearAssignmentDone, clearBatchResponse, clearScannedSkuDetails, setBatchAndSerialisationConfigForSku, setEnforcedFailReason, setGeneratedBarcode, setLastScannedSKUComplete, setScannedSkuDetails, updateEnforcedFailUploadIds, updateIsShelfLifeBreached } from '@src/views/inventory/store'
import { differenceInDays, parse } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BATCH_STATUS, SERIALISATION_OPTION, UNDEFINED } from '../constant'
import BinDisabledAllowShelfLifeModal from './binDisabledAllowShelfLifeModal'
import BinDisabledRestrictShelfLifeModal from './binDisabledRestrictShelfLifeModal'
import BinEnabledAllowShelfLifeModal from './binEnabledAllowShelfLifeModal'
import BatchAndSerialisationSetting from './qc-component/batchAndSerialisationSetting'
import CreateBatchModal from './qc-component/createBatchModal'
import GRNFinalStep from './qc-component/grnFinalStep'
import SkuCard from './qc-component/skuCard'

const EditIcon = (
  <svg width="12" height="12" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2333 4.90008L11.1 2.76674L11.8 2.06674C11.9889 1.87785 12.225 1.78619 12.5083 1.79174C12.7917 1.7973 13.0278 1.89452 13.2167 2.08341L13.9333 2.80008C14.1222 2.98896 14.2167 3.2223 14.2167 3.50008C14.2167 3.77785 14.1222 4.01119 13.9333 4.20008L13.2333 4.90008ZM12.5333 5.60008L4.13333 14.0001H2V11.8667L10.4 3.46674L12.5333 5.60008Z" fill="currentColor" />
  </svg>
)

const SkuDetailsAndQc = React.forwardRef(({ sku_details, maxQty, selectedTabId, searchBarcode, grnType, scannedBarcode }, ref) => {

  const { watch, control, setValue, formState: { errors }, setError, clearErrors } = useForm()
  const { t } = useTranslation()
  const grnResponse = useSelector(store => store.inventory.grn.grnResponse)
  const createBatchResponse = useSelector(store => store.inventory.createBatchResponse)
  const assignmentDone = useSelector(store => store.inventory.grn.assignmentDone)
  const latestSkuDetails = useSelector(store => store.inventory.grn.sku_details?.data)
  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const [isCreateBatchModalOpen, setIsCreateBatchModalOpen] = useState(false)
  const [qty, setQty] = useState(1)
  const [totalItemQtyToScan, setTotalItemQtyToScan] = useState(1)
  const [currentSkuConfig, setCurrentSkuConfig] = useState(null)
  const dispatch = useDispatch()
  const tableData = useSelector(store => store.inventory.binDisabledGrn.tableData)
  const [isBinEnabledAllowShelfLifeModalOpen, setIsBinEnabledAllowShelfLifeModalOpen] = useState(false)
  const [isBinDisabledAllowShelfLifeModalOpen, setIsBinDisabledAllowShelfLifeModalOpen] = useState(false)
  const [isBinDisabledRestrictShelfLifeModalOpen, setIsBinDisabledRestrictShelfLifeModalOpen] = useState(false)
  const isMaxShelfLifeEnforced = configurations?.find(item => item.configuration_type === 'acceptable_shelf_life_validation')?.configuration_values?.enabled
  const loadBatchesOptions = getOptionsFetcher(loadBatchesConfig)

  const {mutate: getShelfLifeFailReasons} = useApi({
    isMutation: true,
    apiKey: ['getShelfLifeFailReasons'],
    apiFn: () => {
      return axiosInstance.get('/api/v1/wms/reasons?reason_type=grn_items&system_defined=true&search_column=title&search_query=Below the minimum acceptable shelf life')
    },
    onSuccess: (data) => {  
      const shelfLifeFailReason = data?.data?.[0]
      dispatch(setEnforcedFailReason(shelfLifeFailReason))
    }
  })

  const handleNonSerialisedBatchedSku = (batch) => {
    dispatch(setGeneratedBarcode(''))
    setValue('omniful-sku-barcode', '')

    const currentBatchSku = tableData?.find(item => item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === batch?.id)

    if (sku_details?.serialisation_status === SERIALISATION_OPTION.non_serialised.id) {
      if (currentBatchSku) {
        const failQty = currentBatchSku?.reasons?.reduce((prev, curr) => prev + curr?.quantity, 0)
        dispatch(setScannedSkuDetails({
          data : { 
            ...sku_details,
            batch,
            pass_qty: isMaxShelfLifeEnforced ? 0 : currentBatchSku?.pass_qty,
            reasons: isMaxShelfLifeEnforced ? [] : currentBatchSku?.reasons,
            fail_qty: isMaxShelfLifeEnforced ? currentBatchSku?.fail_qty : (failQty || 0),
            scanned_qty:isMaxShelfLifeEnforced ? (currentBatchSku?.pass_qty + currentBatchSku?.fail_qty) : (currentBatchSku?.pass_qty + (failQty || 0))
          }
        }))
      } else if (!currentBatchSku) {
        dispatch(setScannedSkuDetails({
          data : { 
            ...sku_details,
            batch,
            pass_qty: 0,
            reasons:[],
            fail_qty:0,
            scanned_qty:0
          }
        }))
      }
    } else if (sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
      if (currentBatchSku) {
        const failQty = currentBatchSku?.reasons?.reduce((prev, curr) => prev + curr?.quantity, 0)
        dispatch(setScannedSkuDetails({
          data : { 
            ...sku_details,
            batch,
            pass_barcodes: isMaxShelfLifeEnforced ? [] : (currentBatchSku?.pass_barcodes || []),
            pass_qty: isMaxShelfLifeEnforced ? 0  : currentBatchSku?.pass_qty,
            reasons: isMaxShelfLifeEnforced ? [] : currentBatchSku?.reasons,
            fail_qty: isMaxShelfLifeEnforced ? currentBatchSku?.fail_qty : (failQty || 0),
            fail_barcodes: currentBatchSku?.fail_barcodes || []
          }
        }))
      } else if (!currentBatchSku) {
        dispatch(setScannedSkuDetails({
          data : { 
            ...sku_details,
            batch,
            pass_barcodes:[],
            pass_qty: 0,
            reasons:[],
            fail_qty:0,
            fail_barcodes:[]
          }
        }))
      }
    }
  }

  const handleCreateBatchModal = () => {
    setIsCreateBatchModalOpen(pre => !pre)
  }

  const formatOptionLabel = (option) => {
    return (
      <div className="batch-custom-format-option">
        <div className="txt-sub-rg title">{option.value.batch_num}</div>
        <div className="txt-asst-rg sub-title">Exp: {option.value.expiry_date}</div>
      </div>
    )
  }

  function formatSelectedLabel({ data }) {
    return <div className='react-select__single-value css-1duh8oh-singleValue'>{data.value.batch_num}</div>
  }

  useEffect(() => {
    if (!isBinDisabled) {
      if (!sku_details.isRoot) {
        setTotalItemQtyToScan(sku_details.maxQty - sku_details.openedQty - sku_details.closedAssignedQty)
      } else {
        if (sku_details.closedQty) {
          setTotalItemQtyToScan(sku_details.closedQty)
        }
      }
    }
  }, [sku_details.isRoot, sku_details.maxQty, sku_details.openedQty, sku_details.closedAssignedQty])

  useEffect(() => {
    if (createBatchResponse.is_success && createBatchResponse.data) {
      const selectedBatch = { value: { batch_num: createBatchResponse.data.external_batch_id, expiry_date: createBatchResponse.data.expiry_date }, label: createBatchResponse.data.external_batch_id, ...createBatchResponse.data }
      setValue('selected_batch', selectedBatch)
      dispatch(updateEnforcedFailUploadIds([]))
      if (sku_details?.batch_status === BATCH_STATUS.BATCHED) {
        const parsedExpiryDate = parse(selectedBatch.expiry_date, 'yyyy-MM-dd', new Date())
        const currentDate = new Date()
        const difference = differenceInDays(parsedExpiryDate, currentDate)
        if ((parseInt(difference) + 1) < parseInt(sku_details?.min_shelf_life)) {
          if (!isBinDisabled) { 
            if (isMaxShelfLifeEnforced) {
              dispatch(updateIsShelfLifeBreached(true))
            }
            setIsBinEnabledAllowShelfLifeModalOpen(p => !p)
          } else {
            if (isMaxShelfLifeEnforced) {
              getShelfLifeFailReasons()
              dispatch(updateIsShelfLifeBreached(true))
              setIsBinDisabledRestrictShelfLifeModalOpen(p => !p)
            } else {
              setIsBinDisabledAllowShelfLifeModalOpen(p => !p)
            }
          }
        }
      }
      dispatch(clearBatchResponse())
    }

  }, [createBatchResponse])

  const handleBatchSelect = (selectedBatch) => {
    if (selectedBatch) {
      clearErrors('selected_batch')
      dispatch(updateEnforcedFailUploadIds([]))
      if (isBinDisabled && sku_details?.batch_status === BATCH_STATUS.BATCHED) {
        handleNonSerialisedBatchedSku(selectedBatch)
      }
      if (sku_details?.batch_status === BATCH_STATUS.BATCHED) {
        const parsedExpiryDate = parse(selectedBatch.expiry_date, 'dd-MM-yyyy', new Date())
        const currentDate = new Date()
        const difference = differenceInDays(parsedExpiryDate, currentDate)
        if ((parseInt(difference) + 1) < parseInt(sku_details?.min_shelf_life)) {
          if (!isBinDisabled) { 
            if (isMaxShelfLifeEnforced) {
              dispatch(updateIsShelfLifeBreached(true))
            }
            setIsBinEnabledAllowShelfLifeModalOpen(p => !p)
          } else {
            if (isMaxShelfLifeEnforced) {
              dispatch(updateIsShelfLifeBreached(true))
              getShelfLifeFailReasons()
              setIsBinDisabledRestrictShelfLifeModalOpen(p => !p)
            } else {
              setIsBinDisabledAllowShelfLifeModalOpen(p => !p)
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (assignmentDone) {
      if (sku_details?.isRoot && !(sku_details.serialisation_status === SERIALISATION_OPTION.serialised.id && qty > 1)) {
        if (!sku_details.child_data || (sku_details.child_data && sku_details.openedAssignedQty >= sku_details.openedQty && sku_details.closedAssignedQty >= sku_details.closedQty)) {
          dispatch(setLastScannedSKUComplete(sku_details))
          dispatch(clearScannedSkuDetails())
        }
      }
      dispatch(clearAssignmentDone())
    }
  }, [assignmentDone])

  const resetBatchAndSerialisationConfig = () => {
    setCurrentSkuConfig({
      batch_status: sku_details?.batch_status,
      serialisation_status: sku_details?.serialisation_status
    })
    dispatch(setBatchAndSerialisationConfigForSku({ prevState: latestSkuDetails, skuId: sku_details.seller_sku_id, batchStatus: 'undefined', serialisationStatus: 'undefined' }))
    setValue('selected_batch', null)
  }

  useEffect(() => {
    if (isBinDisabled) {
      const totalScannedQty = (() => {
        const { pass_qty, fail_qty } = sku_details || {}

        if (pass_qty >= 0 && fail_qty === null) {
          return parseInt(pass_qty) + 1
        }
        if (fail_qty >= 0 && pass_qty === null) {
          return parseInt(fail_qty) + 1
        }
        if (pass_qty >= 0 && fail_qty >= 0) {
          return (parseInt(pass_qty) + parseInt(fail_qty)) + 1
        }
        return 1
      })()
      setTotalItemQtyToScan(totalScannedQty)
    }
  }, [])

  const skuInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
  const skuHasBeenScanned = skuInTable?.scanned_qty || skuInTable?.pass_qty || skuInTable?.fail_qty

  useEffect(() => { 
    if (sku_details?.is_gtin_enabled && !sku_details.sourceType && !watch('selected_batch') && sku_details.batch?.id) {
      setValue('selected_batch', sku_details.batch)
    }
  }, [sku_details])

  return (<>
    <div className='d-flex flex-column gap-12px'>
      {
        sku_details  && <SkuCard skuDetails={sku_details} maxQty={maxQty} selectedTabId={selectedTabId} qty={totalItemQtyToScan} setQty={setTotalItemQtyToScan} watch={watch} setValue={setValue} grnType={grnType} setError={setError} scannedBarcode={scannedBarcode}/>
      }
      {((sku_details?.batch_status === UNDEFINED || sku_details?.batch_status === undefined) && (sku_details?.serialisation_status === UNDEFINED || sku_details?.serialisation_status === undefined)) ?
        <BatchAndSerialisationSetting skuDetails={sku_details} currentSkuConfig={currentSkuConfig} setCurrentSkuConfig={setCurrentSkuConfig} /> 
        :
        <div className='flex-center-between border-left border-dark bg-light-3 p-12px rounded-8px'>
          <div className='d-flex flex-column gap-4px'>
            <div className='text-dark-6 txt-asst-md'>{t('SKU Configuration')}</div>
            <div className='d-flex align-items-center gap-8px text-dark txt-body-md'>
              <div>{sku_details?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? t('Non-Serialised Barcode') : t('Serialised Barcode')}</div>
              <span className='flex-center-center'>•</span>
              <div>{sku_details?.batch_status === BATCH_STATUS.BATCHED ? t('Batched SKU') : t('Non-Batched SKU')}</div>
            </div>
          </div>

          {sku_details?.is_gtin_enabled && sku_details.batch_status === BATCH_STATUS.BATCHED ? null : isBinDisabled ? 
            sku_details.is_configuration_editable && !skuHasBeenScanned && <div className='txt-body-md flex-center-start gap-4px cursor-pointer' style={{ color: 'var(--bs-primary)' }} onClick={resetBatchAndSerialisationConfig}>
              {EditIcon}
              <span>{t('Edit')}</span>
            </div>
            : 
            sku_details?.is_gtin_enabled && sku_details.batch_status === BATCH_STATUS.BATCHED ? null : sku_details.is_configuration_editable && <div className='txt-body-md flex-center-start gap-4px cursor-pointer' style={{ color: 'var(--bs-primary)' }} onClick={resetBatchAndSerialisationConfig}>
              {EditIcon}
              <span>{t('Edit')}</span>
            </div>}
        </div>
      }
      
      {
        (sku_details?.batch_status === BATCH_STATUS.BATCHED ?
          (!watch('selected_batch') ?
            <div className="d-flex gap-4px">
              <div className="flex-grow-1">
                <FloatingDropDown
                  loadOptions={loadBatchesOptions}
                  isRequired={true}
                  title='Select Batch'
                  name='selected_batch'
                  isAsync
                  control={control}
                  classNames={{
                    option: (state) => {
                      return state.isSelected ? 'text-white' : ''
                    }
                  }}
                  formatOptionLabel={formatOptionLabel}
                  formatSelectedLabel={formatSelectedLabel}
                  cacheUniqs={[createBatchResponse.is_success]}
                  additional={{
                    sellerSkuId: sku_details.seller_sku_id
                  }}
                  errors={errors}
                  onChangeFunc={handleBatchSelect}
                  modifiedStyles={{
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: '124px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
              <Button onClick={handleCreateBatchModal} ofStyle='noBackground' className='create-batch-cta'>{t('Create Batch')}</Button>
              <CreateBatchModal isOpen={isCreateBatchModalOpen} toggle={handleCreateBatchModal} sku_details={sku_details} />
            </div> 
            :
            <div className='flex-center-between border-left border-dark bg-light-3 p-12px rounded-8px'>
              <div className='flex-center-start gap-40px'>
                <div>
                  <div className='text-dark-6 txt-asst-md'>{t('Batch Number')}</div>
                  <div className='text-dark txt-body-md pt-4px max-width-150 text-truncate' title={sku_details?.batch?.label}>{sku_details?.batch?.label || watch('selected_batch').value?.batch_num}</div>
                </div>
                <div>
                  <div className='text-dark-6 txt-asst-md'>{t('Expiry Date')}</div>
                  <div className='text-dark txt-body-md pt-4px max-width-150 text-truncate' title={sku_details?.batch?.expiry_date}>{sku_details?.batch?.expiry_date || watch('selected_batch').value?.expiry_date}</div>
                </div>
                {sku_details?.gtin_serialisation_number ? <div>
                  <div className='text-dark-6 txt-asst-md'>{t('Serial No.')}</div>
                  <div className='text-dark txt-body-md pt-4px max-width-150 text-truncate' title={sku_details.gtin_serialisation_number}>{sku_details.gtin_serialisation_number}</div>
                </div> : null}
              </div>
              {sku_details?.is_gtin_enabled && !sku_details.sourceType && sku_details?.batch?.id ? null : <div className='txt-body-md flex-center-start gap-4px cursor-pointer' style={{ color: 'var(--bs-primary)' }} onClick={() => {
                dispatch(updateIsShelfLifeBreached(false))
                setValue('selected_batch', null)
              }}>
                {EditIcon}
                <span>{t('Edit')}</span>
              </div>}
            </div>
          )
          : 
          null)
      }
      {
        (sku_details?.batch_status === BATCH_STATUS.NOT_BATCHED || watch('selected_batch')) && <GRNFinalStep mainScannedBarcode={scannedBarcode} setCurrentSkuConfig={setCurrentSkuConfig} ref={ref} skuDetails={sku_details} watch={watch} setValue={setValue} totalItemQtyToScan={totalItemQtyToScan} errors={errors} control={control} qty={qty} setQty={setQty} selectedTabId={selectedTabId} grnResponse={grnResponse} searchBarcode={searchBarcode} />
      }
    </div>
    <BinEnabledAllowShelfLifeModal 
      isOpen={isBinEnabledAllowShelfLifeModalOpen} 
      toggle={() => setIsBinEnabledAllowShelfLifeModalOpen(p => !p)}
      isMaxShelfLifeEnforced={isMaxShelfLifeEnforced}
    />

    <BinDisabledAllowShelfLifeModal
      isOpen={isBinDisabledAllowShelfLifeModalOpen}
      toggle={() => setIsBinDisabledAllowShelfLifeModalOpen(p => !p)}
    />

    <BinDisabledRestrictShelfLifeModal
      isOpen={isBinDisabledRestrictShelfLifeModalOpen}
      toggle={() => setIsBinDisabledRestrictShelfLifeModalOpen(p => !p)}
    />
  </>
  )
})

export default SkuDetailsAndQc