/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import Button from '@src/@core/components/ui/button';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import InputField from '@src/@core/components/ui/input-field';
import Select from '@src/@core/components/ui/select';
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer';
import { MANUAL_COURIER_PARTNER } from '@src/App.constants';
import { axiosInstance } from '@src/network/AxiosInstance';
import MultipleAwbSideSheet from '@src/views/sales/components/MultipleAwbSideSheet/MultipleAwbSideSheet';
import { CREATE_SHIPMENT_STATUS, SHIPPING_ENTITY, SHIPPING_OPTION } from '@src/views/sales/constant/orders.constants';
import PrintDeliveryNote from '@src/views/sales/pending-actions/pages/orderDetails/orderDetailsTabPages/PrintDeliveryNote';
import { LIVE_ORDERS_TABS } from '@src/views/sales/sales.constant';
import { clearGetPrioritisedShippingPartners } from '@src/views/sales/store/store';
import { iCreateShipmentResponse, iPackage, iShipmentCreateStructKeys, Order } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { fetchSingleShipmentOrder, fetchSuggestedShippingAccounts, shipmentCreate, SHIPPING_AGGREGATOR_API_KEYS } from '@src/views/shipping-aggregator/shipping-aggregator.apis';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { format, parse } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Col, Form, Row, Spinner } from 'reactstrap';
import DetailsCard from '../../details-card';
import CreatedShipmentDetails from './created-shipment-details';
import ShippingPartnerConfig from './shipping-partner-config';
 
 type iCreateShipmentConfig = {
   orderID: string;
   shippingDetailsData: Order & { shipping_options: {key: string, tag: []}[]};
   omnifulGeneratedShipmentType: string;
   setOmnifulGeneratedShipmentType: (data: string) => void;
   entity_name: string;
   toggle: () => void;
   selectedPackages: iPackage[];
   cleanupOnSuccesHandler?: () => void
   shipmentCreated: boolean;
   setShipmentCreated: (data: boolean) => void;
 }
 
const CreateShipmentConfig = (props: iCreateShipmentConfig) => {
  const { orderID, shippingDetailsData, omnifulGeneratedShipmentType, entity_name = '', toggle, selectedPackages, cleanupOnSuccesHandler=()=>{}, shipmentCreated, setShipmentCreated } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const printManualAwbRef = useRef(null);
  const printDeliveryNoteRef = useRef(null);
  const [searchParams] = useSearchParams({});
  const [shippingPartnerOptions, setShippingPartnerOptions] = useState([{}]);
  const [shipmentDetails, setShipmentDetails] = useState<undefined |iCreateShipmentResponse >();
  const [ singleOrderDetailsData, setSingleOrderDetailsData] = useState<Order | undefined>(undefined);
  const orderDetails = singleOrderDetailsData;
  const tenantId = useSelector((store: {auth: {userData: {id: number}}}) => store.auth.userData.id);
  const [getPrioritisedShippingPartnersResonse, setGetPrioritisedShippingPartnersResonse] = useState<undefined | {rule_id: number}>();
  const [manualPrintLoadingState, setManualPrintLoadingState] = useState('');
  const [multipleAwbModalOpen, setMultipleAwbModalOpen] = useState(false);
  const [multipleAwbList, setMultipleAwbList] = useState<unknown[]>([]);
  const [packageData, setPackageData] = useState([]);
  const [isCityEditing, setIsCityEditing] = useState(false);
  const [createShipmentStatus, setCreateShipmentStatus] = useState(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED);
  const defaultCountry = { value: shippingDetailsData.origin_details?.address?.country.id, label: shippingDetailsData.origin_details?.address?.country.name };
  const defaultCity = { value: shippingDetailsData.origin_details?.address?.city.id, label: shippingDetailsData.origin_details?.address?.city.name };
  const omnifulCountry = { value: shippingDetailsData.receiver_address?.omniful_country_id, label: shippingDetailsData.receiver_address?.omniful_country };
  const omnifulCity = { value: shippingDetailsData.receiver_address?.omniful_city_id, label: shippingDetailsData.receiver_address?.omniful_city };
  console.log({manualPrintLoadingState, omnifulCountry, printManualAwbRef});
 
  const { handleSubmit, register, formState: { errors }, watch, setValue, control, clearErrors } = useForm({
    defaultValues: {
      awb: '',
      weight: selectedPackages.reduce((total, singlePackage) => (+singlePackage.package_info.weight.value || 0) + total, 0).toFixed(2),
      remarks: '',
      shipment_type: '',
      number_of_boxes: selectedPackages.length,
      city: {},
      country: {},
      city_id_from: defaultCity,
      country_id_from: defaultCountry,
      shipping_partner: null,
      shipping_reference: '',
      suggested_shipping_account: null,
      courier_partner: null
    },
    mode: 'onChange'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;
 
  const { mutate: getSuggestedAccounts } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_SUGGESTED_ACCOUNTS],
    mutationFn: (body: object) => fetchSuggestedShippingAccounts({body}),
    onSuccess: ({data: {data}}) => {
      setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED);
      const shippingPartnerOption = Object.values(data as object)[0].eligible_shipping_accounts.map((ele: {
         account_name: string,
         shipping_partner: { name: string, logo: string },
         id: number,
 
       }) => {
        const storedObj = {
          ...ele,
          label: `${ele.account_name} (${ele.shipping_partner.name})`,
          value: ele.id,
          logo: ele.shipping_partner.logo
        };
        return storedObj;
      });
      setShippingPartnerOptions(shippingPartnerOption);

      const storeNextLocation = Object.values(data as object)?.[0]?.next_location;
      console.log({storeNextLocation});
 
      const actionValue = data.action_value;
 
      setGetPrioritisedShippingPartnersResonse(data);
 
      if (shippingPartnerOption[0]) {
        setValue('next_location', storeNextLocation?.id ? {label: storeNextLocation.name, value: storeNextLocation.id} : null);
        setValue('shipping_partner', shippingPartnerOption[0]);
        setValue('suggested_shipping_account', shippingPartnerOption[0]);
        const serviceTypes = shippingPartnerOption[0]?.shipping_partner?.details?.service_types;
        const productType = shippingPartnerOption[0]?.shipping_partner?.product_type;
        const productTypeOptions = productType?.value;
        const detailsProductType = shippingPartnerOption[0]?.details?.[productType?.key_name];
        const defaultServiceType = serviceTypes?.find((item: {value: string}) => item.value === shippingPartnerOption[0]?.details?.service_type);
        setValue('service_type', defaultServiceType || null);
        if (productType?.key_name && productTypeOptions?.length) {
          const defaultProductType = actionValue?.product_type?.find((item: {id: string}) => item.id === shippingPartnerOption[0].id);
          const defaultDetailsProductType = productTypeOptions?.find((item: {value: string}) => item.value === detailsProductType);
          const defaultValue = defaultProductType?.value || defaultDetailsProductType || null;
          setValue(productType.key_name, defaultValue);
        }
      }
    }
  });
 
  const { mutate: createShipment, isPending: isCreateShipmentPending } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.CREATE_SHIPMENT],
    mutationFn: (body: object) => shipmentCreate({order_id: orderID, body}),
    onSuccess: ({ data: { data } }) => {
      CustomToast('Shipment created successfully', {my_type: 'success'});
      setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED);
      setShipmentCreated(true);
      setShipmentDetails(data);
      cleanupOnSuccesHandler();
    }
  });
 
  const { mutate: getSingleOrderDetails, isPending: getSingleOrderDetailsPending } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_SINGLE_ORDER_DETAIL],
    mutationFn: () => fetchSingleShipmentOrder({id: orderID, params:{package_status: 'new'}}),
    onSuccess: ({ data }) => {
      setSingleOrderDetailsData(data);
    }
  });

  const { mutate: fetchSingleShipmentAWB, isPending: singleShipmentAWBLodading } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.FETCH_SHIPMENT_AWB],
    mutationFn: ({ shipmentId}: {shipmentId: string | number}) => {
      return axiosInstance.get(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}/awb`);
    },
    onSuccess: ({ data }) => {
      window.open(data.data.awb_label, '_blank');
    }
  });
 
  const city_Id = typeof watch('city')?.value === 'string' ? +watch('city').value?.split('-')?.[0] : watch('city')?.value;
  const shippingPartnerObj: { tag: []; key: string } | undefined  = shippingDetailsData.shipping_options?.find(option => option.key === watch('shipment_type'));
  const isCourierPartnerForShipping = watch('shipping_partner')?.shipping_partner?.fetch_courier_partners;
  const shipping_partner_tag = watch('shipping_partner')?.shipping_partner?.tag || shippingPartnerObj?.tag;
  const { loading: loadingFetchDimensions } = useSelector((state: {shipping: {shippingPartnerMethods: {loading: boolean}}}) => state.shipping.shippingPartnerMethods);
  const isCityMappingRequired = watch('shipping_partner')?.shipping_partner.details?.is_city_mapping_required;
  const dropCity = watch('shipping_partner')?.shipping_partner.mapped_shipping_partner_cities?.drop_city;
  const isFetch_multi_dimensions = watch('shipping_partner')?.shipping_partner.details?.fetch_multi_dimensions;
 
  const isCreateShipmentDisabled = !!((isCityMappingRequired && !dropCity) || isCityEditing);
 
  useEffect(() => {
    return () => {
      setManualPrintLoadingState('');
    };
  }, []);
 
  const handleOrderDetailsData = () => {
    getSingleOrderDetails();
  };
 
  const handleEditOrderDetails = () => {
    dispatch(clearGetPrioritisedShippingPartners());
    setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED);
    // setIsRemarkDisabled(false);
    setValue('shipping_partner', null);
    setValue('suggested_shipping_account', null);
    setValue('shipping_reference', null);
    clearErrors('remarks');
    setIsCityEditing(false);
  };
 
  const onSubmit = (data: any) => {
    let shipmentData:iShipmentCreateStructKeys = {};
    // The exact same struct is sent
    const fromVillage = data.from_village || {};
    const toVillage = data.to_village || {};
    delete fromVillage.value;
    delete fromVillage.label;
    delete toVillage.value;
    delete toVillage.label;
 
    if (omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name) {
      shipmentData = {
        package_ids: selectedPackages.map((el) => el.id),
        shipment_mode: omnifulGeneratedShipmentType,
        type: 'forward',
        next_location_id: data.next_location?.value || undefined,
        shipment_details: {
          remarks: data.remarks
        },
        current_location_id: selectedPackages[0]?.current_location_id,
        order_address: {
          to: {
            city_id: String(data.city.value),
            city: data.city.label,
            country_id: String(data.country.value),
            country: data.country.label
          },
          village_details: {
            from: fromVillage,
            to: toVillage
          }
        },
        shipping_partner_tag: data.shipping_partner?.shipping_partner?.tag,
        shipping_account: {
          id: data.shipping_partner?.id,
          name: data.shipping_partner?.account_name
        },
        omniful_rule_id: getPrioritisedShippingPartnersResonse?.rule_id,
        shipping_reference: data.shipping_reference
      };
      if (data.length && data.breadth && data.height) {
        shipmentData.shipment_details.shipment_packages = isFetch_multi_dimensions ? packageData :
          [
            {
              length: parseInt(data.length),
              breadth: parseInt(data.breadth),
              height: parseInt(data.height)
            }
          ];
      }
    }
 
    if (omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name || omnifulGeneratedShipmentType === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) {
      shipmentData = {
        shipment_type: omnifulGeneratedShipmentType,
        awb_number: data.awb,
        type: 'forward',
        tracking_url: data.tracking_url,
        shipment_details: {
          remarks: data.remarks
        },
        order_address: (data.country?.value || data.city?.value) && {
          to: {
            city_id: data.city?.value && String(data.city.value),
            city: data.city?.label,
            country_id: data.country?.value && String(data.country.value),
            country: data.country?.label
          }
        }
      };
    }
 
    if (data.courier_partner && data.courier_partner.value) {
      shipmentData.courier_partner = {
        id: data.courier_partner.value.id,
        name: data.courier_partner.value.name,
        tag: data.courier_partner.value.tag,
        logo: data.courier_partner.value.logo
      };
      if (data.courier_partner.value.id === MANUAL_COURIER_PARTNER.value) {
        delete shipmentData.courier_partner.id;
      }
    }
    if (watch('shipping_partner')?.shipping_partner.details?.show_delivery_slot) {
      const parsedDate = parse(data.delivery_date.value, 'dd-MM-yyyy', new Date());
      shipmentData.order_details.slot = {
        start_time: data.delivery_start_time,
        end_time: data.delivery_end_time,
        delivery_date: format(parsedDate, 'ddMMyyyy')
      };
    }
    if (watch('parcel_shop')?.value) {
      shipmentData.pre_shipment_details = {
        parcel_shop_id: watch('parcel_shop')?.value
      };
    }
    if (watch('nearby_points_details')?.value) {
      shipmentData.pre_shipment_details = {
        point_id: watch('nearby_points_details')?.value
      };
    }
 
    if (data.service_type) {
      shipmentData.service_type = data.service_type.value;
    }
    const productType = data?.shipping_partner?.shipping_partner?.product_type?.forward;
    if (productType?.key_name && data[productType?.key_name]?.value) {
      shipmentData.pre_shipment_details = {
        ...shipmentData.pre_shipment_details,
        [productType.key_name]: data[productType.key_name].value
      };
    }
    if (entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value) {
      createShipment(shipmentData);
    } else {
      createShipment(shipmentData);
    }
  };
 
  const orderDetailsFieldConfigurations = [
    {
      name: 'number_of_boxes',
      label: 'No of Packages',
      validationSchema: {
        required: 'Packages Quantity is required',
        min: {
          value: 1,
          message: t('Please enter a valid number')
        },
        validate: {
          'less-than-100': (fieldValue: string) => {
            return +fieldValue <= 100 || 'Packages Quantity cannot be more than 100';
          },
          'is-whole-number': (fieldValue: string) => {
            return /^[0-9]+$/.test(fieldValue) || 'Packages Quantity must be a whole number';
          }
        }
      },
      isClearable: true,
      isRequired: true,
      type: 'number',
      disabled: true
    },
    {
      name: 'weight',
      label: 'Weight in KG',
      validationSchema: {
        required: 'Weight is required',
        min: {
          value: 0,
          message: t('Please enter a valid number')
        },
      },
      type: 'number',
      disabled: true
    },
    {
      name: 'remarks',
      label: 'Enter Remarks',
      type: 'textarea',
      // isRequired: isCourierPartnerForShipping,
      // validationSchema: {
      //     required: 'Remarks is required'
      // },
      rows: '3'
      // disabled: isRemarkDisabled
    }
  ];
 
  // const handleGetShippingPartnerOrder = async () => {
  //   try {
  //     const body = {
  //       package_ids: selectedPackages.map((el) => el.id)
  //     };
  //     getSuggestedAccounts(body);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
 
  const togglemultipleAwbModal = (awbData: unknown[]) => {
    setMultipleAwbList(
      awbData
    );
    setMultipleAwbModalOpen((p) => !p);
  };
 
  useEffect(() => {
    const body = {
      package_ids: selectedPackages.map((el) => el.id)
    };
    getSuggestedAccounts(body);
    handleEditOrderDetails();
  }, [shippingDetailsData]);
 
  useEffect(() => {
    if (omnifulGeneratedShipmentType !== watch('shipment_type')) {
      setValue('shipment_type', omnifulGeneratedShipmentType);
    }
  }, [omnifulGeneratedShipmentType]);

  console.log({errors});
 
  return (
    <>
      <div className="">
 
        {!shipmentCreated ? (
          <div>
            <Form onSubmit={handleSubmit(onSubmit)} className='d-flex flex-column gap-20px'>
              <div className='d-flex flex-column gap-24px'>
                <div className='w-100 d-flex flex-column gap-16px'>
                  <div className='text-dark txt-h3-md'>{t('Package Details')}</div>
                  <div className='order-package-details-fields'>
                    {orderDetailsFieldConfigurations.map((config, index) => (
                      <div className={classNames('', { 'grid-desc-col': (orderDetailsFieldConfigurations.length - 1) === index })} key={index}>
                        {config.type === 'select' ?
                          <Controller
                            name={config.name}
                            rules={{required: false}}
                            render={({ field }) => {
                              {/* @ts-expect-error fix */}
                              return <Select
                                label={config.label}
                                isRequired={config.isRequired}
                                {...field}
                              />;
                            }}
                          /> :
                          <InputField
                            key={config.name}
                            errors={errors}
                            {...register(config.name, {
                              ...config.validationSchema,
                              required: config.isRequired ? t(config.validationSchema.required) : undefined
                            })}
                            {...config}
                          />
                        }
                      </div>
                    ))}
                  </div>
                </div>
              </div>
 
              <div className="d-flex flex-column gap-12px pb-72px">
                <div className="d-flex w-100 flex-column gap-12px">
                  {((omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name) && (createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED)) &&
                     <div className='w-100 d-flex flex-column gap-16px py-8px'>
                       <ShippingPartnerConfig
                         register={register}
                         errors={errors}
                         setValue={setValue}
                         clearErrors={clearErrors}
                         control={control}
                         watch={watch}
                         createShipmentStatus={createShipmentStatus}
                         isCourierPartnerForShipping={isCourierPartnerForShipping}
                         shippingPartnerOptions={shippingPartnerOptions}
                         omnifulCity={omnifulCity}
                         isCityEditing={isCityEditing}
                         setIsCityEditing={setIsCityEditing}
                         packageData={packageData}
                         setPackageData={setPackageData}
                         shippingDetailsData={shippingDetailsData}
                         shipping_partner_tag={shipping_partner_tag}
                         tenantId={tenantId}
                         city_Id={city_Id}
                         sellerId={shippingDetailsData.seller_id}
                       />
                     </div>
                  }
                </div>
              </div>
 
              <SidesheetFooter className="create-shipment-sidesheet-footer">
                <div className="d-flex justify-content-end gap-16px">
                  <Button ofStyle='outlined' type='button' onClick={toggle}>{t('Cancel')}</Button>
                  <Button type="submit" disabled={loadingFetchDimensions || (createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED && !watch('shipping_partner')) || isCreateShipmentDisabled || isCreateShipmentPending || !!Object.entries(errors).length}>
                    {(isCreateShipmentPending) && <Spinner size="sm" />}
                    <span className={classNames({ 'ms-50': isCreateShipmentPending })}>{t('Create Shipment')}</span>
                  </Button>
                </div>
              </SidesheetFooter>
 
            </Form>
          </div>
        ) : (
          <>
            {omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name && (
              <div>
                <DetailsCard title='Shipment Detail'>
                  <div>
                    <CreatedShipmentDetails shippingDetailsData={shipmentDetails?.shipping_address} />
                    <Row className="my-1">
                      <Col className="col-3">{t('Order Status')}</Col>
                      <Col className="text-secondary col-9">
                        {(shipmentDetails && shipmentDetails.shipment.status) || '-'}
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col className="col-3">{t('Shipping Partner Order Id')}</Col>
                      <Col className="text-secondary col-9">
                        {(shipmentDetails && shipmentDetails.shipment.shipping_partner_order_id) || '-'}
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col className="col-3">{t('AWB Number')}</Col>
                      <Col className="text-secondary col-9">
                        {(shipmentDetails && shipmentDetails.shipment.awb_number) || '-'}
                        {(shipmentDetails?.shipment.metadata?.tracking_info?.length || 0) > 1 &&
                         <span
                           onClick={() => togglemultipleAwbModal(shipmentDetails?.shipment.metadata.tracking_info || [])}
                           className={'text-primary ms-12px cursor-pointer'}
                         >
                           {`+${(shipmentDetails?.shipment.metadata.tracking_info || []).length - 1}`}
                         </span>}
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col className="col-3">{t('Shipping Partner Status')}</Col>
                      <Col className="text-secondary col-9">
                        {(shipmentDetails && shipmentDetails.shipment.shipping_partner_status) || '-'}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-3">{t('Shipping Partner Name')}</Col>
                      <Col className="text-secondary col-9">
                        {(shipmentDetails && shipmentDetails.shipment.shipping_partner.name) || '-'}
                      </Col>
                    </Row>
                  </div>
                </DetailsCard>
                 
                <SidesheetFooter className="create-shipment-sidesheet-footer">
                  <div className="d-flex justify-content-end gap-16px">
                    <Button ofStyle='outlined' type='button' onClick={toggle}>{t('Close')}</Button>
                    {(searchParams.get('currentTab') !== LIVE_ORDERS_TABS.PACKING.value) && <div className="d-flex justify-content-end gap-16px bg-white">
                      {shipmentDetails && shipmentDetails.shipment.print_awb_allowed &&
                          <Button disabled={singleShipmentAWBLodading} onClick={() => {
                            if (shipmentDetails.shipment.metadata.awb_label) {
                              window.open(shipmentDetails.shipment.metadata.awb_label, '_blank');
                            } else {
                              fetchSingleShipmentAWB({shipmentId: shipmentDetails.shipment.id || ''});
                            }
                          }}>
                            {singleShipmentAWBLodading && <Spinner size="sm" />}
                            <span className={classNames({ 'ms-50': singleShipmentAWBLodading })}>{t('Print AWB')}</span>
                          </Button>}
                      {shipmentDetails && shipmentDetails.shipment.print_delivery_note_allowed && (!orderDetails ?
                        <Button disabled={getSingleOrderDetailsPending} onClick={handleOrderDetailsData}>{getSingleOrderDetailsPending && <Spinner size="sm" />}
                          <span className={classNames({ 'ms-50': getSingleOrderDetailsPending })}>{t('Print Delivery Note')}</span>
                        </Button> : <PrintDeliveryNote printDeliveryNoteRef={printDeliveryNoteRef} buttonClicked={true} orderDetails={orderDetails} />)}
                    </div>}
                  </div>
                </SidesheetFooter>
              </div>
            )}
          </>
        )}
      </div>
      <MultipleAwbSideSheet modalOpen={multipleAwbModalOpen} data={multipleAwbList} toggleSidebar={togglemultipleAwbModal} />
    </>
  );
};
 
export default CreateShipmentConfig;
