/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@src/@core/components/ui/button';
import InputField from '@src/@core/components/ui/input-field';
// import CheckBox from '@src/@core/components/ui/new-checkbox';
import PhoneNumberDropdown from '@src/@core/components/ui/phone-number';
import Select from '@src/@core/components/ui/select';
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer';
import { loadCitiesConfig, loadCountryConfig, loadStatesCitiesConfig, loadStatesConfig } from '@src/app.apis';
import { getOptionsFetcher } from '@src/utility/Utils';
import React from 'react';
import { Info } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { iAddPickupLocationProps } from '../../shipping-aggregator-types';
import { DEFAULT_VALUE_FOR_PICKUP_LOCATION_FIELDS, PATH_NAME, PICKUP_LOCATION_FIELDS_NAME } from '../../shipping-aggregator.constant';
const AddPickupLocationSidesheetForm: React.FC<iAddPickupLocationProps> = (props) => {
  const { toggleSideSheet, handleFormSubmit, loading } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  /* @ts-expect-error fix */
  const loadStatesOptions = getOptionsFetcher(loadStatesConfig);
  const { register, control, watch, setValue, formState: { errors }, handleSubmit, setError, clearErrors } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...DEFAULT_VALUE_FOR_PICKUP_LOCATION_FIELDS
    }
  });

  const handleSubmitFunc = (data: object) => {
    handleFormSubmit(data);
  };// @ts-expect-error Fix Me
  const loadCitiesOptions = getOptionsFetcher(loadCitiesConfig); // @ts-expect-error Fix Me
  const loadStatesCitiesOptions = getOptionsFetcher(loadStatesCitiesConfig);
  // @ts-expect-error Fix Me
  const loadCountryOptions = getOptionsFetcher(loadCountryConfig);
  return (
    <form className='p-24px d-flex flex-column h-100 align-items-center justify-content-between gap-20px' style={{ marginBottom: '72px' }}>
      <div className='w-100 h-100 d-flex flex-column gap-20px'>
        <InputField
          label='Pickup Address Name'
          isRequired
          {...register(PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_NAME, {
            required: 'This field is required'
          })}
          /* @ts-expect-error fix */
          errors={errors}
        />
        <InputField
          label='Address Line 1'
          isRequired
          {...register(PICKUP_LOCATION_FIELDS_NAME.ADDRESS_LINE_1, {
            required: 'This field is required'
          })}
          /* @ts-expect-error fix */
          errors={errors}
        />
        <InputField
          /* @ts-expect-error fix */
          label='Address Line 2'
          {...register(PICKUP_LOCATION_FIELDS_NAME.ADDRESS_LINE_2)}
        />

        <div className='d-flex w-100 gap-16px'>
          <div className='w-100'>
            <Controller
              name={PICKUP_LOCATION_FIELDS_NAME.COUNTRY}
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label='Country'
                    isAsync
                    isRequired
                    isClearable
                    /* @ts-expect-error fix */
                    value={watch(PICKUP_LOCATION_FIELDS_NAME.COUNTRY)}
                    /* @ts-expect-error fix */
                    loadOptions={loadCountryOptions}
                    onChange={(e: any) => {
                      field.onChange(e);
                      setValue(PICKUP_LOCATION_FIELDS_NAME.STATE, null);
                      setValue(PICKUP_LOCATION_FIELDS_NAME.CITY, null);
                    }}
                    /* @ts-expect-error fix */
                    errors={errors}
                  />
                );
              }}
            />
          </div>
          <div className='w-100'>
            <Controller
              name={PICKUP_LOCATION_FIELDS_NAME.STATE}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label='State'
                    isAsync
                    /* @ts-expect-error fix */
                    value={watch(PICKUP_LOCATION_FIELDS_NAME.STATE)}
                    /* @ts-expect-error fix */
                    loadOptions={loadStatesOptions}
                    isClearable
                    isDisabled={!watch(PICKUP_LOCATION_FIELDS_NAME.COUNTRY)}
                    additional={{
                      country: watch(PICKUP_LOCATION_FIELDS_NAME.COUNTRY)
                    }}
                    cacheUniqs={[watch(PICKUP_LOCATION_FIELDS_NAME.COUNTRY)]}
                    onChange={(e: any) => {
                      field.onChange(e);
                      setValue(PICKUP_LOCATION_FIELDS_NAME.CITY, null);
                    }}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className='d-flex w-100 gap-16px'>
          <div className='w-100'>
            <Controller
              name={PICKUP_LOCATION_FIELDS_NAME.CITY}
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label='City'
                    isAsync
                    isRequired
                    isClearable
                    /* @ts-expect-error fix */
                    value={watch(PICKUP_LOCATION_FIELDS_NAME.CITY)}
                    /* @ts-expect-error fix */
                    loadOptions={watch(PICKUP_LOCATION_FIELDS_NAME.STATE) ? loadStatesCitiesOptions : loadCitiesOptions}
                    isDisabled={!watch(PICKUP_LOCATION_FIELDS_NAME.COUNTRY)}
                    additional={{
                      state: watch(PICKUP_LOCATION_FIELDS_NAME.STATE),
                      params: {
                        country_id: watch(PICKUP_LOCATION_FIELDS_NAME.STATE) ? null
                          /* @ts-expect-error fix*/
                          : watch(PICKUP_LOCATION_FIELDS_NAME.COUNTRY)?.value,
                      }
                    }
                    }
                    cacheUniqs={watch(PICKUP_LOCATION_FIELDS_NAME.STATE) ? [watch(PICKUP_LOCATION_FIELDS_NAME.STATE)] : [watch(PICKUP_LOCATION_FIELDS_NAME.COUNTRY)]}
                    onChange={(e: any) => {
                      field.onChange(e);
                    }}
                    /* @ts-expect-error fix */
                    errors={errors}
                  />
                );
              }}
            />
          </div>
          <div className='w-100'>
            <InputField
              label='Zip Code'
              {...register(PICKUP_LOCATION_FIELDS_NAME.ZIP_CODE, {
                required: false
              })}
              /* @ts-expect-error fix */
              errors={errors}
            />
          </div>
        </div>

        {/* @ts-expect-error fix */}
        <PhoneNumberDropdown
          name={PICKUP_LOCATION_FIELDS_NAME.CONTACT_DETAILS}
          contactNumber={watch(PICKUP_LOCATION_FIELDS_NAME.contact_details?.contactNumber)}
          countryCode={watch(PICKUP_LOCATION_FIELDS_NAME.contact_details?.countryCode)}
          control={control}
          errors={errors}
          register={register}
          isClearable
          label="Contact Number"
          isRequired
          setError={setError}
          clearErrors={clearErrors}
        />

        {/* <CheckBox
          type="checkbox"
          label="Pickup location will be pre-selected by default. Uncheck if not needed."
          {...register(PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_PRE_SELECTED)}
          checked={watch(PICKUP_LOCATION_FIELDS_NAME.PICKUP_LOCATION_PRE_SELECTED)}
          isRequired={false}
        /> */}
      </div>

      <div className='w-100 h-fit py-8px px-12px rounded-8px d-flex gap-12px' style={{ background: 'var(--bs-primary-lighter)' }}>
        <span className='m-0 w-fit'>
          <Info className='text-primary' size={20} fill='var(--bs-primary)' stroke='var(--bs-white)' />
        </span>
        <p className='m-0 txt-sub-rg text-dark-6'>{t('Pickup address is only for creating shipments and are not linked to inventory or warehouse operations. To view or manage your pickup address, head to the')} {' '}
          <a className='text-primary' onClick={() => navigate(PATH_NAME.PICKUP_ADDRESS)}>{t('Pickup Address')}</a> {t(' tab.')}
        </p>
      </div>

      <SidesheetFooter className='d-flex gap-12px'>
        <Button ofStyle='outlined' type='button' onClick={toggleSideSheet}>{t('Cancel')}</Button>
        <Button type='button' loading={loading} onClick={handleSubmit(handleSubmitFunc)}>{t('Add')}</Button>
      </SidesheetFooter>
    </form>
  );
};

export default AddPickupLocationSidesheetForm;