import { BANNER_KEYS, LOCAL_STORAGE_KEYS } from '@src/App.constants';
import { useEffect, useState } from 'react';
import { isObjEmpty } from '../Utils';

const useBannerVisibility = (bannerKey) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    const storedBannerData = localStorage.getItem(LOCAL_STORAGE_KEYS.BANNER_KEY);
    const localStorageBannerData = storedBannerData && JSON.parse(storedBannerData) || {};
    if (isObjEmpty(localStorageBannerData)) {
      const defaultVisibility = {};
      Object.values(BANNER_KEYS).forEach((value) => {
        defaultVisibility[value] = true;
      });
      localStorage.setItem(LOCAL_STORAGE_KEYS.BANNER_KEY, JSON.stringify(defaultVisibility));
      setIsBannerVisible(true);
    } else {
      if (bannerKey in localStorageBannerData) {
        setIsBannerVisible(localStorageBannerData[bannerKey]);
      } else {
        setIsBannerVisible(true);
      }
    }
  }, [bannerKey]);

  return [isBannerVisible, setIsBannerVisible];
};

export default useBannerVisibility;
