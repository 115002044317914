/* eslint-disable @typescript-eslint/no-explicit-any */
import PageHeader from '@src/@core/components/ui/page-header';
import SwitchButton from '@src/@core/components/ui/switch-buttons';
import Omniship from '@src/assets/images/icons/primary-navbar/Omniship';
import FilterTabs from '@src/components/filterTabs';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { iShipmentOrderView } from '../../shipping-aggregator-types';
import { ORDERS_TABS, PACKAGE_TABS, SHIPMENT_ORDERS_VIEW_OPTIONS } from '../../shipping-aggregator.constant';
import './styles.scss';

const Orders: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableMetaData, setTableMetaData] = useState({});
  const shipmentOrderView = (Object.values(SHIPMENT_ORDERS_VIEW_OPTIONS).find((view) => view.tab_name === searchParams.get('current_view'))) || SHIPMENT_ORDERS_VIEW_OPTIONS.ORDER;
  const isShipmetOrdersView = shipmentOrderView.id === SHIPMENT_ORDERS_VIEW_OPTIONS.ORDER.id;
  const activeTab = (Object.values(isShipmetOrdersView ? ORDERS_TABS : PACKAGE_TABS).find((item) => item.id === searchParams.get('current_tab')) || (isShipmetOrdersView ? ORDERS_TABS.NEW_ORDERS : PACKAGE_TABS.NEW_PACKAGES));
  const pageHeaderProps: any = {
    breadcrumbIcon: Omniship,
    title: isShipmetOrdersView ? 'Shipment Orders' : 'Shipment Packages',
    breadcrumbsData: [{ title: 'OmniShip' }]
  };
  
  const toggle = (tab: { id: string }) => {
    setSearchParams({ current_tab: tab.id, current_view:  shipmentOrderView.tab_name});
  };

  const handleViewChange = (view: iShipmentOrderView) => {
    setSearchParams({current_view: view.tab_name});
  };

  const ActiveComponent: any = activeTab.component;

  // const handleBannerClick = (id: string) => {
  //   let filterTab;
  //   if (id === REDIRECTION_SHIPMENT_ORDERS_BANNER.READY_TO_SHIP.id) {
  //     filterTab = ORDERS_TABS.READY_TO_SHIP.id;
  //   } else if (id === REDIRECTION_SHIPMENT_ORDERS_BANNER.AT_HUB.id) {
  //     filterTab = ORDERS_TABS.AT_HUB.id;
  //   } else if (id === REDIRECTION_SHIPMENT_ORDERS_BANNER.IN_TRANSIT_ORDERS.id) {
  //     filterTab = ORDERS_TABS.IN_TRANSIT.id;
  //   }
  //   console.log({filterTab});
  //   if (filterTab) navigate(`/shipments/orders?current_tab=${filterTab}`);
  // };

  console.log({activeTab: activeTab.id, shipmentOrderView, tableMetaData});

  return (
    <div className='shipping-aggregator'>
      <div className='position-relative'>
        <PageHeader {...pageHeaderProps} />
        <div className='page-header-right-section'>
          <SwitchButton
            selectedButton={shipmentOrderView}
            onChange={handleViewChange}
            buttons={Object.values(SHIPMENT_ORDERS_VIEW_OPTIONS)}
          />
        </div>
      </div>
      <div className='px-16px pb-16px bg-white'>
      </div>
      <FilterTabs tabDataList={Object.values(isShipmetOrdersView ? ORDERS_TABS : PACKAGE_TABS)} selectedTabId={activeTab.id} onTabChange={(tab: { id: string }) => toggle(tab)} />
      <ActiveComponent searchParams={searchParams} setSearchParams={setSearchParams} activeTab={activeTab} shipmentOrderView={shipmentOrderView} selectedTabId={activeTab.id} setTableMetaData={setTableMetaData} />
    </div>
  );
};

export default Orders;