/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable';
import NewCheckbox from '@src/@core/components/ui/new-checkbox';
import { SIMPLE } from '@src/views/catalog/catalog.constants';
import CreateSku from '@src/views/catalog/components/sku-master/CreateSku';
import { CHOOSE_SKU_KEY } from '@src/views/sales/pending-actions/components/add-items-po/add-items-columns';
import useCreateShipmentsSkuTableCol from '@src/views/shipping-aggregator/hooks/use-create-shipments-sku-table-col';
import React, { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MenuProps } from 'react-select';
import { CREATE_ORDER_FIELDS_NAME } from '../../../../shipping-aggregator.constant';
import '../../style.scss';

type iSearchSkuDropdownProps = {
  formState: any
  packageIndex: number
  packageName: string
  nameFieldSearchName: string
  barcodeFieldSearchName: string
  sellerSkuCodeFieldSearchName: string
  packageValue: string
}

const SelectSkuSearchFields = ({ formState, nameFieldSearchName, barcodeFieldSearchName, sellerSkuCodeFieldSearchName }: any) => {

  const { t } = useTranslation();

  return <div className='w-100'>
    <div className='flex-center-start gap-12px'>
      <div>
        {t('Search results shown by:')}
      </div>
      <div className='flex-center-start gap-8px'>
        <NewCheckbox
          type="checkbox"
          label="Name"
          {...formState.register(nameFieldSearchName)}
          checked={formState.watch(nameFieldSearchName)}
          isRequired={false}
          disabled={!formState.watch(barcodeFieldSearchName) && !formState.watch(sellerSkuCodeFieldSearchName)}
        />
        <NewCheckbox
          type="checkbox"
          label="Barcode"
          {...formState.register(barcodeFieldSearchName)}
          checked={formState.watch(barcodeFieldSearchName)}
          isRequired={false}
          disabled={!formState.watch(nameFieldSearchName) && !formState.watch(sellerSkuCodeFieldSearchName)}
        />
        <NewCheckbox
          type="checkbox"
          label="Seller SKU Code"
          {...formState.register(sellerSkuCodeFieldSearchName)}
          checked={formState.watch(sellerSkuCodeFieldSearchName)}
          isRequired={false}
          disabled={!formState.watch(nameFieldSearchName) && !formState.watch(barcodeFieldSearchName)}
        />

      </div>
    </div>
  </div>;
};

const AddSkuTable: React.FC<iSearchSkuDropdownProps> = (props) => {
  const { formState, packageIndex, packageName, packageValue, nameFieldSearchName, barcodeFieldSearchName, sellerSkuCodeFieldSearchName } = props;
  const [isAddSkuModalOpen, setIsAddSkuModalOpen] = useState(false);

  const skuItemsFieldsObject = useFieldArray({
    control: formState.control,
    name: packageName,
  });

  const ctaConfig = (props: MenuProps<unknown, true>) => ({
    cancel: () => console.log('Cancel clicked', props),
    apply: () => console.log('Apply clicked'),
    append: () => {
      setIsAddSkuModalOpen(true);
      props.selectProps?.onMenuClose();
    },
    disabled: false,
  });

  const tableColumns: any = useCreateShipmentsSkuTableCol({
    formState,
    skuItemsFieldsObject,
    packageIndex,
    packageName,
    packageValue,
    ctaConfig,
    nameFieldSearchName,
    barcodeFieldSearchName,
    sellerSkuCodeFieldSearchName
  });

  const tableData: unknown[] = [{ __key: CHOOSE_SKU_KEY }, ...skuItemsFieldsObject.fields];

  return (
    <>
      <ExpandableCustomTable
        isActionColumnSticky={false}
        columns={tableColumns}
        data={tableData as any || []}
        showColumnsDropdown={false}
        hideTableSearch
        showPagination={false}
        //@ts-expect-error fix
        TableHeaderComponent={
          <SelectSkuSearchFields
            formState={formState}
            nameFieldSearchName={nameFieldSearchName}
            barcodeFieldSearchName={barcodeFieldSearchName}
            sellerSkuCodeFieldSearchName={sellerSkuCodeFieldSearchName}
          />
        }
      />

      <CreateSku
        selectedSeller={formState.watch(CREATE_ORDER_FIELDS_NAME.SELLER.value)}
        isModalOpen={isAddSkuModalOpen}
        setIsModalOpen={setIsAddSkuModalOpen}
        isEdit={false}
        setIsEdit={() => { }}
        dataToEdit={[]}
        setDataTodEdit={() => { }}
        setSearchParams={() => { }}
        skuType={SIMPLE}
        filter={{}}
        setFilter={() => { }}
        productConfiguration={{}}
      />
    </>
  );
};

export default AddSkuTable;
