const Tick = ({fillColor='currentColor',width=16,height=16}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Tick">
        <path id="Vector" d="M7.01658 11.0333L11.7333 6.31659L10.9666 5.56659L7.01658 9.51658L5.01659 7.51658L4.26659 8.26658L7.01658 11.0333ZM7.99992 14.6666C7.08881 14.6666 6.2277 14.4916 5.41658 14.1416C4.60547 13.7916 3.89714 13.3138 3.29159 12.7083C2.68603 12.1027 2.20825 11.3944 1.85825 10.5833C1.50825 9.77214 1.33325 8.91103 1.33325 7.99992C1.33325 7.0777 1.50825 6.21103 1.85825 5.39992C2.20825 4.58881 2.68603 3.88325 3.29159 3.28325C3.89714 2.68325 4.60547 2.20825 5.41658 1.85825C6.2277 1.50825 7.08881 1.33325 7.99992 1.33325C8.92214 1.33325 9.78881 1.50825 10.5999 1.85825C11.411 2.20825 12.1166 2.68325 12.7166 3.28325C13.3166 3.88325 13.7916 4.58881 14.1416 5.39992C14.4916 6.21103 14.6666 7.0777 14.6666 7.99992C14.6666 8.91103 14.4916 9.77214 14.1416 10.5833C13.7916 11.3944 13.3166 12.1027 12.7166 12.7083C12.1166 13.3138 11.411 13.7916 10.5999 14.1416C9.78881 14.4916 8.92214 14.6666 7.99992 14.6666Z" fill={fillColor}/>
      </g>
    </svg>
  )
}
export default Tick
