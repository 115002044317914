/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable';
import { fetchOrderLog, SHIPPING_AGGREGATOR_API_KEYS } from '@src/views/shipping-aggregator/shipping-aggregator.apis';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { useState } from 'react';
import OmniShipLogs from './omniship-logs';
import classNames from 'classnames';

const PackageLogs = ({ orderDetails, id, isPackageDetailView }: any) => {
  const [selectedPackage, setSelectedPackage] = useState(orderDetails?.packages?.[0]);

  const selectedPackageRowStyling = [
    {
      when: (row: any) => row.id === selectedPackage?.id,
      style: {
        backgroundColor: 'var(--bs-primary)',
      },
    }
  ];
  const columns = [
    {
      id: 1,
      width: '70px',
      name: <div className="w-100 text-center">{t('S. No.')}</div>,
      key: 'srNo',
      cell: (row:any, index:number) => {
        // const sno = ((+searchParams.get('page') - 1) * +searchParams.get('per_page')) + +row.sno
        return <div data-tag="allowRowEvents" className={classNames('text-dark text-center w-100 text-capitalize cursor-pointer d-flex align-items-center justify-content-center', {'text-white': row.id === selectedPackage?.id})}>{ index + 1}</div>;
      }
    },
    {
      id: 2,
      Width: '150px',
      name: <div className="w-100 px-4px">{t('Package ID')}</div>,
      key: 'shipment_id',
      cell: (row:any) => {
        // const sno = ((+searchParams.get('page') - 1) * +searchParams.get('per_page')) + +row.sno
        return <div data-tag="allowRowEvents"   className={classNames('text-capitalize cursor-pointer px-4px w-100', {'text-white': row.id === selectedPackage?.id})}>{ row.display_package_id}</div>;
      },
    }
  ];
  const { data: orderLog, isFetching ,refetch } = useQuery({
    queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_SHIPMENT_ORDER_LOG],
    queryFn: () => {
      const params = {actionable_type:'order_package',actionable_id:isPackageDetailView ? id : selectedPackage?.id};
      return fetchOrderLog(params);
    },
    select: ({ data }) => {
      return data.data;
    }
  });
  const handleSelectedPackageChange = (row:any) => {
    setSelectedPackage(row);
    refetch();
  };
 
  return (
    <div className='order-logs d-flex gap-2 pt-10px py-24px px-24px'>
      {!isPackageDetailView &&
        <div className='width-30-per'>
          <ExpandableCustomTable
            columns={columns}
            data={orderDetails.packages}
            expandableRowsComponent={false}
            showColumnsTableHeader={false}
            showPagination={false}
            useReactPaginate={false}
            onRowClicked={handleSelectedPackageChange}
            conditionalRowStyles={selectedPackageRowStyling}
          />
        </div>}
      <OmniShipLogs isLoading={isFetching} logs={orderLog} />
    </div>
  );
};

export default PackageLogs;
