import { MoreVertical } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

const TableActionButton = ({ actions, row }) => {
  const { t } = useTranslation()

  const hasVisibleChild = actions.some(action => action.hasPermission(row))
  if (!hasVisibleChild) return null

  return (
    <div className='d-flex justify-content-center align-items-center cursor-pointer py-8px w-100'>
      <UncontrolledDropdown>
        <DropdownToggle className='more_vert' tag='span'>
          <MoreVertical size={20} />
        </DropdownToggle>
        <DropdownMenu end container='body' className="d-flex flex-column">
          {actions.map(action => (
            action.hasPermission(row) ? <DropdownItem
              key={action.id}
              className='w-100 d-flex align-items-center txt-body-rg gap-8px ps-12px pe-16px py-8px text-dark'
              onClick={() => action.onClick(row)}
            >
              <span>{action.icon}</span>
              <span>
                {t(action.label)}
              </span>
            </DropdownItem> : null
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

export default TableActionButton