/* eslint-disable @typescript-eslint/no-explicit-any */
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner';
import CancelledImage from '@src/assets/images/orders/orderLogs/Cancelled.svg';
import DeliveredImage from '@src/assets/images/orders/orderLogs/Delivered.svg';
import Hub_Assigned from '@src/assets/images/orders/orderLogs/Hub_Assigned.svg';
import New_Order from '@src/assets/images/orders/orderLogs/New_Order.svg';
import shipmentCancelled from '@src/assets/images/orders/orderLogs/Shipment_Cancelled.svg';
import Shipment_Created from '@src/assets/images/orders/orderLogs/Shipment_Created.svg';
import AutoShipmentCreationFail from '@src/assets/images/orders/orderLogs/auto_shipment_creation_fail.svg';
import dispatched from '@src/assets/images/orders/orderLogs/dispatched.svg';
import In_Transit from '@src/assets/images/orders/orderLogs/in_transit.svg';
import orderUpdated from '@src/assets/images/orders/orderLogs/orderUpdated.svg';
import Out_For_Delivery from '@src/assets/images/orders/orderLogs/out_for_delivery.svg';
import Return_In_Progress from '@src/assets/images/orders/orderLogs/return_in_progress.svg';
import Return_To_Origin from '@src/assets/images/orders/orderLogs/return_to_origin.svg';
import ShipmentLost from '@src/assets/images/orders/orderLogs/shipment_lost.svg';
import UnableToDeliver from '@src/assets/images/orders/orderLogs/unable_to_deliver.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';

const OmniShipLogs = ({ isLoading, logs }: any) => {
  const ACTION_LOGO =  {
    'New Package': New_Order,
    'Order Updated': orderUpdated,
    'Ready To Ship': Shipment_Created,
    Dispatched:dispatched,
    'In Transit':In_Transit,
    'Out For Delivery':Out_For_Delivery,
    'Reached At Hub': Hub_Assigned,
    Delivered: DeliveredImage,
    Cancelled: CancelledImage,
    'Shipment Cancelled': shipmentCancelled,
    'Return In Progress': Return_In_Progress,
    'Return To Origin': Return_To_Origin,
    'Automatic Shipment Creation Failed': AutoShipmentCreationFail,
    'Lost': ShipmentLost,
    'Unable To Deliver': UnableToDeliver
  };
  
  return (
    <div className='width-70-per'>
      {
        isLoading
          ?
          <ComponentSpinner className=""/>
          : <PerfectScrollbar>
            {logs?.length > 0
              &&  <div className='omnishipLogsMain pb-2'>
                {logs.map((item:any, index:any) => <div key={index} className="d-flex flex-column">
                  <div className="d-flex gap-2">
                    <div className='logo-container d-flex flex-column align-items-center gap-1'>
                      <div className='d-flex justify-content-center align-items-center mt-1'><img src={ACTION_LOGO?.[item?.display_action]} alt={item.event} height={24} width={24} /></div>
                      {/* <div className='showCircle d-flex justify-content-center align-items-center mt-1'>
                      </div> */}
                      {index < logs.length - 1 && <div className={'styleVerticalLine'}></div>}
                    </div>
                    <div className='pt-1 d-flex flex-column gap-4px'>
                      <h4 className="m-0 txt-sub-md">{item?.display_action}</h4>
                      <p className=' m-0 txt-asst-rg text-dark-6'
                      >
                        {`By ${item.created_by?.name}`}
                      </p>
                      
                      <p className='m-0 txt-asst-rg text-dark-5'>{item.created_at}</p>
                    </div>
                  </div>
                </div>)}
              </div>
            } 
          </PerfectScrollbar>
      }
    </div>
  );
};

export default OmniShipLogs;