import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { components, MenuProps } from 'react-select';
import Button from '../../button';

interface DropdownCTAConfig {
  cancel?: () => void;
  apply?: () => void;
  append?: () => void;
  disabled?: boolean;
}

interface DropdownOptionsWithCTAProps extends MenuProps<unknown, true> {
  ctaConfig: DropdownCTAConfig;
  appendLabel?: ReactNode; 
  cancelLabel?: ReactNode; 
  applyLabel?: ReactNode; 
  isFooterCTA?: boolean;
  isHeaderCTA?: boolean;
}

const DropdownOptionsWithCTA = (props: DropdownOptionsWithCTAProps) => {
  const { ctaConfig: { cancel, apply, append, disabled }, appendLabel, cancelLabel, applyLabel, isHeaderCTA = false, isFooterCTA = false } = props;

  const { Menu } = components;
  const { t } = useTranslation();

  const menuHeaderCTA =  (
    <div className="px-12px py-10px border-bottom text-primary cursor-pointer" onClick={append} >
      {appendLabel || t('Add New')}
    </div>
  ); 

  const menuFooterCTA = (
    <div className="d-flex align-items-center justify-content-end px-12px py-10px border-top gap-12px">
      <Button
        className="w-full"
        ofStyle="outlined"
        ofType="compressed"
        type="button"
        onClick={cancel}
        disabled={disabled}
      >
        {cancelLabel || t('Cancel')}
      </Button>
      <Button
        className="w-full"
        ofType="compressed"
        type="button"
        onClick={apply}
        disabled={disabled}
      >
        {applyLabel || t('Apply')}
      </Button>
    </div>
  );

  return (
    <Menu {...props}>
      <div className="relative">
        {isHeaderCTA && menuHeaderCTA}
        {props.children}
        {isFooterCTA && menuFooterCTA}
      </div>
    </Menu>
  );
};

export default memo(DropdownOptionsWithCTA);
