/* eslint-disable @typescript-eslint/no-explicit-any */

import CustomTag from '@src/@core/components/ui/badge/CustomTag';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import { AbilityContext } from '@src/utility/context/Can';
import { Order } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { EDIT_SHIPPING_DETAILS_MODAL_TYPE } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import DetailsCard from '../../details-card';
import EditCustomerAndShippingDetails from './edit-customer-and-shipping-details-modal';

type iCreateShipmentDetails = {
  orderDetails: Order;
  shipmentCreated: boolean;
  refetch: () => void
}

const BasicDetails = ({ orderDetails, shipmentCreated, refetch }: iCreateShipmentDetails) => {
  const ability = useContext(AbilityContext);
  const [isCustomerSideSheetOpen, setIsCustomerSideSheetOpen] = useState({ open: false, key: EDIT_SHIPPING_DETAILS_MODAL_TYPE.CUSTOMER_DETAILS.key });

  const handleEditDetails = (key: string) => {
    setIsCustomerSideSheetOpen({ open: true, key: key as any });
  };
    
  return (
    <>
      <div className='d-flex flex-column gap-2'>
        <div className='order-details-fields'>
          <div className='w-100'>
            <DetailsCard title="Shipment Order Details">
              <div className='row gap-16px'>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Shipment Order ID')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails.display_order_id || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Payment Type')}</div>
                  <div className='col-8 txt-asst-rg'>
                    {/* @ts-expect-error fix */}
                    <CustomTag style={{ color: '#222', backgroundColor: '#DEDEDE' }} title={orderDetails.payment_info.display_payment_type || ''} />
                  </div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Tags')}</div>
                  <div className='txt-body-rg d-flex flex-wrap gap-4px text-dark col-8'>{
                    orderDetails.tags?.length > 0 ? orderDetails.tags.map((tag, index: number) => {
                      return (
                      /* @ts-expect-error fix */
                        <CustomTag key={index} style={{ color: tag.color, backgroundColor: `${tag.color}1A` }} title={tag.name} />
                      );
                    }) : <div>-</div>
                  }</div>
                </div>
              </div>
            </DetailsCard>
          </div>

          <div className='w-100'>
            <DetailsCard title="Customer Details" isEditable={((ability.can(
              abilityMap.edit_shipment_v2.edit.action,
              abilityMap.edit_shipment_v2.edit.resource
            )) && !shipmentCreated)} handleEdit={() => handleEditDetails(EDIT_SHIPPING_DETAILS_MODAL_TYPE.CUSTOMER_DETAILS.key)}>
              <div className='row gap-16px'>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Customer ')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails?.customer.first_name ? `${orderDetails?.customer.first_name || ''} ${orderDetails?.customer.last_name || ''}` : '-' }</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Email ID')}</div>
                  <div className='txt-body-rg col-8 text-dark'>{orderDetails?.customer.email || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Contact Number')}</div>
                  <div className='txt-body-rg d-flex gap-4px text-dark col-8'>{orderDetails?.customer.display_mobile_number || '-'}</div>
                </div>
              </div>
            </DetailsCard>
          </div>
        </div>

        {!shipmentCreated && <div className='order-details-fields'>
          <div className='w-100'>
            <DetailsCard title="Sender Address">
              <div className='row gap-12px'>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Hub Name')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails?.origin_details.name || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Email ID')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails?.origin_details.email || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Contact Number')}</div>
                  <div className='txt-body-rg col-8 text-dark'>{orderDetails?.origin_details.phone_number || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Address')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails?.origin_details.address.display_address || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('City')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails.origin_details.address.city.name || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('State & Country')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails.origin_details.address.state.name}{orderDetails.origin_details.address.state.name && ','} {orderDetails.origin_details.address.country.name}</div>
                </div>
              </div>
            </DetailsCard>
          </div>

          <div className='w-100'>
            <DetailsCard title="Shipping Address" isEditable={(ability.can(
              abilityMap.edit_shipment_v2.edit.action,
              abilityMap.edit_shipment_v2.edit.resource
            )) && !shipmentCreated} handleEdit={() => handleEditDetails(EDIT_SHIPPING_DETAILS_MODAL_TYPE.SHIPPING_ADDRESS.key)}>
              <div className='row gap-12px'>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Recipient’s Name')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails?.receiver_address.first_name ? `${orderDetails?.receiver_address.first_name} ${orderDetails?.receiver_address.last_name || ''}` : '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Email ID')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails?.receiver_address.email || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Contact Number')}</div>
                  <div className='txt-body-rg col-8 text-dark'>{orderDetails?.receiver_address.display_mobile_number || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('Address')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails?.receiver_address.display_address || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('City')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails?.receiver_address.omniful_city || '-'}</div>
                </div>
                <div className='row w-100'>
                  <div className='txt-body-rg text-dark-5 col-4'>{t('State & Country')}</div>
                  <div className='txt-body-rg text-dark col-8'>{orderDetails?.receiver_address.omniful_state || ''}{orderDetails?.receiver_address.omniful_state && ', '}{orderDetails?.receiver_address.omniful_country || '-'}</div>
                </div>
              </div>
            </DetailsCard>
          </div>
        </div>}
      </div>
      {orderDetails && <EditCustomerAndShippingDetails isModalOpen={isCustomerSideSheetOpen} setIsModalOpen={setIsCustomerSideSheetOpen} order_id={orderDetails.id} editDetails={isCustomerSideSheetOpen.key === EDIT_SHIPPING_DETAILS_MODAL_TYPE.CUSTOMER_DETAILS.key ?
        { ...orderDetails.customer, mobile: orderDetails.customer.display_mobile_number || '' } :
        {
          ...orderDetails.receiver_address,
          id: orderDetails.id,
          phone: orderDetails.receiver_address.display_mobile_number || '',
          zip: orderDetails.receiver_address.zip,
          address1: orderDetails.receiver_address.address1,
          address2: orderDetails.receiver_address.address2,
          omniful_country: orderDetails.receiver_address.omniful_country,
          omniful_country_id: orderDetails.receiver_address.omniful_country_id,
          omniful_city: orderDetails.receiver_address.omniful_city,
          omniful_city_id: orderDetails.receiver_address.omniful_city_id,
          omniful_state: orderDetails.receiver_address.omniful_state,
          omniful_state_id: orderDetails.receiver_address.omniful_state_id,
        }}
      funcHandlerOnSuccess={refetch}
      />}
    </>
  );
};

export default BasicDetails;