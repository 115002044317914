import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Collapse, Container } from 'reactstrap';

interface TypeOfReturnsInformationProps {
  title: string;
  children: React.ReactNode;
  returnInfo: string;
}

const CustomCollapseContainer = ({title, children, returnInfo} :TypeOfReturnsInformationProps) => {

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { t } = useTranslation();
  const collapsedContainer = sessionStorage.getItem('collapsedContainerList');
  const collapsedContainerList = collapsedContainer ? JSON.parse(collapsedContainer) : [];
  const isAlreadyPresent = collapsedContainerList.some((item: string) => item === returnInfo); 
  let timeoutId: unknown;
  
  const containerList = (collapsedList : string[]) => {
    return sessionStorage.setItem('collapsedContainerList', JSON.stringify(collapsedList));
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    if (!isAlreadyPresent) {
      containerList([...collapsedContainerList, returnInfo]);
    }
    if (timeoutId) timeoutId= null;
  };

  useEffect(() => {
    if (!isAlreadyPresent) {
      setIsOpen(true);
      timeoutId = setTimeout(() => {
        containerList([...collapsedContainerList, returnInfo]);
        setIsOpen(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId as number);
    };
  },  [collapsedContainerList, returnInfo]);

  return (
    <div className='custom-collapse-info-container w-100 bg-primary-lighter-global rounded-8px text-dark'>
      <div className={classNames('collapse-info-heading p-12px flex-center-between cursor-pointer border-bottom border-primary-light-global', {'rounded-8px': !isOpen})}  onClick={toggle}>
        <div>
          <h3 className="txt-sub-md m-0">
            {t(title)}
          </h3>
        </div>
        <div>
          {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <Container className="collapse-info ms-0 mt-14px pb-14px d-flex flex-column gap-8px">
          {children}
        </Container>
      </Collapse>
    </div>
  );
};

export default CustomCollapseContainer;