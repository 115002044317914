const FilledInfo = ({width, height, color}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00443 14.6667C7.08517 14.6667 6.22128 14.4917 5.41277 14.1417C4.60426 13.7917 3.89722 13.3139 3.29167 12.7083C2.68611 12.1028 2.20833 11.3953 1.85833 10.586C1.50833 9.77665 1.33333 8.91187 1.33333 7.99166C1.33333 7.07145 1.50833 6.20668 1.85833 5.39735C2.20833 4.588 2.68611 3.88333 3.29167 3.28333C3.89722 2.68333 4.60467 2.20833 5.41402 1.85833C6.22335 1.50833 7.08812 1.33333 8.00833 1.33333C8.92855 1.33333 9.79332 1.50833 10.6027 1.85833C11.412 2.20833 12.1167 2.68333 12.7167 3.28333C13.3167 3.88333 13.7917 4.58888 14.1417 5.4C14.4917 6.21111 14.6667 7.0763 14.6667 7.99556C14.6667 8.91483 14.4917 9.77872 14.1417 10.5872C13.7917 11.3957 13.3167 12.1018 12.7167 12.7053C12.1167 13.3088 11.4111 13.7866 10.6 14.1386C9.78889 14.4906 8.9237 14.6667 8.00443 14.6667Z" fill={color}/>
      <path d="M8.55 11.3333H7.55V7.33333H8.55V11.3333Z" fill="#fff"/>
      <path d="M8.39167 5.94667C8.28611 6.04889 8.15546 6.1 7.9997 6.1C7.84396 6.1 7.7134 6.04889 7.60803 5.94667C7.50268 5.84444 7.45 5.71778 7.45 5.56667C7.45 5.40611 7.50278 5.27153 7.60833 5.16292C7.71389 5.05431 7.84455 5 8.0003 5C8.15605 5 8.2866 5.05431 8.39197 5.16292C8.49732 5.27153 8.55 5.40611 8.55 5.56667C8.55 5.71778 8.49722 5.84444 8.39167 5.94667Z" fill="#fff"/>
    </svg>
  )
}

export default FilledInfo;