import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import Modal from '@src/@core/components/ui/modal'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { ATTACHMENTS_SIDEBAR_MODE } from '@src/views/sales/constant/orders.constants'
import { ATTACHMENTS_SIDESHEET_VIEW } from '@src/views/sales/sales.constant'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'reactstrap'
import AddEditAttachments from './addEditAttachments'
import './attachments.scss'
import PreviewAttachments from './previewAttachments'
const Attachments = (props) => {
  const { isOpen, toggle, addedAttachments, mode, onSaveAttachments, isLoading, uploadDetails } = props

  const { t } = useTranslation()
  const [showRestrictModal, setShowRestrictModal] = useState(false)
  const [currentView, setCurrentView] = useState(ATTACHMENTS_SIDESHEET_VIEW.UPLOAD)

  const formObject = useForm({
    defaultValues: {
      existingFiles: [],
      uploadedFiles: []
    },
    mode: 'onChange'
  })

  // const { append: appendFile, remove: removeFile, replace: replaceFile } = useFieldArray({ control: formObject.control, name: 'files' })

  const isAddOrEditMode = mode === ATTACHMENTS_SIDEBAR_MODE.EDIT.id || mode === ATTACHMENTS_SIDEBAR_MODE.ADD.id
  const isEditMode = mode === ATTACHMENTS_SIDEBAR_MODE.EDIT.id
  const hasBackButton = isEditMode && currentView === ATTACHMENTS_SIDESHEET_VIEW.UPLOAD

  const getToastMessage = (uploadedFiles) => { 
    if (uploadedFiles?.length) { 
      if (uploadedFiles.length > 1) {
        return 'Files have been uploaded successfully.'
      } else {
        return 'File has been uploaded successfully.'
      }
    } else {
      return 'Files saved successfully.'
    }
  }

  const handleUploadFiles = () => {
    const files = formObject.watch('uploadedFiles')
    const uploadedFiles = files.filter(el => !!el.file_url)
    const existingFiles = formObject.watch('existingFiles')

    const filesList = [...uploadedFiles, ...existingFiles]
    const toastMessage = getToastMessage(uploadedFiles)

    if (onSaveAttachments) {
      onSaveAttachments(filesList, toastMessage)
      setShowRestrictModal(false)
      return
    }

    CustomToast('Files saved successfully.', { my_type: 'success' })
    toggle()
  }

  const handleClose = () => {
    formObject.reset({
      existingFiles: [],
      uploadedFiles: []
    })
    setCurrentView(ATTACHMENTS_SIDESHEET_VIEW.UPLOAD)
  }

  const handleOpen = () => {

    if (addedAttachments?.length) {
      formObject.reset({
        existingFiles: [...addedAttachments],
        uploadedFiles: []
      })
    }

    if (isEditMode) {
      setCurrentView(ATTACHMENTS_SIDESHEET_VIEW.EXISTING)
    } else {
      setCurrentView(ATTACHMENTS_SIDESHEET_VIEW.UPLOAD)
    }
  }

  const handleDiscardChanges = () => {
    setShowRestrictModal(false)
    toggle()
  }

  const handleToggleSidesheet = () => {
    if (formObject.formState.isDirty) {
      setShowRestrictModal(true)
      return
    }
    toggle()
  }

  const backButtonHandler = () => {
    setCurrentView(ATTACHMENTS_SIDESHEET_VIEW.EXISTING)
  }

  return (
    <SideSheet
      isOpen={isOpen}
      toggle={handleToggleSidesheet}
      onClosed={handleClose}
      onOpened={handleOpen}
      size="sm"
      contentClassName="p-0"
      modalClassName="modal-slide-in sidebar-todo-modal"
      title={currentView === ATTACHMENTS_SIDESHEET_VIEW.UPLOAD ? 'Attach Files' : 'Attached Files'}
      hasBackButton={hasBackButton}
      backButtonHandler={backButtonHandler}
    >
      <ModalBody className="overflow-auto p-24px">
        {isAddOrEditMode 
          ? <AddEditAttachments 
            handleUploadFiles={handleUploadFiles} 
            isUploading={isLoading}
            addedAttachments={addedAttachments}
            mode={mode}
            formObject={formObject}
            currentView={currentView} 
            setCurrentView={setCurrentView}
            uploadDetails={uploadDetails}
          />
          : <PreviewAttachments addedAttachments={addedAttachments}/>
        }

        <Modal
          closeModalHandler={() => setShowRestrictModal(false)}
          primaryButtonHandler={handleDiscardChanges}
          secondaryButtonHandler={handleUploadFiles}
          title={t('You have Unsaved Changes')}
          content={'If you exit now, your changes will be lost. Do you want to continue without saving?'} 
          secondaryButtonText={t('Save Changes')}
          primaryButtonText={t('Discard Changes')}
          isOpen={showRestrictModal}
          isPrimaryButtonVisible={true}
          isSecondaryButtonVisible={true}
          isSecondaryDisabled={isLoading}
          hasComponentSideContentTranslation={false}
          backdrop={'static'}
        />
      </ModalBody>
    </SideSheet>
  )
}

export default Attachments