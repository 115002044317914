import Attributes from '@src/@core/components/ui/attributes'
import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SKUDetailsCol from '@src/@core/components/ui/sku-details/index'
import { DESCRIPTION_MAX_LIMIT, PRICE_BREAK_DOWN_SECTION, SKU_TYPES } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { AbilityContext } from '@src/utility/context/Can'
import { pluralizeUnit } from '@src/utility/Utils'
import { BUNDLE } from '@src/views/catalog/catalog.constants'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import { LIVE_ORDER_TYPES } from '@src/views/inventory/inventory.constants'
import SKUBarcodeModal from '@src/views/sales/components/SKUBarcodeModal'
import { ORDER_STATUS } from '@src/views/sales/constant/orders.constants'
import CustomerNoteModal from '@src/views/sales/live-orders/newPacking/components/CustomerNoteModal'
import { ORDER_ITEM_SOURCES } from '@src/views/sales/sales.constant'
import { showSkuTypeTag } from '@src/views/sales/sales.utils'
import classNames from 'classnames'
import { useContext, useMemo, useRef, useState } from 'react'
import { Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BatchDeatilsSideSheet from '../../components/BatchDeatilsSideSheet/batchDeatilsSideSheet'
import EditOrderItems from '../../components/editOrderItems'
import BundleSkuDetailsSideSheet from '../../components/OnHoldOrderDetailsSideSheet/BundleSkuDetailsSideSheet'
import OrderItemsChildTable from './OrderItemsChildTable/OrderItemsChildTable'
import './OrdersForApproval.scss'
import { PriceBreakDownSection } from './PriceBreakDownSection'

const OrderDetailsTable = ({ orderDetails: orderDetailProp, editButtons = {} }) => {
  const orderDetails = orderDetailProp || {}

  const { t } = useTranslation()
  const editOrderItemsRef = useRef(null)
  const ability = useContext(AbilityContext)

  const loading = useSelector((state) => state.sales.loading)
  const error = useSelector((state) => state.sales.error)
  const success = useSelector((state) => state.sales.success)
  const userPlan = useSelector(state => state.auth.userData.user_plan) || {}

  const [barcodeModalOpen, setBarcodeModalOpen] = useState(false)
  const [barcodeList, setBarcodeList] = useState([])
  const [batchModalOpen, setBatchModalOpen] = useState(false)
  const [batchModalData, setBatchModalData] = useState({})
  const [skuForDetails, setSkuForDetails] = useState({})
  const [openBundleSkuDetailsSidebar, setOpenBundleSkuDetailsSidebar] = useState(false)
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)
  const [customerNoteCard, setCustomerNoteCard] = useState({ isOpen: false, customerNote: null })
  const [editOrderItems, setEditOrderItems] = useState({ isOpen: false, isLoading: false })
  const [attributes, setAttributes] = useState({isOpen: false, attributes: []})

  const isOnlyPosEnabled = (() => {   
    const enabledPlans = Object.values(userPlan).filter(plan => plan)
    if (enabledPlans.length > 1) return false
    else { return userPlan.is_pos_enabled }
  })()

  const toggleBarcodeModal = (barcodes) => {
    setBarcodeList(
      barcodes ? barcodes?.map((barcode) => ({ barcodeValue: barcode })) : []
    )
    setBarcodeModalOpen((p) => !p)
  }

  const toggleBatchModal = (row) => {
    setBatchModalData(row)
    setBatchModalOpen((p) => !p)
  }

  const handleSkuDetailsSidebar = (data) => {
    setSkuForDetails({...data, seller_id: orderDetails.seller_id})
    setOpenSkuDetailsSidebar(!openSkuDetailsSidebar)
  }

  const handleCustomerNote = (customerNote = null) => {
    setCustomerNoteCard({  isOpen: true, customerNote })
  }

  const handleBundleSkuDetailsSidebar = (data) => {
    if (data) {
      const formattedData = {
        ...data,
        ...data.seller_sku,
        child_skus: data.seller_sku.child_skus
      }
      setSkuForDetails({...formattedData, seller_id: orderDetails.seller_id})
      setOpenBundleSkuDetailsSidebar(true)
    } else {
      setOpenBundleSkuDetailsSidebar(false)
    }
  }

  const handleEditItemClick = (mode) => {
    if (editOrderItemsRef.current) {
      editOrderItemsRef.current.handleItemEditingEnable(mode);
    }
  };

  const toggleAttributes = () => {
    setAttributes({ isOpen: false, attributes: [] })
    setSkuForDetails({})
  }

  const seeAttributes = (row) => {
    setAttributes({ isOpen: true, attributes: row.item_attributes })
    setSkuForDetails({...row.seller_sku, seller_sku_code:row.seller_sku_code, image: row.seller_sku.images?.[0]?.default})
  }

  const isInPicking = orderDetails.status === ORDER_STATUS.IN_PICKING.name 
  const isInPacking = orderDetails.status === ORDER_STATUS.IN_PACKING.name 
  const hasBatches = orderDetails.order_items.some(item => item.batches.length > 0)
  const hasEditOrderDetailsPermission = ability.can(abilityMap.order.edit_details.action, abilityMap.order.edit_details.resource)
  const showEditItemsButton = !!editButtons.edit_order_items && hasEditOrderDetailsPermission

  const tableColumns = useMemo(() => {
    return [
      {
        id: 1,
        name: t('SKU Details'),
        minWidth: '400px',
        maxWidth: '500px',
        cell: (row) => {
          const hasFreeItem = row.source === ORDER_ITEM_SOURCES.AUTOMATION_RULE.value && row.unit_price === 0
          const hasBundle = !!showSkuTypeTag(row.seller_sku.type)
          const hasAttributes = row.item_attributes.length
          const totalAttributes = hasAttributes && row.item_attributes.reduce((acc, curr) => {
            return acc += curr.attributes.length 
          }, 0)
          
          const customTagsArray = [
            hasFreeItem && ({
              className:'text-purple bg-purple-light',
              title: showSkuTypeTag(SKU_TYPES.GIFT.key)
            }),
            hasBundle && ({
              className:'text-info bg-info-light',
              title: showSkuTypeTag(row.seller_sku.type)
            }),
            hasAttributes && ({
              className:'text-primary bg-white border border-primary cursor-pointer',
              title: pluralizeUnit(totalAttributes, 'Attribute'),
              onClick: () => seeAttributes(row)
            })
          ].filter(Boolean)
          return (
            <SKUDetailsCol
              skuDetailData={{
                isValidSku: true,
                id: row.seller_sku.id,
                image: {
                  src: row.seller_sku.images?.[0]?.default,
                  alt: row.seller_sku.name
                },
                details: {
                  name: row.seller_sku.name,
                  handleSkuDetails: () => {
                    const { type } = row.seller_sku
                    return type === BUNDLE ? handleBundleSkuDetailsSidebar(row) : handleSkuDetailsSidebar(row)
                  },
                  skuCode: row.seller_sku_code,
                  columnKey: 'sku_details'
                },
                customTags: customTagsArray
              }}
            />
          )
        }
      },
      {
        id: 2,
        name: t('Barcode'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <div className="box-cell flex-center-between gap-8px w-100 px-2px">
              <span className="text-truncate txt-sub-rg text-dark">
                {row.seller_sku?.barcodes?.[0]}
              </span>
              {row.seller_sku?.barcodes?.length > 1 ? (
                <p className="cursor-pointer flex-center-center gap-2px m-0 ps-6px pe-8px order-details-barcode"
                  onClick={() => toggleBarcodeModal(row.seller_sku?.barcodes)}>
                  <Plus size={12}/>
                  <span className="txt-asst-rg pt-2px">{row.seller_sku?.barcodes?.length - 1}</span>
                </p>
              ) : null}
            </div>
          )
        }
      },
      {
        id: 3,
        name: t('Fail Reason'),
        minWidth: '200px',
        omit: orderDetails.status !== ORDER_STATUS.ON_HOLD.name,
        selector: (row) => row.failed_reason || '-'
      },
      {
        id: 4,
        name: t('Batch Number'),
        minWidth: '240px',
        omit: !hasBatches,
        // selector: (row) => row.batches[0]?.number || "-",
        cell: (row) => <div className="w-100 d-flex justify-content-between align-items-center ps-2px pe-6px">
          <div className="d-flex flex-column gap-4px">
            <span className="d-inline">{row.batches[0]?.number || '-'}</span>
            <span className="d-inline text-dark-6">{row.batches[0]?.expiry_date}</span>
          </div>
          {row.batches.length > 1 && <div className="d-flex justify-content-between gap-2px align-items-center cursor-pointer ps-2px pe-6px order-details-batch-number" onClick={() => toggleBatchModal(row)}>
            <Plus size={14}/>{row.batches.length}</div>}
        </div>
      },
      {
        id: 5,
        name: <div className="w-100 text-end">{t('Ordered Qty')}</div>,
        minWidth: '150px',
        cell: (row) => <div className="w-100 text-end">{row.quantity || 0}</div>
      },
      {
        id: 6,
        name: <div className="w-100 text-end">{t('Ordered Weight')}</div>,
        minWidth: '150px',
        omit: !orderDetails.is_order_weighted,
        cell: (row) => <div className="w-100 text-end">{row.display_ordered_weight || 0}</div>
      },
      {
        id: 7,
        name: <div className={classNames('w-100', {'text-end':!isInPicking})}>{t('Picked Qty')}</div>,
        minWidth: '150px',
        omit: isOnlyPosEnabled,
        cell: (row) => <div className={classNames('w-100', {'text-end':!isInPicking})}>{isInPicking ? t('In Progress') : row.picked_quantity || 0}</div>
      },
      {
        id: 8,
        name: <div className={classNames('w-100', {'text-end':!isInPicking})}>{t('Picked Weight')}</div>,
        minWidth: '150px',
        omit: !orderDetails.is_order_weighted || isOnlyPosEnabled,
        cell: (row) => <div className={classNames('w-100', {'text-end':!isInPicking})}>{isInPicking ? t('In Progress') : row.display_picked_weight || 0}</div>
      },
      {
        id: 9,
        name: <div className={classNames('w-100', {'text-end':!isInPacking})}>{t('Packed Qty')}</div>,
        minWidth: '150px',
        omit: isOnlyPosEnabled,
        cell: (row) => <div className={classNames('w-100', {'text-end':!isInPacking})}>{isInPacking ? t('In Progress') : row.packed_quantity || 0}</div>
      },
      {
        id: 10,
        name: <div className={classNames('w-100', {'text-end':!isInPacking})}>{t('Packed Weight')}</div>,
        minWidth: '150px',
        omit: !orderDetails.is_order_weighted || isOnlyPosEnabled,
        cell: (row) => <div className={classNames('w-100', {'text-end':!isInPacking})}>{isInPacking ? t('In Progress') : row.display_packed_weight || 0}</div>
      },
      {
        id: 11,
        name: <div className="w-100 text-end">{t('Returned Qty')}</div>,
        minWidth: '150px',
        omit: !orderDetails.return_status,
        cell: (row) => <div className="w-100 text-end">{row.returned_quantity || 0}</div>
      },
      {
        id: 12,
        name: <div className="w-100 text-end">{t('Unit Price')}</div>,
        minWidth: '200px',
        cell: (row) => {
          return (
            <div className="w-100 text-end">
              <span>
                {row.unit_price ? row.unit_price.toFixed(2) : '0'}&nbsp;
                {orderDetails.invoice.currency}&nbsp;
                {row.tax_inclusive === true && '(Incl. of tax)'}
              </span>
            </div>  
          )
        }
      },
      {
        id: 13,
        name: <div className="w-100 text-end">{t('Discount')}</div>,
        minWidth: '160px',
        omit: orderDetails.type === LIVE_ORDER_TYPES.STO,
        cell: (row) => <div className="w-100 text-end">{(row.discount ? row.discount.toFixed(2) : 0)}&nbsp;{orderDetails.invoice.currency}</div>
      },
      {
        id: 14,
        name: <div className="w-100 text-end">{t('Tax(%)')}</div>,
        minWidth: '120px',
        omit: orderDetails.type === LIVE_ORDER_TYPES.STO,
        cell: (row) => <div className="w-100 text-end">{row.tax_percent || 0}</div>
      },
      {
        id: 15,
        name: <div className="w-100 text-end">{t('Tax Amount')}</div>,
        minWidth: '140px',
        omit: orderDetails.type === LIVE_ORDER_TYPES.STO,
        cell: (row) => {
          return (
            <div className="w-100 text-end">
              <span>
                {row.tax ? row.tax.toFixed(2) : '0'}&nbsp;
                {orderDetails.invoice.currency}
              </span>
            </div>
          )
        }
      },
      {
        id: 16,
        name: <div className="w-100 text-end">{t('Total')}</div>,
        minWidth: '140px',
        cell: (row) => {
          return (
            <div className="w-100 text-end">
              <span>
                {row.total ? row.total.toFixed(2) : 0}&nbsp;
                {orderDetails.invoice.currency}
              </span>
            </div>
          )
        }
      },
      {
        id: 17,
        name: t('Customer Note'),
        minWidth: '180px',
        omit: !orderDetails.show_customer_note,
        cell: (row) => {
          const customerNote = row.custom_attributes?.customer_note
          if (!customerNote) return '-'
          const isTruncated = customerNote.length > DESCRIPTION_MAX_LIMIT
          return (
            <div>
              <div className='two-line-truncate'>{customerNote}</div>
              {isTruncated && (
                <span
                  className="text-primary txt-asst-md cursor-pointer"
                  onClick={() => handleCustomerNote(customerNote)}
                >
                  {t('Read More')}
                </span>
              )}
            </div>
          )
        }
      }
    ].filter(item => !item.omit)
  }, [orderDetails])

  const childTableColumns = [
    {
      id: 1,
      name: t('SKU Details'),
      width: '316px',
      cell: (row) => {
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: row.id,
              image: {
                src: row.images?.[0]?.default,
                alt: row.name
              },
              details: {
                name: row.name,
                handleSkuDetails: row.id ? () => (handleSkuDetailsSidebar(row)) : null,
                skuCode: row.seller_sku_code,
                columnKey: 'order_sku_details'
              }
            }}
          />
        )
      }
    },
    {
      id: 2,
      name: t('Barcode'),
      minWidth: '200px',
      cell: (row) => {
        return (
          <div className="box-cell">
            <span className="text-truncate w-75">
              {row.barcodes?.[0]}
            </span>
            {row.barcodes?.length > 1 ? (
              <span
                onClick={() => toggleBarcodeModal(row.barcodes)}
                className="ms-1 cursor-pointer w-25"
                style={{
                  color: 'var(--bs-primary)'
                }}
              >
                {`+${row.barcodes?.length - 1}`}
              </span>
            ) : null}
          </div>
        )
      }
    },
    {
      id: 3,
      name: <div className="w-100 text-end">{t('Qty Per Kit')}</div>,
      minWidth: '100px',
      cell: (row) => <div className="w-100 text-end">{row.quantity || 0}</div>
    },
    // {
    //   id: 4,
    //   name: t("Batch Number"),
    //   minWidth: "150px",
    //   cell: (row) => <div className="w-100 d-flex justify-content-between">
    //     {row.batches[0]?.number}
    //     {row.batches.length > 1 && <div className="d-flex justify-content-between gap-2px align-items-center border border-primary text-primary rounded-10px cursor-pointer ps-2px pe-6px" onClick={() => toggleBatchModal(row)}>
    //       <Plus size={14} />{row.batches.length}
    //     </div>}
    //   </div>
    // },
    {
      id: 5,
      name: <div className="w-100 text-end">{t('Ordered Weight')}</div>,
      minWidth: '150px',
      omit: !orderDetails.is_order_weighted,
      cell: (row) => <div className="w-100 text-end">{row.display_ordered_weight || 0}</div>
    },
    {
      id: 6,
      name: <div className={classNames('w-100', {'text-end':!isInPicking})}>{t('Picked Qty')}</div>,
      minWidth: '100px',
      cell: (row) => <div className={classNames('w-100', {'text-end':!isInPicking})}>{isInPicking ? t('In Progress') : row.picked_quantity || 0}</div>
    },
    {
      id: 7,
      name: <div className={classNames('w-100', {'text-end':!isInPicking})}>{t('Picked Weight')}</div>,
      minWidth: '100px',
      omit: !orderDetails.is_order_weighted,
      cell: (row) => <div className={classNames('w-100', {'text-end':!isInPicking})}>{isInPicking ? t('In Progress') : row.display_picked_weight || 0}</div>
    },
    {
      id: 8,
      name: <div className={classNames('w-100', {'text-end':!isInPacking})}>{t('Packed Qty')}</div>,
      minWidth: '100px',
      cell: (row) => <div className={classNames('w-100', {'text-end':!isInPacking})}>{isInPacking ? t('In Progress') : row.packed_quantity || 0}</div>
    },
    {
      id: 9,
      name: <div className={classNames('w-100', {'text-end':!isInPacking})}>{t('Packed Weight')}</div>,
      minWidth: '150px',
      omit: !orderDetails.is_order_weighted,
      cell: (row) => <div className={classNames('w-100', {'text-end':!isInPacking})}>{isInPacking ? t('In Progress') : row.display_packed_weight || 0}</div>
    },
    {
      id: 10,
      name: <div className="w-100 text-end">{t('Unit Price')}</div>,
      minWidth: '100px',
      cell: (row) => {
        return (
          <div className="w-100 text-end">
            {row.unit_price ? row.unit_price.toFixed(2) : 0}&nbsp;
            {orderDetails.invoice.currency}
          </div>
        )
      }
    }
  ]
  
  const showPriceSection = {
    show_sub_total:false,
    show_total_discount:false,
    show_total_tax:false,
    show_additional_charges:false,
    show_shipping_cost:false,
    show_shipping_tax:false,
    show_horizontal_divider:false, 
    show_grand_total:true,
    show_total_amount_paid:false,
    show_paid_amounts:false,
    show_total_refund:false,
    show_refunded_amounts: false,
    show_total_due: false
  }

  return (
    <>
      {!editOrderItems.isOpen && (
        <ExpandableCustomTable
          loading={loading.getSingleOrderDetails}
          error={error.getSingleOrderDetails}
          success={success.getSingleOrderDetails}
          data={orderDetails.order_items || []}
          showPagination={false}
          columns={tableColumns}
          useReactPaginate={false}
          showColumnsDropdown={false}
          showColumnsTableHeader={showEditItemsButton}
          TableHeaderComponent={
            showEditItemsButton ? (
              <Button
                ofStyle='outlined'
                onClick={() => handleEditItemClick(true)}
                loading={editOrderItems.isLoading}
              >
                {t('Edit Items')}
              </Button>
            ) : null
          }
          expandableRows
          expandableRowsComponent={OrderItemsChildTable}
          expandableRowsComponentProps={{ childTableColumns, childDataKey: 'seller_sku.child_skus' }}
          expandableRowExpanded={(row) => row.seller_sku?.type === SKU_TYPES.BUNDLE.key}
          expandableRowDisabled={(row) => row.seller_sku?.type !== SKU_TYPES.BUNDLE.key}
        />
      )}

      <EditOrderItems
        orderDetails={orderDetails}
        editOrderItems={editOrderItems}
        setEditOrderItems={setEditOrderItems}
        ref={editOrderItemsRef}
      />

      {orderDetails && !editOrderItems.isOpen && (
        <div className='mt-24px'>
          <PriceBreakDownSection
            orderDetails={orderDetails}
            orderType={PRICE_BREAK_DOWN_SECTION.FORWARD_ORDER.value}
            show_price_section_keys={orderDetails.type === LIVE_ORDER_TYPES.STO && showPriceSection}
          />
        </div>
      )}

      <SKUBarcodeModal modalOpen={barcodeModalOpen} toggleSidebar={toggleBarcodeModal} data={barcodeList} />
      <BatchDeatilsSideSheet modalOpen={batchModalOpen} toggleSidebar={toggleBatchModal} data={batchModalData} />
      <SimpleSkuDetailModal skuForDetails={skuForDetails} toggleSimpleSkuModal={() => handleSkuDetailsSidebar({})} isOpen={openSkuDetailsSidebar} />
      <CustomerNoteModal isCustomerNoteShow={customerNoteCard.isOpen} setIsCustomerNoteShow={setCustomerNoteCard} customerNote={customerNoteCard.customerNote} />

      <BundleSkuDetailsSideSheet isOpen={openBundleSkuDetailsSidebar} bundleSkuForDetails={skuForDetails} toggleBundleSkuModal={() => handleBundleSkuDetailsSidebar(false)} />
      <Attributes isOpen={attributes.isOpen} toggle={toggleAttributes} itemAttributes={attributes.attributes} skuDetails={skuForDetails}/>
    </>
  )
}

export default OrderDetailsTable