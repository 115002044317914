export const SHIPMENT_TABLE_COLUMN_HEADER = {
  SR_NO: {name: 'S. No.'},
  AWB_NUMBER: {name: 'AWB Number'},
  ORDER_ID: {name: 'Order ID'},
  DELIVERY_STATUS: {name: 'Delivery Status'},
  SHIPMENT_STATUS: {name: 'Shipment Status'},
  RETURN_ORDER_ID: { name: 'Return Order ID' },
  SHIPPING_ACCOUNT: {name: 'Shipping Account'},
  SHIPPING_PARTNER: {name: 'Shipping Partner'},
  COURIER_PARTNER: {name: 'Courier Partner'},
  NO_OF_PACKAGES: {name: 'No. of Packages'},
  HANDED_OVER_TO: {name: 'Handed Over To'},
  CUSTOMER_NAME: {name: 'Customer Name'},
  CUSTOMER_PHONE_NUMBER: {name: 'Customer Phone Number'},
  SHIPMENT_CREATED_AT: {name: 'Created At'},
  SHIPPED_AT: {name: 'Shipped At'},
  CANCELLED_AT: { name: 'Cancelled At' },
  SHIPMENT_UPDATED_AT:{name: 'Updated At'},
  DISPATCHED_AT: {name: 'Dispatched At'},
  DELIVERY_AT: {name: 'Delivered At'},
  EXPECTED_DELIVERY_AT: {name: 'Expected Delivery At'},
  DELIVERY_REATTEMPTS: {name: 'Delivery Reattempts'},
  SORTING_CODE: {name: 'Sorting Code'},
  MANIFEST_NUMBER: {name: 'Manifest Number', key: 'manifest_number'},
  CONVEYOR_BELT_STATUS: {name: 'Conveyor Belt Status', key: 'conveyor_belt_status'},
  DROP_COUNT: {name: 'Dropped in Channel', key: 'drop_count'}
}
export const customReactSelectStyles = ({isTransparent = false, menuHeight = '160px', selectedContainerHeight = '35px'}) => {
  return {
    container: (provided) => ({
      ...provided,
      width: '100%'
    }),
    control: (provided) => ({
      ...provided,
      // width: `230px`,
      borderColor: isTransparent ? 'transparent' : '#DEDEDE',
      minHeight: selectedContainerHeight,
      maxHeight: selectedContainerHeight,
      boxShadow: 'none',
      cursor: 'pointer',

      '&:hover': {
        borderColor: isTransparent ? 'transparent' : '#5468fa'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      overflow: 'visible',
      textAlign: 'start',
      paddingLeft: isTransparent ? 0 : '10px'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#222222'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      top: state.hasValue || state.selectProps.inputValue ? '-47%' : '13%',
      transition: 'top 0.1s, font-size 0.1s',
      fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
      backgroundColor: 'white',
      color: '#999999'
    }),
    clearIndicator: (provided) => ({
      ...provided,
      paddingRight: 0
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'lightgray',
      paddingLeft: 0,
      paddingRight: 0,
      marginInlineEnd: '10px',
      '&:hover': {
        color: 'gray'
      },
      svg: {
        color: '#666666 !important',
        width: '15px',
        height: '15px'
      }
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    menuList: (provided, state) => ({
      ...provided,
      textAlign: 'start',
      color: '#222222',
      maxHeight: state.selectProps.menuIsOpen ? menuHeight : '40px'
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer'
    })
  }
}

export const tableFilterReactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    borderColor: state.isFocused ? '#d8d6de !important' : '#d8d6de !important',
    minHeight: '30px',
    maxHeight: '30px',
    minWidth: '180px',
    fontSize: '12px',
    paddingTop: '0px',
    '&:hover': {
      borderColor: '#5468FA !important',
      cursor: 'pointer'
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black !important'
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? '-37%' : '20%',
    marginTop: '-2px',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
    backgroundColor: 'transparent'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0
    // fontSize: 2
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    marginInlineEnd: '10px',
    width: '20px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  })
}

export const SHIPMENTS_STATUS = {
  READY_TO_SHIP : {key: 'ready_to_ship', name:'Ready To Ship'},
  SHIPPED : {key: 'shipped', name:'Shipped'},
  DISPATCHED: {key: 'dispatched', name:'Dispatched'},
  IN_TRANSIT: {key: 'in_transit', name: 'In Transit'},
  OUT_FOR_DELIVERY: {key: 'out_for_delivery', name: 'Out For Delivery'},
  SHIPMENT_DELIVERED : {key: 'delivered', name: 'Delivered'},
  SHIPMENT_CANCELLED: {key: 'cancelled', name: 'Cancelled'},
  ITEMS_PICKED_UP: {key: 'picked_up', name: 'Item(s) Picked Up'},
  RETURN_SHIPMENT_CREATED: {key: 'ready_for_pickup', name: 'Reverse Shipment Created'},
  RETURN_TO_ORIGIN: {key: 'return_to_origin', name: 'Return To Origin'},
  RETURN_IN_PROGRESS: {key: 'return_in_progress', name: 'Return in Progress'},
  ARRIVED_AT_HUB: {key: 'reached_at_hub', name: 'Return To Origin'},
  ALL: { key: 'All', name: 'all' },
  CREATED:{key:'created', name: 'Created'},
  PARTIALLY_DELIVERED: { key: 'partially_delivered', name: 'Partially Delivered' },
  LOST:{ key: 'lost', name: 'Lost'},
}

export const FORWARD_SHIPMENT_STATUS_OPTIONS = [
  { label: 'Ready To Ship', value: 'ready_to_ship', date_placeholder: 'Shipment Created Date' },
  { label: 'Dispatched', value: 'dispatched', date_placeholder: 'Dispatched Date' },
  { label: 'In Transit', value: 'in_transit', date_placeholder: 'Shipment Created Date' },
  { label: 'Out For Delivery', value: 'out_for_delivery', date_placeholder: 'Delivery Date' },
  { label: 'Delivered', value: 'delivered', date_placeholder: 'Delivered Date' },
  { label: 'Cancelled', value: 'cancelled', date_placeholder: 'Cancelled Date' },
  { label: 'Created', value: 'created', date_placeholder: 'Created Date' },
  { label: 'Partially Delivered', value: 'partially_delivered', date_placeholder: 'Partially Delivered' },
  { label: 'Lost', value: 'lost'}
]

export const CUSTOMER_RETURN_OPTIONS = [
  { label: 'Reverse Shipment Created', value: 'ready_for_pickup' },
  { label: 'Item(s) Picked Up', value: 'picked_up' },
  { label: 'Return To Origin', value: 'reached_at_hub' }
]

export const COURIER_RETURN_OPTIONS = [
  { label: 'Return in Progress', value: 'return_in_progress' },
  { label: 'Return To Origin', value: 'return_to_origin' }
]

export const FORWARD_SHIPMENTS_SEARCH__COLUMNS_LIST = [
  {
    id: 'awb_number',
    name: 'AWB Number'
  },
  {
    id: 'seller_sales_channel_order_ids',
    name: 'Order ID'
  },
  { 
    id: 'customer_phone',
    name: 'Customer Mobile Number' 
  },
  { 
    id: 'customer_email',
    name: 'Customer Email'
  }
]

export const RETURN_SHIPMENTS_SEARCH__COLUMNS_LIST = [
  {
    id: 'awb_number',
    name: 'AWB Number'
  },
  {
    id: 'seller_sales_channel_order_ids',
    name: 'Order ID'
  },
  // {
  //   id: 'return_order_id',
  //   name: 'Return Order ID'
  // },
  { 
    id: 'customer_phone',
    name: 'Customer Mobile Number' 
  },
  { 
    id: 'customer_email',
    name: 'Customer Email'
  }
]
export const RETURN_SHIPMENT_TABS = {
  COURIER_PARTNER_RETURN: { name: 'Courier Partner Return', key: 'rto_cancelled' },
  CUSTOMER_RETURN: { name: 'Customer Return', key: 'post_delivery' }
  // APPROVED: {name: "Approved", key: "approved"}
}

export const DATE_FILTER_KEY = {
  CREATED: 'created',
  UPDATED: 'updated'
}
export const FILTER_KEYS = {
  SHIPMENT_STATUS:'shipment_status'
}

export const SHIPMENT_STATUS = {
  ACTIVE: {label:'Active', value:'active'},
  INACTIVE: {label:'Inactive', value:'inactive'}
}

export const SHIPMENT_STATUS_FILTER_OPTIONS = [{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }]

export const ADDITIONAL_FILTER_DROPDOWN_STYLE = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}

export const CONVEYOR_BELT_STATUS = {
  dropped_at_channel: { label: 'Dropped At Channel', value: 'dropped_at_channel' },
  collected_from_channel: { label: 'Collected From Channel', value: 'collected_from_channel' },
  removed_from_channel: { label: 'Removed From Channel', value: 'removed_from_channel' }
}