// ** React Imports
import { retry } from '@src/utility/retry'
import PublicTracking from '@src/views/public-tracking'
import ShipmentsTracking from '@src/views/public-tracking/tms/shipments-tracking'
import { lazy } from 'react'

const Authentication = lazy(() => retry(() => import('../../pages/authentication')))

const AuthenticationRoutes = [
  {
    path: '/tracking',
    element:  <Authentication />,
    title: 'Public Tracking',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/tracking/:trackingId',
    element: <PublicTracking />,
    title: 'Public Tracking Detail',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/shipments/tracking/:trackingId',
    element: <ShipmentsTracking/>,
    title: 'Public Tracking Detail',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/shipments/update_location',
    element: <Authentication/>,
    title: 'Update Location',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/login',
    element: <Authentication />,
    title: 'Login',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/forgot-password',
    element: <Authentication />,
    layout: 'BlankLayout',
    title: 'Forgot Password',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/set-password',
    element: <Authentication />,
    title: 'Set Password',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/reset-password',
    element: <Authentication />,
    title: 'Reset Password',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
]

export default AuthenticationRoutes
