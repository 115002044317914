import BarcodeScanner from '@src/@core/components/ui/barcodeScanner'
import InputField from '@src/@core/components/ui/input-field'
import RadioButton from '@src/@core/components/ui/radio-button'
import { KEYBOARD_KEYS } from '@src/App.constants'
import {
  clearGrnFailBinId,
  clearGrnPassBinId
} from '@src/views/inventory/store'
import { clearCreateReturnGrnResponse, createReturnGrn, processNextReturnOrder } from '@src/views/returns/store'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
const OffTypeInputTypeOptions = {
  awbNumber: {label: 'AWB Number', value: 'awb'},
  returnOrderId: {label: 'Return Order ID', value: 'returnOrderId'}
}
const GrnWithOffTypeReturns = ({isNextOrderProcess}) => {
  const {
    control,
    register,
    watch,
    setValue
  } = useForm()
  const dispatch = useDispatch()
  const loadingState = useSelector((state) => state.inventory.loadingState)
  const [offTypeReturnInput, setOffTypeReturnInput] = useState(OffTypeInputTypeOptions.awbNumber)
  const returnGrnResponse = useSelector((state) => state.returns.createReturnGrnResponse?.data)
  const handleClearSearchBarcode = () => {
    setValue('awb_number', '')
    setValue('return_order_id', '')
  }

  const handleChangeOffTypeReturnInput = (type) => {
    setOffTypeReturnInput(type)
    handleClearSearchBarcode()
  }

  const handleCreateReturnGrn = (e) => {
    if (e.key !== KEYBOARD_KEYS.ENTER) return

    const awbNumber = watch('awb_number')?.trim()
    const returnOrderId = watch('return_order_id')?.trim()
    
    if (!awbNumber && !returnOrderId) return

    if (isNextOrderProcess) {
      const queryParams = {
        awb_number: awbNumber || undefined,
        return_order_id: returnOrderId || undefined
      }
      dispatch(processNextReturnOrder({ grn_id: returnGrnResponse.id, params: queryParams, _audioRequired: !!awbNumber}))
      return
    }
    
    const body = {
      awb_number: awbNumber || undefined,
      return_order_id: returnOrderId || undefined,
      errorConfig: {
        audioRequired: !!awbNumber
      }
    }

    dispatch(clearCreateReturnGrnResponse())
    dispatch(clearGrnPassBinId())
    dispatch(clearGrnFailBinId())
    setValue('pass_bin_barcode', '')
    setValue('fail_bin_barcode', '')
    dispatch(createReturnGrn(body))
  }

  return (
    <div className="d-flex flex-column gap-24px">
      <div className="flex-center-start gap-32px">
        {
          Object.values(OffTypeInputTypeOptions).map((offTypeOption) => {
            return (
              <RadioButton
                id={offTypeOption.value}
                name={offTypeOption.value}
                label={offTypeOption.label}
                checked={offTypeReturnInput.value === offTypeOption.value}
                onClick={() => handleChangeOffTypeReturnInput(offTypeOption)}
              />
            )
          })
        }
      </div>
      {offTypeReturnInput.value === OffTypeInputTypeOptions.awbNumber.value && <BarcodeScanner
        width="380px"
        startIcon={
          <img
            src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
            alt="QR Code"
            width="16px" 
            height="16px"
          />
        }
        placeholder="Scan AWB Number"
        control={control}
        loading={loadingState.createNewGrnForGateEntry}
        name="awb_number"
        onKeyDown={handleCreateReturnGrn}
        hasValue={watch('awb_number')}
        handleClear={handleClearSearchBarcode}
      />}
      {offTypeReturnInput.value === OffTypeInputTypeOptions.returnOrderId.value && <InputField
        {...register('return_order_id')}
        name='return_order_id'
        isClearable
        label="Return Order ID"
        // isRequired
        width="100%"
        onKeyDown={handleCreateReturnGrn}
        handleClear={handleClearSearchBarcode}
      />}
    </div>
  )
}

export default GrnWithOffTypeReturns
