/* eslint-disable @typescript-eslint/no-explicit-any */
import { SHIPMENT_ORDERS_VIEW_OPTIONS } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { iShipmentOrderView } from '../../../../shipping-aggregator-types';
import OrdersTableView from './orders-table-view';
import PackageTableView from './package-table-view';

type iNewOrders = {
  shipmentOrderView: iShipmentOrderView;
  selectedTabId: number;
  // eslint-disable-next-line no-unused-vars
  setTableMetaData: (data: any) => void
}

const NewOrders = ({shipmentOrderView, setTableMetaData}: iNewOrders) => {

  return (
    <div className='p-16px d-flex flex-column gap-16px shipment-orders'>

      {
        shipmentOrderView.id === SHIPMENT_ORDERS_VIEW_OPTIONS.ORDER.id ? 
          <OrdersTableView
            setTableMetaData={setTableMetaData}
          />
          :
          <PackageTableView
            setTableMetaData={setTableMetaData}
          />
      }
    </div>
  );
};

export default NewOrders;
