import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import { DROPDOWN_TYPES } from '@src/@core/components/ui/customTableFilterComponent/customTableFilterComponent.constant'
import { loadCountryConfig } from '@src/app.apis'
import { KEYBOARD_KEYS, MANUAL_SHIPPING_ACCOUNT, SKU_TYPES } from '@src/App.constants'
import { getOptionsFetcher, validateDecimals, validatePositiveNumber } from '@src/utility/Utils'
import classNames from 'classnames'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { CREATE_ORDER_FORM_FIELD_NAMES } from './create-order/create-order.constant'
import { ORDER_DETAILS_BUTTON_TYPE, TABLE_HEADER_DIRECTION } from './sales.constant'
import { getFreeTagsForFilter } from './store/store'

export const showSkuTypeTag = (skuType) => {
  switch (skuType) {
  case SKU_TYPES.BUNDLE.key:
    return SKU_TYPES.BUNDLE.display_name
  case SKU_TYPES.ASSEMBLE.key:
    return SKU_TYPES.ASSEMBLE.display_name
  case SKU_TYPES.GIFT.key:
    return SKU_TYPES.GIFT.display_name
  default:
    return false
  }
}
  
export const getStatusComponent = ({status}) => {
  switch (status) {
  case 'Unfulfilled':
    return (
      <CustomTag className="text-warning bg-warning-light" title='Unfulfilled' />
    )
  case 'Fulfilled':
    return (
      <CustomTag className="text-success bg-success-light" title='Fulfilled'/>
    )
  case 'Cancelled':
    return (
      <CustomTag className="text-danger bg-danger-light" title='Cancelled' />
    )
  case 'Refunded':
    return (
      <CustomTag className="text-purple bg-purple-light" title='Refunded' />
    )
  case 'Available':
    return (
      <CustomTag className="text-white bg-danger" title='Available'/>
    )
  case 'Complete':
    return (
      <CustomTag className="text-success bg-success-light" title='Complete' />
    )
  case 'Partial':
    return (
      <CustomTag className="text-warning bg-warning-light" title='Partial' />
    )
  default:
    return null
  }
}

export const OrderDetailsRow = ({ entityName, entityValue, entityNameColSpan = 3, entityValueColSpan = 9, modifyLabelStyle = '' }) => {
  const {t} = useTranslation()
  return <>
    <Row className="p-0 m-0">
      <Col className={`col-${entityNameColSpan} p-0 m-0 ${modifyLabelStyle || ''} text-break`}>{t(entityName)}</Col>
      <Col className={`col-${entityValueColSpan} p-0 ps-24px m-0 text-dark text-break`}>{entityValue}</Col>
    </Row>
  </>
}

export const categorizeOrderDetailsButtons = (orderDetails, buttonArray) => {
  const buttons = orderDetails?.buttons || []
  const buttonCategories = {
    actionButtons: [],
    syncButtons: {},
    editButtons: {}
  }

  buttonArray.forEach(({ id, name, onClick, hasPermission }) => {
    const button = buttons.find(button => button.key === id && button.type === ORDER_DETAILS_BUTTON_TYPE.ACTION_BUTTON.type && hasPermission)
    if (button) {
      buttonCategories.actionButtons.push({
        ...button,
        text: button.display_name,
        name,
        onClick,
        id:button.key
      })
    }
  })

  buttons.forEach(button => {
    if (button.type === ORDER_DETAILS_BUTTON_TYPE.SYNC_BUTTON.type) {
      buttonCategories.syncButtons[button.key] = button
    } else if (button.type === ORDER_DETAILS_BUTTON_TYPE.EDIT_BUTTON.type) {
      buttonCategories.editButtons[button.key] = button
    }
  })

  return buttonCategories
}

export const isValidUptoTwoDecimal = (value) => /^\d*\.?\d{0,2}$/.test(value)

export const customLabelIdsFilter = (t) => {
  return {
    filterKey: 'custom_label_ids',
    displayName: t('Tags'),
    type: DROPDOWN_TYPES.MULTI_ASYNC_DROPDOWN,
    options: getFreeTagsForFilter
  }
}

export function ShippingAccountColumn(shipment) {
  const accountName = shipment?.shipping_account?.name || ''
  const partnerName = shipment?.shipping_partner_name

  if (accountName || partnerName) {
    let partnerDisplay = ''
    if (partnerName) {
      partnerDisplay = (partnerName === MANUAL_SHIPPING_ACCOUNT && !accountName) ? MANUAL_SHIPPING_ACCOUNT : `(${partnerName})`
    }
    return `${accountName} ${partnerDisplay}`.trim()
  } else {
    return '-'
  }
}

export const disableEnterKeyEvent = (event) => {
  if (event.key === KEYBOARD_KEYS.ENTER) {
    event.preventDefault()
  }
}

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`
  }
  return str
}

export  const mergeAddress = (addressDetails) => {
  if (!addressDetails) return null
  const parts = [
    addressDetails.address_line1 || addressDetails.address1,
    addressDetails.address_line2 || addressDetails.address2,
    addressDetails.country,
    addressDetails.state,
    addressDetails.city,
    addressDetails.postal_code || addressDetails.zip
  ]
  const address = parts.filter(Boolean).join(', ')
  return address
}

export const orderTypeFilter = (t) => {
  return {
    filterKey: 'type',
    displayName: t('Order Type'),
    type: DROPDOWN_TYPES.DROPDOWN,
    options: [
      {label:'B2C',value:'b2c'},
      {label:'B2B',value:'b2b'},
      {label:'STO',value:'sto'},
      {label:'RTS',value:'rtv'},
    ]
  }
}


export const RenderSellerWithCode = ({ sellerName, sellerCode }) => {
  const sellerDisplay = sellerName ? `${sellerName}${sellerCode ? ` (${sellerCode})` : ''}` : '-';
  
  return (
    <div title={sellerDisplay} className="two-line-truncate">{sellerDisplay}</div>
  );
};
  
export const TableHeaderWithTooltip = ({ header, tooltipMessage, id, popperClassName = '', innerClassName = '', headerDirection = TABLE_HEADER_DIRECTION.START }) => {
  const [isTargetReady, setIsTargetReady] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTargetReady(true);
    }, 750);

    return () => clearTimeout(timeout);
  }, [])
  
  return (
    <div className={classNames('w-100 gap-8px', {
      'flex-center-end': headerDirection === TABLE_HEADER_DIRECTION.END,
      'flex-center-start': headerDirection === TABLE_HEADER_DIRECTION.START
    })}>
      <div>{t(header)}</div>
      {isTargetReady && <div>
        <Info size={16} className='cursor-pointer' id={`table-header-${id}`} />
        <UncontrolledTooltip
          offset={[0, 5]}
          target={`table-header-${id}`}
          className='text-center txt-h3-sb'
          {...(popperClassName ? { popperClassName } : {})}
          {...(innerClassName ? { innerClassName } : {})}
        >
          {t(tooltipMessage)}
        </UncontrolledTooltip>
      </div>}
    </div>
  );
};

// destination country Filter
export const destinationCountryFilter = (t) => {
  const loadCountryOptions = getOptionsFetcher(loadCountryConfig)

  return {
    filterKey: 'destination_country_id',
    displayName: t('Country'),
    type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
    options: loadCountryOptions
  }
};

export const getCreateOrderTotalPriceAndWeight = ({ watch }) => {
  const orderItems = watch(CREATE_ORDER_FORM_FIELD_NAMES.ORDER_ITEMS);

  let totalWeight = 0;
  let totalAmount = 0;

  orderItems.forEach((orderItem) => {
    const uom = orderItem.finalStruct.ordered_weight?.unit
    const orderedWeight = uom === 'g' ? (+orderItem.finalStruct.ordered_weight?.value / 1000) : +orderItem.finalStruct.ordered_weight?.value

    totalWeight += orderedWeight || 0;
    totalAmount += +orderItem.finalStruct.total || 0;
  });

  return { totalWeight: +totalWeight.toFixed(2), totalAmount: +totalAmount.toFixed(2) };
}
  
export const validateNumber = (e, revertValue) => {
  const value = e.target.value;
  if (!value) {
    return true;
  }
  if (validateDecimals(value) && validatePositiveNumber(value)) return true;
  if (revertValue) {
    e.target.value = revertValue();
  }
  return false;
};

export const getDeliveryStatusFilter = (t) => {
  return {
    filterKey: 'delivery_status',
    displayName: 'Delivery Status',
    type: DROPDOWN_TYPES.MULTI_DROPDOWN,
    options: [
      { label: t('Ready To Ship'), value: 'ready_to_ship' },
      { label: t('Dispatched'), value: 'dispatched' },
      { label: t('In Transit'), value: 'in_transit' },
      { label: t('Out For Delivery'), value: 'out_for_delivery' },
      { label: t('Delivered'), value: 'delivered' },
      { label: t('Cancelled'), value: 'cancelled' },
      { label: t('Return in Progress'), value: 'return_in_progress' },
      { label: t('Return To Origin'), value: 'return_to_origin' },
      { label: t('Partially Delivered'), value: 'partially_delivered'},
      { label: t('Lost'), value: 'lost'}
    ]
  }
}
// Helper function to manually format date as "DD-MM-YYYY"
export const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
  const year = date.getFullYear();

  return `${day}-${month}-${year}`; // Ensures "DD-MM-YYYY" format
};

// Helper function to get the range for the last 90 days
export const getLastNinetyDaysRange = () => {
  const today = new Date();

  // Get the 'to' date, which is today
  const toDate = formatDate(today); // Format as "DD-MM-YYYY"

  // Create a new date object for the 'from' date
  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(today.getDate() - 89); // Subtract 89 days to get exactly 90 days inclusive
  const fromDate = formatDate(ninetyDaysAgo); // Format as "DD-MM-YYYY"

  return { from: fromDate, to: toDate };
};

export const createDateRangeObject = (from, to) => {
  return {
    label: `${from} to ${to}`,
    value: {
      start: from,
      end: to,
    },
  };
};

