import SideSheet from '@src/@core/components/ui/sideSheet'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { AbilityContext } from '@src/utility/context/Can'
import { Countries } from '@src/views/hubs/utils/countries'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, ModalBody, Row } from 'reactstrap'
import { CUSTOMER_ADDRESS, CUSTOMER_MODAL_TYPE } from '../../constant/orders.constants'
import CreateCustomer from '../../pending-actions/pages/createOrder/CreateCustomer'
import { clearCustomerAddresses, getCustomerAddresses, resetSuccess } from '../../store/store'
import CustomerAddressCard from './customerAddressCard'
import './customerDetails.scss'
import Edit from '@src/assets/images/icons/Edit'

const CustomerDetails = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)
  const [openEditCustomerDetailsSidebar, setOpenEditCustomerDetailsSidebar] = useState({
    open: false,
    type: CUSTOMER_MODAL_TYPE.EDIT_CUSTOMER.key
  })
  const { openCustomerDetailsSidebar, setOpenCustomerDetailsSidebar, customerDetails, setOpenCreateAddressSidebar, selectedSeller, setIsEditCustomerEnabled } = props
  const customerAddresses = useSelector(store => store.sales.customerAddresses)
  const customerAddressesMetaData = useSelector(store => store.sales.customerAddressesMetaData)
  const loading = useSelector(store => store.sales.loading)
  const success = useSelector(store => store.sales.success)
  const isLastPage = customerAddressesMetaData?.current_page === customerAddressesMetaData?.last_page
  const {
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      customer_phone_number: {
        countryCode: {
          name: 'Saudi Arabia',
          flag: '🇸🇦',
          country_code: 'SA',
          calling_code: '+966',
          value: 'Saudi Arabia (SA)',
          label: 'Saudi Arabia (+966)',
          maxPhoneNumberLength: 9
        },
        contactNumber: ''
      }
    },
    mode: 'onChange'
  })

  const getCurrentCustomerAddress = () => {
    const params = {
      entity_id: customerDetails.id,
      entity_name: 'customer',
      page: customerAddressesMetaData?.current_page + 1 || 1,
      per_page: 20
    }
    if (!loading.getCustomerAddresses) dispatch(getCustomerAddresses({ params }))
  }
  const handleOnsideSheetClosed = () => {
    dispatch(clearCustomerAddresses())
    setIsEditCustomerEnabled(false)
    reset()
  }
  const handleOnSideSheetOpen = () => {
    const customerCountryCodeObj = Countries.find(country => country.calling_code === customerDetails.country_calling_code)
    setValue('first_name', customerDetails.first_name)
    setValue('last_name', customerDetails.last_name)
    setValue('email', customerDetails.email)
    if (customerDetails.phone_number) {
      setValue('customer_phone_number.countryCode', customerCountryCodeObj)
      setValue('customer_phone_number.contactNumber', customerDetails.phone_number)
      setValue('country_code', customerCountryCodeObj?.country_code)
    }
    if (ability.can(abilityMap.customer.view_address.action, abilityMap.customer.view_address.resource
    )) getCurrentCustomerAddress()
  }
  const handleCreateNewAddress = () => {
    setOpenCreateAddressSidebar((prev) => ({
      ...prev,
      open: true,
      type: CUSTOMER_ADDRESS.CREATE_ADDRESS.key,
      entity_id: customerDetails.id
    }))
  }
  const handleEditCustomerDetails = () => {
    setOpenEditCustomerDetailsSidebar({
      open: true,
      type: CUSTOMER_MODAL_TYPE.EDIT_CUSTOMER.key
    })
  }
  const editCustomerAddress = (e, id) => {
    e.stopPropagation()
    setOpenCreateAddressSidebar((prev) => ({
      ...prev,
      open: true,
      type: CUSTOMER_ADDRESS.EDIT_ADDRESS.key,
      entity_id: customerDetails.id,
      address_id: id
    }))
  }
  const handleScroll = (container) => {
    const tolerance = 5
    const { scrollTop, clientHeight, scrollHeight } = container
    if ((scrollTop + clientHeight >= scrollHeight - tolerance) && (clientHeight !== 0 && scrollTop !== 0) && (!loading.getCustomerAddresses && !isLastPage) && customerAddressesMetaData?.total !== 0) {
      getCurrentCustomerAddress()
    }
  }

  useEffect(() => {
    if (success.createAddress || success.editAddress) {
      dispatch(clearCustomerAddresses())
      setOpenCreateAddressSidebar(prev => ({ ...prev, open: false }))
      dispatch(resetSuccess())
      const params = {
        entity_id: customerDetails.id,
        entity_name: 'customer',
        page: 1,
        per_page: 20
      }
      if (!loading.getCustomerAddresses) dispatch(getCustomerAddresses({ params }))
    }
  }, [success])

  useEffect(() => {
    if (watch('email') && errors.customer_phone_number) clearErrors('customer_phone_number')
    if (watch('customer_phone_number.contactNumber') && errors.email?.type === 'required') clearErrors('email')
  }, [watch('email'), watch('customer_phone_number.contactNumber')])
  
  return (
    <>
      <SideSheet
        isOpen={openCustomerDetailsSidebar}
        toggle={() => { setOpenCustomerDetailsSidebar(false) }}
        contentClassName="p-0"
        modalClassName="modal-slide-in sidebar-todo-modal"
        title='Customer Details'
        onOpened={handleOnSideSheetOpen}
        onClosed={handleOnsideSheetClosed}
      >
        <PerfectScrollbar>
          {customerDetails && <ModalBody className='customers-details-modal-body p-0'>
            <div className={classNames('d-flex flex-column p-24px')}>
              <Card className="details-card w-100 bg-white rounded-3">
                <div className="card-header d-flex justify-content-between py-12px px-16px rounded-top-2 text-secondary txt-h3-md">
                  <span className="txt-h3-md text-dark">{t('Personal Information')}</span>
                  {ability.can(
                    abilityMap.customer.edit.action,
                    abilityMap.customer.edit.resource
                  ) && <span
                    onClick={handleEditCustomerDetails}className="cursor-pointer"><Edit height={18} width={18} /></span>}
                </div>
                <div className='d-flex flex-column p-16px gap-16px'>
                  <Row>
                    <Col xs={4}>{t('Customer Name')}:</Col>
                    <Col className="text-black text-capitalize">{customerDetails.first_name} {customerDetails.last_name}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}>{t('Email ID')}:</Col>
                    <Col className="text-black">{customerDetails.email || '-'}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}>{t('Phone No')}:</Col>
                    <Col className="text-black">{customerDetails.phone || '-'}</Col>
                  </Row>
                </div>
              </Card>
      
              {ability.can(
                abilityMap.customer.view_address.action,
                abilityMap.customer.view_address.resource
              ) && <>
                <div className="flex-center-between mt-24px mb-16px">
                  <span className="txt-h3-md text-dark">{t('Customer Address')}</span>
                  {ability.can(
                    abilityMap.customer.create_address.action,
                    abilityMap.customer.create_address.resource
                  ) && <span className="txt-h3-md cursor-pointer address-color" onClick={handleCreateNewAddress}>{t('Add New Address')}</span>}
                </div>
                <div className="customer-address pb-24px overflow-auto flex-fill mb-2">
                  {!customerAddresses && loading.getCustomerAddresses ? <ComponentSpinner /> :
                    <PerfectScrollbar onYReachEnd={handleScroll} options={{ wheelPropagation: true }}>
                      <div className="address-wrapper">
                        {customerAddresses?.length > 0 ?
                          customerAddresses.map((address, index) => {
                            return (
                              <CustomerAddressCard
                                address={address}
                                key={index}
                                editCustomerAddress={editCustomerAddress}
                              />
                            )
                          }) : <div className='text-center mt-12px txt-h2-md'>{t('No Address Found')}</div>}
                      </div>
                    </PerfectScrollbar>
                  }
                </div>
              </>}
            </div>
          </ModalBody>}
        </PerfectScrollbar>
      </SideSheet>
      <CreateCustomer
        openCreateCustomerSidebar={openEditCustomerDetailsSidebar}
        setOpenCreateCustomerSidebar={setOpenEditCustomerDetailsSidebar}
        selectedSeller={selectedSeller}
        customerDetails={customerDetails}
      />
    </>
  )
}

export default CustomerDetails