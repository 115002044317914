import Button from '@src/@core/components/ui/button'
import InputField from '@src/@core/components/ui/input-field'
import { INPUT_TYPES } from '@src/App.constants'
import clearIcon from '@src/assets/images/svg/X.svg'
import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Clock } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import './timepicker.scss'
import { ErrorIcon } from '/src/assets/images/icons/ErrorIcon'

const TimePicker = ({ onChange, error = '', label, placeHolder, value, isRequired = false, disabled = false, setIsTimeDropdownOpen = () => { }, isClearable = true }) => {

  //translation
  const {t} = useTranslation()
  
  //ref
  const dropdownRef = useRef()

  //states
  const [timeValue, setTimeValue] = useState(value || '')
  const [timeRangePickerOpen, setTimePickerRangeOpen] = useState(false)

  const hrInput = () => {

    const hrArr = []
    for (let i = 1; i < 13; i++) {
      hrArr.push(i)
    }

    return hrArr

  }

  const minInput = () => {

    const minArr = []
    for (let i = 0; i < 60; i++) {
      minArr.push(i)
    }
    return minArr

  }

  const handleInputFieldChange = (e) => {
    setTimeValue(e.target.value)
    onChange(timeValue)
  }

  const handleInputFocusAndDivClick = () => {
    if (!disabled) {
      setTimePickerRangeOpen(true) 
      onChange(timeValue)
    }
  }

  const handleInputFieldBlur = () => {
    setTimePickerRangeOpen(false)
    onChange(timeValue)
  }

  const openTimePicker = () => {
    if (!disabled) setTimePickerRangeOpen((p) => !p)
  }

  const handleXClicked = (e) => {
    e.stopPropagation()
    if (!disabled) {
      setTimeValue('')
      onChange('') 
    }
  }

  const handleDoneButtonClick = (e) => {
    e.stopPropagation()
    setTimePickerRangeOpen(false)
    onChange(timeValue)
  }

  const keyDown = (e) => {
    const key = e.keyCode
    if (key === 32) {
      e.preventDefault()
    }
  }

  const handleMinClick = (value) => {
    let min = value
    const hour24 = timeValue.split(':').map(Number)[0]
    let newHr = hour24
    if (hour24 < 10) newHr = `0${hour24}`
    if (min < 10) min = `0${min}`
    setTimeValue(`${newHr}:${min}`)
    onChange(timeValue)
  }

  const handleHrClick = (value) => {
    let newHr = value
    const minute = timeValue.split(':').map(Number)[1]
    let newMin = minute || 0
    if (newMin < 10) newMin = `0${newMin}`
    if (newHr < 10) newHr = `0${newHr}`
    setTimeValue(`${newHr}:${newMin}`)
    onChange(timeValue)
  }

  const handleAmClick = () => {
    const [hour24, minute] = timeValue.split(':').map(Number)
    let hourAm = hour24
    let minuteAm = minute || 0
    if (hour24 >= 12) hourAm = hour24 - 12
    if (hourAm < 10) hourAm = `0${hourAm}`
    if (minuteAm < 10) minuteAm = `0${minuteAm}`
    setTimeValue(`${hourAm}:${minuteAm}`)
    onChange(timeValue)
  }

  const handlePmClick = () => {
    const [hour24, minute] = timeValue.split(':').map(Number)
    let hourPm = hour24
    let minutePm = minute || 0
    if (hour24 < 12) hourPm = hour24 + 12
    if (hourPm < 10) hourPm = `0${hourPm}`
    if (minutePm < 10) minutePm = `0${minutePm}`
    setTimeValue(`${hourPm}:${minutePm}`)
    onChange(timeValue)
  }

  const getTimeValue = () => {
    const [hour24, minute] = timeValue !== '' ? timeValue.split(':').map(Number) : [undefined, undefined]
    let hour12 = hour24
    if (hour12 > 12) hour12 = hour24 - 12
    if (hour12 === 0) hour12 = 12
    return { hour12, minute, hour24 }
  }

  useOnClickOutside(dropdownRef, () => {
    if (timeRangePickerOpen) {
      onChange(timeValue)
      setTimePickerRangeOpen(false)
    }
  })

  useEffect(() => {
    if (!timeValue) {
      setTimeValue(value || '')
    }
  }, [value])
  
  useEffect(() => {
    setIsTimeDropdownOpen(timeRangePickerOpen)
  }, [timeRangePickerOpen])

  return (
    <div className={classNames('omniful-time-picker-component-wrapper', {'active-error': error})} ref={dropdownRef}>
      <Dropdown isOpen={timeRangePickerOpen} className='w-100' toggle={handleInputFocusAndDivClick}>
        <DropdownToggle className='time-picker-input-wrapper'>
          <div className='d-flex gap-16px align-items-center justify-content-center w-100 position-relative'>
            {timeRangePickerOpen || timeValue ?
              <>
                <InputField
                  type={INPUT_TYPES.TIME}
                  isRequired={isRequired}
                  label={label}
                  value={timeValue || ' '}
                  onChange={handleInputFieldChange}
                  onKeyDown={keyDown}
                  className={`w-100 ${disabled ? 'cursor-auto' : 'cursor-pointer'}`}
                  placeHolder={placeHolder}
                  startAdornment={<Clock onClick={openTimePicker} className="cursor-pointer" size={16} color='var(--bs-dark)' />}
                  disabled={disabled}
                />
                {(timeValue && !disabled && isClearable) && <img src={clearIcon} alt="clearIcon" onClick={handleXClicked} className='cross-icon' />}
              </>
              : <div className={classNames('d-flex align-items-center gap-8px timepicker-placeholder', {'active-error': error, 'bg-light-4 cursor-default':disabled})}>
                <Clock onClick={openTimePicker} className="cursor-pointer" size={17} color={error ?'var(--bs-danger)' :'var(--bs-dark)'} />
                <span className='text-dark-5 txt-sub-rg title'>{placeHolder}</span>
                <span className='label txt-sub-rg'>{label}</span>
              </div>
            }
          </div>
          {error && <div className=' text-danger d-flex align-items-center text-start gap-4px ps-12px pt-4px txt-sub-rg'> <span className="info-icon me-4px width-fit-content"> <ErrorIcon/> </span><div className='pt-2px'>{error}</div></div>}
          {timeRangePickerOpen &&
            <DropdownMenu className="time-picker-dropdown-wrapper bg-white">
              <div className="time-picker-dropdown-body">
                <div className="hr-input-div d-flex flex-column py-8px">
                  {
                    hrInput().map((value, index) => (
                      <div
                        key={index}
                        onClick={() => handleHrClick(value)}
                        className={
                          classNames(
                            'cursor-pointer fs-14 flex-center-center',
                            {
                              'selected-hr': value === getTimeValue().hour12,
                              'hr-input-value-div': value !== getTimeValue().hour12
                            }
                          )
                        }
                      >
                        {value < 10 ? `0${value}` : value}
                      </div>
                    ))
                  }
                </div>
                <div className="min-input-div d-flex flex-column py-8px">
                  {
                    minInput().map((value, index) => (
                      <div
                        key={index}
                        onClick={() => handleMinClick(value)}
                        className={
                          classNames(
                            'cursor-pointer fs-14 flex-center-center',
                            {
                              'selected-min': value === getTimeValue().minute,
                              'min-input-value-div': value !== getTimeValue().minute
                            }
                          )
                        }
                      >
                        {value < 10 ? `0${value}` : value}
                      </div>
                    ))
                  }
                </div>
                <div className="hr-input-div d-flex flex-column py-8px px-4px">
                  <div onClick={handleAmClick}
                    className={
                      classNames(
                        'cursor-pointer flex-center-center py-9px px-18px',
                        {
                          'am-pm-hover': getTimeValue().hour24 > 12,
                          'am-pm-selection': getTimeValue().hour24 < 12,
                          'no-am-pm': timeValue === '',
                          'am-12': getTimeValue().hour24 === 12
                        }
                      )
                    }
                  >
                    {t('AM')}
                  </div>
                  <div onClick={handlePmClick}
                    className={
                      classNames(
                        'cursor-pointer flex-center-center py-10px px-18px',
                        {
                          'am-pm-hover': getTimeValue().hour24 < 12,
                          'am-pm-selection': getTimeValue().hour24 >= 12,
                          'no-am-pm': timeValue === ''
                        }
                      )
                    }
                  >
                    {t('PM')}
                  </div>
                </div>
              </div>
              <div className="flex-end align-content-center p-8px" onBlur={handleInputFieldBlur}>
                <Button
                  className='cursor-pointer'
                  type='Button'
                  ofType='compressed'
                  ofStyle='noBackground'
                  onClick={handleDoneButtonClick}
                >
                  {t('Done')}
                </Button>
              </div>
            </DropdownMenu>
          }
        </DropdownToggle>
      </Dropdown>
    </div>
  )

}

export default TimePicker