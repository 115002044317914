import { Frame } from '@src/assets/data/assets'
import { useTranslation } from 'react-i18next'

const NoSkuScannedState = () => {
  const { t } = useTranslation()

  return (
    <div className="rounded-16px bg-light-2 d-flex flex-column gap-16px align-items-center justify-content-center px-24px py-40px">
      <div>
        <Frame />
      </div>
      <div>
        {t('By default, all the scanned items will be marked as pass.')}
      </div>
    </div>
  )
}

export default NoSkuScannedState