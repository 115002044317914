import Button from '@src/@core/components/ui/button'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { AbilityContext } from '@src/utility/context/Can'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { Plus, RefreshCcw } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { DELIVERY_TYPE, SHIPPING_ENTITY } from '../constant/orders.constants'
import OrderTagsMapper from '../live-orders/pages/packingOrderWise/StationPages/packingOrderTabsPages/OrderTagsMapper'
import AddFreeTags from '../pending-actions/components/AddFreeTags/AddFreeTags'
import { getOrdersApi } from '../sales.apis'
import { EDIT_MODAL_TYPE } from '../sales.constant'
import { getShippingDetails, resetSuccess, syncOrderPaymentStatus } from '../store/store'
import Edit from '@src/assets/images/icons/Edit'

const OrderDetails = ({setIsShipmentCreated, isShipmentCreated, handleEditDetailsModal}) => {
  const shippingDetailsData = useSelector((store) => store.sales.shippingDetailsData)
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const dispatch = useDispatch()
  const sales_loading = useSelector((state) => state.sales.loading)
  const sales_success = useSelector((state) => state.sales.success)
  const [isOpenAddLocationAttributesModal, setIsOpenAddLocationAttributesModal] = useState(false)
  const queryClient = useQueryClient()

  const handleSyncPaymentStatus = () => {
    dispatch(syncOrderPaymentStatus({seller_sales_channel_order_id:shippingDetailsData.entity_data.seller_sales_channel_order_id, seller_id:shippingDetailsData.entity_data.seller_id, seller_sales_channel_id:shippingDetailsData.entity_data.seller_sales_channel_id}))
  }

  useEffect(() => {
    if (shippingDetailsData && shippingDetailsData.status === 'Packed') {
      setIsShipmentCreated(true)
    }
  }, [shippingDetailsData])

  useEffect(() => {
    if (sales_success.syncOrderPaymentStatus) {
      dispatch(resetSuccess())
      dispatch(getShippingDetails({ params: { entity_id: shippingDetailsData.entity_data.oms_order_id, entity_name: SHIPPING_ENTITY.FORWARD_ORDER.value } }))
    }
  }, [sales_success.syncOrderPaymentStatus])

  useEffect(() => {
    if (sales_success.editCustomerAndShippingDetails) {
      queryClient.invalidateQueries({ queryKey: [getOrdersApi.apiKey] })
    }
  }, [sales_success.editCustomerAndShippingDetails])

  const toggleAddLocationAttributesModal = () => {
    setIsOpenAddLocationAttributesModal(p => !p)
  }

  return (
    <div className='shipment-order-details-section'>
      {shippingDetailsData &&
                <div className='d-flex w-100 gap-1 px-1 mb-1 order-details'>
                  <div className='bg-white w-50 border rounded-4'>
                    <h5 className='py-1 px-1 fw-bolder order-details-heading text-secondary mb-0 rounded-top-4 txt-h3-md'>
                      {t('Order Details')}
                    </h5>
                    <div className="py-1 px-1">
                      <Row className='mb-1'>
                        <Col className='col-5'>{t('Order ID')}:</Col>
                        <Col className='text-secondary col-7'>
                          {shippingDetailsData.entity_data?.seller_sales_channel_order_id || '-'}
                          {shippingDetailsData.entity_data?.order_alias ? ` (${shippingDetailsData.entity_data.order_alias})` : ''}
                        </Col>
                      </Row>
                      {shippingDetailsData.entity_data.delivery_type === DELIVERY_TYPE.CLICK_AND_COLLECT.name && <Row className='mb-1'>
                        <Col className='col-5'>{t('Delivery Type')}:</Col>
                        <Col className='col-7'>
                          <span className="rounded-12px py-2px px-12px me-8px txt-sub-md text-danger bg-danger-light">
                            {t('Click & Collect')}
                          </span>
                        </Col>
                      </Row>}
                      <Row className='mb-1'>
                        <Col className='col-5'>{t('Order Tags')}:</Col>
                        <Col className='text-secondary col-7'>
                          <OrderTagsMapper customIcon={() => <span className="text-primary">+{shippingDetailsData.entity_data.tags?.length - 3}</span>} tagsArray={shippingDetailsData.entity_data.tags} />
                        </Col>
                      </Row>
                      {shippingDetailsData.entity_data.type !== 'RTS' ? <>
                        <Row className='mb-1'>
                          <Col className='col-5'>{t('Tags')}:</Col>
                          <Col className='text-secondary col-7 d-flex gap-6px'>
                            {shippingDetailsData.entity_data.custom_labels?.length > 0 ?
                              <>
                                {ability.can(abilityMap.order.edit_details.action, abilityMap.order.edit_details.resource) && <div className='text-primary txt-sub-rg cursor-pointer bg-primary-lighter width-24px height-24px flex-center-center rounded-12px' onClick={toggleAddLocationAttributesModal}>
                                  <Plus size={16} />
                                </div>}
                                <OrderTagsMapper customIcon={() => <span className="text-primary">+{shippingDetailsData.entity_data.custom_labels?.length - 2}</span>} tagsArray={shippingDetailsData.entity_data.custom_labels} viewItems={2} />
                              </>
                              :
                              <>
                                {ability.can(abilityMap.order.edit_details.action, abilityMap.order.edit_details.resource) ?
                                  <Button className="p-0 shadow-none" ofStyle='noBackground' onClick={toggleAddLocationAttributesModal}>{t('Add Tag')}</Button>
                                  : '-'
                                }
                              </>
                            }
                          </Col>
                          <AddFreeTags 
                            isOpen={isOpenAddLocationAttributesModal} 
                            toggle={toggleAddLocationAttributesModal} 
                            orderDetails={{...shippingDetailsData.entity_data, id: shippingDetailsData.entity_data.oms_order_id}}
                          />
                        </Row>
                      </> : null}
                      {shippingDetailsData.entity_data.sales_channel_name && <Row className='mb-1'>
                        <Col className='col-5'>{t('Channel')}:</Col>
                        <Col className='text-secondary col-7'>
                          {shippingDetailsData.entity_data.sales_channel_name || '-'}
                        </Col>
                      </Row>}
                      <Row className='mb-1'>
                        <Col className="col-5">{t('Payment Method')}:</Col>
                        <Col className="text-secondary fw-bold col-7 d-flex align-items-center">
                          {shippingDetailsData.entity_data.payment_method || '-'}
                          {shippingDetailsData.entity_data.order_metadata?.is_payment_sync_allowed && shippingDetailsData.entity_data.payment_method && !isShipmentCreated && <>
                            <Button id='sync_payment_status' ofStyle="noBackground" className="p-4px ms-12px rounded-4px" onClick={handleSyncPaymentStatus} disabled={sales_loading.syncOrderPaymentStatus}>
                              <RefreshCcw className={classNames({ 'sync-rotate-icon': sales_loading.syncOrderPaymentStatus })} size={16} color={sales_loading.syncOrderPaymentStatus ? 'var(--bs-primary-light)' : 'var(--bs-primary)'} />
                            </Button>
                            <UncontrolledTooltip placement="bottom" target='sync_payment_status'>
                              {t('sync')}
                            </UncontrolledTooltip>
                          </>
                          }
                        </Col>
                      </Row>
                      {shippingDetailsData.entity_data.ssc_order_details?.shipping_company && <Row>
                        <Col className='col-5'>{t('Shipping Method')}:</Col>
                        <Col className='text-secondary col-7'>
                          {`${shippingDetailsData.entity_data.ssc_order_details.shipping_company} (${t('Sales Channel')})`} 
                        </Col>
                      </Row>}
                    </div>
                  </div>
                  <div className='w-50 border rounded-4'>
                    <h5 className='py-1 px-1 fw-bolder text-secondary mb-0 d-flex justify-content-between rounded-top-4 customer-details-heading'>
                      <span className='txt-h3-md'>{t('Customer Details')}</span>
                      {!isShipmentCreated && ability.can(
                        abilityMap.order.edit_details.action,
                        abilityMap.order.edit_details.resource
                      ) && <span className='txt-h3-md cursor-pointer' onClick={() => { handleEditDetailsModal(EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key) }}>
                        <Edit height={18} width={18}/></span>}
                    </h5>
                    <div className='py-1 px-1'>
                      <Row className='mb-1'>
                        <Col className='col-5'>{t('Customer Name')}:</Col>
                        <Col className='text-secondary col-7'>{shippingDetailsData.entity_data.customer.first_name || '-'} {shippingDetailsData.entity_data.customer.last_name}</Col>
                      </Row>
                      <Row className='mb-1'>
                        <Col className='col-5'>{t('Email ID')}: </Col>
                        <Col className='text-secondary col-7'>{shippingDetailsData.entity_data.customer.email || '-'}</Col>
                      </Row>
                      <Row>
                        <Col className='col-5'>{t('Phone Number')}:</Col>
                        <Col className='text-secondary col-7'>{shippingDetailsData.entity_data.customer.mobile || '-'}</Col>
                      </Row>
                    </div>
                  </div>
                </div>
      }
    </div>
  )
}

export default OrderDetails