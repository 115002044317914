import { iCreateShipmentAddressRes } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

type iCreatedShipmentDetails = {
  shippingDetailsData: iCreateShipmentAddressRes | undefined
}

const CreatedShipmentDetails = ({ shippingDetailsData}: iCreatedShipmentDetails) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="mb-1">
        <Col className="col-3">{t('Name')}</Col>
        <Col className="text-secondary col-9">
          <span>{shippingDetailsData?.name || '-'}</span>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3">{t('Email ID')}</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData?.email || '-'}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3">{t('Address')}</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData?.address || '-'}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3"><span>{t('City')}</span></Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData?.city.name || '-'}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3"><span>{t('State/Country')}</span></Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData?.state.name || ''}{shippingDetailsData?.state.name && ', '}{shippingDetailsData?.country.name || '-'}
        </Col>
      </Row>
      {shippingDetailsData?.sales_channel_city && <Row className="mb-1">
        <Col className="col-3">{t('Sales Channel City')}</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.sales_channel_city}
        </Col>
      </Row>}
      {shippingDetailsData?.shipping_city && <Row className="mb-1">
        <Col className="col-3">{t('Shipping City')}</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.shipping_city}
        </Col>
      </Row>}
      <Row>
        <Col className="col-3">{t('Phone number')}</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData?.contact_details.number ? (`${shippingDetailsData.contact_details.country_calling_code}-${shippingDetailsData.contact_details.number}`)  : '-'}
        </Col>
      </Row>
    </>
  );
};

export default CreatedShipmentDetails;