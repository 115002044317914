import SideSheet from '@src/@core/components/ui/sideSheet';
import React from 'react';
import { iAddCustomerProps } from '../../shipping-aggregator-types';
import AddCustomerSidesheetForm from './add-customer-sidesheet-form';

const AddCustomerSidesheet: React.FC<iAddCustomerProps> = (props) => {
  const { isOpen, toggleSideSheet, editCustomerSidesheet } = props;
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <SideSheet
        modalClassName='modal-slide-in create-order-container'
        isOpen={isOpen}
        toggle={toggleSideSheet}
        size='sm'
        title={editCustomerSidesheet?.isEdit ? 'Edit Customer' : 'Add Customer'}
      >
        <AddCustomerSidesheetForm {...props} />
      </SideSheet>
    </>
  );
};

export default AddCustomerSidesheet;