import { ORDER_TYPES } from '@src/App.constants'
import Edit from '@src/assets/images/icons/Edit'
import { EDIT_MODAL_TYPE } from '@src/views/sales/sales.constant'
import { OrderDetailsRow } from '@src/views/sales/sales.utils'
import { useTranslation } from 'react-i18next'
import { Card } from 'reactstrap'

const CustomerDetailsCard = ({ customer, editButtons, handleEditDetailsModal, orderType, hasEditOrderDetailsPermission }) => {
  const { t } = useTranslation()

  const customerDetailsData = [
    {
      id: 'customer_name',
      entityName: t('Name'),
      entityValue: customer?.first_name || customer?.last_name ?`${customer.first_name || ''} ${customer.last_name || ''}`:'',
    },
    {
      id: 'phone_number',
      entityName: t('Phone No.'),
      entityValue: customer?.mobile,
    },
    {
      id: 'email_id',
      entityName: t('Email ID'),
      entityValue: customer?.email,
    }
  ]

  const handleEdit = () => {
    const modalType = orderType === ORDER_TYPES.STO.value ? EDIT_MODAL_TYPE.RECEPIENT_DETAILS.key : EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key
    handleEditDetailsModal(modalType)
  }

  const getTitle = () => {
    if (orderType === ORDER_TYPES.STO.value) {
      return t('Recepient Details')
    } else {
      return t('Customer Details')
    }
  }

  return (
    <Card className="details-card w-100 h-100 bg-white rounded-3">
      <div className="card-header d-flex justify-content-between py-12px px-16px rounded-top-2 text-secondary txt-h3-md">
        <span>{getTitle()}</span>
        {editButtons.edit_customer_details && hasEditOrderDetailsPermission && <span className="cursor-pointer" onClick={handleEdit}>
          <Edit width={20} height={20} />
        </span>}
      </div>
      <div className='d-flex flex-column p-16px gap-16px'>
        {customerDetailsData.map((row) => (
          <OrderDetailsRow
            key={row.id}
            entityNameColSpan={4}
            entityValueColSpan={8}
            entityName={row.entityName}
            entityValue={row.entityValue || '-'}
          />
        ))}
      </div>
    </Card>
  )
}

export default CustomerDetailsCard
