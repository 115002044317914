import purchase from '@src/views/Purchases/store'
import bulkShip from '@src/views/bulk-ship/store'
import catalog from '@src/views/catalog/store'
import fleet from '@src/views/fleet/store'
import revampedHubs from '@src/views/hubs/store'
import inventory from '@src/views/inventory/store/index'
import reports from '@src/views/reports/store'
import returns from '@src/views/returns/store'
import salesChannel from '@src/views/sales-channel/store'
import sales from '@src/views/sales/store/store'
import seller from '@src/views/sellers/store'
import allSettings from '@src/views/settings/store'
import shipping from '@src/views/shipping/store'
import auth from './authentication'
import layout from './layout'

const rootReducer = {
  auth,
  layout,
  revampedHubs,
  shipping,
  salesChannel,
  catalog,
  inventory,
  sales,
  purchase,
  seller,
  fleet,
  returns,
  allSettings,
  reports,
  bulkShip
}

export default rootReducer