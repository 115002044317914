import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import shipmentCancelled from '@src/assets/images/orders/orderLogs/Shipment_Cancelled.svg'
import returnToOrigin from '@src/assets/images/return-order/logs/arrivedAtHub.svg'
import markAsRefunded from '@src/assets/images/return-order/logs/markAsRefunded.svg'
import pickedUp from '@src/assets/images/return-order/logs/pickedUp.svg'
import qcInProgress from '@src/assets/images/return-order/logs/qcInProgress.svg'
import qcProcessed from '@src/assets/images/return-order/logs/qcProcessed.svg'
import refunded from '@src/assets/images/return-order/logs/refunded.svg'
import returnCancelled from '@src/assets/images/return-order/logs/returnCancelled.svg'
import returnCompleted from '@src/assets/images/return-order/logs/returnCompleted.svg'
import returnInProblem from '@src/assets/images/return-order/logs/returnInProblem.svg'
import returnOrderCreated from '@src/assets/images/return-order/logs/returnOrderCreated.svg'
import returnRequestApproved from '@src/assets/images/return-order/logs/returnRequestApproved.svg'
import returnRequestCancelled from '@src/assets/images/return-order/logs/returnRequestCancelled.svg'
import returnRequestCreated from '@src/assets/images/return-order/logs/returnRequestCreated.svg'
import shipmentCreated from '@src/assets/images/return-order/logs/shipmentCreated.svg'
import { getReturnOrderLogs } from '@src/views/returns/store'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'

const returnOrderLogsLogo = {
  'Return Request Created': returnRequestCreated,
  'Return Order Created': returnOrderCreated,
  'Shipment Created': shipmentCreated,
  'Picked Up': pickedUp,
  'Return To Origin': returnToOrigin,
  'Arrived At Hub': returnToOrigin,
  'QC Processed': qcProcessed,
  Refunded: refunded,
  'Return Completed': returnCompleted,
  'Return Cancelled': returnCancelled,
  'Return In Problem': returnInProblem,
  'Return Request Approved': returnRequestApproved,
  'Return Request Cancelled': returnRequestCancelled,
  'QC In Progress': qcInProgress,
  'Shipment Cancelled': shipmentCancelled,
  'Marked as Refunded': markAsRefunded,
}

const ReturnOrderLog = (props) => {
  const dispatch = useDispatch()
  const {returnOrderToShowDetail} = props
  const { returnOrderLogs, loading } = useSelector((store) => store.returns)
  const {t} = useTranslation()
  
  useEffect(() => {
    dispatch(getReturnOrderLogs({orderID: returnOrderToShowDetail.orderID}))
  }, [])
  return (
    <>
      {
        loading.getReturnOrderLogs
          ? <ComponentSpinner className="mt-5"/>
          : <PerfectScrollbar>
            <div className='return-order-logs-container pb-2'>
              {returnOrderLogs?.logs?.map((item, index) => <div key={index} className="d-flex flex-column">
                <div className="d-flex gap-2">
                  <div className='d-flex flex-column align-items-center gap-1' style={{ minWidth: '50px', maxWidth: '50px' }}>
                    {returnOrderLogsLogo[item?.event] 
                      ? <div className='d-flex justify-content-center align-items-center mt-1'><img src={returnOrderLogsLogo[item?.event]}/></div> 
                      : <div className='showCircle d-flex justify-content-center align-items-center mt-1'></div>
                    }                  
                    {index < returnOrderLogs.logs?.length - 1 && <div className={'styleVerticalLine'}></div>}
                  </div>
                  <div className='pt-1'>
                    <h4 className="processHeading txt-body-md">{t(item.event)}</h4>
                    <p className='orderSubHeading'>{item.event_updated_by?.name ? `${t('By')} ${item.event_updated_by?.name}` : ''}</p>
                    <p className='orderDate'>{item.event_updated_at}</p>
                    <p className=''>{item.note}</p>
                  </div>
                </div>
              </div>)}
            </div>
          </PerfectScrollbar>
      }
    </>
  )
}
  
export default ReturnOrderLog