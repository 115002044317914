// @ts-expect-error fix
import DeliveryBoxIcon from '@src/assets/images/public-tracking-page/delivery-box.svg';
import classNames from 'classnames';
import { X } from 'react-feather';
import { SHIPMENT_STATUS, SHIPMENT_STATUS_LIST } from '../public-tracking.constant';
import { iTrackingStatusLogsAndStepperProps } from '../public-tracking.types';

const TrackingStatusLogsAndStepper = ({packageLogData, windowWidth = 0}: iTrackingStatusLogsAndStepperProps) => {
  const packageIsCancelled = packageLogData.tracking_status === SHIPMENT_STATUS.CANCELLED;
  const previousStatus = packageLogData.previous_tracking_status;
  const shipmentStatusList = SHIPMENT_STATUS_LIST.filter(state => state.shipment_type === packageLogData.type);
  const currentStatus = shipmentStatusList.find(state => state.status === ((packageIsCancelled && previousStatus) ? previousStatus : packageLogData.tracking_status))?.status || SHIPMENT_STATUS_LIST[0].status;

  console.log(packageIsCancelled, previousStatus);
  const getCurrentPosition = (status: string) => {
    return shipmentStatusList.findIndex(state => state.status === status);
  };

  // const isStepActive = (stepId: string) => {
  //   return getCurrentPosition(stepId) <= getCurrentPosition(currentStatus);
  // };
  
  const getProgressWidth = () => {
    const position = getCurrentPosition(currentStatus);

    if (position === 0 )return '16%';
    if (position === shipmentStatusList.length - 1) return '100%';
    
    // Half progress for the current step
    const percentage = (position / (shipmentStatusList.length - 1)) * 100; 
    const halfStepWidth = 100 / (shipmentStatusList.length - 1) / 2;

    if (packageIsCancelled) {
      return `${percentage}%`;
    } else {
      return `${percentage + halfStepWidth}%`;
    }
  };

  return (
    <div className="tracking-status-logs-and-stepper">
      <div className="tracing-status-stepper">
        {/* Background Line */}
        <div className="stepper-background-line" />
        
        {/* Active Progress Line */}
        <div className="stepper-active-progress-line"
          style={{ width: windowWidth > 640 ? getProgressWidth() : '0', height: windowWidth < 640 ? getProgressWidth() : '4px' }}
        />

        {/* Active Progress Line Box */}
        {!packageIsCancelled &&
          <span className="stepper-active-progress-line-box flex-center-end"
            style={{ width: windowWidth > 640 ? getProgressWidth() : '0', height: windowWidth < 640 ? getProgressWidth() : '4px' }}
          >
            <img src={DeliveryBoxIcon} alt="" />
          </span>
        }
        
        {/* Steps */}
        {shipmentStatusList.map((step) => (
          <div key={step.id} className="stepper-steps">
            <div className={classNames('stepper-steps-icon', { 'bg-danger': packageIsCancelled && currentStatus === step.status })}>
              {packageIsCancelled && currentStatus === step.status ? <X  size={20} stroke='var(--bs-white)'/> : step.icon}
            </div>
            <span className={'txt-body-rg text-dark stepper-steps-label'}>
              {step.display_name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrackingStatusLogsAndStepper;