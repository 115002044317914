import { SESSION_STORAGE_KEYS } from '@src/App.constants'

export const retry = function(componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      (typeof window.sessionStorage === 'object' && window.sessionStorage.getItem('retry-lazy-refreshed')) || 'false'
    )
    // try to import the component
    componentImport().then((component) => {
      if (typeof window.sessionStorage === 'object') {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh
      } 
      resolve(component)
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        if (typeof window.sessionStorage === 'object') {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true') // we are now going to refresh
        } 
        return window.location.reload() // refresh the page
      }
      reject(error) // Default error behaviour as already tried refresh
    })
  })
}

export const reloadIfRequired = (key = SESSION_STORAGE_KEYS.RETRY_LAZY_REFRESHED, {
  timeCap = 600000
} = {}) => {
  const currentTime = new Date().getTime();
  const lastRefreshTime = JSON.parse(window.sessionStorage.getItem(key) || '0');

  if ((currentTime - lastRefreshTime) > timeCap) { 
    if (typeof window.sessionStorage === 'object') {
      window.sessionStorage.setItem(key, JSON.stringify(currentTime)); 
    }
    return window.location.reload(); 
  }
}