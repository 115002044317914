import Button from '@src/@core/components/ui/button';
import { Info, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

const FreeItemsInfoModal = ({ isOpen, handleClose, handleContinue }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} centered contentClassName="rounded-16px">
      <ModalBody className="p-24px d-flex flex-column gap-16px width-600">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center gap-16px">
            <div className="p-12px bg-primary-lighter-global rounded-8px">
              <Info height={32} width={32} color={'var(--bs-primary)'} />
            </div>
            <div className="txt-h1-sb text-dark">
              {t('Gift Item Notice')}
            </div>
          </div>
          <div>
            <X
              className="cursor-pointer"
              color="var(--bs-secondary)"
              size={24}
              onClick={handleClose}
            />
          </div>
        </div>
        <div className="d-flex flex-column gap-16px">
          <div className="text-dark">
            {t(
              'This order includes item(s) from Gifting Automation Rules, which cannot be edited or shown during edits. If the applied automation rule is no longer satisfied post-edit, the item(s) will be removed.'
            )}
          </div>
          <div className="d-flex justify-content-end mt-8px">
            <div className="d-flex gap-16px">
              <Button className="flex-center-center" onClick={handleClose} ofStyle="noBackground">
                {t('Close')}
              </Button>
              <Button
                className="flex-center-center"
                onClick={handleContinue}
              >
                {t('Continue')}
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FreeItemsInfoModal;
