import BulkUpload from '@src/@core/components/ui/bulk-upload'
import Button from '@src/@core/components/ui/button'
import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import { TENANT_TYPE } from '@src/App.constants'
import { initRealTime } from '@src/network/RealTime'
import { uploadFileOnS3 } from '@src/redux/authentication'
import SkuBulkUploadDetail from '@src/views/catalog/components/sku-bundle/bundle-bulk-upload/SkuBulkUploadDetail'
import { INVENTORY_SAMPLE_SHEETS } from '@src/views/inventory/inventory.constants'
import { UPLOAD_BULK_CUSTOMERS } from '@src/views/sales/sales.constant'
import { bulkCustomersUpload, cleanupCustomerBulkResponse, cleanupCustomerUploadedFile, getCustomerDetail, setCustomerUploadedFile } from '@src/views/sales/store/store'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'reactstrap'
import bulkCustomer from '../../../../../assets/images/svg/bulkBins.svg'
import excel from '../../../../../assets/images/svg/excel.svg'
import './uploadCustomers.scss'

const UploadBulkCustomer = ({openUploadBulkModal, setOpenUploadBulkModal}) => {
  const {t} = useTranslation()
  const fileRef = useRef(null)
  const {handleSubmit, watch, setValue, control, formState: { errors }} = useForm()
  const dispatch = useDispatch()
  const uploadedFiles = useSelector((state) => state.sales.customerBulkUploadFile)
  const bulk_upload = useSelector((state) => state.sales.uploadBulkCustomesResponse)
  const [isFileExists, setIsFileExists] = useState(null)
  const [isSellerMatched, setIsSellerMatched] = useState(false)
  const [bulkCustomerUploadId, setBulkCustomerUploadId] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const selectedSeller = watch(UPLOAD_BULK_CUSTOMERS)
  const tenantSellers = useSelector(state => state.auth.userSellers)
  const pusherLoading = useSelector(state => state.auth.pusherLoading)

  const handleCloseSideBar = () => {
    setBulkCustomerUploadId(null)
    setOpenUploadBulkModal(false)
    setUploadProgress(0)
    dispatch(cleanupCustomerUploadedFile())
    dispatch(cleanupCustomerBulkResponse())
    setIsFileUploading(false)
    setIsFileExists(null)
    setValue('seller', null)
  }

  useEffect(() => {
    setValue('seller', selectedSeller ? selectedSeller : {label: tenantSellers[0]?.name, value: tenantSellers[0]?.value})
    if (bulk_upload?.data?.data?.pusher_response?.event) {
      setOpenUploadBulkModal(false)
      dispatch(getCustomerDetail({ seller_id: selectedSeller?.value ? selectedSeller.value : tenantSellers[0].seller_id}))
      dispatch(cleanupCustomerUploadedFile())
      dispatch(cleanupCustomerBulkResponse())
      setUploadProgress(0)
      setIsFileUploading(false)
      setIsFileExists(null)
      setValue('seller', null)
    }
  }, [bulk_upload])

  const handleUploadFile = async (e) => {
    setIsFileUploading(true)
    const upload_id = await uploadFileOnS3({
      service: 'customers',
      usecase: 'customer_bulk_upload',
      file: e?.[0]
    })
    try {
      if (upload_id && e?.length) {
        setIsFileUploading(true)
        setBulkCustomerUploadId(upload_id)
        setUploadProgress(100)
        let fileType = e[0].name.split('.')
        fileType = fileType[fileType.length - 1]
        dispatch(
          setCustomerUploadedFile({
            bulk_customers: { file: e?.[0].name, upload_id, file_type: fileType }
          })
        )
        setIsFileUploading(false)
      } else if (e.target.value) {
        fileRef.current.value = ''
      }

    } catch (error) {
      setIsFileUploading(false)
    }

  }

  const handleFormSubmit = async () => {
    const body = {
      upload_id: uploadedFiles?.bulk_customers?.upload_id,
      seller_code: selectedSeller?.code ? selectedSeller.code : tenantSellers[0].code
    }
    try {
      if (uploadedFiles?.bulk_customers?.upload_id) {
        const sellerId = selectedSeller?.value ? selectedSeller.value : tenantSellers[0].seller_id
        initRealTime(() => {
          dispatch(bulkCustomersUpload({sellerId, body}))
        })
      }
    } catch (error) {
      console.log(error)
    }

  }

  return (
    <SideSheet 
      isOpen={openUploadBulkModal} 
      toggle={handleCloseSideBar} 
      modalClassName="modal-slide-in sidebar-todo-modal"
      title="Bulk Upload Customers"
      size="lg"
    >
      <Form onSubmit={handleSubmit(handleFormSubmit)} className='overflow-y-auto'>
        <div className="m-24px bulk-upload-customers-wrapper"> 
          {
            (tenantType === TENANT_TYPE.FULFILLMENT_CENTRE) && 
                   <SkuBulkUploadDetail
                     watch={watch}
                     selectedSeller={selectedSeller}
                     control={control}
                     errors={errors}
                     isSellerMatched={isSellerMatched}
                     setIsSellerMatched={setIsSellerMatched}
                     t={t}
                   />
          }
          <div className="bin-instructions mt-20px">
            <div className="p-16px text-dark-6">
              <p>{t('Instructions to upload bulk customers')}:</p>
              <div className="ms-6px">
                <p className="mt-8px">1. {t('Download the sample sheet')}.</p>
                <p className="mt-8px">2. <span>{t('Fill details as per the')}</span> <span className="text-dark">{t('given data format in the sheet only')}</span>.</p>
                <p className="mb-0">3. {t('Submit the sheet to upload customers in bulk')}.</p>
              </div>
            </div>
          </div>
          <div className="mt-24px drag-files-container border border-dark-2">
            <div className="flex-center-center">
              {
                (!uploadedFiles || !isFileExists || !bulkCustomerUploadId || isFileUploading) && 
                    <div>
                      <img src={bulkCustomer} alt="Drag your file"/>
                    </div>   
              } 
              <BulkUpload
                fileChangeHandler={handleUploadFile}
                uploadId={bulkCustomerUploadId}
                fileLogo={excel}
                setUploadId={setBulkCustomerUploadId}
                progress={uploadProgress}
                setUploadProgress={setUploadProgress}
                isFileExists={isFileExists}
                setIsFileExists={setIsFileExists}
                url={INVENTORY_SAMPLE_SHEETS.CUSTOMER_BULK_UPLOAD}
                hasBorder={false}
                isContentCenter={false}
                title="(Acceptable file formats is .csv. and maximum 5000 rows can be uploaded)."
                isFileUploading={isFileUploading}
              /> 
            </div>
          </div>
          <SidesheetFooter><Button disabled={pusherLoading || isFileUploading || !isFileExists || !bulkCustomerUploadId || !uploadedFiles || (tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && !isSellerMatched)}>{t('Upload Customers')}</Button></SidesheetFooter>
        </div>
      </Form>
    </SideSheet>
  )
}

export default UploadBulkCustomer
