/* eslint-disable @typescript-eslint/no-explicit-any */

import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner';
import FilledInfo from '@src/assets/images/icons/FilledInfo';
import { SHIPPING_ENTITY, SHIPPING_OPTION } from '@src/views/sales/constant/orders.constants';
import { iPackage } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicDetails from './basic-details';
import CreateShipmentConfig from './create-shipment-config';

type iCreateShipmentSideSheet = {
  singleOrderDetail: any
  toggle: () => void
  isLoading: boolean
  selectedPackages: iPackage[]
  cleanupOnSuccesHandler?: () => void
  refetch: () => void
}
const CreateShipmentSideSheet = ({ singleOrderDetail, toggle, isLoading, selectedPackages=[], cleanupOnSuccesHandler, refetch }: iCreateShipmentSideSheet) => {
  const { t } = useTranslation();
  const [shipmentCreated, setShipmentCreated] = useState<boolean>(false);
  const [omnifulGeneratedShipmentType, setOmnifulGeneratedShipmentType] = useState<string>(SHIPPING_OPTION.SHIPPING_PARTNER.name);
  
  return (
    <>
      {isLoading ?
        <div>
          <ComponentSpinner className={'mt-5'} />
        </div> :
        <div className='p-24px d-flex flex-column gap-24px create-shipment-sidedheet'>
          <BasicDetails
            orderDetails={singleOrderDetail}
            shipmentCreated={shipmentCreated}
            refetch={refetch}
          />
          {!shipmentCreated && <div className='d-flex align-items-center gap-12px p-8px bg-primary-lighter rounded-8px txt-sub-rg text-dark'>
            <FilledInfo width={20} height={20} color={'var(--bs-primary)'} />
            <div>{t('If recipient details are missing from the shipping address, the customer\'s name, email, and phone number will be used for delivery.')}</div>
          </div>}
          {(singleOrderDetail && !isLoading) && <CreateShipmentConfig
            selectedPackages={selectedPackages}
            orderID={singleOrderDetail.id}
            omnifulGeneratedShipmentType={omnifulGeneratedShipmentType}
            setOmnifulGeneratedShipmentType={setOmnifulGeneratedShipmentType}
            shippingDetailsData={singleOrderDetail}
            entity_name={SHIPPING_ENTITY.SHIPMENT_ORDER.value}
            toggle={toggle}
            cleanupOnSuccesHandler={cleanupOnSuccesHandler}
            setShipmentCreated={setShipmentCreated}
            shipmentCreated={shipmentCreated}
          />}
        </div>
      }
    </>
  );
};

export default CreateShipmentSideSheet;