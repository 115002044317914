/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@src/@core/components/ui/button';
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable';
import Select from '@src/@core/components/ui/select';
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner';
import { getApiUrl } from '@src/api.urls';
import { axiosInstance } from '@src/network/AxiosInstance';
import { formattedLongDateWithTime } from '@src/utility/Utils';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { getShipmentPackageLogs } from '../public-tracking-page.api';
import { SHIPMENT_TYPES } from '../public-tracking.constant';
import { iPackageActionLogs, iShipmentPackages, iTrackingStatusWithTableProps } from '../public-tracking.types';
import TrackingStatusLogsAndStepper from './tracking-status-logs-and-stepper';

const TrackingStatusWithTable = ({ shipmentPackagesData }: iTrackingStatusWithTableProps) => {
  const { t } = useTranslation();
  const defaultSelectedPackage = shipmentPackagesData?.[0];
  const [selectedPackageData, setSelectedPackageData] = useState(defaultSelectedPackage);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isViewDetails, setIsViewDetails] = useState(true);

  const { data: packageLogData, isPending } = useQuery({
    queryKey: [getShipmentPackageLogs.apiKey, selectedPackageData.id],
    queryFn: () => {
      return axiosInstance.get(getApiUrl(getShipmentPackageLogs.url, { packageId: selectedPackageData.id }));
    },
    select: ({ data }) => {
      return data.data;
    },
    enabled: !!selectedPackageData.id
  });
  
  const columns = [
    {
      id: 1,
      width: '70px',
      name: <div className="w-100 text-center">{t('S. No.')}</div>,
      key: 'srNo',
      cell: (row: iShipmentPackages, index: number) => {
        return <div data-tag="allowRowEvents" className={classNames('text-dark text-center w-100 text-capitalize cursor-pointer d-flex align-items-center justify-content-center', {'text-white': row.display_package_id === selectedPackageData?.display_package_id})}>{ index + 1}</div>;
      }
    },
    {
      id: 2,
      Width: '150px',
      name: <div className="w-100 px-4px">{t('Package ID')}</div>,
      key: 'shipment_id',
      cell: (row: iShipmentPackages) => {
        return <div data-tag="allowRowEvents" className={classNames('text-capitalize cursor-pointer px-4px w-100', {'text-white': row.display_package_id === selectedPackageData?.display_package_id})}>{ row.display_package_id}</div>;
      },
    }
  ];

  const handleSelectedPackageChange = (row: iShipmentPackages) => {
    setSelectedPackageData(row);
  };

  const selectedPackageRowStyling = [
    {
      when: (row: { display_package_id: string }) => row.display_package_id === selectedPackageData?.display_package_id,
      style: {
        backgroundColor: 'var(--bs-primary)',
      },
    }
  ];

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return (
    <div className={classNames('tracking-status-with-table', {
      'flex-center-center': selectedPackageData.shipment_type === SHIPMENT_TYPES.REVERSE_SHIPMENT,
      'align-items-center': windowWidth <= 1024
      
    })}>
      {shipmentPackagesData?.length > 1 && <>
        <div className='tracking-status-table'>
          <ExpandableCustomTable
            columns={columns}
            // @ts-expect-error fix
            data={shipmentPackagesData}
            expandableRowsComponent={false}
            showColumnsTableHeader={false}
            showPagination={false}
            useReactPaginate={false}
            onRowClicked={handleSelectedPackageChange}
            // @ts-expect-error fix
            conditionalRowStyles={selectedPackageRowStyling}
          />
        </div>
        <div className='tracking-status-dropdown'>
          { /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-expect-error */}
          <Select
            label='Select Package'
            name='select-package'
            defaultValue={{ label: defaultSelectedPackage.display_package_id, value: defaultSelectedPackage.id, data: defaultSelectedPackage }}
            options={shipmentPackagesData?.map((ele: iShipmentPackages) => { return { label: ele.display_package_id, value: ele.id, data: ele }; })}
            onChange={(e: any) => {
              setSelectedPackageData(e.data);
            }}
            className='w-full'
          />
        </div>
      </>}
      <div className='w-100 package-log-container'>
        <div className='package-log-header'>
          <div className='package-log-header-line'></div>
          <div className='text-dark'>{t('Tracking Status')}</div>
          <div className='package-log-header-line'></div>
        </div>

        {isPending ?  <div className='flex-center-center py-10px'><ComponentSpinner className=''/></div> : <>
          <div className='w-100 package-log-body'>
            <TrackingStatusLogsAndStepper
              packageLogData={packageLogData || {}}
              windowWidth={windowWidth}
            />
          </div>
          <div className={classNames('w-100 package-log-footer', { 'mt-20px': windowWidth > 640, 'border-0': !isViewDetails })}>
            {!isViewDetails &&
            <div className='package-log-tracking-info'>
              {packageLogData.action_logs?.map((ele: iPackageActionLogs, index: number) => {
                return (
                  <div key={index} className='package-log-tracking-info-item'>
                    <div className='text-dark-5 txt-sub-rg'>{ele.created_at ? formattedLongDateWithTime(ele.created_at) : '-'}</div>
                    <div className='text-dark-5 txt-sub-rg'>{ele.display_action || '-'}</div>
                    <div className='text-dark-5 txt-sub-rg'>{ele.remark || '-'}</div>
                  </div>
                );
              })}
            </div>
            }
            <Button
              ofStyle='noBackground'
              className='shadow-none gap-4px mt-24px'
              // @ts-expect-error fix
              icon={isViewDetails ? ChevronDown : ChevronUp }
              iconSize={20}
              alignIcon='right'
              onClick={() => setIsViewDetails(!isViewDetails)}
            >
              {isViewDetails ? t('View Details') : t('Hide Details')}
            </Button>
          </div>
        </>}
      </div>
    </div>
  );
};

export default TrackingStatusWithTable;