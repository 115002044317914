import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import InputField from '@src/@core/components/ui/input-field'
import MultiInputValue from '@src/@core/components/ui/multi-value-input'
import Select from '@src/@core/components/ui/select'
import { PACKAGE_SKU_TYPE, TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import deleteImage from '@src/assets/images/icons/delete-icon.svg'
import Edit from '@src/assets/images/icons/Edit'
import ability from '@src/configs/acl/ability'
import { axiosInstance } from '@src/network/AxiosInstance'
import { removeDuplicateItemsBasedOnKey } from '@src/utility/Utils'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import { Package } from 'react-feather'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { components } from 'react-select'
import {
  CATALOG_FLOATING_YES_AND_NO_OPTIONS,
  CATALOG_UNIT_OF_MEASUREMENT,
  LABELS_ADDITIONAL_OPTIONS,
  PRODUCT_CONFIGURATION,
  SIMPLE_SKU_FIELDS_NAME,
  SIMPLE_SKU_FORM_VALIDATION_SCHEMA
} from '../../catalog.constants'
import { getCasePackOrSimpleSkusAsync, getSellersAsync, getSkusLabels } from '../../store'
import AddSkuLabelModal from './AddSkuLabelModal'
import DeleteSkuTag from './DeleteSkuTag'

export default function SingleSkuDetailField({
  control,
  errors,
  register,
  Vector,
  t,
  tenantType,
  selectedSeller,
  watch,
  setValue,
  isEdit,
  singleSkuLoading,
  barcodeData,
  setBarcodeData,
  clearErrors,
  bundleCreationLoading,
  updateBundleSkusRes,
  imageGettingUpload,
  getValues,
  productConfiguration
}) {
  const [barcodeInput, setBarcodeInput] = useState('')
  const [isOpenAddSkuLabelModal, setIsOpenAddSkuLabelModal] = useState(false)
  const [isOpenDeleteSkuTagModal, setIsOpenDeleteSkuTagModal] = useState({ isOpen: false, data: null })
  const [label, setLabel] = useState('')
  const [isLabelEdit, setIsLabelEdit] = useState(false)
  const [labelId, setLabelId] = useState(null)
  const initialLabelValue = useRef(label)
  const [isLabelSuccess, setIsLabelSuccess] = useState(false)
  const [skuTagDeleted, setSkuTagDeleted] = useState(false)
  const isSellerViewAllowed = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource)
  const isLoading = bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload

  const singleSkuDetail = useSelector((state) => state.catalog.sku)

  const handleBarcodeKey = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      if (barcodeInput) {
        setBarcodeData(removeDuplicateItemsBasedOnKey([...barcodeData, { barcode: barcodeInput, isEditable: true }], 'barcode', 'asc'))
      }
      setBarcodeInput('')
    }
  }
  const handleBarcodeValue = (e) => {
    let { value } = e.target
    value = value.trim()
    setBarcodeInput(value)
  }
  const handleBarcodeRemove = (ind) => {
    setBarcodeData(barcodeData.filter((ele, index) => index !== ind))
  }
  const blurHandler = () => {
    if (barcodeData.length || barcodeInput) {
      clearErrors(SIMPLE_SKU_FIELDS_NAME.BARCODES, undefined)
    }
    if (barcodeInput) {
      setBarcodeData(removeDuplicateItemsBasedOnKey([...barcodeData, { barcode: barcodeInput, isEditable: true }], 'barcode', 'asc'))
    }
    setBarcodeInput('')

  }

  const weightConfig = productConfiguration?.length > 0 && productConfiguration?.find(config => config.configuration_type === PRODUCT_CONFIGURATION.WEIGHTED_SKU.value)
  const isWeightedConfigActive = weightConfig.is_active
  const isWeighted = weightConfig?.configuration_value?.is_weighted
  const measurementUnits = isWeighted ? Object.values(CATALOG_UNIT_OF_MEASUREMENT) : Object.values(CATALOG_UNIT_OF_MEASUREMENT).filter(item => item.value === 'ea')
  // const condtions = isWeightedConfigActive ? measurementUnits : Object.values(CATALOG_UNIT_OF_MEASUREMENT)
  // const barcodeInputValidator = () => {
  //   if (!barcodeData.length && !barcodeInput) {
  //     return 'This field is required.'
  //   } 
  //   return undefined
  // }

  const gtinValidator = () => {
    if (watch(SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED)?.value && !watch(SIMPLE_SKU_FIELDS_NAME.GTIN)) {
      return 'This field is required.'
    }
  }

  const toggleAddLabelModal = () => {
    if (isLabelEdit) {
      setLabelId(null)
      setIsLabelEdit(false)
    }
    setIsOpenAddSkuLabelModal(p => !p)
  }

  const toggleDeleteSkuTagModal = ({ data }) => {
    if (isOpenDeleteSkuTagModal.isOpen) {
      setIsOpenDeleteSkuTagModal({ isOpen: !isOpenDeleteSkuTagModal.isOpen, data: null })
    } else setIsOpenDeleteSkuTagModal({ isOpen: !isOpenDeleteSkuTagModal.isOpen, data })
  }

  const { mutate: addSkuLabel, isPending: isAddLocationAttributePending, isSuccess: isAddLocationAttributeSuccess } = useMutation({
    mutationKey: ['add-label'],
    mutationFn: ({ body }) => axiosInstance.post('/api/v1/products/labels', body),
    onSuccess: ({ data }) => {
      CustomToast('Sku tag added successfully', { my_type: 'success' })
      setValue(SIMPLE_SKU_FIELDS_NAME.LABELS, [...watch(SIMPLE_SKU_FIELDS_NAME.LABELS), { label: data.data?.name, value: data.data?.id }])
    }
  })

  const handleSkuLabelChange = (e) => {
    const filteredOptions = e.filter(option => option.tag !== LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value)
    const matchedOption = e.find(option => option.tag === LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value)

    if (matchedOption?.tag === LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value) {
      addSkuLabel({ body: { name: matchedOption.value.trim() } })
      setValue(SIMPLE_SKU_FIELDS_NAME.LABELS, filteredOptions)
    } else {
      setValue(SIMPLE_SKU_FIELDS_NAME.LABELS, e)
    }
  }

  const CustomDropdownOption = (props) => {
    const { data: { label, color, value, tag } } = props
    const { Option } = components

    const handleEdit = (event) => {
      event.stopPropagation()
      setLabel(label)
      initialLabelValue.current = label
      setIsLabelEdit(true)
      setLabelId(value)
      toggleAddLabelModal()
    }

    const handleDelete = (event) => {
      event.stopPropagation()
      toggleDeleteSkuTagModal({ isOpen: true, data: { label, value, sellerId: selectedSeller?.value } })
    }

    return (
      <Option key={value} {...props}>
        <div className="custom-option flex-center-between" title={label}>
          <div className='d-flex gap-20px align-items-center w-50'>
            <div className={classNames('cursor-pointer text-truncate', { 'rounded px-4px py-2px': color })}>
              {label}
            </div>
          </div>
          {tag !== LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value ?
            <div className="edit-icon d-flex gap-12px align-items-center">
              <span onClick={handleEdit} className='cursor-pointer'>
                <Edit title="Edit" height={16} width={16}  />
              </span>
              <img src={deleteImage} alt="delete" title='Delete' height={16} width={16} onClick={handleDelete} />
            </div> : null
          }
        </div>
      </Option>
    )
  }

  const CustomDropdownOptionForSKUCode = (props) => {
    const { data: { label, value, package_type, sku_code, weight }, isSelected } = props
    const { Option } = components

    return (
      <Option key={value} {...props}>
        <div className="flex-center-between" title={label}>
          <div className="cursor-pointer text-truncate">
            <p className='m-0 pb-2px txt-body-rg text-truncate'>{label}</p>
            <p className={classNames('m-0 txt-asst-rg text-dark-6 flex-center-start gap-4px', {
              'text-white': isSelected
            })}>
              <span>{sku_code}</span>
              {weight ? <span>•&nbsp;{weight}</span> : null}
            </p>
          </div>
          {package_type === PACKAGE_SKU_TYPE.CASE_PACK.value ? <p className='m-0 bg-info-light rounded-10px flex-center-start gap-4px px-8px'>
            <span className='text-info'>{PACKAGE_SKU_TYPE.CASE_PACK.label}</span>
            <Package size={12} color='var(--bs-info)' />
          </p> : null}
        </div>
      </Option>
    )
  }
  
  return (
    <div className='d-flex flex-column gap-22px w-100 bundle-detail-input-fields'>
      {tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed && (
        <div className='two-col-section d-flex gap-22px w-100'>
          <Controller
            name={SIMPLE_SKU_FIELDS_NAME.SELLER}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isAsync
                isRequired
                width="100%"
                label="Select Seller"
                loadOptions={getSellersAsync}
                isDisabled={isEdit || isLoading}
              />
            )}
          />


          <div className='w-100'></div>
        </div>
      )}
      {/* <div>
      </div> */}
      <div className='two-col-section d-flex flex-1 gap-22px w-100'>
        <div className='w-100'>
          <InputField
            width='100%'
            label='SKU Name'
            name={SIMPLE_SKU_FIELDS_NAME.SKU_NAME}
            isRequired
            isClearable
            errors={errors}
            disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
            {...register(
              SIMPLE_SKU_FIELDS_NAME.SKU_NAME,
              SIMPLE_SKU_FORM_VALIDATION_SCHEMA[SIMPLE_SKU_FIELDS_NAME.SKU_NAME]
            )}
          />
        </div>
        <div className='w-100'>
          <InputField
            width='100%'
            name={SIMPLE_SKU_FIELDS_NAME.SKU_CODE}
            label='SKU Code'
            isRequired
            isClearable
            register={register}
            errors={errors}
            disabled={isEdit || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
            {...register(
              SIMPLE_SKU_FIELDS_NAME.SKU_CODE,
              SIMPLE_SKU_FORM_VALIDATION_SCHEMA[SIMPLE_SKU_FIELDS_NAME.SKU_CODE]
            )}
          />
        </div>
      </div>
      <div className='two-col-section d-flex gap-22px w-100'>
        <Controller
          name={SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE}
          control={control}
          rules={{ required: 'Package Type is required' }}
          render={({ field }) => (
            <Select
              {...field}
              options={Object.values(PACKAGE_SKU_TYPE)}
              label="Package Type"
              errors={errors}
              isDisabled={isEdit}
              onChange={(selectedOption) => {
                field.onChange(selectedOption); 
                if (selectedOption?.value === PACKAGE_SKU_TYPE.UNIT.value) {
                  clearErrors(SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE_SKU);
                  clearErrors(SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE_SKU_QTY);
                }
              }}
            />
          )}
        />

        <div className='d-flex flex-column justify-content-end gap-4px w-100'>
          <Controller
            name={SIMPLE_SKU_FIELDS_NAME.LABELS}
            control={control}
            render={({ field, ref }) => {
              return (
                <Select
                  {...field}
                  ref={ref}
                  label="SKU Tags"
                  isMulti
                  isAsync
                  loadOptions={getSkusLabels}
                  isClearable
                  cacheUniqs={[selectedSeller?.value, isLabelSuccess, isAddLocationAttributeSuccess, skuTagDeleted]}
                  additional={{ sellerId: selectedSeller?.value, page: 1 }}
                  isDisabled={isAddLocationAttributePending}
                  customComponents={{ Option: CustomDropdownOption }}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    field.onChange(e)
                    handleSkuLabelChange(e)
                  }}
                  modifiedClassNames={{
                    option: (state) => {
                      return classNames('cursor-pointer txt-sub-rg font-normal select-option', {
                        'bg-primary text-white': state.isSelected,
                        'bg-primary-lighter': state.isFocused && !state.isSelected,
                        'text-primary': state.data.tag === LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value,
                        'text-dark': state.data.tag !== LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value
                      })
                    }
                  }}
                />
              )
            }}
          />
        </div>
        <AddSkuLabelModal isOpen={isOpenAddSkuLabelModal} toggle={toggleAddLabelModal} label={label} setLabel={setLabel} labelId={labelId} isEdit={isLabelEdit} isButtonDisabled={initialLabelValue.current === label} setIsLabelSuccess={setIsLabelSuccess} />
        <DeleteSkuTag isOpen={isOpenDeleteSkuTagModal.isOpen} toggle={() => toggleDeleteSkuTagModal({ isOpen: false, data: null })} data={isOpenDeleteSkuTagModal.data} setSkuTagDeleted={setSkuTagDeleted} />
      </div>
      {watch(SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE)?.value === PACKAGE_SKU_TYPE.PALLET.value || watch(SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE)?.value === PACKAGE_SKU_TYPE.CASE_PACK.value ?
        <div className='p-16px bg-primary-lighter br-8'>
          <p className='txt-body-sb'>{t(`Enter SKU code and quantity for items within the 
          ${watch(SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE)?.value === PACKAGE_SKU_TYPE.PALLET.value ? PACKAGE_SKU_TYPE.PALLET.label : PACKAGE_SKU_TYPE.CASE_PACK.label}.`)}</p>
          <div className='two-col-section d-flex gap-1 w-100'>
            <Controller
              name={SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE_SKU}
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  width="100%"
                  isRequired
                  isAsync
                  label="SKU Code or Name"
                  errors={errors}
                  loadOptions={getCasePackOrSimpleSkusAsync}
                  cacheUniqs={[getValues(SIMPLE_SKU_FIELDS_NAME.SELLER)]}
                  additional={{
                    page: 1,
                    sellerId: watch(SIMPLE_SKU_FIELDS_NAME.SELLER)?.value,
                  }}
                  isDisabled={
                    (isEdit && singleSkuLoading) || isLoading
                  }
                  customComponents={{
                    Option: CustomDropdownOptionForSKUCode, 
                  }}
                />
              )}
            />


            <InputField
              width='100%'
              isRequired
              name={SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE_SKU_QTY}
              label='Enter Quantity'
              isClearable
              register={register}
              errors={errors}
              // disabled={}
              {...register(
                SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE_SKU_QTY,
                SIMPLE_SKU_FORM_VALIDATION_SCHEMA[SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE_SKU_QTY]
              )}
            />
          </div>

        </div>
        : null}
      {watch(SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE)?.value === PACKAGE_SKU_TYPE.UNIT.value ? <div className='two-col-section d-flex flex-1 gap-22px w-100'>
        <div className='w-100'>
          <Controller
            name={SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={CATALOG_FLOATING_YES_AND_NO_OPTIONS}
                label="Is GTIN Enabled"
                isDisabled={
                  (isEdit && (singleSkuLoading || singleSkuDetail?.is_gtin_picking_enabled)) ||
                  bundleCreationLoading ||
                  updateBundleSkusRes.loading ||
                  imageGettingUpload
                }
              />
            )}
          />

        </div>
        <div className='w-100'>
          <InputField
            width='100%'
            label='GTIN'
            name={SIMPLE_SKU_FIELDS_NAME.GTIN}
            isClearable
            isRequired={watch(SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED)?.value}
            errors={errors}
            disabled={(isEdit && (singleSkuLoading || singleSkuDetail?.is_gtin_picking_enabled)) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload ||  !(watch(SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED)?.value)}
            {...register(SIMPLE_SKU_FIELDS_NAME.GTIN, {validate: gtinValidator})}
          />
        </div>
      </div> : null}
      <div className='two-col-section d-flex flex-1 gap-22px w-100'>
        {(watch(SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE)?.value === PACKAGE_SKU_TYPE.UNIT.value && isWeighted) ? <div className='w-100 d-flex flex-column gap-6px'>
          <Controller
            name={SIMPLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={isWeightedConfigActive ? measurementUnits : Object.values(CATALOG_UNIT_OF_MEASUREMENT)}
                label="Unit of Measurement"
                isDisabled={
                  (isEdit && singleSkuLoading) ||
                  bundleCreationLoading ||
                  updateBundleSkusRes.loading ||
                  imageGettingUpload
                }
              />
            )}
          />

          {isEdit ? <div className='d-flex gap-4px align-items-center'>
            <img src={Vector} />
            <div className='text-dark-5 txt-asst-rg'>
              {t('Changing the UoM will reset the total inventory of the SKU to 0.')}
            </div>
          </div> : null}
          {(isEdit || watch(SIMPLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT).label === 'EA' || watch(SIMPLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT).label === 'L' || watch(SIMPLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT).label === 'Pack') ? null : <div className='d-flex gap-4px align-items-center'>
            <img src={Vector} />
            <div className='text-dark-5 txt-asst-rg'>
              {t('The chosen unit of measurement accounts SKU to be a weighted item.')}
            </div>
          </div>}
        </div> : null}
        <div className='w-100'>
          <Controller
            name={SIMPLE_SKU_FIELDS_NAME.PERISHABLE}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={CATALOG_FLOATING_YES_AND_NO_OPTIONS}
                label="Is it perishable?"
                errors={errors}
                isDisabled={
                  (isEdit && singleSkuLoading) ||
                  bundleCreationLoading ||
                  updateBundleSkusRes.loading ||
                  imageGettingUpload
                }
              />
            )}
          />

        </div>
      </div>

      <div className='two-col-section d-flex flex-1 gap-1 w-100'>
        <div className='w-100'>
          <MultiInputValue
            {...register(SIMPLE_SKU_FIELDS_NAME.BARCODES)}
            width='100%'
            label='Barcodes'
            name={SIMPLE_SKU_FIELDS_NAME.BARCODES}
            onKeyDown={(e) => handleBarcodeKey(e)}
            onChange={(e) => handleBarcodeValue(e)}
            value={barcodeInput}
            valueArray={barcodeData}
            valueCanRemove={!isEdit}
            valueRemoveHandler={handleBarcodeRemove}
            disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
            onBlur={blurHandler}
            style={{ minWidth: '50px' }}
          />
        </div>
      </div>
      <div className='d-flex flex-column gap-6px'>
        <InputField
          width='100%'
          label='Write description here'
          className='text-area'
          type='textarea'
          name={SIMPLE_SKU_FIELDS_NAME.DESCRIPTION}
          value={watch(SIMPLE_SKU_FIELDS_NAME.DESCRIPTION)}
          disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
          errors={errors}
          maxLength='3000'
          {...register(SIMPLE_SKU_FIELDS_NAME.DESCRIPTION)}
        />
        <div className='d-flex gap-4px align-items-center'>
          <img src={Vector} />
          <div className='text-dark-5 txt-asst-rg'>
            {t('Maximum 3000 Characters')}
          </div>
        </div>
      </div>
    </div>
  )
}
