/* eslint-disable @typescript-eslint/no-explicit-any */
import trackingImage from '@src/assets/images/orders/trackingOrder.svg';
import { RowWrapper } from '@src/views/sales/shipmentOrders/shipmentOrders.utils';
import { SHIPMENT_ORDER_ENTITY } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

const ShippingDetailsCard = ({orderDetails = {}, entityType}: any) => {
  const { t } = useTranslation();
  const shippingDetails = orderDetails.destination_details;
  
  console.log({orderDetails, entityType, shippingDetails});

  const ORIGIN_DETAILS_DATA = [
    {
      id: 'customer_name',
      entityName: 'Customer Name',
      entityValue: `${shippingDetails.first_name || '-'} ${shippingDetails.last_name || ''}`,
      omit: false
    },
    {
      id: 'contact_number',
      entityName: 'Contact Number',
      entityValue: shippingDetails.phone || '-',
      omit: false
    },
    {
      id: 'email',
      entityName: 'Email ID',
      entityValue: shippingDetails.email || '-',
      omit: false
    },
    {
      id: 'address',
      entityName: 'Address',
      entityValue: `${shippingDetails.address1 || '-'} ${shippingDetails.address2 ? ', ' : ''} ${shippingDetails.address2 || ''}`,
      omit: false
    },
    {
      id: 'city',
      entityName: 'City',
      entityValue: shippingDetails.city?.name || '-',
      omit: false
    },
    {
      id: 'state_country',
      entityName: 'State & Country',
      entityValue: `${shippingDetails.state?.name || ''} ${shippingDetails.state?.name ? ', ' : ''} ${shippingDetails.country?.name || '-'}`,
      omit: false
    },
    {
      id: 'tracking_number',
      entityName: 'Tracking Number',
      entityValue: <div className='d-flex align-items-center gap-50'>
        <a href={`/tracking/${orderDetails.tracking_number}`} target='_blank' rel='noreferrer'>
          <img src={trackingImage} alt='tracking-image' />
        </a>
        <span>{orderDetails.tracking_number}</span>
      </div>,
      omit: entityType !== SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS,
    },
  ];

  return (
    <Card className="bg-white w-100 origin-details-card rounded-3">
      <div className="card-header py-12px px-16px rounded-top-12px  text-secondary txt-h3-md">
        <span>{t('Shipping Details')}</span>
      </div>
      <div className="d-flex flex-column p-16px gap-16px">
        {ORIGIN_DETAILS_DATA.filter((item) => !item.omit).map((item) => (
          <RowWrapper key={item.id} entityNameColSpan={4} entityValueColSpan={8} entityName={item.entityName} entityValue={item.entityValue || '-'} />
        ))
        }
      </div>
    </Card>
  );
};

export default ShippingDetailsCard;