/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@src/@core/components/ui/button';
import InputField from '@src/@core/components/ui/input-field';
import CheckBox from '@src/@core/components/ui/new-checkbox';
import Select from '@src/@core/components/ui/select';
import { GET_CURRENCIES } from '@src/redux/authentication';
import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside';
import { iAddShipmentPackageDetailsProps } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { CREATE_ORDER_FIELDS_NAME, ORDER_DIMENSIONS_UNIT, ORDER_WEIGHT_UNIT, PACKAGES_DEFAULT_VALUE, PAYMENT_METHOD } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { allowOnlyPositiveInteger } from '@src/views/shipping-aggregator/shipping-aggregator.utility';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Check, Info, X } from 'react-feather';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, UncontrolledTooltip } from 'reactstrap';
import AddSkuTable from '../add-sku-table/add-sku-table';
import Edit from '@src/assets/images/icons/Edit';

const AddShipmentPackageDetails = (props: iAddShipmentPackageDetailsProps) => {
  const {formState} = props;
  const { t } = useTranslation();
  const numberOfPackagesRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState('0');
  const [isNumberOfPackageChanged, setIsNumberOfPackageChanged] = useState(false);

  const { register, control, watch, formState: { errors }, trigger, setValue, clearErrors, setError } = formState;
  const { fields: packagesFields, append, remove } = useFieldArray({
    control: control,
    name: CREATE_ORDER_FIELDS_NAME.PACKAGES.value,
  });

  const toggle = (id: string) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const customHandleIsFieldRequired = (value: string | number, arrayToCompare: (string | number | undefined)[]) => {
    if (!value) {
      const isAnyFieldFilled = arrayToCompare.some((el) => !!el); // Check if any related field has a value
      return isAnyFieldFilled ? 'This field is required' : undefined;
    }
    return undefined;
  };

  const handlePackageChange = (numberOfPackages: number) => {
    const currentLength = packagesFields.length;
    if (numberOfPackages > currentLength) {
      for (let i = currentLength; i < numberOfPackages; i++) {
        append(PACKAGES_DEFAULT_VALUE);
      }
    } else if (numberOfPackages < currentLength) {
      for (let i = currentLength - 1; i >= numberOfPackages; i--) {
        remove(i);
      }
    }
    watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).forEach((_: unknown, index: number) => {
      setValue(`packages.${index}.addOrderItem`, false);
      setValue(`packages.${index}.order_items`, []);
    });
    distributeOrderAmountAndOrderWeightToPackages(numberOfPackages);
  };

  const distributeOrderAmountAndOrderWeightToPackages = (numberOfPackages:number) => {
    const packageValue = (watch(CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value) / numberOfPackages);
    const packageWeight = (watch(CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT.value) / numberOfPackages);
    const formattedPackageValue = Math.round(packageValue * 100) / 100;
    const formattedPackageWeight = Math.round(packageWeight * 100) / 100;
    watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).forEach((_: unknown, index: number) => {
      if (+packageValue) setValue(`packages.${index}.packageValue`, formattedPackageValue);
      if (+packageWeight)setValue(`packages.${index}.weight`, formattedPackageWeight);
    }
    );
  };

  const handleAllPackageWithSameDimension = (isChecked: any) => {
    if (isChecked) {
      const packageDimension:{length:string,width:string, height:string, dimensionUnit:any } = watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).find((shipmentPackage: {length:string,width:string, height:string, dimensionUnit:any }) => (shipmentPackage.length && shipmentPackage.width && shipmentPackage.height));
      if (packageDimension.length && packageDimension.width && packageDimension.height) {
        watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).forEach((_: unknown, index: number) => {
          setValue(`packages.${index}.length`, packageDimension.length);
          setValue(`packages.${index}.width`, packageDimension.width);
          setValue(`packages.${index}.height`, packageDimension.height);
          setValue(`packages.${index}.dimensionUnit`, packageDimension.dimensionUnit);
        }
        );
      }
    } else {
      watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).forEach((_: unknown, index: number) => {
        setValue(`packages.${index}.length`,'');
        setValue(`packages.${index}.width`, '');
        setValue(`packages.${index}.height`, '');
        setValue(`packages.${index}.dimensionUnit`, ORDER_DIMENSIONS_UNIT.CM);
      }
      );
    }
  };

  const handleSaveAndNext = async(index: number) => {
    const isValid = await trigger([
      `packages.${index}.weight`,
      `packages.${index}.length`,
      `packages.${index}.width`,
      `packages.${index}.height`,
      `packages.${index}.packageValue`,
    ]);
    if(isValid) setOpen(String(index + 1));
  };

  // const handleOrderAmountUpdate = () => {
  //   const allPackageTotalOrderAmount = watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).reduce((acc: number, orderPackage: any) => acc + +orderPackage.packageValue, 0);
  //   setValue(CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value, allPackageTotalOrderAmount);
  // };

  const handleOrderWeightUpdate = () => {
    const allPackageTotalOrderAmount = watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).reduce((acc: number, orderPackage: any) => acc + +orderPackage.weight, 0);
    setValue(CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT.value, allPackageTotalOrderAmount);
  };

  useOnClickOutside(numberOfPackagesRef, () => {
    if (isNumberOfPackageChanged) {
      formState.reset({
        ...formState.getValues(),
        [CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value]: packagesFields.length
      });
      setIsNumberOfPackageChanged(false);
    }
  });

  return (
    <form className='d-flex flex-column gap-16px add-shipment-package-details'>
      <div className="d-flex gap-16px">
        <div className="d-flex flex-column gap-10px w-100">
          <div className='d-flex align-items-start gap-12px' ref={numberOfPackagesRef}>
            <div className="w-100">
              <InputField
                label={CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.label}
                width="100%"
                isRequired
                {...register(CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value, {
                  required: 'This field is required',
                  validate: (value: number) => value > 0 && value <= 100 ? undefined : 'Number of packages must be between 1 and 100',
                })}
                disabled={!isNumberOfPackageChanged}
                errors={errors}
                onInput={(e: any) => {
                  e.target.value = allowOnlyPositiveInteger(e.target.value);
                }}
              />
            </div>
            {!isNumberOfPackageChanged &&
            <div className='h-100'>
              <Button className='bg-light-2 border-0 h-100 shadow-none' type='button'
                onClick={() => {
                  setIsNumberOfPackageChanged(true);
                  
                }}>
                <Edit width={18} height={18}/>
              </Button>
            </div>
            }
            {isNumberOfPackageChanged &&
              <div className='flex-center-start gap-12px' style={{height: '44px'}}>
                <Button className='bg-success-light border-0 h-100 shadow-none' type='button' onClick={() => {
                  if (!errors[CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value]?.message) {
                    handlePackageChange(watch(CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value));
                    setIsNumberOfPackageChanged(false);
                  } else return;
                }}>
                  <Check stroke='var(--bs-success)' size={18} />
                </Button>
                <Button className='bg-danger-light border-0 h-100 shadow-none' type='button' onClick={() => {
                  formState.reset({
                    ...formState.getValues(),
                    [CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value]: packagesFields.length
                  });
                  setIsNumberOfPackageChanged(false);
                }}>
                  <X stroke='var(--bs-danger)' size={16} />
                </Button>
              </div>
            }
          </div>

          {packagesFields.length > 1 && (<div className='d-flex gap-6px align-items-center'>
            <CheckBox
              type="checkbox"
              label={CREATE_ORDER_FIELDS_NAME.ALL_PACKAGES_ARE_SAME.label}
              {...register(CREATE_ORDER_FIELDS_NAME.ALL_PACKAGES_ARE_SAME.value, {
                onChange:(e:any) => handleAllPackageWithSameDimension(e.target.checked)
              })}
              checked={watch(CREATE_ORDER_FIELDS_NAME.ALL_PACKAGES_ARE_SAME.value)}
              isRequired={false}
              disabled={+watch(CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value) < 2 || (!watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value)?.some((orderPackage:any) => (orderPackage.length &&  orderPackage.width &&  orderPackage.height)))}
            />
            <span id="dimensionTooltip"><Info size={14} stroke='var(--bs-dark-6)'/></span>
            <UncontrolledTooltip target="dimensionTooltip" >
              {t('Fill dimensions for one package to enable this checkbox.')}
            </UncontrolledTooltip>
          </div>  
          )}
        </div>
      </div>

      {packagesFields.map((field, index) => {
        return (
          <Accordion key={field.id} open={open} toggle={toggle} className={classNames('package-accordion-wrapper', {'mt-10px': packagesFields.length === 1})}>
            <AccordionItem className='border-0'>
              <AccordionHeader targetId={`${index}`} className=''>{index + 1}. Package Details</AccordionHeader>
              <AccordionBody accordionId={`${index}`}>
                {/* <div className='txt-body-rg text-dark pb-16px'>{t('Package Details')}</div> */}
                <div className='d-flex flex-column gap-24px'>
                  <div className='d-flex gap-16px'>
                    <div className='w-100 d-flex gap-12px'>
                      <div className="w-100">
                        <InputField
                          label={'Weight'}
                          isRequired
                          {...register(`packages.${index}.weight`, {
                            required: 'This field is required',
                            validate: (value: number) => value > 0 ? undefined : 'Order weight must be greater than 0',
                            onChange: handleOrderWeightUpdate
                          })}
                          errors={errors}
                          onInput={(e: any) => {
                            e.target.value = allowOnlyPositiveInteger(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <Controller
                          name={CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT_UNIT.value}
                          control={control}
                          render={({ field }) => {
                            return (
                            /* @ts-expect-error fix */
                              <Select
                                {...field}
                                label={'Unit'}
                                options={Object.values(ORDER_WEIGHT_UNIT)}
                                width='110px'
                                isDisabled
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-100 d-flex gap-12px'>
                      <div className="w-100">
                        <InputField
                          label={'Due Amount'}
                          {...register(`packages.${index}.dueAmount`, {
                            // required: 'This field is required',
                            validate: (value: number) => (value <= +watch(`packages.${index}.packageValue`) || (watch(CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.value)?.value === PAYMENT_METHOD.PREPAID.value)) ? undefined : 'Order due amount must be less than or equal to package value',
                          })}
                          errors={errors}
                          onInput={(e: any) => {
                            e.target.value = allowOnlyPositiveInteger(e.target.value);
                          }}
                          disabled={watch(CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.value)?.value === PAYMENT_METHOD.PREPAID.value || !watch(CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.value)?.value}
                        />
                      </div>
                      <Controller
                        name={'currency'}
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              value={formState.watch(CREATE_ORDER_FIELDS_NAME.CURRENCY.value)}
                              label={'Currency'}
                              isAsync
                              /* @ts-expect-error fix */
                              loadOptions={GET_CURRENCIES}
                              width='110px'
                              isDisabled
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className='d-flex gap-16px'>
                    <div className='d-flex gap-12px w-100'>
                      <div className='w-50'>
                        <InputField
                          label="Length"
                          isRequired={watch(`packages.${index}.height`) || watch(`packages.${index}.width`) || watch(`packages.${index}.length`)}
                          {...register(`packages.${index}.length`, {
                            validate: (value: any) =>
                              customHandleIsFieldRequired(value, [
                                watch(`packages.${index}.height`),
                                watch(`packages.${index}.width`),
                                watch(`packages.${index}.length`),
                              ]),
                          })}
                          onInput={(e: any) => {
                            e.target.value = allowOnlyPositiveInteger(e.target.value);
                          }}
                          disabled={watch(CREATE_ORDER_FIELDS_NAME.ALL_PACKAGES_ARE_SAME.value)}
                          errors={errors}
                        />

                      </div>
                      <div className='w-50'>
                        <InputField
                          label='Width'
                          isRequired={watch(`packages.${index}.height`) || watch(`packages.${index}.width`) || watch(`packages.${index}.length`)}
                          {...register(`packages.${index}.width`, {
                            validate: (value: any) =>
                              customHandleIsFieldRequired(value, [
                                watch(`packages.${index}.height`),
                                watch(`packages.${index}.width`),
                                watch(`packages.${index}.length`),
                              ]),
                          })}
                          onInput={(e: any) => {
                            e.target.value = allowOnlyPositiveInteger(e.target.value);
                          }}
                          disabled={watch(CREATE_ORDER_FIELDS_NAME.ALL_PACKAGES_ARE_SAME.value)}
                          errors={errors}
                        />
                      </div>
                      <div className='w-50'>
                        <InputField
                          label='Height'
                          isRequired={watch(`packages.${index}.height`) || watch(`packages.${index}.width`) || watch(`packages.${index}.length`)}
                          {...register(`packages.${index}.height`, {
                            validate: (value: any) =>
                              customHandleIsFieldRequired(value, [
                                watch(`packages.${index}.height`),
                                watch(`packages.${index}.width`),
                                watch(`packages.${index}.length`),
                              ]),
                          })}
                          onInput={(e: any) => {
                            e.target.value = allowOnlyPositiveInteger(e.target.value);
                          }}
                          disabled={watch(CREATE_ORDER_FIELDS_NAME.ALL_PACKAGES_ARE_SAME.value)}
                          errors={errors}
                        />
                      </div>
                      <Controller
                        name={`packages.${index}.dimensionUnit`}
                        control={control}
                        render={({ field }) => {
                          return (
                            /* @ts-expect-error fix */
                            <Select
                              {...field}
                              label={'Unit'}
                              isDisabled={watch(CREATE_ORDER_FIELDS_NAME.ALL_PACKAGES_ARE_SAME.value)}
                              options={Object.values(ORDER_DIMENSIONS_UNIT)}
                              width='110px'
                            />
                          );
                        }}
                      />
                    </div>
                    <div className='w-100 d-flex gap-12px'>
                      <div className="w-100">
                        <InputField
                          label={'Package Value'}
                          isRequired
                          {...register(`packages.${index}.packageValue`, {
                            required: 'This field is required',
                            validate: (value: number) => value > 0 ? undefined : 'Order weight must be greater than 0',
                            onChange: () => {
                              // handleOrderAmountUpdate();
                              if (+watch(`packages.${index}.dueAmount`) < +watch(`packages.${index}.packageValue`)) { 
                                clearErrors(`packages.${index}.dueAmount`);
                              } else setError(`packages.${index}.dueAmount`, { type: 'error', message: 'Order due amount must be less than or equal to package value' });
                            }
                          })}
                          errors={errors}
                          onInput={(e: any) => {
                            e.target.value = allowOnlyPositiveInteger(e.target.value);
                          }}
                        />
                      </div>
                      <Controller
                        name={'currency'}
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              value={formState.watch(CREATE_ORDER_FIELDS_NAME.CURRENCY.value)}
                              label={'Currency'}
                              isAsync
                              /* @ts-expect-error fix */
                              loadOptions={GET_CURRENCIES}
                              width='110px'
                              isDisabled
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={classNames('pt-16px', {'pb-16px': watch(`packages.${index}.addOrderItem`)})}>
                  <CheckBox
                    type="checkbox"
                    label={'Add Order Item'}
                    {...register(`packages.${index}.addOrderItem`, {
                      onChange: () => {
                        setValue(`packages.${index}.order_items`, []);
                      }
                    })}
                    checked={watch(`packages.${index}.addOrderItem`)}
                    isRequired={false}
                  />
                </div>
                {watch(`packages.${index}.addOrderItem`) && (
                  <AddSkuTable
                    formState={formState}
                    packageIndex={index}
                    packageName={`packages.${index}.order_items`}
                    packageValue={`packages.${index}.packageValue`}
                    nameFieldSearchName={`packages.${index}.name_search`}
                    barcodeFieldSearchName={`packages.${index}.barcode_search`}
                    sellerSkuCodeFieldSearchName={`packages.${index}.seller_sku_code_search`}
                  />
                )}
                
                <div className={classNames('d-flex justify-content-end', {'pt-24px': watch(`packages.${index}.addOrderItem`)})}>
                  <Button type="button" onClick={() => handleSaveAndNext(index)}>{t('Next')}</Button>
                </div>
              
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        );
      })}
    </form>
  );
};

export default AddShipmentPackageDetails;