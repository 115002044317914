import DropdownWithTitle from '@src/@core/components/ui/dropdown';
import { DROPDOWN_TYPES } from '@src/App.constants';
import { iFilterValue } from '@src/app.types';

type iFiltersMapperProps = iFilterValue

const FiltersMapper = ({ type, filterKey, displayName, props }: iFiltersMapperProps) => {
  switch (type) {
  case DROPDOWN_TYPES.SINGLE_DROPDOWN_WITH_TITLE:
    return (
      /* @ts-expect-error fix */
      <DropdownWithTitle
        name={filterKey} 
        title={displayName}
        {...props}
      />
    );
  default:
  }
};

export default FiltersMapper;
