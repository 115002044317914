import omnifulLogoWithText from '@src/assets/images/omniful/omniful_logo_with_text.svg';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import IntlDropdown from './IntlDropdown';

const PublicNavbarHeader = () => {
  const domainData = useSelector(store => store.auth.domainData)

  return (
    <div className="public-nav-container w-100 border border-primary-lighter" id='main-nav'>
      <Link className='public-nav-logo' to='/'>
        <div className="height-38px width-150">
          {domainData && <img
            src={domainData?.tenant_info.logo || omnifulLogoWithText}
            className="object-fit-contain"
            height="100%"
            width="100%"
            alt="Logo"
          />}
        </div>
      </Link>
      <div className='d-flex w-100 h-100 align-items-center justify-content-end gap-16px'>
        <IntlDropdown />
      </div>
    </div>
  );
};

export default PublicNavbarHeader;