import BarcodeScanner from '@src/@core/components/ui/barcodeScanner'
import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import { KEYBOARD_KEYS } from '@src/App.constants'
import noMatchesFound from '@src/assets/images/svg/table/noMatchesFound.svg'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getOptionsFetcher } from '@src/utility/Utils'
import { SIMPLE } from '@src/views/catalog/catalog.constants'
import CreateSku from '@src/views/catalog/components/sku-master/CreateSku'
import { nonSerialisedBatchedSkuScan, useEnforcedFailNonSerialisedBatchedSkuScan, useEnforcedFailSerialisedBatchedSkuScan, useSerialisedBatchedSkuScan } from '@src/views/inventory/components/inventoryOperations/grn-revamped/component/grnBinDisabledApiHooks/skuScanHooks'
import GtinWarningModal from '@src/views/inventory/components/inventoryOperations/grn-revamped/component/gtinWarningModal'
import NoSkuScannedState from '@src/views/inventory/components/inventoryOperations/grn-revamped/component/NoSkuScannedState'
import BatchAndSerialisationSetting from '@src/views/inventory/components/inventoryOperations/grn-revamped/component/qc-component/batchAndSerialisationSetting'
import CreateBatchModal from '@src/views/inventory/components/inventoryOperations/grn-revamped/component/qc-component/createBatchModal'
import GRNFinalStep from '@src/views/inventory/components/inventoryOperations/grn-revamped/component/qc-component/grnFinalStep'
import SkuCard from '@src/views/inventory/components/inventoryOperations/grn-revamped/component/qc-component/skuCard'
import {
  BATCH_STATUS,
  SEARCH_SKU_TYPES,
  SERIALISATION_OPTION,
  UNDEFINED
} from '@src/views/inventory/components/inventoryOperations/grn-revamped/constant'
import { OMS_RETURN_ORDER_TYPE } from '@src/views/inventory/constants.inventory'
import { loadBatchesConfig } from '@src/views/inventory/inventory.apis'
import { GRN_TYPES } from '@src/views/inventory/inventory.constants'
import {
  clearAddItemInGrnBinResponse,
  clearBatchResponse,
  clearBinStatus,
  clearLastScannedBin,
  clearLastScannedSKUComplete,
  clearScannedSkuDetails,
  getReturnGrnSkuDetails,
  getSkuDetails,
  resetSuccessState,
  setBatchAndSerialisationConfigForSku,
  setBinDisabledGrnTableData,
  setGeneratedBarcode,
  setScannedSkuDetails,
  updateGtinSkuDetails,
  updateIsGtinChanged
} from '@src/views/inventory/store'
import { useEffect, useRef, useState } from 'react'
import { Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

const EditIcon = (
  <svg width="12" height="12" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2333 4.90008L11.1 2.76674L11.8 2.06674C11.9889 1.87785 12.225 1.78619 12.5083 1.79174C12.7917 1.7973 13.0278 1.89452 13.2167 2.08341L13.9333 2.80008C14.1222 2.98896 14.2167 3.2223 14.2167 3.50008C14.2167 3.77785 14.1222 4.01119 13.9333 4.20008L13.2333 4.90008ZM12.5333 5.60008L4.13333 14.0001H2V11.8667L10.4 3.46674L12.5333 5.60008Z" fill="currentColor" />
  </svg>
)

const QualityCheck = ({
  control,
  watch,
  setValue,
  errors,
  qty,
  setQty,
  totalItemQtyToScan,
  setTotalItemQtyToScan,
  returnGrnResponse,
  setError,
  clearErrors
}) => {
  const {t} = useTranslation()
  const qcBinBarcodeRef = useRef(null)

  const {
    selectedGlobalHubId,
    selectedSellerID,
    sku_details,
    skuDetailsLoading,
    skuNotFoundError,
    skuConfigResponse,
    createBatchResponse,
    singleReturnGrnOrderDetail,
    returnOrderDetail,
    processNextReturnOrderSuccess,
    globalSeller,
    configurations,
    tableData,
    enforcedFailReason,
    enforcedFailUploadIds,
    isShelfLifeBreached,
    isGtinChanged,
    gtinSkuDetails,
    successState
  } = useSelector(store => ({
    selectedGlobalHubId: store.auth.selectedGlobalHubId,
    selectedSellerID: store.inventory.sellerId,
    sku_details: store.inventory.grn.sku_details?.data,
    skuDetailsLoading: store.inventory.grn.sku_details_loading,
    skuNotFoundError: store.inventory.grn.no_sku_found,
    skuConfigResponse: store.inventory.skuConfigResponse,
    createBatchResponse: store.inventory.createBatchResponse,
    singleReturnGrnOrderDetail: store.returns.singleReturnGrnOrderDetail,
    returnOrderDetail: store.returns.returnOrderDetail,
    processNextReturnOrderSuccess: store.returns.success.processNextReturnOrder,
    globalSeller: store.auth.globalSeller,
    configurations: store.auth.selectedHubConfiguration,
    tableData: store.inventory.binDisabledGrn.tableData,
    enforcedFailReason: store.inventory.enforcedFailReason,
    enforcedFailUploadIds: store.inventory.enforcedFailUploadIds,
    isShelfLifeBreached: store.inventory.isShelfLifeBreached,
    isGtinChanged : store.inventory.isGtinChanged,
    gtinSkuDetails: store.inventory.gtinSkuDetails,
    successState: store.inventory.successState
  }))
  const isMaxShelfLifeEnforced = configurations?.find(item => item.configuration_type === 'acceptable_shelf_life_validation')?.configuration_values?.enabled
  const [scannedBarcode, setScannedBarcode] = useState('')
  const dispatch = useDispatch()
  const returnOrderSeller = returnOrderDetail?.seller_id && returnOrderDetail?.seller_name ? {label: returnOrderDetail.seller_name, value: returnOrderDetail.seller_id} : null
  const defaultSeller = {label: globalSeller.name, value: globalSeller.seller_id}
  const selectedSeller = returnOrderSeller || defaultSeller
  const [isSimpleSkuModalOpen, setIsSimpleSkuModalOpen] = useState(false)
  const [isSkuNotFound, setIsSkuNotFound] = useState(false)
  const [isCreateBatchModalOpen, setIsCreateBatchModalOpen] = useState(false)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const [isBarcodeScanned, setIsBarcodeScanned] = useState(false)
  const updateEnforcedSerialisedBatchedBinsabledSkuDetails = useEnforcedFailSerialisedBatchedSkuScan({sku_details, scannedBarcode, tableData, enforcedFailUploadIds, enforcedFailReason, returnGrnResponse, dispatch, selectedGlobalHubId})
  const updateSerializedBatchedBinDisabledSkuDetails = useSerialisedBatchedSkuScan({sku_details, scannedBarcode, returnGrnResponse, tableData, dispatch, selectedGlobalHubId})
  const updateNonSerialisedBatchedEnforcedMarkAsFail = useEnforcedFailNonSerialisedBatchedSkuScan({skuDetails:sku_details, tableData, enforcedFailReason, enforcedFailUploadIds, returnGrnResponse, selectedGlobalHubId, dispatch, scannedBarcode})
  const updateNonSerializedBatchedBinDisabledSkuDetails = nonSerialisedBatchedSkuScan({sku_details, returnGrnResponse, tableData, dispatch, selectedGlobalHubId, scannedBarcode})
  const [isGtinModalOpen, setIsGtinModalOpen] = useState(false)
  const skuInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
  const skuHasBeenScanned = skuInTable?.scanned_qty || skuInTable?.pass_qty || skuInTable?.fail_qty
  const [currentSkuConfig, setCurrentSkuConfig] = useState(null)
  const scanBarcodeRef = useRef(null)
  const loadBatchesOptions = getOptionsFetcher(loadBatchesConfig)

  const handleScanSkuBarcode = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (e.target.value === '') {
        CustomToast('Please enter a barcode', { my_type: 'error', audioRequired: false })
        return
      }
      const rawBarcode = watch('search_barcode')?.trim();
      let decodedBarcode;

      try {
        decodedBarcode = decodeURIComponent(rawBarcode);
      } catch (error) {
        // can put a cutom toast to show user that barcode format is invalid but need to confirm with PM
        console.log(error.message, '- Invalid Barcode Format')
        return;
      }
      setScannedBarcode(rawBarcode)

      if (isBinDisabled) {
        setIsBarcodeScanned(true)
      }
      setValue('selected_batch', null)
      setValue('omniful-sku-barcode', '')
      setValue('scanned-bin', '')
      setValue('fail_reason', '')
      if (!gtinSkuDetails.seller_sku_id) {
        dispatch(clearScannedSkuDetails())
      }
      dispatch(
        getReturnGrnSkuDetails({
          return_order_id: singleReturnGrnOrderDetail?.id,
          sku_barcode: decodedBarcode,
          gtinSkuScanned: !isBinDisabled,
          errorConfig: { audioRequired: true }
        })
      )
      dispatch(clearLastScannedSKUComplete())
    }
  }
 
  const handleClearSearchBarcode = () => {
    setValue('selected_batch', null)
    setValue('search_barcode', '')
    setValue('omniful-sku-barcode', '')
    setValue('scanned-bin', '')
    setValue('fail_reason', '')
    dispatch(clearAddItemInGrnBinResponse())
    dispatch(clearBinStatus())
    if (!gtinSkuDetails.seller_sku_id) {
      dispatch(clearScannedSkuDetails())
    }
  }

  const handleNonSerialisedBatchedSku = (batch) => {
    dispatch(setGeneratedBarcode(''))
    setValue('omniful-sku-barcode', '')

    const currentBatchSku = tableData?.find(item => item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === batch?.id)

    if (sku_details?.serialisation_status === SERIALISATION_OPTION.non_serialised.id) {
      if (currentBatchSku) {
        const failQty = currentBatchSku?.reasons?.reduce((prev, curr) => prev + curr?.quantity, 0)
        dispatch(setScannedSkuDetails({
          data : { 
            ...sku_details,
            batch,
            pass_qty: currentBatchSku?.pass_qty,
            reasons:currentBatchSku?.reasons,
            fail_qty:failQty || 0,
            scanned_qty:(currentBatchSku?.pass_qty + (failQty || 0))
          }
        }))
      } else if (!currentBatchSku) {
        dispatch(setScannedSkuDetails({
          data : { 
            ...sku_details,
            batch,
            pass_qty: 0,
            reasons:[],
            fail_qty:0,
            scanned_qty:0
          }
        }))
      }
    // updateNonSerialisedBatchedSkuFromDropdown()
    } else if (sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
      if (currentBatchSku) {
        const failQty = currentBatchSku?.reasons?.reduce((prev, curr) => prev + curr?.quantity, 0)
        dispatch(setScannedSkuDetails({
          data : { 
            ...sku_details,
            batch,
            pass_barcodes: currentBatchSku?.pass_barcodes || [],
            pass_qty: currentBatchSku?.pass_qty,
            reasons:currentBatchSku?.reasons,
            fail_qty:failQty || 0,
            fail_barcodes: currentBatchSku?.fail_barcodes || []
          }
        }))
      } else if (!currentBatchSku) {
        dispatch(setScannedSkuDetails({
          data : { 
            ...sku_details,
            batch,
            pass_barcodes:[],
            pass_qty: 0,
            reasons:[],
            fail_qty:0,
            fail_barcodes:[]
          }
        }))
      }
    }
  }
  
  const formatOptionLabel = (option) => {
    return (
      <div className="batch-custom-format-option">
        <div className="txt-sub-rg title">{option.value.batch_num}</div>
        <div className="txt-asst-rg sub-title">
          Exp: {option.value.expiry_date}
        </div>
      </div>
    )
  }

  function formatSelectedLabel({ data }) {
    return (
      <div className="react-select__single-value css-1duh8oh-singleValue">
        {data.value.batch_num}
      </div>
    )
  }

  const handleBatchSelect = (selectedBatch) => {
    if (selectedBatch) {
      clearErrors('selected_batch')
      if (isBinDisabled && sku_details?.batch_status === BATCH_STATUS.BATCHED) {
        handleNonSerialisedBatchedSku(selectedBatch)
      }
    }
  }

  const handleCreateBatchModal = () => {
    setIsCreateBatchModalOpen(pre => !pre)
  }

  useEffect(() => {
    if (skuConfigResponse.is_success) {
      const rawBarcode = watch('search_barcode')?.trim();
      let decodedBarcode;

      try {
        decodedBarcode = decodeURIComponent(rawBarcode);
      } catch (error) {
        // can put a cutom toast to show user that barcode format is invalid but need to confirm with PM
        console.log(error.message, '- Invalid Barcode Format')
        return;
      }

      dispatch(
        getSkuDetails({
          sku_barcode: decodedBarcode,
          seller_sku_code: watch('search_sku'),
          seller_id: selectedSellerID,
          hub_id: selectedGlobalHubId,
          gtinSkuScanned: !isBinDisabled,
          errorConfig: {
            audioRequired: true
          }
        })
      )
    }
  }, [skuConfigResponse])

  useEffect(() => {
    if (createBatchResponse.is_success && createBatchResponse.data) {
      const selectedBatch = {
        value: {
          batch_num: createBatchResponse.data.external_batch_id,
          expiry_date: createBatchResponse.data.expiry_date
        },
        label: createBatchResponse.data.external_batch_id,
        ...createBatchResponse.data
      }
      setValue('selected_batch', selectedBatch)
      dispatch(clearBatchResponse())
    }
  }, [createBatchResponse])

  useEffect(() => {
    if(!isBinDisabled) {
      if (sku_details) {
        setValue('search_barcode', '')
        dispatch(clearLastScannedBin())
        if (gtinSkuDetails?.seller_sku_id && !sku_details.isGoBack && !isGtinChanged) {
          scanBarcodeRef.current?.focus()
        } else {
          qcBinBarcodeRef.current?.focus()
        }
      }
    }
  }, [sku_details])

  useEffect(() => {
    setIsSkuNotFound(skuNotFoundError)
    if (skuNotFoundError) {
      setValue('search_barcode', '')
      setScannedBarcode(null)
      scanBarcodeRef.current?.focus()
    }
  }, [skuNotFoundError])

  useEffect(() => {
    if (processNextReturnOrderSuccess && isSkuNotFound) {
      setIsSkuNotFound(false)  
    }
  }, [processNextReturnOrderSuccess])

  const {mutate: updateBinDisabledSkuDetails} = useApi({
    isMutation:true,
    apiKey:'update-Bin-Disabled-Sku-Details-by-barcode-non-serialised-skus-return-grn',
    apiFn:({passQty, reasons}) => {
      const reasonsToSend = Array.isArray(reasons) && reasons?.length ? 
        reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const body = {
        pass_quantity: (parseInt(passQty) + 1),
        seller_sku_code: sku_details?.seller_sku_code,
        over_receive: false,
        return_order_id: returnGrnResponse?.return_order_detail?.id,
        reasons:reasonsToSend
      }
      return axiosInstance.post(`/api/v1/wms/hubs/${returnGrnResponse?.hub_id}/grns/${returnGrnResponse.id}`, body)
    },
    onSuccess:() => { 
      dispatch(setScannedSkuDetails({
        data: {
          ...sku_details,
          pass_qty:sku_details?.pass_qty + 1,
          scanned_qty:sku_details?.scanned_qty + 1
        }
      }))

      const updateTableData = tableData.map((item) => {
        return item.seller_sku_id === sku_details?.seller_sku_id ? { ...item, pass_qty: item.pass_qty + 1, scanned_qty: item.scanned_qty + 1 } : item
      })
      dispatch(setBinDisabledGrnTableData(updateTableData))
      CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
    }
  })

  const {mutate:getUpdatedSkuDetails} = useApi({
    isMutation:true,
    apiKey:'get-Updated-Sku-Details-by-barcode-non-serialised-skus-grn-return',
    apiFn:() => {
      return axiosInstance.get(`/api/v1/wms/hubs/${returnGrnResponse?.hub_id}/grns/${returnGrnResponse?.id}/skus/${sku_details?.seller_sku_id}`)
    },
    onSuccess:(data) => {
      // const previousSkuDetails = tableData?.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
      const previousSkuDetails = tableData.find(item => (item.batch?.id ? item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === sku_details?.batch?.id : item.seller_sku_id === sku_details?.seller_sku_id))
      if (sku_details?.sku_config?.is_batching_enable === false) {
        dispatch(setScannedSkuDetails({
          data:{
            tenant_id: sku_details?.tenant_id,
            seller_id: sku_details?.seller_id,
            seller_sku_id: sku_details?.seller_sku_id,
            seller_sku_code: sku_details?.seller_sku_code,
            images: sku_details?.images,
            barcodes: sku_details?.barcodes || [],
            name: sku_details?.name,
            weight:sku_details?.weight,
            package_type: sku_details?.package_type,
            child_sku: sku_details?.package_level_skus?.[0],
            serialisation_status: sku_details?.sku_config?.is_serialisation_enable === null ? 'undefined' : sku_details?.sku_config?.is_serialisation_enable === true ? SERIALISATION_OPTION.serialised.id : SERIALISATION_OPTION.non_serialised.id,
            batch_status: sku_details?.sku_config?.is_batching_enable === null ? 'undefined' : BATCH_STATUS.NOT_BATCHED,
            is_configuration_editable: sku_details?.sku_config?.is_configuration_editable,
            dimensions: sku_details?.dimensions,
            pass_qty: previousSkuDetails ? previousSkuDetails.pass_qty : 0,
            fail_qty: previousSkuDetails ? previousSkuDetails.fail_qty : 0,
            reasons: previousSkuDetails ? previousSkuDetails.reasons : [],
            pass_barcodes: previousSkuDetails ? previousSkuDetails.pass_barcodes : [],
            fail_barcodes: previousSkuDetails ? previousSkuDetails.fail_barcodes : [],
            grn_details:data?.data?.grn_details,
            scanned_qty: previousSkuDetails ? (previousSkuDetails.pass_qty + previousSkuDetails.fail_qty) : 0
          }}
        ))
      } else if (sku_details?.sku_config?.is_batching_enable === true) {
        dispatch(setScannedSkuDetails({
          data: {
            tenant_id: sku_details?.tenant_id,
            seller_id: sku_details?.seller_id,
            seller_sku_id: sku_details?.seller_sku_id,
            seller_sku_code: sku_details?.seller_sku_code,
            images: sku_details?.images,
            barcodes: sku_details?.barcodes || [],
            name: sku_details?.name,
            weight:sku_details?.weight,
            package_type: sku_details?.package_type,
            child_sku: sku_details?.package_level_skus?.[0],
            serialisation_status: sku_details?.sku_config?.is_serialisation_enable === null ? 'undefined' : sku_details?.sku_config?.is_serialisation_enable === true ? SERIALISATION_OPTION.serialised.id : SERIALISATION_OPTION.non_serialised.id,
            batch_status: BATCH_STATUS.BATCHED,
            is_configuration_editable: sku_details?.sku_config?.is_configuration_editable,
            dimensions: sku_details?.dimensions,
            pass_qty: sku_details?.is_gtin_enabled ? (previousSkuDetails?.pass_qty || 0) : 0,
            fail_qty: sku_details?.is_gtin_enabled ? (previousSkuDetails?.fail_qty || 0) : 0,
            pass_barcodes: sku_details?.is_gtin_enabled && sku_details?.sku_config?.is_serialisation_enable ? previousSkuDetails?.pass_barcodes : [],
            batch: sku_details?.is_gtin_enabled ? sku_details.batch : {},
            fail_barcodes: sku_details?.is_gtin_enabled && sku_details?.sku_config?.is_serialisation_enable ? previousSkuDetails?.fail_barcodes : [],
            grn_details:data?.data?.grn_details,
            scanned_qty: sku_details?.is_gtin_enabled ? ((previousSkuDetails?.pass_qty || 0) + (previousSkuDetails?.fail_qty || 0)) : 0,
            min_shelf_life: sku_details?.sku_config?.min_shelf_life,
            gtin_number: sku_details?.gtin_number,
            gtin_serialisation_number: sku_details?.gtin_serialisation_number,
            is_gtin_enabled: sku_details?.is_gtin_enabled,
            reasons:previousSkuDetails?.reasons || []
          }}
        ))
      } else {
        dispatch(setScannedSkuDetails({
          data: {
            tenant_id: sku_details?.tenant_id,
            seller_id: sku_details?.seller_id,
            seller_sku_id: sku_details?.seller_sku_id,
            seller_sku_code: sku_details?.seller_sku_code,
            images: sku_details?.images,
            barcodes: sku_details?.barcodes || [],
            name: sku_details?.name,
            weight:sku_details?.weight,
            package_type: sku_details?.package_type,
            child_sku: sku_details?.package_level_skus?.[0],
            serialisation_status: undefined,
            batch_status: undefined,
            is_configuration_editable: sku_details?.sku_config?.is_configuration_editable,
            dimensions: sku_details?.dimensions,
            pass_qty: 0,
            fail_qty: 0,
            pass_barcodes:[],
            fail_barcodes:[],
            grn_details:[],
            scanned_qty: 0
          }}
        ))
      }
      if (sku_details?.sku_config?.is_batching_enable !== null && sku_details?.sku_config?.is_serialisation_enable !== null) {
        if (!sku_details?.sku_config?.is_batching_enable && !sku_details?.sku_config?.is_serialisation_enable) {
          updateBinDisabledSkuDetails({passQty:data?.data?.grn_details?.[0]?.pass_quantity || 0, reasons:data?.data?.grn_details?.[0]?.reasons})
        }
        if (sku_details?.is_gtin_enabled && sku_details.sku_config?.is_batching_enable) {
          if (sku_details?.sku_config?.is_serialisation_enable) {
            if (isMaxShelfLifeEnforced && isShelfLifeBreached) {
              updateEnforcedSerialisedBatchedBinsabledSkuDetails()
            } else {
              updateSerializedBatchedBinDisabledSkuDetails()
            }
          } else {
            if (isMaxShelfLifeEnforced && isShelfLifeBreached) {
              updateNonSerialisedBatchedEnforcedMarkAsFail()
            } else {
              updateNonSerializedBatchedBinDisabledSkuDetails()
            }
          }
        } 
      }
    }
  })

  const handleGtinSkip = async () => {
    const rawBarcode = watch('search_barcode')?.trim();
    let decodedBarcode;

    try {
      decodedBarcode = decodeURIComponent(rawBarcode);
    } catch (error) {
      // can put a cutom toast to show user that barcode format is invalid but need to confirm with PM
      console.log(error.message, '- Invalid Barcode Format')
      return;
    }
      
    await dispatch(clearScannedSkuDetails())
    await dispatch(updateGtinSkuDetails({}))
    await dispatch(updateIsGtinChanged(false))
    await dispatch(
      getReturnGrnSkuDetails({
        return_order_id: singleReturnGrnOrderDetail?.id,
        sku_barcode: decodedBarcode,
        gtinSkuScanned: !isBinDisabled
      })
    )
    setValue('search_barcode', '')
    setScannedBarcode(null)
    scanBarcodeRef.current?.focus()
    setIsGtinModalOpen(p => !p)
  }

  const resetBatchAndSerialisationConfig = () => {
    setCurrentSkuConfig({
      batch_status: sku_details?.batch_status,
      serialisation_status: sku_details?.serialisation_status
    })
    dispatch(setBatchAndSerialisationConfigForSku({ prevState: sku_details, skuId: sku_details.seller_sku_id, batchStatus: 'undefined', serialisationStatus: 'undefined' }))
    setValue('selected_batch', null)
  }

  const handleGtinGoBack = () => {
    dispatch(setScannedSkuDetails({
      data : {
        ...gtinSkuDetails,
        isGoBack:true
      }
    }))
    setValue('search_barcode', '')
    setScannedBarcode(null)
    qcBinBarcodeRef.current?.focus()
    setIsGtinModalOpen(p => !p)
  }

  useEffect(() => {
    if (isBinDisabled) {
      if (sku_details?.seller_sku_id && isBarcodeScanned) {
        setIsBarcodeScanned(false)
        getUpdatedSkuDetails()
        setValue('search_barcode', '')
        if (!sku_details?.is_gtin_enabled && sku_details.batch_status !== BATCH_STATUS.BATCHED) {
          setScannedBarcode(null)
        }
        scanBarcodeRef.current?.focus()
      }
    }
  }, [sku_details])
  
  useEffect(() => {
    if (sku_details?.is_gtin_enabled && !sku_details.sourceType && !watch('selected_batch') && sku_details.batch?.id) {
      setValue('selected_batch', sku_details.batch)
    }
  }, [sku_details])

  useEffect(() => {
    if (successState.getReturnGrnSkuDetails && !isBinDisabled) {
      if (isGtinChanged) {
        setIsGtinModalOpen(p => !p)
      } else {
        if (gtinSkuDetails?.is_gtin_enabled && gtinSkuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          dispatch(setScannedSkuDetails({
            data : {
              ...gtinSkuDetails,
              isGoBack:false
            }
          }))
        }
      }
      dispatch(resetSuccessState())
    }
  }, [successState])

  return (
    <div className="d-flex flex-column gap-16px">
      <div className="text-dark txt-body-md">{t('Scan SKU Barcode')}</div>
      <BarcodeScanner
        ref={scanBarcodeRef}
        width="380px"
        startIcon={
          <img
            src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
            alt="QR Code"
            width="16px" 
            height="16px"
          />}
        placeholder="Scan SKU Barcode"
        control={control}
        loading={skuDetailsLoading}
        name="search_barcode"
        onKeyDown={handleScanSkuBarcode}
        hasValue={watch('search_barcode')}
        handleClear={handleClearSearchBarcode}
      />
      {isSkuNotFound 
        ? singleReturnGrnOrderDetail?.type === OMS_RETURN_ORDER_TYPE.OFF_SYSTEM_RETURN.value
          ? <div className="py-3 flex-center-center flex-column gap-20px">
            <img
              src={noMatchesFound}
              alt="No Data Image"
              width={160}
              height={160}
            />
            <div className="text-dark txt-h2-sb">{t('No SKU found with this barcode')}</div>
            <div><Button onClick={() => setIsSimpleSkuModalOpen(true)}><Plus size={16}/> Create New SKU</Button></div>
          </div>
          : null
        : singleReturnGrnOrderDetail?.type === OMS_RETURN_ORDER_TYPE.OFF_SYSTEM_RETURN.value
          ? <Button ofType="compressed" ofStyle="noBackground" className="border-0" onClick={() => setIsSimpleSkuModalOpen(true)}><Plus size={14}/> Create New SKU</Button>
          : null
      }
      {}
      {skuDetailsLoading ? (
        <div className="d-flex justify-content-between rounded-8px bg-light-1 p-16px border border-success-light">
          <div className="d-flex flex-column gap-8px w-50">
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-1"></div>
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-2"></div>
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-3"></div>
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-4"></div>
          </div>
          <div className="skeleton-effect height-50px me-40px width-50 align-self-center rounded-8px"></div>
        </div>
      ) : (
        <>
          {(gtinSkuDetails?.id || sku_details)  ?
            <SkuCard
              skuDetails={gtinSkuDetails?.id ? gtinSkuDetails : sku_details}
              selectedTabId={SEARCH_SKU_TYPES.sku_barcode.id}
              qty={totalItemQtyToScan}
              setQty={setTotalItemQtyToScan}
              watch={watch}
              remainingQty={sku_details.remaining_quantity}
              grnType={GRN_TYPES.RETURN}
              setError={setError}
              scannedBarcode={scannedBarcode}
            /> : (isBinDisabled ? <NoSkuScannedState /> : null)
          }
          {sku_details  ? (sku_details?.batch_status === UNDEFINED || sku_details?.batch_status === undefined) &&
            (sku_details?.serialisation_status === UNDEFINED || sku_details?.serialisation_status === undefined) ?
            <BatchAndSerialisationSetting skuDetails={sku_details} currentSkuConfig={currentSkuConfig} setCurrentSkuConfig={setCurrentSkuConfig}/>
            :
            <div className='flex-center-between border-left border-dark bg-light-3 p-12px rounded-8px'>
              <div className='d-flex flex-column gap-4px'>
                <div className='text-dark-6 txt-asst-md'>{t('SKU Configuration')}</div>
                <div className='d-flex align-items-center gap-8px text-dark txt-body-md'>
                  <div>{sku_details?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? t('Non-Serialised Barcode') : t('Serialised Barcode')}</div>
                  <span className='flex-center-center'>•</span>
                  <div>{sku_details?.batch_status === BATCH_STATUS.BATCHED ? t('Batched SKU') : t('Non-Batched SKU')}</div>
                </div>
              </div>
  
              {sku_details?.is_gtin_enabled && sku_details.batch_status === BATCH_STATUS.BATCHED ? null : isBinDisabled ? 
                sku_details.is_configuration_editable && !skuHasBeenScanned && <div className='txt-body-md flex-center-start gap-4px cursor-pointer' style={{ color: 'var(--bs-primary)' }} onClick={resetBatchAndSerialisationConfig}>
                  {EditIcon}
                  <span>{t('Edit')}</span>
                </div>
                : 
                sku_details?.is_gtin_enabled && sku_details.batch_status === BATCH_STATUS.BATCHED ? null : sku_details.is_configuration_editable && <div className='txt-body-md flex-center-start gap-4px cursor-pointer' style={{ color: 'var(--bs-primary)' }} onClick={resetBatchAndSerialisationConfig}>
                  {EditIcon}
                  <span>{t('Edit')}</span>
                </div>}
            </div> : null
          }
          {(sku_details?.batch_status === BATCH_STATUS.BATCHED) ?
            (!sku_details?.is_gtin_enabled && !watch('selected_batch') ?
              <div className="d-flex align-items-start gap-4px">
                <div className="flex-grow-1">
                  <FloatingDropDown
                    loadOptions={loadBatchesOptions}
                    isRequired={true}
                    title="Select Batch"
                    name="selected_batch"
                    isAsync
                    control={control}
                    classNames={{
                      option: (state) => {
                        return state.isSelected ? 'text-white' : ''
                      }
                    }}
                    formatOptionLabel={formatOptionLabel}
                    formatSelectedLabel={formatSelectedLabel}
                    cacheUniqs={[createBatchResponse.is_success]}
                    additional={{
                      sellerSkuId: sku_details.seller_sku_id
                    }}
                    errors={errors}
                    onChangeFunc={handleBatchSelect}
                  />
                </div>
                {singleReturnGrnOrderDetail?.type === OMS_RETURN_ORDER_TYPE.OFF_SYSTEM_RETURN.value && <>
                  <Button onClick={handleCreateBatchModal} ofStyle='noBackground'>{t('Create Batch')}</Button>
                  <CreateBatchModal isOpen={isCreateBatchModalOpen} toggle={handleCreateBatchModal} sku_details={sku_details} />
                </>}
              </div>
              : 
              <div className='flex-center-between border-left border-dark bg-light-3 p-12px rounded-8px'>
                <div className='flex-center-start gap-40px'>
                  <div>
                    <div className='text-dark-6 txt-asst-md'>{t('Batch Number')}</div>
                    <div className='text-dark txt-body-md pt-4px max-width-150 text-truncate' title={sku_details?.batch?.label}>{sku_details?.batch?.label || watch('selected_batch')?.value?.batch_num}</div>
                  </div>
                  <div>
                    <div className='text-dark-6 txt-asst-md'>{t('Expiry Date')}</div>
                    <div className='text-dark txt-body-md pt-4px max-width-150 text-truncate' title={sku_details?.batch?.expiry_date}>{sku_details?.batch?.expiry_date || watch('selected_batch')?.value?.expiry_date}</div>
                  </div>
                  {sku_details?.gtin_serialisation_number ? <div>
                    <div className='text-dark-6 txt-asst-md'>{t('Serial No.')}</div>
                    <div className='text-dark txt-body-md pt-4px max-width-150 text-truncate' title={sku_details.gtin_serialisation_number}>{sku_details.gtin_serialisation_number}</div>
                  </div> : null}
                </div>
                {sku_details?.is_gtin_enabled && !sku_details.sourceType && sku_details?.batch?.id ? null : <div className='txt-body-md flex-center-start gap-4px cursor-pointer' style={{ color: 'var(--bs-primary)' }} onClick={() => setValue('selected_batch', null)}>
                  {EditIcon}
                  <span>{t('Edit')}</span>
                </div>}
              </div>
            ) 
            : 
            null
          }
          {(sku_details?.batch_status === BATCH_STATUS.NOT_BATCHED  ||
            watch('selected_batch') || gtinSkuDetails?.id) && (
            <GRNFinalStep
              ref={qcBinBarcodeRef}
              skuDetails={gtinSkuDetails?.id ? gtinSkuDetails : sku_details}
              watch={watch}
              setValue={setValue}
              totalItemQtyToScan={totalItemQtyToScan}
              errors={errors}
              control={control}
              qty={qty}
              setQty={setQty}
              selectedTabId={SEARCH_SKU_TYPES.sku_barcode.id}
              grnResponse={{...returnGrnResponse, seller_id: singleReturnGrnOrderDetail?.seller_id, return_order_id: singleReturnGrnOrderDetail?.id }}
              searchBarcode={watch('search_barcode') || scannedBarcode}
            />
          )}
        </>
      )}
      <CreateSku
        selectedSeller={selectedSeller}
        isModalOpen={isSimpleSkuModalOpen}
        setIsModalOpen={setIsSimpleSkuModalOpen}
        isEdit={false}
        setIsEdit={() => {}}
        dataToEdit={[]}
        setDataTodEdit={() => {}}
        setSearchParams={() => {}}
        skuType={SIMPLE}
        filter={{}}
        setFilter={() => {}}
      />
      <GtinWarningModal
        isOpen={isGtinModalOpen}
        handleGoBack={handleGtinGoBack}
        handleSkip={handleGtinSkip}
      />
    </div>
  )
}

export default QualityCheck
