import { Delivered, InTransit, OrderPlaced, OutForDelivery, ReadyToShip, ReturnedToSeller, ReturnInProgress, ReturnPicked, ReturnRequested } from '@src/assets/images/public-tracking-page/icons';
import { iShipmentStatus } from './public-tracking.types';

export const SHIPMENT_TYPES = {
  FORWARD_SHIPMENT: 'forward',
  REVERSE_SHIPMENT: 'reverse',
};

// SHIPMENTS TRACKING
export const SHIPMENT_STATUS = {
  ORDER_PLACED: 'new',
  READY_TO_SHIP: 'ready_to_ship',
  IN_TRANSIT: 'in_transit',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  DELIVERED: 'delivered',
  CANCELLED: 'cancelled',
  RETURN_REQUESTED: 'returned',
  RETURN_PICKED: 'to_be_picked',
  RETURN_IN_PROGRESS: 'return_in_progress',
  RETURNED_TO_SELLER: 'delivered',
};

export const SHIPMENT_STATUS_LIST: iShipmentStatus[] = [
  { id: 1, status: SHIPMENT_STATUS.ORDER_PLACED, display_name: 'Order Placed', shipment_type: SHIPMENT_TYPES.FORWARD_SHIPMENT, icon: <OrderPlaced/> },
  { id: 2, status: SHIPMENT_STATUS.READY_TO_SHIP, display_name: 'Ready To Ship', shipment_type: SHIPMENT_TYPES.FORWARD_SHIPMENT, icon: <ReadyToShip/> },
  { id: 3, status: SHIPMENT_STATUS.IN_TRANSIT, display_name: 'In Transit', shipment_type: SHIPMENT_TYPES.FORWARD_SHIPMENT, icon: <InTransit/> },
  { id: 4, status: SHIPMENT_STATUS.OUT_FOR_DELIVERY, display_name: 'Out For Delivery', shipment_type: SHIPMENT_TYPES.FORWARD_SHIPMENT, icon: <OutForDelivery/> },
  { id: 5, status: SHIPMENT_STATUS.DELIVERED, display_name: 'Delivered', shipment_type: SHIPMENT_TYPES.FORWARD_SHIPMENT, icon: <Delivered/> },
  { id: 6, status: SHIPMENT_STATUS.RETURN_REQUESTED, display_name: 'Return Requested', shipment_type: SHIPMENT_TYPES.REVERSE_SHIPMENT, icon: <ReturnRequested/> },
  { id: 7, status: SHIPMENT_STATUS.RETURN_PICKED, display_name: 'Return Picked', shipment_type: SHIPMENT_TYPES.REVERSE_SHIPMENT, icon: <ReturnPicked/> },
  { id: 8, status: SHIPMENT_STATUS.RETURN_IN_PROGRESS, display_name: 'Return In Progress', shipment_type: SHIPMENT_TYPES.REVERSE_SHIPMENT, icon: <ReturnInProgress/> },
  { id: 9, status: SHIPMENT_STATUS.RETURNED_TO_SELLER, display_name: 'Returned To Seller', shipment_type: SHIPMENT_TYPES.REVERSE_SHIPMENT, icon: <ReturnedToSeller/> },
];