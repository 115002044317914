/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@src/@core/components/ui/button';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import Modal from '@src/@core/components/ui/modal';
import { getApiUrl } from '@src/api.urls';
import { TENANT_TYPE } from '@src/App.constants';
import { axiosInstance } from '@src/network/AxiosInstance';
import { bindToChannelEvent, initRealTime } from '@src/network/RealTime';
import { cleanupPusherResponse } from '@src/redux/authentication';
import { CREATE_ORDER_FIELDS_NAME, DEFAULT_VALUE_FOR_CREATE_ORDER_FIELDS, ORDER_CREATION_TYPE } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { useMutation } from '@tanstack/react-query';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bulkCreateShipmentOrder, createShipmentOrder } from '../../shipping-aggregator.apis';
import { gatPackageDetails, handleFormReset } from '../../shipping-aggregator.utility';
import BulkOrderCreation from './components/bulk-order-creation';
import SingleOrderCreation from './components/single-order-creation';

const CreateShipmentOrderForm: React.FC = () => {
  const [isOrderAmountWarningModalOpen, setIsOrderAmountWarningModalOpen] = useState(false);
  const [uploadId, setUploadId] = useState('');
  const [isFileExists, setIsFileExists] = useState(false);
  const pusherResponse = useSelector((store:any) => store.auth.pusherResponse);
  const tenantSellers = useSelector((state: { auth: { userSellers: any[] } }) => state.auth.userSellers);
  const tenantType = useSelector((state: any) => state.auth.userData.tenant.type);
  const globalSeller = useSelector((state: any) => state.auth.globalSeller);
  const hubID = useSelector((store: { auth: { selectedGlobalHubId: string } }) => store.auth.selectedGlobalHubId);
  const userHubs = useSelector((store: { auth: { userHubs: any[] } }) => store.auth.userHubs);
  const hubLevelCurrency = userHubs.find((hub) => hub.id === hubID)?.currency;
  const DEFAULT_SELLER = tenantType === TENANT_TYPE.FULFILLMENT_CENTRE ? tenantSellers?.length ? { value: tenantSellers[0].seller_id, label: tenantSellers[0].name, code: tenantSellers[0].code, ...tenantSellers[0] } : null : { value: globalSeller.seller_id, label: globalSeller.name, code: globalSeller.code, ...globalSeller };
  const DEFAULT_CURRENCY = Object.keys(hubLevelCurrency).length ? { label: hubLevelCurrency.code, value: hubLevelCurrency.code } : {};
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const formState = useForm({
    mode: 'onChange',
    defaultValues: {
      ...DEFAULT_VALUE_FOR_CREATE_ORDER_FIELDS,
      [CREATE_ORDER_FIELDS_NAME.SELLER.value]: DEFAULT_SELLER,
      [CREATE_ORDER_FIELDS_NAME.CURRENCY.value]: DEFAULT_CURRENCY,
    }
  });
  const orderCreationType: any = formState.watch(CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value);
  const allPackageTotalOrderAmount = formState.watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).reduce((acc: number, orderPackage: any) => acc + +orderPackage.packageValue, 0);

  const { mutate: createSingleShipmentOrder, isPending: isCreateSingleShipmentOrderPending } = useMutation({
    mutationKey: [createShipmentOrder.apiKey],
    mutationFn: (body: any) => {
      return axiosInstance.post(getApiUrl(createShipmentOrder.url), body);
    },
    onSuccess: () => {
      CustomToast('Shipment order created successfully', { my_type: 'success' });
      const resetValues = handleFormReset({ key: CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value, watch: formState.watch });
      formState.reset(resetValues);
      navigate('/omniship/shipment-orders');
    }
  });
  const { mutate: bulkShipmentOrder, isPending: isBulkShipmentOrderPending } = useMutation({
    mutationKey: [bulkCreateShipmentOrder.apiKey],
    mutationFn: (body: any) => {
      return axiosInstance.post(getApiUrl(bulkCreateShipmentOrder.url), body);
    },
    onSuccess: (data) => {
      // CustomToast('Shipment order created successfully', { my_type: 'success' });
      const resetValues = handleFormReset({ key: CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value, watch: formState.watch });
      formState.reset(resetValues);
      setIsFileExists(false);
      const eventName = data?.data?.data?.event;
      bindToChannelEvent(eventName);
    }
  });
  useEffect(() => {
    if (pusherResponse?.['bulk-create-order'] === 'success') {
      navigate('/omniship/shipment-orders');
      dispatch(cleanupPusherResponse());
    }
  }, [pusherResponse]);

  const handlePickupLocationFormSubmit = (data: any) => {
    console.log('pickup location data', data);
    formState.setValue('pickup_location', data);
  };

  const handleCreateCustomerForm = (data: any) => {
    console.log(data, 'customer data');
    formState.setValue('customer_details', data);
  };

  const handleFormSubmit = async(data: any) => {
    const body = {
      seller_id: data[CREATE_ORDER_FIELDS_NAME.SELLER.value]?.value,
      client_reference_id: data[CREATE_ORDER_FIELDS_NAME.REFERENCE_ORDER_ID.value],
      payment_info: {
        currency: data[CREATE_ORDER_FIELDS_NAME.CURRENCY.value]?.value,
        payment_type: data[CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.value]?.value,
        total: +data[CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value],
      },
      customer: {
        first_name: data[CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value]?.first_name,
        last_name: data[CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value]?.last_name,
        mobile_number: {
          number: data[CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value]?.phone_number,
          country_calling_code: data[CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value]?.country_calling_code,
          country_code: data[CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value]?.country_code
        },
        email: data[CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value]?.email,
      },
      receiver_address: {
        first_name: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.first_name,
        last_name: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.last_name,
        mobile_number: {
          number: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.phone_number,
          country_calling_code: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.country_calling_code,
          country_code: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.country_code
        },
        email: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.email,
        address1: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.address_line_1,
        address2: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.address_line_2,
        city: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.city?.name,
        state: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.state?.name,
        country: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.country?.name,
        zip: data[CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]?.zip_code
      },
      location_id: data[CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value]?.id,
      packages: gatPackageDetails({packages: data[CREATE_ORDER_FIELDS_NAME.PACKAGES.value], order_weight_unit: data[CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT_UNIT.value]?.value}),
    };

    if (!Object.keys(formState.formState.errors).length && Math.abs(+data[CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value] - allPackageTotalOrderAmount) > 1) {
      setIsOrderAmountWarningModalOpen(true);
      return;
    } else {
      if (typeof orderCreationType === 'object' && orderCreationType?.value === ORDER_CREATION_TYPE.SINGLE.value) {
        createSingleShipmentOrder(body);
      } else {
        initRealTime(() => {
          bulkShipmentOrder({
            upload_id: uploadId,
            seller_id: data[CREATE_ORDER_FIELDS_NAME.SELLER.value]?.value,
            location_id: data[CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value]?.id
          });
        });
      }
    }
  };

  const handleAdjustOrderValue = () => {
    const roundOffOrderAmount = Math.round(allPackageTotalOrderAmount * 100) / 100;
    formState.setValue(CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value, roundOffOrderAmount);
    CustomToast('Order amount adjusted successfully', { my_type: 'success' });
    formState.setFocus(CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value);
  };

  return (
    <div className='create-order-form-container position-relative'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          formState.handleSubmit(handleFormSubmit)(e);
        }}
      >
        {typeof orderCreationType === 'object' && orderCreationType?.value === ORDER_CREATION_TYPE.BULK.value ? (
          <BulkOrderCreation formState={formState} handlePickupLocationForm={handlePickupLocationFormSubmit} handleCreateCustomerForm={handleCreateCustomerForm} defaultCurrency={DEFAULT_CURRENCY}  setUploadId={setUploadId} isFileExists={isFileExists} setIsFileExists={setIsFileExists}/>
        ) : (
          <SingleOrderCreation formState={formState} handlePickupLocationForm={handlePickupLocationFormSubmit} handleCreateCustomerForm={handleCreateCustomerForm} defaultCurrency={DEFAULT_CURRENCY} />
        )}
        <div className='d-flex justify-content-end gap-12px mt-1'>
          { orderCreationType?.value === ORDER_CREATION_TYPE.SINGLE.value && <Button
            type='button'
            ofStyle='outlined'
            disabled={(+formState.watch(CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value) === allPackageTotalOrderAmount)}
            onClick={handleAdjustOrderValue}
          >
            {t('Adjust Order Amount')}
          </Button>}
          <Button
            disabled={isCreateSingleShipmentOrderPending || isBulkShipmentOrderPending ||( !uploadId &&  orderCreationType?.value === ORDER_CREATION_TYPE.BULK.value )}
            type='submit'
            loading={isCreateSingleShipmentOrderPending || isBulkShipmentOrderPending}
          >{t('Create Order')}</Button>
        </div>
      </form>
      { /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-expect-error */}
      <Modal
        isOpen={isOrderAmountWarningModalOpen}
        title='Confirm Shipment Order Creation'
        content={<div className='d-flex flex-column gap-10px'>
          <div className='text-dark-6'>{t('The total order amount does not match the sum of package values. Please adjust the values to match before proceeding with the order creation.')}</div>
          {/* <ul>
            <li className='txt-sub-rg'>
              <span className='txt-sub-md text-dark'>{t('Confirm')}: </span>
              <span className='text-dark-6'>{t('Proceeds with the order using the current order value.')}</span>
            </li>
            <li className='txt-sub-rg'>
              <span className='txt-sub-md text-dark'>{t('Cancel')}: </span>
              <span className='text-dark-6'>{t('Returns to the order creation screen to allow adjustments.')}</span>
            </li>
          </ul> */}
        </div>}
        hasComponentSideContentTranslation={false}
        closeModalHandler={() => setIsOrderAmountWarningModalOpen(!isOrderAmountWarningModalOpen)}
        primaryButtonText='Cancel'
        primaryButtonHandler={() => setIsOrderAmountWarningModalOpen(false)}
        secondaryButtonText='Adjust Order Amount'
        isSecondaryDisabled={isCreateSingleShipmentOrderPending}
        // primaryButtonProps={{ 
        //   disabled: isCreateSingleShipmentOrderPending,
        // }}
        // secondaryButtonProps={{ 
        //   disabled: isCreateSingleShipmentOrderPending,
        //   loading: isCreateSingleShipmentOrderPending
        // }}
        secondaryButtonHandler={() => {
          handleAdjustOrderValue();
          setIsOrderAmountWarningModalOpen(false);
        }}
      />
    </div>
  );
};

export default CreateShipmentOrderForm;