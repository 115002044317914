// ** React Imports
import { Fragment } from 'react'

// ** Routes Imports
import authenticationRoutes from './Authentication'
import modules from './modules'

// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'
import VerticalLayout from '@src/layouts/VerticalLayout'

// ** Route Components
import PrivateRoute from '@src/@core/components/ui/routes/PrivateRoute'
import PublicRoute from '@src/@core/components/ui/routes/PublicRoute'

// ** Utils
import { isObjEmpty } from '@utils'

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />
  // horizontal: <HorizontalLayout />
}

// ** Document title
const templateTitle = '%s - Omniful'

// ** Default Route
const defaultRoute = '/'

// ** Merge Routes
const routes = [
  ...modules,
  ...authenticationRoutes
]

const getRouteMeta = route => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const layoutRoutes = []

  if (routes) {
    routes.filter(route => {
      let isBlank = false

      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)
      ) {

        let RouteTag = PrivateRoute

        // ** Check for public or private route
        if (route.meta) {
          isBlank = route.meta.layout === 'blank'
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
        }

        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
              LayoutWrapper
              : Fragment

          const wrapperProps = isBlank === false ? getRouteMeta(route) : {}

          route.element = (
            <Wrapper {...wrapperProps}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        layoutRoutes.push(route)
      }
      return layoutRoutes
    })
  }
  return layoutRoutes
}

const getRoutes = layout => {
  const defaultLayout = layout || 'vertical'
  const layouts = ['vertical', 'horizontal', 'blank']

  const allRoutes = []

  layouts.forEach(layoutItem => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    allRoutes.push({
      path: '/',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return allRoutes
}

export { defaultRoute as DefaultRoute, getRoutes, routes as Routes, templateTitle as TemplateTitle }

