/* eslint-disable @typescript-eslint/no-explicit-any */
import BulkActions from '@src/@core/components/ui/bulk-actions/BulkActions';
import Button from '@src/@core/components/ui/button';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import SideSheet from '@src/@core/components/ui/sideSheet';
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer';
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import { axiosInstance } from '@src/network/AxiosInstance';
import { AbilityContext } from '@src/utility/context/Can';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { iOrderDetailsSidesheet, iPackage } from '../../shipping-aggregator-types';
import { fetchSingleShipment, fetchSingleShipmentOrder, SHIPPING_AGGREGATOR_API_KEYS } from '../../shipping-aggregator.apis';
import { ORDERS_TABS, SHIPMENT_ORDER_ENTITY } from '../../shipping-aggregator.constant';
import OrderDetails from './order-details/order-details';
import PackageInfo from './package-info/package-info';
import PackageLogs from './package-logs/package-logs';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const OrderDetailsSidesheet: React.FC<iOrderDetailsSidesheet> = (props) => {
  const { isOpen, toggleSideSheet, entity: { id, key }, defaultTabOpen, setIsCreateShipmentSideSheetOpen, setSelectedPackages } = props;
  const queryClient = useQueryClient();
  useTranslation();
  const [searchParams] = useSearchParams();
  const {t} = useTranslation();
  const ability = useContext(AbilityContext);
  const currentSelectedTab = searchParams.get('current_tab') || ORDERS_TABS.NEW_ORDERS.id;
  const TAB_LISTS = [
    { id: 'ORDER_DETAILS', name: 'Details', omit: false, component: OrderDetails },
    { id: 'PACKAGE_DETAILS', name: 'Packages', omit: false, component: PackageInfo },
    { id: 'ORDER_LOG', name: 'Logs', omit: false, component: PackageLogs }
  ];
  const [activeTab, setActiveTab] = useState(TAB_LISTS[0].id);
  const [selectedPackagesInPackageDetails, setSelectedPackagesInPackageDetails] = useState({});

  const isLMDSellerEnabled = useSelector((store: any) => store.auth.userData?.restricted_third_party_logistics);

  const { data: singleOrderDetail, isFetching: isSingleOrderDetailLoading } = useQuery({
    queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_SINGLE_ORDER_DETAIL],
    queryFn: () => {
      const params = {
        package_status: currentSelectedTab === ORDERS_TABS.ALL.id ? ORDERS_TABS.NEW_ORDERS.id : (currentSelectedTab || ORDERS_TABS.NEW_ORDERS.id)
      };
      return fetchSingleShipmentOrder({ id, params });
    },
    select: ({ data }) => {
      const responseData = data?.data;
      const { receiver_address } = responseData;
      const modifiedData = {
        ...responseData,
        destination_details: {
          ...receiver_address,
          first_name: receiver_address.first_name,
          last_name: receiver_address.last_name,
          address1: receiver_address.address1,
          address2: receiver_address.address2,
          phone: receiver_address.display_mobile_number,
          email: receiver_address.email,
          country: {
            id: receiver_address.omniful_country_id,
            name: receiver_address.omniful_country
          },
          city: {
            id: receiver_address.omniful_city_id,
            name: receiver_address.omniful_city
          },
          state: {
            id: receiver_address.omniful_state_id,
            name: receiver_address.omniful_state
          }
        }
      };
      return modifiedData;
    },
    enabled: !!(isOpen && id && key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS)
  });

  const { data: singleShipmentDetail, isFetching: isSingleShipmentDetailLoading } = useQuery({
    queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_SHIPMENTS],
    queryFn: () => {
      return fetchSingleShipment({params: { id: id }});
    },
    select: ({ data }) => {
      const responseData = data?.data;
      const { pickup_details, drop_details, shipment_details } = responseData;
      const modifiedData = {
        ...responseData,
        client_reference_id: responseData.metadata?.shipping_reference,
        payment_info: {
          display_payment_type: shipment_details.display_payment_type,
          total: shipment_details.invoice_value,
          currency: shipment_details.currency,
        },
        total_weight: shipment_details.weight,
        total_sku_count: shipment_details.total_sku_count,
        origin_details: {
          name: pickup_details.name,
          email: pickup_details.email,
          phone_number: pickup_details.phone,
          address: {
            address_line1: pickup_details.address,
            country: {
              id: pickup_details.country_id,
              name: pickup_details.country
            },
            city: {
              id: pickup_details.city_id,
              name: pickup_details.city
            },
            state: {
              id: pickup_details.state_id,
              name: pickup_details.state
            }
          }
        },
        destination_details: {
          first_name: drop_details.name,
          address1: drop_details.address,
          phone: drop_details.phone,
          email: drop_details.email,
          country: {
            id: drop_details.omniful_country_id,
            name: drop_details.omniful_country
          },
          city: {
            id: drop_details.omniful_city_id,
            name: drop_details.omniful_city
          },
          state: {
            id: drop_details.omniful_state_id,
            name: drop_details.omniful_state
          }
        }
      };
      return modifiedData;
    },
    enabled: !!(isOpen && id && key === SHIPMENT_ORDER_ENTITY.SHIPMENT_DETAILS)
  });

  const handleOnTabChange = (tab: { id: string }) => {
    setActiveTab(tab.id);
  };

  const handleOnClosed = () => {
    setActiveTab(TAB_LISTS[0].id);
  };
  const handleCreateShipmentInDetails = () => {
    if (activeTab === TAB_LISTS[0].id) {
      const selectablePackage =  singleOrderDetail?.packages.filter((singlePackage: iPackage) => singlePackage.is_package_selectable);
      if (setSelectedPackages) setSelectedPackages({
        [singleOrderDetail?.id as string]: selectablePackage
      });
      if (setIsCreateShipmentSideSheetOpen && selectablePackage.length) setIsCreateShipmentSideSheetOpen(true);
    } else {
      if (setSelectedPackages && Object.values(selectedPackagesInPackageDetails).length) {
        setSelectedPackages({
          [singleOrderDetail?.id as string]: Object.values(selectedPackagesInPackageDetails)
        });
      } else {
        CustomToast('Please Select At least One Package', { my_type: 'error', duration:2000 });
        return; 
      }
      if (setIsCreateShipmentSideSheetOpen && Object.values(selectedPackagesInPackageDetails).length) setIsCreateShipmentSideSheetOpen(true);
      setSelectedPackagesInPackageDetails({});
    }
    toggleSideSheet();
  };

  const { mutate: markShipmentOperation } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.MARK_AS_DELIVERED],
    mutationFn: async ({ shipmentId, body}: {shipmentId: string | number, body: {status: string}}) => {
      const response = await axiosInstance.put(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}`, body);
      return {...response, type: body.status};
    },
    onSuccess: ({type}: any) => {
      if (type === 'cancelled') {
        CustomToast('Shipment cancelled successfully', { my_type: 'success' });
      } else {
        CustomToast(`Mark shipment as ${type} successfully`, { my_type: 'success' });
      }
      queryClient.invalidateQueries({ queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_ORDERS] });
      queryClient.invalidateQueries({ queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_PACKAGES] });
      queryClient.invalidateQueries({ queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_SHIPMENTS] });
      toggleSideSheet();
    }
  });

  const { mutate: fetchSingleShipmentAWB } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.FETCH_SHIPMENT_AWB],
    mutationFn: ({ shipmentId}: {shipmentId: string | number}) => {
      return axiosInstance.get(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}/awb`);
    },
    onSuccess: ({ data }) => {
      window.open(data.data.awb_label, '_blank');
    }
  });

  const bulkActionButtons = {
    CREATE_SHIPMENT: {
      id: 'create-shipment',
      text: 'Create Shipment',
      onClick: handleCreateShipmentInDetails,
      omit: key === SHIPMENT_ORDER_ENTITY.SHIPMENT_DETAILS ? true : !((singleOrderDetail?.can_create_shipment && !(isLMDSellerEnabled && currentSelectedTab === ORDERS_TABS.AT_HUB.id )) && (ability.can(
        abilityMap.add_shipment_v2.create.action,
        abilityMap.add_shipment_v2.create.resource
      )))
    },
    MARK_AS_DELIVERED: {
      id: 'mark-as-delivered',
      text: 'Mark as Delivered',
      onClick: () => {
        const body = {
          status: 'delivered'
        };
        markShipmentOperation({shipmentId: key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? singleOrderDetail?.metadata?.shipment_id : singleShipmentDetail?.id, body});
      },
      omit: !((key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? singleOrderDetail?.can_mark_delivered : singleShipmentDetail?.can_mark_delivered) && (ability.can(
        abilityMap.edit_shipment_v2.edit.action,
        abilityMap.edit_shipment_v2.edit.resource
      )))
    },
    MARK_AS_SHIPPED: {
      id: 'mark-as-shipped',
      text: 'Mark as Shipped',
      onClick: () => {
        const body = {
          status: 'shipped'
        };
        markShipmentOperation({shipmentId: key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? singleOrderDetail?.metadata?.shipment_id : singleShipmentDetail?.id, body});
      },
      omit: !((key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? singleOrderDetail?.can_mark_shipped : singleShipmentDetail?.can_mark_shipped) && (ability.can(
        abilityMap.edit_shipment_v2.edit.action,
        abilityMap.edit_shipment_v2.edit.resource
      )))
    },
    CANCEL_SHIPMENT: {
      id: 'cancel-shipment',
      text: 'Cancel Shipment',
      onClick: () => {
        const body = {
          status: 'cancelled'
        };
        markShipmentOperation({shipmentId:key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? singleOrderDetail?.metadata?.shipment_id : singleShipmentDetail?.id, body});
      },
      omit:  !((key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? singleOrderDetail?.can_cancel_shipment : singleShipmentDetail?.can_cancel_shipment) && (ability.can(
        abilityMap.edit_shipment_v2.edit.action,
        abilityMap.edit_shipment_v2.edit.resource
      )))
    },
    PRINT_AWB: {
      id: 'print-awb',
      text: 'Print AWB',
      onClick: () => {
        fetchSingleShipmentAWB({shipmentId: key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? singleOrderDetail?.metadata?.shipment_id : singleShipmentDetail?.id});
      },
      omit: !((key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? singleOrderDetail?.can_print_awb : singleShipmentDetail?.can_print_awb)&& (ability.can(
        abilityMap.view_awb_shipment_v2.view_awb.action,
        abilityMap.view_awb_shipment_v2.view_awb.resource
      )))
    }
  };
  const ActiveComponent = TAB_LISTS.find(tab => tab.id === activeTab)?.component as React.FC<{orderDetails:any, entityType: string}>;
  
  const handleSidesheetOpen = () => {
    if(defaultTabOpen) setActiveTab(TAB_LISTS[defaultTabOpen].id);
  };

  const allButtonOmitTrue = Object.values(bulkActionButtons).every(
    (button) => button.omit
  );

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <SideSheet
        modalClassName='modal-slide-in order-details-sidesheet-container'
        isOpen={isOpen}
        toggle={toggleSideSheet}
        size='lg'
        onOpened={handleSidesheetOpen}
        title={key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? 'Shipment Order Details' : 'Shipment Details'}
        subTextData={[`Shipment ${key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS  ? 'Order ID' : 'AWB No.'} : ${ key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ?singleOrderDetail?.display_order_id : singleShipmentDetail?.id}`]}
        tabDataList={TAB_LISTS}
        selectedTabId={activeTab}
        customBackground={true}
        headerWithTabs={true}
        onTabChange={handleOnTabChange}
        onClosed={handleOnClosed}
      >
        {((isSingleOrderDetailLoading) || isSingleShipmentDetailLoading) ? <ComponentSpinner className={''} /> :
          <div className={classNames('mb-40px', { 'pb-40px' : activeTab !== TAB_LISTS[1].id })}>
            <ActiveComponent
              orderDetails={key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS ? singleOrderDetail : singleShipmentDetail}
              entityType={key}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              /* @ts-expect-error */
              selectedPackages = {selectedPackagesInPackageDetails}
              setSelectedPackages = {setSelectedPackagesInPackageDetails}
            />
          </div>
        }
        {activeTab === TAB_LISTS[0].id && <SidesheetFooter>
          {!allButtonOmitTrue ?
            <BulkActions color="primary" actions={Object.values(bulkActionButtons).filter((opt) => (!opt.omit)).filter((_, ind) => ind !== 0)} selectedButton={Object.values(bulkActionButtons).filter(opt => !opt.omit)[0]} /> : 
            <Button onClick={toggleSideSheet}>{t('Close')}</Button>
          }
        </SidesheetFooter>}
        {activeTab === TAB_LISTS[1].id && (currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id) && (key === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS) && <SidesheetFooter>
          <Button onClick={handleCreateShipmentInDetails}>{t('Create Shipment')}</Button>
        </SidesheetFooter>}
        {activeTab !== TAB_LISTS[0].id && (currentSelectedTab !== ORDERS_TABS.NEW_ORDERS.id) && <SidesheetFooter>
          <Button onClick={toggleSideSheet}>{t('Close')}</Button>
        </SidesheetFooter>}
        {activeTab === TAB_LISTS[2].id && (currentSelectedTab === ORDERS_TABS.NEW_ORDERS.id) && <SidesheetFooter>
          <Button onClick={toggleSideSheet}>{t('Close')}</Button>
        </SidesheetFooter>}
        {activeTab === TAB_LISTS[1].id && (key === SHIPMENT_ORDER_ENTITY.SHIPMENT_DETAILS) && <SidesheetFooter>
          <Button onClick={toggleSideSheet}>{t('Close')}</Button>
        </SidesheetFooter>}
      </SideSheet>
    </>
  );
};

export default OrderDetailsSidesheet;