import classNames from 'classnames'
import { format, parse } from 'date-fns'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Calendar as DateRangePicker } from 'react-date-range'
import { Calendar, X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import './styles.scss'
import { ErrorIcon } from '/src/assets/images/icons/ErrorIcon'

const IsRequiredIcon = (
  <svg width="5" height="5" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.98852 3.564C1.70052 3.564 1.43652 3.496 1.19652 3.36C0.964516 3.216 0.776516 3.028 0.632516 2.796C0.496516 2.556 0.428516 2.292 0.428516 2.004C0.428516 1.564 0.580516 1.192 0.884516 0.888C1.18852 0.584 1.55652 0.432 1.98852 0.432C2.43652 0.432 2.81252 0.584 3.11652 0.888C3.42052 1.192 3.57252 1.564 3.57252 2.004C3.57252 2.444 3.42052 2.816 3.11652 3.12C2.81252 3.416 2.43652 3.564 1.98852 3.564Z" fill="#C21808"></path></svg>
)

const SingleDateFilter = ({ label = '', value = { label: 'All', value: '' }, title = '', placeholder = '', error = '',  onChange = () => { }, isLabelAllVisible = false, isRequired, isClearlable: isClearable, ...rest }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [date, setDate] = useState(() => (value.value ? parse(value.value.split(' ')[0], 'dd-MM-yyyy', new Date()) : new Date()))
  const { t } = useTranslation()

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState)
  }

  const handleApplyDateFilter = selectedDate => {
    const formattedDate = format(selectedDate, 'dd-MM-yyyy')
    setDate(selectedDate)

    if (onChange) {
      onChange({ label: formattedDate, value: formattedDate })
    }
    setIsDropdownOpen(false)
  }

  const handleClearDateFilter = () => {
    if (rest.isDisabled) return
    onChange(isLabelAllVisible ? { label: 'All', value: '' } : { label: '', value: '' })
    setDate(new Date())
    setIsDropdownOpen(false)
  }


  return (
    <Dropdown Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown} className='position-relative date-filter bg-white w-100 p-0 rounded-8px'>
      <DropdownToggle disabled={rest.isDisabled} tag='span' className={classNames('toggle-btn px-16px rounded-8px d-flex align-items-center justify-content-between gap-8px bg-white', {
        'toggle-active': isDropdownOpen, 'opacity-50 cursor-default': rest.isDisabled
      })}>
        <div className="date-filter-dropdown-content d-flex align-items-center gap-8px">
          <Calendar size={18} />
          {title && <span className='title'>{t(title)}</span>}
          {(placeholder && !value.value) && <span className='text-dark-5 txt-sub-rg'>{t(placeholder)}</span>}
          {value.label && <span className="txt-sub-md text-dark"> {value.value} </span>}
          <span className='date-label'>{label || t('Activity Date')} {isRequired && <span className="required_icon"> {IsRequiredIcon} </span> }</span>
        </div>
        <span className='toggle-icon m-0'>
          {(value.value && isClearable) && (
            <div className={classNames('clear-filter', {'cursor-pointer': !rest.isDisabled})} onClick={handleClearDateFilter}>
              <X size={16} color='var(--bs-dark-5)' />
            </div>
          )}
          {/* {isDropdownOpen ? <ChevronUp size={20} color='var(--bs-dark-5)' className='m-0'/> : <ChevronDown size={20} color='var(--bs-dark-5)' className='m-0'/>} */}
        </span>
      </DropdownToggle>
      {error && <div className=' text-danger d-flex align-items-center text-start ps-1 pt-4px txt-sub-rg'> <span className="info-icon me-4px"> <ErrorIcon/> </span>{ error}</div>}
      {/* {isDropdownOpen && */}
      <DropdownMenu container={'body'} style={{zIndex: '99999'}} className='p-0'>
        <DropdownItem text className='border  zindex-10 date-with-open-dropdown' >
          <DateRangePicker
            date={date}
            onChange={handleApplyDateFilter}
            color="var(--bs-primary)"
            {...rest}
          />
        </DropdownItem>
      </DropdownMenu>
      {/* } */}
    </Dropdown>
  )
}

SingleDateFilter.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  title: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isLabelAllVisible: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default SingleDateFilter