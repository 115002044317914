/* eslint-disable @typescript-eslint/no-explicit-any */
import CustomTag from '@src/@core/components/ui/badge/CustomTag';
import { RowWrapper } from '@src/views/sales/shipmentOrders/shipmentOrders.utils';
import { SHIPMENT_ORDER_ENTITY } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

const BasicDetailsCard = ({orderDetails = {}, entityType}: any) => {
  const { t } = useTranslation();
  console.log({orderDetails, entityType});

  const BASIC_DETAILS_DATA = useMemo(() =>[
    {
      id: 'shipment_type',
      entityName: 'Shipment Type',
      entityValue: 'Forward',
      omit: entityType === SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS
    },
    {
      id: 'shipment_status',
      entityName: 'Shipment Status',
      entityValue: <>
        {/* @ts-expect-error fix */}
        <CustomTag style={{ color: 'var(--bs-primary)', backgroundColor: 'var(--bs-primary-lighter)' }} title={orderDetails.display_status} />
      </>,
      omit: false
    },
    {
      id: 'handling_type',
      entityName: 'Handling Type',
      entityValue: <>Closed</>,
      omit: true
    },
    {
      id: 'product_type',
      entityName: 'Product Type',
      entityValue: <>Goods</>,
      omit: true
    },
    {
      id: 'reference_id',
      entityName: 'Reference ID',
      entityValue: orderDetails.client_reference_id,
      omit: entityType !== SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS,
    },
    {
      id: 'no_of_sku',
      entityName: 'No. of SKU',
      //todo
      entityValue: orderDetails.total_sku_count,
      omit: false
    },
    {
      id: 'seller',
      entityName: 'Seller',
      entityValue: orderDetails.seller_name,
      omit: false
    },
    {
      id: 'service_type',
      entityName: 'Service Type',
      entityValue: <>Normal</>,
      omit: true
    },
    {
      id: 'payment_method',
      entityName: 'Payment Method',
      entityValue: orderDetails.payment_info?.display_payment_type,
      omit: false
    },
    {
      id: 'no_of_packages',
      entityName: 'No. of packages',
      entityValue: orderDetails.packages?.length,
      omit: false
    },
    {
      id: 'amount',
      entityName: 'Amount',
      entityValue: orderDetails.payment_info?.total ? `${orderDetails.payment_info.total} ${orderDetails.payment_info.currency}` : '-',
      omit: false
    },
    {
      id: 'total_weight',
      entityName: 'Total weight',
      //todo
      entityValue: orderDetails.total_weight?.value ? `${orderDetails.total_weight.value} ${orderDetails.total_weight.uom}` : '-',
      omit: false
    },
    {
      id: 'tags',
      entityName: 'Tags',
      entityValue: <div className='txt-sub-rg d-flex flex-wrap w-100 gap-4px text-dark col-8'>{
        orderDetails.tags?.length > 0 ? orderDetails.tags.map((tag:any, index: number) => {
          return (
          /* @ts-expect-error fix */
            <CustomTag key={index} style={{ color: tag.color, backgroundColor: `${tag.color}1A` }} title={tag.name} />
          );
        }) : <h6 style={{ fontWeight: 400 }}>{'-'}</h6>
      }</div>,
      omit: entityType !== SHIPMENT_ORDER_ENTITY.SHIPMENT_ORDER_DETAILS,
    }
  ], []);
  
  return (
    <Card className="bg-white w-100 basic-details-card rounded-3">
      <div className="card-header py-12px px-16px rounded-top-12px  text-secondary txt-h3-md">
        <span>{t('Basic Details')}</span>
      </div>
      <div className="basic-details-row-wrapper">
        {BASIC_DETAILS_DATA.filter((item) => !item.omit).map((item) => (
          <RowWrapper key={item.id} entityNameColSpan={4} entityValueColSpan={8} entityName={item.entityName} entityValue={item.entityValue || '-'} />
        ))
        }
      </div>
    </Card>
  );
};

export default BasicDetailsCard;