// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BANNER_TYPES, BANNER_VARIANTS, LOCAL_STORAGE_KEYS } from '@src/App.constants';
import useBannerVisibility from '@src/utility/hooks/useBannerVisiblity';
import classNames from 'classnames';
import React from 'react';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Icons from './Icons';

interface iBanner {
  type?: keyof typeof BANNER_TYPES;
  variant: keyof typeof BANNER_VARIANTS;
  text?: string;
  actionText?: string;
  action?: () => void;
  bannerKey: string;
  className?: string;
  children?: React.ReactNode;
}

const backgroundClassMap = {
  DANGER: 'bg-danger-light',
  SUCCESS: 'bg-success-light',
  WARNING: 'bg-warning-light',
  INFO: 'bg-primary-lighter',
  NEUTRAL: 'bg-purple-light',
};

const Banner = ({
  type = BANNER_TYPES.NOTIFICATION as keyof typeof BANNER_TYPES,
  text,
  actionText,
  variant,
  action,
  bannerKey,
  className,
  children,
}: iBanner) => {
  const { t } = useTranslation();
  const [isBannerVisible, setIsBannerVisible] = useBannerVisibility(bannerKey);
  console.log('isBannerVisible', isBannerVisible);
  const hasChildren = React.Children.count(children) > 0;

  const hideBanner = () => {
    const storedBannerData = localStorage.getItem(LOCAL_STORAGE_KEYS.BANNER_KEY);
    const localStorageBannerData = storedBannerData && JSON.parse(storedBannerData) || {};
    localStorage.setItem(LOCAL_STORAGE_KEYS.BANNER_KEY, JSON.stringify({ ...localStorageBannerData, [bannerKey]: false }));
    if (typeof setIsBannerVisible === 'function') {
      setIsBannerVisible(false);
    }
  };

  return isBannerVisible ? (
    <div
      className={classNames(`text-dark txt-sub-rg banner ${className}`, backgroundClassMap[variant], {
        'flex-center-start rounded-8px': type === BANNER_TYPES.NOTIFICATION,
        'flex-center-center': type === BANNER_TYPES.BANNER,
        'flex-center-between': hasChildren,
      })}
    >
      <div
        className={classNames('flex-center-between gap-12px p-8px', {
          'w-100': type === BANNER_TYPES.NOTIFICATION,
          'd-flex p-12px': hasChildren,
        })}
      >
        <div
          className={classNames('d-flex gap-10px', {
            'flex-center-center': !hasChildren,
          })}
        >
          {/* Icon */}
          <span className='mt-2px'><Icons variant={variant} size={18} /></span>

          {/* Content */}
          <div
            className={classNames('d-flex gap-8px align-items-center', {
              'gap-0': hasChildren,
            })}
          >
            {hasChildren ? (
              <div className="d-flex flex-column">{children}</div>
            ) : (
              <>
                <div>{text}</div>
                {actionText && (
                  <div
                    className="txt-sub-md text-primary cursor-pointer"
                    onClick={() => {
                      if (action) action();
                    }}
                  >
                    {t(actionText)}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Cross Button */}
      {type === BANNER_TYPES.BANNER && (
        <div
          className={classNames('cursor-pointer d-flex align-items-center text-center ms-10px', {
            'm-34px': hasChildren,
          })}
          onClick={hideBanner}
        >
          <X size={16} />
        </div>
      )}
    </div>
  ) : null;
};

export default Banner;
