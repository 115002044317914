export const SHIPMENT_ORDERS_STATUS = {
  NEW_ORDERS:{ key: 'new_orders', name: 'New Orders', status: 'New Order'},
  READY_TO_SHIP:{ key: 'ready_to_ship', name: 'Ready To Ship' },
  DISPATCHED:{ key: 'dispatched', name: 'Dispatched' },
  IN_TRANSIT:{ key: 'in_transit', name: 'In Transit' },
  RETURN_IN_PROGRESS :{ key: 'return_in_progress', name: 'Return In Progress' },
  OUT_FOR_DELIVERY:{ key: 'out_for_delivery', name: 'Out For Delivery' },
  RETURN_TO_ORIGIN :{ key: 'return_to_origin', name: 'Return To Origin' },
  DELIVERED:{ key: 'delivered', name: 'Delivered' },
  CANCELLED:{ key: 'cancelled', name: 'Cancelled' },
  ALL:{ key: 'all', name: 'All' }
}

export const ALL_SHIPMENT_ORDER_STATUS_OPTION = [
  { label: 'Delivered', value: 'delivered' },
  { label: 'Return In Progress', value: 'return_in_progress' },
  { label: 'Return To Origin', value: 'return_to_origin' },
  { label: 'New Order', value: 'new_order' },
  { label: 'Ready To Ship', value: 'ready_to_ship' },
  { label: 'Dispatched', value: 'dispatched' },
  { label: 'In Transit', value: 'in_transit' },
  { label: 'Out For Delivery', value: 'out_for_delivery' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Lost', value: 'lost' },
  { label: 'Unable To Deliver', value: 'unable_to_deliver' }
]

export const SHIPMENT_ORDER_SHEET_FORMAT = {
  CSV: {
    format: '.csv',
    sampleFile: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/orders/shipment-orders/samples/shipment_orders.csv'
  }
}

export const SHIPMENT_ORDER_TYPE = {
  B2B: {name: 'B2B', value:'b2b'},
  B2C: {name: 'B2C', value:'b2c'}
}

export const PAYMENT_STATUS_OPTIONS = [
  {
    label: 'Prepaid',
    value: 'prepaid'
  },
  {
    label: 'Postpaid',
    value: 'postpaid'
  }
]

export const ORDER_CREATION_OPTIONS = [
  { label: 'Bulk', value: 'bulk' },
  { label: 'Single', value: 'single' }
]

export const EDIT_PRICE_SECTION = {
  TOTAL:{key:'total'},
  TOTAL_DUE:{key:'total_due'}
}

export const EDIT_SHIPMENT_ORDER_ENTITY = {
  ITEMS_DETAILS_EDIT:'shipment_order_item_details',
  TOTAL_EDIT:'shipment_order_total',
  TOTAL_DUE_EDIT:'shipment_order_total_due'
}

export const PAYMENT_METHOD = {
  PREPAID:{
    name: 'Prepaid',
    value: 'prepaid'
  },
  POSTPAID:{
    name: 'Postpaid',
    value: 'postpaid'
  }
}

export const SHIPMENT_ORDER_TYPE_OPTIONS = {
  FORWARD: { key: 'forward_shipment_order', display_key: 'Forward' },
  RETURN: { key: 'return_shipment_order', display_key: 'Reverse' }
}