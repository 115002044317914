import { captureException } from '@sentry/react'
import Button from '@src/@core/components/ui/button'
import { Error500 } from '@src/assets/data/assets'
import useThemeSetup from '@src/utility/hooks/useThemeSetup'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import './styles.scss'

export const FallBack = () => {
  const { t } = useTranslation()

  const domainData = useSelector(store => store.auth.domainData)
  const userData = useSelector(state => state.auth.userData)

  useThemeSetup(userData || domainData)
  const handleRetry = () => {
    window.location.reload()
  }
  return (
    <div className="home-page-on-boot-failed flex-center-center flex-column">
      <div className="fatal-img">
        <Error500 primary='var(--bs-primary)' width={250} height={250}/>
      </div>
      <div className="main-heading mb-1 ">{t('Oops! Something went wrong.')}</div>
      <div className="ms-0 my-1 text-dark">{t('Please refresh the page or try again later.')}</div>
      <div className="mt-1">
        <Button onClick={handleRetry}>
          {t('Retry')}
        </Button>
      </div>
    </div>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }
  
  componentDidCatch(error, info) {
    console.log({ error, info }, 'inside component did catch')
    captureException(error)
  }


  render() {
    if (this.state.hasError || this.props.bootApiError) {
      return <FallBack />
    }

    return this.props.children
  }
}
const mapStateToProps = state => ({
  bootApiError: state.auth.bootApiError
})

export default connect(mapStateToProps)(ErrorBoundary)