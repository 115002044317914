import { Input, InputGroup } from 'reactstrap'
import SearchIcon from '../../../custom-search-dropDown/Search'
import { useTranslation } from 'react-i18next'
import './CustomSearchInput.scss'
import { MAX_SEARCH_CHAR_LIMIT } from '@src/App.constants'

const CustomSearchInput = (props) => {
  const {t} = useTranslation() 
  // eslint-disable-next-line no-unused-vars
  const {innerRef, ...rest} = props
  return (
    <div className="custom-search-input-main-wrapper w-100">
      <InputGroup
        className='custom-search-input input-group-merge'
      >
        <div className="search-icon">
          <SearchIcon
            isOpen={false}
            searchVal={false}
            isHoveredSelectTag={false}
          />
        </div>
        <Input
          {...rest}
          className="ps-0 custom-filter-search-input text-truncate w-100"
          placeholder={t('Search Here')}
          type="search"
          maxLength={MAX_SEARCH_CHAR_LIMIT}
        />
      </InputGroup>
    </div>
  )
}
export default CustomSearchInput