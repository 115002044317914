import CopyOnClick from '@src/@core/components/ui/click-and-copy';
import Edit from '@src/assets/images/icons/Edit';
import { OrderDetailsRow } from '@src/views/sales/sales.utils';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

const ShippingAddressCard = ({ shipping_address, editButtons, handleEditDetailsModal, hasEditOrderDetailsPermission }) => {

  const { t } = useTranslation();

  const addressDetailsData = [
    {
      key: 'recipient_name',
      label: 'Recipient\'s Name',
      value: shipping_address ? `${shipping_address.first_name} ${shipping_address.last_name}` : null,
      omit: !shipping_address?.first_name
    },
    {
      key: 'email',
      label: 'Email ID',
      value: shipping_address?.email,
      omit: !shipping_address?.email
    },
    {
      key: 'address',
      label: 'Address',
      value: shipping_address?.display_address,
      omit: !shipping_address?.display_address
    },
    {
      key: 'city',
      label: 'City',
      value: shipping_address?.display_city,
      omit: !shipping_address?.display_city
    },
    {
      key: 'state_country',
      label: 'State/Country',
      value: shipping_address?.display_state_country,
      omit: !shipping_address?.display_state_country
    },
    {
      key: 'sales_channel_city',
      label: 'Sales Channel City',
      value: shipping_address?.display_sales_channel_city,
      omit: !shipping_address?.display_sales_channel_city
    },
    {
      key: 'shipping_city',
      label: 'Shipping City',
      value: shipping_address?.display_manual_city,
      omit: !shipping_address?.display_manual_city
    },
    {
      key: 'lat_long',
      label: 'Lat/Long',
      value: <CopyOnClick id="latitude" columnKey="_longitude">
        {`${shipping_address.latitude}, ${shipping_address.longitude}`}
      </CopyOnClick>,
      omit: shipping_address?.latitude <= 0 && shipping_address?.longitude <= 0
    },
    {
      key: 'phone_number',
      label: 'Phone No.',
      value: shipping_address?.phone,
      omit: !shipping_address?.phone
    }
  ]

  return (
    <Card className="details-card w-100 h-100 bg-white rounded-3">
      <div className="card-header d-flex justify-content-between py-12px px-16px rounded-top-2 text-secondary txt-h3-md">
        <span>{t('Shipping Address')}</span>
        {editButtons.edit_shipping_address && hasEditOrderDetailsPermission && <span className="cursor-pointer" onClick={() => { handleEditDetailsModal('SHIPPING_ADDRESS') }}>
          <Edit width={20} height={20} />
        </span>}
      </div>
      <div className='d-flex flex-column p-16px gap-16px'>
        {addressDetailsData
          .filter(detail => !detail.omit)
          .map(detail => (
            <OrderDetailsRow
              key={detail.key}
              entityNameColSpan={4}
              entityValueColSpan={8}
              entityName={detail.label}
              entityValue={detail.value || '-'}
            />
          ))}
      </div>
    </Card>
  );
};

export default ShippingAddressCard;
