import { useTranslation } from 'react-i18next';

interface OrderSummaryProps {
  subTotal: number
  totalTaxes: number
  totalAmount: number
  isCreateShipmentView?: boolean
  currency?: string
}

const OrderSummary = ({ subTotal, totalTaxes, totalAmount, currency = 'SAR', isCreateShipmentView = false }: OrderSummaryProps) => {
  const { t } = useTranslation();
  // const grandTotal = subTotal + totalTaxes + totalDiscount;

  return (
    <div className="w-100">

      <div className="w-100 d-flex flex-column gap-16px">
        <div className="row w-100">
          <span className="col w-75 p-0 text-dark-6 txt-body-md">{t('Sub Total')}</span>
          <span className="p-0 text-dark txt-body-md d-flex justify-content-end" style={{width: 'fit-content'}}> {subTotal} {currency} </span>
        </div>
        <div className="row w-100">
          <span className="col w-75 p-0 text-dark-6 txt-body-md">{t('Total Taxes')}</span>
          <span className="p-0 text-dark txt-body-md d-flex justify-content-end" style={{width: 'fit-content'}}> {totalTaxes} {currency} </span>
        </div>
      </div>

      <div className="row w-100">
        <hr className="my-1 border-dark-2 w-100" style={{ borderStyle: 'dashed' }} />
      </div>

      <div className="row w-100">
        <div className="col w-75 p-0">
          <span className="txt-h3-md text-dark-6"> {t(isCreateShipmentView ? 'Total' : 'Grand Total')} </span>
          <span className="txt-h3-rg text-dark-5"> ({t(isCreateShipmentView ? 'Tax Incl.' : 'Order Value')}) </span>
        </div>
        <span className="p-0 d-flex justify-content-end txt-h3-md text-dark" style={{width: 'fit-content'}}>
          {Math.round(+totalAmount * 100) / 100} {currency}
        </span>
      </div>

    </div>
  );
};

export default OrderSummary;