// ** Reactstrap Imports
import {
  Form,
  ModalBody,
  ModalHeader,
  Spinner
} from 'reactstrap'

import Button from '@src/@core/components/ui/button'
import InputField from '@src/@core/components/ui/input-field'
import PhoneNumberDropdown from '@src/@core/components/ui/phone-number'
import Select from '@src/@core/components/ui/select'
import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import { loadCitiesConfig, loadCountryConfig, loadStatesCitiesConfig, loadStatesConfig } from '@src/app.apis'
import { REGEX_PATTERN } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import FilledInfo from '@src/assets/images/icons/FilledInfo'
import ability from '@src/configs/acl/ability'
import { getOptionsFetcher } from '@src/utility/Utils'
import { Countries } from '@src/views/hubs/utils/countries'
import { CUSTOMER_MODAL_TYPE } from '@src/views/sales/constant/orders.constants'
import { CUSTOMERS_FIELDS } from '@src/views/sales/sales.constant'
import {
  createCustomer,
  editCustomer,
  resetSuccess
} from '@src/views/sales/store/store'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import './CreateCustomer.scss'
const CreateCustomer = ({
  openCreateCustomerSidebar,
  setOpenCreateCustomerSidebar,
  selectedSeller,
  customerDetails = {}
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loading = useSelector((store) => store.sales.loading)
  const success = useSelector(store => store.sales.success)
  const handleCreateCustomerSidebar = () => {
    setOpenCreateCustomerSidebar({open : false, type : CUSTOMER_MODAL_TYPE.CREATE_CUSTOMER.key})
  }
  const loadStatesOptions = getOptionsFetcher(loadStatesConfig);
  const isCustomerCreationAllowed = ability.can(abilityMap.customer.create.action, abilityMap.customer.create.resource)
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    reset,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name:'',
      email: '',
      customer_phone_number: {
        countryCode: {
          name: 'Saudi Arabia',
          flag: '🇸🇦',
          country_code: 'SA',
          calling_code: '+966',
          value: 'Saudi Arabia (SA)',
          label: 'Saudi Arabia (+966)',
          maxPhoneNumberLength: 9
        },
        contactNumber: ''
      },
      customer_address1: '',
      customer_address2: '',
      customer_country: '',
      customer_state: '',
      customer_city: '',
      customer_zip: ''
    },
    mode: 'onChange'
    // context: {
    //     single: orderType?.value === 'single'
    // }
  })
  const onSubmit = (data) => {
    
    if (!selectedSeller) return

    if (CUSTOMER_MODAL_TYPE[openCreateCustomerSidebar.type].key === CUSTOMER_MODAL_TYPE.EDIT_CUSTOMER.key) {
      let body = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email || undefined
      }
      if (data.customer_phone_number.contactNumber) {
        body = {...body,
          mobile: data.customer_phone_number.contactNumber,
          country_calling_code: data.customer_phone_number.countryCode.calling_code,
          country_code: data.customer_phone_number.countryCode.country_code}
      }
      dispatch(editCustomer({seller_id: selectedSeller.value, customer_id:customerDetails.id, body}))
    } else {
      let body = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email || undefined,
        seller_code: selectedSeller.code,
        address: {
          address1: data.customer_address1,
          address2: data.customer_address2,
          city_id: data.customer_city.value,
          city: data.customer_city.label,
          country_id: data.customer_country.value,
          country: data.customer_country.label,
          state_id: data.customer_state?.value,
          state: data.customer_state?.label,
          first_name: data.first_name,
          last_name: data.last_name,
          zip: data.customer_zip || undefined,
          email: data.email || undefined
        }
      }
      if (data.customer_phone_number.contactNumber) {
        body = {
          ...body,
          mobile: data.customer_phone_number.contactNumber,
          country_calling_code: data.customer_phone_number.countryCode.calling_code,
          country_code: data.customer_phone_number.countryCode.country_code,
          address: {
            ...body.address,
            phone: data.customer_phone_number.contactNumber,
            country_calling_code: data.customer_phone_number.countryCode.calling_code,
            country_code: data.customer_phone_number.countryCode.country_code
          }
        }
      }
      dispatch(createCustomer({ seller_id: selectedSeller.value, body }))
    }
  }

  const handleSidebarClosed = () => {
    reset()
  }
  const handleSidebarOpened = () => {
    if (CUSTOMER_MODAL_TYPE[openCreateCustomerSidebar.type].key === CUSTOMER_MODAL_TYPE.EDIT_CUSTOMER.key) {
      const customerCountryCodeObj = Countries.find(country => country.calling_code === customerDetails.country_calling_code)
      setValue('first_name', customerDetails.first_name)
      setValue('last_name', customerDetails.last_name)
      setValue('email', customerDetails.email)
      if (customerDetails.phone_number) {
        setValue('customer_phone_number.countryCode', customerCountryCodeObj)
        setValue('customer_phone_number.contactNumber', customerDetails.phone_number)
      }
    }
  }
  useEffect(() => {
    if (watch('email') && errors.customer_phone_number) clearErrors('customer_phone_number')
    if (watch('customer_phone_number.contactNumber') && errors.email?.type === 'required') clearErrors('email')
  }, [watch('email'), watch('customer_phone_number.contactNumber')])
  
  const isPhoneRequired = !!(!watch('email') || watch('customer_phone_number.contactNumber'))
  const isEmailRequired = !!(!watch('customer_phone_number.contactNumber') || watch('email'))

  useEffect(() => {
    if (success.createCustomer) {
      setOpenCreateCustomerSidebar({open : false, type : CUSTOMER_MODAL_TYPE.CREATE_CUSTOMER.key})
      dispatch(resetSuccess())
    }
    if (success.editCustomer) {
      setOpenCreateCustomerSidebar({open : false, type : CUSTOMER_MODAL_TYPE.EDIT_CUSTOMER.key})
      dispatch(resetSuccess())
    }
  }, [success])
  const loadCitiesOptions = getOptionsFetcher(loadCitiesConfig)
  const loadStatesCitiesOptions = getOptionsFetcher(loadStatesCitiesConfig)
  const loadCountryOptions = getOptionsFetcher(loadCountryConfig)
  return (
    <SideSheet
      modalClassName="modal-slide-in sidebar-todo-modal create-customer-sidesheet"
      isOpen={openCreateCustomerSidebar.open}
      toggle={handleCreateCustomerSidebar}
      onClosed={handleSidebarClosed}
      onOpened={handleSidebarOpened}
    >

      <ModalHeader toggle={handleCreateCustomerSidebar}>
        {t(CUSTOMER_MODAL_TYPE[openCreateCustomerSidebar.type].headerTitle)}
      </ModalHeader>
      <ModalBody className="modal-body-data p-24px">
        <div className="d-flex mb-2 p-12px rounded-8px gap-10px info-header text-dark txt-body-rg">
          <FilledInfo height={20} width={20} color={'var(--bs-primary)'}/>
          
          <span>{t(`An email or phone number is required to ${CUSTOMER_MODAL_TYPE[openCreateCustomerSidebar.type].infoText}`)}.</span>
        </div>
        <Form>
          <>
            <div className="d-flex flex-column gap-18px">
              <InputField
                errors={errors}
                {...register(CUSTOMERS_FIELDS.FIRST_NAME, {
                  required: t('Please enter first name')
                })}
                isClearable
                label="First Name"
                isRequired
              />
              <InputField
                errors={errors}
                {...register(CUSTOMERS_FIELDS.LAST_NAME)}
                isClearable
                label="Last Name"
              />
              <InputField
                errors={errors}
                {...register(CUSTOMERS_FIELDS.EMAIL, {
                  required: watch('customer_phone_number.contactNumber') ? false :  'Please enter either email or contact number',
                  pattern:{
                    value: REGEX_PATTERN.EMAIL_VALIDATION,
                    message: t('Enter a valid email address')
                  }
                })}
                isClearable
                label="Email"
                isRequired={isEmailRequired}
              />
              <div className='mb-18px'>
                <PhoneNumberDropdown
                  name={CUSTOMERS_FIELDS.PHONE}
                  contactNumber={watch('customer_phone_number.contactNumber')}
                  countryCode={watch('customer_phone_number.countryCode')}
                  control={control}
                  errors={errors}
                  register={register}
                  isClearable
                  label="Contact Number"
                  isRequired={isPhoneRequired}
                  setError={setError}
                  clearErrors={clearErrors}
                  customValidation={val => {
                    if (watch(CUSTOMERS_FIELDS.EMAIL) || val.length) {
                      return true
                    }
                    return 'Please enter either email or contact number'
                  }}
                  menuPlacement='auto'
                  captureMenuScroll={false}
                />
              </div>
            </div>
            {CUSTOMER_MODAL_TYPE[openCreateCustomerSidebar.type].key === CUSTOMER_MODAL_TYPE.CREATE_CUSTOMER.key &&
              <div className="d-flex flex-column gap-18px mb-5">
                <InputField
                  errors={errors}
                  isRequired
                  {...register(CUSTOMERS_FIELDS.ADDRESS_1, {
                    required: t('Please enter address')
                  })}
                  isClearable
                  label='Address line 1'
                />
                <InputField
                  errors={errors}
                  {...register(CUSTOMERS_FIELDS.ADDRESS_2)}
                  isClearable
                  label='Address line 2'
                />
                <Controller
                  name={CUSTOMERS_FIELDS.COUNTRY}
                  control={control}
                  rules={{ required: t('Please select country') }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isAsync
                      loadOptions={loadCountryOptions}
                      isTooltipError
                      errors={errors}
                      value={watch(CUSTOMERS_FIELDS.COUNTRY)}
                      isClearable
                      label='Select Country'
                      isRequired
                      onChange={(e) => {
                        field.onChange(e)
                        if (watch(CUSTOMERS_FIELDS.CITY) || watch(CUSTOMERS_FIELDS.STATE)) {
                          setValue(CUSTOMERS_FIELDS.CITY, null)
                          setValue(CUSTOMERS_FIELDS.STATE, null)
                        }
                      }}
                      infoText={!errors.customer_country ? 'Type at least 3 characters to search for a country' : null}
                      menuPlacement='auto'
                      captureMenuScroll={false}
                    />
                  )}
                />

                <Controller
                  name={CUSTOMERS_FIELDS.STATE}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isAsync
                      value={getValues(CUSTOMERS_FIELDS.STATE)}
                      loadOptions={loadStatesOptions}
                      isClearable
                      isDisabled={!watch(CUSTOMERS_FIELDS.COUNTRY)}
                      label='Select State/Region'
                      cacheUniqs={[getValues(CUSTOMERS_FIELDS.COUNTRY)]}
                      additional={{
                        country: getValues(CUSTOMERS_FIELDS.COUNTRY)
                      }}
                      onChange={(e) => {
                        field.onChange(e)
                        if (watch(CUSTOMERS_FIELDS.CITY)) {
                          setValue(CUSTOMERS_FIELDS.CITY, null)
                        }
                      }}
                      menuPlacement='auto'
                      captureMenuScroll={false}
                    />
                  )}
                />

                <Controller
                  name={CUSTOMERS_FIELDS.CITY}
                  control={control}
                  rules={{ required: t('Please select city') }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isAsync
                      loadOptions={watch(CUSTOMERS_FIELDS.STATE) ? loadStatesCitiesOptions : loadCitiesOptions}
                      isTooltipError
                      errors={errors}
                      value={watch(CUSTOMERS_FIELDS.CITY)}
                      isClearable
                      label="Select City"
                      isRequired
                      isDisabled={!getValues(CUSTOMERS_FIELDS.COUNTRY)}
                      cacheUniqs={watch(CUSTOMERS_FIELDS.STATE) ? [getValues(CUSTOMERS_FIELDS.STATE)] : [getValues(CUSTOMERS_FIELDS.COUNTRY)]}
                      additional={{
                        state: watch(CUSTOMERS_FIELDS.STATE),
                        params: {
                          country_id: watch(CUSTOMERS_FIELDS.STATE) ? null : watch(CUSTOMERS_FIELDS.COUNTRY)?.value, 
                        },
                      }}
                      menuPlacement='auto'
                      captureMenuScroll={false}
                    />
                  )}
                />
                <div>
                  <InputField
                    errors={errors}
                    {...register(CUSTOMERS_FIELDS.ZIP)}
                    isClearable
                    label='Zip'
                    type="number"
                  />
                </div>
              </div>
            }
          </>
        </Form>
      </ModalBody>
      <SidesheetFooter className='position-sticky bottom-0'>
        <Button
          type="button"
          onClick={handleSubmit(onSubmit)}
          disabled={loading.createCustomer || loading.editCustomer || !isCustomerCreationAllowed}
          icon={loading.createCustomer || loading.editCustomer ? Spinner : null}
          iconSize='sm'
        >
          {t('Save')}
        </Button>
      </SidesheetFooter>
    </SideSheet>
  )
}

export default CreateCustomer
