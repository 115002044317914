import { Plus } from 'react-feather'
import OrderTagsMapper from '../live-orders/pages/packingOrderWise/StationPages/packingOrderTabsPages/OrderTagsMapper'

/**
 * Renders a cell component that displays a list of tags with a custom icon.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.tags - The array of tags to be displayed.
 * @return {JSX.Element} The rendered OrderTagCell component.
 */

const OrderTagCell = ({ tags }) => {
  const CustomIcon = () => {
    return <p className="cursor-pointer flex-center-center gap-2px m-0 ps-6px pe-8px text-primary-global rounded-10px border border-primary-global">
      <Plus size={12} />
      <span className="txt-asst-rg pt-2px">{tags.length - 2}</span>
    </p>
  }
  return (
    <OrderTagsMapper customIcon={CustomIcon} tagsArray={tags} viewItems={2} containerBody/>
  )
}

export default OrderTagCell