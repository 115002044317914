import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import { DROPDOWN_TYPES } from '@src/@core/components/ui/customTableFilterComponent/customTableFilterComponent.constant'
import SortHeaderColumn from '@src/@core/components/ui/table-header-col'
import { loadCourierPartnersConfig } from '@src/app.apis'
import { KEYBOARD_KEYS, TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import Bug from '@src/assets/images/icons/Bug'
import no_courier_partner_image from '@src/assets/images/omniful/no_courier_partner_image.svg'
import no_image from '@src/assets/images/omniful/no_image.svg'
import trackingImage from '@src/assets/images/orders/trackingOrder.svg'
import { getOptionsFetcher } from '@src/utility/Utils'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { SHIPMENT_ORDERS_STATUS } from './shipmentOrders.constant'

export const SHIPMENT_ORDERS_TABLE_COLUMN_HEADER = {
  SR_NO: {name: 'S. No.'},
  ORDER_ID: {name: 'Order ID'},
  SELLER: {name: 'Seller'},
  CUSTOMER_NAME: {name: 'Customer Name'},
  CUSTOMER_EMAIL: {name: 'Customer Email'},
  CUSTOMER_PHONE: {name: 'Customer Phone'},
  TOTAL_QUANTITY: {name: 'Total Return Quantity'},
  SKU: {name: 'SKU'},
  DELIVERED_QTY: {name: 'Delivered Qty'},
  UNIT_PRICE: {name: 'Unit Price'},
  REASON: {name: 'Reason'},
  TOTAL_PRICE: {name: 'Total Price'},
  TOTAL_AMOUNT: {name: 'Total Amount'},
  AWB_NUMBER: {name: 'AWB Number', key:'awb_number'},
  SHIPPING_PARTNER : {name: 'Shipping Partner', key: 'shipping_partner_name'},
  CREATED_AT : {name: 'Created At', key: 'created_at'},
  CREATED_BY : {name: 'Created By', key: 'created_by'},
  SHIPMENT_CREATED_AT : {name: 'Shipment Created At'},
  SHIPMENT_CREATED_BY : {name: 'Shipment Created By'},
  ACTION: {name: 'Action'},
  DELIVERY_ATTEMPT: {name: 'Delivery Attempts'},
  SALES_CHANNEL: {name: 'Sales Channel'},
  STATUS: {name: 'Status'},
  TOTAL_SKU: {name: 'Total SKU(s)'},
  TOTAL_ITEMS: {name: 'Total Items'},
  HUB: {name: 'Hub'},
  COURIER_PARTNER: {name: 'Courier Partner'},
  NO_OF_PACKAGES: {name: 'No. of Packages'},
  TOTAL_WEIGHT: {name: 'Total Weight'},
  REFERENCE_ORDER_ID: {name: 'Reference Order ID'},
  ORDER_SOURCE: {name: 'Order Source'},
  PICKUP_LOCATION: {name: 'Location'},
  SHIPMENT_TYPE: { name: 'Type' },
  DELIVERY_REATTEMPTS:{name:'Delivery Reattempts'}
}

const COMMON_COLUMNS = (handleSetOrderToShowOrderDetail, t, tenantType) => [
  {
    id: 2,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.ORDER_ID.name),
    key: 'order_id',
    minWidth: '280px',
    reorder: true,
    cell: (row) => (row.shipment_order_id ? <CopyOnClick id={row.id} handleDetails={() => handleSetOrderToShowOrderDetail(row)} columnKey="order_id" >{row.shipment_order_id}</CopyOnClick> : '-')
  },
  {
    id: 3,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.REFERENCE_ORDER_ID.name),
    key: 'order_alias',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.order_alias || '-'
  },
  {
    id: 5,
    name: t('Order Tags'),
    key: 'tags',
    width: '300px',
    reorder: true,
    cell: (row) => {
      return ( 
        <div className="d-flex flex-wrap gap-6px">
          {
            row.tags.length > 0 ? row.tags.map((tag, index) => {
              return (
                <CustomTag key={index} style={{ color: tag.color, backgroundColor: `${tag.color}1A`}} title={tag.name} />
              ) 
            }) : <div className="w-100 text-center">-</div>
          }
        </div>
      )
    }
  },
  {
    id: 4,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.SHIPMENT_TYPE.name),
    key: 'shipment_type',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.display_type || '-'
  },

  {
    id: 6,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.CUSTOMER_NAME.name),
    key: 'customer_name',
    minWidth: '160px',
    reorder: true,
    cell: (row) => `${row.customer.first_name} ${row.customer.last_name}` || '-'
  },
  {
    id: 7,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.SELLER.name),
    key: 'seller_name',
    minWidth: '160px',
    reorder: true,
    cell: (row) => row.seller.name || '-',
    omit: tenantType === TENANT_TYPE.SELF_USAGE
  },
  {
    id: 8,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.PICKUP_LOCATION.name),
    key: 'pickup_location',
    minWidth: '220px',
    reorder: true,
    cell: (row) => row.hub_details.name || '-'
  },
  {
    id: 9,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.SALES_CHANNEL.name),
    key: 'sales_channel_name',
    minWidth: '180px',
    reorder: true,
    cell: (row) => (
      <div className="flex-center-center w-100 h-100">
        {row.sales_channel.logo_url ? (
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_image
            }}
            src={row.sales_channel.logo_url}
            alt="source"
            height="30px"
            width="60px"
          />
        ) : (
          '-'
        )}
      </div>
    )
  },
  {
    id: 10,
    minWidth: '200px',
    key: 'created_at',
    reorder: true,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.CREATED_AT.name),
    selector: (row) => row.created_at || '-'
  },
  {
    id: 11,
    minWidth: '200px',
    key: 'medium',
    reorder: true,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.ORDER_SOURCE.name),
    selector: (row) => row.medium || '-'
  }
]
const IN_TRANSIT = (handleSetOrderToShowOrderDetail, t, ability, tenantType, handleCancelShipmentOrder,handleShipmentsTracking) => [
  {
    id: 1,
    name: <div className="w-100 text-end">{t('S. No.')}</div>,
    key:'serialNo',
    minWidth: '60px',
    maxWidth: '60px',
    omit:ability.can(abilityMap.shipment_order.edit_status.action, abilityMap.shipment_order.edit_status.resource),
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  ...COMMON_COLUMNS(handleSetOrderToShowOrderDetail, t, tenantType),
  {
    id: 30,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.AWB_NUMBER.name),
    minWidth: '300px',
    key: 'awb_number',
    reorder: true,
    cell: (row) => (
      <div className="w-100 h-100 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-50">
          {row.shipment_details.tracking_details.tracking_url && <a href={row.shipment_details.tracking_details.tracking_url} target="_blank" rel="noreferrer"><img src={trackingImage} alt="tracking-image" /></a>}
          {row.shipment_details.tracking_details.awb_number ? <CopyOnClick id={row.id} columnKey="awb_number">{row.shipment_details.tracking_details.awb_number}</CopyOnClick> : '-'}
        </div>
        {row.shipment_details.shipping_partner.tag !== 'manual' && row.shipment_details.tracking_details.awb_number  &&
            <div className="cursor-pointer height-20px width-20px flex-center-center" onClick={() => handleShipmentsTracking({ awb_number: row.shipment_details.tracking_details.awb_number, shipping_partner_name: row.shipment_details.shipping_partner.name, shipping_partner_tag: row.shipment_details.shipping_partner.tag })}>
              <Bug stroke="var(--bs-primary)" strokeWidth={1.5} />
            </div>
        }
      </div>
    )
  },
  {
    id: 31,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.SHIPPING_PARTNER.name),
    minWidth: '160px',
    key: 'shipping_partner',
    reorder: true,
    selector: (row) => row.shipment_details.shipping_partner.name || '-'
  },
  {
    id: 32,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.COURIER_PARTNER.name),
    minWidth: '140px',
    key: 'courier_partner',
    reorder: true,
    cell: row => (
      <div className="flex-center-center gap-50 w-100">
        {row.shipment_details.courier_partner.logo 
          ? <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_courier_partner_image
            }}
            src={row.shipment_details.courier_partner.logo || no_courier_partner_image}
            alt='courier-logo'
            height={50} width={50}
            style={{objectFit:'contain'}}
            className="border rounded"
          />
          : <span>{row.shipment_details.courier_partner.name || '-'}</span>
        }
      </div>
    )   
  },
  {
    id: 33,
    name: <SortHeaderColumn className="w-100 text-end text-truncate" title={SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.NO_OF_PACKAGES.name} type="number" id="number_of_packages"/>,
    minWidth: '160px',
    key: 'number_of_packages',
    reorder: true,
    sortable: true,
    cell: (row) => <div className="w-100 text-end">{row.number_of_boxes || '-'}</div>
  },
  {
    id: 34,
    name: <SortHeaderColumn className="w-100 text-end text-truncate" title={SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.TOTAL_WEIGHT.name} type="number" id="total_weight"/>,
    minWidth: '160px',
    key: 'total_weight',
    reorder: true,
    sortable: true,
    cell: (row) => <div className="w-100 text-end">{row.total_weight || '-'}</div>
  },
  {
    id: 35,
    name: <div className="w-100 text-end text-truncate" title={t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.DELIVERY_REATTEMPTS.name)}>{t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.DELIVERY_REATTEMPTS.name)}</div>,
    minWidth: '180px',
    key: 'delivery_reattempts',
    reorder: true,
    cell: (row) => {
      console.log(row)
      return <div className="w-100 text-end">{row.shipment_details.tracking_details.number_of_retries || ''}</div>
    },
  },
  {
    id: 36,
    name: t('Action'),
    key: 'action',
    minWidth: '80px',
    selector: (row) => row,
    omit: (!ability.can(abilityMap.shipment_order.cancel.action, abilityMap.shipment_order.cancel.resource)),
    cell: (row) => (
      <div className="m-auto">
        <p
          className="shipment-order-cancel-btn d-flex justify-content-center align-items-center cursor-pointer border-0"
          id={`cancelOrderIdReadyToPack${row.id}`}
          onClick={() => handleCancelShipmentOrder(row)}
        >
          <span className="cancel-btn-icon d-flex justify-content-center align-items-center">
            <X color={'var(--bs-danger)'} size={15} />
          </span>
        </p>
        <UncontrolledTooltip
          placement="bottom"
          target={`cancelOrderIdReadyToPack${row.id}`}
          trigger="hover"
          offset={[0, 5]}
        >
          {t('Cancel Order')}
        </UncontrolledTooltip>
      </div>
    )
  },

]
const SHIPMENT_NEW_ORDERS = (handleSetOrderToShowOrderDetail, t, ability, tenantType, handleCancelShipmentOrder) => [
  {
    id: 1,
    name: <div className="w-100 text-end">{t('S. No.')}</div>,
    key:'serialNo',
    minWidth: '60px',
    maxWidth: '60px',
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  ...COMMON_COLUMNS(handleSetOrderToShowOrderDetail, t, tenantType),
  {
    id: 14,
    name: <SortHeaderColumn className="w-100 text-end text-truncate" title={SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.NO_OF_PACKAGES.name} type="number" id="number_of_packages"/>,
    minWidth: '160px',
    key: 'number_of_packages',
    reorder: true,
    sortable: true,
    cell: (row) => <div className="w-100 text-end">{row.number_of_boxes}</div>
  },
  {
    id: 15,
    name: <SortHeaderColumn className="w-100 text-end text-truncate" title={SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.TOTAL_WEIGHT.name} type="number" id="total_weight"/>,
    minWidth: '160px',
    key: 'total_weight',
    reorder: true,
    sortable: true,
    cell: (row) => <div className="w-100 text-end">{row.total_weight}</div>
  },
  {
    id: 16,
    name: t('Action'),
    key: 'action',
    minWidth: '80px',
    selector: (row) => row,
    omit: (!ability.can(abilityMap.shipment_order.cancel.action, abilityMap.shipment_order.cancel.resource)),
    cell: (row) => (
      <div className="m-auto">
        <p
          id={`cancelOrderIdReadyToPack${row.id}`}
          onClick={() => handleCancelShipmentOrder(row)}
          className="shipment-order-cancel-btn d-flex justify-content-center align-items-center cursor-pointer border-0"
        >
          <span className="cancel-btn-icon d-flex justify-content-center align-items-center">
            <X color={'var(--bs-danger)'} size={15} />
          </span>
        </p>
        <UncontrolledTooltip
          placement="bottom"
          target={`cancelOrderIdReadyToPack${row.id}`}
          trigger="hover"
          offset={[0, 5]}
        >
          {t('Cancel Order')}
        </UncontrolledTooltip>
      </div>
    )
  }
]

const READY_TO_SHIP = (handleSetOrderToShowOrderDetail, t, ability, tenantType, handleCancelShipmentOrder, handleShipmentsTracking) => [
  {
    id: 1,
    name: <div className="w-100 text-end">{t('S. No.')}</div>,
    key:'serialNo',
    minWidth: '60px',
    maxWidth: '60px',
    omit:ability.can(abilityMap.shipment_order.edit_status.action, abilityMap.shipment_order.edit_status.resource),
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  ...COMMON_COLUMNS(handleSetOrderToShowOrderDetail, t, tenantType),
  {
    id: 30,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.AWB_NUMBER.name),
    minWidth: '300px',
    key: 'awb_number',
    reorder: true,
    cell: (row) => (
      <div className="w-100 h-100 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-50">
          {row.shipment_details.tracking_details.tracking_url && <a href={row.shipment_details.tracking_details.tracking_url} target="_blank" rel="noreferrer"><img src={trackingImage} alt="tracking-image" /></a>}
          {row.shipment_details.tracking_details.awb_number ? <CopyOnClick id={row.id} columnKey="awb_number">{row.shipment_details.tracking_details.awb_number}</CopyOnClick> : '-'}
        </div>
        {row.shipment_details.shipping_partner.tag !== 'manual' && row.shipment_details.tracking_details.awb_number  &&
            <div className="cursor-pointer height-20px width-20px flex-center-center" onClick={() => handleShipmentsTracking({ awb_number: row.shipment_details.tracking_details.awb_number, shipping_partner_name: row.shipment_details.shipping_partner.name, shipping_partner_tag: row.shipment_details.shipping_partner.tag })}>
              <Bug stroke="var(--bs-primary)" strokeWidth={1.5} />
            </div>
        }
      </div>
    )
  },
  {
    id: 31,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.SHIPPING_PARTNER.name),
    minWidth: '160px',
    key: 'shipping_partner',
    reorder: true,
    selector: (row) => row.shipment_details.shipping_partner.name || '-'
  },
  {
    id: 32,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.COURIER_PARTNER.name),
    minWidth: '140px',
    key: 'courier_partner',
    reorder: true,
    cell: row => (
      <div className="flex-center-center gap-50 w-100">
        {row.shipment_details.courier_partner.logo 
          ? <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_courier_partner_image
            }}
            src={row.shipment_details.courier_partner.logo || no_courier_partner_image}
            alt='courier-logo'
            height={50} width={50}
            style={{objectFit:'contain'}}
            className="border rounded"
          />
          : <span>{row.shipment_details.courier_partner.name || '-'}</span>
        }
      </div>
    )   
  },
  {
    id: 33,
    name: <SortHeaderColumn className="w-100 text-end text-truncate" title={SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.NO_OF_PACKAGES.name} type="number" id="number_of_packages"/>,
    minWidth: '160px',
    key: 'number_of_packages',
    reorder: true,
    sortable: true,
    cell: (row) => <div className="w-100 text-end">{row.number_of_boxes || '-'}</div>
  },
  {
    id: 34,
    name: <SortHeaderColumn className="w-100 text-end text-truncate" title={SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.TOTAL_WEIGHT.name} type="number" id="total_weight"/>,
    minWidth: '160px',
    key: 'total_weight',
    reorder: true,
    sortable: true,
    cell: (row) => <div className="w-100 text-end">{row.total_weight || '-'}</div>
  },
  {
    id: 35,
    name: t('Action'),
    key: 'action',
    minWidth: '80px',
    selector: (row) => row,
    omit: (!ability.can(abilityMap.shipment_order.cancel.action, abilityMap.shipment_order.cancel.resource)),
    cell: (row) => (
      <div className="m-auto">
        <p
          className="shipment-order-cancel-btn d-flex justify-content-center align-items-center cursor-pointer border-0"
          id={`cancelOrderIdReadyToPack${row.id}`}
          onClick={() => handleCancelShipmentOrder(row)}
        >
          <span className="cancel-btn-icon d-flex justify-content-center align-items-center">
            <X color={'var(--bs-danger)'} size={15} />
          </span>
        </p>
        <UncontrolledTooltip
          placement="bottom"
          target={`cancelOrderIdReadyToPack${row.id}`}
          trigger="hover"
          offset={[0, 5]}
        >
          {t('Cancel Order')}
        </UncontrolledTooltip>
      </div>
    )
  }
]

const CANCELLED = (handleSetOrderToShowOrderDetail, t, tenantType, handleShipmentsTracking) => [
  {
    id: 1,
    name: <div className="w-100 text-end">{t('S. No.')}</div>,
    key:'serialNo',
    minWidth: '60px',
    maxWidth: '60px',
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  ...COMMON_COLUMNS(handleSetOrderToShowOrderDetail, t, tenantType),
  {
    id: 30,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.AWB_NUMBER.name),
    minWidth: '200px',
    key: 'awb_number',
    reorder: true,
    cell: (row) => (
      <div className="w-100 h-100 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-50">
          {row.shipment_details.tracking_details.tracking_url && <a href={row.shipment_details.tracking_details.tracking_url} target="_blank" rel="noreferrer"><img src={trackingImage} alt="tracking-image" /></a>}
          {row.shipment_details.tracking_details.awb_number ? <CopyOnClick id={row.id} columnKey="awb_number">{row.shipment_details.tracking_details.awb_number}</CopyOnClick> : '-'}
        </div>
        {row.shipment_details.shipping_partner.tag !== 'manual' && row.shipment_details.tracking_details.awb_number  &&
          <div className="cursor-pointer height-20px width-20px flex-center-center" onClick={() => handleShipmentsTracking({ awb_number: row.shipment_details.tracking_details.awb_number, shipping_partner_name: row.shipment_details.shipping_partner.name, shipping_partner_tag: row.shipment_details.shipping_partner.tag })}>
            <Bug stroke="var(--bs-primary)" strokeWidth={1.5} />
          </div>
        }
      </div>
    )
  },
  {
    id: 31,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.SHIPPING_PARTNER.name),
    minWidth: '160px',
    key: 'shipping_partner',
    reorder: true,
    selector: (row) => row.shipment_details.shipping_partner.name || '-'
  },
  {
    id: 32,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.COURIER_PARTNER.name),
    minWidth: '140px',
    key: 'courier_partner',
    reorder: true,
    cell: row => (
      <div className="flex-center-center gap-50 w-100">
        {row.shipment_details.courier_partner.logo 
          ? <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_courier_partner_image
            }}
            src={row.shipment_details.courier_partner.logo || no_courier_partner_image}
            alt='courier-logo'
            height={50} width={50}
            style={{objectFit:'contain'}}
            className="border rounded"
          />
          : <span>{row.shipment_details.courier_partner.name || '-'}</span>
        }
      </div>
    )   
  },
  {
    id: 33,
    name:  <SortHeaderColumn className="w-100 text-end text-truncate" title={SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.NO_OF_PACKAGES.name} type="number" id="number_of_packages"/>,
    minWidth: '160px',
    key: 'number_of_packages',
    reorder: true,
    sortable: true,
    cell: (row) => <div className="w-100 text-end">{row.number_of_boxes || '-'}</div>
  },
  {
    id: 34,
    name: <SortHeaderColumn className="w-100 text-end text-truncate" title={SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.TOTAL_WEIGHT.name} type="number" id="total_weight"/>,
    minWidth: '160px',
    key: 'total_weight',
    reorder: true,
    sortable: true,
    cell: (row) => <div className="w-100 text-end">{row.total_weight || '-'}</div>
  },
  {
    id: 35,
    name: <div className="w-100 text-end text-truncate" title={t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.DELIVERY_REATTEMPTS.name)}>{t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.DELIVERY_REATTEMPTS.name)}</div>,
    minWidth: '180px',
    key: 'delivery_reattempts',
    reorder: true,
    cell: (row) => <div className="w-100 text-end">{row.shipment_details.tracking_details.number_of_retries || ''}</div>
  }
]

const SHIPMENT_ALL_ORDERS = (handleSetOrderToShowOrderDetail, t, tenantType, handleShipmentsTracking) => [
  {
    id: 1,
    name: <div className="w-100 text-end">{t('S. No.')}</div>,
    minWidth: '60px',
    maxWidth: '60px',
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  ...COMMON_COLUMNS(handleSetOrderToShowOrderDetail, t, tenantType),
  {
    id: 30,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.STATUS.name),
    minWidth: '160px',
    key: 'status',
    reorder: true,
    selector: (row) => row.status || '-'
  },
  {
    id: 31,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.AWB_NUMBER.name),
    minWidth: '200px',
    key: 'awb_number',
    reorder: true,
    cell: (row) => (
      <div className="w-100 h-100 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-50">
          {row.shipment_details.tracking_details.tracking_url && <a href={row.shipment_details.tracking_details.tracking_url} target="_blank" rel="noreferrer"><img src={trackingImage} alt="tracking-image" /></a>}
          {row.shipment_details.tracking_details.awb_number ? <CopyOnClick id={row.id} displayId={row.shipment_details.tracking_details.awb_number} columnKey="awb_number">{row.shipment_details.tracking_details.awb_number}</CopyOnClick> : '-'}
        </div>
        {row.shipment_details.shipping_partner.tag !== 'manual' && row.shipment_details.tracking_details.awb_number  &&
          <div className="cursor-pointer height-20px width-20px flex-center-center" onClick={() => handleShipmentsTracking({ awb_number: row.shipment_details.tracking_details.awb_number, shipping_partner_name: row.shipment_details.shipping_partner.name, shipping_partner_tag: row.shipment_details.shipping_partner.tag })}>
            <Bug stroke="var(--bs-primary)" strokeWidth={1.5} />
          </div>
        }
      </div>
    )
  },
  {
    id: 32,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.SHIPPING_PARTNER.name),
    minWidth: '160px',
    key: 'shipping_partner',
    reorder: true,
    selector: (row) => row.shipment_details.shipping_partner.name || '-'
  },
  {
    id: 33,
    name: t(SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.COURIER_PARTNER.name),
    minWidth: '140px',
    key: 'courier_partner',
    reorder: true,
    cell: row => (
      <div className="flex-center-center gap-50 w-100">
        {row.shipment_details.courier_partner.logo 
          ? <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_courier_partner_image
            }}
            src={row.shipment_details.courier_partner.logo || no_courier_partner_image}
            alt='courier-logo'
            height={50} width={50}
            style={{objectFit:'contain'}}
            className="border rounded"
          />
          : <span>{row.shipment_details.courier_partner.name || '-'}</span>
        }
      </div>
    )   
  },
  {
    id: 34,
    name: <SortHeaderColumn title={SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.NO_OF_PACKAGES.name} className="w-100 text-end text-truncate" type="number" id="number_of_packages"/>,
    minWidth: '160px',
    key: 'number_of_packages',
    reorder: true,
    sortable: true,
    cell: (row) => <div className="w-100 text-end">{row.number_of_boxes || '-'}</div>
  },
  {
    id: 35,
    name:  <SortHeaderColumn title={SHIPMENT_ORDERS_TABLE_COLUMN_HEADER.TOTAL_WEIGHT.name} className="w-100 text-end text-truncate" type="number" id="total_weight"/>,
    minWidth: '160px',
    key: 'total_weight',
    reorder: true,
    sortable: true,
    cell: (row) => <div className="w-100 text-end">{row.total_weight || '-'}</div>
  }
]

export const getShipmentOrdersColumns = ({handleSetOrderToShowOrderDetail, status, ability, t, tenantType, handleCancelShipmentOrder, handleShipmentsTracking}) => {
  switch (status) {
  case SHIPMENT_ORDERS_STATUS.NEW_ORDERS.key:
    return SHIPMENT_NEW_ORDERS(handleSetOrderToShowOrderDetail, t, ability, tenantType, handleCancelShipmentOrder)
  case SHIPMENT_ORDERS_STATUS.READY_TO_SHIP.key:
    return READY_TO_SHIP(handleSetOrderToShowOrderDetail, t, ability, tenantType, handleCancelShipmentOrder, handleShipmentsTracking)
  case SHIPMENT_ORDERS_STATUS.DISPATCHED.key:
    return READY_TO_SHIP(handleSetOrderToShowOrderDetail, t, ability, tenantType, handleCancelShipmentOrder, handleShipmentsTracking)
  case SHIPMENT_ORDERS_STATUS.IN_TRANSIT.key:
    return IN_TRANSIT(handleSetOrderToShowOrderDetail, t, ability, tenantType, handleCancelShipmentOrder, handleShipmentsTracking)
  case SHIPMENT_ORDERS_STATUS.OUT_FOR_DELIVERY.key:
    return IN_TRANSIT(handleSetOrderToShowOrderDetail, t, ability, tenantType, handleCancelShipmentOrder, handleShipmentsTracking)
  case SHIPMENT_ORDERS_STATUS.RETURN_TO_ORIGIN.key:
    return IN_TRANSIT(handleSetOrderToShowOrderDetail, t, ability, tenantType, handleCancelShipmentOrder, handleShipmentsTracking)
  case SHIPMENT_ORDERS_STATUS.CANCELLED.key:
    return CANCELLED(handleSetOrderToShowOrderDetail, t, tenantType, handleShipmentsTracking)
  case SHIPMENT_ORDERS_STATUS.ALL.key:
    return SHIPMENT_ALL_ORDERS(handleSetOrderToShowOrderDetail, t, tenantType, handleShipmentsTracking)
  default:
    return COMMON_COLUMNS(handleSetOrderToShowOrderDetail, t, ability, tenantType, handleShipmentsTracking)
  }
}

export const RowWrapper = ({ entityName, entityValue, entityNameColSpan = 4, entityValueColSpan = 8 }) => {
  const { t } = useTranslation()
  return <>
    <Row className="p-0 m-0">
      <Col className={`col-${entityNameColSpan} p-0 m-0`}>{t(entityName)}</Col>
      <Col className={`col-${entityValueColSpan} p-0 ps-24px m-0 text-dark`}>{entityValue}</Col>
    </Row>
  </>
}

export const handleInputKeyPress = (event) => {
  if (event.key === KEYBOARD_KEYS.ENTER) {
    event.preventDefault()
  }
}

export const displayDeliveryAddress = (data) => {
  const addressParts = [
    data.address1,
    data.address2,
    data.city && data.city.name,
    data.state && data.state.name,
    data.country && data.country.name,
    data.zip
  ].filter(part => !!part)

  return addressParts.join(', ')
}

export const getCourierPartnerCustomFilter = (t) => {
  const loadCourierPartnersOptions = getOptionsFetcher(loadCourierPartnersConfig)
  return(
    {
      filterKey: 'courier_partner_tag',
      displayName: t('Courier Partners'),
      type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
      options: loadCourierPartnersOptions
    }
  )
}