import SideSheet from '@src/@core/components/ui/sideSheet';
import React from 'react';
import { iAddPickupLocationProps } from '../../shipping-aggregator-types';
import AddPickupLocationSidesheetForm from './add-pickup-location-sidesheet-form';

const AddPickupLocationSidesheet: React.FC<iAddPickupLocationProps> = (props) => {
  const { isOpen, toggleSideSheet, editPickupLocationSidesheet } = props;
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <SideSheet
        modalClassName='modal-slide-in create-order-container'
        isOpen={isOpen}
        toggle={toggleSideSheet}
        size='sm'
        title={editPickupLocationSidesheet?.isEdit ? 'Edit Pickup Address' : 'Add Pickup Address'}
      >
        <AddPickupLocationSidesheetForm {...props} />
      </SideSheet>
    </>
  );
};

export default AddPickupLocationSidesheet;