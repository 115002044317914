import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import BulkActions from '@src/@core/components/ui/bulk-actions/BulkActions'
import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import Modal from '@src/@core/components/ui/modal'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { ORDER_TYPES } from '@src/App.constants'
import { API_ENDPOINTS } from '@src/api.urls'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { PageNotFound } from '@src/components/pageNotFound/pageNotFound'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getApiUrl } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import STODetailSidebar from '@src/views/sales/StockTransferOrder/components/storeDetail/stoDetailSidebar'
import CancelOrderPopup from '@src/views/sales/components/CancelOrderPopup'
import ReassignHubModal from '@src/views/sales/components/reassignHubModal'
import { ATTACHMENTS_SIDEBAR_MODE, EDIT_ORDER_ENTITY_TYPES, ORDER_OPERATIONAL_BUTTONS, ORDER_STATUS } from '@src/views/sales/constant/orders.constants'
import ReorderModal from '@src/views/sales/create-order/components/reorder-modal'
import { ORDER_RECREATION_TYPE } from '@src/views/sales/create-order/create-order.constant'
import EditCustomerAndShippingDetailsModal from '@src/views/sales/pending-actions/components/EditCustomerAndShippingDetailsModal/EditCustomerAndShippingDetailsModal'
import Attachments from '@src/views/sales/pending-actions/pages/createOrder/attachments'
import BasicDetailsCard from '@src/views/sales/pending-actions/pages/orderDetails/components/basicDetailsCard/basicDetailsCard'
import CustomerDetailsCard from '@src/views/sales/pending-actions/pages/orderDetails/components/customerDetailsCard/customerDetailsCard'
import DestinationHubDetailsCard from '@src/views/sales/pending-actions/pages/orderDetails/components/destinationHubDetailsCard/destinationHubDetailsCard'
import OrderInfobanner from '@src/views/sales/pending-actions/pages/orderDetails/components/orderInfoBanner/orderInfobanner'
import ShippingAddressCard from '@src/views/sales/pending-actions/pages/orderDetails/components/shippingAddressCard/shippingAddressCard'
import useOrderRecreation from '@src/views/sales/pending-actions/pages/orderDetails/components/useOrderRecreation'
import SplittedOrderDetailsSideSheet from '@src/views/sales/pending-actions/pages/splittedOrders/components/splittedOrderDetailsSideSheet'
import { AUTOMATION_RULE_ORDER_ITEM_STATUS, EDIT_MODAL_TYPE, REASONS_FOR_ON_HOLD } from '@src/views/sales/sales.constant'
import { categorizeOrderDetailsButtons } from '@src/views/sales/sales.utils'
import { editOrder, resetSuccess, setSuccess, syncOrderPaymentStatus, updateOrderStatus } from '@src/views/sales/store/store'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import AvailableWithoutReservationInfo from '../../AvailableWithoutReservationInfo'
import OnHoldItemsTable from '../OnHoldItemsTable'

const OnHoldItemDetails = (props) => {
  const { orderId, onHoldOrderDetails, isFetching, isError, isSuccess, getSingleOnHoldOrderDetails, handleGetParentData, handleSideSheetClose } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)
  const queryClient = useQueryClient()
  const { handleOrderRecreation } = useOrderRecreation();

  const [isForcefulHubRoutePopupOpen, setForcefulHubRoutePopupOpen] = useState(false)
  const [isStoDetailsOpen, setIsStoDetailsOpen] = useState(false)
  const [isReassignHubModalOpen, setReassignHubModalOpen] = useState(false)
  
  const handleToggleForcefulHubRoutePopup = () => {
    setForcefulHubRoutePopupOpen(p => !p)
  } 

  const { mutate:getDataOnChangeOfSelectedHub, isPending: tableDataPending, isError: tableDataError, isSuccess: tableDataSuccess } = useMutation({
    mutationKey: ['get-data-on-change-of-selected-hub', orderId],
    mutationFn: ({selectedHubId}) => {
      const url = getApiUrl(`${API_ENDPOINTS.ORDERS.GET_SINGLE_ON_HOLD_ORDER}`, { orderId: orderId })
      const params = { hub_selected: selectedHubId}
      return axiosInstance.get(url, { params })
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['single-hold-order-details', orderId], {data:data} )
    },
  })

  const { mutate: handleForcefulHubRouting, isPending: isForcefulHubRoutingPending } = useMutation({
    mutationKey: ['put-forceful-hub-routing'],
    mutationFn: () => {
      const url = getApiUrl(`${API_ENDPOINTS.ORDERS.FORCEFUL_HUB_ROUTE}`, { orderId: orderId, hubId: onHoldOrderDetails.selected_hub.id })
      return axiosInstance.put(url)
    },
    onSuccess: () => {
      CustomToast('Order has been successfully assigned to the hub.', { my_type: 'success' })
      handleToggleForcefulHubRoutePopup()
      handleSideSheetClose()
      dispatch(setSuccess({
        forcefulHubRouting: true
      }))
    }
  })

  // store
  const loading = useSelector(store => store.sales.loading)
  const success = useSelector(store => store.sales.success)
  const selectedGlobalHubId = useSelector((store) => store.auth.selectedGlobalHubId);

  // states
  const [isSplittedOrderDetailsSideSheetOpen, setIsSplittedOrderDetailsSideSheetOpen] = useState(false)
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState({ open: false, key: EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key })
  const [isApproveOrderPopUpOpen, setIsApproveOrderPopUpOpen] = useState(false)
  const [isCancelOrderPopupOpen, setIsCancelOrderPopupOpen] = useState(false)
  const [attachmentSidebar, setAttachmentSidebar] = useState({
    isOpen: false,
    mode: ATTACHMENTS_SIDEBAR_MODE.ADD.id
  })
  const [addedAttachments, setAddedAttachments] = useState([])
  const [isOpenAddLocationAttributesModal, setIsOpenAddLocationAttributesModal] = useState(false)
  const [reorderModal, setReorderModal] = useState({ isOpen: false, recreationType: '' })

  // variables
  const hasEditAttachmentButton = useMemo(() => { 
    return onHoldOrderDetails?.buttons?.find(btn => btn.key === ORDER_OPERATIONAL_BUTTONS.EDIT_ATTACHMENTS.id) 
  }, [onHoldOrderDetails])
  const hasForcefulRoutePermission = ability.can(abilityMap.order.forceful_route.action, abilityMap.order.forceful_route.resource)
  const hasEditOrderDetailsPermission = ability.can(abilityMap.order.edit_details.action, abilityMap.order.edit_details.resource)  
  const hasGiftItemRemoved = onHoldOrderDetails?.automation_rule_items_detail?.items_status === AUTOMATION_RULE_ORDER_ITEM_STATUS.REMOVED

  // functions
  const toggleAddLocationAttributesModal = () => {
    setIsOpenAddLocationAttributesModal(p => !p)
  }

  const handleToggleAttachmentSidebar = () => {
    const mode = hasEditOrderDetailsPermission && hasEditAttachmentButton
      ? onHoldOrderDetails.attachments?.length ? ATTACHMENTS_SIDEBAR_MODE.EDIT.id : ATTACHMENTS_SIDEBAR_MODE.ADD.id
      : ATTACHMENTS_SIDEBAR_MODE.VIEW.id
    setAttachmentSidebar(prev => ({ ...prev, isOpen: !prev.isOpen, mode }))
  }

  const handleWhenAttachmentEdited = (req) => {
    const message = req.toastMessage || 'Files saved successfully';
    CustomToast(message, {my_type: 'success'} )
    handleToggleAttachmentSidebar()
    getSingleOnHoldOrderDetails()
    handleGetParentData()
  }

  const { mutate: editAttachment, isPending: isEditAttachmentPending} = editOrder({
    onSuccess: handleWhenAttachmentEdited
  })

  const handleSyncPaymentStatus = () => {
    dispatch(syncOrderPaymentStatus({ seller_sales_channel_order_id: onHoldOrderDetails.seller_sales_channel_order_id, seller_id: onHoldOrderDetails.seller_id, seller_sales_channel_id: onHoldOrderDetails.seller_sales_channel_id }))
  }

  const handleOpenSplittedOrderDetailSideSheet = () => {
    setIsSplittedOrderDetailsSideSheetOpen(true)
  }

  const handleCloseSplittedOrderDetailSideSheet = () => {
    setIsSplittedOrderDetailsSideSheetOpen(false)
  }

  const handleEditDetailsModal = (key) => {
    setIsEditDetailsModalOpen({ open: true, key })
  }

  const handleHubChange = (newHub) => {
    getDataOnChangeOfSelectedHub({ selectedHubId: newHub.value })
  }

  const handleApproval = () => {
    setIsApproveOrderPopUpOpen(true)
  }

  const toggleApprovalPopup = () => {
    setIsApproveOrderPopUpOpen(!isApproveOrderPopUpOpen)
  }

  const handleCancelOrderSuccess = () => {
    handleGetParentData()
    setIsCancelOrderPopupOpen(false)
    handleSideSheetClose(false)
  }

  const handleReassignHubSuccess = () => {
    handleGetParentData()
    setReassignHubModalOpen(false)
    handleSideSheetClose(false)
  }

  const onSaveAttachments = (files, toastMessage) => {
    const attachments = files.map((fileObj) => {
      return {
        file_url: fileObj.file_url,
        name: fileObj.file.name.split('.')[0],
        description: fileObj.description,
        mime_type: fileObj.file.type,
        should_upload: Boolean(fileObj.should_upload)
      }
    })
    const body = {
      attachments,
      edit_entity_type: EDIT_ORDER_ENTITY_TYPES.ORDER_ATTACHMENTS
    }
    editAttachment({body, order_id: onHoldOrderDetails.id, toastMessage})
  }


  const handleShowSTODetail = () => {
    setIsStoDetailsOpen(p=>!p)
  }

  const handleReorder = ({ recreationType, hubId }) => {
    const hasHubMismatch = onHoldOrderDetails.assigned_hub.id !== selectedGlobalHubId
  
    if (hasHubMismatch && !reorderModal.isOpen) {
      setReorderModal({ isOpen: true, recreationType });
      return;
    }
  
    handleOrderRecreation({
      recreationType,
      hubId: hubId || onHoldOrderDetails.assigned_hub.id,
      orderId: onHoldOrderDetails.id,
      previousOrderDisplayId: onHoldOrderDetails.seller_sales_channel_order_id
    });
  };

  const hubOptions = useMemo(() => {
    return onHoldOrderDetails?.hubs.map(hub => ({
      label: hub.name,
      value: hub.id
    })) || []
  }, [onHoldOrderDetails])
  
  const show_inventory = useMemo(() => {
    const { reason } = onHoldOrderDetails || {}
    return hubOptions?.length > 0 && (
      reason === REASONS_FOR_ON_HOLD.INVENTORY_NOT_PRESENT.label || 
      reason === REASONS_FOR_ON_HOLD.SKU_NOT_VALID.label || 
      reason === REASONS_FOR_ON_HOLD.REQUIRED_MANUAL_APPROVAL.label ||
      reason === REASONS_FOR_ON_HOLD.NOT_ABLE_TO_ROUTE.label
    )
  }, [onHoldOrderDetails, hubOptions])

  // The order of buttons in the array is important; we arrange them in order of priority
  const buttonArray = [
    {
      id: 'approve',
      name: 'APPROVE_ORDER',
      onClick: handleApproval,
      hasPermission: ability.can(abilityMap.order.approve.action, abilityMap.order.approve.resource)
    },
    {
      id: 'cancel_order',
      name: 'CANCEL_ORDER',
      onClick: () => setIsCancelOrderPopupOpen(true),
      hasPermission: ability.can(abilityMap.order.cancel.action, abilityMap.order.cancel.resource)
    },
    {
      id: 'split_order',
      name: 'SPLIT_ORDER',
      onClick: handleOpenSplittedOrderDetailSideSheet,
      hasPermission: ability.can(abilityMap.split_order.create.action, abilityMap.split_order.create.resource)
    },
    {
      id: 'reassign_hub',
      name: 'REASSIGN_HUB',
      onClick: ()=>setReassignHubModalOpen(true),
      hasPermission: ability.can(abilityMap.order.reassign_hub.action, abilityMap.order.reassign_hub.resource)
    },
    {
      id: 'duplicate_order',
      name: 'DUPLICATE_ORDER',
      onClick: ()=>handleOrderRecreation({ recreationType: ORDER_RECREATION_TYPE.DUPLICATE, hubId: onHoldOrderDetails?.assigned_hub.id || selectedGlobalHubId, orderId: onHoldOrderDetails?.id, previousOrderDisplayId: onHoldOrderDetails.seller_sales_channel_order_id  }),
      hasPermission: ability.can(abilityMap.order.create.action, abilityMap.order.create.resource)
    },
    {
      id: 'reorder',
      name: 'REORDER',
      onClick: () => handleReorder({recreationType: ORDER_RECREATION_TYPE.RE_ORDER}),
      hasPermission: ability.can(abilityMap.order.create.action, abilityMap.order.create.resource)
    }
  ]

  // Filter action buttons
  const { actionButtons, syncButtons, editButtons } = useMemo(() => categorizeOrderDetailsButtons(onHoldOrderDetails, buttonArray), [onHoldOrderDetails, buttonArray])

  const primaryButtonLoading = {
    APPROVE_ORDER: loading.updateOrderStatus,
    CANCEL_ORDER: loading.cancelOrder,
    SPLIT_ORDER: false
  }
  const primaryActionButton = actionButtons.shift()

  useEffect(() => {
    if (success.editCustomerAndShippingDetails) {
      setIsEditDetailsModalOpen(prev => ({ ...prev, open: false }))
      dispatch(resetSuccess())
      if (onHoldOrderDetails) {
        getSingleOnHoldOrderDetails()
      }
      handleGetParentData()
    }
    
    if (success.syncOrderPaymentStatus) {
      getSingleOnHoldOrderDetails()
      dispatch(resetSuccess())
    }
  }, [success.editCustomerAndShippingDetails, success.syncOrderPaymentStatus])
  
  useEffect(() => {
    if (onHoldOrderDetails?.attachments?.length) {
      const attachments = onHoldOrderDetails.attachments.map(attachment => (
        {
          file: {
            name: attachment.name,
            type: attachment.mime_type
          },
          description: attachment.description,
          file_url: attachment.file_url
        }
      ))
      setAddedAttachments(attachments)
    } else {
      setAddedAttachments([])
    }
  }, [onHoldOrderDetails])


  const uploadDetails = {
    service: 'oms',
    usecase: 'order-attachments'
  }
  

  return (
    <>
      {isError ? (
        <PageNotFound />
      ) : isFetching ? (
        <ComponentSpinner />
      ) : onHoldOrderDetails ? (
        <div className='on-hold-order-item-detail'>
        
          <OrderInfobanner orderDetails={onHoldOrderDetails} />

          <div className='flex-center-between gap-16px'>
            <div className='status-wrapper bg-white rounded-8px d-flex align-items-center gap-12px flex-grow-1'>
              <div className='status-label rounded-start text-secondary txt-h3-md py-8px px-16px'>{t('Status')}</div>
              <div className='d-flex gap-8px pe-12px'>
                {onHoldOrderDetails.status && <CustomLabel title={onHoldOrderDetails.status} className='order-status-tag text-uppercase' />}
              </div>
            </div>
            {(primaryActionButton || actionButtons.length > 0) && (
              <div className='d-flex gap-12px'>
                {primaryActionButton && (
                  <Button onClick={primaryActionButton.onClick} disabled={primaryButtonLoading[primaryActionButton.name]}>
                    {primaryButtonLoading[primaryActionButton.name] && <Spinner size='sm' className='me-50' />}
                    {primaryActionButton.text}
                  </Button>
                )}

                {actionButtons.length > 0 && <BulkActions actions={actionButtons} selectedButton={{ id: 'moreActions', text: 'More Actions' }} />}
              </div>
            )}
          </div>

          <BasicDetailsCard
            orderDetails={onHoldOrderDetails}
            hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
            toggleAddLocationAttributesModal={toggleAddLocationAttributesModal}
            isOpenAddLocationAttributesModal={isOpenAddLocationAttributesModal}
            syncButtons={syncButtons}
            handleSyncPaymentStatus={handleSyncPaymentStatus}
            handleOpenSplittedOrderDetailSideSheet={handleOpenSplittedOrderDetailSideSheet}
            handleToggleAttachmentSidebar={handleToggleAttachmentSidebar}
            hasEditAttachmentButton={hasEditAttachmentButton}
            handleShowSTODetail={handleShowSTODetail}
          />

          <div className='customer-shipping-card-wrapper'>
            <CustomerDetailsCard
              customer={onHoldOrderDetails.customer}
              editButtons={editButtons}
              handleEditDetailsModal={handleEditDetailsModal}
              orderType={onHoldOrderDetails.type}
              hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
            />
            {onHoldOrderDetails.type === ORDER_TYPES.STO.value ? (
              <DestinationHubDetailsCard shipping_address={onHoldOrderDetails.shipping_address} destination_hub={onHoldOrderDetails.destination_hub} />
            ) : (
              <ShippingAddressCard
                shipping_address={onHoldOrderDetails.shipping_address}
                editButtons={editButtons}
                handleEditDetailsModal={handleEditDetailsModal}
                hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
              />
            )}
          </div>

          {show_inventory && (
            <div className='hub-details-wrapper d-flex align-items-center my-24px p-12px rounded-4 gap-12px'>
              <DropdownWithTitle
                title={t('Hub')}
                options={hubOptions}
                defaultValue={{ label: onHoldOrderDetails.selected_hub.name, value: onHoldOrderDetails.selected_hub.id }}
                value={onHoldOrderDetails.selected_hub ? { label: onHoldOrderDetails.selected_hub.name, value: onHoldOrderDetails.selected_hub.id } : null}
                selectOptionHandler={handleHubChange}
                getOptionValue={(option) => option.label}
                externalStyles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: '160px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    textAlign: 'start',
                    border: 'none',
                    boxShadow: 'none',
                  }),
                }}
              />
              <div className='hub-inventory-details-wrapper d-flex bg-white rounded-4 flex-grow-1 justify-content-around'>
                <div className='d-flex gap-8px'>
                  <span>{t('Ordered SKU(s)')}</span>
                  <span>:</span>
                  <span className='text-dark'>{onHoldOrderDetails.total_sku_count}</span>
                </div>
                <div className='vertical-divider'></div>
                <div className='d-flex gap-8px'>
                  <span className='text-success'>{t('Available SKU(s)')}</span>
                  <span>:</span>
                  <span className={classNames({ 'text-primary': tableDataPending, 'text-dark': !tableDataPending })}>
                    {tableDataPending ? <Spinner size='sm' /> : onHoldOrderDetails.available_sku}
                  </span>
                </div>
                <div className='vertical-divider'></div>
                <div className='d-flex gap-8px'>
                  <span className='text-danger'>{t('Unavailable SKU(s)')}</span>
                  <span>:</span>
                  <span className={classNames({ 'text-primary': tableDataPending, 'text-dark': !tableDataPending })}>
                    {tableDataPending ? <Spinner size='sm' /> : onHoldOrderDetails.unavailable_sku}
                  </span>
                </div>
              </div>
              {!onHoldOrderDetails?.assigned_hub?.name && hasForcefulRoutePermission && (
                <Button
                  ofType='compressed'
                  onClick={handleToggleForcefulHubRoutePopup}
                  disabled={isForcefulHubRoutingPending || onHoldOrderDetails.available_sku < onHoldOrderDetails.total_sku_count}
                >
                  {t('Assign Hub')}
                </Button>
              )}
            </div>
          )}

          {onHoldOrderDetails.show_available_without_reservation ? (
            <div className='mb-24px'>
              <AvailableWithoutReservationInfo />
            </div>
          ) : null}

          <OnHoldItemsTable
            orderDetails={onHoldOrderDetails}
            isPending={tableDataPending}
            isError={tableDataError}
            isSuccess={onHoldOrderDetails ? isSuccess : tableDataSuccess}
            show_inventory={show_inventory}
            editButtons={editButtons}
          />

          <SplittedOrderDetailsSideSheet
            isModalOpen={isSplittedOrderDetailsSideSheetOpen}
            handleCloseSideSheet={handleCloseSplittedOrderDetailSideSheet}
            order_id={onHoldOrderDetails.is_split_order ? onHoldOrderDetails.parent_order_details.id : onHoldOrderDetails.id}
            isSplitOrderCreated={onHoldOrderDetails.is_split_order}
          />

          {onHoldOrderDetails && isEditDetailsModalOpen.open && (
            <EditCustomerAndShippingDetailsModal
              isModalOpen={isEditDetailsModalOpen}
              setIsModalOpen={setIsEditDetailsModalOpen}
              order_id={onHoldOrderDetails.id}
              editDetails={
                isEditDetailsModalOpen.key === EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key || isEditDetailsModalOpen.key === EDIT_MODAL_TYPE.RECEPIENT_DETAILS.key
                  ? onHoldOrderDetails.customer
                  : onHoldOrderDetails.shipping_address
              }
            />
          )}

          <Modal
            key='approve_order_modal'
            isOpen={isApproveOrderPopUpOpen}
            hasComponentSideContentTranslation={false}
            closeModalHandler={toggleApprovalPopup}
            secondaryButtonHandler={() => {
              dispatch(updateOrderStatus({ orderID: onHoldOrderDetails.id, status:ORDER_STATUS.NEW_ORDER.key}))
            }}
            primaryButtonHandler={toggleApprovalPopup} 
            title={t('Are you sure you want to approve the order?')}
            content={<>
              <span className='txt-body-rg'>{t('Order ID')}:</span> <span className='txt-body-md'>{onHoldOrderDetails.seller_sales_channel_order_id}</span>
            </>} 
            primaryButtonText={t('Cancel')}
            secondaryButtonText={t('Approve')}
            secondaryButtonProps={{
              loading: loading.updateOrderStatus
            }}
          />

          <CancelOrderPopup
            isCancelOrderPopupOpen={isCancelOrderPopupOpen}
            setIsCancelOrderPopupOpen={setIsCancelOrderPopupOpen}
            orderDetail={{ ...onHoldOrderDetails, sellerOrderId: onHoldOrderDetails.seller_sales_channel_order_id }}
            setOrderDetail={() => {}}
            handleCancelOrderSuccess={handleCancelOrderSuccess}
          />
          <Attachments
            isOpen={attachmentSidebar.isOpen}
            toggle={handleToggleAttachmentSidebar}
            addedAttachments={addedAttachments}
            setAddedAttachments={setAddedAttachments}
            mode={attachmentSidebar.mode}
            onSaveAttachments={onSaveAttachments}
            isLoading={isEditAttachmentPending}
            uploadDetails={uploadDetails}
          />
          <Modal
            closeModalHandler={handleToggleForcefulHubRoutePopup}
            secondaryButtonHandler={handleForcefulHubRouting}
            primaryButtonHandler={handleToggleForcefulHubRoutePopup}
            secondaryButtonText={t('Confirm')}
            secondaryButtonProps={{ disabled: isForcefulHubRoutingPending }}
            primaryButtonText={t('Cancel')}
            title={`Are you sure you want to assign this order to ${onHoldOrderDetails.selected_hub?.name}?`}
            content={`This order will be assigned to the ${onHoldOrderDetails.selected_hub?.name} hub. This action cannot be reversed.`}
            isOpen={isForcefulHubRoutePopupOpen}
            isButtonVerticallyAlign={false}
          />

          <STODetailSidebar isShowSTODetail={isStoDetailsOpen} handleShowSTODetail={handleShowSTODetail} stoRequestId={onHoldOrderDetails.sto_request_id} />

          <ReassignHubModal
            isOpen={isReassignHubModalOpen}
            setIsOpen={setReassignHubModalOpen}
            orderDetail={onHoldOrderDetails}
            handleReassignHubSuccess={handleReassignHubSuccess}
          />
          
          <ReorderModal
            isOpen={reorderModal.isOpen}
            setIsOpen={() => setReorderModal({ isOpen: false, recreationType: '' })}
            handleContinue={handleReorder}
            preloadData={{
              orderId: onHoldOrderDetails?.seller_sales_channel_order_id,
              assignedHubId: onHoldOrderDetails?.hub_id,
              recreationType:reorderModal.recreationType
            }}
          />
        </div>
      ) : null}
    </>
  )
}

export default OnHoldItemDetails