import { Plus } from 'react-feather'

export const SIDE_BAR_DETAIL = {
  GATE_ENTRY: 'gateEntry',
  PURCHASE_ORDER: 'purchaseOrder',
  STO: 'stockTransferOrder'
}

export const ENTITY_TYPE = {
  PO: 'po',
  PO_SHIPMENT: 'po_shipment'
}
export const CYCLE_COUNT_TYPE = {
  APP: 'app',
  WEB:'web'
}
export const FILTER_KEYS = {
  SELLER_ID: 'seller_id',
  SUPPLIER_ID: 'supplier_id'
}

export const QC_BIN_TYPE = {
  PASS: 'pass',
  FAIL: 'fail'
}
export const GET_BATCHES_ACTIONS = ({t, handleOnClickBulkUpload}) => ({
  CREATE: {
    id: 1,
    text: t('Bulk Actions'),
    icon: Plus
  },
  BULK_UPLOAD_SKUS: {
    id: 2,
    text: t('Batch Upload'),
    icon: Plus,
    onClick: handleOnClickBulkUpload
  }

})

export const OMS_RETURN_ORDER_TYPE = {
  COURIER_PARTNER_RETURN: { name: 'Courier Partner Return', value: 'courier_partner_return'},
  CUSTOMER_RETURN: { name: 'Customer Return', value: 'customer_return'},
  OFF_SYSTEM_RETURN: { name: 'Off System Return', value: 'off_system_return'}
}

export const ADDITIONAL_FILTER_DROPDOWN_STYLE = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '235px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}

export const FORCAST_FILTER_DROPDOWN_STYLE = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '204px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}

export const INTERVAL = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' }
]

export const METHOD = [
  { label: 'Moving Average', value: 'moving_avg', desc: 'Results may vary based on historical data accuracy and recent trends' },
  {label: 'Linear Regression', value: 'linear_regression', desc: 'Neglecting anomalies may lead to inaccurate predictions, impacting the reliability of forecasting outcomes'},
  { label: 'Exponential Moving Average', value: 'exponential_avg', desc: 'Results may be influenced by recent data more heavily than older data, potentially overlooking long-term trends' },
  { label: 'Mean', value: 'mean', desc: 'Results may vary as this may not capture fluctuations or seasonality' }
]

export const LIMIT = {
  DAYS: 60,
  WEEKS: 8,
  MONTHS: 2
}

export const FORCAST_METHODS = {
  MEAN: 'mean',
  EXPONENTIALAVERAGE: 'Exponential Average',
  MOVINGAVERAGE: 'Moving Average'
}

export const FORCAST_INTERVAL = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month'
}

export const SAFETY_STOCK = 'Safety Stock'

export const REORDER_POINT = 'Reorder Point'

// Ordered","Partially Recieved","Recieved",
export const STO_RECEIVED_STATUS = {
  ORDERED: 'Ordered',
  PARTIAL_RECEIVED: 'Partially Recieved',
  RECEIVED: 'Recieved',
  CLOSED: 'Closed'
}
