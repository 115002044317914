import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import Button from '@src/@core/components/ui/button'
import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { SKU_TYPES, TENANT_TYPE } from '@src/App.constants'
import { uploadMultipleFilesOnS3 } from '@src/redux/authentication'
import { completeReturnOrderProcessing } from '@src/views/returns/store'
import { OrderDetailsRow } from '@src/views/sales/sales.utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Card, ModalBody } from 'reactstrap'
import CreateReturnOrderProcessingTable from '../components/create-return-order-processing-table'
import './createReturnOrderProcessing.scss'

const CreateReturnOrderProcessing = (props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const returnOrderDetail = useSelector((state) => state.returns.returnOrderDetail)
  const loading = useSelector((state) => state.returns.loading)
  const success = useSelector((state) => state.returns.success)
  const tenantType = useSelector(state => state.auth.userData.tenant.type)
  const [skuTableData, setSkuTableData] = useState([])
  const [isNextButtonPressed, setNextButtonPressed] = useState(false)
  const [quantitiesDetail, setQuantitiesDetail] = useState({})
  const [imageUploading,setImageUploading] = useState(false)
  const {
    isCreateReturnOrderProcessingModalOpen,
    handleCreateReturnOrderProcessingModal
  } = props

  const handleModalWhenClosed = () => {
    setSkuTableData([])
    setNextButtonPressed(false)
    setQuantitiesDetail({})
  }

  const handleSetAllQuantities = () => {
    const allSums = skuTableData.reduce((sums, obj) => {
      return {
        return_quantity: sums.return_quantity + obj.return_quantity,
        pass_quantity: sums.pass_quantity + obj.pass_quantity,
        fail_quantity: sums.fail_quantity + obj.fail_quantity
      }
    }, {return_quantity: 0, pass_quantity: 0, fail_quantity: 0})
    setQuantitiesDetail(allSums)
  }

  const handleNextButtonPressedToggle = () => {
    setNextButtonPressed(prev => !prev)
    setQuantitiesDetail({})
    handleSetAllQuantities()
  }

  const handleCompleteCreateOrderProcessing = async () => {
    let uploadIds;

    const isImagesToUpload = skuTableData
      .map((item) => item.qc_fail_reasons)
      .some((reason) => reason.some((reason) => reason.images?.length > 0));
    
    const isChildImagesToUpload = skuTableData
      .flatMap((item) => item.seller_sku.child_skus)
      .some((child) =>
        child.qc_fail_reasons?.some((reason) => reason.images?.length > 0 && reason.quantity > 0)
      );
    
    if (isImagesToUpload || isChildImagesToUpload) {
      setImageUploading(true);
     
      const allImagesToUpload = skuTableData.flatMap((item) => [
        ...item.qc_fail_reasons?.flatMap((reason) => 
          reason.quantity > 0 ? reason.images?.filter((image) => !image.isUploaded) : []
        ) || [],
        ...item.seller_sku?.child_skus?.flatMap((childItem) => 
          childItem.qc_fail_reasons?.flatMap((reason) => 
            reason.quantity > 0 ? reason.images?.filter((image) => !image.isUploaded) : []
          ) || []
        ) || []
      ]).map((image) => ({
        service: 'qc_fail_reason_images',
        usecase: 'qc_fail_images',
        file: image
      }));
  
      uploadIds = await uploadMultipleFilesOnS3(allImagesToUpload);
      if (!uploadIds) {
        setImageUploading(false);
        return;
      }
    }
  
    let updatedSkuTableData = [...skuTableData];
    let uploadIndex = 0;
  
    const processQcFailReasons = (items) => {
      return items.map((item) => {
        let updatedQcFailReasons = item.qc_fail_reasons?.map((reason) => {
          if (reason.images && reason.images.length > 0 && reason.quantity > 0) {
            const reasonUploadIds = reason.images.map(() => uploadIds[uploadIndex++]);
            const imageIds = reason.images.map((image, index) => reasonUploadIds[index]);
            return {
              ...reason,
              images: imageIds,
            };
          }
          return reason;
        });
    
        let updatedChildQcFailReasons = [];
        if (item.seller_sku?.child_skus?.length > 0) {
          updatedChildQcFailReasons = processQcFailReasons(item.seller_sku.child_skus);
        }
    
        return {
          ...item,
          qc_fail_reasons: updatedQcFailReasons,
          seller_sku: {
            ...item.seller_sku,
            child_skus: updatedChildQcFailReasons,
          },
        };
      });
    };
  
    if (uploadIds?.length > 0) {
      updatedSkuTableData = processQcFailReasons(updatedSkuTableData);
    }
  
    const body = updatedSkuTableData.map((item) => ({
      item_id: item.id,
      seller_sku_code: item.seller_sku_code,
      pass_quantity: item.pass_quantity,
      fail_quantity: item.fail_quantity,
      qc_fail_reasons: item.qc_fail_reasons,
      qc_child_items: item.seller_sku_type === SKU_TYPES.BUNDLE.key
        ? item.seller_sku?.child_skus?.map((childItem) => ({
          seller_sku_code: childItem.seller_sku_code,
          pass_quantity: childItem.pass_quantity,
          fail_quantity: childItem.fail_quantity,
          qc_fail_reasons: childItem.qc_fail_reasons,
        }))
        : undefined,
    }));
    dispatch(completeReturnOrderProcessing({ body: { qc_items: body }, orderID: returnOrderDetail.id }));
    setImageUploading(false);
  };
  

  useEffect(() => {
    if (returnOrderDetail) {
      const mappedData = []
      returnOrderDetail.order_items?.forEach((item) => {
        const mainSkuData = {
          ...item,
          seller_sku_code: item.seller_sku_code,
          seller_sku_image: item.seller_sku?.images?.[0]?.default,
          seller_sku_name: item.seller_sku.name,
          delivered_quantity: item.delivered_quantity,
          return_quantity: item.return_quantity,
          fail_quantity: 0,
          pass_quantity: item.return_quantity,
          seller_sku_type: item.seller_sku?.type
        }
        const mappedChildData = []
        item.seller_sku?.child_skus?.forEach((childItem) => {
          const childSkuData = {
            ...childItem,
            parentId: item.id,
            parent_seller_sku_code: item.seller_sku_code,
            seller_sku_code: childItem.seller_sku_code,
            seller_sku_image: childItem.images?.[0]?.default,
            seller_sku_name: childItem.name,
            return_quantity: childItem.return_quantity,
            fail_quantity: 0,
            pass_quantity: childItem.return_quantity,
            quantity: childItem.quantity
          }
          mappedChildData.push(childSkuData)
        })
        mappedData.push({...mainSkuData, 
          seller_sku: { child_skus: mappedChildData }
        })
      })
      setSkuTableData(mappedData)
    }
  }, [returnOrderDetail])

  useEffect(() => {
    if (success.completeReturnOrderProcessing) {
      handleCreateReturnOrderProcessingModal()
    }
  }, [success.completeReturnOrderProcessing])
  return (
    <SideSheet
      isOpen={isCreateReturnOrderProcessingModalOpen}
      toggle={handleCreateReturnOrderProcessingModal}
      onClosed={handleModalWhenClosed}
      contentClassName="p-0 bg-white"
      modalClassName="modal-slide-in create-return-order-processing-modal"
      size="lg"
      title="Return Order Processing"
    >
      <div className="d-flex justify-content-between title-container ps-24px pb-24px">
        <div className="d-flex flex-column gap-25">
          <div className="d-flex gap-25 align-items-center">
            <div className="return-order-detail-id txt-sub-rg text-dark-6">
              {t('Return Order ID')}: {returnOrderDetail?.return_order_id || '-'}
                &nbsp;
              <span>
                {returnOrderDetail?.order_alias ? `(${returnOrderDetail.order_alias})` : null}
              </span>
            </div>
            {
              returnOrderDetail?.status ? <CustomLabel title={returnOrderDetail.status} className="ms-12px return-order-details-status"/> : null
            }
          </div>
        </div>
      </div>
      <ModalBody className="overflow-auto m-0 p-24px">
        <>
          {
            (loading.getReturnOrderByAwbNumber || loading.getReturnOrderDetail)
              ? <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ height: '300px' }}
              >
                <ComponentSpinner />
              </div>
              :  returnOrderDetail
                ? 
                <div className='d-flex gap-10px flex-column'>
                  <Card className="bg-white w-100 rounded-4 details-card mb-16px">
                    <div className="card-header py-12px px-16px rounded-top-4 text-secondary txt-h3-md">
                      <span>{t('Basic Details')}</span>
                    </div>
                    <div className="basic-details-row-wrapper">
                      <OrderDetailsRow entityNameColSpan={4} entityValueColSpan={8} entityName='Order ID' entityValue={returnOrderDetail.seller_sales_channel_order_id || '-'} />
                      <OrderDetailsRow entityNameColSpan={4} entityValueColSpan={8} entityName='AWB Number' entityValue={returnOrderDetail.shipment?.awb_number || '-'} />
                      <OrderDetailsRow entityNameColSpan={4} entityValueColSpan={8} entityName='Total SKU(s)' entityValue={returnOrderDetail.total_sku_count || '-'} />
                      <OrderDetailsRow entityNameColSpan={4} entityValueColSpan={8} entityName='Total Return Qty' entityValue={returnOrderDetail.total_return_quantity || '-'} />
                      <OrderDetailsRow entityNameColSpan={4} entityValueColSpan={8} entityName='Total Amount' entityValue={`${returnOrderDetail.total.toFixed(2)} ${returnOrderDetail.invoice.currency}` || '-'} />
                      <OrderDetailsRow entityNameColSpan={4} entityValueColSpan={8} entityName='Hub' entityValue={returnOrderDetail.hub.name || '-'} />
                      {tenantType !== TENANT_TYPE.SELF_USAGE && <OrderDetailsRow entityNameColSpan={4} entityValueColSpan={8} entityName='Seller Name' entityValue={returnOrderDetail.seller_name || '-'} />}
                    </div>
                  </Card>
                  <CreateReturnOrderProcessingTable key={`is-next-button-pressed-${isNextButtonPressed}`} returnOrderDetail={returnOrderDetail} skuTableData={skuTableData} setSkuTableData={setSkuTableData} isNextButtonPressed={isNextButtonPressed}/>
                  {isNextButtonPressed && <div className="return-pass-fail-summary-container mb-5">
                    <div className="return-pass-fail-summary-container-row">
                      <div className="key returned-quantity">{t('Total Returned Qty')}</div>
                      <div className="colon">:</div>
                      <div className="value returned-quantity">{quantitiesDetail?.return_quantity}</div>
                    </div>
                    <div className="return-pass-fail-summary-container-row">
                      <div className="key passed-quantity">{t('Total Passed Qty')}</div>
                      <div className="colon">:</div>
                      <div className="value passed-quantity">{quantitiesDetail?.pass_quantity}</div>
                    </div>
                    <div className="return-pass-fail-summary-container-row">
                      <div className="key failed-quantity">{t('Total Failed Qty')}</div>
                      <div className="colon">:</div>
                      <div className="value failed-quantity">{quantitiesDetail?.fail_quantity}</div>
                    </div>
                  </div>}
                  <SidesheetFooter>
                    {!isNextButtonPressed && <Button color="primary" outline={isNextButtonPressed} onClick={handleNextButtonPressedToggle}>{t('Mark QC Complete')}</Button>}
                    {isNextButtonPressed && <Button color="primary" loading={imageUploading || loading.completeReturnOrderProcessing}  onClick={handleCompleteCreateOrderProcessing}>{t('Done')}</Button>}
                  </SidesheetFooter>
                </div>
                : null
          }
        </>
      </ModalBody>
    </SideSheet>
  )
}
export default CreateReturnOrderProcessing
