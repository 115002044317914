import Button from '@src/@core/components/ui/button'
import InputField from '@src/@core/components/ui/input-field'
import PhoneNumberDropdown from '@src/@core/components/ui/phone-number'
import Select from '@src/@core/components/ui/select'
import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import { loadCountryConfig, loadStatesCitiesConfig, loadStatesConfig } from '@src/app.apis'
import { DEFAULT_COUNTRY_CODE } from '@src/App.constants'
import { getOptionsFetcher } from '@src/utility/Utils'
import { Countries } from '@src/views/hubs/utils/countries'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import {
  ModalBody,
  ModalHeader,
  Spinner
} from 'reactstrap'
import './addNewVirtualHubFormModal.scss'

const AddNewVirtualHubFormModal = (props) => {
  const { isVirtualHubFormModalOpen, resetVirtualHubFormModal, handleVirtualHubFormSubmit, virtualHubFromOpenFor, selectedPickUpLocation = {}, isEdit = false, isLoading = false} = props
  const loading = useSelector((store) => store.sales.loading)
  const loadCountryOptions = getOptionsFetcher(loadCountryConfig)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    register,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      virtual_hub_name: '',
      address_line1: '',
      address_line2: '',
      country: '',
      city: '',
      state: '',
      zip: '',
      phone:{
        countryCode: DEFAULT_COUNTRY_CODE,
        contactNumber: ''
      }
    },
    mode: 'onChange'
  })
  const loadStatesOptions = getOptionsFetcher(loadStatesConfig);
  const { t } = useTranslation()
  const handleModalDiscard = () => {
    resetVirtualHubFormModal()
    reset({
      virtual_hub_name: '',
      address_line1: '',
      address_line2: '',
      country: '',
      city: '',
      state: '',
      zip: '',
      phone:{
        countryCode: DEFAULT_COUNTRY_CODE,
        contactNumber: ''
      }
    })
  }
  

  useEffect(() => {
    if (isEdit) {
      const countryData = Countries.find(country => country.calling_code === selectedPickUpLocation.contact_details.country_calling_code)
      reset({
        'virtual_hub_name': selectedPickUpLocation.name,
        'address_line1': selectedPickUpLocation.address.address_line1,
        'address_line2': selectedPickUpLocation.address.address_line2,
        'country': { label: selectedPickUpLocation.address.country.name, value: selectedPickUpLocation.address.country.id, ...selectedPickUpLocation.address.country },
        'state': { label: selectedPickUpLocation.address.state.name, value: selectedPickUpLocation.address.state.id },
        'city': { label: selectedPickUpLocation.address.city.name, value: selectedPickUpLocation.address.city.id },
        'zip': selectedPickUpLocation.address.postal_code,
        'phone.contactNumber': selectedPickUpLocation.contact_details.phone_number,
        'phone.countryCode': countryData || '',
      })
    }
  }, [selectedPickUpLocation.id, isVirtualHubFormModalOpen])

  const loadStatesCitiesOptions = getOptionsFetcher(loadStatesCitiesConfig);
  return (
    <SideSheet modalClassName="modal-slide-in add-new-virtual-hub-form-modal" size="sm" isOpen={isVirtualHubFormModalOpen} toggle={handleModalDiscard}>
      <ModalHeader toggle={handleModalDiscard}>
        <h2>
          {t(`Create ${virtualHubFromOpenFor}`)}
        </h2>
      </ModalHeader>
      <PerfectScrollbar>
        <ModalBody className="modal-body ">
          <form>
            <div className="input-field-container">
              <InputField
                errors={errors}
                {...register('virtual_hub_name', { required: t('This field is required') })}
                isClearable
                label={t(`${virtualHubFromOpenFor} Name`)}
                isRequired
                width="100%"
              />
            </div>
            <div className="input-field-container">
              <InputField
                errors={errors}
                {...register('address_line1', { required: t('This field is required') })}
                isClearable
                label={t('Address Line 1')}
                isRequired
                width="100%"
              />
            </div>
            <div className="input-field-container">
              <InputField
                errors={errors}
                {...register('address_line2', { required: t('This field is required') })}
                isClearable
                label={t('Address Line 2')}
                isRequired
                width="100%"
              />
            </div>
            <div className="flex-input-wrapper">
              <div className="input-field-container">
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: t('Select a country')}}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isAsync
                      isRequired
                      defaultValue={watch('country')}
                      value={getValues('country')}
                      loadOptions={loadCountryOptions}
                      errors={errors}
                      isClearable
                      isDisabled={!!watch('destination_hub')}
                      label={t('Select Country')}
                      onChange={(e) => {
                        field.onChange(e)
                        if (watch('state') || watch('city')) {
                          setValue('state', null)
                          setValue('city', null)
                        }
                      }}
                      infoText={!errors.country ? 'Type at least 3 characters to search for a country' : null}
                      menuPlacement='auto'
                      captureMenuScroll={false}
                      width='263px'
                    />
                  )}
                />
              </div>
              <div className="input-field-container">
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: t('Select state/region')}}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isAsync
                      isRequired
                      isClearable
                      defaultValue={watch('state')}
                      value={getValues('state')}
                      loadOptions={loadStatesOptions}
                      errors={errors}
                      isDisabled={!watch('country') || watch('destination_hub')}
                      label={t('Select State/Region')}
                      onChange={(e) => {
                        field.onChange(e)
                        if (watch('city')) {
                          setValue('city', null)
                        }
                      }}
                      cacheUniqs={[getValues('country')]}
                      additional={{
                        country: getValues('country')
                      }}
                      menuPlacement='auto'
                      captureMenuScroll={false}
                      width='263px'
                    />
                  )}
                />
              </div>
            </div>

            <div className="flex-input-wrapper">
              <div className="input-field-container">
                <Controller
                  name="city"
                  control={control}
                  rules={{required: t('Select city')}}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isAsync
                      isRequired
                      isClearable
                      defaultValue={watch('city')}
                      value={getValues('city')}
                      loadOptions={loadStatesCitiesOptions}
                      errors={errors}
                      isDisabled={!watch('state') || watch('destination_hub')}
                      label={t('Select City')}
                      cacheUniqs={[getValues('state')]}
                      additional={{
                        state: watch('state')
                      }}
                      menuPlacement='auto'
                      width='263px'
                      captureMenuScroll={false}
                    />
                  )}
                />
              </div>
              <div className="input-field-container">
                <InputField
                  errors={errors}
                  {...register('zip', { required: t('This field is required') })}
                  isClearable
                  label={t('Zip Code')}
                  isRequired
                  disabled={!!watch('destination_hub')}
                />
              </div>
            </div>

            <div className="input-field-container">
              <PhoneNumberDropdown
                name='phone'
                control={control}
                errors={errors}
                register={register}
                isClearable
                label="Phone Number"
                dropdownWidth="140px"
                contactNumber={watch('phone.contactNumber')}
                countryCode={watch('phone.countryCode')}
                isRequired={true}
                setError={setError}
                clearErrors={clearErrors}
                captureMenuScroll={false}
              />
            </div>
          </form>
        </ModalBody>
      </PerfectScrollbar>
      <SidesheetFooter className='position-sticky bottom-0 gap-12px'>
        <Button
          type="button" ofStyle="outlined"
          onClick={handleModalDiscard}
        >
          {t('Discard')}
        </Button>
        <Button
          type="button"
          onClick={handleSubmit(handleVirtualHubFormSubmit)}
          disabled={isEdit ? isLoading : loading.addNewVirtualHub}
        >
          {isEdit ? isLoading : loading.addNewVirtualHub && (
            <Spinner size="sm" className="text-white" />
          )}
          {isEdit ? t('Update') : t('Create')}
        </Button>
      </SidesheetFooter>
    </SideSheet>
  )
}

export default AddNewVirtualHubFormModal
