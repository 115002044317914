/* eslint-disable @typescript-eslint/no-explicit-any */
import trackingImage from '@src/assets/images/orders/trackingOrder.svg';
import classNames from 'classnames';
import React from 'react';
import { Check } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const chunkArray = (array: any[], size: number) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

type iTrackingViewLogs = {
  logsDetails: any
  isPackageDetailView?: boolean
  status: string
}

const getPreviousIndex = (i: number, j: number, numCols: number) => {
  if (j > 0) {
    // Move left in the same row
    return [i, j - 1];
  } else if (i > 0) {
    // Move to the last column of the previous row
    return [i - 1, numCols - 1];
  } else {
    // At the very start of the array
    return []; // No previous index
  }
};

const getSecondLastElement = (logRowsList: any) => {
  const i = logRowsList.length - 1;
  const j = logRowsList[0].length - 1;
  return getPreviousIndex(i, j, 4);
};

const TrackingViewLogs: React.FC<iTrackingViewLogs> = ({ status, logsDetails }) => {
  const nextLocationIndex = logsDetails.findIndex((log: any) => log.is_next);
  const nextLocationRowIndex = Math.floor((nextLocationIndex - 1) / 4);
  const nextLocationColumnIndex = nextLocationIndex % 4;

  const isLMDSellerEnabled = useSelector((store: any) => store.auth.userData?.restricted_third_party_logistics);

  const logRowsList = chunkArray(logsDetails, 4);
  const { t } = useTranslation();

  const [rowPreviousToNextLoc, colPreviousToNextLoc] = getPreviousIndex(nextLocationRowIndex, nextLocationColumnIndex, 4);
  const [rowOfSecondLastElem, colOfSecondLastElem] = getSecondLastElement(logRowsList);

  const hasBlurLineWithNextLocation = (rowIndex: number, colIndex: number) => {
    if (status !== 'delivered') {
      return rowIndex === rowPreviousToNextLoc && colIndex === colPreviousToNextLoc;
    } else if (status === 'delivered') {
      return false;
    }
  };

  const hasBlurLineWithoutNextLocation = (rowIndex: number, colIndex: number) => {
    if (status !== 'delivered') {
      return rowIndex === rowOfSecondLastElem && colIndex === colOfSecondLastElem;
    }else if (status === 'delivered') {
      return false;
    }
  };

  const hasBlurCircleWithNextLocation = (rowIndex: number, colIndex: number) => {
    return rowIndex > rowPreviousToNextLoc || (rowIndex === rowPreviousToNextLoc && colIndex > colPreviousToNextLoc);
  };

  const hasBlurCircleWithoutNextLocation = (rowIndex: number, colIndex: number) => {
    if (status === 'delivered') {
      return false;
    } else {
      return rowIndex === logRowsList.length - 1 && colIndex === logRowsList[0].length -1;
    }
  };

  const blurCircle = 'bg-primary-lighter  border-primary-light not-visited-location';
  const blurLine = 'not-delivered';

  return (
    <div className={classNames('tracking-view-logs')}>
      {logRowsList.map((row: any, rowIndex: number) => (
        <div className={classNames('log-row')} key={rowIndex}>

          {row.map((log: any, colIndex: number) => (
            <div className='single-log txt-asst-rg' key={log.id}>
              <div
                className={classNames('log-icon border border-primary', {
                  [blurLine]: nextLocationIndex === -1 ? hasBlurLineWithoutNextLocation(rowIndex, colIndex) : hasBlurLineWithNextLocation(rowIndex, colIndex),
                  [blurCircle]: nextLocationIndex === -1 ? hasBlurCircleWithoutNextLocation(rowIndex, colIndex) : hasBlurCircleWithNextLocation(rowIndex, colIndex),
                })}
              >
                <Check size={16} color={rowIndex > rowPreviousToNextLoc || (rowIndex === rowPreviousToNextLoc && colIndex > colPreviousToNextLoc) ? 'transparent' : 'white'} />
              </div>
              <div className='text-dark'>{log.name}</div>
          
              {log.shipping_partner.name && !isLMDSellerEnabled  && <div>{t(`Picked by ${log.shipping_partner.name}`)}</div>}
          
              {log.awb_number && !isLMDSellerEnabled && (
                <div className='d-flex align-items-center gap-50'>
                  {log.tracking_url && (
                    <a href={log.tracking_url} target='_blank' rel='noreferrer'>
                      <img src={trackingImage} alt='tracking-image' />
                    </a>
                  )}
                  <span>{log.awb_number}</span>
                </div>
              )}
          
              {log.created_at && <div>{log.created_at}</div>}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TrackingViewLogs;
