/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExpandableCustomTable, externalTableStyles } from '@src/@core/components/ui/globalTable/ExpandableCustomTable';
import { useRTL } from '@src/utility/hooks/useRTL';
import SkuCard from '@src/views/catalog/components/skuCard';
import OrderSummary from '@src/views/shipping-aggregator/pages/create-shipment-order/components/order-summary/order-summary';
import { Order, iPackage } from '@src/views/shipping-aggregator/shipping-aggregator-types';
import { useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import BasicDetailsCard from './basic-details-card';
import OriginDetailsCard from './origin-details-card';
import ShippingDetailsCard from './shipping-details-card';

const OrderDetails = ({ orderDetails = {}, entityType }:any) => {
  const { t } = useTranslation();
  const [isRTL] = useRTL();
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  console.log(entityType);
  
  const orderColumns = useMemo(() => {
    return [
      {
        id: 1,
        name: <div className='d-flex w-100 align-items-center justify-content-center'>{t('S. No.')}</div>,
        key: 'sr.no',
        width: '70px',
        reorder: false,
        cell: (_: Order, ind: number) => <div className='d-flex w-100 align-items-center justify-content-center'>{ind + 1}</div>
      },
      {
        id: 2,
        name: t('Package'),
        key: 'package',
        minWidth: '230px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div>{row.display_package_id}</div>
          );
        },
      },
      {
        id: 3,
        name: t('Weight'),
        key: 'weight',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='flex flex-col gap-2 align-middle'>{row.package_info.weight?.value ? `${row.package_info.weight.value} ${row.package_info.weight.uom}` : '-'}</div>
          );
        },
      },
      {
        id: 4,
        name: <div className='w-100 text-end'>{t('Dimensions')}</div>,
        key: 'dimensions',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="w-100 text-end">
              {(row.package_info.length * row.package_info.width * row.package_info.height) > 0 ? (
                <div className="d-flex txt-sub-rg align-items-center w-100 justify-content-end gap-4px">
                  <span>{row.package_info.length}</span>
                  <X size={12} color="var(--bs-dark-5)"/>
                  <span>{row.package_info.width}</span>
                  <X size={12} color="var(--bs-dark-5)"/>
                  <span>{row.package_info.height}</span>
                </div>
              ) : '-'}
            </div>
          );
        },
      },
      {
        id: 5,
        minWidth: '150px',
        name: <div className='w-100 d-flex align-items-center justify-content-end'>{t('Due Amount')}</div>,
        key: 'due_amount',
        cell: (row: iPackage) => {
          return <div className='w-100 d-flex align-items-center justify-content-end'>
            {row.payment_info.total_due ? `${row.payment_info.total_due} ${row.payment_info.currency}` : '-'}
          </div>;
        }
      },
      {
        id: 6,
        minWidth: '150px',
        name: <div className='w-100 d-flex align-items-center justify-content-end'>{t('Package Value')}</div>,
        key: 'package_value',
        cell: (row: iPackage) => {
          return <div className='w-100 d-flex align-items-center justify-content-end'>
            {row.payment_info.total ? `${row.payment_info.total} ${row.payment_info.currency}` : '-'}
          </div>;
        },
      },
    ];
  }, [t]);
  
  const packagesColumns = useMemo(() => {
    return [
      {
        id: 21,
        name: <div className='d-flex w-100 align-items-center justify-content-center'>{t('S. No.')}</div>,
        key: 'sr.no',
        width: '70px',
        reorder: false,
        cell: (_: unknown, ind: number) => {
          return <div className='d-flex w-100 align-items-center justify-content-center'>{ind + 1}</div>;
        }
      },
      {
        id: 22,
        name: t('SKU Details'),
        key: 'sku_details',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage) => {
          return <SkuCard
            toolTipID={row.sku.id}
            skuName={row.sku.name}
            skuCode={row.sku.code}
            url={row.sku.images?.[0]?.default}
          />;
        },
      },
      {
        id: 23,
        name: <div className='w-100 d-flex align-items-center justify-content-end'>{t('Order Quantity')}</div>,
        key: 'order_quantity',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='w-100 d-flex align-items-center justify-content-end'>{row.quantity}</div>
          );
        },
      },
      {
        id: 24,
        name: t('Total Weight'),
        key: 'total_weight',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='flex flex-col gap-2 align-middle'>{row.weight?.value ?`${row.weight.value} ${row.weight.uom}` : '-'}</div>
          );
        },
      },
      {
        id: 25,
        name: <div className='w-100 d-flex align-items-center justify-content-end'>{t('Unit Price (Tax excl.)')}</div>,
        key: 'unit_price',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='w-100 d-flex align-items-center justify-content-end'>
              {row.unit_price ? `${row.unit_price} ${orderDetails.payment_info.currency}` : '-'}
            </div>
          );
        }
      },
      {
        id: 26,
        name: <div className='w-100 d-flex align-items-center justify-content-end'>{t('Tax (%)')}</div>,
        key: 'tax_percent',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='w-100 d-flex align-items-center justify-content-end'>
              {row.tax_percent}
            </div>
          );
        }
      },
      {
        id: 27,
        name: <div className='w-100 d-flex align-items-center justify-content-end'>{t('Tax Amount')}</div>,
        key: 'tax_amount',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='w-100 d-flex align-items-center justify-content-end'>
              {row.tax ? `${row.tax} ${orderDetails.payment_info.currency}` : '-'}
            </div>
          );
        }
      },
      {
        id: 28,
        name: <div className='w-100 d-flex align-items-center justify-content-end'>{t('Item Total')}</div>,
        key: 'item_total',
        minWidth: '200px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='w-100 d-flex align-items-center justify-content-end'>
              {row.total ? `${row.total} ${orderDetails.payment_info.currency}` : '-'}
            </div>
          );
        }
      }
    ];
  }, [t]);
  
  return (
    <div className="order-details px-24px pt-10px pb-24px">
      <div className='d-flex flex-column gap-16px'>
        <BasicDetailsCard orderDetails={orderDetails} entityType={entityType} />
        <div className='d-flex gap-16px'>
          <OriginDetailsCard orderDetails={orderDetails} entityType={entityType}/>
          <ShippingDetailsCard orderDetails={orderDetails} entityType={entityType}/>
        </div>
        <div className='order-details-table'>
          <ExpandableCustomTable
            // key={tableRerenderer}
            loading={false}
            success={true}
            error={false}
            columns={orderColumns}
            data={orderDetails.packages || []}
            meta={{}}
            useReactPaginate={false}
            showPagination={false}
            expandableRowExpanded={(row: Order) => expandedRows.includes(`${row.id}`)}
            expandableRows
            // @ts-expect-error fix
            handlePagination={() => { }}
            showColumnsTableHeader={false}
            onRowExpandToggled={(expanded: boolean, row: Order) => {
              console.log({expanded, row});
              setExpandedRows(p => {
                if (expanded) return [...p, `${row.id}`];
                else return p.filter(id => `${id}` !== `${row.id}`);
              });
            }}
            expandableIcon={{
              collapsed: (() => (<div className='d-flex width-100 justify-content-center'>
                <div className='rounded-4px border border-dark-5'>
                  <ChevronDown className='text-primary' size={18} />
                </div>
              </div>))(),
              expanded: (() => (<div className='d-flex width-100 justify-content-center'>
                <div className='rounded-4px border border-dark-5'>
                  <ChevronUp className='text-primary' size={18} />
                </div>
              </div>))()
            }}
            expandableRowDisabled={(row: {items: any[]}) => !row.items?.length}
            expandableRowsComponent={(row: {data: iPackage}) => {
              return <div className='inner-card-shadow position-relative nested-orders-table'>
                {
                  isRTL ? row.data.items?.map((pkg: iPackage, index: number) => (
                    <span key={pkg.id} className='side-lines' style={{
                      height: 80 + (index * 58)
                    }} >
                      <div className='rounded-circle'></div>
                    </span>
                  )) : row.data.items?.map((pkg: iPackage, index: number) => (
                    <span key={pkg.id} className='side-lines' style={{
                      height: 80 + (index * 58)
                    }} >
                      <div className='rounded-circle'></div>
                    </span>
                  ))
                }
                <div className='overflow-hidden package-container bg-dark-2'>
                  <ExpandableCustomTable
                    tableWrapperClassNames='package-table-wrapper'
                    showColumnsTableHeader={false}
                    showPagination={false}
                    striped={false}
                    showCustomHeader={false}
                    columns={packagesColumns}
                    loading={false}
                    error={false}
                    success={true}
                    data={row.data.items || []}
                    selectableRows={false}
                    meta={{}}
                    showFixedTableActionColumn={false}
                    expandableRowsComponent={undefined}
                    searchcolumnsList={undefined}
                    handleQueryParams={undefined}
                    search_column_query={undefined}
                    handleRefreshTable={undefined}
                    handleSort={undefined}
                    columnName={undefined}
                    defaultSortFieldId={undefined}
                    defaultSortAsc={undefined}
                    customStyles={{ ...externalTableStyles, rows: { style: { backgroundColor: '#FFFFFF', borderColor: '#EEEFF2' } }, cells: { style: { ...externalTableStyles.cells?.style, padding: '8px 16px' } }, headRow: { style: { background: '#F4F4F4', borderBottom: 'none', borderTop: 'none' } } }}
                    tableClassNames='package-table' />
                </div>
              </div>;
            }}
            customStyles={{
              expanderCell: {
                style: {
                  borderRight: '1px solid var(--bs-dark-2)'
                }
              },
            }}
          />
        </div>
        <div className='w-100 d-flex justify-content-end'>
          <div style={{ width: '360px' }}>
            <OrderSummary
              subTotal={orderDetails.payment_info.subtotal}
              totalTaxes={orderDetails.payment_info.tax}
              totalAmount={orderDetails.payment_info.total}
              currency={orderDetails.payment_info.currency}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;