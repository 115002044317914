import Button from '@src/@core/components/ui/button';
import InputField from '@src/@core/components/ui/input-field';
import { INPUT_TYPES } from '@src/App.constants';
import EditIcon from '@src/assets/images/icons/EditIcon';
import React, { useState } from 'react';
import { Info, Trash2, X } from 'react-feather';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { MARK_AS_REFUND_FORM_FIELD_NAMES } from '../../returns.constants';
import { iMarkAsRefundFormValues } from '../../returns.type';

type iRefundAdjustmentProps = {
  markAsRefundForm: UseFormReturn<iMarkAsRefundFormValues>
  currency: string
  itemsTotal: number
  totalAdditionalCharges: number
}

const RefundAdjustment: React.FC<iRefundAdjustmentProps> = (props) => {
  const { markAsRefundForm, itemsTotal, totalAdditionalCharges, currency } = props;
  
  const {
    setValue,
    watch,
    formState: { errors },
  } = markAsRefundForm;
  const { t } = useTranslation();

  const [refundAdjustmentAmount,setRefundAdjustmentAmount]= useState<string>(watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT));

  const toggleRefundAdjustment = (status: boolean) => {
    setValue(MARK_AS_REFUND_FORM_FIELD_NAMES.IS_REFUND_ADJUSTMENT_EDIT_MODE, status);
    setRefundAdjustmentAmount(watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT));
  };

  const handleDelete = () => {
    setValue(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT, '');
    setValue(MARK_AS_REFUND_FORM_FIELD_NAMES.IS_REFUND_ADJUSTMENT_EDIT_MODE, false);
  };

  const handleApply = () => {
    // If the user types '', '+', or '-', consider the values as 0.
    const numericRefundAmount = isNaN(+refundAdjustmentAmount) ? 0 : +refundAdjustmentAmount;
    const roundedRefundAmount = numericRefundAmount.toFixed(2); 
    setValue(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT, roundedRefundAmount);
    setValue(MARK_AS_REFUND_FORM_FIELD_NAMES.IS_REFUND_ADJUSTMENT_EDIT_MODE, false);
  };

  const handleRefundOnChange = (newValue: string) => {
    // allow values with + and - symbols and up to 2 decimal places
    const regex = /^[+-]?\d*(\.\d{0,2})?$/;
  
    if (!regex.test(newValue)) {
      return;
    }
  
    if (newValue === '' || newValue === '+' || newValue === '-') {
      setRefundAdjustmentAmount(newValue);
      return;
    }
  
    const numericValue = +newValue;
  
    // handling of negative value
    if (numericValue < 0 && Math.abs(numericValue) > itemsTotal - totalAdditionalCharges) {
      return;
    }

    setRefundAdjustmentAmount(newValue);
  };

  return (
    <>
      {watch(MARK_AS_REFUND_FORM_FIELD_NAMES.IS_REFUND_ADJUSTMENT_EDIT_MODE) ? (
        <div className='d-flex flex-column gap-16px bg-light-2 p-16px rounded-8px'>
          <div className='d-flex flex-center-between txt-h3-rg text-dark'>
            <div className='flex-center-start gap-6px text-dark-5'>
              <span>{t('Refund Adjustment')}</span>
              <Info size={16} id='refund_adjustment' className='cursor-pointer' color='var(--bs-dark-4)' />
              <UncontrolledTooltip target="refund_adjustment" offset={[0, 5]}  popperClassName='width-250' innerClassName='mw-100'>
                {'Adjust the refund amount by adding extra charges or deducting fees.'}
              </UncontrolledTooltip>
            </div>
            <span
              className='cursor-pointer'
              onClick={() => {
                toggleRefundAdjustment(false);
              }}
            >
              <X size={20} />
            </span>
          </div>
          <div className='d-flex gap-16px h-40px'>
            <div className='w-100'>
              <InputField
                /* @ts-expect-error fix */
                label={`Amount (${currency})`}
                value={refundAdjustmentAmount}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                isClearable
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRefundOnChange(e.target.value)}
              />
            </div>
            <div className='flex-center-between gap-16px rounded-8px'>
              <Button ofStyle='outlined' type='button' onClick={handleApply}>
                {t('Apply')}
              </Button>
              {watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT) && (
                <div className='flex-center-center border border-danger p-10px rounded-8px cursor-pointer' onClick={handleDelete}>
                  <Trash2 size={20} color='var(--bs-danger)' />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='text-dark flex-center-between cursor-pointer'>
          <div className='flex-center-start gap-8px'>
            <span className='txt-h3-rg text-dark-5'>{t('Refund Adjustment')}</span>
            {watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT) ? (
              <span
                className='cursor-pointer edit-button txt-sub-md bg-primary-global'
                onClick={() => {
                  toggleRefundAdjustment(true);
                }}
              >
                {EditIcon({ width: 16, height: 16 })}
              </span>
            ) : null}
          </div>

          {watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT) ? (
            <div className='txt-h3-rg'>
              {+watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT) > 0 
                ? `${watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT)}`
                : watch(MARK_AS_REFUND_FORM_FIELD_NAMES.REFUND_ADJUSTMENT_AMOUNT)} 
              {' '}{currency}
            </div>
          
          ) : (
            <div
              className='txt-h3-rg text-primary'
              onClick={() => {
                toggleRefundAdjustment(true);
              }}
            >
              {t('Apply')}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RefundAdjustment;
