import { BANNER_VARIANTS } from '@src/App.constants';
import ErrorFilled from '@src/assets/images/icons/errorFilled';
import InfoFilled from '@src/assets/images/icons/Infofilled';
import Settings from '@src/assets/images/icons/primary-navbar/Settings';
import Tick from '@src/assets/images/icons/tick';
import React from 'react';
import Warning from './Warning';

interface IconProps {
  variant: keyof typeof BANNER_VARIANTS;
  size?: number;
}

const Icons: React.FC<IconProps> = ({ variant, size = 18 }) => {
  const iconMap = {
    [BANNER_VARIANTS.DANGER]: <ErrorFilled size={size} />,  
    [BANNER_VARIANTS.SUCCESS]: <Tick fillColor='var(--bs-success)'/>,      
    [BANNER_VARIANTS.WARNING]: <Warning/>,    
    [BANNER_VARIANTS.INFO]: <InfoFilled fillColor='var(--bs-primary)'/>,   
    [BANNER_VARIANTS.NEUTRAL]: <Settings iswhite={false} fillColor='var(--bs-purple)' height={20} width={20} />
  } as Record<IconProps['variant'], React.ReactNode>;

  return iconMap[variant];
};

export default Icons;
