import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import { UncontrolledTooltip } from 'reactstrap'
import './style.scss'


const SkuCard = ({
  toolTipID,
  url,
  noImage = false,
  skuName,
  skuCode = '',
  skuTag = false
}) => {
  return (
    <div className="sku-card-container flex-center-between gap-8px flex-grow-1">
      <div className="flex-center-start gap-10px">
        {!noImage && <div className="border border-dark-2 rounded-2 flex-center-center max-height-40px max-width-40px overflow-hidden">
          <img
            src={url || no_sku_image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_sku_image
            }}
            loading="lazy"
            width={40}
            height={40}
            alt="no img"
            className="align-self-center border border-dark-2 rounded-2 bg-white"
          />
        </div>}
        <div>
          <div
            id={`skuNameTooltip-${toolTipID}`}
            className="truncated-text text-dark txt-sub-rg width-fit-content"
          >
            {skuName}
          </div>
          <UncontrolledTooltip innerClassName="text-start" placement="bottom" target={`skuNameTooltip-${toolTipID}`}>
            {skuName}
          </UncontrolledTooltip>
          <div className="txt-asst-rg text-dark-6">{skuCode}</div>
        </div>
      </div>
      <div>
        {skuTag}
      </div>
    </div>
  )
}

export default SkuCard