//@ts-expect-error fix
import noSkuImage from '@src/assets/images/omniful/no_sku_image.svg';
import classNames from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { components, OptionProps } from 'react-select';
import { UncontrolledTooltip } from 'reactstrap';

interface iSkuDataProps extends OptionProps {
  data:{
    id: string;
    name: string;
    sku_code: string,
    image?: HTMLImageElement,
    selling_price?: number,
    currency?:string,
    disabled?:boolean,
  }
  tooltipText?:string,
  tooltipPlacement?:string
  tooltipPopperClassNames?:string
}

const SkuDetailsOption = (props:iSkuDataProps) => {
  const { Option } = components;
  const {data, tooltipText, tooltipPlacement, tooltipPopperClassNames} = props;
  const { t } = useTranslation();
  const ref = useRef();

  const handleImageError = (currentTarget:HTMLImageElement) => {
    currentTarget.onerror = null;
    currentTarget.src = noSkuImage;
  };

  return (
    //@ts-expect-error fix
    <Option key={data.id} {...props} innerRef={ref}>
      <div className={classNames('flex-center-start gap-10px', {
        'opacity-50': data.disabled
      })} id={`sku_details${data.id}_unique_id`}>
        <div className="border border-dark-2 rounded-2 flex-center-center max-height-64px max-width-64px overflow-hidden bg-white">
          <img
            onError={({ currentTarget }) => handleImageError(currentTarget)}
            src={data.image || noSkuImage}
            alt={`${data.sku_code}-image`}
            className="h-full w-full object-fit-fill"
            width="64"
            height="64"
            loading="lazy"
          />
        </div>
        <div className='d-flex flex-column gap-6px width-80-per'>
          <div className='txt-body-md text-dark text-truncate' title={data.name}>{data.name}</div>
          <div className='txt-asst-rg text-dark-6 text-truncate' title={data.sku_code}>
            <span>{t('SKU Code:')}</span> <span className='txt-asst-md text-dark'>{data.sku_code}</span>
          </div>
          {data.selling_price ? <div className='txt-asst-rg text-dark-6 text-truncate'>
            <span>{t('Selling Price:')}</span> <span className='txt-asst-md text-dark' title={`${data.selling_price} ${data.currency}`}>
              {data.selling_price}{' '}{data.currency}</span>
          </div> : null}
        </div>
      </div>

      {/**@ts-expect-error fix */}
      {data.disabled && tooltipText ? <UncontrolledTooltip container={ref?.current} popperClassName={tooltipPopperClassNames} placement={tooltipPlacement || 'top'}  offset={[0, 5]} target={`sku_details${data.id}_unique_id`}>
        {t(tooltipText)}
      </UncontrolledTooltip> : null
      }
    </Option>
  );
};

export default SkuDetailsOption;