/*eslint-disable no-undef*/

import { init as SentryInit, browserTracingIntegration, createReduxEnhancer, httpClientIntegration, reactRouterV6BrowserTracingIntegration } from '@sentry/react'
import { ENVIRONMENT } from '@src/App.constants'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

const environmentMap = {
  [ENVIRONMENT.DEVELOPMENT]: 'Development',
  [ENVIRONMENT.STAGING]: 'Staging',
  [ENVIRONMENT.PRODUCTION]: 'Production'
}

export default class SentryUtility {
  static ERROR_PATTERNS = {
    INVALIDATED_IMPORTS: [
      '\'text/html\' is not a valid JavaScript MIME type',
      'Failed to fetch',
      'Failed to fetch dynamically imported module',
      'Load failed',
      'Importing a module script failed',
      'Failed to load module script: Expected a JavaScript module script but the server responded with a MIME type of "text/html"',
      'Error loading dynamically imported module'
    ],
    IGNORABLE: [
      'HTTP Client Error with status code: 400',
      'Failed to execute \'removeChild\' on \'Node\': The node to be removed is not a child of this node',
      'Failed to execute \'insertBefore\' on \'Node\': The node before which the new node is to be inserted is not a child of this node',
      'Non-error promise rejection captured with value: Object not found matching ID:',
      'Unable to preload CSS for /assets/',
      'The object can not be found here',
      'Object captured as promise rejection with keys: code, data, message',
    ],
    WARNING: [
      'The operation is insecure'
    ]
  }

  static checkErrorType(errorMessage) {
    const lowerMessage = errorMessage.toLowerCase()
    
    return {
      isImportInvalidationError: SentryUtility.ERROR_PATTERNS.INVALIDATED_IMPORTS.some(pattern => 
        lowerMessage.includes(pattern.toLowerCase())
      ),
      isIgnorableError: SentryUtility.ERROR_PATTERNS.IGNORABLE.some(pattern => 
        lowerMessage.includes(pattern.toLowerCase())
      ),
      isWarning: SentryUtility.ERROR_PATTERNS.WARNING.some(pattern => 
        lowerMessage.includes(pattern.toLowerCase())
      )
    }
  }

  static handleError(error, event) {
    console.log('🚀 ~ SentryUtility ~ handleError ~ error, event:', error, event)
    
    if (!error?.message) return event

    const { isWarning } = SentryUtility.checkErrorType(error.message)

    if (isWarning) {
      event.level = 'warning'
    }

    return event
  }

  static init() {
    SentryInit({
      dsn: import.meta.env.VITE_SENTRY_DSN_KEY,
      environment: SentryUtility.getEnvironment(),
      tracesSampleRate: 0.1,
      normalizeDepth: 5,
      maxBreadcrumbs: 50,
      ignoreErrors: [
        ...SentryUtility.ERROR_PATTERNS.IGNORABLE,
        ...SentryUtility.ERROR_PATTERNS.INVALIDATED_IMPORTS
      ],
      beforeSend(event, hint) {
        console.log({ event, hint })
        return SentryUtility.handleError(hint.originalException, event)
      },
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
        browserTracingIntegration({}),
        httpClientIntegration({
          failedRequestStatusCodes: [400, 500]
        })
      ]
    })
  }

  static getEnvironment() {
    const env = import.meta.env.VITE_ENVIRONMENT || ENVIRONMENT.DEVELOPMENT
    
    return environmentMap[env]
  }

  static logger = (data) => data

  static sentryReduxEnhancer = createReduxEnhancer({})
}