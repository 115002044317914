import { useTranslation } from 'react-i18next'
import './customerDetails.scss'
import { useContext } from 'react'
import { AbilityContext } from '@src/utility/context/Can'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'

const CustomerAddressCard = ({address, editCustomerAddress}) => {
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const RenderAddress = () => {
    const parts = [
      address.address1,
      address.address2,
      address.city?.name,
      address.zip,
      address.state?.name,
      address.country?.name
    ]
  
    const addressData = parts.filter(Boolean).join(', ')
    return <>{addressData}</>
  }
  return (
    <div className="addresses">
      <div className="address-detail">
        <div className="customer-name me-1">
          <span className="me-1">{address.first_name} {address.last_name}</span>
          {address.phone_number}
        </div>
        <div className="address">
          <RenderAddress address={address} />
        </div>
      </div>
      {ability.can(
        abilityMap.customer.edit_address.action,
        abilityMap.customer.edit_address.resource
      ) && <button
        type="button"
        onClick={(e) => editCustomerAddress(e, address.id)}
      >
        {t('Edit')}
      </button>}
    </div>
  )
}

export default CustomerAddressCard