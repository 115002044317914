import { yupResolver } from '@hookform/resolvers/yup'
import BarcodeScanner from '@src/@core/components/ui/barcodeScanner'
import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import { KEYBOARD_KEYS } from '@src/App.constants'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import { GRN_TYPES } from '@src/views/inventory/inventory.constants'
import {
  clearSkuDetailInBin,
  clearSkuQtyUpdated,
  getBinStatus,
  getSkuDetailInGrnBin,
  inventoryActions,
  updateSkuQtyInGrnBin
} from '@src/views/inventory/store'
import classNames from 'classnames'
import { memo, useEffect, useRef, useState } from 'react'
import { X } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Modal, ModalBody, Spinner } from 'reactstrap'
import * as yup from 'yup'
import { BIN_TYPE } from '../../constant'

const UpdateItemQty = ({ isOpen, toggle, grnResponse, grnType = GRN_TYPES.STANDARD }) => {
  const { setBinStatus } = inventoryActions
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // ** States
  const [skuDetail, setSkuDetail] = useState(null)

  const sourceBinBarcodeRef = useRef()

  const bin_status = useSelector((store) => store.inventory.grn.bin_status)
  const selectedGlobalHubId = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )
  const loading = useSelector((store) => store.inventory.loadingState)
  const skuDetailInBin = useSelector(
    (store) => store.inventory.grn.skuDetailInBin
  )
  const singleReturnGrnOrderDetail = useSelector((state) => state.returns.singleReturnGrnOrderDetail)

  //      const passBinMatched = scannedBarcode && bin_status === 'Pass'
  //   const failBinMatched = scannedBarcode && bin_status === 'Fail'

  const UpdateBinQtySchema = yup.object().shape({
    source_bin: yup.string().required(t('This field is required')),
    destination_bin: yup.string().required(t('This field is required'))
  })
  const {
    control,
    //   setValue,
    //   clearErrors,
    // handleSubmit,
    //   register,
    watch,
    getValues,
    reset
    //   resetField,
    //   formState: { errors }
  } = useForm({
    defaultValues: {
      source_bin_barcode: '',
      sku_barcode: '',
      fail_reason: null
      // phone: "",
      // employee_id: "",
      // role_ids: [],
      // all_hubs: false,
      // hub_ids: [],
      // all_sellers: tenantType === TENANT_TYPE.SELF_USAGE,
      // seller_ids: [],
      // is_active: false,
      // timezone: ""
    },
    mode: 'onChange',
    resolver: yupResolver(UpdateBinQtySchema)
  })

  const maxQty = skuDetail?.failedReasons?.find(
    (reason) => reason.value === watch('fail_reason')?.value
  )?.qty

  useEffect(() => {
    if (skuDetailInBin) {
      const failedReasons = skuDetailInBin?.reasons?.map((reason) => ({
        value: reason.id,
        label: reason.title,
        qty: reason.quantity
      }))
      setSkuDetail({
        qty: skuDetailInBin.is_serialised ? 0 : skuDetailInBin?.quantity,
        seller_sku_id: skuDetailInBin?.sku?.seller_sku_id,
        name: skuDetailInBin.sku.name,
        seller_sku_code: skuDetailInBin.sku.seller_sku_code,
        img: skuDetailInBin?.sku?.images?.[0]?.url,
        is_serialised: skuDetailInBin.is_serialised,
        bin_qc_type: skuDetailInBin.bin_qc_type,
        failedReasons
      })
    }
  }, [skuDetailInBin])

  const hanleEnterBinBarcode = (e) => {
    if (e.code === KEYBOARD_KEYS.ENTER) {
      if (e.target.value === '') {
        CustomToast(('Please enter a barcode'), { my_type: 'error', audioRequired: false })
        return
      }
      dispatch(
        getBinStatus({
          hub_id: selectedGlobalHubId,
          grn_id: grnResponse.id,
          bin_barcode: e.target.value.trim(),
          source: 'grn',
          errorConfig: {
            audioRequired: true
          }
        })
      )
    }
  }
  const hanleEnterSkuBarcode = (e) => {
    if (e.code === KEYBOARD_KEYS.ENTER) {
      if (e.target.value === '') {
        CustomToast(('Please enter a barcode'), { my_type: 'error', audioRequired: false })
        return
      }

      const rawBarcode = e.target.value.trim();
      let decodedBarcode;

      try {
        decodedBarcode = decodeURIComponent(rawBarcode);
      } catch (error) {
        // can put a cutom toast to show user that barcode format is invalid but need to confirm with PM
        console.log(error.message, '- Invalid Barcode Format')
        return;
      }

      dispatch(
        getSkuDetailInGrnBin({
          grn_id: grnResponse.id,
          params: {
            bin_barcode: watch('source_bin_barcode'),
            sku_barcode: decodedBarcode,
            ...(grnType === GRN_TYPES.RETURN && { return_order_id: singleReturnGrnOrderDetail.id })
          },
          errorConfig: {
            audioRequired: true
          }
        })
      )
    }
  }

  const increaseQuantity = () => {
    setSkuDetail((prev) => ({ ...prev, qty: prev.qty + 1 }))
  }
  const decreaseQuantity = () => {
    setSkuDetail((prev) => ({ ...prev, qty: prev.qty - 1 }))
  }
  const handleQtyChange = (e) => {
    const number = e.target.value.trim()
    let value = +number
    if (isNaN(value) || value < 0) {
      value = 0
    }
    if (value >= skuDetailInBin?.quantity) {
      setSkuDetail((prev) => ({ ...prev, qty: skuDetailInBin?.quantity }))
    } else {
      setSkuDetail((prev) => ({ ...prev, qty: value }))
    }
  }

  const handleUpdateSkuQty = () => {
    const body = {
      sku_barcode: skuDetail?.is_gtin_enabled ? null : getValues('sku_barcode').trim(),
      bin_barcode: getValues('source_bin_barcode').trim(),
      seller_sku_id: skuDetail.seller_sku_id,
      quantity: skuDetail.qty,
      reason_id: getValues('fail_reason')?.value
      
    }
    if (grnType === GRN_TYPES.RETURN) {
      body.return_order_id = singleReturnGrnOrderDetail.id
    }
    dispatch(updateSkuQtyInGrnBin({ grn_id: grnResponse.id, body }))
  }

  const handleOnClosed = () => {
    dispatch(clearSkuQtyUpdated())
    dispatch(setBinStatus(''))
    reset()
    setSkuDetail(null)
    dispatch(clearSkuDetailInBin())
  }
  const handleFocusSourceBinBarcode = () => {
    if (sourceBinBarcodeRef.current) sourceBinBarcodeRef.current?.focus()
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={toggle}
      onOpened={handleFocusSourceBinBarcode}
      onClosed={handleOnClosed}
      modalClassName="rounded-12px"
    >
      <ModalBody className="d-flex flex-column gap-24px py-16px px-20px ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="txt-body-md text-dark">Update Item Quantity</div>
          <X onClick={toggle} size={16} className="text-dark cursor-pointer" />
        </div>
        <Form className="d-flex flex-column gap-1">
          <BarcodeScanner
            ref={sourceBinBarcodeRef}
            placeholder="Scan Source Bin Barcode"
            startIcon={
              <img
                src={
                  bin_status === BIN_TYPE.pass_bin.status
                    ? BIN_TYPE.pass_bin.bin_border_icon
                    : bin_status === BIN_TYPE.fail_bin.status
                      ? BIN_TYPE.fail_bin.bin_border_icon
                      : 'https://cdn-icons-png.flaticon.com/128/1550/1550324.png'
                }
                alt="QR Code"
                width="16px"
                height="16px"
              />
            }
            control={control}
            name="source_bin_barcode"
            hasValue={watch('source_bin_barcode')}
            // persistPlaceholder="Pass Bin - "
            onKeyDown={hanleEnterBinBarcode}
          />
          <BarcodeScanner
            placeholder="Scan SKU Barcode"
            startIcon={
              <img
                src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
                alt="QR Code"
                width="16px"
                height="16px"
              />
            }
            control={control}
            name="sku_barcode"
            hasValue={watch('sku_barcode')}
            // persistPlaceholder="Pass Bin - "
            disabled={watch('source_bin_barcode')?.length === 0}
            onKeyDown={hanleEnterSkuBarcode}
          />
          {bin_status === BIN_TYPE.fail_bin.status &&
            skuDetail &&
            !skuDetail?.is_serialised && (
            <div>
              <div className="txt-sub-md mb-1">
                  Select reason associated with the item you want to remove
              </div>
              <FloatingDropDown
                name="fail_reason"
                title="Select Reason"
                isRequired
                control={control}
                isClearable
                value={watch('fail_reason')}
                // defaultValue={watch("fail_reason")}
                onChangeFunc={(e) => setSkuDetail((prev) => ({ ...prev, qty: e?.qty }))
                }
                options={skuDetail?.failedReasons}
                // errors={errors}
              />
              {/* <Info size={15} /> */}
            </div>
          )}
          {skuDetail &&
            (!skuDetail.is_serialised ? (bin_status === BIN_TYPE.fail_bin.status ? watch('fail_reason')?.qty : true) : true) && (
            <div className="d-flex align-items-center justify-content-between border border-dark-2 rounded p-50">
              <div className="d-flex align-items-center gap-50">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = no_sku_image
                  }}
                  src={skuDetail.img || no_sku_image}
                  height={64}
                  width={64}
                />
                <div>
                  <div className="txt-body-md text-dark">
                    {skuDetail.name}
                  </div>
                  <div className="txt-asst-rg text-dark-6">
                    {skuDetail.seller_sku_code}
                  </div>
                </div>
              </div>

              {!skuDetail.is_serialised && (
                <QuantityButton
                  decreaseQuantity={decreaseQuantity}
                  increaseQuantity={increaseQuantity}
                  quantity={skuDetail.qty}
                  onChange={handleQtyChange}
                  minQuantityEnabled={skuDetail.qty <= 0}
                  maxQuantityEnabled={
                    maxQty
                      ? skuDetail.qty === maxQty
                      : skuDetail.qty === skuDetailInBin?.quantity
                  }
                />
              )}
            </div>
          )}
        </Form>
        <Button
          className="w-100 justify-content-center"
          ofStyle="outlined"
          //   color='primary'
          disabled={loading.updateSkuQtyInGrnBin || (maxQty
            ? skuDetail?.qty === maxQty
            : skuDetail?.qty === skuDetailInBin?.quantity)
          }
          onClick={handleUpdateSkuQty}
          type="button"
        >
          {loading.updateSkuQtyInGrnBin && <Spinner size="sm" />}
          <span
            className={classNames({ 'ms-50': loading.updateSkuQtyInGrnBin })}
          >
            {t('Update Quantity')}
          </span>
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default memo(UpdateItemQty)
