import Button from '@src/@core/components/ui/button'
import SideSheet from '@src/@core/components/ui/sideSheet'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { CUSTOMER_ADDRESS } from '@src/views/sales/constant/orders.constants'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ModalBody, ModalHeader } from 'reactstrap'
import '../style.scss'

const ChangeDeliveryAddress = ({
  openChangeAddressSidebar,
  setOpenChangeAddressSidebar,
  setOpenCreateAddressSidebar,
  selectedDeliveryAddress,
  setSelectedDeliveryAddress,
  selectedCustomer
}) => {
  const { t } = useTranslation()
  const loading = useSelector((store) => store.sales.loading)
  const customerAddresses = useSelector((store) => store.sales.customerAddresses)
  const [selectedAddress, setSelectedAddress] = useState(null)

  const selecteDeliveryAddress = (e, address) => {
    e.stopPropagation()
    setSelectedAddress(address)
  }

  const handleSidebarOpened = () => {
    setSelectedAddress(selectedDeliveryAddress)
  }

  const handleDoneButton = () => {
    setSelectedDeliveryAddress(selectedAddress)
    setOpenChangeAddressSidebar((prev) => ({ ...prev, open: false }))
  }
  const editCustomerAddress = (e, id) => {
    e.stopPropagation()
    setOpenCreateAddressSidebar((prev) => ({
      ...prev,
      open: true,
      type: CUSTOMER_ADDRESS.EDIT_ADDRESS.key,
      entity_id: selectedCustomer.id,
      address_id: id
    }))
  }

  const handleChangeAddressSidebar = () => {
    setOpenChangeAddressSidebar((prev) => ({ ...prev, open: false }))
  }

  const CustomerAddress = ({ address, selectAddress, selectedAddress }) => {
    return (
      <div className={classNames('addresses', { 'default-address': address.id === selectedAddress?.id })}
        onClick={(e) => selectAddress(e, address)}
      >
        <input
          type="radio"
          className="input-radio-overrides"
          checked={address.id === selectedAddress?.id}
        />
        <div className="address-detail">
          <div className="customer-name me-1">
            <span className="me-1">{address.first_name}</span>
            {address.phone_number}
          </div>
          <div className="address">{`${address?.address1 || ''} ${address?.address2 || ''
          } ${address?.city?.name || ''}, ${address?.zip || ''}, ${address?.state?.name || ''
          }, ${address?.country?.name || ''}`}</div>
        </div>
        <button
          type="button"
          onClick={(e) => editCustomerAddress(e, address.id)}
        >
          Edit
        </button>
      </div>
    )
  }

  const handleCreateNewAddress = () => {
    setOpenCreateAddressSidebar((prev) => ({
      ...prev,
      open: true,
      type: CUSTOMER_ADDRESS.CREATE_ADDRESS.key,
      entity_id: selectedCustomer.id
    }))
  }

  return (
    <SideSheet
      modalClassName="modal-slide-in sidebar-todo-modal change-shipment-order-delivery-address-sidebar"
      isOpen={openChangeAddressSidebar.open}
      toggle={handleChangeAddressSidebar}
      onOpened={handleSidebarOpened}
    >
      <ModalHeader toggle={handleChangeAddressSidebar}>
        {t('Change Address')}
      </ModalHeader>
      {(loading.getCustomerAddress || loading.editAddress) ? (
        <ComponentSpinner />
      ) : (
        <ModalBody className="modal-body-data">
          <div className="add-new-address my-1">
            <Button
              type="button"
              ofStyle="outlined"
              onClick={handleCreateNewAddress}
            >
              {t('Create Address')}
            </Button>
          </div>
          <div className="address-wrapper py-1">
            {customerAddresses?.length > 0 &&
              customerAddresses.map((address, index) => {
                return (
                  <CustomerAddress
                    address={address}
                    key={index}
                    selectAddress={selecteDeliveryAddress}
                    selectedAddress={selectedAddress}
                  />
                )
              })}
          </div>
          <div className="add-new-address my-1">
            <Button type="button" onClick={handleDoneButton}>
              {t('Done')}
            </Button>
          </div>
        </ModalBody>
      )}
    </SideSheet>
  )
}

export default ChangeDeliveryAddress
