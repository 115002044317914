/* eslint-disable @typescript-eslint/no-explicit-any */
// import CustomFilter from '@src/@core/components/ui/customTableFilterComponent';
// import { DROPDOWN_TYPES } from '@src/@core/components/ui/customTableFilterComponent/customTableFilterComponent.constant';
import DropdownWithTitle from '@src/@core/components/ui/dropdown';
import { ExpandableCustomTable, externalTableStyles } from '@src/@core/components/ui/globalTable/ExpandableCustomTable';
// import NewCheckbox from '@src/@core/components/ui/new-checkbox';
import CopyOnClick from '@src/@core/components/ui/click-and-copy';
import CustomDropdownOption from '@src/@core/components/ui/dropdown/customDropdownOption';
import PageHeader from '@src/@core/components/ui/page-header';
import { ADDITIONAL_FILTER_DROPDOWN_STYLE, TENANT_TYPE } from '@src/App.constants';
import Omniship from '@src/assets/images/icons/primary-navbar/Omniship';
// @ts-expect-error fix
import noCourierPartnerImage from '@src/assets/images/omniful/no_courier_partner_image.svg';
// @ts-expect-error fix
import trackingImage from '@src/assets/images/orders/trackingOrder.svg';
import { axiosInstance } from '@src/network/AxiosInstance';
import { useFilters, usePagination, useSearch } from '@src/utility/hooks/useFilters';
import { useRTL } from '@src/utility/hooks/useRTL';
import { getSellersAsync } from '@src/views/catalog/store';
import { FORWARD_SHIPMENT_STATUS_OPTIONS } from '@src/views/sales/shipments/shipments.constant';
// import { getOrderTagsAsync } from '@src/views/sales/store/store';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import { AbilityContext } from '@src/utility/context/Can';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, MoreVertical } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import OrderDetailsSidesheet from '../../components/order-details-sidesheet';
import PackageDetailsSideSheet from '../../components/order-details-sidesheet/order-details/package-details-sidesheet';
import { iPackage, Order } from '../../shipping-aggregator-types';
import { fetchAllShipment, fetchShippingPartners, SHIPPING_AGGREGATOR_API_KEYS } from '../../shipping-aggregator.apis';
import { SHIPMENT_ORDER_ENTITY } from '../../shipping-aggregator.constant';

const AllShipments: React.FC = () => {
  const pageHeaderProps: any = {
    breadcrumbIcon: Omniship,
    title: 'All Shipments',
    breadcrumbsData: [{ title: 'OmniShip' }]
  };
  const TableColumnsHeader = [
    { id: 'awb_number', name: 'AWB Number' },
  ];

  //state
  const [shipmentDetailsSideSheet, setShipmentDetailsSideSheet] =  useState<{ isOpen: boolean, id: string, defaultTabOpen?: number }>({ isOpen: false, id: '' });
  const [shipmentPackageDetails, setShipmentPackageDetails] = useState({ isOpen: false, data: null });
  const { searchQuery, setSearchQuery } = useSearch({ column: TableColumnsHeader[0] });
  const { pagination, setPagination } = usePagination();
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const tenantType = useSelector((state: any) => state.auth.userData.tenant.type);

  //hooks
  const { filter, setFilter } = useFilters({});
  const [isRTL] = useRTL();
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);

  const { data: getAllShipments, refetch: refetchAllShipments, isFetching: isFetchingAllShipments, isSuccess: isSuccessAllShipments, isError: isErrorAllShipments } = useQuery({
    queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_SHIPMENTS, pagination, filter, searchQuery],
    queryFn: () => {
      const formattedParams: [string, unknown][] = [];

      if (pagination?.page) {
        formattedParams.push(['page', pagination.page]);
        formattedParams.push(['per_page', pagination.per_page || 20]);
      }

      if (searchQuery?.query) { 
        formattedParams.push([searchQuery.column.id, searchQuery.query]);
      }

      for (const key in filter) { 
        if (typeof filter[key] === 'object' && Array.isArray(filter[key])) {
          formattedParams.push([key, (filter[key] as { value: string }[]).map(({ value }) => value)]);
        } else if (typeof filter[key] === 'object') {
          formattedParams.push([key, (filter[key] as { value: string }).value]);
        } else {
          formattedParams.push([key, filter[key]]);
        }
      } 
      const params = Object.fromEntries(formattedParams);
      return fetchAllShipment({params});
    },
    select: ({data}: any) => {
      const mappedData = data.data?.map((item: any, index: number) => ({
        ...item,
        serial_no: ((data.meta?.current_page - 1) * data.meta?.per_page) + (index + 1)
      }));
      return { ...data, mappedData};
    },
    enabled: true
  });

  const { mutate: fetchSingleShipmentAWB } = useMutation({
    mutationKey: ['fetch-single-shipment-awb'],
    mutationFn: ({ shipmentId}: {shipmentId: string | number}) => {
      return axiosInstance.get(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}/awb`);
    },
    onSuccess: ({ data }) => {
      window.open(data.data.awb_label, '_blank');
    }
  });

  const { mutate: markShipmentOperation } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.MARK_AS_DELIVERED],
    mutationFn: async ({ shipmentId, body}: {shipmentId: string | number, body: {status: string}}) => {
      const response = await axiosInstance.put(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}`, body);
      return {...response, type: body.status};
    },
    onSuccess: ({type}) => {
      refetchAllShipments();
      CustomToast(`Mark shipment as ${type} successfully`, { my_type: 'success' });
    }
  });

  // const { mutate: markShipmentAsDelivered } = useMutation({
  //   mutationKey: ['mark-single-shipment-as-delivered'],
  //   mutationFn: ({body, shipmentId}: {body: {status: string}, shipmentId: string | number}) => {
  //     return axiosInstance.put(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}`, body);
  //   },
  //   onSuccess: ({ data }) => {
  //     console.log({ data });
  //   }
  // });

  // checkbox handler
  // const handleSelectedShipmentsChange = (changedShipment: (Omit<Order, 'packages'> & { packages: iPackage[] }), checked: boolean) => {
  //   if (checked) {
  //     setSelectedOrderPackages(p => {
  //       const copiedStruct = { ...p };
  //       copiedStruct[changedShipment.id] = changedShipment.packages;
  //       return copiedStruct;
  //     });
  //   } else {
  //     setSelectedOrderPackages(p => {
  //       const copiedStruct = { ...p };
  //       delete copiedStruct[changedShipment.id];
  //       return copiedStruct;
  //     });
  //   }
  // };

  // const handleSelectedPackageChange = (changedPackage: (iPackage & { shipment_id: string }), checked: boolean) => {
  //   if (checked) {
  //     setSelectedOrderPackages(p => {
  //       const copiedStruct = { ...p };
  //       const targetedShipmentPackages = copiedStruct[changedPackage.shipment_id];
  //       if (!targetedShipmentPackages?.length) {
  //         copiedStruct[changedPackage.order_id] = [changedPackage];
  //         return copiedStruct;
  //       }
  //       copiedStruct[changedPackage.shipment_id] = [...copiedStruct[changedPackage.shipment_id], changedPackage];
  //       return copiedStruct;
  //     });
  //   } else {
  //     setSelectedOrderPackages(p => {
  //       const copiedStruct = { ...p };
  //       const targetedShipmentPackages = copiedStruct[changedPackage.shipment_id];
  //       if (targetedShipmentPackages?.length === 1) {
  //         // This should never be the case
  //         delete copiedStruct[changedPackage.shipment_id];
  //         return copiedStruct;
  //       }
  //       copiedStruct[changedPackage.shipment_id] = copiedStruct[changedPackage.shipment_id].filter((pkg) => `${pkg.id}` !== `${changedPackage.id}`);
  //       return copiedStruct;
  //     });
  //   }
  // };
  //handler

  // const getAllAppliedFilters = (appliedFilter: any) => {
  //   setFilter(appliedFilter);
  // };

  const filterHandler = ({ filterKey, filterValue }: any) => {
    if (!filterValue) {
      delete filter[filterKey];
      setFilter(filter);
      return;
    }
    setFilter({ ...filter, [filterKey]: filterValue });
  };

  const handleSearchItem = ({searchVal, selectedColumn = null}: any) => {
    const searchQuery = searchVal ? {column: selectedColumn, query: searchVal} : null;
    setSearchQuery(searchQuery);
  };

  const handlePagination = (page: {selected: number, per_page: number}) => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 20 };
    setPagination(pagination);

  };

  const handleOpenPackageSideSheet = (data: any) => () => {
    setShipmentPackageDetails({ isOpen: true, data });
  };

  // const ALL_ORDER_PAGE_FILTER_DATA = {
  //   tags: {
  //     filterKey: 'tags',
  //     displayName: t('Order Tags'),
  //     type: DROPDOWN_TYPES.MULTI_ASYNC_DROPDOWN,
  //     options: getOrderTagsAsync,
  //   },
  // };

  //columns
  const orderColumns = useMemo(() => {
    return [
      // {
      //   id: 1,
      //   omit: false,
      //   name: '',
      //   key: 'selectable',
      //   width: '60px',
      //   reorder: false,
      //   cell: (row: Order) => {
      //     const isSelected = selectedOrderPackages[row.id]?.length === row.packages?.length;
      //     const isPartiallyChecked = (selectedOrderPackages[row.id]?.length > 0 && selectedOrderPackages[row.id]?.length < row.packages?.length) ? true : false;
      //     return (
      //       /* @ts-expect-error fix */
      //       <NewCheckbox name='shipment-checkbox' id={`${row.id}`} partialChecked={isPartiallyChecked} checked={isPartiallyChecked || isSelected} onChange={(e: { target: { checked: boolean } }) => handleSelectedShipmentsChange(row, ((e.target as unknown as { checked: boolean }).checked))} />
      //     );
      //   },
      // },
      {
        id: 2,
        name: <div className='d-flex w-100 align-items-center justify-content-center'>{t('S. No.')}</div>,
        key: 'sr.no',
        width: '70px',
        reorder: false,
        cell: (row: {serial_no: string}) => <div className='d-flex w-100 align-items-center justify-content-center'>{row.serial_no}</div>
      },
      {
        id: 3,
        name: t('AWB Number'),
        key: 'awb_number',
        width: '250px',
        reorder: true,
        cell: (row: { awb_number: string, id: string, order_partner_order_id: string }) => {
          return (
            // @ts-expect-error fix
            <CopyOnClick
              id={row.id}
              handleDetails={() =>  setShipmentDetailsSideSheet({isOpen:true, id: row.id})}
              columnKey='seller_sales_channel_order_id'
            >{row.awb_number}</CopyOnClick>
          );
        },
      },
      {
        id: 4,
        name: t('Delivery Status'),
        key: 'delivery_status',
        width: '200px',
        reorder: true,
        cell: (row: {display_status: string}) => {
          return (
            <span>{row.display_status || '-'}</span>
          );
        },
      },
      {
        id: 5,
        name: t('Tracking Url'),
        key: 'tracking_url',
        width: '200px',
        reorder: true,
        cell: (row: { awb_number: string, tracking_url: string }) => (
          <div className="w-100 h-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-50">
              {row.tracking_url && <a href={row.tracking_url} target="_blank" rel="noreferrer"><img src={trackingImage} alt="tracking-image" /></a>}
              <span>{row.awb_number || '-'}</span>
            </div>
          </div>
        ),
      },
      {
        id: 6,
        minWidth: '200px',
        name: t('Shipping Partner'),
        key: 'shipping_partner',
        reorder: true,
        cell: (row: { shipping_partner: { name: string, logo: string }, shipping_partner_name: string }) => (
          <div className="d-flex align-items-center justify-content-center gap-50 w-100">
            {row.shipping_partner?.logo 
              ? <img
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = noCourierPartnerImage;
                }}
                src={row.shipping_partner.logo || noCourierPartnerImage}
                alt='courier-logo'
                height={50} width={50}
                className="object-fit-contain border rounded"
              />
              : <span>{row.shipping_partner?.name || '-'}</span>
            }
          </div>
        ),
      },
      // {
      //   id: 6,
      //   minWidth: '150px',
      //   name: t('Courier Partner'),
      //   key: 'courier_partner',
      //   reorder: true,
      //   cell: (row: { courier_partner: { name: string, logo: string } }) => (
      //     <div className="d-flex align-items-center justify-content-center gap-50 w-100">
      //       {row.courier_partner?.logo
      //         ? <img
      //           onError={({ currentTarget }) => {
      //             currentTarget.onerror = null;
      //             currentTarget.src = noCourierPartnerImage;
      //           }}
      //           src={row.courier_partner.logo || noCourierPartnerImage}
      //           alt='courier-logo'
      //           height={50} width={50}
      //           className="object-fit-contain border rounded"
      //         />
      //         : <span>{row.courier_partner?.name || '-'}</span>
      //       }
      //     </div>
      //   ),
      // },
      {
        id: 7,
        minWidth: '150px',
        name: t('No. Of Packages'),
        key: 'number_of_packages',
        reorder: true,
        cell: (row: { packages: any[], id:any }) => {
          return <div
            className='text-primary cursor-pointer'
            onClick={() => setShipmentDetailsSideSheet({ isOpen: true, id: row.id, defaultTabOpen: 1 })}
          >
            {row.packages?.length} {row.packages?.length > 1 ? 'Packages' : 'Package'}
          </div>;
        }
      },
      {
        id: 8,
        minWidth: '200px',
        name: t('Seller Name'),
        key: 'seller_name',
        reorder: true,
        cell: (row: { seller_name: string }) => {
          return <div className=''>
            {row.seller_name || '-'}
          </div>;
        }
      },
      {
        id: 9,
        minWidth: '250px',
        name: t('Pickup Address'),
        key: 'pickup_address',
        reorder: true,
        cell: (row: { pickup_details: { address: string }}) => {
          return <div className=''>
            {row.pickup_details?.address || '-'}
          </div>;
        }
      },
      {
        id: 10,
        minWidth: '200px',
        name: t('Pickup City'),
        key: 'pickup_city',
        reorder: true,
        cell: (row: { pickup_details: { city: string }}) => {
          return <div className=''>
            {row.pickup_details?.city || '-'}
          </div>;
        }
      },
      {
        id: 11,
        minWidth: '250px',
        name: t('Shipping Address'),
        key: 'shipping_address',
        reorder: true,
        cell: (row: { drop_details: { address: string }}) => {
          return <div className=''>
            {row.drop_details?.address || '-'}
          </div>;
        }
      },
      {
        id: 12,
        minWidth: '200px',
        name: t('Shipping City'),
        key: 'shipping_city',
        reorder: true,
        cell: (row: { drop_details: { city: string }}) => {
          return <div className=''>
            {row.drop_details?.city || '-'}
          </div>;
        }
      },
      {
        id: 14,
        minWidth: '150px',
        name: t('Payment'),
        key: 'shipping_city',
        reorder: true,
        cell: (row: {shipment_details: {display_payment_type: string}}) => {
          return <div className=''>{row.shipment_details.display_payment_type}</div>;
        }
      },
      {
        id: 16,
        minWidth: '250px',
        name: t('Created At'),
        key: 'created_at',
        reorder: true,
        cell: (row: { created_at: string }) => {
          return <span>{row.created_at || '-'}</span>;
        }
      },
      {
        id: 17,
        name: 'Action',
        width: '80px',
        key: 'actions',
        allowOverflow: true,
        selector: (row: { id: number }) => row,
        cell: (row: {id: number}) => {
          return (
            <div className="d-flex justify-content-center align-items-center w-100 cursor-pointer">
              <UncontrolledDropdown>
                <DropdownToggle className="pe-1 more_vert" tag="span">
                  <MoreVertical size={20} />
                </DropdownToggle>
                <DropdownMenu end container="body">

                  <DropdownItem className="w-100 text-dark" onClick={() => {
                    fetchSingleShipmentAWB({ shipmentId: row.id });
                  }}>
                    <span className="align-middle ms-50 txt-sub-rg">{t('Print AWB')}</span>
                  </DropdownItem>

                  { (ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  )) && <DropdownItem
                    className='w-100 text-dark'
                    onClick={() => markShipmentOperation({
                      shipmentId: row.id,
                      body: {
                        status: 'delivered'
                      }
                    })}
                  >
                    <span className="align-middle ms-50  txt-sub-rg">
                      {t('Mark as Delivered')}
                    </span>
                  </DropdownItem>
                  }

                  {(ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  )) && <DropdownItem
                    className='w-100 text-dark'
                    onClick={() => markShipmentOperation({
                      shipmentId: row.id,
                      body: {
                        status: 'shipped'
                      }
                    })}
                  >
                    <span className="align-middle ms-50 txt-sub-rg">
                      {t('Mark as Shipped')}
                    </span>
                  </DropdownItem>
                  }

                  {(ability.can(
                    abilityMap.edit_shipment_v2.edit.action,
                    abilityMap.edit_shipment_v2.edit.resource
                  )) && <DropdownItem
                    className='w-100 text-dark'
                    onClick={() => markShipmentOperation({
                      shipmentId: row.id,
                      body: {
                        status: 'cancelled'
                      }
                    })}
                  >
                    <span className="align-middle ms-50  txt-sub-rg">
                      {t('Cancel Shipment')}
                    </span>
                  </DropdownItem>
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          );
        }
      }
    ];
  }, [t]);

  const packagesColumns = useMemo(() => {
    return [
      // {
      //   id: 21,
      //   name: '',
      //   key: 'selectable-packages',
      //   width: '60px',
      //   reorder: false,
      //   omit: false,
      //   cell: (row: iPackage) => {
      //     // eslint-disable-next-line no-debugger
      //     const isSelected = selectedOrderPackages[row.order_id]?.findIndex(pkg => pkg.id === row.id) > -1;
      //     const isAtLeastOneShipmentSelected = Object.values(selectedOrderPackages)?.length > 0;
      //     console.log({isSelected});
      //     return (
      //       /* @ts-expect-error fix */
      //       <NewCheckbox name='shipment-checkbox' id={`${row.id}`} disabled={isAtLeastOneShipmentSelected && !selectedOrderPackages[row.order_id]} checked={isSelected} onChange={(e: { target: { checked: boolean } }) => handleSelectedPackageChange(row, ((e.target as unknown as { checked: boolean }).checked))} />
      //     );
      //   },
      // },
      {
        id: 22,
        name: <div className='d-flex w-100 align-items-center justify-content-center'>{t('S. No.')}</div>,
        key: 'sr.no',
        width: '70px',
        reorder: false,
        cell: (_: unknown, ind: number) => {
          return <div className='d-flex w-100 align-items-center justify-content-center'>{ind + 1}</div>;
        }
      },
      {
        id: 23,
        name: t('Package ID'),
        key: 'package_id',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div
              className='w-100 text-primary cursor-pointer'
              onClick={handleOpenPackageSideSheet(row)}
            >
              {row.display_package_id || '-'}
            </div>
          );
        },
      },
      // {
      //   id: 24,
      //   name: t('Package’s AWB Number'),
      //   key: 'package_identifier',
      //   minWidth: '250px',
      //   reorder: true,
      //   cell: (row: iPackage) => {
      //     return (
      //       <div className='group/copy flex w-full flex-col'>
      //         <div className={classNames('flex h-full w-full items-center text-clickable')}>
      //           <div className={classNames({ 'cursor-pointer': row.awb })} onClick={() => {
      //           }}>{row.awb}</div>
      //         </div>
      //       </div>
      //     );
      //   },
      // },
      {
        id: 25,
        name: t('Tracking Number'),
        key: 'tracking_number',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='w-100'>
              {row.tracking_number || '-'}
            </div>
          );
        },
      },
      {
        id: 35,
        name: t('Package Type'),
        key: 'package_type',
        minWidth: '200px',
        reorder: true,
        cell: () => {
          return (
            <div>{'Forward'}</div>
          );
        },
      },
      {
        id: 26,
        name: t('Package Status'),
        key: 'package_status',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.display_status || '-'}
            </div>
          );
        }
      },
      {
        id: 27,
        name: t('Current Location'),
        key: 'current_location',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.current_location_name || '-'}
            </div>
          );
        }
      },
      {
        id: 28,
        name: t('Next Location'),
        key: 'next_location',
        minWidth: '250px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className="flex w-full gap-[6px]">
              {row.next_location_name || '-'}
            </div>
          );
        }
      },
      // {
      //   id: 29,
      //   name: t('Courier Partner'),
      //   key: 'courier_partner',
      //   minWidth: '250px',
      //   reorder: true,
      //   cell: (row: iPackage) => {
      //     return (
      //       <div className="flex w-full gap-[6px]">
      //         {row.courier_partner || '-'}
      //       </div>
      //     );
      //   }
      // },
      {
        id: 30,
        name: t('Weight'),
        key: 'weight',
        minWidth: '180px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='flex items-center gap-2'>
              {row.package_info.weight?.value ? `${row.package_info.weight?.value} ${row.package_info.weight?.uom}` : '-'}
            </div>
          );
        },
      },
      {
        id: 31,
        name: t('Dimensions'),
        key: 'dimensions',
        minWidth: '180px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <div className='flex items-center gap-2'>
              {row.package_info?.height && row.package_info.width && row.package_info.length 
                ? `${row.package_info.length} x ${row.package_info.width} x ${row.package_info.height}` 
                : '-'}
            </div>
          );
        },
      },
      // {
      //   id: 32,
      //   name: t('Delivery Attempts'),
      //   key: 'delivery_attempts',
      //   minWidth: '200px',
      //   reorder: true,
      //   cell: (row: iPackage) => {
      //     return (
      //       <div className='flex items-center gap-2'>
      //         {row.delivery_attempts || '-'}
      //       </div>
      //     );
      //   },
      // },
      {
        id: 33,
        name: t('Created At'),
        key: 'created_at',
        minWidth: '240px',
        reorder: true,
        cell: (row: iPackage) => {
          return (
            <span>{row.created_at || '-'}</span>
          );
        }
      }
    ];
  }, [t]);

  return (
    <div className='all-shipments'>
      <PageHeader {...pageHeaderProps} />
      <div className='p-16px d-flex flex-column gap-16px'>
        <div className='flex-wrap d-flex gap-16px'>
          {/* <CustomFilter
            filterData={ALL_ORDER_PAGE_FILTER_DATA}
            defaultSelectedOption={filter}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            getAllAppliedFilters={getAllAppliedFilters}
          /> */}

          {tenantType === TENANT_TYPE.FULFILLMENT_CENTRE &&
          // @ts-expect-error fix
            <DropdownWithTitle
              isAsync
              isClearable={!!filter.seller_id}
              value={!filter.seller_id ? { label: 'All', value: '' } : filter.seller_id}
              title={'Sellers'}
              loadOptionsHandler={getSellersAsync}
              selectOptionHandler={(value: any) => filterHandler({ filterKey: 'seller_id', filterValue: value })}
              externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            />
          }
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          <DropdownWithTitle
            value={(() => {
              if (!filter.status) return {label: 'All', value: ''};
              return filter.status;
            })()}
            title="Delivery Status"
            isClearable={!!filter.status}
            options={FORWARD_SHIPMENT_STATUS_OPTIONS}
            selectOptionHandler={(value: any) => filterHandler({ filterKey:'status', filterValue: value })}
            defaultValue={{label: 'All', value: ''}}
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
          />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          <DropdownWithTitle
            isAsync
            isClearable={!!filter.shipping_partner_id}
            value={!filter.shipping_partner_id ? { label: 'All', value: '' }: filter.shipping_partner_id}
            title={'Shipping Partner'}
            loadOptionsHandler={fetchShippingPartners || getSellersAsync}
            selectOptionHandler={(value: any) => filterHandler({ filterKey: 'shipping_partner_id', filterValue: value })}
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            additionalComponents={{Option: CustomDropdownOption}}
          />
        </div>
        <ExpandableCustomTable
        // key={tableRerenderer}
          loading={isFetchingAllShipments}
          success={isSuccessAllShipments}
          error={isErrorAllShipments}
          columns={orderColumns}
          data={getAllShipments?.mappedData}
          meta={getAllShipments?.meta}
          useReactPaginate={false}
          isFilterOrSearchApplied={!!(Object.values(filter)?.length)}
          searchcolumnsList={TableColumnsHeader}
          search_column_query={searchQuery}
          handleQueryParams={handleSearchItem}
          handleRefreshTable={refetchAllShipments}
          // @ts-expect-error fix
          handlePagination={handlePagination}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // TableHeaderComponent={
          //   <>
          //     <Button icon={() => <Printer size={16}/>} ofStyle='outlined' onClick={() => {}}>{t('AWB')}</Button>
          //     <Button onClick={() => {}}>{t('Mark as Delivered')}</Button>
          //   </>
          // }
          expandableRowExpanded={(row: Order) => expandedRows.includes(`${row.id}`)}
          expandableRows
          onRowExpandToggled={(expanded: boolean, row: Order) => {
            console.log({expanded, row});
            setExpandedRows(p => {
              if (expanded) return [...p, `${row.id}`];
              else return p.filter(id => `${id}` !== `${row.id}`);
            });
          }}
          expandableIcon={{
            collapsed: (() => (<div className='d-flex width-100 justify-content-center'>
              <div className='rounded-4px border border-dark-5'>
                <ChevronDown className='text-primary' size={18} />
              </div>
            </div>))(),
            expanded: (() => (<div className='d-flex width-100 justify-content-center'>
              <div className='rounded-4px border border-dark-5'>
                <ChevronUp className='text-primary' size={18} />
              </div>
            </div>))()
          }}
          expandableRowsComponent={(row: {data: Order}) => {
            return <div className='inner-card-shadow position-relative nested-orders-table'>
              {
                isRTL ? row.data.packages?.map((pkg: iPackage, index: number) => (
                  <span key={pkg.id} className='side-lines' style={{
                    height: 70 + (index * 48)
                  }} >
                    <div className='rounded-circle'></div>
                  </span>
                )) : row.data.packages?.map((pkg: iPackage, index: number) => (
                  <span key={pkg.id} className='side-lines' style={{
                    height: 70 + (index * 48)
                  }} >
                    <div className='rounded-circle'></div>
                  </span>
                ))
              }
              <div className='overflow-hidden package-container bg-dark-2'>
                <ExpandableCustomTable
                  tableWrapperClassNames='package-table-wrapper'
                  showColumnsTableHeader={false}
                  showPagination={false}
                  striped={false}
                  showCustomHeader={false}
                  columns={packagesColumns}
                  loading={false}
                  error={false}
                  success={true}
                  data={row.data.packages || []}
                  selectableRows={false}
                  meta={{}}
                  showFixedTableActionColumn={false}
                  expandableRowsComponent={undefined}
                  searchcolumnsList={undefined}
                  handleQueryParams={undefined}
                  search_column_query={undefined}
                  handleRefreshTable={undefined}
                  handleSort={undefined}
                  columnName={undefined}
                  defaultSortFieldId={undefined}
                  defaultSortAsc={undefined}
                  customStyles={{ ...externalTableStyles, rows: { style: { backgroundColor: '#FFFFFF', borderColor: '#EEEFF2' } }, cells: { style: { ...externalTableStyles.cells?.style, padding: '8px 16px' } }, headRow: { style: { background: '#F4F4F4', borderBottom: 'none', borderTop: 'none' } } }}
                  tableClassNames='package-table' />
              </div>
            </div>;
          }}
          customStyles={{
            expanderCell: {
              style: {
                borderRight: '1px solid var(--bs-dark-2)'
              }
            },
          }}
        />
      </div>
      <OrderDetailsSidesheet
        isOpen={shipmentDetailsSideSheet.isOpen}
        toggleSideSheet={() => setShipmentDetailsSideSheet({isOpen:false, id:''})}
        entity={{id: shipmentDetailsSideSheet.id, key: SHIPMENT_ORDER_ENTITY.SHIPMENT_DETAILS}}
        defaultTabOpen={shipmentDetailsSideSheet.defaultTabOpen}
      />
      <PackageDetailsSideSheet
        isOpen={shipmentPackageDetails.isOpen}
        toggleSideSheet={() => setShipmentPackageDetails({ isOpen: false, data: null })}
        shipmentPackageDetails={shipmentPackageDetails.data}
      />
    </div>
  );
};

export default AllShipments;