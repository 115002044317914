/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject } from 'react';
import { iHandleFormReset } from './shipping-aggregator-types';
import { CREATE_ORDER_FIELDS_NAME, DEFAULT_VALUE_FOR_CREATE_ORDER_FIELDS, ORDER_DIMENSIONS_UNIT, PACKAGES_DEFAULT_VALUE } from './shipping-aggregator.constant';

export const GENERATE_REF_ID = () => {
  // Generate a random 10-digit number
  const uniqueNumber = Math.floor(1000000000 + Math.random() * 9000000000);

  // Return the formatted REF-ID
  return `REF-ID-${uniqueNumber}`;
};

// Utility function for smooth scrolling
export const scrollToElement = (ref: RefObject<HTMLElement>) => {
  if (ref && ref.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

// Function to group SKUs by their ID and associate them with their respective package details
export const groupSKUsByPackages = (packages: any[]) => {
  const skuInPackages: Record<string, any> = {};
  
  packages.forEach((pkg: any, pkgIndex: number) => {
    pkg.order_items.forEach((sku: any) => {
      const skuId = sku.sku.id;
  
      // Initialize entry if not exists
      if (!skuInPackages[skuId]) {
        skuInPackages[skuId] = {
          sku_details: sku, // Store details for the SKU
          packages: [],  
        };
      }
  
      // Add package details to the SKU
      skuInPackages[skuId].packages.push({
        name: `Package ${pkgIndex + 1}`,
        quantity: sku.quantity,
        unit_price: sku.selling_price,
        total: sku.total,
        tax: sku.tax,
        tax_percent: sku.tax_percent,
        ordered_weight: sku.ordered_weight
      });
    });
  });
  return skuInPackages;
};

// Function to convert SKU map object into an array format
export const convertSKUMapToArray = (skuPackageMap: Record<string, any>) => {
  return Object.keys(skuPackageMap).map((skuId: any) => ({ ...skuPackageMap[skuId], id: skuId }));
};

export const gatPackageDetails = ({ packages, order_weight_unit }: any) => {
  return packages.map((pkg: any) => {
    return {
      package_info: {
        length: parseFloat(pkg.length || 0),
        width: parseFloat(pkg.width || 0),
        height: parseFloat(pkg.height || 0),
        weight: {
          uom: order_weight_unit,
          value: parseFloat(pkg.weight || 0)
        },
      },
      payment_info: {
        total: parseFloat(pkg.packageValue || 0),
        total_due: parseFloat(pkg.dueAmount || 0),
      },
      items: pkg.order_items.map((item: any) => {
        return  {
          sku: item.sku,
          order_weight: item.ordered_weight,
          quantity: +item.quantity,
          unit_price: +item.selling_price,
          selling_price: +item.selling_price,
          tax_percent: +item.tax_percent,
          // discount_percent: '',
          total: +item.total,
        };
      })
    };
  });
};

export const distributeOrderWeightToPackages = ({formState}: any) => {
  const packageWeight = (formState.watch(CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT.value) / formState.watch(CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value));
  const formattedPackageWeight = Math.round(packageWeight * 100) / 100;
  formState.watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).forEach((_: unknown, index: number) => {
    if (+packageWeight) formState.setValue(`packages.${index}.weight`, formattedPackageWeight);
    formState.clearErrors(`packages.${index}.weight`);
  }
  );
};

export  const distributeOrderAmountToPackages = ({formState}: any) => {
  const packageValue = (formState.watch(CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value) / formState.watch(CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value));
  const formattedPackageValue = Math.round(packageValue * 100) / 100;
  formState.watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).forEach((_: unknown, index: number) => {
    if (+packageValue) formState.setValue(`packages.${index}.packageValue`, formattedPackageValue);
    formState.clearErrors(`packages.${index}.packageValue`);
  }
  );
};

export const allowOnlyPositiveInteger = (input: string) => {
  return input.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
};

export const handleFormReset = ({key, watch}: iHandleFormReset ) => {
  switch (key) {
  case CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value:
    return {
      ...DEFAULT_VALUE_FOR_CREATE_ORDER_FIELDS,
      [CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value]: watch(CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value),
      [CREATE_ORDER_FIELDS_NAME.SELLER.value]: watch(CREATE_ORDER_FIELDS_NAME.SELLER.value),
      [CREATE_ORDER_FIELDS_NAME.CURRENCY.value]: watch(CREATE_ORDER_FIELDS_NAME.CURRENCY.value)
    };
  case CREATE_ORDER_FIELDS_NAME.SELLER.value:
    return {
      ...DEFAULT_VALUE_FOR_CREATE_ORDER_FIELDS,
      [CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value]: watch(CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value),
      [CREATE_ORDER_FIELDS_NAME.SELLER.value]: watch(CREATE_ORDER_FIELDS_NAME.SELLER.value),
      // [CREATE_ORDER_FIELDS_NAME.CURRENCY.value]: watch(CREATE_ORDER_FIELDS_NAME.CURRENCY.value),
      // [CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value]: watch(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value),
      // [CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value]: watch(CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value),
      // [CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value]: watch(CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value),
      // [CREATE_ORDER_FIELDS_NAME.PACKAGES.value]: watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).map((pkg: any) => ({ ...pkg, order_items: [] }))
    };
  case CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.value:
    return {
      ...DEFAULT_VALUE_FOR_CREATE_ORDER_FIELDS,
      [CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value]: watch(CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value),
      [CREATE_ORDER_FIELDS_NAME.SELLER.value]: watch(CREATE_ORDER_FIELDS_NAME.SELLER.value),
      [CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.value]: watch(CREATE_ORDER_FIELDS_NAME.PAYMENT_TYPE.value),
      [CREATE_ORDER_FIELDS_NAME.CURRENCY.value]: watch(CREATE_ORDER_FIELDS_NAME.CURRENCY.value),
      [CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value]: watch(CREATE_ORDER_FIELDS_NAME.ORDER_AMOUNT.value),
      [CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT_UNIT.value]: watch(CREATE_ORDER_FIELDS_NAME.ORDER_WEIGHT_UNIT.value),
      [CREATE_ORDER_FIELDS_NAME.REFERENCE_ORDER_ID.value]: watch(CREATE_ORDER_FIELDS_NAME.REFERENCE_ORDER_ID.value),
      [CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value]: watch(CREATE_ORDER_FIELDS_NAME.PICKUP_LOCATION.value),
      [CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value]: watch(CREATE_ORDER_FIELDS_NAME.SEARCH_PICKUP_LOCATION.value),
      [CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value]: watch(CREATE_ORDER_FIELDS_NAME.CUSTOMER_ADDRESS.value),
      [CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value]: watch(CREATE_ORDER_FIELDS_NAME.SEARCH_CUSTOMER.value),
      [CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value]: watch(CREATE_ORDER_FIELDS_NAME.CUSTOMER_DETAILS.value),
      [CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value]: watch(CREATE_ORDER_FIELDS_NAME.NUMBER_OF_PACKAGES.value),
      [CREATE_ORDER_FIELDS_NAME.PACKAGES.value]: watch(CREATE_ORDER_FIELDS_NAME.PACKAGES.value).map(() => ({dimensionUnit: ORDER_DIMENSIONS_UNIT.CM, order_items: [] }))
    };
  default:
    return {
      ...DEFAULT_VALUE_FOR_CREATE_ORDER_FIELDS,
      [CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value]: watch(CREATE_ORDER_FIELDS_NAME.ORDER_CREATION_TYPE.value),
      [CREATE_ORDER_FIELDS_NAME.SELLER.value]: watch(CREATE_ORDER_FIELDS_NAME.SELLER.value),
      [CREATE_ORDER_FIELDS_NAME.CURRENCY.value]: watch(CREATE_ORDER_FIELDS_NAME.CURRENCY.value),
      [CREATE_ORDER_FIELDS_NAME.PACKAGES.value]: PACKAGES_DEFAULT_VALUE
    };
  }
}; 