import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import { axiosInstance } from '@src/network/AxiosInstance'
import ChoiceSelector from '@src/views/pos/components/choice-selector/choiceSelector'
import { getRegistersAsyncData } from '@src/views/pos/pos.apis'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { X } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Spinner } from 'reactstrap'

const PayBalanceModal = ({ isOpen, toggle, selectedCustomer, setSelectedCustomer, handleGetAllCustomers }) => {

  const { t } = useTranslation()
  const payOptions = [{ label: 'Cash', value: 'cash' }, { label: 'Credit Card', value: 'card' }]
  const [activePayOption, setActivePayOption] = useState(payOptions[0])
  const { register, formState: { errors }, control, handleSubmit, reset } = useForm()
  const hub_id = useSelector(store => store.auth.selectedGlobalHubId)

  const { mutate: payBalance, isPending } = useMutation({
    mutationKey: ['pay-balance-pos-customer'],
    mutationFn: (body) => {
      return axiosInstance.put('/api/v1/pos/cash_management/customer/balance', body)
    },
    onSuccess: () => {
      CustomToast('Balance Paid Successfully', { my_type: 'success' })
      handleGetAllCustomers()
      toggle()
    }
  })

  const submitHandler = (data) => {
    const body = {
      method: activePayOption.value,
      amount: +data.amount,
      register_id: +data.register.value,
      customer_id: selectedCustomer.id
    }
    payBalance(body)
  }

  const handleClosed = () => {
    reset()
    setActivePayOption(payOptions[0])
    setSelectedCustomer({})
  }

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} onClosed={handleClosed}>
      <div className='p-24px d-flex flex-column gap-8px border-bottom'>
        <div className='txt-h1-sb text-dark flex-center-between'>
          <div >{t('Pay Balance')}</div>
          <div className='cursor-pointer' onClick={toggle}><X /></div>
        </div>
        <div className='txt-body-rg text-dark-5'>{t('Your oldest sales will be paid off first.')}</div>
      </div>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className='p-24px d-flex flex-column gap-24px'>
          <ChoiceSelector
            options={payOptions}
            activeOption={activePayOption}
            setActiveOption={setActivePayOption}
          />
          <InputField
            {...register('amount', { required: 'This field is required' })}
            errors={errors}
            label='Enter Amount'
            isRequired
            type='number'
            step='0.01'
          />
          <FloatingDropDown
            name="register"
            control={control}
            errors={errors}
            loadOptions={getRegistersAsyncData}
            validationSchema={{
              register: { required: 'This field is required' }
            }}
            isAsync
            isRequired
            title="Select Register"
            additional={{
              hub_id
            }}
          />
        
         
        </div>
        <div className='p-24px flex-center-start gap-16px border-top'>
          <Button type='button' ofStyle='outlined' className='w-50 flex-center-center' onClick={toggle} disabled={isPending}>{t('Cancel')}</Button>
          <Button type='submit' className='w-50 flex-center-center' disabled={isPending}>
            {isPending && <Spinner size='sm' />}
            {t('Add')}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default PayBalanceModal