import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent } from '@src/network/RealTime'
import { PUTAWAY_TYPES } from '@src/views/returns/returns.constants'

const API_PREFIXES = {
  TENANT: '/api/v1/tenant',
  WMS: '/api/v1/wms',
  CREATE: '/api/v2/wms/hubs',
  OMS: '/api/v1/oms'
}
export const getReturnRequestList = createAsyncThunk('/return-request/list', async ({params}) => {
  if (!Number(params?.hub_id)) {
    delete params.hub_id
  }
  const response = await axiosInstance.get(`${API_PREFIXES.OMS}/returns/orders/request`, {params: {...params}})
  return response?.data
})
export const getReturnRequestDetail = createAsyncThunk('/return-request/detail', async ({ returnRequestId }) => {
  const response = await axiosInstance.get(`${API_PREFIXES.OMS}/returns/orders/request/${returnRequestId}`)
  return response?.data
})
export const getOrderDetail = createAsyncThunk('/order-return/detail', async ({ sellerOrderId }) => {
  const response = await axiosInstance.get(`${API_PREFIXES.OMS}/returns/orders/request/orders/${sellerOrderId}`)
  return response?.data
})
export const approveReturnRequest = createAsyncThunk('/return-request/approve', async ({ returnRequestId, body }) => {
  const response = await axiosInstance.put(`${API_PREFIXES.OMS}/returns/orders/request/${returnRequestId}/approve`,  body)
  return response?.data
})

// rejectReturnRequest
export const rejectReturnRequest = createAsyncThunk('/return-request/reject-return-request', async ({ body }) => {
  const response = await axiosInstance.put(`${API_PREFIXES.OMS}/returns/orders/request/reject`,  body)
  return response?.data
})

// get return order with status
export const getReturnOrders = createAsyncThunk('/return-request/get-return-orders', async ({params}, store) => {
  const response = await axiosInstance.get(`${API_PREFIXES.OMS}/returns/orders`, {params: {hub_id: store.getState()?.auth?.selectedGlobalHubId, ...params }})
  return response.data
})
// cancel return order
export const cancelReturnOrder = createAsyncThunk('/return-request/cancel-return-order', async ({orderID}) => {
  const response = await axiosInstance.put(`${API_PREFIXES.OMS}/returns/orders/${orderID}/cancel`)
  return response.data
})
// get return order detail
export const getReturnOrderDetail = createAsyncThunk('/return-request/get-return-order-detail', async (param) => {
  const response = await axiosInstance.get(`${API_PREFIXES.OMS}/returns/orders/${param}`)
  return response.data
})

// getReturnOrderToCreateReturnRequest
export const getReturnOrderToCreateReturnRequest = createAsyncThunk('/return-request/get-return-order-to-create-return-request', async ({seller_sales_channel_order_Id}) => {
  const response = await axiosInstance.get(`${API_PREFIXES.OMS}/returns/orders/request/orders/${seller_sales_channel_order_Id}`)
  return response.data
})
// getReturnOrderByAwbNumber
export const getReturnOrderByAwbNumber = createAsyncThunk('/return-order/get-return-order-by-awb-number', async (params) => {
  const response = await axiosInstance.get(`${API_PREFIXES.OMS}/returns/orders/awb`, {...params})
  return response.data
})
// createReturnRequest
export const createReturnRequest = createAsyncThunk('/return-request/create-return-request', async ({body}) => {
  const response = await axiosInstance.post(`${API_PREFIXES.OMS}/returns/orders/request`, body)
  return response.data
})
// completeReturnOrderProcessing
export const completeReturnOrderProcessing = createAsyncThunk('/return-request/complete-return-order-processing', async ({body, orderID}) => {
  const response = await axiosInstance.put(`${API_PREFIXES.OMS}/returns/orders/${orderID}/qc`, body)
  return response.data
})
// createReverseShipment
export const createReverseShipment = createAsyncThunk('/return-request/create-reverse-shipment', async ({body, return_id}) => {
  const response = await axiosInstance.post(`${API_PREFIXES.OMS}/returns/orders/${return_id}/shipments`, body)
  return response.data
})
// markOrdersAsReturnedTohHub
export const markOrdersAsReturnedTohHub = createAsyncThunk('/return-order/mark-order-as-returned-to-hub', async ({body}) => {
  const response = await axiosInstance.put(`${API_PREFIXES.OMS}/orders/shipments`, body)
  return response.data
})
// importReturnOrderBulk 
export const importReturnOrderBulk = createAsyncThunk('/return-order/import-bulk-return-order', async (body) => {
  const response = await axiosInstance.post(`${API_PREFIXES.OMS}/returns/orders/import`, body)
  return response.data
}
)
// getReturnOrderLogs
export const getReturnOrderLogs = createAsyncThunk('/return-order/get-return-order-logs', async ({orderID}) => {
  const response = await axiosInstance.get(`${API_PREFIXES.OMS}/returns/orders/${orderID}/logs`)
  return response.data
})

// getAllSellers
export const getAllSellers = createAsyncThunk('/returns/getAllSellers', async () => {
  const response = await axiosInstance.get('/api/v1/tenant/sellers', { params: { sort: 'name_asc', page: 1, per_page: 100, is_active: true } })
  let sellerData = response.data.data
  sellerData = sellerData.map((seller) => ({
    value: seller,
    label: seller.name
  }))
  return sellerData
}
)
export const getAllShippingPartners = createAsyncThunk('/returns/allShippingPartner', async (params) => {
  const response = await axiosInstance.get('/api/v1/shipping/shipping_partners?is_integrated=true', { params })
  let shippingData = response.data.data
  shippingData = shippingData.map((shipping) => ({
    value: shipping,
    label: shipping.name
  }))
  shippingData.unshift({label: 'Manual', value: {tag: 'manual'}})
  return shippingData
}
)
// get async Seller data
export const getSellersAsync = async (search = '', loadedOptions, { page = 1 }) => {
  const params = search ? { search_column: 'name', search_query: search } : { page }
  try {
    const res = await axiosInstance.get('/api/v1/tenant/sellers', { params: { ...params, sort: 'name_asc', is_active: true } })
    const arr = res?.data?.data
    // arr.unshift({ id: 'all', name: 'All' })
    return {
      options: arr.map((seller) => ({
        value: seller,
        label: seller.name
      })),
      hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
      additional: {
        page: res?.data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

// getReturnGateEntry
export const getReturnGateEntries = createAsyncThunk('/return-order/return-gate-entries', async (params = {}, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.get(`${API_PREFIXES.WMS}/hubs/${hub_id}/returns/gate_entries`, params)
  return response.data
})

// getReturnGateEntry
export const getReturnGateEntryDetails = createAsyncThunk('/return-order/return-gate-entry-details', async (return_gate_entry_id = 1, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.get(`${API_PREFIXES.WMS}/hubs/${hub_id}/returns/gate_entries/${return_gate_entry_id}`)
  return response.data
})

// getReturnGateEntry
export const createReturnGateEntry = createAsyncThunk('/return-order/create-return-gate-entry', async (body, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.post(`${API_PREFIXES.WMS}/hubs/${hub_id}/returns/gate_entries`, body)
  return response.data
})

// getReturnGrns
export const getReturnGrns = createAsyncThunk('/return-order/get-return-grns', async (params = {}, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.get(`${API_PREFIXES.WMS}/hubs/${hub_id}/returns/grns`, { params })
  return response.data
}) 

// getReturnGrnOverview
export const getReturnGrnOverview = createAsyncThunk('/return-order/get-return-grn-details', async ({ grn_id, params }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.get(`${API_PREFIXES.WMS}/hubs/${hub_id}/returns/grns/${grn_id}`, { params })
  return response.data
})

// getReturnGrnOrderDetails
export const getReturnGrnOrderDetails = createAsyncThunk('/return-order/overview-details', async ({ grn_id, order_id, params }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.get(`${API_PREFIXES.WMS}/hubs/${hub_id}/returns/grns/${grn_id}/order/${order_id}/details`, { params })
  return response.data
})

// getReturnGrnPutaway
export const getReturnGrnPutaway = createAsyncThunk('/return-order/putaway-details', async ({ grn_id, putawayType, params }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  let response
  if (putawayType === PUTAWAY_TYPES.ITEM_PUTAWAY) {
    response = await axiosInstance.get(`${API_PREFIXES.WMS}/hubs/${hub_id}/grns/${grn_id}/item_put_away`, { params })
  } else {
    response = await axiosInstance.get(`${API_PREFIXES.WMS}/hubs/${hub_id}/grns/${grn_id}/bin_put_away`, { params })
  }
  return response.data
})

export const createReturnGrn = createAsyncThunk('/return-order/create-return-grn', async (body, store) => {
  const {errorConfig, ...config} = body
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.post(`${API_PREFIXES.WMS}/hubs/${hub_id}/returns/grns`, {...config}, {_audioRequired: Boolean(errorConfig?.audioRequired)})
  return response.data
})

export const getSingleReturnOrderDetail = createAsyncThunk('/return-order/qc-type-data', async (body, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/returns/grns/order/details`, body)
  return res.data
})
export const processNextReturnOrder = createAsyncThunk('/return-order/process-next-order', async (body, store) => {
  const {grn_id, params, ...rest} = body
  const hub_id = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.get(`${API_PREFIXES.WMS}/hubs/${hub_id}/returns/grns/${grn_id}/processed`, {params, ...rest})
  return response.data
})

export const getReturnOrderHubDetail = createAsyncThunk('/return-order/hub-details', async (hubId) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}`)
  return res.data
})

export const returnsSlice = createSlice({
  name: 'returns',

  initialState: {
    // Static States
    loading: {},
    success: {},
    error: {},
    loadingState: {},

    // API States
    returnRequestList: {data: [], meta: {}},
    returnRequestDetail: { loading: false, is_success: false, data: null },
    orderDetail: { loading: false, is_success: false, data: null },
    returnOrders: {data: null, meta: null},
    returnOrderDetail: null,
    orderDetailToCreateReturnRequest: null,
    returnOrderBulkUploadFile: null,
    importReturnOrderBulkResponse: null,
    sellers: null,
    allShippingPartners: null,
    returnOrderLogs: null,
    returnGateEntries: null,
    returnGateEntryDetails: null,
    returnGateEntryCreated: false,
    returnGrns: null,
    returnGrnOverview: null,
    returnGrnOrderDetails: null,
    returnGrnPutaway: null,
    createReturnGrnResponse: null,
    singleReturnGrnOrderDetail: null,
    processNextReturnOrderResponse:null,
    returnOrderHubDetail: null,
    isRedirectFromItemDetails:false
  },

  reducers: {
    setReturnOrderBulkUploadedFile: (state, action) => {
      state.returnOrderBulkUploadFile = { ...action.payload }
    },
    cleanupReturnOrderData: (state, action) => {
      const {variableName} = action.payload
      state[variableName] = null
    },
    cleanupImportReturnOrderResponse: (state) => {
      state.importReturnOrderBulkResponse = null
    },
    resetSuccess : state => {
      state.success = {}
    },
    resetReturnGateEntry : state => {
      state.returnGateEntryDetails = null
      state.returnGateEntryCreated = false
      state.returnOrderDetail = null
    },
    clearCreateReturnGrnResponse: state => {
      state.createReturnGrnResponse = null
      state.returnOrderDetail = null
    },
    clearSingleReturnOrderDetail: state => {
      state.singleReturnGrnOrderDetail = null
    },
    clearOrderDetailToCreateReturnRequest: state => {
      state.orderDetailToCreateReturnRequest = null
    },
    clearReturnOrderDetail: state => {
      state.returnOrderDetail = null
    },
    resetError : state => {
      state.error = {}
    },
    setIsRedirectFromItemDetails :(state, action) => {
      state.isRedirectFromItemDetails = action.payload
    },
    clearGetAwbSuccessOrError : (state) => {
      state.success = {...state.success, getReturnOrderByAwbNumber: false}
      state.error = {...state.error, getReturnOrderByAwbNumber: false}
    } 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReturnRequestList.pending, (state) => {
        state.loading = {...state.loading, getReturnRequestList: true}
        state.success = {...state.success, getReturnRequestList: false}
        state.error = {...state.error, getReturnRequestList: false}
      })
      .addCase(getReturnRequestList.fulfilled, (state, action) => {
        state.loading = {...state.loading, getReturnRequestList: false}
        state.success = {...state.success, getReturnRequestList: true}
        state.returnRequestList = action.payload
      })
      .addCase(getReturnRequestList.rejected, (state) => {
        state.loading = {...state.loading, getReturnRequestList: false}
        state.error = {...state.error, getReturnRequestList: true}
      })
      .addCase(getReturnRequestDetail.pending, (state) => {
        state.loading = {...state.loading, getReturnRequestDetail: true}
      })
      .addCase(getReturnRequestDetail.fulfilled, (state, action) => {
        state.loading = {...state.loading, getReturnRequestDetail: false}
        state.returnRequestDetail = action.payload
      })
      .addCase(getReturnRequestDetail.rejected, (state) => {
        state.loading = {...state.loading, getReturnRequestDetail: false}
      })
      .addCase(getOrderDetail.fulfilled, (state, action) => {
        state.orderDetail = {
          ...action.payload
        }
      })
      .addCase(getOrderDetail.rejected, (state, action) => {
        state.orderDetail = {
          ...action.payload,
          loading: false,
          is_success: false
        }
      })
      .addCase(getOrderDetail.pending, (state) => {
        state.orderDetail = {
          loading: true,
          is_success: false,
          data: null
        }
      })
      .addCase(approveReturnRequest.pending, (state) => {
        state.loading = {...state.loading, approveReturnRequest: true}
      })
      .addCase(approveReturnRequest.fulfilled, (state, action) => {
        state.loading = {...state.loading, approveReturnRequest: false}
        state.success = {...state.success, approveReturnRequest: action.payload.data}
        CustomToast('Return request approved successfully.', {my_type:'success'})
      })
      .addCase(approveReturnRequest.rejected, (state) => {
        state.loading = {...state.loading, approveReturnRequest: false}
      })
      // rejectReturnRequest response 
      .addCase(rejectReturnRequest.pending, (state) => {
        state.loading = {...state.loading, rejectReturnRequest: true}
      })
      .addCase(rejectReturnRequest.fulfilled, (state) => {
        state.loading = {...state.loading, rejectReturnRequest: false}
        state.success = {...state.success, rejectReturnRequest: true}
        CustomToast('Return request rejected successfully.', {my_type:'success'})
      })
      .addCase(rejectReturnRequest.rejected, (state) => {
        state.loading = {...state.loading, rejectReturnRequest: false}
      })
      // get return orders response
      .addCase(getReturnOrders.pending, (state) => {
        state.loading = {...state.loading, getReturnOrders: true}
      })
      .addCase(getReturnOrders.fulfilled, (state, action) => {
        state.loading = {...state.loading, getReturnOrders: false}
        state.success = {...state.success, getReturnOrders: true}
        state.error = {...state.error, getReturnOrders: false}
        state.returnOrders = action.payload
      })
      .addCase(getReturnOrders.rejected, (state) => {
        state.loading = {...state.loading, getReturnOrders: false}
        state.success = {...state.success, getReturnOrders: false}
        state.error = {...state.error, getReturnOrders: true}
      })
      // cancel return order response
      .addCase(cancelReturnOrder.pending, (state) => {
        state.loading = {...state.loading, cancelReturnOrder: true}
      })
      .addCase(cancelReturnOrder.fulfilled, (state) => {
        state.loading = {...state.loading, cancelReturnOrder: false}
        state.success = {...state.success, cancelReturnOrder: true}
        CustomToast('Return order cancelled successfully', {my_type: 'success'})
      })
      .addCase(cancelReturnOrder.rejected, (state) => {
        state.loading = {...state.loading, cancelReturnOrder: false}
      })
      // return order detail response
      .addCase(getReturnOrderDetail.pending, (state) => {
        state.loading = {...state.loading, getReturnOrderDetail: true}
        state.success = {...state.success, getReturnOrderDetail: false}
        state.error = {...state.error, getReturnOrderDetail: false}
      })
      .addCase(getReturnOrderDetail.fulfilled, (state, action) => {
        state.loading = {...state.loading, getReturnOrderDetail: false}
        state.success = {...state.success, getReturnOrderDetail: true}
        state.returnOrderDetail = action.payload.data
      })
      .addCase(getReturnOrderDetail.rejected, (state) => {
        state.loading = {...state.loading, getReturnOrderDetail: false}
        state.error = {...state.error, getReturnOrderDetail: true}
        state.returnOrderDetail = null
      })
      // getReturnOrderToCreateReturnRequest response
      .addCase(getReturnOrderToCreateReturnRequest.pending, (state) => {
        state.loading = {...state.loading, getReturnOrderToCreateReturnRequest: true}
        state.orderDetailToCreateReturnRequest = null
      })
      .addCase(getReturnOrderToCreateReturnRequest.fulfilled, (state, action) => {
        state.loading = {...state.loading, getReturnOrderToCreateReturnRequest: false}
        state.success = {...state.success, getReturnOrderToCreateReturnRequest: true}
        state.orderDetailToCreateReturnRequest = action.payload.data
      })
      .addCase(getReturnOrderToCreateReturnRequest.rejected, (state) => {
        state.loading = {...state.loading, getReturnOrderToCreateReturnRequest: false}
        state.orderDetailToCreateReturnRequest = null
        // Set isRedirectFromItemDetails to true when the "Create Return Request" button is clicked.
        // This flag is reset to false in the cleanup process of the "Create Return Request" component,
        // Here handling in case of errors
        state.isRedirectFromItemDetails = false
      })
      // createReturnRequest response 
      .addCase(createReturnRequest.pending, (state) => {
        state.loading = {...state.loading, createReturnRequest: true}

      })
      .addCase(createReturnRequest.fulfilled, (state) => {
        state.loading = {...state.loading, createReturnRequest: false}
        state.success = {...state.success, createReturnRequest: true}
        CustomToast('Return request created successfully', {my_type: 'success'})
      })
      .addCase(createReturnRequest.rejected, (state) => {
        state.loading = {...state.loading, createReturnRequest: false}
        state.returnOrderDetail = null
      })
      // getReturnOrderByAwbNumber response 
      .addCase(getReturnOrderByAwbNumber.pending, (state) => {
        state.loading = {...state.loading, getReturnOrderByAwbNumber: true}
        state.error = {...state.error, getReturnOrderByAwbNumber: false}
        state.success = {...state.success, getReturnOrderByAwbNumber: false}
      })
      .addCase(getReturnOrderByAwbNumber.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getReturnOrderByAwbNumber: false }
        state.success = {...state.success, getReturnOrderByAwbNumber: true}
        state.returnOrderDetail = action.payload.data
        // CustomToast("Please Enter Valid AWB Number", {my_type: "error"})
    
      })
      .addCase(getReturnOrderByAwbNumber.rejected, (state) => {
        state.loading = { ...state.loading, getReturnOrderByAwbNumber: false }
        state.error = {...state.error, getReturnOrderByAwbNumber: true}
        state.returnOrderDetail = null 
      })
      // completeReturnOrderProcessing response
      .addCase(completeReturnOrderProcessing.pending, (state) => {
        state.loading = {...state.loading, completeReturnOrderProcessing: true}
      })
      .addCase(completeReturnOrderProcessing.fulfilled, (state) => {
        state.success = {...state.success, completeReturnOrderProcessing: true}
        state.loading = {...state.loading, completeReturnOrderProcessing: false}
        CustomToast('QC process completed successfully', {my_type: 'success'})
      })
      .addCase(completeReturnOrderProcessing.rejected, (state) => {
        state.loading = {...state.loading, completeReturnOrderProcessing: false}
      })
      // createReverseShipment response
      .addCase(createReverseShipment.pending, (state) => {
        state.loading = {...state.loading, createReverseShipment: true}

      })
      .addCase(createReverseShipment.fulfilled, (state) => {
        state.loading = {...state.loading, createReverseShipment: false}
        state.success = {...state.success, createReverseShipment: true}
        CustomToast('Reverse shipment created successfully', {my_type: 'success'})
      })
      .addCase(createReverseShipment.rejected, (state) => {
        state.loading = {...state.loading, createReverseShipment: false}
      })
      // markOrdersAsReturnedTohHub response
      .addCase(markOrdersAsReturnedTohHub.pending, (state) => {
        state.loading = {...state.loading, markOrdersAsReturnedTohHub: true}

      })
      .addCase(markOrdersAsReturnedTohHub.fulfilled, (state) => {
        state.loading = {...state.loading, markOrdersAsReturnedTohHub: false}
        state.success = {...state.success, markOrdersAsReturnedTohHub: true}
        CustomToast('Order(s) returned to the origin successfully.', {my_type: 'success'})
      })
      .addCase(markOrdersAsReturnedTohHub.rejected, (state) => {
        state.loading = {...state.loading, markOrdersAsReturnedTohHub: false}
      })
      .addCase(importReturnOrderBulk.pending, (state) => {
        state.loading = { ...state.loading, importReturnOrderBulk: true }
        state.success = { ...state.success, importReturnOrderBulk: false }
        state.error = { ...state.error, importReturnOrderBulk: false }
      })
      .addCase(importReturnOrderBulk.fulfilled, (state, action) => {
        state.loading = { ...state.loading, importReturnOrderBulk: false }
        CustomToast('Your request has been accepted. You will receive a confirmation shortly.', { my_type: 'success' })
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
        state.success = { ...state.success, importReturnOrderBulk: true }
      })
      .addCase(importReturnOrderBulk.rejected, (state) => {
        state.loading = { ...state.loading, importReturnOrderBulk: false }
        state.error = { ...state.error, importReturnOrderBulk: true }
      })
      // getReturnOrderLogs response
      .addCase(getReturnOrderLogs.pending, (state) => {
        state.loading = {...state.loading, getReturnOrderLogs: true}

      })
      .addCase(getReturnOrderLogs.fulfilled, (state, action) => {
        state.loading = {...state.loading, getReturnOrderLogs: false}
        state.returnOrderLogs = action.payload.data
      })
      .addCase(getReturnOrderLogs.rejected, (state) => {
        state.loading = {...state.loading, getReturnOrderLogs: false}
      })
      // getAllSellers response
      .addCase(getAllSellers.pending, (state) => {
        state.loading = { ...state.loading, getAllSellers: true }
      })
      .addCase(getAllSellers.fulfilled, (state, action) => {
        state.sellers = action.payload
        state.loading = { ...state.loading, getAllSellers: false }
      })
      .addCase(getAllSellers.rejected, (state) => {
        state.loading = { ...state.loading, getAllSellers: false }
      })
      .addCase(getAllShippingPartners.pending, (state) => {
        state.loading = { ...state.loading, getAllShippingPartners: true }
      })
      .addCase(getAllShippingPartners.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getAllShippingPartners: false }
        state.allShippingPartners = action.payload
      })
      .addCase(getAllShippingPartners.rejected, (state) => {
        state.loading = { ...state.loading, getAllShippingPartners: false }
        state.error = true
      })
      .addCase(getReturnGateEntries.pending, (state) => {
        state.loading = { ...state.loading, getReturnGateEntries: true }
        state.error = { ...state.error, getReturnGateEntries: false }
        state.success = {...state.success, getReturnGateEntries: false }
        state.returnGateEntries = null
      })
      .addCase(getReturnGateEntries.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getReturnGateEntries: false }
        state.error = { ...state.error, getReturnGateEntries: false }
        state.success = {...state.success, getReturnGateEntries: true }
        state.returnGateEntries = action.payload
        state.returnOrderDetail = null
      })
      .addCase(getReturnGateEntries.rejected, (state) => {
        state.loading = { ...state.loading, getReturnGateEntries: false }
        state.error = { ...state.error, getReturnGateEntries: true }
        state.success = {...state.success, getReturnGateEntries: false }
      })
      .addCase(getReturnGateEntryDetails.pending, (state) => {
        state.loading = { ...state.loading, getReturnGateEntryDetails: true }
      })
      .addCase(getReturnGateEntryDetails.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getReturnGateEntryDetails: false }
        state.returnGateEntryDetails = action.payload
      })
      .addCase(getReturnGateEntryDetails.rejected, (state) => {
        state.loading = { ...state.loading, getReturnGateEntryDetails: false }
        state.error = true
      })
      .addCase(createReturnGateEntry.pending, (state) => {
        state.loading = { ...state.loading, createReturnGateEntry: true }
      })
      .addCase(createReturnGateEntry.fulfilled, (state) => {
        state.loading = { ...state.loading, createReturnGateEntry: false }
        state.returnGateEntryCreated = true
      })
      .addCase(createReturnGateEntry.rejected, (state) => {
        state.loading = { ...state.loading, createReturnGateEntry: false }
        state.error = true
      })
      .addCase(getReturnGrns.pending, (state) => {
        state.loading = { ...state.loading, getReturnGrns: true }
      })
      .addCase(getReturnGrns.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getReturnGrns: false }
        state.success = { ...state.success, getReturnGrns: true }
        state.error = { ...state.error, getReturnGrns: false }
        state.returnGrns = action.payload
      })
      .addCase(getReturnGrns.rejected, (state) => {
        state.loading = { ...state.loading, getReturnGrns: false }
        state.success = { ...state.success, getReturnGrns: false }
        state.error = { ...state.error, getReturnGrns: true }
      })
      .addCase(getReturnGrnOverview.pending, (state) => {
        state.loading = { ...state.loading, getReturnGrnOverview: true }
        state.success = { ...state.success, getReturnGrnOverview: false }
        state.error = { ...state.error, getReturnGrnOverview: false }
      })
      .addCase(getReturnGrnOverview.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getReturnGrnOverview: false }
        state.success = { ...state.success, getReturnGrnOverview: true }
        state.error = { ...state.error, getReturnGrnOverview: false }
        state.returnGrnOverview = action.payload
      })
      .addCase(getReturnGrnOverview.rejected, (state) => {
        state.loading = { ...state.loading, getReturnGrnOverview: false }
        state.success = { ...state.success, getReturnGrnOverview: false }
        state.error = { ...state.error, getReturnGrnOverview: true }
      })
      .addCase(getReturnGrnOrderDetails.pending, (state) => {
        state.loading = { ...state.loading, getReturnGrnOrderDetails: true }
      })
      .addCase(getReturnGrnOrderDetails.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getReturnGrnOrderDetails: false }
        state.success = { ...state.success, getReturnGrnOrderDetails: true }
        state.returnGrnOrderDetails = action.payload
      })
      .addCase(getReturnGrnOrderDetails.rejected, (state) => {
        state.loading = { ...state.loading, getReturnGrnOrderDetails: false }
        state.success = { ...state.success, getReturnGrnOrderDetails: false }
      })
      .addCase(getReturnGrnPutaway.pending, (state) => {
        state.loading = { ...state.loading, getReturnGrnPutaway: true }
      })
      .addCase(getReturnGrnPutaway.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getReturnGrnPutaway: false }
        state.success = { ...state.success, getReturnGrnPutaway: true }
        state.returnGrnPutaway = action.payload
      })
      .addCase(getReturnGrnPutaway.rejected, (state) => {
        state.loading = { ...state.loading, getReturnGrnPutaway: false }
        state.success = { ...state.success, getReturnGrnPutaway: false }
        state.error = { ...state.error, getReturnGrnPutaway: true }
      })
      .addCase(createReturnGrn.pending, (state) => {
        state.loading = { ...state.loading, createReturnGrn: true }
      })
      .addCase(createReturnGrn.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createReturnGrn: false }
        state.success = { ...state.success, createReturnGrn: true }
        state.error = { ...state.error, createReturnGrn: false }
        state.createReturnGrnResponse = action.payload
        state.singleReturnGrnOrderDetail = action.payload.data.return_order_detail
      })
      .addCase(createReturnGrn.rejected, (state) => {
        state.loading = { ...state.loading, createReturnGrn: false }
        state.success = { ...state.success, createReturnGrn: false }
        state.error = { ...state.error, createReturnGrn: true }
      })
      .addCase(getSingleReturnOrderDetail.pending, state => {
        state.loadingState = { ...state.loadingState, getSingleReturnOrderDetail: true }
      })
      .addCase(getSingleReturnOrderDetail.fulfilled, (state, action) => {
        state.loadingState = { ...state.loadingState, getSingleReturnOrderDetail: false }
        state.singleReturnGrnOrderDetail = action.payload
      })
      .addCase(getSingleReturnOrderDetail.rejected, state => {
        state.loadingState = { ...state.loadingState, getSingleReturnOrderDetail: false }
        state.errorState = { ...state.errorState, getSingleReturnOrderDetail: true }
      })
      .addCase(processNextReturnOrder.pending, (state) => {
        state.loading = { ...state.loading, processNextReturnOrder: true }
      })
      .addCase(processNextReturnOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, processNextReturnOrder: false }
        state.success = { ...state.success, processNextReturnOrder: true }
        state.error = { ...state.error, processNextReturnOrder: false }
        state.processNextReturnOrderResponse = action.payload.data
        state.createReturnGrnResponse = {
          ...state.createReturnGrnResponse,
          data: {
            ...state.createReturnGrnResponse?.data,
            return_order_detail: action.payload.data.return_order_detail
          }
        }
        state.singleReturnGrnOrderDetail = action.payload.data
      })
      .addCase(processNextReturnOrder.rejected, (state) => {
        state.loading = { ...state.loading, processNextReturnOrder: false }
        state.success = { ...state.success, processNextReturnOrder: false }
        state.error = { ...state.error, processNextReturnOrder: true }
      })
      .addCase(getReturnOrderHubDetail.fulfilled, (state, action) => {
        state.returnOrderHubDetail = action.payload.data
      }) 
      .addCase(getReturnOrderHubDetail.rejected, (state) => {
        state.returnOrderHubDetail = null
      })
  }

})
export const {
  cleanupReturnOrderData,
  resetSuccess,
  setReturnOrderBulkUploadedFile,
  cleanupImportReturnOrderResponse,
  resetReturnGateEntry,
  clearCreateReturnGrnResponse,
  clearSingleReturnOrderDetail,
  clearOrderDetailToCreateReturnRequest,
  clearReturnOrderDetail,
  resetError,
  setIsRedirectFromItemDetails,
  clearGetAwbSuccessOrError
} = returnsSlice.actions

export default returnsSlice.reducer

export const getShippingPartnerAsyncDataGateEntry = async (
  search,
  loadedOptions,
  { params }
) => {
  try {
    const response = await axiosInstance.get('/api/v1/shipping/shipping_partners', {params:{...params, name_filter: search}})
    const data = response?.data?.data
    data.unshift({id: null, tag: 'manual', name: 'Manual'})
    data.unshift({id: null, tag: 'All', name: 'All'})
    return {
      options: data.map((ele) => ({
        value: { id: ele.id, tag: ele.tag, name: ele.name, logo: ele?.logo },
        label: ele?.name,
        logo: ele?.logo
      })),
      hasMore: data?.meta?.current_page < data?.meta?.last_page,
      additional: {
        page: data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        params
      }
    }
  }
}