/* eslint-disable @typescript-eslint/no-explicit-any */
import BulkActions from '@src/@core/components/ui/bulk-actions/BulkActions';
import Button from '@src/@core/components/ui/button';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import SideSheet from '@src/@core/components/ui/sideSheet';
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import { axiosInstance } from '@src/network/AxiosInstance';
import { AbilityContext } from '@src/utility/context/Can';
import { SHIPPING_AGGREGATOR_API_KEYS } from '@src/views/shipping-aggregator/shipping-aggregator.apis';
import { ORDERS_TABS } from '@src/views/shipping-aggregator/shipping-aggregator.constant';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import CreateShipment from '../../create-shipment';
import PackageInfo from '../package-info/package-info';
import PackageLogs from '../package-logs/package-logs';

const PackageDetailsSideSheet: React.FC<any> = (props) => {
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);
  const { isOpen, toggleSideSheet, shipmentPackageDetails } = props;
  const [searchParams] = useSearchParams();
  const currentSelectedTab = searchParams.get('current_tab');
  const isLMDSellerEnabled = useSelector((store: any) => store.auth.userData?.restricted_third_party_logistics);
  const TAB_LISTS = [
    { id: 'PACKAGE_DETAILS', name: 'Details', component: PackageInfo },
    { id: 'PACKAGE_LOG', name: 'Logs',  component: PackageLogs }
  ];
  const [activeTab, setActiveTab] = useState(TAB_LISTS[0].id);
  const [isCreateShipmentSideSheetOpen, setIsCreateShipmentSideSheetOpen] = useState<boolean>(false);
  const ActiveComponent = TAB_LISTS.find(tab => tab.id === activeTab)?.component as React.FC<{ id: string, isPackageDetailView: boolean }>;

  const { mutate: markShipmentOperation } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.MARK_AS_DELIVERED],
    mutationFn: async ({ shipmentId, body}: {shipmentId: string | number, body: {status: string}}) => {
      const response = await axiosInstance.put(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}`, body);
      return {...response, type: body.status};
    },
    onSuccess: ({type}: any) => {
      if (type === 'cancelled') {
        CustomToast('Shipment cancelled successfully', { my_type: 'success' });
      } else {
        CustomToast(`Mark shipment as ${type} successfully`, { my_type: 'success' });
      }
      queryClient.invalidateQueries({ queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_ORDERS] });
      queryClient.invalidateQueries({ queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_PACKAGES] });
      queryClient.invalidateQueries({ queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_SHIPMENTS] });
      toggleSideSheet();
    }
  });

  const { mutate: fetchSingleShipmentAWB } = useMutation({
    mutationKey: [SHIPPING_AGGREGATOR_API_KEYS.FETCH_SHIPMENT_AWB],
    mutationFn: ({ shipmentId}: {shipmentId: string | number}) => {
      return axiosInstance.get(`/api/v1/shipping_aggregator/orders/shipments/${shipmentId}/awb`);
    },
    onSuccess: ({ data }) => {
      window.open(data.data.awb_label, '_blank');
    }
  });

  const handleOnTabChange = (tab: { id: string }) => {
    setActiveTab(tab.id);
  };

  const handleOnClosed = () => {
    setActiveTab(TAB_LISTS[0].id);
  };

  const toggleCreateShipment = () => {
    setIsCreateShipmentSideSheetOpen((p) => !p);
  };
  
  const selectedPackageCleanup = () => {
    queryClient.invalidateQueries({ queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_ORDERS] });
    queryClient.invalidateQueries({ queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_PACKAGES] });
    queryClient.invalidateQueries({ queryKey: [SHIPPING_AGGREGATOR_API_KEYS.GET_ALL_SHIPMENTS] });
    toggleSideSheet();
  };

  console.log({shipmentPackageDetails});

  const bulkActionButtons = {
    CREATE_SHIPMENT: {
      id: 'create-shipment',
      text: 'Create Shipment',
      onClick: toggleCreateShipment,
      omit: !((shipmentPackageDetails?.can_create_shipment && !(isLMDSellerEnabled && currentSelectedTab === ORDERS_TABS.AT_HUB.id )) && (ability.can(
        abilityMap.add_shipment_v2.create.action,
        abilityMap.add_shipment_v2.create.resource
      )))
    },
    MARK_AS_DELIVERED: {
      id: 'mark-as-delivered',
      text: 'Mark as Delivered',
      onClick: () => {
        const body = {
          status: 'delivered'
        };
        markShipmentOperation({shipmentId: shipmentPackageDetails?.shipments_info[shipmentPackageDetails.shipments_info.length - 1].shipment_id as string || '', body});
      },
      omit: !(shipmentPackageDetails?.can_mark_delivered && (ability.can(
        abilityMap.edit_shipment_v2.edit.action,
        abilityMap.edit_shipment_v2.edit.resource
      )))
    },
    MARK_AS_SHIPPED: {
      id: 'mark-as-shipped',
      text: 'Mark as Shipped',
      onClick: () => {
        const body = {
          status: 'shipped'
        };
        markShipmentOperation({shipmentId: shipmentPackageDetails?.shipments_info[shipmentPackageDetails.shipments_info.length - 1].shipment_id as string || '', body});
      },
      omit: !(shipmentPackageDetails?.can_mark_shipped && (ability.can(
        abilityMap.edit_shipment_v2.edit.action,
        abilityMap.edit_shipment_v2.edit.resource
      )))
    },
    CANCEL_SHIPMENT: {
      id: 'cancel-shipment',
      text: 'Cancel Shipment',
      onClick: () => {
        const body = {
          status: 'cancelled'
        };
        markShipmentOperation({shipmentId: shipmentPackageDetails?.shipments_info[shipmentPackageDetails.shipments_info.length - 1].shipment_id as string || '', body});
      },
      omit: !(shipmentPackageDetails?.can_cancel_shipment && (ability.can(
        abilityMap.edit_shipment_v2.edit.action,
        abilityMap.edit_shipment_v2.edit.resource
      )))
    },
    PRINT_AWB: {
      id: 'print-awb',
      text: 'Print AWB',
      onClick: () => {
        fetchSingleShipmentAWB({shipmentId: shipmentPackageDetails?.shipments_info[shipmentPackageDetails.shipments_info.length - 1].shipment_id as string || ''});
      },
      omit: !(shipmentPackageDetails?.can_print_awb && (ability.can(
        abilityMap.view_awb_shipment_v2.view_awb.action,
        abilityMap.view_awb_shipment_v2.view_awb.resource
      )))
    }
  };

  // const allButtonOmitTrue = Object.values(bulkActionButtons).every(
  //   (button) => button.omit
  // );

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <SideSheet
        modalClassName='modal-slide-in order-details-sidesheet-container'
        isOpen={isOpen}
        toggle={toggleSideSheet}
        size='md'
        subTextData={[`Package ID : ${shipmentPackageDetails?.display_package_id}`]}
        title='Package'
        tabDataList={TAB_LISTS}
        selectedTabId={activeTab}
        customBackground={true}
        headerWithTabs={true}
        onTabChange={handleOnTabChange}
        onClosed={handleOnClosed}
      >
        <div className='pb-40px mb-40px'>
          <ActiveComponent
            id={shipmentPackageDetails?.id}
            isPackageDetailView
          />
        </div>
        <SidesheetFooter>
          {activeTab === TAB_LISTS[0].id ? (
            <div className='d-flex w-100 justify-content-end gap-1'>
              <BulkActions  color="primary" actions={Object.values(bulkActionButtons).filter((opt) => (!opt.omit)).filter((_, ind) => ind !== 0)} selectedButton={Object.values(bulkActionButtons).filter(opt => !opt.omit)[0]} />
            </div>) : (
            <Button onClick={toggleSideSheet}>{t('Close')}</Button>
          )}
        </SidesheetFooter>
      </SideSheet>

      <CreateShipment
        isOpen={isCreateShipmentSideSheetOpen}
        toggle={toggleCreateShipment}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-expect-error */
        selectedPackages={shipmentPackageDetails?.id ? [shipmentPackageDetails] : undefined}
        cleanupOnSuccesHandler={selectedPackageCleanup}
      />
    </>
  );
};

export default PackageDetailsSideSheet;