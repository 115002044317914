const Edit = ({ width = 12, height = 12, color='var(--bs-dark)' }) => {

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2487 2.51311L3.70184 10.06L3.70184 11.8357L5.47757 11.8357L13.0244 4.28885L11.2487 2.51311Z" stroke={color} strokeWidth="0.9" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default Edit